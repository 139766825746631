import { useState } from 'react';
import { Button, Col, Row, Title, useTranslation } from '@packages/utils';
import { useGetElcResourcesQuery } from 'redux/apiSlice/elcs.slice';
import fileWithParagraphs from 'assets/images/fileWithParagraphs.svg';
import { useCheckPermission } from 'hooks/useCheckPermission';
import CardContent from 'components/Base/Layout/Content/CardContent';
import { TabBodyWrapper } from 'components/Base/Tabs';
import { EIconNames, Icon } from 'components/Base/Icon';
import { TResourcesTabProps } from './Resources.types';
import AddResourcesModal from './AddResourcesModal';
import ResourcesView from './ResourcesView';

const Resources = ({ elcId }: TResourcesTabProps) => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.elcs.detail.resources-tab',
  });
  const isResourceEditable = useCheckPermission('ELC_RESOURCE_EDIT');
  const [open, setOpen] = useState<boolean>(false);
  const { data, isLoading } = useGetElcResourcesQuery({
    elcId,
  });

  if (!data) {
    return <></>;
  }

  const { elcSpecificDocuments, generalElcDocuments, links } = data;

  return (
    <CardContent contentPadding="16px" tabContent>
      <TabBodyWrapper
        isLoading={isLoading}
        emptyText={t('empty-view.EMPTY_TEXT')}
        src={fileWithParagraphs}
        isEmpty={
          !elcSpecificDocuments.length &&
          !generalElcDocuments.length &&
          !links.length
        }
        {...(isResourceEditable && {
          btnText: t('add-resource.ADD_BTN'),
          onBtnClick: () => setOpen(true),
        })}
      >
        <>
          <Row justify="space-between">
            <Col>
              <Title level={3}>{t('TITLE')}</Title>
            </Col>
            {isResourceEditable && (
              <Col>
                <Button
                  color="primary"
                  type="primary"
                  onClick={() => setOpen(true)}
                  icon={<Icon icon={EIconNames.PLUS} className="pointer" />}
                >
                  {t('add-resource.ADD_BTN')}
                </Button>
              </Col>
            )}
          </Row>
          <ResourcesView
            elcId={elcId}
            data={data}
            isResourceEditable={isResourceEditable}
          />
        </>
      </TabBodyWrapper>
      <AddResourcesModal setOpen={setOpen} open={open} elcId={elcId} />
    </CardContent>
  );
};

export default Resources;
