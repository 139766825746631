import pageNotFoundImage from 'assets/images/404.svg';
import ErrorPageView from '..';

const NotFoundPage = () => {
  return (
    <ErrorPageView
      imgSrc={pageNotFoundImage}
      alt="404 page not found"
      title="OOPS! Something went wrong!"
      description="The page you were looking for does not exist."
    />
  );
};

export default NotFoundPage;
