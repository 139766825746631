import { getColumnSearchProps } from '@idaho-aeyc/ui-kit';
import { useTranslation } from '@packages/utils';

const useGetTableColumnData = () => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.generic-components.table.columns-search',
  });
  const getTableColumnSearchProps = <T,>(inputType?: string) => {
    return getColumnSearchProps<T>(
      t('SEARCH_HERE_LABEL'),
      t('SEARCH_LABEL'),
      t('RESET_BTN'),
      inputType || 'text',
    );
  };
  return { getTableColumnSearchProps };
};

export default useGetTableColumnData;
