import { FC } from 'react';
import { useTranslation } from '@packages/utils';
import EditableDatePicker from 'components/Base/EditableFields/EditableDatePicker';
import { dateTimeFormatUI, timepickerFormat } from 'helpers/utils';
import { Row } from 'components/Base/Grid';
import { EIconNames } from 'components/Base/Icon';
import {
  EWorkshopPartType,
  EWorkshopStatus,
  TPartsUI,
} from 'data/types/workshops.types';
import dayjs, { Dayjs } from 'dayjs';
import { useEditPartDateTimeMutation } from 'redux/apiSlice/workshops.slice';
import {
  ENotificationType,
  useShowToastNotification,
} from 'components/Base/Notification';
import { TRtkErrorType } from 'data/types/generic.types';
import { InplaceEditLabels } from 'components/Base/EditableFields/components/InplaceEditLabel';

const PartDateInput: FC<{
  part: TPartsUI;
  workshopId: string;
  nextPartDateTime?: string;
  previousPartDateTime?: string;
  workshopStatus?: EWorkshopStatus;
}> = ({
  part,
  workshopId,
  previousPartDateTime,
  nextPartDateTime,
  workshopStatus,
}) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.workshops.details.sessions.view.actions.update.date',
  });
  const { t: tDateTime } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.workshops.details.basic-info.parts-date',
  });
  const { t: tEnum } = useTranslation({
    keyPrefix: 'enums',
  });
  const [editPartDateTime, { isLoading }] = useEditPartDateTimeMutation();
  const needToDisable = workshopStatus === EWorkshopStatus.PUBLISHED;
  const { showToastNotification } = useShowToastNotification();

  const disableDate = (current: Dayjs) => {
    if (!needToDisable) {
      return false;
    }
    // should disable dates after next part and dates before prev part
    const nextPart = dayjs(nextPartDateTime);
    const prevPart = dayjs(previousPartDateTime);

    const dateNow = dayjs();

    const disableDaysBeforeNow = current.isBefore(dateNow);
    const disableAfterNextPart =
      current.isAfter(nextPart) || current.isSame(nextPart);
    const disableBeforePrevPart =
      current.isBefore(prevPart) || current.isSame(prevPart);

    if (!previousPartDateTime && nextPartDateTime) {
      return disableAfterNextPart || disableDaysBeforeNow;
    }
    if (previousPartDateTime && !nextPartDateTime) {
      return disableBeforePrevPart || disableDaysBeforeNow;
    }
    if (previousPartDateTime && nextPartDateTime) {
      return (
        disableBeforePrevPart || disableAfterNextPart || disableDaysBeforeNow
      );
    }
    return disableDaysBeforeNow;
  };
  const onEdit = async (value: string) => {
    try {
      await editPartDateTime({
        dateTime: value,
        workshopId,
        workshopPartId: part.workshopPartId,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (e) {
      showToastNotification({
        message: (e as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };
  const label =
    part.partType !== EWorkshopPartType.STANDALONE
      ? `${tEnum(`workshop-part-type.${part.partType}`)}`
      : `${tDateTime('LABEL')}`;
  return (
    <Row>
      <InplaceEditLabels label={label} icon={EIconNames.CALENDAR} />
      <EditableDatePicker
        title={part.dateTime?.value}
        format={dateTimeFormatUI}
        readonly={part.dateTime?.allowedOperations.readonly}
        canRemove={part.dateTime?.allowedOperations.canRemove}
        onEdit={onEdit}
        emptyText={t('EMPTY_TEXT')}
        showTime={{
          minuteStep: 15,
          format: timepickerFormat,
        }}
        disabledDate={disableDate}
        showNow={!needToDisable}
        isLoading={isLoading}
      />
    </Row>
  );
};

export default PartDateInput;
