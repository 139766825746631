import { FC, useState } from 'react';
import { Row, useTranslation } from '@packages/utils';
import { EIconNames } from 'components/Base/Icon';
import { useEditHostMutation } from 'redux/apiSlice/workshops.slice';
import {
  useShowToastNotification,
  ENotificationType,
} from 'components/Base/Notification';
import { TRtkErrorType } from 'data/types/generic.types';
import EditableSelect from 'components/Base/EditableFields/EditableSelect';
import { InplaceEditLabels } from 'components/Base/EditableFields/components/InplaceEditLabel';
import { useGetOrganizationsIdentityQuery } from 'redux/apiSlice/organizations.slice';
import CreateOrganizationModal from 'components/Shared/CreateOrganizationModal';
import { TCreateOrganizationModalOpen } from 'components/Shared/CreateOrganizationModal/CreateOrganizationModal.types';
import { THostProps } from './Host.types';
import ConfirmModalEditHost from './ConfirmModalEditHost';

const Host: FC<THostProps> = ({ host, id }) => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.workshops.details.basic-info.host',
  });
  const { showToastNotification } = useShowToastNotification();
  const [openConfirmModal, setOpenConfirmModal] = useState({
    visible: false,
    newOrgId: '',
  });
  const [open, setOpen] = useState<TCreateOrganizationModalOpen>({
    visible: false,
    initialOrgName: '',
  });

  const [editHost, { isLoading: editHostLoading, isError: editHostError }] =
    useEditHostMutation();
  const { data: organizations } = useGetOrganizationsIdentityQuery();

  const onHostEdit = async (value: string) => {
    try {
      await editHost({
        id,
        value,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (err) {
      showToastNotification({
        message: (err as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  const getNewOrganizationId = async (orgId: string) => {
    setOpenConfirmModal({
      visible: true,
      newOrgId: orgId,
    });
  };

  return (
    <>
      <Row align="middle">
        <InplaceEditLabels label={t('LABEL')} icon={EIconNames.HOST} />
        <EditableSelect
          onEdit={onHostEdit}
          title={{
            label: host?.name || '',
            value: host?.id || '',
          }}
          isLoading={editHostLoading}
          options={organizations}
          addNewOption={{
            onClick: currentTypedValue => {
              setOpen({
                visible: true,
                initialOrgName: currentTypedValue || '',
              });
            },
            addButtonLabel: t('ADD'),
          }}
          emptyText={t('EMPTY_TEXT')}
          readonly={host?.allowedOperations.readonly}
          canRemove={host?.allowedOperations.canRemove}
          isError={editHostError}
        />
      </Row>
      <CreateOrganizationModal
        open={open}
        setOpen={setOpen}
        attachOrganization={getNewOrganizationId}
      />
      <ConfirmModalEditHost
        open={openConfirmModal}
        setOpen={setOpenConfirmModal}
        onHostEdit={onHostEdit}
        editHostLoading={editHostLoading}
      />
    </>
  );
};

export default Host;
