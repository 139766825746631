import { Sider } from '@idaho-aeyc/ui-kit';
import { styled } from 'styled-components';
import { Row } from '@packages/utils';

export const SSidebarBodyWrapper = styled(Row)`
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100% - 274px);
  padding: 0 8px;
  &&::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &&::-webkit-scrollbar-thumb {
    background: #0f8ab4;
  }
`;

export const SSider = styled(Sider)`
  &.ant-layout-sider {
    height: 100vh;
    background-color: ${({ theme }) => theme.colorPrimaryActive};
    .ant-layout-sider-children {
      height: 100%;
    }
  }
`;
