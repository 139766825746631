import { Col } from '@packages/utils';
import { styled } from 'styled-components';

export const DetailsEmptyViewWrapper = styled.div`
  border: 2px dashed ${({ theme }) => theme.colorSimpleCardNormalBorder};
  border-radius: ${({ theme }) => theme.cardBorderRadiusXs}px;
  padding: ${({ theme }) => theme.paddingS}px;
`;

export const DetailsEmptyViewBtnWrapper = styled(Col)`
  display: flex;
  justify-content: center;
`;
