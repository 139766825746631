import { FC, useState } from 'react';
import TagColumnContent from 'components/Shared/Tags/TagColumnContent/TagColumnContent';
import AddEditTagModal from 'components/Shared/Tags/AddEditTagModal';
import { useEditTagsMutation } from 'redux/apiSlice/tags.slice';
import { TTagsProps } from './Tags.types';

const Tags: FC<TTagsProps> = ({
  tagType,
  url,
  selectedTags,
  associationId,
  invalidateTagsConfig,
  isEditable = true,
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const [editTags, { isLoading }] = useEditTagsMutation();

  const onUpdateTags = async (
    assignedTagIds: string[],
    removedTagIds: string[],
  ) => {
    await editTags({
      associationId,
      assignedTagIds,
      removedTagIds,
      url,
      invalidateTagsConfig,
    }).unwrap();
  };
  return (
    <>
      <TagColumnContent
        isEditable={isEditable}
        onOpenModal={() => setOpen(true)}
        tags={selectedTags}
      />
      {open && (
        <AddEditTagModal
          tagType={tagType}
          open={open}
          setOpen={setOpen}
          confirmLoading={isLoading}
          initialSelectedTags={selectedTags}
          onSubmit={onUpdateTags}
        />
      )}
    </>
  );
};

export default Tags;
