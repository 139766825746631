import { ETagsUrls } from 'data/urls/tags.url';

export type TTagUI = {
  value: string;
  text: string;
};
export type TTagDB = {
  id: string;
  name: string;
};

export enum ETagType {
  WORKSHOP = 'WORKSHOP',
  REGISTRANT = 'REGISTRANT',
  ORGANIZATION = 'ORGANIZATION',
  PEOPLE = 'PEOPLE',
  PARENT_ORDER = 'PARENT_ORDER',
}

export type TagInvalidateConfig = {
  invalidateTag: string;
  sliceName: any;
};

export type TTagsSliceProps = {
  invalidateTagsConfig: TagInvalidateConfig[];
  url: ETagsUrls;
} & TTagsEditUI;

export type TTagsEditUI = {
  associationId: string;
  assignedTagIds: string[];
  removedTagIds: string[];
};

export type TTagsEditDB = {
  associationId: string;
  assignedTagIds: string[];
  removedTagIds: string[];
};
