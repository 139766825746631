import {
  Col,
  ENotificationType,
  Row,
  TRtkErrorType,
  useShowToastNotification,
  useTranslation,
  filterLabelOption,
} from '@packages/utils';
import EditableSelect from 'components/Base/EditableFields/EditableSelect';
import { InplaceEditLabels } from 'components/Base/EditableFields/components/InplaceEditLabel';
import { EUserType, TUserIdentityUI } from 'data/types/user.types';
import { useEditLeadMutation } from 'redux/apiSlice/elcs.slice';
import { useGetUserIdentityQuery } from 'redux/apiSlice/userGrow.slice';
import { EIconNames } from 'components/Base/Icon';
import ElcItemPopover from 'pages/private/ELCs/components/ElcPopover';
import { TAEYCLeadProps } from './AEYCLead.types';
import { avatarColorMapper } from '../../../../ELCs.data';

const AEYCLead = ({ data, elcId, isEditAllowed }: TAEYCLeadProps) => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.elcs.list.elc-item.aeyc-lead',
  });
  const { showToastNotification } = useShowToastNotification();
  const [editLead, { isLoading: leadEditIsLoading, isError: leadEditIsError }] =
    useEditLeadMutation();

  const { data: leads } = useGetUserIdentityQuery({
    profileTypes: EUserType.ELC_LEAD,
  });

  const leadsOptions = leads?.map((item: TUserIdentityUI) => ({
    label: item.fullName,
    value: item.id,
  }));

  const onEdit = async (value: string) => {
    try {
      await editLead({
        elcId,
        userId: value,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (e) {
      showToastNotification({
        message: (e as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  return (
    <Row align="middle" wrap={false}>
      <Col>
        <InplaceEditLabels label={t('LABEL')} icon={EIconNames.PROFILE} />
      </Col>
      <Col flex="auto">
        <EditableSelect
          title={{
            label: data.fullName,
            value: data.userId,
          }}
          isLoading={leadEditIsLoading}
          options={leadsOptions}
          onEdit={onEdit}
          fitContent={false}
          emptyText={t('EMPTY_TEXT')}
          placeholder={t('PLACEHOLDER')}
          readonly={!isEditAllowed}
          canRemove={false}
          isError={leadEditIsError}
          avatarColor={avatarColorMapper.lead}
          selectProps={{
            filterOption: filterLabelOption,
            showSearch: true,
          }}
          {...(data && {
            tooltip: (
              <ElcItemPopover
                fullName={data.fullName}
                email={data.email}
                phone={data.phone}
              />
            ),
          })}
        />
      </Col>
    </Row>
  );
};

export default AEYCLead;
