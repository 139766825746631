import { useEffect, useState } from 'react';
import get from 'lodash/get';
import { useTranslation } from '@packages/utils';
import useForm from 'hooks/useForm';
import { Modal } from 'components/Base/Modal';
import {
  useShowToastNotification,
  ENotificationType,
} from 'components/Base/Notification';
import { TValidateFieldsError } from 'data/types/generic.types';
import { TCreateOrganizationUI } from 'data/types/organizations.types';
import {
  useCreateOrganizationMutation,
  useGetOrganizationsIdentityQuery,
} from 'redux/apiSlice/organizations.slice';
import { TCreateOrganizationModalProps } from './CreateOrganizationModal.types';
import CreateOrganizationForm from './CreateOrganizationFrom';

const CreateOrganizationModal = ({
  open,
  setOpen,
  setSelectedOrganization,
  attachOrganization,
  attachOrganizationLoading,
}: TCreateOrganizationModalProps) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.organizations.list.actions.add-organization.modal',
  });
  const { showToastNotification } = useShowToastNotification();
  const { initialOrgName, visible } = open;
  const { form } = useForm<TCreateOrganizationUI>();
  const [organizationId, setOrganizationId] = useState<string>('');
  const [createOrganization, { isLoading }] = useCreateOrganizationMutation();
  const { data: organizations } = useGetOrganizationsIdentityQuery();

  const handleClose = () => {
    setOpen({
      visible: false,
      initialOrgName: '',
    });
    form.resetFields();
  };

  useEffect(() => {
    if (initialOrgName) {
      form.setFieldValue('name', initialOrgName);
    }
  }, [initialOrgName]);

  useEffect(() => {
    if (!organizations || !setSelectedOrganization) {
      return;
    }
    const selectedOrganization = organizations.find(
      item => item.value === organizationId,
    );
    setSelectedOrganization(selectedOrganization);
  }, [organizations]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const id = await createOrganization(values).unwrap();
      if (attachOrganization) {
        await attachOrganization(id);
      }
      setOrganizationId(id);
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
      handleClose();
    } catch (e) {
      if (!(e as TValidateFieldsError)?.errorFields) {
        const message = get(e, 'data.message');
        showToastNotification({
          message,
          type: ENotificationType.ERROR,
        });
      }
    }
  };

  return (
    <Modal
      title={t('TITLE')}
      open={visible}
      onCancel={handleClose}
      onOk={handleSubmit}
      okText={t('SUBMIT_BTN')}
      cancelText={t('CANCEL_BTN')}
      confirmLoading={isLoading || attachOrganizationLoading}
    >
      <CreateOrganizationForm form={form} />
    </Modal>
  );
};

export default CreateOrganizationModal;
