import { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from '@packages/utils';
import { Modal } from 'components/Base/Modal';
import { QRCode } from 'components/Base/QRCode';
import { Paragraph } from 'components/Base/Typography';
import { STextWithHorizontalLine } from '../Authentication.styles';
import { RedirectLink } from './AuthAppDownloadRedirectModal.styles';

const AuthAppDownloadRedirectModal = forwardRef((_, ref) => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.authentication.twoFA.step.one',
  });
  const [open, setOpen] = useState(false);
  const [opSystem, setOpSystem] = useState('');

  useImperativeHandle(ref, () => ({
    open: (system: string) => {
      setOpen(true);
      setOpSystem(system);
    },
  }));

  const qrCodeValue =
    opSystem === t('ANDROID')
      ? 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US&pli=1'
      : 'https://apps.apple.com/us/app/google-authenticator/id388497605';

  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      title={opSystem}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <QRCode value={qrCodeValue} size={88} className="mb-6 mt-4 m-auto" />
      <Paragraph className="text-center">
        {t('download_modal.QR_DESCRIPTION')}
      </Paragraph>
      <STextWithHorizontalLine className="my-4">
        <span>{t('download_modal.OR')}</span>
      </STextWithHorizontalLine>
      <RedirectLink
        href={qrCodeValue}
        target="_blank"
        className="text-center"
        rel="noreferrer"
      >
        {t('download_modal.GO_TO')}{' '}
        {opSystem === t('ANDROID')
          ? t('download_modal.Android.STORE')
          : t('download_modal.ios.STORE')}
      </RedirectLink>
    </Modal>
  );
});

export default AuthAppDownloadRedirectModal;
