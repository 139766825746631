import { authSplitApi } from 'redux/helpers/slice.helpers';
import {
  ETagType,
  TTagDB,
  TTagUI,
  TTagsSliceProps,
} from 'data/types/tags.types';
import { getTagsListUrl } from 'data/urls/tags.url';
import {
  tagsEditConverter,
  tagsGetConverter,
} from 'data/convertors/tags.convertor';

export const tagsApi = authSplitApi('tags', ['tags']).injectEndpoints({
  endpoints: build => ({
    getTagsList: build.query<TTagUI[], ETagType>({
      query(tagType) {
        return {
          url: getTagsListUrl,
          method: 'GET',
          params: {
            type: tagType,
          },
        };
      },
      transformResponse: (tags: TTagDB[]) => tagsGetConverter.fromDb(tags),
      providesTags: ['tags'],
    }),
    editTags: build.mutation<void, TTagsSliceProps>({
      query(body) {
        return {
          url: `${getTagsListUrl}/${body.url}`,
          method: 'PATCH',
          body: tagsEditConverter.toDb(body),
        };
      },
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            arg.invalidateTagsConfig.forEach(({ invalidateTag, sliceName }) => {
              dispatch(sliceName.util.invalidateTags([invalidateTag]));
            });
          })
          .catch(() => {
            // do nothing
          });
      },
    }),
  }),
});

export const { useGetTagsListQuery, useEditTagsMutation } = tagsApi;
