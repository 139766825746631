import { FC } from 'react';
import { Title, Row, useTranslation } from '@packages/utils';
import { TMainContactProps } from './MainContact.types';
import UpdateOrganizationName from './components/UpdateOrganizationName';
import UpdateOrganizationAddress from './components/UpdateOrganizationAddress';
import UpdateOrganizationEmail from './components/UpdateOrganizationEmail';
import UpdateOrganizationPhone from './components/UpdateOrganizationPhone';
import UpdateAdditionalOrgAddress from './components/UpdateAdditionalOrgAddress';

const MainContact: FC<TMainContactProps> = ({ detailsData }) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.organizations.details.details-tab.main-contact',
  });
  const {
    id,
    name,
    address,
    email,
    phone,
    isAddressUpdated,
    additionalAddress,
  } = detailsData;
  return (
    <>
      <Row className="w-100 mb-3">
        <Title level={4}>{t('LABEL')}</Title>
      </Row>
      <Row gutter={[0, 16]}>
        <UpdateOrganizationName id={id} name={name} />
        <UpdateOrganizationAddress
          id={id}
          address={address}
          isAddressUpdated={isAddressUpdated}
        />
        <UpdateAdditionalOrgAddress id={id} address={additionalAddress} />
        <UpdateOrganizationEmail id={id} email={email} />
        <UpdateOrganizationPhone id={id} phone={phone} />
      </Row>
    </>
  );
};

export default MainContact;
