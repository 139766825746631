import {
  useTranslation,
  useShowToastNotification,
  ENotificationType,
  TRtkErrorType,
} from '@packages/utils';
import EditableTextArea from 'components/Base/EditableFields/EditableTextArea';
import { useEditNoteMutation } from 'redux/apiSlice/elcs.slice';
import { TEditDetailActionProps } from './EditDetailAction.types';

const EditDetailAction = ({
  elcId,
  elcNoteId,
  isEditable,
  note,
}: TEditDetailActionProps) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.details-tab.details-section.action.edit',
  });
  const { showToastNotification } = useShowToastNotification();
  const [editDescription, { isLoading }] = useEditNoteMutation();

  const onDetailEdit = async (value: string) => {
    try {
      await editDescription({
        elcId,
        elcNoteId,
        note: value,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (err) {
      showToastNotification({
        message: (err as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  return (
    <EditableTextArea
      fitContent={false}
      wrapperClassName="pb-3 word-break"
      title={note}
      emptyText={t('EMPTY_TEXT')}
      onEdit={onDetailEdit}
      isLoading={isLoading}
      showCount
      maxLength={350}
      readonly={!isEditable}
      canRemove={false}
    />
  );
};

export default EditDetailAction;
