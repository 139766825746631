import { authSplitApi } from 'redux/helpers/slice.helpers';
import { IAmplifyUser } from 'data/types/amplify.types';
import { EUserUrls } from 'data/urls/user.url';
import {
  EUserType,
  TAddNewUserUI,
  TDeleteUserUI,
  TUpdateUserUI,
  TUserDataDB,
  TUserDataUi,
  TUserIdentityDB,
  TUserIdentityUI,
  TUserListDataDB,
  TUserListDataUI,
} from 'data/types/user.types';
import {
  addNewUserConverter,
  deleteUserConverter,
  getUserProfileConvertor,
  updateUserConverter,
  userIdentityGetConverter,
  userListGetConverter,
} from 'data/convertors/user.convertors';
import { pagableDataConverter } from 'data/convertors/general.converters';
import {
  TPageableDataWithContentDB,
  TPageableDataWithContentUI,
  TTableFilters,
} from 'data/types/generic.types';
import { peopleApi } from './people.slice'; // eslint-disable-line
import { elcsApi } from './elcs.slice';

const baseUrl = `${EUserUrls.BASE}`;

export const userGrowApi = authSplitApi('user', [
  'user',
  'user-identity',
  'user-identity-no-profile',
]).injectEndpoints({
  endpoints: build => ({
    getUserProfile: build.query<TUserDataUi, { amplifyUser: IAmplifyUser }>({
      query() {
        return {
          url: `${baseUrl}/${EUserUrls.PROFILE}`,
          method: 'GET',
        };
      },
      providesTags: ['user'],
      transformResponse: (data: TUserDataDB) => {
        if (!data) {
          return null;
        }

        return getUserProfileConvertor.fromDb(data);
      },
    }),
    userAcceptInvite: build.mutation<string, void>({
      query() {
        return {
          url: `${baseUrl}/${EUserUrls.ACCEPT_INVITE}`,
          method: 'PATCH',
          body: {},
        };
      },
    }),
    getUsersList: build.query<
      TPageableDataWithContentUI<TUserListDataUI[]>,
      TTableFilters
    >({
      query({ page, pageSize }) {
        return {
          url: `${baseUrl}`,
          method: 'GET',
          params: {
            page,
            size: pageSize,
          },
        };
      },
      providesTags: ['users'],
      transformResponse: (
        data: TPageableDataWithContentDB<TUserListDataDB[]>,
      ) => {
        return {
          ...pagableDataConverter.fromDb(data),
          content: (data.content || []).map(workshop =>
            userListGetConverter.fromDb(workshop),
          ),
        };
      },
    }),
    getUserIdentity: build.query<
      TUserIdentityUI[],
      { profileTypes?: EUserType }
    >({
      query({ profileTypes }) {
        return {
          url: `${baseUrl}/${EUserUrls.IDENTITY}`,
          method: 'GET',
          params: {
            ...(profileTypes && { profileTypes }),
          },
        };
      },
      transformResponse: (data: TUserIdentityDB[]) => {
        return data?.length
          ? data.map(item => userIdentityGetConverter.fromDb(item))
          : [];
      },
      providesTags: ['user-identity'],
    }),
    getUserIdentityNoProfile: build.query<
      TUserIdentityUI[],
      { profileType?: EUserType }
    >({
      query({ profileType }) {
        return {
          url: `${baseUrl}/${EUserUrls.IDENTITY}/${EUserUrls.NO_PROFILE}`,
          method: 'GET',
          params: {
            ...(profileType && { profileType }),
          },
        };
      },
      transformResponse: (data: TUserIdentityDB[]) => {
        return data?.length
          ? data.map(item => userIdentityGetConverter.fromDb(item))
          : [];
      },
      providesTags: ['user-identity-no-profile'],
    }),
    // addNewUser: build.mutation<string, TAddNewUserUI>({
    //   query(body) {
    //     return {
    //       url: `${baseUrl}/${EUserUrls.INVITE}`,
    //       method: 'POST',
    //       body: addNewUserConverter.toDb(body),
    //     };
    //   },
    //   invalidatesTags: ['users'],
    // }),
    addNewUser: build.mutation<string, TAddNewUserUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EUserUrls.TEAM}`,
          method: 'PATCH',
          body: addNewUserConverter.toDb(body),
        };
      },
      invalidatesTags: ['users', 'user-identity', 'user-identity-no-profile'],
    }),
    // deleteUser: build.mutation<void, TDeleteUserUI>({
    //   query(body) {
    //     return {
    //       url: `${baseUrl}`,
    //       method: 'DELETE',
    //       body: deleteUserConverter.toDb(body),
    //     };
    //   },
    //   invalidatesTags: ['users'],
    //   onQueryStarted(arg, { dispatch, queryFulfilled }) {
    //     queryFulfilled
    //       .then(() => {
    //         dispatch(
    //           peopleApi.util.invalidateTags(['peoples', 'site-coordinators']),
    //         );
    //         dispatch(elcsApi.util.invalidateTags(['contacts-identity']));
    //         dispatch(elcsApi.util.invalidateTags(['elac-contacts-identity']));
    //       })
    //       .catch(() => {
    //         // do nothing
    //       });
    //   },
    // }),
    deleteUser: build.mutation<void, TDeleteUserUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EUserUrls.TEAM}`,
          method: 'DELETE',
          body: deleteUserConverter.toDb(body),
        };
      },
      invalidatesTags: ['users', 'user-identity', 'user-identity-no-profile'],
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(
              peopleApi.util.invalidateTags(['peoples', 'site-coordinators']),
            );
            dispatch(elcsApi.util.invalidateTags(['contacts-identity']));
            dispatch(elcsApi.util.invalidateTags(['elac-contacts-identity']));
          })
          .catch(() => {
            // do nothing
          });
      },
    }),
    updateUser: build.mutation<string, TUpdateUserUI>({
      query(body) {
        return {
          url: `${baseUrl}`,
          method: 'PUT',
          body: updateUserConverter.toDb(body),
        };
      },

      invalidatesTags: ['users'],
    }),
  }),
});

export const {
  useGetUserProfileQuery,
  useDeleteUserMutation,
  useUserAcceptInviteMutation,
  useUpdateUserMutation,
  useGetUsersListQuery,
  useAddNewUserMutation,
  useGetUserIdentityQuery,
  useGetUserIdentityNoProfileQuery,
} = userGrowApi;
