import { useState } from 'react';
import { Button } from '@idaho-aeyc/ui-kit';
import { useTranslation } from '@packages/utils';
import { useGetElcNotesAvailableCategoriesQuery } from 'redux/apiSlice/elcs.slice';
import AddDetailsModal from './AddDetailsModal';
import { TAddDetailActionProps } from './AddDetailAction.types';

const AddDetailAction = ({ elcId }: TAddDetailActionProps) => {
  //   const theme = useTheme();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.details-tab.details-section',
  });

  const { data } = useGetElcNotesAvailableCategoriesQuery(
    { elcId },
    {
      skip: !elcId,
    },
  );

  const categories = data?.map(cat => ({
    label: t(`notes-categories.${cat}`),
    value: cat,
  }));

  return (
    <>
      <Button color="primary" type="primary" onClick={() => setOpen(true)}>
        {t('action.add.BUTTON')}
      </Button>
      {categories?.length && (
        <AddDetailsModal
          open={open}
          categories={categories}
          setOpen={setOpen}
          elcId={elcId}
        />
      )}
    </>
  );
};

export default AddDetailAction;
