import get from 'lodash/get';
import { useTranslation } from '@packages/utils';
import useForm from 'hooks/useForm';
import { Form, FormItem } from 'components/Base/Form';
import { PasswordInput } from 'components/Base/Input';
import { MessageLabel } from 'components/Base/Typography';
import {
  useShowToastNotification,
  ENotificationType,
} from 'components/Base/Notification';
import { useStateSafely } from 'hooks/useStateSafely';
import {
  currentAmplifyAuthUser,
  useChangePasswordMutation,
} from 'redux/apiSlice/auth.slice';
import useGetFormData from './ChangePassword.data';
import { TChangePasswordData } from './ChangePassword.types';
import { SButton, SChangePasswordWrapper } from './ChangePassword.styles';

const ChangePassword = () => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.authentication.changePassword',
  });
  const { showToastNotification } = useShowToastNotification();
  const { changePasswordFormData } = useGetFormData();
  const { form } = useForm<TChangePasswordData>();
  const [error, setError] = useStateSafely<string>('');
  const [changePassword, { isLoading }] = useChangePasswordMutation();

  const onSubmit = async () => {
    try {
      const values = await form.validateFields();
      const user = await currentAmplifyAuthUser();
      await changePassword({
        user,
        existing: values.currentPassword,
        password: values.password,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
      form.resetFields();
      setError('');
    } catch (err) {
      const message = get(err, ['data', 'message']);
      setError(message);
    }
  };

  const { currentPassword, password, passwordConfirm } = changePasswordFormData;
  return (
    <SChangePasswordWrapper>
      <Form
        form={form}
        name="login"
        layout="vertical"
        requiredMark={false}
        onFinish={onSubmit}
      >
        <FormItem {...currentPassword}>
          <PasswordInput placeholder={currentPassword.placeholder} />
        </FormItem>
        <FormItem {...password}>
          <PasswordInput placeholder={password.placeholder} />
        </FormItem>
        <FormItem dependencies={[password.name]} {...passwordConfirm}>
          <PasswordInput placeholder={passwordConfirm.placeholder} />
        </FormItem>
        <SButton
          color="primary"
          type="primary"
          htmlType="submit"
          className="w-100 mt-10 mb-2"
          loading={isLoading}
        >
          {t('SUBMIT_BTN')}
        </SButton>
        <MessageLabel type="danger">{error}</MessageLabel>
      </Form>
    </SChangePasswordWrapper>
  );
};

export default ChangePassword;
