import { FC, useState } from 'react';
import { get } from 'lodash';
import { useTranslation, Button } from '@packages/utils';
import { useTheme } from 'styled-components';
import { EIconNames, Icon } from 'components/Base/Icon';
import {
  useShowToastNotification,
  ENotificationType,
} from 'components/Base/Notification';
import { Modal } from 'components/Base/Modal';
import ConfirmModalBody from 'components/Shared/ConfirmModalBody';
import { useMoveActionMutation } from 'redux/apiSlice/registrants.slice';
import { TMoveActionProps } from './MoveAction.types';

const MoveAction: FC<TMoveActionProps> = ({
  registrantId,
  workshopClassId,
  workshopId,
}) => {
  const theme = useTheme();
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);

  const [moveAction, { isLoading }] = useMoveActionMutation();
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.workshops.details.workshop-wait-list.actions.move',
  });
  const { showToastNotification } = useShowToastNotification();
  const handleCLoseConfirmModal = () => {
    setOpenConfirmModal(false);
  };

  const handleSubmit = async () => {
    try {
      await moveAction({
        registrantId,
        workshopClassId,
        workshopId,
      }).unwrap();
      showToastNotification({
        message: t('modal.SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (e) {
      const message = get(e, 'data.message');
      showToastNotification({
        message,
        type: ENotificationType.ERROR,
      });
    }
    handleCLoseConfirmModal();
  };

  return (
    <>
      <Button
        icon={
          <Icon
            alt="move"
            icon={EIconNames.RIGHT_ARROW}
            size={14}
            className="pointer"
            color={theme.colorErrorDefault}
          />
        }
        onClick={() => setOpenConfirmModal(true)}
        tooltip={t('MOVE_TOOLTIP')}
        color="primary"
        shape="circle"
        size="middle"
      />

      {openConfirmModal && (
        <Modal
          title={t('modal.TITLE')}
          open={openConfirmModal}
          onCancel={handleCLoseConfirmModal}
          onOk={handleSubmit}
          okText={t('modal.SUBMIT_BTN')}
          cancelText={t('modal.CANCEL_BTN')}
          confirmLoading={isLoading}
        >
          <ConfirmModalBody description={t('modal.DESCRIPTION')} />
        </Modal>
      )}
    </>
  );
};

export default MoveAction;
