import * as React from 'react';
import { FC } from 'react';
import { useAppSelector } from 'redux/store';
import { useTranslation } from '@packages/utils';
import { useTheme } from 'styled-components';
import {
  EWorkshopCategory,
  EWorkshopSeriesType,
} from 'data/types/workshops.types';
import { Row } from 'components/Base/Grid';
import { Title } from 'components/Base/Typography';
import SeriesPart from './SeriesPart';
// import StandalonePart from './StandalonePart';
import { TSessionsSectionProps } from './SessionsSection.types';
import { flyerSessionsSectionKeyPrefix } from './SeriesPart/Series.data';
import StandalonePart from './StandalonePart';

export const SessionsSection: FC<TSessionsSectionProps> = ({
  data,
  typeCategory,
  isChildCareAndPreschool,
  type,
}) => {
  const theme = useTheme();
  const flyerLanguage = useAppSelector(state => state.appSlice.flyerLanguage);
  const { t } = useTranslation({
    keyPrefix: flyerSessionsSectionKeyPrefix,
    ...(flyerLanguage && { lng: flyerLanguage }),
  });
  const isSeries = typeCategory !== EWorkshopCategory.STANDALONE;

  const hideTitle =
    isSeries || type === EWorkshopSeriesType.READY_FOR_KINDERGARTEN;

  const sectionTitle = hideTitle ? null : (
    <Row className="mb-2">
      <Title level={1} color={theme.colorGreenFlyer}>
        {t('TITLE')}
      </Title>
    </Row>
  );
  return (
    <>
      {sectionTitle}
      <Row
        gutter={[16, 16]}
        wrap={false}
        style={{ width: isSeries ? '' : 'max-content' }}
      >
        {isSeries ? (
          <SeriesPart
            parts={data.parts}
            isChildCareAndPreschool={isChildCareAndPreschool}
          />
        ) : (
          <StandalonePart
            standaloneData={data.existingAgeGroups}
            date={data.parts[0].dateTime?.value}
          />
        )}

        {/* TODO Nona remove maybe */}
      </Row>
    </>
  );
};
