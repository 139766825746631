import styled from 'styled-components';
import { Tabs } from 'components/Base/Tabs';

export const STabs = styled(Tabs)`
  &.ant-tabs {
    .ant-tabs-content-holder {
      padding: 0;
    }
  }
`;
