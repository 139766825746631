import styled from 'styled-components';
import { Card, Row } from '@packages/utils';

export const SDetailsListItemWrapper = styled(Card)`
  .actions-section {
    display: none;
  }
  &:hover {
    background: #f2f2f2;
    .actions-section {
      display: flex;
    }
  }
`;

export const SNotesHeader = styled(Row)`
  min-height: 32px;
  margin-bottom: 12px;
`;
