import {
  TAuthConverter,
  TCreateConverter,
  TFetchConverter,
} from 'data/types/converter.types';
import {
  EOrganizationFilterParams,
  TCreateOrganizationDB,
  TCreateOrganizationUI,
  TDeleteOrganizationDB,
  TDeleteOrganizationUI,
  TOrganizationByIdUI,
  TOrganizationByIdDB,
  TOrganizationDataDB,
  TOrganizationDataUI,
  TOrganizationsIdentityDB,
  TOrganizationsIdentityUI,
  TUpdateOrganizationDB,
  TUpdateOrganizationUI,
  TUpdateOrganizationFieldUI,
  TUpdateOrganizationNameDB,
  TUpdateOrganizationAddressUI,
  TUpdateOrganizationAddressDB,
  TUpdateOrganizationEmailDB,
  TUpdateOrganizationPhoneDB,
  TCreateOrganizationNoteDB,
  TCreateOrganizationNoteUI,
  TOrganizationNotesUI,
  TOrganizationNotesDB,
  TUpdateOrganizationNoteUI,
  TUpdateOrganizationNoteDB,
  TDeleteOrganizationNoteUI,
  TDeleteOrganizationNoteDB,
  TAddAdditionalEmailUI,
  TAddAdditionalEmailDB,
  TAddAdditionalPhoneUI,
  TAddAdditionalPhoneDB,
  TAdditionalEmailsUI,
  TAdditionalEmailsDB,
  TAdditionalPhoneUI,
  TAdditionalPhoneDB,
  TPeopleOrganizationsUI,
  TPeopleOrganizationsDB,
  TUpdateAdditionalPhoneUI,
  TUpdateAdditionalPhoneDB,
  TUpdateAdditionalEmailUI,
  TUpdateAdditionalEmailDB,
  TUpdateOrganizationAdditionalAddressUI,
  TUpdateOrganizationAdditionalAddressDB,
} from 'data/types/organizations.types';
import {
  TAllowedOperationsValueDB,
  TAllowedOperationsValueUI,
  TTableFilters,
} from 'data/types/generic.types';
import { dateConverter, dateShortenFormat } from 'helpers/utils';
import {
  addressConvertor,
  allowOperationsShortAddressWithConverter,
  allowOperationsWithPhoneNumberConverter,
  allowOperationsWithStringValueConverter,
  addressShortDataConverter,
  phoneNumberConverter,
  allowOperationsConverter,
} from './general.converters';
import { tagsGetConverter } from './tags.convertor';

export const getOrganizationsIdentityConvertor: TFetchConverter<
  TOrganizationsIdentityUI,
  TOrganizationsIdentityDB
> = {
  fromDb: data => ({
    value: data.id,
    label: data.name,
  }),
};

export const deleteOrganizationConverter: TCreateConverter<
  TDeleteOrganizationUI,
  TDeleteOrganizationDB
> = {
  toDb: data => ({
    organizationId: data.organizationId,
  }),
};

export const createOrganizationConvertor: TAuthConverter<
  TCreateOrganizationUI,
  TCreateOrganizationUI,
  TCreateOrganizationDB,
  TCreateOrganizationDB
> = {
  fromDb: data => ({ id: data.id }),
  toDb: data => {
    return {
      name: data.name,
      address: addressConvertor.toDb(data.address),
    };
  },
};

export const createOrganizationNoteConvertor: TCreateConverter<
  TCreateOrganizationNoteUI,
  TCreateOrganizationNoteDB
> = {
  toDb: data => ({
    organizationId: data.organizationId,
    category: data.category,
    note: data.note,
  }),
};

export const updateOrganizationNoteConvertor: TCreateConverter<
  TUpdateOrganizationNoteUI,
  TUpdateOrganizationNoteDB
> = {
  toDb: data => ({
    organizationId: data.organizationId,
    organizationNoteId: data.organizationNoteId,
    note: data.note,
  }),
};

export const deleteOrganizationNoteConvertor: TCreateConverter<
  TDeleteOrganizationNoteUI,
  TDeleteOrganizationNoteDB
> = {
  toDb: data => ({
    organizationId: data.organizationId,
    organizationNoteId: data.organizationNoteId,
  }),
};

export const updateOrganizationConverter: TCreateConverter<
  TUpdateOrganizationUI,
  TUpdateOrganizationDB
> = {
  toDb: data => ({
    organizationId: data.organizationId,
    name: data.name,
    address: addressConvertor.toDb(data.address),
  }),
};

export const organizationFilterConverter = (
  filters: TTableFilters<EOrganizationFilterParams>['filters'],
) => {
  const filtersToDB = {
    ...(filters?.cities && {
      [EOrganizationFilterParams.CITIES]: filters?.cities.map(city => city),
    }),
    ...(filters?.nameContains && {
      [EOrganizationFilterParams.NAME_CONTAINS]: filters?.nameContains.map(
        name => name,
      ),
    }),
    ...(filters?.tagIds && {
      [EOrganizationFilterParams.TAGS]: filters?.tagIds,
    }),
  };
  return filtersToDB;
};

export const organizationGetConverter: TFetchConverter<
  TOrganizationDataUI,
  TOrganizationDataDB
> = {
  fromDb: data => ({
    organizationId: data.organizationId,
    createdDate:
      data.createdDate && dateConverter(data.createdDate, dateShortenFormat),
    name: data.name,
    address: addressShortDataConverter.fromDb(data.address),
    tags: tagsGetConverter.fromDb(data.tags),
  }),
};

export const notesConverter: TFetchConverter<
  TOrganizationNotesUI,
  TOrganizationNotesDB
> = {
  fromDb: data => {
    return {
      category: data.category,
      note: data.note,
      id: data.id,
    };
  },
};

export const allowOperationsForNotesConverter: TFetchConverter<
  TAllowedOperationsValueUI<TOrganizationNotesUI[]>,
  TAllowedOperationsValueDB<TOrganizationNotesDB[]>
> = {
  fromDb: data => {
    return {
      value:
        data.value && (data.value || []).map(el => notesConverter.fromDb(el)),
      allowedOperations: allowOperationsConverter.fromDb(
        data.allowedOperations,
      ),
    };
  },
};

export const allowOperationsForAdditionalEmailValue: TFetchConverter<
  TAllowedOperationsValueUI<TAdditionalEmailsUI[]>,
  TAllowedOperationsValueDB<TAdditionalEmailsDB[]>
> = {
  fromDb: data => {
    return {
      value: data.value,
      allowedOperations: allowOperationsConverter.fromDb(
        data.allowedOperations,
      ),
    };
  },
};

export const allowAdditionalPhoneValue: TFetchConverter<
  TAdditionalPhoneUI,
  TAdditionalPhoneDB
> = {
  fromDb: data => {
    return {
      additionalPhoneId: data.additionalPhoneId,
      phone: phoneNumberConverter.fromDb(data.phone),
    };
  },
};

export const allowOperationsForAdditionalPhoneValue: TFetchConverter<
  TAllowedOperationsValueUI<TAdditionalPhoneUI[]>,
  TAllowedOperationsValueDB<TAdditionalPhoneDB[]>
> = {
  fromDb: data => {
    return {
      value: data.value?.map(item => allowAdditionalPhoneValue.fromDb(item)),
      allowedOperations: allowOperationsConverter.fromDb(
        data.allowedOperations,
      ),
    };
  },
};

export const peopleOrganizationsValues: TFetchConverter<
  TPeopleOrganizationsUI,
  TPeopleOrganizationsDB
> = {
  fromDb: data => {
    return {
      fullName: data.fullName,
      userId: data.userId,
      userOrganizationId: data.userOrganizationId,
      title: allowOperationsWithStringValueConverter.fromDb(data.title),
    };
  },
};

export const allowOperationsForPeopleOrganizationsValue: TFetchConverter<
  TAllowedOperationsValueUI<TPeopleOrganizationsUI[]>,
  TAllowedOperationsValueDB<TPeopleOrganizationsDB[]>
> = {
  fromDb: data => {
    return {
      value: data.value?.map(item => peopleOrganizationsValues.fromDb(item)),
      allowedOperations: allowOperationsConverter.fromDb(
        data.allowedOperations,
      ),
    };
  },
};

export const getOrganizationByIdConverter: TFetchConverter<
  TOrganizationByIdUI,
  TOrganizationByIdDB
> = {
  fromDb: data => ({
    id: data.id,
    additionalEmails:
      data.additionalEmails &&
      allowOperationsForAdditionalEmailValue.fromDb(data.additionalEmails),
    additionalPhones:
      data.additionalPhones &&
      allowOperationsForAdditionalPhoneValue.fromDb(data.additionalPhones),
    address:
      data.address &&
      allowOperationsShortAddressWithConverter.fromDb(data.address),
    additionalAddress:
      data.address &&
      allowOperationsWithStringValueConverter.fromDb(data.additionalAddress),
    isAddressUpdated: data.isAddressUpdated,
    email:
      data.email && allowOperationsWithStringValueConverter.fromDb(data.email),
    name:
      data.name && allowOperationsWithStringValueConverter.fromDb(data.name),
    phone:
      data.phone && allowOperationsWithPhoneNumberConverter.fromDb(data.phone),
    organizationNotes:
      data.organizationNotes &&
      allowOperationsForNotesConverter.fromDb(data.organizationNotes),
    allowedDelete: data.allowedDelete,
    userOrganizations:
      data.userOrganizations &&
      allowOperationsForPeopleOrganizationsValue.fromDb(data.userOrganizations),
  }),
};

export const updateOrganizationAddressConverter: TCreateConverter<
  TUpdateOrganizationAddressUI,
  TUpdateOrganizationAddressDB
> = {
  toDb: data => ({
    organizationId: data.id,
    address: addressConvertor.toDb(data.address),
  }),
};

export const updateOrganizationAdditionalAddressConverter: TCreateConverter<
  TUpdateOrganizationAdditionalAddressUI,
  TUpdateOrganizationAdditionalAddressDB
> = {
  toDb: data => ({
    organizationId: data.id,
    additionalAddress: data.additionalAddress || null,
  }),
};

export const updateOrganizationNameConverter: TCreateConverter<
  TUpdateOrganizationFieldUI,
  TUpdateOrganizationNameDB
> = {
  toDb: data => ({
    organizationId: data.id,
    name: data.value,
  }),
};
export const updateOrganizationEmailConverter: TCreateConverter<
  TUpdateOrganizationFieldUI,
  TUpdateOrganizationEmailDB
> = {
  toDb: data => ({
    organizationId: data.id,
    email: data.value || null,
  }),
};

export const updateOrganizationPhoneConverter: TCreateConverter<
  TUpdateOrganizationFieldUI,
  TUpdateOrganizationPhoneDB
> = {
  toDb: data => ({
    organizationId: data.id,
    phone: data.value && phoneNumberConverter.toDb(data.value),
  }),
};

export const addAdditionalEmailConverter: TCreateConverter<
  TAddAdditionalEmailUI,
  TAddAdditionalEmailDB
> = {
  toDb: data => ({
    organizationId: data.organizationId,
    email: data.email,
  }),
};

export const addAdditionalPhoneConverter: TCreateConverter<
  TAddAdditionalPhoneUI,
  TAddAdditionalPhoneDB
> = {
  toDb: data => ({
    organizationId: data.organizationId,
    phone: phoneNumberConverter.toDb(data.phone) || '',
  }),
};

export const updateAdditionalPhoneConverter: TCreateConverter<
  TUpdateAdditionalPhoneUI,
  TUpdateAdditionalPhoneDB
> = {
  toDb: data => ({
    phone: phoneNumberConverter.toDb(data.phone) || '',
    organizationId: data.organizationId,
    additionalPhoneId: data.additionalPhoneId,
  }),
};

export const updateAdditionalEmailConverter: TCreateConverter<
  TUpdateAdditionalEmailUI,
  TUpdateAdditionalEmailDB
> = {
  toDb: data => ({
    email: data.email || '',
    organizationId: data.organizationId,
    additionalEmailId: data.additionalEmailId,
  }),
};
