import { useTranslation } from '@packages/utils';
import useGetValidationData from 'components/Base/Form/validationRules';
import { EWorkshopLanguage } from 'data/types/generic.types';

const useGetData = () => {
  const { t } = useTranslation();
  const { validationRules } = useGetValidationData();
  const sessionFormFields = {
    age: {
      name: 'age',
      label: t(
        'descriptions.admin-portal.workshops.details.sessions.create-form.age.LABEL',
      ),
      placeholder: t(
        'descriptions.admin-portal.workshops.details.sessions.create-form.age.PLACEHOLDER',
      ),
      rules: [validationRules.requiredField()],
    },
    language: {
      name: 'language',
      label: t(
        'descriptions.admin-portal.workshops.details.sessions.create-form.language.LABEL',
      ),
      placeholder: t(
        'descriptions.admin-portal.workshops.details.sessions.create-form.language.PLACEHOLDER',
      ),
      rules: [validationRules.requiredField()],
    },
    capacity: {
      name: 'capacity',
      label: t(
        'descriptions.admin-portal.workshops.details.sessions.create-form.capacity.LABEL',
      ),
      placeholder: t(
        'descriptions.admin-portal.workshops.details.sessions.create-form.capacity.PLACEHOLDER',
      ),
      rules: [validationRules.positiveNumbers()],
    },
    facilitator: {
      name: 'facilitator',
      label: t(
        'descriptions.admin-portal.workshops.details.sessions.create-form.facilitator.LABEL',
      ),
      placeholder: t(
        'descriptions.admin-portal.workshops.details.sessions.create-form.facilitator.PLACEHOLDER',
      ),
    },
  };

  const languageOptions = Object.values(EWorkshopLanguage).map(el => ({
    label: t(`enums.language.${el}`),
    value: el,
  }));
  return { sessionFormFields, languageOptions };
};

export default useGetData;
