import html2canvas from 'html2canvas';
import { jsPDF as JSPDF } from 'jspdf';

export const useDownloadPdfFromComponent = () => {
  const downloadPdf = async (
    ref: React.RefObject<HTMLElement>,
    fileName?: string,
  ) => {
    const element = ref.current;
    if (!element) return;
    const canvas = await html2canvas(element, { scale: 1.5 });
    const data = canvas.toDataURL('image/png');

    const pdf = new JSPDF('portrait', 'in', [8.5, 11]);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
    pdf.save(`${fileName || 'flyer'}.pdf`);
  };

  return downloadPdf;
};
