import { FC, useState } from 'react';
import DeleteSessionModal from './DeleteSessionModal';
import { TDeleteActionProps } from '../Actions.types';
import ActionButton from '../ActionButton';

const DeleteAction: FC<TDeleteActionProps> = ({ workshopId, classItem }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <ActionButton type="delete" onClick={() => setOpen(true)} />
      <DeleteSessionModal
        setOpen={setOpen}
        open={open}
        workshopId={workshopId}
        classId={classItem.id}
      />
    </>
  );
};

export default DeleteAction;
