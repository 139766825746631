import { Title, useTranslation, Row } from '@packages/utils';
import { TMainContactProps } from './MainContact.types';
import FirstName from './components/FirstName';
import LastName from './components/LastName';
import PeopleTitle from './components/PeopleTitle';
import PeopleEmail from './components/PeopleEmail';
import PeopleMobile from './components/PeopleMobile';

const MainContact = ({ data }: TMainContactProps) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.people.details.details-tab.main-contact',
  });
  const { firstName, lastName, title, email, phone, id } = data;
  return (
    <>
      <Row className="w-100 mb-3">
        <Title level={4}>{t('LABEL')}</Title>
      </Row>
      <FirstName data={firstName} userId={id} />
      <LastName data={lastName} userId={id} />
      <PeopleTitle data={title} userId={id} />
      <PeopleEmail data={email} userId={id} />
      <PeopleMobile data={phone} userId={id} />
    </>
  );
};

export default MainContact;
