import { useTranslation } from '@packages/utils';
import { Icon, EIconNames } from 'components/Base/Icon';
import { Tooltip } from 'components/Base/Tooltip';
import { tagModalKeyPrefix } from '../../untils';

const AddEditTagModalTitle = () => {
  const { t } = useTranslation({
    keyPrefix: tagModalKeyPrefix,
  });
  return (
    <>
      {t('TITLE')}
      <Tooltip title={t('TAG_TOOLTIP')}>
        <Icon className="pointer ml-2" alt="info icon" icon={EIconNames.INFO} />
      </Tooltip>
    </>
  );
};

export default AddEditTagModalTitle;
