import { useTranslation } from '@packages/utils';
import { TEditableTextAreaProps } from './EditableTextArea.types';
import { SEditableTextArea } from './EditableTextArea.styles';

const EditableTextArea = ({ ...props }: TEditableTextAreaProps) => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.generic-components.editable',
  });
  const { goBackTooltip = t('GO_BACK_LABEL'), ...restProps } = props;
  return <SEditableTextArea goBackTooltip={goBackTooltip} {...restProps} />;
};

export default EditableTextArea;
