import { FC } from 'react';
import { TActionProps } from 'pages/private/KitManagement/components/KitManagementActions/ExportAction/ExportActions.types';
import KitsMarkAsOrderedAction from 'pages/private/KitManagement/components/KitManagementActions/MarkAsOrderedAction';

const WorkshopMarkAsOrdered: FC<TActionProps> = ({
  setShowAlert,
  selectedRows,
}) => {
  const orderIds = selectedRows?.map(row => row.key) || [];

  return (
    <KitsMarkAsOrderedAction
      setShowAlert={setShowAlert}
      partOrderIds={orderIds as string[]}
      selectedRows={selectedRows}
    />
  );
};

export default WorkshopMarkAsOrdered;
