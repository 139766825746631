import { useTranslation } from '@packages/utils';
import { RoutesPaths } from 'routes/Routes.types';
import { useCheckPermission } from 'hooks/useCheckPermission';
import ELCDetail from '../ELCDetail';

const AllELCDetail = () => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.elcs.list.all-elcs',
  });
  const isEditable = useCheckPermission('ELC_DETAILS_VIEW');

  return (
    <ELCDetail
      isDetailsEditable={!isEditable}
      backUrl={`/app/${RoutesPaths.ELCS}`}
      backButtonSuffix={t('TITLE')}
    />
  );
};

export default AllELCDetail;
