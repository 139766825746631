import { useState } from 'react';
import { Switch } from 'components/Base/Switch';
import { useTranslation } from '@packages/utils';
import {
  usePublishLightBoxMutation,
  useUnpublishLightBoxMutation,
} from 'redux/apiSlice/lightbox.slice';
import {
  ENotificationType,
  useShowToastNotification,
} from 'components/Base/Notification';
import { TRtkErrorType } from 'data/types/generic.types';
import { TPublishToggleProps } from './PublishToggle.types';

const PublishToggle = ({ lightboxId, published }: TPublishToggleProps) => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.lightBox.actions',
  });

  const [isPublished, setIsPublished] = useState<boolean>(published);
  const [publishLightBox, { isLoading: publishIsLoading }] =
    usePublishLightBoxMutation();
  const [unpublishLightBox, { isLoading: unpublishIsLoading }] =
    useUnpublishLightBoxMutation();
  const { showToastNotification } = useShowToastNotification();

  const handleSwitchChange = async (isEnabled: boolean) => {
    setIsPublished(isEnabled);
    try {
      if (isEnabled) {
        await publishLightBox({ lightboxId }).unwrap();
      } else {
        await unpublishLightBox({ lightboxId }).unwrap();
      }
      showToastNotification({
        message: isEnabled
          ? t('publish.SUCCESS_MESSAGE')
          : t('unpublish.SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (err) {
      showToastNotification({
        message: (err as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  return (
    <Switch
      checkedChildren={t('publish.LABEL')}
      unCheckedChildren={t('unpublish.LABEL')}
      onChange={handleSwitchChange}
      checked={isPublished}
      loading={publishIsLoading || unpublishIsLoading}
    />
  );
};

export default PublishToggle;
