import { FC } from 'react';
import { useTranslation, Card } from '@packages/utils';
import { useTheme } from 'styled-components';
import { Col, Row } from 'components/Base/Grid';
import { Counter } from 'components/Base/Counter';
import { Divider } from 'components/Base/Divider';
import { Select } from 'components/Base/Select';
import { EAttendanceStatus } from 'data/types/registrants.types';
import { TUpdateAttendanceItemProps } from './UpdateAttendanceItem.types';
import { FirstCol, SecondCol } from '../UpdateAttendancesModal.styles';
import AttendanceText from '../../components/AttendanceText';

const UpdateAttendanceItem: FC<TUpdateAttendanceItemProps> = ({
  attendance,
  onSelect,
  onKitCountChange,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    partType,
    isUpdatable,
    kitCount,
    attendanceStatus,
    sessionAttendanceId,
  } = attendance;

  if (!isUpdatable) {
    return null;
  }
  return (
    <Col className="w-100 px-0">
      <Card
        styles={{
          body: {
            padding: '4px 12px',
          },
        }}
        className="mb-2"
      >
        <Row wrap={false} align="middle">
          <FirstCol>
            <AttendanceText
              text={t(`enums.workshop-part-type.${partType}`)}
              color={theme.colorPurpleDefault}
            />
          </FirstCol>
          <Divider type="vertical" thickness={3} height={30} />
          <SecondCol>
            <Select
              options={Object.values(EAttendanceStatus).map(status => ({
                label: t(`enums.attendance-status.${status}`),
                value: status,
              }))}
              className="w-100"
              placeholder={t(
                'descriptions.admin-portal.workshops.details.workshop-registrants.actions.edit.modal.attendance-chart.attendance-input.PLACEHOLDER',
              )}
              placement="topLeft"
              value={attendanceStatus}
              onSelect={value => {
                onSelect(sessionAttendanceId, value);
              }}
            />
          </SecondCol>
          <Divider type="vertical" thickness={3} height={30} />
          <Col>
            <Counter
              defaultValue={kitCount || 0}
              disabled={attendanceStatus === EAttendanceStatus.ABSENT}
              onChange={value => {
                onKitCountChange(sessionAttendanceId, value);
              }}
              minValue={0}
              wrapperPadding="6px 16px"
              fullWidth
            />
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

export default UpdateAttendanceItem;
