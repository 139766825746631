import { useEffect, useState } from 'react';
import { Button, Collapse, useTranslation } from '@packages/utils';
import { EIconNames, Icon } from 'components/Base/Icon';
import { useGetElcMeetingTopicQuery } from 'redux/apiSlice/elcs.slice';
import AddTopicForm from './AddTopic';
import { TTopicsTabProps } from './TopicsTab.types';
import EditTopic from './EditTopic';
import RemoveTopic from './RemoveTopic';

const TopicsTab = ({ meetingId, elcId, isDrawerOpen }: TTopicsTabProps) => {
  const [activeKeys, setActiveKeys] = useState<string | string[]>([]);
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.meetings-tab.details.topics.add',
  });
  const { data } = useGetElcMeetingTopicQuery(
    {
      meetingId,
    },
    {
      skip: !meetingId,
    },
  );
  const [hideForm, setHideForm] = useState<boolean>(true);

  useEffect(() => {
    if (!data) {
      return;
    }
    if (data.length > 0) {
      setHideForm(true);
    } else {
      setHideForm(false);
    }
  }, [data]);

  if (!data) {
    return <></>;
  }

  const onCancelForm = () => {
    if (data.length > 0) {
      setHideForm(true);
    }
  };

  const items = data.map(item => {
    return {
      key: item.topicId,
      label: item.topicTitle,
      children: (
        <EditTopic elcId={elcId} meetingId={item.meetingId} data={item} />
      ),
      extra: (
        <RemoveTopic
          elcId={elcId}
          meetingId={item.meetingId}
          topicId={item.topicId}
        />
      ),
    };
  });

  const handleAddTopic = () => {
    setHideForm(false);
    setActiveKeys([]);
  };

  return (
    <>
      {hideForm ? (
        <Button
          color="primary"
          type="link"
          className="pl-0 mb-4"
          onClick={handleAddTopic}
          icon={<Icon icon={EIconNames.PLUS} className="pointer" />}
        >
          {t('ADD_BTN')}
        </Button>
      ) : (
        <AddTopicForm
          isDrawerOpen={isDrawerOpen}
          meetingId={meetingId}
          elcId={elcId}
          onCancelForm={onCancelForm}
        />
      )}
      <Collapse
        accordion
        items={items}
        activeKey={activeKeys}
        onChange={keys => setActiveKeys(keys)}
      />
    </>
  );
};

export default TopicsTab;
