import { FC } from 'react';
import { useTheme } from 'styled-components';
import { ButtonDropdown } from 'components/Base/ButtonDropdown';
import { TextIcon } from 'components/Base/TextIcon';
import { useTranslation } from '@packages/utils';
import { EIconNames } from 'components/Base/Icon';
import { TActionsButtonDetailsPageProps } from './ActionsButtonDetailsPage.types';

const ActionsButtonDetailsPage: FC<TActionsButtonDetailsPageProps> = ({
  items,
}) => {
  const theme = useTheme();
  const { t } = useTranslation({
    keyPrefix: 'descriptions.button',
  });

  return (
    <ButtonDropdown
      buttonProps={{
        children: (
          <TextIcon
            label={t('ACTIONS')}
            alt="arrow icon"
            revert
            icon={EIconNames.ARROW_FILLED_DOWN}
            iconColor={theme.colorBlackDescription}
            color={theme.colorBlackPrimary}
            size={14}
          />
        ),
        color: 'primary',
      }}
      dropdownProps={{
        menu: {
          items,
        },
        rootClassName: 'button-dropdown',
        placement: 'bottomRight',
      }}
    />
  );
};

export default ActionsButtonDetailsPage;
