import { FC, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';
import { Modal } from 'components/Base/Modal';
import useForm from 'hooks/useForm';
import {
  EditChildcare,
  TChildcareItem,
  useTranslation,
  Button,
} from '@packages/utils';
import {
  ENotificationType,
  useShowToastNotification,
} from 'components/Base/Notification';
import { Icon, EIconNames } from 'components/Base/Icon';
import {
  useEditChildcareMutation,
  useGetRegistrantsNoChildcareQuery,
} from 'redux/apiSlice/registrants.slice';
import { TValidateFieldsError } from 'data/types/generic.types';
import { get } from 'lodash';
import { Form, FormItem } from 'components/Base/Form';
import { Select } from 'components/Base/Select';
import useGetValidationData from 'components/Base/Form/validationRules';
import { TAddChildcareRequestProps } from './AddChildcareRequest.types';
import { childCareActionsKeyPrefix } from '../../ChildCareTab.data';

const AddChildcareRequest: FC<TAddChildcareRequestProps> = ({ workshopId }) => {
  const theme = useTheme();
  const { form } = useForm<{
    childcareRequirements: TChildcareItem[];
    registrantId: string;
  }>();
  const { showToastNotification } = useShowToastNotification();
  const { validationRules } = useGetValidationData();
  const { t } = useTranslation({
    keyPrefix: childCareActionsKeyPrefix.concat('.add'),
  });
  const { t: tValidation } = useTranslation({
    keyPrefix: 'descriptions.validation-errors',
  });
  const [editChildcare, { isLoading }] = useEditChildcareMutation();

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    form.resetFields();
    setOpen(false);
  };

  const handleOpen = () => setOpen(true);

  const { data: registrantsData, isLoading: getRegistrantsLoading } =
    useGetRegistrantsNoChildcareQuery(
      {
        workshopId,
      },
      {
        skip: !workshopId || !open,
      },
    );

  const registrantsOptions = useMemo(() => {
    return (registrantsData || []).map(item => ({
      label: item.fullName,
      value: item.registrantId,
    }));
  }, [registrantsData]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      if (values.childcareRequirements.every(item => !item.checked)) {
        form.setFields([
          {
            name: 'childcareRequirements',
            errors: [tValidation('THIS_FIELD_IS_REQUIRED')],
          },
        ]);
        return;
      }

      const workshopClassId = registrantsData?.find(
        item => item.registrantId === values.registrantId,
      )?.workshopClassId;

      if (!workshopClassId) {
        return;
      }
      await editChildcare({
        childcareRequirements: values.childcareRequirements,
        registrantId: values.registrantId,
        workshopClassId,
        workshopId,
      }).unwrap();
      showToastNotification({
        message: t('modal.SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
      handleClose();
    } catch (error) {
      if (!(error as TValidateFieldsError)?.errorFields) {
        const message = get(error, 'data.message');
        showToastNotification({
          message,
          type: ENotificationType.ERROR,
        });
      }
    }
  };

  return (
    <>
      <Button
        color="primary"
        type="primary"
        onClick={handleOpen}
        icon={<Icon icon={EIconNames.PLUS} color={theme.colorWhitePrimary} />}
      >
        {t('ADD_BTN')}
      </Button>
      {open && (
        <Modal
          title={t('modal.TITLE')}
          open={open}
          onCancel={handleClose}
          onOk={handleSubmit}
          okText={t('modal.SUBMIT_BTN')}
          cancelText={t('modal.CANCEL_BTN')}
          confirmLoading={isLoading}
        >
          <Form form={form}>
            <FormItem
              label={t('form.registrant.LABEL')}
              name="registrantId"
              rules={[validationRules.requiredField()]}
            >
              <Select
                options={registrantsOptions}
                loading={getRegistrantsLoading}
                placeholder={t('form.registrant.PLACEHOLDER')}
              />
            </FormItem>

            <FormItem name="childcareRequirements">
              <EditChildcare />
            </FormItem>
          </Form>
        </Modal>
      )}
    </>
  );
};

export default AddChildcareRequest;
