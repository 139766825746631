import React from 'react';
import { useTranslation } from '@packages/utils';
import { useTheme } from 'styled-components';
import { Row, Col } from 'components/Base/Grid';
import { Text } from 'components/Base/Typography';
import { useAppSelector } from 'redux/store';
import { TAgeWrapper } from './AgeGroup.styles';
import { TAgeGroupProps } from './AgeGroup.types';
import { flyerAgeGroupKeyPrefix } from './AgeGroup.data';
import { SInfoItemWrapper } from '../Flyers.styles';

const AgeGroup = ({ existingAgeGroups }: TAgeGroupProps) => {
  const theme = useTheme();
  const flyerLanguage = useAppSelector(state => state.appSlice.flyerLanguage);
  const { t } = useTranslation({
    ...(flyerLanguage && { lng: flyerLanguage }),
  });

  return (
    <SInfoItemWrapper>
      <Row wrap={false} align="middle" justify="start" gutter={[6, 0]}>
        <Col>
          <Text
            fontSize={theme.fontSizeHeading2}
            fontWeight={theme.fontWeightHeavy}
            color={theme.colorGreenFlyer}
          >
            {t(`${flyerAgeGroupKeyPrefix}.TITLE`)}
          </Text>
        </Col>
        <Col>
          <TAgeWrapper
            color={theme.colorBlackDescription}
            fontWeight={theme.fontWeightRegular}
            fontSize={theme.fontSizeHeading2}
          >
            {existingAgeGroups.map((age, index) => (
              <React.Fragment key={age}>
                {index > 0 && <span> | </span>}
                {t(`enums.age-group.${age}`)}
              </React.Fragment>
            ))}
          </TAgeWrapper>
        </Col>
      </Row>
    </SInfoItemWrapper>
  );
};

export default AgeGroup;
