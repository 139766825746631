import { useTheme } from 'styled-components';
import {
  Col,
  ENotificationType,
  Row,
  TRtkErrorType,
  Tag,
  Text,
  useShowToastNotification,
  useTranslation,
} from '@packages/utils';
import EditableSelect from 'components/Base/EditableFields/EditableSelect';
import { InplaceEditLabels } from 'components/Base/EditableFields/components/InplaceEditLabel';
import { EIconNames } from 'components/Base/Icon';
import ElcItemPopover from 'pages/private/ELCs/components/ElcPopover';
import {
  useEditFiscalAgentMutation,
  useGetAllContactsQuery,
} from 'redux/apiSlice/elcs.slice';
import { avatarColorMapper } from 'pages/private/ELCs/ELCs.data';
import { TFiscalAgentProps } from './FiscalAgent.types';
import { SCard } from '../TeamSection.styles';

const FiscalAgent = ({ data, elcId, isEditable }: TFiscalAgentProps) => {
  const theme = useTheme();
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.details-tab.team-section.fiscal-agent',
  });

  const { showToastNotification } = useShowToastNotification();
  const { data: fiscalAgents } = useGetAllContactsQuery({
    elcId,
  });

  const [editFiscalAgent, { isLoading, isError }] =
    useEditFiscalAgentMutation();

  const onEdit = async (value: string) => {
    try {
      await editFiscalAgent({
        elcId,
        userId: value,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (e) {
      showToastNotification({
        message: (e as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  const fiscalAgentsOptions = fiscalAgents?.content?.map(item => ({
    label: (
      <Row>
        <Col>
          {item.firstName} {item.lastName}
        </Col>
        <Col>
          <Text
            fontSize={theme.fontSizeMd}
            color={theme.buttonColorPrimaryDisabledText}
          >
            {item.email}
          </Text>
        </Col>
      </Row>
    ),
    value: item.userId,
    title: item.firstName + item.lastName,
  }));

  return (
    <Col md={8}>
      <SCard className="h-100">
        <Tag color="blue">{t('LABEL')}</Tag>
        <Row align="middle">
          <Col>
            <InplaceEditLabels
              label={t('INPLACE_EDIT_LABEL')}
              icon={EIconNames.PROFILE}
            />
          </Col>
          <Col flex="auto">
            <EditableSelect
              {...(data && {
                title: {
                  label: data.fullName,
                  value: data.userId,
                },
              })}
              options={fiscalAgentsOptions}
              onEdit={onEdit}
              emptyText={t('EMPTY_TEXT')}
              placeholder={t('PLACEHOLDER')}
              readonly={!isEditable}
              canRemove={false}
              fitContent={false}
              isError={isError}
              isLoading={isLoading}
              avatarColor={avatarColorMapper.lead}
              {...(data && {
                tooltip: (
                  <ElcItemPopover
                    fullName={data.fullName}
                    email={data.email}
                    phone={data.phone}
                  />
                ),
              })}
            />
          </Col>
        </Row>
      </SCard>
    </Col>
  );
};

export default FiscalAgent;
