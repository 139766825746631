import { SForm, SFormItem, SFormList } from './Form.styles';
import { FormProps, FormItemProps, FormListProps } from './Form.types';

export const Form = ({ ...props }: FormProps) => {
  return <SForm {...props} />;
};

export const FormItem = ({ ...props }: FormItemProps) => {
  return <SFormItem {...props} />;
};

export const FormList = ({ ...props }: FormListProps) => {
  return <SFormList {...props} />;
};
