import { FC } from 'react';
import { useTheme } from 'styled-components';
import { Row } from 'components/Base/Grid';
import { EIconNames } from 'components/Base/Icon';
import { TActionsOnAttendanceColumnProps } from './ActionsOnAttendanceColumn.types';
import ActionButton from './ActionButton';

const ActionsOnAttendanceColumn: FC<TActionsOnAttendanceColumnProps> = ({
  showEditAttendance,
  showRemoveRegistrant,
  showReRegisterRegistrant,
  showUnregisterRegistrant,
  onEditButtonClick,
  onRemoveButtonClick,
  onReRegisterButtonClick,
  onUnregisterButtonClick,
}) => {
  const theme = useTheme();
  return (
    <Row gutter={[4, 4]} wrap={false} className="edit-button">
      {showEditAttendance && (
        <ActionButton
          color={theme.colorPrimaryDefault}
          icon={EIconNames.EDIT_FILLED}
          onClick={onEditButtonClick}
          tooltipTitle="Edit"
          iconAlt="Edit attendance"
        />
      )}
      {showRemoveRegistrant && (
        <ActionButton
          color={theme.colorErrorDefault}
          icon={EIconNames.PERSON_DELETE}
          onClick={onRemoveButtonClick}
          tooltipTitle="Remove"
          iconAlt="Remove registrant"
        />
      )}
      {showUnregisterRegistrant && (
        <ActionButton
          color={theme.colorErrorDefault}
          icon={EIconNames.UNREGISTER}
          onClick={onUnregisterButtonClick}
          tooltipTitle="Unregister"
          iconAlt="Unregister registrant"
        />
      )}
      {showReRegisterRegistrant && (
        <ActionButton
          color={theme.colorErrorDefault}
          icon={EIconNames.UNDO}
          onClick={onReRegisterButtonClick}
          tooltipTitle="Reregister"
          iconAlt="Reregister registrant"
        />
      )}
    </Row>
  );
};

export default ActionsOnAttendanceColumn;
