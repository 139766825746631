import { FC, useEffect, useState } from 'react';
import { useEditElcMeetingActionItemStatusMutation } from 'redux/apiSlice/elcs.slice';
import {
  useShowToastNotification,
  ENotificationType,
} from 'components/Base/Notification';
import { TRtkErrorType } from 'data/types/generic.types';
import { Col, Row, useTranslation } from '@packages/utils';
import { EIconNames, Icon } from 'components/Base/Icon';
import { useTheme } from 'styled-components';
import { Tooltip } from 'components/Base/Tooltip';
import { TActionItemStatusProps } from './ActionItemStatus.types';

const ActionItemStatus: FC<TActionItemStatusProps> = ({
  id,
  status,
  isNew,
  setNewRowData,
  elcId,
  meetingId,
}) => {
  const theme = useTheme();
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.meetings-tab.details.action-items.table.editable-cell.status',
  });
  const { showToastNotification } = useShowToastNotification();

  const [value, setValue] = useState(!!status);

  useEffect(() => {
    if (status === undefined) return;
    setValue(status);
  }, [status]);

  const [updateStatus, { isLoading }] =
    useEditElcMeetingActionItemStatusMutation();

  const onUpdateStatus = async (val: boolean) => {
    if (isLoading) return;
    if (isNew) {
      setValue(val);
      setNewRowData(prev => ({ ...prev, status: val }));
      return;
    }
    try {
      await updateStatus({
        actionItemId: id,
        elcId,
        meetingId,
        status: val,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (e) {
      showToastNotification({
        message: (e as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  const icon = value
    ? EIconNames.CHECK_CIRCLE_OUTLINE_DONE
    : EIconNames.CHECK_CIRCLE_OUTLINE;
  const iconCursor = isLoading ? 'not-allowed' : 'pointer';
  const iconColor = value ? theme.colorSuccessActive : ' ';
  const tooltipTitle = value ? t('MARK_AS_INCOMPLETE') : t('MARK_AS_COMPLETED');

  return (
    <Row justify="center">
      <Tooltip title={tooltipTitle}>
        <Col onClick={() => onUpdateStatus(!value)}>
          <Icon icon={icon} className={iconCursor} color={iconColor} />
        </Col>
      </Tooltip>
    </Row>
  );
};

export default ActionItemStatus;
