import { useState } from 'react';
import { Row, Col, Button, useTranslation } from '@packages/utils';
import { useTheme } from 'styled-components';
import { EIconNames, Icon } from 'components/Base/Icon';
import { TDeleteDetailActionProps } from './DeleteDetailAction.types';
import DeleteDetailsModal from './DeleteDetailsModal';

const DeleteDetailAction = ({
  isEditable,
  elcId,
  elcNoteId,
}: TDeleteDetailActionProps) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.details-tab.details-section.action.delete',
  });
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  return (
    <Row gutter={[16, 16]}>
      {isEditable && (
        <Col>
          <Button
            icon={
              <Icon
                alt="delete icon"
                icon={EIconNames.DELETE}
                size={14}
                color={theme.colorErrorDefault}
                className="pointer"
              />
            }
            tooltip={t('TOOLTIP')}
            onClick={() => setOpen(true)}
            color="primary"
            shape="circle"
            size="middle"
          />
          <DeleteDetailsModal
            setOpen={setOpen}
            open={open}
            elcId={elcId}
            elcNoteId={elcNoteId}
          />
        </Col>
      )}
    </Row>
  );
};

export default DeleteDetailAction;
