import * as React from 'react';
import { forwardRef, ReactElement } from 'react';
import circle1 from 'assets/images/flyerEllips1.svg';
import circle2 from 'assets/images/flyerEllips2.svg';
import circle3 from 'assets/images/flyerEllips3.svg';
import { Image } from 'components/Base/Image';
import {
  SCircleWrapper,
  SFlyerBody,
  SFlyerWrapper,
} from './FlyerWrapper.styles';

export const FlyerWrapper = forwardRef<
  HTMLDivElement,
  { children: ReactElement }
>(({ children }, ref) => {
  return (
    <SFlyerWrapper ref={ref}>
      <SCircleWrapper top={0} left={-5}>
        <Image
          width={800}
          height={200}
          src={circle1}
          alt="circle1"
          aria-hidden="true"
        />
      </SCircleWrapper>
      <SCircleWrapper bottom={0} right={0}>
        <Image width={599} src={circle2} alt="circle2" aria-hidden="true" />
      </SCircleWrapper>
      <SCircleWrapper bottom={0} right={0}>
        <Image width={535} src={circle3} alt="circle3" aria-hidden="true" />
      </SCircleWrapper>
      <SFlyerBody>{children}</SFlyerBody>
    </SFlyerWrapper>
  );
});
