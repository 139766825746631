import { useTheme } from 'styled-components';
import {
  Col,
  ENotificationType,
  TRtkErrorType,
  useShowToastNotification,
  useTranslation,
} from '@packages/utils';
import { EIconNames, Icon } from 'components/Base/Icon';
import { useDeleteGuestAttendeeMutation } from 'redux/apiSlice/elcs.slice';
import { TRemoveGuestAttendeeProps } from './RemoveGuestAttendee.types';

const RemoveGuestAttendee = ({
  elcId,
  meetingId,
  attendeeId,
}: TRemoveGuestAttendeeProps) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.meetings-tab.details.attendees.actions.remove',
  });
  const theme = useTheme();
  const { showToastNotification } = useShowToastNotification();
  const [deleteAttendee, { isLoading }] = useDeleteGuestAttendeeMutation();

  const handleSubmit = async (e: {
    stopPropagation: () => void;
    preventDefault: () => void;
  }) => {
    e.stopPropagation();
    if (isLoading) {
      e.preventDefault();
      return;
    }
    try {
      await deleteAttendee({
        elcId,
        meetingId,
        attendeeId,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (err) {
      showToastNotification({
        message: (err as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  return (
    <Col onClick={handleSubmit}>
      <Icon
        icon={EIconNames.DELETE_OUTLINED}
        color={theme.colorBlackIconColor}
        className="pointer"
        tooltip={t('DELETE_TOOLTIP')}
      />
    </Col>
  );
};

export default RemoveGuestAttendee;
