import { FC } from 'react';
import { useTranslation } from '@packages/utils';
import { Modal } from 'components/Base/Modal';
import {
  useShowToastNotification,
  ENotificationType,
} from 'components/Base/Notification';
import { TRtkErrorType } from 'data/types/generic.types';
import ConfirmModalBody from 'components/Shared/ConfirmModalBody';
import { useRemoveRegistrantMutation } from 'redux/apiSlice/registrants.slice';
import { TRemoveRegistrantModalProps } from './RemoveRegistrantModal.types';

const RemoveRegistrantModal: FC<TRemoveRegistrantModalProps> = ({
  open,
  setOpen,
  registrantId,
  workshopClassId,
  workshopId,
}) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.workshops.details.workshop-registrants.actions.remove.modal',
  });
  const { showToastNotification } = useShowToastNotification();
  const [removeRegistrant, { isLoading }] = useRemoveRegistrantMutation();

  const handleSubmit = async () => {
    try {
      await removeRegistrant({
        registrantId,
        workshopClassId,
        workshopId,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
      setOpen(false);
    } catch (e) {
      showToastNotification({
        message: (e as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  return (
    <Modal
      title={t('TITLE')}
      open={open}
      onCancel={() => setOpen(false)}
      onOk={handleSubmit}
      okText={t('REMOVE_BTN')}
      cancelText={t('CANCEL_BTN')}
      confirmLoading={isLoading}
    >
      <ConfirmModalBody title={t('SUBTITLE')} description={t('DESCRIPTION')} />
    </Modal>
  );
};

export default RemoveRegistrantModal;
