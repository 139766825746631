import React, { useRef } from 'react';
import { useTheme } from 'styled-components';
import { Card, Title, useTranslation } from '@packages/utils';
import { useDispatch } from 'react-redux';
import { setPageHeaderHeight } from 'redux/slices';
import NotFoundPage from 'components/Shared/ErrorPageView/PageNotFound';
import SomethingWentWrong from 'components/Shared/ErrorPageView/SomethingWentWrong';
import useElementResize from 'hooks/useElementResize';
import {
  SEmptyView,
  SPageWrapper,
  SPageWrapperHeader,
} from './PageWrapper.styles';
import { TPageWrapperProps } from './PageWrapper.types';
import { Col, Row } from '../Grid';
import Spinner from '../Spinner';
import { EIconNames, Icon } from '../Icon';

const PageWrapper = ({
  children,
  title,
  actions,
  backButton,
  isLoading,
  emptyWrapper,
  titleSuffix,
  error,
}: TPageWrapperProps) => {
  const theme = useTheme();
  const { t } = useTranslation({
    keyPrefix: 'descriptions.global_names',
  });
  const dispatch = useDispatch();
  const pageWrapperHeaderRef = useRef<HTMLDivElement>(null);
  const { height: pageWrapperHeaderHeight } = useElementResize({
    ref: pageWrapperHeaderRef,
  });

  dispatch(setPageHeaderHeight(pageWrapperHeaderHeight));

  let content: JSX.Element;
  if (isLoading) {
    content = <Spinner centered spinnerSize={32} />;
  } else if (error?.status === 404) {
    content = <NotFoundPage />;
  } else if (error) {
    content = <SomethingWentWrong error={error} />;
  } else if (emptyWrapper?.isEmpty) {
    content = (
      <Card className="m-4">
        <SEmptyView {...emptyWrapper} />
      </Card>
    );
  } else {
    content = children;
  }
  return (
    <SPageWrapper
      className="page-wrapper" // this class used in download flyer, make sure to change it if you want to change the class name
      data-testid="page-wrapper-main"
    >
      <SPageWrapperHeader
        justify="space-between"
        align="middle"
        ref={pageWrapperHeaderRef}
      >
        <Col>
          <Row align="middle">
            <Col>
              <Row
                className={backButton ? 'pointer' : ''}
                align="middle"
                gutter={[8, 0]}
                {...(backButton && { onClick: backButton.onClick })}
              >
                {backButton && (
                  <Col>
                    <Icon
                      icon={EIconNames.ARROW_BACK}
                      size={24}
                      color={theme.colorBlackIconColor}
                      alt="back icon"
                      tooltip={{
                        title: t('BACK_TO_PLACEHOLDER', {
                          placeholder: backButton.tooltip,
                        }),
                      }}
                    />
                  </Col>
                )}
                {(backButton || title) && (
                  <Col>
                    <Title level={2} color="#000000">
                      {backButton?.labelPrefix || title}
                    </Title>
                  </Col>
                )}
              </Row>
            </Col>
            <Col>{titleSuffix}</Col>
          </Row>
        </Col>
        {actions && !emptyWrapper?.isEmpty && !isLoading && !error && (
          <Col>{actions}</Col>
        )}
      </SPageWrapperHeader>
      {content}
    </SPageWrapper>
  );
};

export default PageWrapper;
