import { FC } from 'react';
import { useTheme } from 'styled-components';
import { Text } from 'components/Base/Typography';
import { TAttendanceTextProps } from './AttendanceText.types';

const AttendanceText: FC<TAttendanceTextProps> = ({
  color,
  text,
  className,
}) => {
  const theme = useTheme();

  return (
    <Text
      style={{ whiteSpace: 'nowrap' }}
      fontSize={theme.fontSizeLg}
      fontWeight={theme.fontWeightSemiBold}
      {...(color && { color })}
      {...(className && { className })}
    >
      {text}
    </Text>
  );
};

export default AttendanceText;
