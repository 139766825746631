import ElcSpecificDocsSection from './ElcSpecificDocsSection';
import GeneralElcDocsSection from './GeneralElcDocsSection';
import LinksSection from './LinksSection';
import { TResourcesViewProps } from './ResourcesView.types';

const ResourcesView = ({
  elcId,
  data,
  isResourceEditable,
}: TResourcesViewProps) => {
  if (!data) {
    return <></>;
  }
  const { generalElcDocuments, elcSpecificDocuments, links } = data;

  return (
    <>
      {!!generalElcDocuments.length && (
        <GeneralElcDocsSection
          elcId={elcId}
          isResourceEditable={isResourceEditable}
          data={generalElcDocuments}
        />
      )}
      {!!elcSpecificDocuments.length && (
        <ElcSpecificDocsSection
          elcId={elcId}
          isResourceEditable={isResourceEditable}
          data={elcSpecificDocuments}
        />
      )}
      {!!links.length && (
        <LinksSection
          elcId={elcId}
          isResourceEditable={isResourceEditable}
          data={links}
        />
      )}
    </>
  );
};

export default ResourcesView;
