import { useTranslation } from '@packages/utils';
import useGetValidationData from 'components/Base/Form/validationRules';

const useGetFormData = () => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.resources-tab.add-resource.modal.form',
  });
  const { validationRules } = useGetValidationData();

  const resourceTypeOptions = [
    {
      value: 'DOCUMENT',
      label: t('resource-type.DOCUMENT'),
    },
    {
      value: 'LINK',
      label: t('resource-type.LINK'),
    },
  ];

  const documentTypeOptions = [
    {
      value: 'GENERAL_ELC_DOCUMENT',
      label: t('document-type.GENERAL_ELC_DOC'),
    },
    {
      value: 'ELC_SPECIFIC_DOCUMENT',
      label: t('document-type.ELC_SPECIFIC_DOC'),
    },
  ];

  const addResourcesFormFields = {
    resourceType: {
      name: 'resourceType',
    },
    documentType: {
      name: 'documentType',
      label: '',
      rules: [validationRules.requiredField()],
    },
    fileUpload: {
      name: 'fileUpload',
      label: t('file.LABEL'),
      rules: [validationRules.requiredField()],
    },
    name: {
      name: 'name',
      label: t('title.LABEL'),
      placeholder: t('title.PLACEHOLDER'),
      rules: [validationRules.requiredField()],
    },
    url: {
      name: 'url',
      label: t('link.LABEL'),
      placeholder: t('link.PLACEHOLDER'),
      rules: [validationRules.requiredField(), validationRules.validUrl()],
    },
  };

  return { addResourcesFormFields, resourceTypeOptions, documentTypeOptions };
};

export default useGetFormData;
