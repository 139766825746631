import { useEffect, useState } from 'react';
import { Button, useTranslation } from '@packages/utils';
import { useGetElcMeetingTATimeQuery } from 'redux/apiSlice/elcs.slice';
import { EIconNames, Icon } from 'components/Base/Icon';
import TATimeItem from './TATimeItem';
import AddTATimeForm from './AddTATimeForm';
import { TTATimeTabProps } from './TATimeTab.types';

const TATimeTab = ({ elcId, isDrawerOpen, meetingId }: TTATimeTabProps) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.meetings-tab.details.ta-time.add',
  });

  const [hideForm, setHideForm] = useState<boolean>(true);
  const { data } = useGetElcMeetingTATimeQuery(
    {
      meetingId,
    },
    {
      skip: !meetingId,
    },
  );

  useEffect(() => {
    if (!data) {
      return;
    }
    if (data.length > 0) {
      setHideForm(true);
    } else {
      setHideForm(false);
    }
  }, [data]);

  if (!data) {
    return <></>;
  }

  const onCancelForm = () => {
    if (data.length > 0) {
      setHideForm(true);
    }
  };

  return (
    <>
      {hideForm ? (
        <Button
          color="primary"
          type="link"
          className="pl-0 mb-4"
          onClick={() => setHideForm(false)}
          icon={<Icon icon={EIconNames.PLUS} className="pointer" />}
        >
          {t('ADD_BTN')}
        </Button>
      ) : (
        <AddTATimeForm
          isDrawerOpen={isDrawerOpen}
          onCancelForm={onCancelForm}
          elcId={elcId}
          meetingId={meetingId}
        />
      )}
      {data.map(item => (
        <TATimeItem key={item.taTimeId} data={item} elcId={elcId} />
      ))}
    </>
  );
};

export default TATimeTab;
