import { FC } from 'react';
import { ENotificationType } from '@idaho-aeyc/ui-kit';
import { Modal } from 'components/Base/Modal';
import ConfirmModalBody from 'components/Shared/ConfirmModalBody';
import { useTranslation } from '@packages/utils';
import { useDeleteCustomQuestionMutation } from 'redux/apiSlice/workshops.slice';
import { TRtkErrorType } from 'data/types/generic.types';
import { useShowToastNotification } from 'components/Base/Notification';
import { TRANSLATION_KEY } from '../../QuestionTab.data';
import { TDeleteQuestionModalProps } from '../../QuestionTab.types';

const DeleteQuestionModal: FC<TDeleteQuestionModalProps> = ({
  open,
  setModalOpen,
  workshopId,
}) => {
  const [deleteQuestion, { isLoading }] = useDeleteCustomQuestionMutation();
  const { showToastNotification } = useShowToastNotification();

  const { t } = useTranslation({
    keyPrefix: `${TRANSLATION_KEY}.modals.delete-modal`,
  });
  const closeModal = () => {
    setModalOpen(false);
  };
  const handleOnDelete = async () => {
    try {
      await deleteQuestion({
        id: workshopId,
      }).unwrap();
      showToastNotification({
        message: t('success-message'),
        type: ENotificationType.SUCCESS,
      });
    } catch (err) {
      showToastNotification({
        message: (err as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    } finally {
      closeModal();
    }
  };
  return (
    <Modal
      title={t('TITLE')}
      onCancel={closeModal}
      open={open}
      onOk={handleOnDelete}
      confirmLoading={isLoading}
    >
      <ConfirmModalBody description={t('description')} />
    </Modal>
  );
};
export default DeleteQuestionModal;
