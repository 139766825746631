import { FC } from 'react';
import { Form, FormItem } from 'components/Base/Form';
import useGetValidationData from 'components/Base/Form/validationRules';
import useForm from 'hooks/useForm';
import PlaceAutocomplete from 'components/Shared/PlaceAutocomplete';
import { Icon, EIconNames } from 'components/Base/Icon';
import { Col } from 'components/Base/Grid';
import { TAddressTypesUI } from 'data/types/generic.types';
import { TEditableAddressFormProps } from './EditableAddressForm.types';

const EditableAddressForm: FC<TEditableAddressFormProps> = ({
  placeholder,
  setValue,
  onEdit,
  required,
  rules,
  setIsEditable,
  minWidth,
  defaultValue,
}) => {
  const { validationRules } = useGetValidationData();
  const formValidationRules = [
    validationRules.address(),
    ...(required ? [validationRules.requiredField()] : []),
    ...(rules || []),
  ];

  const { form } = useForm<{ address: TAddressTypesUI }>();

  const handleCloseEditableMode = () => {
    setIsEditable(false);
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const { address } = values;
      // @ts-ignore
      if (address?.target && !address.target.value) {
        if (required) {
          return;
        }
        setValue(defaultValue);
        if (defaultValue) {
          onEdit(null);
        }
      } else {
        if (defaultValue !== address.formattedAddress) {
          onEdit(address);
        }
        setValue(address.formattedAddress);
      }
      handleCloseEditableMode();
    } catch (e) {
      // do nothing
    }
  };

  return (
    <Form form={form} style={{ minWidth: minWidth ? `${minWidth}px` : 'auto' }}>
      <FormItem name="address" rules={formValidationRules}>
        <PlaceAutocomplete
          placeholder={placeholder}
          onChange={address => {
            form.setFieldValue('address', address);
            handleSubmit();
          }}
          onBlur={address => {
            form.setFieldValue(
              'address',
              // @ts-ignore
              address?.target && !address.target.value ? '' : address,
            );
            handleSubmit();
          }}
          autoFocus
          defaultValue={defaultValue}
          suffix={
            <Col
              className="pointer"
              onClick={e => {
                e.stopPropagation();
                handleCloseEditableMode();
              }}
            >
              <Icon icon={EIconNames.UNDO} alt="back icon" />
            </Col>
          }
        />
      </FormItem>
    </Form>
  );
};

export default EditableAddressForm;
