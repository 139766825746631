import { useEffect, useState } from 'react';
import { useTranslation, Card, Row, Col } from '@packages/utils';
import { Form, FormItem, FormList } from 'components/Base/Form';
import { Select, SelectWithAdd } from 'components/Base/Select';
import { Input } from 'components/Base/Input';
import { useGetPeopleIdentityQuery } from 'redux/apiSlice/people.slice';
import CreateCoordinatorModal from 'components/Shared/Coordinator/CreateCoordinatorModal';
import { workshopCategoryToCount } from 'data/convertors/workshops.convertors';
import { TCreateCoordinatorModalOpen } from 'components/Shared/Coordinator/CreateCoordinatorModal/CreateCoordinatorModal.types';
import { TCreateSessionFormProps } from '../../CreateSessionCard.types';
import useGetData from '../../CreateSessionCard.data';

const SeriesForm = ({
  form,
  possibleAgeGroup,
  typeCategory,
  formValues,
}: TCreateSessionFormProps) => {
  const { t } = useTranslation();
  const { sessionFormFields, languageOptions } = useGetData();
  const [open, setOpen] = useState<TCreateCoordinatorModalOpen>({
    visible: false,
    initialName: '',
  });
  const [coordinatorId, setCoordinatorId] = useState<string>('');
  const [formListIndex, setFormListIndex] = useState<number>();
  const { age, language, capacity, facilitator } = sessionFormFields;
  const { data: peopleIdentityOptions, isLoading } =
    useGetPeopleIdentityQuery();

  const ageGroupsOptions = possibleAgeGroup?.map(item => ({
    label: t(`enums.age-group.${item}`),
    value: item,
  }));

  useEffect(() => {
    if (!peopleIdentityOptions) {
      return;
    }
    const facilitatorSelected = peopleIdentityOptions.find(
      item => item.value === coordinatorId,
    );
    const fields = form.getFieldValue('sessions');
    if (formListIndex !== undefined) {
      fields[formListIndex][facilitator.name] = facilitatorSelected?.value;
    }
    form.setFieldsValue({ sessions: fields });
  }, [peopleIdentityOptions]);

  const partCount = typeCategory ? workshopCategoryToCount[typeCategory] : 1;

  useEffect(() => {
    if (!formValues) {
      return;
    }
    form.setFieldsValue(formValues);
  }, [formValues]);

  return (
    <>
      <Form
        form={form}
        initialValues={{
          age:
            ageGroupsOptions?.length === 1 ? ageGroupsOptions[0].value : null,
          language: null,
          capacity: '',
        }}
      >
        <Card>
          <Row gutter={[16, 16]}>
            <Col sm={8} xs={24}>
              <FormItem {...age}>
                <Select
                  placeholder={age.placeholder}
                  options={ageGroupsOptions}
                />
              </FormItem>
            </Col>
            <Col sm={8} xs={24}>
              <FormItem {...language}>
                <Select
                  placeholder={language.placeholder}
                  options={languageOptions}
                />
              </FormItem>
            </Col>
            <Col sm={8} xs={24}>
              <FormItem {...capacity}>
                <Input placeholder={capacity.placeholder} />
              </FormItem>
            </Col>
          </Row>
        </Card>
        <Row gutter={[16, 16]} className="mt-4">
          <FormList
            name="sessions"
            initialValue={Array.from({ length: partCount }, () => ({
              facilitator: null,
            }))}
          >
            {fields => (
              <>
                {fields.map((field, index) => (
                  <Col sm={8} xs={24} key={field.key}>
                    <Card
                      title={t(
                        'descriptions.admin-portal.workshops.details.sessions.create-form.TITLE',
                        {
                          placeholder: index + 1,
                        },
                      )}
                    >
                      <FormItem
                        name={[field.name, facilitator.name]}
                        label={facilitator.label}
                      >
                        <SelectWithAdd
                          placeholder={facilitator.placeholder}
                          loading={isLoading}
                          options={peopleIdentityOptions}
                          onBtnClick={currentSearchValue => {
                            setOpen({
                              visible: true,
                              initialName: currentSearchValue || '',
                            });
                            setFormListIndex(index);
                          }}
                          addButtonLabel={t(
                            'descriptions.admin-portal.workshops.details.sessions.create-form.facilitator.ADD_BTN',
                          )}
                          enableAddButton
                          allowClear
                        />
                      </FormItem>
                    </Card>
                  </Col>
                ))}
              </>
            )}
          </FormList>
        </Row>
      </Form>
      <CreateCoordinatorModal
        open={open}
        setOpen={setOpen}
        label={t(
          'descriptions.admin-portal.workshops.details.sessions.create-form.facilitator.LABEL',
        )}
        setCoordinatorId={setCoordinatorId}
      />
    </>
  );
};

export default SeriesForm;
