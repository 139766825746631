import { Col, useTranslation } from '@packages/utils';
import { useTheme } from 'styled-components';
import { Icon, EIconNames } from 'components/Base/Icon';

const PendingIconTooltip = () => {
  const theme = useTheme();
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.elcs.list.elc-item',
  });
  return (
    <Col>
      <Icon
        icon={EIconNames.CLOCK}
        color={theme.colorBlackIconColor}
        tooltip={{
          title: t('ELC_STATUS_TOOLTIP'),
        }}
      />
    </Col>
  );
};

export default PendingIconTooltip;
