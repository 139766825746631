import { FC } from 'react';
import { Button } from '@packages/utils';
import { useTheme } from 'styled-components';
import { Row, Col } from 'components/Base/Grid';
import { Title } from '../Typography';
import { EIconNames, Icon } from '../Icon';
import {
  DetailsEmptyViewBtnWrapper,
  DetailsEmptyViewWrapper,
} from './DetailsEmptyView.style';
import { TDetailsEmptyViewProps } from './DetailsEmptyView.types';

const DetailsEmptyView: FC<TDetailsEmptyViewProps> = ({
  addBtnText,
  onClick,
  title,
}) => {
  const theme = useTheme();
  return (
    <DetailsEmptyViewWrapper>
      <Row justify="center" className="w-100 empty-content " gutter={[0, 8]}>
        <Col span={24} className="text-center">
          <Title level={5}>{title}</Title>
        </Col>
        <DetailsEmptyViewBtnWrapper span={24} className="text-center m-auto">
          <Button
            onClick={onClick}
            color="primary"
            size="small"
            icon={
              <Icon
                icon={EIconNames.PLUS}
                size={12}
                color={theme.colorBlackPrimary}
              />
            }
          >
            {addBtnText}
          </Button>
        </DetailsEmptyViewBtnWrapper>
      </Row>
    </DetailsEmptyViewWrapper>
  );
};

export default DetailsEmptyView;
