import styled from 'styled-components';
import { Row } from '@packages/utils';
import { EmptyView } from '../EmptyView';

export const SPageWrapper = styled.div`
  &&.page-wrapper {
    .ant-tabs {
      .ant-tabs-nav {
        background-color: ${({ theme }) => theme.colorWhitePrimary};
        padding: 0 ${({ theme }) => theme.paddingS}px;
        margin-bottom: 0;
      }
    }
  }
`;

export const SPageWrapperHeader = styled(Row)`
  background-color: ${({ theme }) => theme.colorWhitePrimary};
  padding: ${({ theme }) =>
    `${theme.paddingLg}px ${theme.paddingLg}px ${theme.paddingS}px`};
`;

export const SEmptyView = styled(EmptyView)`
  height: 75vh;
`;
