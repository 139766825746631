import { FC } from 'react';
import { Tag, TagColors, useTranslation } from '@packages/utils';
import { EWorkshopStatus } from 'data/types/workshops.types';
import { TWorkshopStatusProps } from './WorkshopStatus.types';

const WorkshopStatus: FC<TWorkshopStatusProps> = ({ text, className }) => {
  const { t } = useTranslation({
    keyPrefix: 'enums.workshop-status',
  });
  const workshopStatusColorMapper: Record<EWorkshopStatus, TagColors> = {
    [EWorkshopStatus.PLANNING]: 'primary',
    [EWorkshopStatus.CANCELLED]: 'danger',
    [EWorkshopStatus.PUBLISHED]: 'success',
    [EWorkshopStatus.CLOSED]: 'purple',
    [EWorkshopStatus.RESCHEDULED]: 'pink',
  };

  if (!text) return null;
  return (
    <Tag className={className} color={workshopStatusColorMapper[text]}>
      {t(text)}
    </Tag>
  );
};

export default WorkshopStatus;
