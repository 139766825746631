import { useEffect } from 'react';
import { Form, FormItem, Input } from '@packages/utils';
import dayjs from 'dayjs';
import { dateTimeFormatUI, timepickerFormat } from 'helpers/utils';
import { DatePicker } from 'components/Base/DatePicker';
import useGetFormData from './AddEditMeetingForm.data';
import { TAddEditMeetingFormProps } from './AddEditMeetingForm.types';

const AddEditMeetingForm = ({
  form,
  initialValues,
}: TAddEditMeetingFormProps) => {
  const { addEditMeetingFormFields } = useGetFormData();
  const { date, title } = addEditMeetingFormFields;

  useEffect(() => {
    if (!initialValues) {
      return;
    }
    form.setFieldsValue({
      title: initialValues.title,
      date: dayjs(initialValues.date),
    });
  }, [initialValues]);

  return (
    <Form form={form}>
      <FormItem {...title}>
        <Input placeholder={title.placeholder} maxLength={250} />
      </FormItem>
      <FormItem {...date}>
        <DatePicker
          showTime={{
            minuteStep: 15,
            format: timepickerFormat,
          }}
          className="w-100"
          placeholder={date.placeholder}
          format={dateTimeFormatUI}
        />
      </FormItem>
    </Form>
  );
};

export default AddEditMeetingForm;
