import { FC } from 'react';
import {
  ChildcareView,
  EditChildcare,
  EditableContentWithActions,
  TChildcareItem,
  useTranslation,
} from '@packages/utils';
import { useEditChildcareMutation } from 'redux/apiSlice/registrants.slice';
import { Form, FormItem } from 'components/Base/Form';
import useForm from 'hooks/useForm';
import { TValidateFieldsError } from 'data/types/generic.types';
import { get, isEqual } from 'lodash';
import { ENotificationType } from '@idaho-aeyc/ui-kit';
import { useShowToastNotification } from 'components/Base/Notification';
import { TChildcareEditColumnProps } from './ChildcareEditColumn.types';
import { childCareActionsKeyPrefix } from '../../ChildCareTab.data';

const ChildcareEditColumn: FC<TChildcareEditColumnProps> = ({
  childcareRequirements,
  registrantId,
  workshopClassId,
  workshopId,
  isReadOnly,
}) => {
  const { form } = useForm<{ childcareRequirements: TChildcareItem[] }>();
  const { showToastNotification } = useShowToastNotification();
  const { t } = useTranslation({
    keyPrefix: childCareActionsKeyPrefix.concat('.add'),
  });
  const [editChildcare, { isLoading }] = useEditChildcareMutation();

  const onSave = async () => {
    try {
      const values = await form.validateFields();
      if (isEqual(values.childcareRequirements, childcareRequirements)) {
        return;
      }
      await editChildcare({
        childcareRequirements: values.childcareRequirements,
        registrantId,
        workshopClassId,
        workshopId,
      }).unwrap();

      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (error) {
      if (!(error as TValidateFieldsError)?.errorFields) {
        const message = get(error, 'data.message');
        showToastNotification({
          message,
          type: ENotificationType.ERROR,
        });
      }
    }
  };

  return (
    <EditableContentWithActions
      isLoading={isLoading}
      isReadOnly={isReadOnly}
      editView={
        <Form form={form}>
          <FormItem name="childcareRequirements">
            <EditChildcare
              width={250}
              initialChildcare={childcareRequirements}
            />
          </FormItem>
        </Form>
      }
      readView={<ChildcareView childcareItems={childcareRequirements} />}
      onSave={onSave}
      wrapperSytle={{ width: '350px' }}
    />
  );
};

export default ChildcareEditColumn;
