import { FC } from 'react';
import { useLazyExportParentsCSVQuery } from 'redux/apiSlice/kitManagement.slice';
import ExportAction from './parentExportAction';
import { TActionProps } from './ExportActions.types';

const ParentExport: FC<TActionProps> = ({ setShowAlert, selectedRows }) => {
  const orderIds = selectedRows?.map(row => row.key) || [];
  const [exportParents, { isLoading }] = useLazyExportParentsCSVQuery();

  const onExport = async () => {
    await exportParents({
      parentOrderIds: orderIds,
    }).unwrap();
  };
  return (
    <ExportAction
      setShowAlert={setShowAlert}
      onSubmit={onExport}
      confirmLoading={isLoading}
      selectedRows={selectedRows}
    />
  );
};

export default ParentExport;
