import DetailsList from './DetailsList';
import { TDetailsSectionProps } from './DetailsSection.types';
import DetailsSectionEmptyView from './DetailsSectionEmptyView';

const DetailsSection = ({ data, elcId, isEditable }: TDetailsSectionProps) => {
  return (
    <>
      {!data.length ? (
        <DetailsSectionEmptyView />
      ) : (
        <DetailsList data={data} elcId={elcId} isEditable={isEditable} />
      )}
    </>
  );
};

export default DetailsSection;
