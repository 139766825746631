import { TRtkErrorType, useTranslation } from '@packages/utils';
import PageWrapper from 'components/Base/PageWrapper';
import { TBackButtonState } from 'components/Base/PageWrapper/PageWrapper.types';
import { Tabs } from 'components/Base/Tabs';
import { TabsProps } from 'components/Base/Tabs/Tabs.types';
import { useAppSelector } from 'redux/store';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetElcByIdQuery } from 'redux/apiSlice/elcs.slice';
import { useCheckPermission } from 'hooks/useCheckPermission';
import useKeepTabsInUrl from 'hooks/useKeepTabsInUrl';
import { EELSDetailsTabsUrl } from '../components/ELSDetailTabs.types';
import DetailsTab from '../components/DetailsTab';
import { TELCDetailProps } from './ELCDetail.types';
import Contacts from '../components/ContactsTab';
import ElacMembers from '../components/ELACMembersTab';
import ELCPlaces from '../components/ELCPlacesTab';
import Meetings from '../components/MeetingsTab';
import Resources from '../components/Resources';

const ELCDetail = ({
  isDetailsEditable,
  backButtonSuffix,
  backUrl,
}: TELCDetailProps) => {
  const isElcsMoreThanOne = useAppSelector(
    state => state.redirectSlice.isElcsMoreThanOne,
  );
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.elcs',
  });
  const isContactVisible = useCheckPermission('ELC_CONTACTS_VIEW');
  const isPlacesVisible = useCheckPermission('ELC_PLACES_VIEW');
  const isMeetingsTabVisible = useCheckPermission('MEETING_VIEW');
  const isResourcesTabVisible = useCheckPermission('ELC_RESOURCE_VIEW');
  const params = useParams();
  const { id } = params as { id: string };
  const navigate = useNavigate();
  const location = useLocation();
  const goBackState = (location.state as TBackButtonState) || {
    backUrl,
    backButtonSuffix,
  };

  const onBackClick = () => {
    if (!goBackState?.backUrl) {
      return;
    }
    navigate(goBackState.backUrl);
  };

  const { data, isLoading, error } = useGetElcByIdQuery({
    elcId: id,
  });

  const { onTabChange, tabInUrl } = useKeepTabsInUrl({
    defaultTab: EELSDetailsTabsUrl.DETAILS,
    stateInUrl: goBackState,
  });

  const tabItems: TabsProps['items'] = [
    {
      key: EELSDetailsTabsUrl.DETAILS,
      label: t('detail.tabs.DETAILS'),
      children: (
        <DetailsTab isEditable={isDetailsEditable} data={data} elcId={id} />
      ),
    },
    {
      key: EELSDetailsTabsUrl.CONTACTS,
      label: t('detail.tabs.CONTACTS'),
      children: <Contacts elcId={id} />,
      show: isContactVisible,
    },
    {
      key: EELSDetailsTabsUrl.ELAC_MEMBERS,
      label: t('detail.tabs.ELAC_MEMBERS'),
      children: <ElacMembers elcId={id} />,
      show: isContactVisible,
    },
    {
      key: EELSDetailsTabsUrl.ELC_PLACES,
      label: t('detail.tabs.ELC_PLACES'),
      children: <ELCPlaces elcId={id} />,
      show: isPlacesVisible,
    },
    {
      key: EELSDetailsTabsUrl.RESOURCES,
      label: t('detail.tabs.RESOURCES'),
      children: <Resources elcId={id} />,
      show: isResourcesTabVisible,
    },
    {
      key: EELSDetailsTabsUrl.MEETINGS,
      label: t('detail.tabs.MEETINGS'),
      children: <Meetings elcId={id} />,
      show: isMeetingsTabVisible,
    },
  ];

  return (
    <PageWrapper
      title={data?.name}
      {...(goBackState?.backButtonSuffix &&
        isElcsMoreThanOne && {
          backButton: {
            labelPrefix: data?.name || '',
            tooltip: goBackState.backButtonSuffix,
            onClick: onBackClick,
          },
        })}
      isLoading={isLoading}
      error={error as TRtkErrorType}
    >
      <Tabs
        defaultActiveKey={tabInUrl}
        onChange={onTabChange}
        items={tabItems}
      />
    </PageWrapper>
  );
};

export default ELCDetail;
