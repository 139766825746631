import { FC } from 'react';
import { Row } from 'components/Base/Grid';
import { TChildcareTotalsProps } from './ChildcareTotals.types';
import ChildCareTotalCard from './ChildCareTotalCard';

const ChildcareTotals: FC<TChildcareTotalsProps> = ({
  childcareTotalCounts,
}) => {
  return (
    <Row gutter={[10, 10]}>
      {(childcareTotalCounts || []).map(item => (
        <ChildCareTotalCard data={item} />
      ))}
    </Row>
  );
};

export default ChildcareTotals;
