import { Row, TRtkErrorType, useTranslation } from '@packages/utils';
import PageWrapper from 'components/Base/PageWrapper';
import { useCheckPermission } from 'hooks/useCheckPermission';
import { useGetElcsQuery } from 'redux/apiSlice/elcs.slice';
import CardContent from 'components/Base/Layout/Content/CardContent';
import { EIconNames } from 'components/Base/Icon';
import ELCItem from '../ELCItem';

const AllELCs = () => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.elcs.list.all-elcs',
  });
  const { data, isLoading, error } = useGetElcsQuery();

  const isEmpty = !data?.length;

  const isEditAllowed = useCheckPermission('ELC_LIST_UPDATE');

  return (
    <PageWrapper
      title={t('TITLE')}
      isLoading={isLoading}
      error={error as TRtkErrorType}
      emptyWrapper={{
        isEmpty,
        alt: 'chess queen',
        icon: EIconNames.CHESS_QUEEN,
        emptyText: t('empty-view.EMPTY_TEXT'),
      }}
    >
      <CardContent contentPadding="16px">
        <Row gutter={[16, 16]}>
          {data?.map(item => (
            <ELCItem
              key={item.elcId}
              elcData={item}
              isEditAllowed={isEditAllowed}
              backButtonSuffix={t('TITLE')}
            />
          ))}
        </Row>
      </CardContent>
    </PageWrapper>
  );
};

export default AllELCs;
