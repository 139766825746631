import { TOption } from '@idaho-aeyc/ui-kit';
import { citiesOptionsConverter } from 'data/convertors/general.converters';
import { EWorkshopAppUrls } from 'data/urls/workshopApp';
import { emptySplitApi } from 'redux/helpers/slice.helpers';

const baseUrl = `${EWorkshopAppUrls.WORKSHOP_APP}`;

export const workshopAppApi = emptySplitApi('workshop-app', [
  'idahoCities',
]).injectEndpoints({
  endpoints: build => ({
    getIdahoCities: build.query<TOption[], void>({
      query() {
        return {
          url: `${baseUrl}/${EWorkshopAppUrls.CITIES}/${EWorkshopAppUrls.IDAHO}`,
          method: 'GET',
        };
      },
      providesTags: ['idahoCities'],
      transformResponse: (data: string[]) =>
        citiesOptionsConverter.fromDb(data),
    }),
  }),
});

export const { useGetIdahoCitiesQuery } = workshopAppApi;
