import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { get } from 'lodash';
import { useTranslation } from '@packages/utils';
import { Modal } from 'components/Base/Modal';
import { EIconNames } from 'components/Base/Icon';
import {
  useShowToastNotification,
  ENotificationType,
} from 'components/Base/Notification';
import { useDeleteActionMutation } from 'redux/apiSlice/workshops.slice';
import { RoutesPaths } from 'routes/Routes.types';
import ConfirmModalBody from 'components/Shared/ConfirmModalBody';
import ActionGroupItemLabel from 'components/Shared/ActionsButtonDetailsPage/ActionGroupItemLabel';
import { TActionProps } from '../Actions.types';

const DeleteAction: FC<TActionProps> = ({ id }) => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.workshops.details.actions.remove',
  });
  const { showToastNotification } = useShowToastNotification();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const [deleteWorkshop, { isLoading }] = useDeleteActionMutation();

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    try {
      await deleteWorkshop({
        workshopId: id,
      }).unwrap();
      showToastNotification({
        message: t('modal.SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
      navigate(`/app/${RoutesPaths.WORKSHOP}`);
    } catch (err) {
      const message = get(err, 'data.message');
      showToastNotification({
        message,
        type: ENotificationType.ERROR,
      });
    }
    handleClose();
  };

  return (
    <>
      <ActionGroupItemLabel
        label={t('REMOVE_BTN')}
        icon={EIconNames.DELETE_OUTLINED}
        onClick={() => setOpen(true)}
      />

      <Modal
        open={open}
        title={t('modal.TITLE')}
        cancelText={t('modal.CANCEL_BTN')}
        okText={t('modal.REMOVE_BTN')}
        onCancel={handleClose}
        onOk={handleSubmit}
        width={350}
        confirmLoading={isLoading}
      >
        <ConfirmModalBody
          title={t('modal.SUBTITLE')}
          description={t('modal.DESCRIPTION')}
        />
      </Modal>
    </>
  );
};

export default DeleteAction;
