import { useTranslation } from '@packages/utils';
import useGetValidationData from 'components/Base/Form/validationRules';

const useGetFormData = () => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.elac-members-tab.list.actions.add-elac-member.modal.form',
  });
  const { validationRules } = useGetValidationData();
  const addELACMemberFormFields = {
    contactId: {
      name: 'contactId',
      label: t('person.LABEL'),
      placeholder: t('person.PLACEHOLDER'),
      rules: [validationRules.requiredField()],
    },
  };

  return { addELACMemberFormFields };
};

export default useGetFormData;
