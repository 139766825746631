import { Key, useCallback, useEffect, useMemo, useState } from 'react';
import { isEqual, isEmpty } from 'lodash';
import {
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from 'antd/es/table/interface';
import { ESortDirection, TSortParams } from 'data/types/generic.types';
import useDidUpdate from 'hooks/useDidUpdate';
import { appLocalStorage, EStorageNames } from 'helpers/storage';
import {
  TStorageFilters,
  TUseTableParams,
  TUseTableReturnData,
} from '../Table.types';

const useTable = <T>({
  storagePath = '',
  defaultSortColumn = {},
  defaultPageSize = '20',
  defaultColumns,
  defaultFilters = {},
}: TUseTableParams<T>): TUseTableReturnData<T> => {
  const storageFilters = appLocalStorage.getItem(EStorageNames.filters);

  let tableFilters: TStorageFilters<T> = {};
  if (storageFilters) {
    tableFilters = storageFilters[storagePath] as TStorageFilters<T>;
  }
  const [columns, setColumns] = useState(defaultColumns);
  const [page, setPage] = useState(tableFilters?.page || 0);
  const [selectedRows, setSelectedRows] = useState<{
    selectedRowsKeys: Key[];
    selectedRowsRecords: T[];
  }>({
    selectedRowsKeys: [],
    selectedRowsRecords: [],
  });
  const [pageSize, setPageSize] = useState(
    tableFilters?.pageSize || Number(defaultPageSize),
  );
  const [sortColumn, setSortColumn] = useState<TSortParams>(
    tableFilters?.sortColumn || defaultSortColumn,
  );
  const [selectedFilters, setSelectedFilters] = useState<
    Record<string, FilterValue | null>
  >(tableFilters?.selectedFilters || defaultFilters);

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<T> | SorterResult<T>[],
  ) => {
    if (pagination.pageSize !== pageSize) {
      setPageSize(pagination.pageSize!);
    }
    if (pagination.current! - 1 !== page) {
      setPage(pagination.current! - 1);
    }
    if (!Array.isArray(sorter)) {
      if (sorter?.order && sorter?.columnKey) {
        setSortColumn({
          sortDirection: ESortDirection[sorter.order],
          sortField: sorter.columnKey as string,
        });
      } else {
        setSortColumn({});
      }
    }
    setSelectedFilters(filters);
  };

  const hasFilters = useMemo(() => {
    return (
      (!isEmpty(defaultSortColumn) &&
        !isEqual(sortColumn, defaultSortColumn)) ||
      (isEmpty(defaultSortColumn) && !isEmpty(sortColumn)) ||
      (!isEmpty(defaultFilters) && !isEqual(selectedFilters, defaultFilters)) ||
      (isEmpty(defaultFilters) && !isEmpty(selectedFilters))
    );
  }, [selectedFilters, sortColumn]);

  useDidUpdate(() => {
    appLocalStorage.setItem(EStorageNames.filters, {
      ...(storageFilters && storageFilters),
      [storagePath]: {
        ...(storageFilters?.[storagePath] && storageFilters[storagePath]),
        page,
        pageSize,
        sortColumn,
        selectedFilters,
      },
    });
  }, [
    page,
    pageSize,
    sortColumn,
    selectedRows,
    selectedFilters,
    storagePath,
    storageFilters,
  ]);

  useEffect(() => {
    if (tableFilters) {
      setColumns(p =>
        p.map(item => {
          if (selectedFilters[item.key as string]) {
            item.defaultFilteredValue = selectedFilters[item.key as string];
          }
          if (!selectedFilters[item.key as string]) {
            delete item.defaultFilteredValue;
          }
          if (sortColumn.sortField !== item.key) {
            delete item.defaultSortOrder;
          }
          if (sortColumn.sortField === item.key) {
            item.defaultSortOrder =
              sortColumn.sortDirection === ESortDirection.ascend
                ? 'ascend'
                : 'descend';
          }
          return item;
        }),
      );
    }
  }, [selectedFilters, sortColumn]);

  const onFiltersReset = useCallback(() => {
    setSelectedFilters(defaultFilters);
    setSortColumn(defaultSortColumn);
  }, []);

  return {
    page,
    pageSize,
    sortColumn,
    setPage,
    setPageSize,
    setSortColumn,
    handleTableChange,
    selectedFilters,
    hasSelectedFilter: hasFilters,
    setSelectedRows,
    selectedRows,
    columns,
    setColumns,
    hasFilters,
    onFiltersReset,
    setSelectedFilters,
  };
};

export default useTable;
