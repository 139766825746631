import get from 'lodash/get';
import { useTranslation } from '@packages/utils';
import { Modal } from 'components/Base/Modal';
import useForm from 'hooks/useForm';
import { TValidateFieldsError } from 'data/types/generic.types';
import { TWorkshopCreateUI } from 'data/types/workshops.types';
import { useCreateWorkshopMutation } from 'redux/apiSlice/workshops.slice';
import {
  useShowToastNotification,
  ENotificationType,
} from 'components/Base/Notification';
import { TCreateWorkshopModalProps } from './CreateWorkshopModal.types';
import CreateWorkshopForm from './CreateWorkshopForm';

const CreateWorkshopModal = ({ open, setOpen }: TCreateWorkshopModalProps) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.workshops.list.actions.create-workshop.modal',
  });
  const { showToastNotification } = useShowToastNotification();
  const { form } = useForm<TWorkshopCreateUI>();
  const [createWorkshop, { isLoading }] = useCreateWorkshopMutation();

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    try {
      const values = (await form.validateFields()) as TWorkshopCreateUI;
      await createWorkshop(values).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
      handleClose();
    } catch (e) {
      if (!(e as TValidateFieldsError)?.errorFields) {
        const message = get(e, 'data.message');
        showToastNotification({
          message,
          type: ENotificationType.ERROR,
        });
      }
    }
  };

  return (
    <Modal
      title={t('TITLE')}
      open={open}
      onCancel={handleClose}
      onOk={handleSubmit}
      okText={t('SUBMIT_BTN')}
      cancelText={t('CANCEL_BTN')}
      okButtonProps={{ loading: isLoading }}
    >
      <CreateWorkshopForm form={form} />
    </Modal>
  );
};

export default CreateWorkshopModal;
