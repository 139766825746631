import { FC, useEffect, useState } from 'react';
import { useEditElcMeetingActionItemOwnerMutation } from 'redux/apiSlice/elcs.slice';
import {
  useShowToastNotification,
  ENotificationType,
} from 'components/Base/Notification';
import { TRtkErrorType } from 'data/types/generic.types';
import { useTranslation, filterTitleOption } from '@packages/utils';
import useUserOptions from 'hooks/useUserOptions';
import EditableSelect from 'components/Base/EditableFields/EditableSelect';
import { TActionItemOwnerProps } from './ActionItemOwner.types';

const ActionItemOwner: FC<TActionItemOwnerProps> = ({
  id,
  ownerId,
  isNew,
  setNewRowData,
  elcId,
  meetingId,
}) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.meetings-tab.details.action-items.table.editable-cell.owner',
  });
  const { showToastNotification } = useShowToastNotification();

  const [value, setValue] = useState(ownerId);

  useEffect(() => {
    if (ownerId === undefined) return;
    setValue(ownerId);
  }, [ownerId]);

  const [updateOwner, { isLoading, isError }] =
    useEditElcMeetingActionItemOwnerMutation();

  const onUpdateOwner = async (val: string) => {
    if (isNew) {
      setValue(val);
      setNewRowData(prev => ({ ...prev, ownerId: val }));
      return;
    }
    try {
      await updateOwner({
        actionItemId: id,
        elcId,
        meetingId,
        ownerId: val,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (e) {
      showToastNotification({
        message: (e as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  const usersOptions = useUserOptions();
  return (
    <EditableSelect
      onEdit={onUpdateOwner}
      {...(value && {
        title: {
          value,
          label: usersOptions?.find(user => user.value === value)?.title,
        },
      })}
      options={usersOptions}
      isError={isError}
      isLoading={isLoading}
      canRemove
      emptyText={t('TOOLTIP')}
      placeholder={t('PLACEHOLDER')}
      selectProps={{
        filterOption: filterTitleOption,
        showSearch: true,
      }}
    />
  );
};

export default ActionItemOwner;
