import { Col, Divider, Row, Title, useTranslation } from '@packages/utils';
import { useTheme } from 'styled-components';
import CardContent from 'components/Base/Layout/Content/CardContent';
import AddInfo from './AddInfo';
import { TDetailsTabProps } from './DetailsTab.types';
import MainContact from './MainContact';
import AdditionalContacts from './AdditionalContacts';
import PeopleOrganizations from './PeopleOrganizations';

const DetailsTab = ({ data }: TDetailsTabProps) => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.people.details.details-tab',
  });
  const theme = useTheme();
  if (!data) {
    return <></>;
  }

  return (
    <CardContent contentPadding="16px" tabContent>
      <Row justify="space-between">
        <Col>
          <Title level={3}>{t('TITLE')}</Title>
        </Col>
        <Col>
          <AddInfo
            allowActionsEmail={data.additionalEmails.allowedOperations}
            allowActionsMobile={data.additionalPhones.allowedOperations}
            userId={data.id}
          />
        </Col>
      </Row>
      <Row gutter={[12, 12]} className="mt-6">
        <Col sm={11}>
          <Row>
            <MainContact data={data} />
            {(!!data.additionalEmails.value?.length ||
              !!data.additionalPhones.value?.length) && (
              <AdditionalContacts data={data} />
            )}
          </Row>
        </Col>
        <Col sm={1}>
          <Divider
            type="vertical"
            color={theme.colorBlackMainBorder}
            style={{ height: '100%' }}
          />
        </Col>
        <Col sm={12}>
          <PeopleOrganizations
            userOrganizations={data.userOrganizations}
            userId={data.id}
            userName={data.fullName}
          />
        </Col>
      </Row>
    </CardContent>
  );
};

export default DetailsTab;
