import { useTheme } from 'styled-components';
import { TextIcon } from 'components/Base/TextIcon';
import { EIconNames, Icon } from 'components/Base/Icon';
import { TDropdownItemsProps } from './DropdownItems.types';

const DropdownItems = ({ label }: TDropdownItemsProps) => {
  const theme = useTheme();
  return (
    <TextIcon
      label={label}
      alt={label}
      icon={
        <Icon
          icon={EIconNames.ARROW_LEFT_OUTLINED}
          alt="dropdown icon"
          color={theme.colorBlackPrimary}
          size={16}
        />
      }
      strong={false}
      color={theme.colorBlackPrimary}
      fontWeight={theme.fontWeightRegular}
    />
  );
};

export default DropdownItems;
