import React, { FC } from 'react';
import { Form, FormItem } from 'components/Base/Form';
import { TextArea } from 'components/Base/Input';
import useGetValidationData from 'components/Base/Form/validationRules';
import { useTranslation } from '@packages/utils';
import { QUESTION_MAX_LENGTH, TRANSLATION_KEY } from '../QuestionTab.data';
import { TQuestionAddFormProps } from '../QuestionTab.types';

const QuestionAddForm: FC<TQuestionAddFormProps> = ({
  form,
  questionInitialValue,
}) => {
  const { t } = useTranslation({
    keyPrefix: `${TRANSLATION_KEY}.question-form`,
  });

  const { validationRules } = useGetValidationData();

  return (
    <Form form={form}>
      <FormItem
        name="question"
        label={t('label')}
        rules={[validationRules.required()]}
        className="mb-8"
        initialValue={questionInitialValue}
      >
        <TextArea className="mb-1" maxLength={QUESTION_MAX_LENGTH} showCount />
      </FormItem>
    </Form>
  );
};
export default QuestionAddForm;
