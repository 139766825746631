import { FC } from 'react';
import { useTranslation } from '@packages/utils';
import { SelectProps, SelectWithAddProps } from './Select.types';
import { SSelect, SSelectWithAdd } from './Select.styles';

export const Select: FC<SelectProps> = ({ ...props }) => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.generic-components.select',
  });
  const {
    cancelBtnText = t('CANCEL_BTN'),
    submitBtnText = t('SUBMIT_BTN'),
    emptyOptionsLabel = t('EMPTY_OPTIONS_LABEL'),
    ...restProps
  } = props;

  return (
    <SSelect
      cancelBtnText={cancelBtnText}
      submitBtnText={submitBtnText}
      emptyOptionsLabel={emptyOptionsLabel}
      {...restProps}
    />
  );
};

export const SelectWithAdd: FC<SelectWithAddProps> = props => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.generic-components.select',
  });
  const {
    addButtonLabel = t('CREATE_NEW_BTN'),
    placeholder = t('PLACEHOLDER'),
    emptyOptionsLabel = t('EMPTY_OPTIONS_LABEL'),
    ...restProps
  } = props;
  return (
    <SSelectWithAdd
      addButtonLabel={addButtonLabel}
      placeholder={placeholder}
      emptyOptionsLabel={emptyOptionsLabel}
      {...restProps}
    />
  );
};
