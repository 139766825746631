import {
  Col,
  ENotificationType,
  TRtkErrorType,
  useShowToastNotification,
  useTranslation,
} from '@packages/utils';
import { useEditElcMeetingTopicRoadBlockMutation } from 'redux/apiSlice/elcs.slice';
import EditableReachTextEditor from 'components/Base/EditableFields/EditableReachTextEditor';
import { InplaceEditLabels } from 'components/Base/EditableFields/components/InplaceEditLabel';
import { TRoadBlockProps } from './RoadBlock.types';

const RoadBlock = ({
  elcId,
  meetingId,
  roadBlock,
  topicId,
}: TRoadBlockProps) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.meetings-tab.details.topics.edit.roadblocks',
  });
  const { showToastNotification } = useShowToastNotification();

  const [editElcMeetingTopicRoadBlock, { isLoading, isError }] =
    useEditElcMeetingTopicRoadBlockMutation();

  const onEdit = async (value: string) => {
    try {
      await editElcMeetingTopicRoadBlock({
        elcId,
        meetingId,
        topicId,
        roadBlock: value,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (e) {
      showToastNotification({
        message: (e as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  return (
    <>
      <Col className="ml-1">
        <InplaceEditLabels label={t('INPLACE_EDIT_LABEL')} />
      </Col>
      <Col className="mb-4">
        <EditableReachTextEditor
          title={roadBlock}
          fitContent={false}
          onEdit={onEdit}
          emptyText={t('EMPTY_TEXT')}
          placeholder={t('PLACEHOLDER')}
          readonly={false}
          isError={isError}
          isLoading={isLoading}
        />
      </Col>
    </>
  );
};

export default RoadBlock;
