import { FC } from 'react';
import { useTranslation } from '@packages/utils';
import CardContent from 'components/Base/Layout/Content/CardContent';
import { TQuestionTabProps } from './QuestionTab.types';
import QuestionsEmptyView from './components/QuestionsEmptyView';
import QuestionView from './components/QuestionView';
import { TRANSLATION_KEY } from './QuestionTab.data';

const QuestionTab: FC<TQuestionTabProps> = ({ question, workshopId }) => {
  const { t } = useTranslation({
    keyPrefix: `${TRANSLATION_KEY}`,
  });

  return (
    <CardContent title={t('TITLE')} tabContent contentPadding="16px">
      {question?.value ? (
        <QuestionView question={question} workshopId={workshopId} />
      ) : (
        <QuestionsEmptyView workshopId={workshopId} />
      )}
    </CardContent>
  );
};

export default QuestionTab;
