import get from 'lodash/get';
import { useTranslation } from '@packages/utils';
import { RoutesPaths } from 'routes/Routes.types';
import { TValidateFieldsError } from 'data/types/generic.types';
import { useDuplicateWorkshopMutation } from 'redux/apiSlice/workshops.slice';
import {
  useShowToastNotification,
  ENotificationType,
} from 'components/Base/Notification';
import { EIconNames } from 'components/Base/Icon';
import ActionGroupItemLabel from 'components/Shared/ActionsButtonDetailsPage/ActionGroupItemLabel';
import { TActionProps } from '../Actions.types';

const DuplicateAction = ({ id }: TActionProps) => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.workshops.details.actions.duplicate',
  });
  const { showToastNotification } = useShowToastNotification();
  const [duplicateWorkshop] = useDuplicateWorkshopMutation();

  const handleDuplicate = async () => {
    try {
      const duplicateId = await duplicateWorkshop({ workshopId: id }).unwrap();

      if (duplicateId) {
        window.open(
          `/app/${RoutesPaths.WORKSHOP}/${duplicateId}?workshopAction=duplicated`,
        );
      }
    } catch (e) {
      if (!(e as TValidateFieldsError)?.errorFields) {
        const message = get(e, 'data.message');
        showToastNotification({
          message,
          type: ENotificationType.ERROR,
        });
      }
    }
  };

  return (
    <ActionGroupItemLabel
      label={t('DUPLICATE_BTN')}
      icon={EIconNames.DUPLICATE}
      onClick={handleDuplicate}
    />
  );
};

export default DuplicateAction;
