import { EAgeGroup } from './generic.types';
import { TTagDB, TTagUI } from './tags.types';
import {
  EWorkshopPartType,
  EWorkshopSeriesType,
  EWorkshopStandaloneType,
} from './workshops.types';

export type TWorkshopSessionDateUI = {
  partType: EWorkshopPartType;
  date: string | null;
};

export enum EFamilyFilterParams {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  MOBILE = 'phone',
  LOCATION = 'locationIds',
  TYPE = 'workshopTypes',
  TAGS = 'tagIds',
}

export type TWorkshopSessionDateDB = Record<EWorkshopPartType, string | null>;

export type TFamilyDataUi = {
  lastName: string;
  firstName: string;
  email: string;
  phone: string;
  childrenNames: string[];
  workshopId: string;
  workshopClassId: string;
  registrantId: string;
  workshopType: EWorkshopStandaloneType | EWorkshopSeriesType;
  ageGroup: EAgeGroup;
  location: string;
  sessionDates: TWorkshopSessionDateUI[];
  tags?: TTagUI[];
};

export type TFamilyDataDB = {
  lastName: string;
  firstName: string;
  email: string;
  phone: string;
  childrenNames: string[];
  workshopId: string;
  workshopClassId: string;
  registrantId: string;
  workshopType: EWorkshopStandaloneType | EWorkshopSeriesType;
  ageGroup: EAgeGroup;
  location: string;
  sessionDates: TWorkshopSessionDateDB;
  tags?: TTagDB[];
};

export type TEditFamilyUI = {
  workshopId: string;
  workshopClassId: string;
  registrantId: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
};

export type TEditFamilyDB = {
  workshopId: string;
  workshopClassId: string;
  registrantId: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
};
