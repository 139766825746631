/* eslint-disable @typescript-eslint/no-explicit-any */
import { regexPatterns } from '@idaho-aeyc/ui-kit';
import { RuleObject } from 'antd/lib/form';
import dayjs from 'dayjs';
import { useTranslation } from '@packages/utils';

const useGetValidationData = () => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.validation-errors',
  });

  const EValidationMessages = {
    NEGATIVE_IMPOSSIBLE: t('NEGATIVE_IMPOSSIBLE'),
    ONLY_NUMBERS: t('ONLY_NUMBERS'),
    DECREASE_IMPOSSIBLE: t('DECREASE_IMPOSSIBLE'),
    THIS_FIELD_IS_REQUIRED: t('THIS_FIELD_IS_REQUIRED'),
    INCORRECT_EMAIL_ADDRESS: t('INCORRECT_EMAIL_ADDRESS'),
    INVALID_PHONE_FORMAT: t('INVALID_PHONE_FORMAT'),
    CANT_START_WITH_SPACE: t('CANT_START_WITH_SPACE'),
    ACCEPT_TERMS_AND_CONDITIONS: t('ACCEPT_TERMS_AND_CONDITIONS'),
    PASSWORD_DONT_MATCH: t('PASSWORD_DONT_MATCH'),
    END_DATE_MUST_BE_LATER: t('END_DATE_MUST_BE_LATER'),
    START_DATE_MUST_BE_EARLIER: t('START_DATE_MUST_BE_EARLIER'),
    START_END_SHOULD_DIFFER: t('START_END_SHOULD_DIFFER'),
    END_START_SHOULD_DIFFER: t('END_START_SHOULD_DIFFER'),
    END_SHOULD_BE_LATER: t('END_SHOULD_BE_LATER'),
    START_SHOULD_BE_EARLIER: t('START_SHOULD_BE_EARLIER'),
    VALUE_SHOULDNT_BE_NEGATIVE: t('VALUE_SHOULDNT_BE_NEGATIVE'),
    VALUE_BETWEEN_0_AND_100: t('VALUE_BETWEEN_0_AND_100'),
    VALUE_SHOULD_BE_POSITIVE: t('VALUE_SHOULD_BE_POSITIVE'),
    VALUE_SHOULD_CONTAIN_ONE_DECIMAL: t('VALUE_SHOULD_CONTAIN_ONE_DECIMAL'),
    ONLY_ENGLISH_LETTERS_ALLOWED: t('ONLY_ENGLISH_LETTERS_ALLOWED'),
    PROVIDE_CORRECT_ADDRESS: t('PROVIDE_CORRECT_ADDRESS'),
    PROVIDE_ADDRESS_IN_IDAHO: t('PROVIDE_ADDRESS_IN_IDAHO'),
    INVALID_URL: t('INVALID_URL'),
    PASSWORD: t('PASSWORD'),
    MAX_LIMIT: t('MAX_LIMIT'),
  };

  const validationRules = {
    required: (fieldName?: string) => ({
      validator(_: RuleObject, value: any) {
        if (
          (typeof value === 'string' && value.trim()) ||
          (typeof value !== 'string' && !!value)
        ) {
          return Promise.resolve();
        }
        return Promise.reject(
          new Error(
            fieldName
              ? t('PLACEHOLDER_FIELD_IS_REQUIRED', {
                  placeholder: fieldName,
                })
              : EValidationMessages.THIS_FIELD_IS_REQUIRED,
          ),
        );
      },
    }),
    requiredField: (fieldName?: string) => ({
      required: true,
      message: fieldName
        ? t('PLACEHOLDER_FIELD_IS_REQUIRED', {
            placeholder: fieldName,
          })
        : EValidationMessages.THIS_FIELD_IS_REQUIRED,
    }),
    email: () => ({
      pattern: regexPatterns.email,
      message: EValidationMessages.INCORRECT_EMAIL_ADDRESS,
    }),
    multipleEmails: () => ({
      pattern: regexPatterns.emailsSeparatedWithComma,
      message: EValidationMessages.INCORRECT_EMAIL_ADDRESS,
    }),
    phoneNumber: () => ({
      pattern: regexPatterns.phoneNumber,
      message: EValidationMessages.INVALID_PHONE_FORMAT,
    }),
    withoutSpace: (fieldName: string) => ({
      validator(_: RuleObject, value: string) {
        if (!regexPatterns.startEndWhiteSpace.test(value)) {
          return Promise.resolve();
        }
        return Promise.reject(
          new Error(
            fieldName
              ? `${fieldName} can't start or end with white space!`
              : EValidationMessages.CANT_START_WITH_SPACE,
          ),
        );
      },
    }),
    password: () => ({
      validator(_: RuleObject, value: any) {
        const regex =
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[~`!@#$%^&*()\-_+={}[\]|\\;:"<>,./?]).{8,}$/;
        if (!value) {
          return Promise.reject(
            new Error(EValidationMessages.THIS_FIELD_IS_REQUIRED),
          );
        }
        if (regex.test(value)) {
          return Promise.resolve();
        }

        return Promise.reject(new Error(EValidationMessages.PASSWORD));
      },
    }),

    acceptTerms: [
      {
        validator(_: RuleObject, value: any) {
          if (value) {
            return Promise.resolve();
          }
          return Promise.reject(
            new Error(EValidationMessages.ACCEPT_TERMS_AND_CONDITIONS),
          );
        },
      },
    ],
    passwordConfirm: [
      // @ts-ignore
      ({ getFieldValue }) => ({
        validator(_: RuleObject, value: any) {
          if (!value) {
            return Promise.reject(
              new Error(EValidationMessages.THIS_FIELD_IS_REQUIRED),
            );
          }
          if (getFieldValue('password') === value) {
            return Promise.resolve();
          }
          return Promise.reject(
            new Error(EValidationMessages.PASSWORD_DONT_MATCH),
          );
        },
      }),
    ],
    startTime: [
      // @ts-ignore
      ({ getFieldValue, setFields }) => ({
        validator(_: RuleObject, value: string) {
          if (!value) {
            return Promise.reject(
              new Error(EValidationMessages.THIS_FIELD_IS_REQUIRED),
            );
          }
          if (dayjs(getFieldValue('endTime')).isSame(dayjs(value), 'minutes')) {
            return Promise.reject(
              new Error(EValidationMessages.START_END_SHOULD_DIFFER),
            );
          }
          if (
            !getFieldValue('endTime') ||
            dayjs(getFieldValue('endTime')).isAfter(dayjs(value))
          ) {
            setFields([
              {
                name: 'endTime',
                errors: [],
              },
            ]);
            return Promise.resolve();
          }
          return Promise.reject(
            new Error(EValidationMessages.START_DATE_MUST_BE_EARLIER),
          );
        },
      }),
    ],
    endTime: [
      // @ts-ignore
      ({ getFieldValue, setFields }) => ({
        validator(_: RuleObject, value: string) {
          if (!value) {
            return Promise.reject(
              new Error(EValidationMessages.THIS_FIELD_IS_REQUIRED),
            );
          }
          if (
            dayjs(getFieldValue('startTime')).isSame(dayjs(value), 'minutes')
          ) {
            return Promise.reject(
              new Error(EValidationMessages.END_START_SHOULD_DIFFER),
            );
          }
          if (
            !getFieldValue('startTime') ||
            dayjs(getFieldValue('startTime')).isBefore(dayjs(value))
          ) {
            setFields([
              {
                name: 'startTime',
                errors: [],
              },
            ]);
            return Promise.resolve();
          }
          return Promise.reject(
            new Error(EValidationMessages.END_SHOULD_BE_LATER),
          );
        },
      }),
    ],
    startDate: [
      // @ts-ignore
      ({ getFieldValue, setFields }) => ({
        validator(_: RuleObject, value: string) {
          if (!value) {
            return Promise.reject(
              new Error(EValidationMessages.THIS_FIELD_IS_REQUIRED),
            );
          }
          if (
            !getFieldValue('endDate') ||
            dayjs(getFieldValue('endDate')).isAfter(dayjs(value))
          ) {
            setFields([
              {
                name: 'endDate',
                errors: [],
              },
            ]);
            return Promise.resolve();
          }
          return Promise.reject(
            new Error(EValidationMessages.START_SHOULD_BE_EARLIER),
          );
        },
      }),
    ],
    endDate: [
      // @ts-ignore
      ({ getFieldValue, setFields }) => ({
        validator(_: RuleObject, value: string) {
          if (!value) {
            return Promise.reject(
              new Error(EValidationMessages.THIS_FIELD_IS_REQUIRED),
            );
          }
          if (
            !getFieldValue('startDate') ||
            dayjs(getFieldValue('startDate')).isBefore(dayjs(value))
          ) {
            setFields([
              {
                name: 'startDate',
                errors: [],
              },
            ]);
            return Promise.resolve();
          }
          return Promise.reject(
            new Error(EValidationMessages.END_DATE_MUST_BE_LATER),
          );
        },
      }),
    ],
    endDateOptional: [
      // @ts-ignore
      ({ getFieldValue, setFields }) => ({
        validator(_: RuleObject, value: string) {
          if (!value) {
            return Promise.resolve();
          }
          if (
            !getFieldValue('startDate') ||
            dayjs(getFieldValue('startDate')).isBefore(dayjs(value))
          ) {
            setFields([
              {
                name: 'startDate',
                errors: [],
              },
            ]);
            return Promise.resolve();
          }
          return Promise.reject(
            new Error(EValidationMessages.END_DATE_MUST_BE_LATER),
          );
        },
      }),
    ],
    startTimeOptional: [
      // @ts-ignore
      ({ getFieldValue, setFields }) => ({
        validator(_: RuleObject, value: string) {
          if (
            !getFieldValue('endTime') ||
            dayjs(getFieldValue('endTime')).isAfter(dayjs(value))
          ) {
            setFields([
              {
                name: 'endTime',
                errors: [],
              },
            ]);
            return Promise.resolve();
          }
          return Promise.reject(
            new Error(EValidationMessages.START_DATE_MUST_BE_EARLIER),
          );
        },
      }),
    ],
    endTimeOptional: [
      // @ts-ignore
      ({ getFieldValue, setFields }) => ({
        validator(_: RuleObject, value: string) {
          if (
            !getFieldValue('startTime') ||
            dayjs(getFieldValue('startTime')).isBefore(dayjs(value))
          ) {
            setFields([
              {
                name: 'startTime',
                errors: [],
              },
            ]);
            return Promise.resolve();
          }
          return Promise.reject(
            new Error(EValidationMessages.END_SHOULD_BE_LATER),
          );
        },
      }),
    ],
    integerMoreThanZeroOptional: () => ({
      validator(_: RuleObject, value: any) {
        if (value > 0 || !value) {
          return Promise.resolve();
        }
        return Promise.reject(
          new Error(EValidationMessages.VALUE_SHOULDNT_BE_NEGATIVE),
        );
      },
    }),
    integerFromZeroToHundred: () => ({
      validator(_: RuleObject, value: any) {
        if ((value >= 0 && value <= 100) || !value) {
          return Promise.resolve();
        }
        return Promise.reject(
          new Error(EValidationMessages.VALUE_BETWEEN_0_AND_100),
        );
      },
    }),
    integerWithDecimalsRequired: () => ({
      validator(_: RuleObject, value: any) {
        if (!value || Number.isNaN(+value)) {
          return Promise.reject(
            new Error(EValidationMessages.THIS_FIELD_IS_REQUIRED),
          );
        }
        if (!value.match(/^[+-](\d*\.)?\d+$/g)) {
          return Promise.resolve();
        }
        return Promise.reject(
          new Error(EValidationMessages.VALUE_SHOULD_BE_POSITIVE),
        );
      },
    }),
    integerWithDecimalsOptional: () => ({
      validator(_: RuleObject, value: any) {
        if (!value.match(/^[+-](\d*\.)?\d+$/g)) {
          return Promise.resolve();
        }
        return Promise.reject(
          new Error(EValidationMessages.VALUE_SHOULD_BE_POSITIVE),
        );
      },
    }),

    integerOptional: () => ({
      validator(_: RuleObject, value: any) {
        if (!value.match(/[^\d]/g)) {
          return Promise.resolve();
        }
        return Promise.reject(
          new Error(EValidationMessages.VALUE_SHOULD_BE_POSITIVE),
        );
      },
    }),
    onePointDecimal: () => ({
      validator(_: RuleObject, value: any) {
        if (!!value && !!value.split('.')[1]) {
          if (value.split('.')[1].length > 1) {
            return Promise.reject(
              new Error(EValidationMessages.VALUE_SHOULD_CONTAIN_ONE_DECIMAL),
            );
          }
        }
        return Promise.resolve();
      },
    }),
    domainNameOptional: () => ({
      validator(_: RuleObject, value: string[]) {
        if (
          !value ||
          !value.length ||
          value.every(item => item.match(regexPatterns.domainName))
        ) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('Invalid domain name!'));
      },
    }),
    onlyEnglishLetters: () => ({
      pattern: regexPatterns.onlyEnglishLetters,
      message: EValidationMessages.ONLY_ENGLISH_LETTERS_ALLOWED,
    }),
    address: () => ({
      validator(
        _: RuleObject,
        value: Record<string, string> | undefined | any,
      ) {
        if (!value) {
          return Promise.resolve();
        }
        if (value?.target && !value.target.value) {
          return Promise.resolve();
        }
        if (
          !Object.values(value).length ||
          Object.values(value).some(item => !item)
        ) {
          return Promise.reject(
            new Error(EValidationMessages.PROVIDE_CORRECT_ADDRESS),
          );
        }
        if (value.state && value.state.toLowerCase() !== 'idaho') {
          return Promise.reject(
            new Error(EValidationMessages.PROVIDE_ADDRESS_IN_IDAHO),
          );
        }
        return Promise.resolve();
      },
    }),
    validUrl: () => ({
      validator(_: RuleObject, value: string) {
        if (value) {
          try {
            // eslint-disable-next-line no-new
            new URL(value);
            return Promise.resolve();
          } catch (error) {
            return Promise.reject(new Error(EValidationMessages.INVALID_URL));
          }
        }
        return Promise.resolve();
      },
    }),
    classCapacityValidation: () => ({
      validator(_: RuleObject, value: string) {
        if (!value) return Promise.resolve();
        const numericValue = Number(value);
        if (numericValue < 0) {
          return Promise.reject(
            new Error(EValidationMessages.NEGATIVE_IMPOSSIBLE),
          );
        }

        if (value.match(/[^\d]/g)) {
          return Promise.reject(new Error(EValidationMessages.ONLY_NUMBERS));
        }

        // if (
        //   workshopStatus === EWorkshopStatus.PUBLISHED &&
        //   (number > numericValue || number === 0) // 0 is case when capacity is unlimited
        // ) {
        //   return Promise.reject(
        //     new Error(EValidationMessages.DECREASE_IMPOSSIBLE),
        //   );
        // }

        return Promise.resolve();
      },
    }),
    positiveNumbers: () => ({
      validator(_: RuleObject, value: string) {
        if (!value) return Promise.resolve();
        const numericValue = Number(value);
        if (numericValue < 0) {
          return Promise.reject(
            new Error(EValidationMessages.NEGATIVE_IMPOSSIBLE),
          );
        }
        if (value.match(/[^\d]/g)) {
          return Promise.reject(new Error(EValidationMessages.ONLY_NUMBERS));
        }
        return Promise.resolve();
      },
    }),
    maxLimit: (limit: number) => ({
      validator: (_: RuleObject, value: any[]) => {
        if (value?.length >= limit) {
          // You can customize the validation message by overriding the message field when you call this validation rule. Like in CreateWorkshopSubscribersForm.data.ts file.
          return Promise.reject(new Error(EValidationMessages.MAX_LIMIT));
        }
        return Promise.resolve();
      },
    }),
  };
  return { EValidationMessages, validationRules };
};

export default useGetValidationData;
