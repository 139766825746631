import { useTranslation } from '@packages/utils';
import { TColumnType } from 'components/Base/Table/Table.types';
import { Col, Row } from 'components/Base/Grid';
import {
  ERegistrantsColumnKey,
  TRegistrantUI,
} from 'data/types/registrants.types';
import useGetTableColumnData from 'components/Base/Table/components/SearchFilter';
import AgeGroupesColumn from 'components/Shared/AgeGroupesColumn';
import { ParagraphEllipsis } from 'components/Shared/ParagraphEllipsis';
import { ANSWER_MAX_LENGTH } from '../../WaitlistTab/WorkshopWaitList/WorkshopWaitList.data';
import { TFilterRegistrantsColumns } from './RegistrantsList.types';

const useGetRegistrantsColumnsData = () => {
  const { getTableColumnSearchProps } = useGetTableColumnData();

  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.workshops.details.workshop-registrants.table.title',
  });
  const workshopRegistrantsColumns: TColumnType<TRegistrantUI>[] = [
    {
      title: t('REGISTRATION_DATE'),
      dataIndex: 'createdDate',
      key: ERegistrantsColumnKey.CREATION_DATE,
      sorter: true,
      onCell: () => ({
        style: { width: '145px' },
      }),
    },
    {
      title: t('FIRST_NAME'),
      dataIndex: 'firstName',
      key: ERegistrantsColumnKey.FIRST_NAME,
      sorter: true,
      ...getTableColumnSearchProps<TRegistrantUI>(),
    },
    {
      title: t('LAST_NAME'),
      dataIndex: 'lastName',
      key: ERegistrantsColumnKey.LAST_NAME,
      sorter: true,
      ...getTableColumnSearchProps<TRegistrantUI>(),
    },
    {
      title: t('NUMBER_OF_CHILDREN'),
      dataIndex: 'countOfChildren',
      key: 'countOfChildren',
      onCell: () => ({
        style: { width: '70px' },
      }),
    },
    {
      title: t('CHILDREN_NAMES'),
      dataIndex: 'childrenNames',
      key: 'childrenNames',
      render: (text, record) => (
        <Row gutter={[0, 8]}>
          {record?.childrenNames?.map(name => (
            <Col key={name} span={24}>
              {name}
            </Col>
          ))}
        </Row>
      ),
      onCell: () => ({
        style: { width: '180px' },
      }),
    },
    {
      title: t('MOBILE'),
      dataIndex: 'phone',
      key: 'phone',
      ellipsis: true,
    },
    {
      title: t('EMAIL'),
      dataIndex: 'email',
      key: 'email',
      onCell: () => ({
        style: { maxWidth: '130px' },
      }),
    },
    {
      title: t('AGE_GROUP'),
      dataIndex: 'ageGroup',
      key: ERegistrantsColumnKey.FILTER_BY_LANGUAGE_AND_AGE_GROUP,
      filters: [],
      filterMode: 'tree',
      filterMultiple: true,
      render: (_, { englishAgeGroups, spanishAgeGroups }) => {
        return (
          <AgeGroupesColumn
            englishSessions={englishAgeGroups}
            spanishSessions={spanishAgeGroups}
          />
        );
      },
    },
    {
      title: t('QUESTION_ANSWER'),
      dataIndex: 'customQuestionAnswer',
      key: 'customQuestionAnswer',
      render: answer => {
        return (
          <ParagraphEllipsis text={answer} textMaxLength={ANSWER_MAX_LENGTH} />
        );
      },
      onCell: () => ({
        style: { minWidth: '180px' },
      }),
    },
    {
      width: 250,
      title: t('ATTENDANCE_KITS'),
      dataIndex: 'attendances',
      key: 'attendances',
      fixed: 'right',
      onCell: record => ({
        className: record.hasUpdatableAttendance ? 'custom-cell' : '',
        style: {
          minWidth: '250px',
          borderSpacing: 'initial',
        },
      }),
      render: () => null,
    },
  ];

  return { workshopRegistrantsColumns };
};

const filterRegistrantsColumns = ({
  customQuestion,
  hideChildInfo,
  columns,
  setColumns,
}: TFilterRegistrantsColumns) => {
  if (!customQuestion || hideChildInfo) {
    const filteredColumns = columns.filter(col => {
      if (!customQuestion && col.dataIndex === 'customQuestionAnswer') {
        return false;
      }
      if (
        hideChildInfo &&
        (col.dataIndex === 'countOfChildren' ||
          col.dataIndex === 'childrenNames')
      ) {
        return false;
      }
      return true;
    });
    setColumns(filteredColumns);
  }
};

export { filterRegistrantsColumns };

export default useGetRegistrantsColumnsData;
