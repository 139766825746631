import { TSessionViewProps } from './SessionView.types';
import SessionViewItem from './SessionViewItem';

const SessionView = ({
  typeCategory,
  classes,
  workshopId,
  possibleAgeGroup,
  onDuplicate,
  workshopStatus,
  customQuestion,
  isCCP,
}: TSessionViewProps) => {
  const { allowedOperations, value } = classes;

  return (
    <>
      {value?.map(item => {
        return (
          <SessionViewItem
            key={item.id}
            allowedOperations={allowedOperations}
            classItem={item}
            onDuplicate={onDuplicate}
            workshopId={workshopId}
            workshopStatus={workshopStatus}
            possibleAgeGroup={possibleAgeGroup}
            typeCategory={typeCategory}
            customQuestion={customQuestion}
            isCCP={isCCP}
          />
        );
      })}
    </>
  );
};

export default SessionView;
