import { FC } from 'react';
import { useTheme } from 'styled-components';
import { useTranslation, Button } from '@packages/utils';
import { EIconNames, Icon } from 'components/Base/Icon';
import { TActionButtonProps } from '../QuestionTab.types';

const ActionButton: FC<TActionButtonProps> = ({ type, onClick }) => {
  const theme = useTheme();
  const { t } = useTranslation({
    keyPrefix: 'descriptions.tooltip',
  });

  switch (type) {
    case 'delete':
      return (
        <Button
          icon={
            <Icon
              alt="delete icon"
              icon={EIconNames.DELETE}
              size={14}
              color={theme.colorErrorDefault}
              className="pointer"
            />
          }
          tooltip={t('DELETE')}
          onClick={onClick}
          color="primary"
          shape="circle"
          size="middle"
        />
      );
    case 'edit':
      return (
        <Button
          icon={
            <Icon
              alt="Edit icon"
              icon={EIconNames.EDIT_FILLED}
              color={theme.colorPinkActive}
              size={16}
              className="pointer"
            />
          }
          tooltip={t('EDIT')}
          color="primary"
          onClick={onClick}
          shape="circle"
          size="middle"
        />
      );
    default:
      return null;
  }
};

export default ActionButton;
