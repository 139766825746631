import { Row, Title, useTranslation } from '@packages/utils';
import { TElcSpecificDocsSectionProps } from './ElcSpecificDocsSection.types';
import ResourceItem from '../components/ResourceItem';

const ElcSpecificDocsSection = ({
  elcId,
  data,
  isResourceEditable,
}: TElcSpecificDocsSectionProps) => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.elcs.detail.resources-tab.sections',
  });
  return (
    <>
      <Title level={4} className="mt-6 mb-3">
        {t('ELC_SPECIFIC_DOCS')}
      </Title>
      <Row gutter={[16, 16]}>
        {data.map(item => (
          <ResourceItem
            key={item.resourceId}
            data={item}
            elcId={elcId}
            isResourceEditable={isResourceEditable}
          />
        ))}
      </Row>
    </>
  );
};

export default ElcSpecificDocsSection;
