import { FC, useState } from 'react';
import { useTranslation } from '@packages/utils';
import { Modal } from 'components/Base/Modal';
import { Col } from 'components/Base/Grid';
import {
  useShowToastNotification,
  ENotificationType,
} from 'components/Base/Notification';
import { TRtkErrorType } from 'data/types/generic.types';
import ActionGroupItemLabel from "components/Shared/ActionsButtonDetailsPage/ActionGroupItemLabel";
import ConfirmModalBody from "components/Shared/ConfirmModalBody";
import { useMarkAsNoAddressActionMutation } from "redux/apiSlice/kitManagement.slice";
import { TActionProps } from "./MarkAsNoAddress.types";


const MarkAsNoAddress: FC<TActionProps> = ({
  setShowAlert,
  selectedRows,
}) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.kit-management.list.actions.mark-as-no-address',
  });
  const { showToastNotification } = useShowToastNotification();
  const [open, setOpen] = useState(false);
  const orderIds = selectedRows?.map(row => row.key) || [];
  const [markAsNoAddress, { isLoading }] = useMarkAsNoAddressActionMutation();

  const handleCloseModal = () => {
    setOpen(false);
  };
  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleSubmit = async () => {
    try {
      await markAsNoAddress({
        parentOrderIds: orderIds,
      }).unwrap();
      showToastNotification({
        message: t('modal.SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (err) {
      showToastNotification({
        message: (err as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
    handleCloseModal();
  };

  const handleActionButtonClick = () => {
    if (selectedRows.length) {
      handleOpenModal();
    } else {
      setShowAlert(true);
    }
  };

  return (
    <Col>
      <ActionGroupItemLabel
          onClick={handleActionButtonClick}
          label={t('BUTTON')}
      />
      <Modal
        open={open}
        title={t('modal.TITLE')}
        okText={t('modal.SUBMIT_BTN')}
        cancelText={t('modal.CANCEL_BTN')}
        onCancel={handleCloseModal}
        onOk={handleSubmit}
        confirmLoading={isLoading}
      >
        <ConfirmModalBody title={t('modal.SUBTITLE')} description={t('modal.DESCRIPTION')}/>
      </Modal>
    </Col>
  );
};

export default MarkAsNoAddress;
