import { FC } from 'react';
import { Button } from '@packages/utils';
import { Icon } from 'components/Base/Icon';
import { Col } from 'components/Base/Grid';
import { TActionButtonProps } from './ActionButton.types';

const ActionButton: FC<TActionButtonProps> = ({
  onClick,
  color,
  icon,
  tooltipTitle,
  iconAlt,
}) => {
  return (
    <Col>
      <Button
        onClick={onClick}
        icon={<Icon alt={iconAlt} icon={icon} size={14} color={color} />}
        tooltip={tooltipTitle}
        color="primary"
        shape="circle"
        size="middle"
      />
    </Col>
  );
};

export default ActionButton;
