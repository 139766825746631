import { useTranslation, Card, Col, Row } from '@packages/utils';
import FacilitatorSelect from '../../components/FacilitatorSelect';
import { TSessionItemPartViewProps } from '../../SessionView.types';
import CopySessionAttendanceUrl from '../../components/Actions/CopySessionAttendanceUrl';

const SeriesPartsView = ({
  classItem,
  workshopId,
  workshopStatus,
}: TSessionItemPartViewProps) => {
  const { t } = useTranslation({
    keyPrefix: 'enums.workshop-part-type',
  });
  const { id, sessions } = classItem;

  return (
    <>
      {sessions.map((item, index) => {
        const key = index;
        const { facilitator, id: workshopSessionId, partType } = item;
        return (
          <Col lg={8} md={24} sm={24} key={key}>
            <Card
              title={
                <Row justify="space-between" align="middle">
                  <Col>{t(`${partType}`)}</Col>
                  <Col>
                    <CopySessionAttendanceUrl
                      sessionId={item.id}
                      workshopId={workshopId}
                      workshopStatus={workshopStatus}
                    />
                  </Col>
                </Row>
              }
              className="mt-4"
            >
              <FacilitatorSelect
                id={id}
                facilitator={facilitator}
                workshopId={workshopId}
                workshopSessionId={workshopSessionId}
              />
            </Card>
          </Col>
        );
      })}
    </>
  );
};

export default SeriesPartsView;
