import { FC } from 'react';
import { get } from 'lodash';
import { useTranslation } from '@packages/utils';
import { Modal } from 'components/Base/Modal';
import useForm from 'hooks/useForm';
import { useCreateWorkshopSubscriberMutation } from 'redux/apiSlice/subscibers.slice';
import { TValidateFieldsError } from 'data/types/generic.types';
import {
  useShowToastNotification,
  ENotificationType,
} from 'components/Base/Notification';
import { TCreateWorkshopSubscribersForm } from './CreateWorkshopSubscribersForm/CreateWorkshopSubscribersForm.types';
import CreateWorkshopSubscriberForm from './CreateWorkshopSubscribersForm';
import { TCreateWorkshopSubscribersModalProps } from './CreateWorkshopSubscribersModal.types';

const CreateWorkshopSubscribersModal: FC<
  TCreateWorkshopSubscribersModalProps
> = ({ open, setOpen }) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.subscribers.list.actions.add-subscriber.modal',
  });
  const { showToastNotification } = useShowToastNotification();
  const { form } = useForm<TCreateWorkshopSubscribersForm>();
  const [createWorkshopSubscriber, { isLoading }] =
    useCreateWorkshopSubscriberMutation();

  const handleClose = () => {
    setOpen(false);
    form.resetFields();
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      await createWorkshopSubscriber(values).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
      handleClose();
    } catch (e) {
      if (!(e as TValidateFieldsError)?.errorFields) {
        const message = get(e, 'data.message');
        showToastNotification({
          message,
          type: ENotificationType.ERROR,
        });
      }
    }
  };

  return (
    <Modal
      title={t('TITLE')}
      open={open}
      onCancel={handleClose}
      onOk={handleSubmit}
      okText={t('SUBMIT_BTN')}
      cancelText={t('CANCEL_BTN')}
      okButtonProps={{ loading: isLoading }}
    >
      <CreateWorkshopSubscriberForm form={form} />
    </Modal>
  );
};

export default CreateWorkshopSubscribersModal;
