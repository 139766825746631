import { useTranslation } from '@packages/utils';
import { Form, FormItem } from 'components/Base/Form';
import { TextArea } from 'components/Base/Input';
import { Select } from 'components/Base/Select';
import useGetFormData from './AddDetailsForm.data';
import { TAddDetailsFormProps } from './AddDetailsForm.types';
import { addDetailsSectionKey } from '../../AddDetailsSection.data';

const AddDetailsForm = ({
  form,
  note: noteDetails,
  categories,
}: TAddDetailsFormProps) => {
  const { addDetailsFormFields } = useGetFormData();
  const { t } = useTranslation({
    keyPrefix: `${addDetailsSectionKey}.notes-categories`,
  });
  const { category, note } = addDetailsFormFields;

  return (
    <Form
      form={form}
      initialValues={{
        category: noteDetails ? t(noteDetails.category) : null,
        note: noteDetails?.note || '',
      }}
    >
      <FormItem {...category}>
        <Select options={categories} placeholder={category.placeholder} />
      </FormItem>
      <FormItem {...note}>
        <TextArea
          placeholder={note.placeholder}
          className="mb-4"
          maxLength={350}
          showCount
        />
      </FormItem>
    </Form>
  );
};

export default AddDetailsForm;
