import { TEditableReachTextEditorProps } from './EditableReachTextEditor.types';
import { SEditableReachTextEditor } from './EditableReachTextEditor.styles';

const EditableReachTextEditor = (props: TEditableReachTextEditorProps) => {
  const { ...restProps } = props;

  return <SEditableReachTextEditor {...restProps} />;
};

export default EditableReachTextEditor;
