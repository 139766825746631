import { TextArea } from '@packages/utils';
import { Form, FormItem } from 'components/Base/Form';
import { Input, PhoneInput } from 'components/Base/Input';
import { TCreateCoordinatorFormProps } from './CreateCoordinatorForm.types';
import useGetFormData from './CreateCoordinatorForm.data';

const CreateCoordinatorForm = ({ form, data }: TCreateCoordinatorFormProps) => {
  const { createCoordinatorFormFields } = useGetFormData();
  const { firstName, lastName, email, phone, title } =
    createCoordinatorFormFields;

  return (
    <Form
      form={form}
      initialValues={{
        firstName: data?.firstName || '',
        lastName: data?.lastName || '',
        email: data?.email || '',
        phone: data?.phone || '',
        title: data?.title || '',
      }}
    >
      <FormItem {...firstName}>
        <Input placeholder={firstName.placeholder} />
      </FormItem>
      <FormItem {...lastName}>
        <Input placeholder={lastName.placeholder} />
      </FormItem>
      <FormItem {...title}>
        <TextArea placeholder={title.placeholder} maxLength={250} showCount />
      </FormItem>
      <FormItem {...email}>
        <Input placeholder={email.placeholder} />
      </FormItem>
      <FormItem {...phone}>
        <PhoneInput placeholder={phone.placeholder} />
      </FormItem>
    </Form>
  );
};

export default CreateCoordinatorForm;
