import { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import { TClassItemUI } from 'data/types/workshops.types';
import Spinner from 'components/Base/Spinner';
import { Row } from 'components/Base/Grid';
import CreateSessionCard from './CreateSessionCard';
import CreateSessionEmptyView from './CreateSessionEmptyView';
import { TFormData, TSessionsProps } from './Sessions.types';
import SessionView from './SessionView';

const Sessions = ({
  workshop,
  showForm,
  setShowForm,
  id,
  isWorkshopDataFetching,
}: TSessionsProps) => {
  const [formValues, setFormValues] = useState<TFormData | null>(null);
  const createSessionCardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (showForm) {
      createSessionCardRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, [showForm]);

  if (!workshop) {
    return null;
  }

  const handleDuplicate = (data: TClassItemUI) => {
    dayjs.extend(localeData);
    dayjs.extend(weekday);
    if (!showForm) {
      const sessionsList = data.sessions.map(item => ({
        facilitator: item.facilitator.id,
      }));
      setFormValues({
        age: data.ageGroup.value.value,
        language: data.language.value.label,
        capacity: data.capacity.value,
        sessions: sessionsList,
      });
      setShowForm(true);
    }
  };

  return (
    <>
      {isWorkshopDataFetching && !workshop.classes.value?.length && (
        <Row justify="center">
          <Spinner spinnerSize={24} />
        </Row>
      )}
      <div ref={createSessionCardRef}>
        {showForm && (
          <CreateSessionCard
            setShowForm={setShowForm}
            setFormValues={setFormValues}
            id={id}
            formValues={formValues}
            typeCategory={workshop.typeCategory}
            possibleAgeGroup={workshop.possibleAgeGroups}
          />
        )}
      </div>
      {workshop.classes.value?.length ? (
        <SessionView
          possibleAgeGroup={workshop.possibleAgeGroups}
          workshopId={id}
          classes={workshop.classes}
          typeCategory={workshop.typeCategory}
          onDuplicate={handleDuplicate}
          workshopStatus={workshop.status}
          customQuestion={workshop?.customQuestion?.value}
          isCCP={workshop?.isCCP}
        />
      ) : (
        <>
          {!showForm && !isWorkshopDataFetching && (
            <CreateSessionEmptyView
              canAdd={workshop.classes.allowedOperations.canAdd}
              setShowForm={setShowForm}
            />
          )}
        </>
      )}
    </>
  );
};

export default Sessions;
