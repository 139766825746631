import { useTheme } from 'styled-components';
import { useTranslation } from '@packages/utils';
import logo from 'assets/images/logo_white.svg';
import logoSmall from 'assets/images/logo_small.svg';
import { Image } from 'components/Base/Image';
import { EIconNames } from 'components/Base/Icon';
import useSidebarContext from 'context/CollapseSidebarContext';
import {
  SButton,
  SHeaderContent,
  SSidebarHeader,
  SToggleIcon,
} from './SidebarHeader.styles';

const SidebarHeader = () => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.sidebar.tooltip',
  });
  const theme = useTheme();
  const { collapsed, setCollapsed } = useSidebarContext();

  const logoSrc = collapsed ? logoSmall : logo;
  return (
    <SSidebarHeader
      $collapsed={collapsed}
      wrap={false}
      justify="center"
      className="w-100"
    >
      <SHeaderContent className="w-100" $collapsed={collapsed}>
        <Image src={logoSrc} alt="logo" width={collapsed ? 34 : ''} />
      </SHeaderContent>
      <SButton
        $collapsed={collapsed}
        icon={
          <SToggleIcon
            alt="toggle sidebar icon"
            icon={EIconNames.ARROW_LEFT}
            size={14}
            color={theme.colorBlackDescription}
            className="pointer"
            $collapsed={collapsed}
          />
        }
        tooltip={collapsed ? t('EXPAND') : t('COLLAPSE')}
        onClick={() => setCollapsed(!collapsed)}
        color="primary"
        shape="circle"
        size="middle"
        className="pointer"
      />
    </SSidebarHeader>
  );
};

export default SidebarHeader;
