import { useTranslation } from '@packages/utils';
import useGetValidationData from 'components/Base/Form/validationRules';

const useGetFormData = () => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.contacts-tab.list.actions.add-contact.modal.form',
  });
  const { validationRules } = useGetValidationData();
  const addContactFormFields = {
    userId: {
      name: 'userId',
      label: t('person.LABEL'),
      placeholder: t('person.PLACEHOLDER'),
      rules: [validationRules.requiredField()],
    },
    isElacMember: {
      name: 'isElacMember',
      label: t('ADD_ELAC'),
    },
  };

  return { addContactFormFields };
};

export default useGetFormData;
