import { useTranslation } from '@packages/utils';
import useGetValidationData from 'components/Base/Form/validationRules';
import {
  EAttendanceType,
  EWorkshopSeriesType,
  EWorkshopStandaloneType,
} from 'data/types/workshops.types';

export const keyPrefix =
  'descriptions.admin-portal.workshops.list.actions.create-workshop.modal.form';

const useGetData = () => {
  const { validationRules } = useGetValidationData();
  const { t } = useTranslation();
  const attendanceTypeOptions = Object.values(EAttendanceType).map(item => ({
    value: item,
    label: t(`enums.attendance-type.${item}`),
  }));

  const hostingTypeOptions = [
    {
      value: false,
      label: t(
        'descriptions.admin-portal.workshops.list.actions.create-workshop.modal.form.hosting-type.radio.PUBLIC',
      ),
    },
    {
      value: true,
      label: t(
        'descriptions.admin-portal.workshops.list.actions.create-workshop.modal.form.hosting-type.radio.PRIVATE',
      ),
    },
  ];

  const createWorkshopFormFields = {
    type: {
      name: 'type',
      label: t(
        'descriptions.admin-portal.workshops.list.actions.create-workshop.modal.form.workshop.LABEL',
      ),
      placeholder: t(
        'descriptions.admin-portal.workshops.list.actions.create-workshop.modal.form.workshop.PLACEHOLDER',
      ),
      rules: [validationRules.requiredField()],
    },
    host: {
      name: 'host',
      label: t(
        'descriptions.admin-portal.workshops.list.actions.create-workshop.modal.form.host.LABEL',
      ),
      placeholder: t(
        'descriptions.admin-portal.workshops.list.actions.create-workshop.modal.form.host.PLACEHOLDER',
      ),
      rules: [validationRules.requiredField()],
    },
    attendanceType: {
      name: 'attendanceType',
      label: t(
        'descriptions.admin-portal.workshops.list.actions.create-workshop.modal.form.attendance-type.LABEL',
      ),
      options: attendanceTypeOptions,
      initialValue: EAttendanceType.IN_PERSON,
      rules: [validationRules.requiredField()],
    },
    hostedPrivately: {
      name: 'hostedPrivately',
      label: t(
        'descriptions.admin-portal.workshops.list.actions.create-workshop.modal.form.hosting-type.LABEL',
      ),
      initialValue: false,
      options: hostingTypeOptions,
      rules: [validationRules.requiredField()],
    },
  };

  const typeData = [
    {
      label: t(
        `descriptions.admin-portal.workshops.list.actions.create-workshop.modal.form.workshop.dropdown.workshop-standalone-type.LABEL`,
      ),
      options: Object.values(EWorkshopStandaloneType).map(item => ({
        value: item,
        label: t(`enums.workshop-standalone-type.${item}`),
      })),
    },
    {
      label: t(
        `descriptions.admin-portal.workshops.list.actions.create-workshop.modal.form.workshop.dropdown.workshop-series-type.LABEL`,
      ),
      options: Object.values(EWorkshopSeriesType).map(item => ({
        value: item,
        label: t(`enums.workshop-series-type.${item}`),
      })),
    },
  ];

  return { typeData, createWorkshopFormFields };
};

export default useGetData;
