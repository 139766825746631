import { Form, FormItem } from 'components/Base/Form';
import { useTranslation, SRequiredLabel } from '@packages/utils';
import { Input } from 'components/Base/Input';
import PlaceAutocomplete from 'components/Shared/PlaceAutocomplete';
import { TCreateOrganizationFormProps } from './CreateOrganizationFrom.types';
import useGetFormData from './CreateOrganizationForm.data';

const CreateOrganizationForm = ({
  form,
  organizationData,
}: TCreateOrganizationFormProps) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.organizations.list.actions.add-organization.modal.form',
  });
  const { createOrganizationFormFields } = useGetFormData();
  const { address, name } = createOrganizationFormFields;

  return (
    <Form
      form={form}
      initialValues={{
        name: organizationData?.name || '',
        address: organizationData?.address?.formattedAddress || '',
      }}
    >
      <FormItem {...name}>
        <Input placeholder={name.placeholder} />
      </FormItem>

      <SRequiredLabel>{t('address.LABEL')}</SRequiredLabel>
      <FormItem {...address}>
        <PlaceAutocomplete placeholder={address.placeholder} />
      </FormItem>
    </Form>
  );
};

export default CreateOrganizationForm;
