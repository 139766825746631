import { FC } from 'react';
import { useTranslation, Tag } from '@packages/utils';
import { Col } from 'components/Base/Grid';
import { TAgeGroupItemProps } from './AgeGroupItem.types';
import { SAgeGroupItemMultyplier } from './AgeGroupItem.styles';

const AgeGroupItem: FC<TAgeGroupItemProps> = ({
  language,
  ageGroup,
  count,
  color,
}) => {
  const { t } = useTranslation({
    keyPrefix: 'enums.age-group',
  });
  const showMultiplier = count > 1;
  return (
    <Col className="mb-1">
      <Tag color={color}>
        {language}: {t(ageGroup)}
        {showMultiplier && (
          <SAgeGroupItemMultyplier>{`(x${count})`}</SAgeGroupItemMultyplier>
        )}
      </Tag>
    </Col>
  );
};

export default AgeGroupItem;
