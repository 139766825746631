import { TTagUI } from 'data/types/tags.types';

export const getTagDifferences = (
  initialTags: TTagUI[],
  finalTags: TTagUI[],
): { assignedTagIds: string[]; removedTagIds: string[] } => {
  const initialTagIds = new Set(initialTags.map(tag => tag.value));
  const finalTagIds = new Set(finalTags.map(tag => tag.value));

  const assignedTagIds = Array.from(finalTagIds).filter(
    id => !initialTagIds.has(id),
  );

  const removedTagIds = Array.from(initialTagIds).filter(
    id => !finalTagIds.has(id),
  );

  return { assignedTagIds, removedTagIds };
};

export const tagModalKeyPrefix =
  'descriptions.generic-components.tags.tag-modal';
