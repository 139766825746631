import { Row, Col } from 'components/Base/Grid';
import { EWorkshopPartType } from 'data/types/workshops.types';
import { TDateTimeProps } from './DateTime.types';
import PartDateInput from './PartDateInput';

const PartsDate = ({ id, parts, workshopStatus }: TDateTimeProps) => {
  const getPrevAndNextPartDates = (part: EWorkshopPartType) => {
    const getDateTime = (partType: EWorkshopPartType) => {
      return parts?.find(pt => pt.partType === partType)?.dateTime?.value || '';
    };
    switch (part) {
      case EWorkshopPartType.PART_ONE: {
        const nextPartDateTime = getDateTime(EWorkshopPartType.PART_TWO);
        return { nextPartDateTime };
      }
      case EWorkshopPartType.PART_TWO: {
        const previousPartDateTime = getDateTime(EWorkshopPartType.PART_ONE);
        const nextPartDateTime = getDateTime(EWorkshopPartType.PART_THREE);
        return { previousPartDateTime, nextPartDateTime };
      }
      case EWorkshopPartType.PART_THREE: {
        const previousPartDateTime = getDateTime(EWorkshopPartType.PART_TWO);
        return { previousPartDateTime };
      }
      default: {
        return { previousPartDateTime: '', nextPartDateTime: '' };
      }
    }
  };
  return (
    <Row gutter={[4, 8]} align="middle">
      <Col>
        {parts?.map(part => {
          return (
            <PartDateInput
              part={part}
              workshopId={id}
              key={part.workshopPartId}
              {...getPrevAndNextPartDates(part.partType)}
              workshopStatus={workshopStatus}
            />
          );
        })}
      </Col>
    </Row>
  );
};

export default PartsDate;
