import React, { useRef, useState } from 'react';
import { StandaloneSearchBox } from '@react-google-maps/api';
import { Input } from 'components/Base/Input';
import {
  TAddressComponentProps,
  TAddressProps,
  TPlaceAutocompleteProps,
  TPlaceProps,
} from './PlaceAutocomplete.types';

const PlaceAutocomplete = ({
  placeholder = 'Enter location',
  onChange = () => {
    // do nothing
  },
  value,
  defaultValue,
  suffix: undoSifix,
  ...rest
}: TPlaceAutocompleteProps) => {
  const [displayAddress, setDisplayAddress] = useState<string | undefined>(
    value || defaultValue,
  );
  const [lastSelectedAddress, setLastSelectedAddress] =
    useState<TAddressProps>();

  const inputRef = useRef<{
    getPlaces: () => TPlaceProps[];
  }>({
    getPlaces: () => [],
  });

  const getAddressObject = (addressComponents: TAddressComponentProps[]) => {
    const addressObject: TAddressProps = {
      placeId: undefined,
      formattedAddress: undefined,
      streetNumber: undefined,
      street: undefined,
      city: undefined,
      state: undefined,
      country: undefined,
      zipCode: undefined,
      latitude: undefined,
      longitude: undefined,
    };

    (addressComponents || []).forEach(component => {
      const { types, long_name } = component;

      if (types.includes('street_number')) {
        addressObject.streetNumber = long_name;
      } else if (types.includes('route')) {
        addressObject.street = long_name;
      } else if (types.includes('locality')) {
        addressObject.city = long_name;
      } else if (types.includes('administrative_area_level_1')) {
        addressObject.state = long_name;
      } else if (types.includes('country')) {
        addressObject.country = long_name;
      } else if (types.includes('postal_code')) {
        addressObject.zipCode = long_name;
      }
    });

    return addressObject;
  };

  const extractAddressComponents = (place: TPlaceProps): TAddressProps => {
    const addressComponents = place.address_components;
    const formattedAddress = place.formatted_address;
    const latitude = Number(place.geometry?.location?.lat()?.toFixed(6));
    const longitude = Number(place.geometry?.location?.lng()?.toFixed(6));
    const placeId = place.place_id;
    const addressObject = getAddressObject(addressComponents);
    return { ...addressObject, placeId, formattedAddress, latitude, longitude };
  };

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces() || [];
    if (place) {
      const address = extractAddressComponents(place);
      setLastSelectedAddress(address);
      onChange(address);
      setDisplayAddress(address.formattedAddress);
    }
  };

  const handleInputChange = (event: {
    target: { value: React.SetStateAction<string | undefined> };
  }) => {
    // @ts-ignore
    const val = event.target.value;
    if (!val) {
      onChange(undefined);
    }
    if (val && lastSelectedAddress?.formattedAddress !== displayAddress) {
      onChange({});
    }
    setDisplayAddress(val);
  };

  return (
    // <LoadScript
    //   googleMapsApiKey="AIzaSyDedbW8WqmnwZMQKaEk_mOsesx2gwvQqI0"
    //   libraries={['places']}
    //   language="en"
    // >

    <StandaloneSearchBox
      options={{
        // @ts-ignore // TODO SUREN inestigate how to display only us addresses
        componentRestrictions: { country: ['us'] },
      }}
      onLoad={ref => {
        // @ts-ignore
        inputRef.current = ref;
      }}
      onPlacesChanged={handlePlaceChanged}
    >
      <Input
        {...rest}
        value={displayAddress}
        placeholder={placeholder}
        onChange={handleInputChange}
        suffix={<>{undoSifix}</>}
      />
    </StandaloneSearchBox>
    // </LoadScript>
  );
};

export default PlaceAutocomplete;
