import { FC } from 'react';
import { useTheme } from 'styled-components';
import { useTranslation, Button } from '@packages/utils';
import { Icon, EIconNames } from 'components/Base/Icon';
import {
  ENotificationType,
  useShowToastNotification,
} from 'components/Base/Notification';
import { useLazyGetSessionAttendanceUrlQuery } from 'redux/apiSlice/workshops.slice';
import { TRtkErrorType } from 'data/types/generic.types';
import { EWorkshopStatus } from 'data/types/workshops.types';
import { TCopySessionAttendanceUrlProps } from './CopySessionAttendanceUrl.types';

const CopySessionAttendanceUrl: FC<TCopySessionAttendanceUrlProps> = ({
  sessionId,
  workshopId,
  workshopStatus,
}) => {
  const theme = useTheme();
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.workshops.details.sessions.view.actions.copy-session-attendance-url',
  });

  const { showToastNotification } = useShowToastNotification();

  const [getAttendanceUrl, { isLoading }] =
    useLazyGetSessionAttendanceUrlQuery();

  const onCopyUrl = async () => {
    try {
      const url = await getAttendanceUrl({
        workshopId,
        sessionId,
      }).unwrap();

      if (url) {
        await navigator.clipboard.writeText(url);
      }
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (err) {
      showToastNotification({
        message: (err as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  if (workshopStatus !== EWorkshopStatus.PUBLISHED) {
    return null;
  }

  return (
    <Button
      icon={
        <Icon
          icon={EIconNames.COPY_OUTLINED}
          size={14}
          color={theme.colorErrorDefault}
          className="pointer"
        />
      }
      tooltip={t('TOOLTIP')}
      color="primary"
      shape="circle"
      size="middle"
      onClick={onCopyUrl}
      loading={isLoading}
    />
  );
};

export default CopySessionAttendanceUrl;
