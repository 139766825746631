import { useAppSelector } from 'redux/store';
import { Row, Col, Paragraph, useTranslation } from '@packages/utils';
import { useTheme } from 'styled-components';
import { Avatar } from 'components/Base/Avatar';
import useSidebarContext from 'context/CollapseSidebarContext';
import { EUserType } from 'data/types/user.types';
import { SDivider, SSidebarUserInfo } from './SidebarUserInfo.styles';

const SidebarUserInfo = () => {
  const theme = useTheme();
  const { t: tUserType } = useTranslation({
    keyPrefix: 'enums.user-type',
  });
  const { collapsed } = useSidebarContext();
  const usersData = useAppSelector(state => state.userSlice);
  const { user } = usersData;
  const firstName = user === null ? '' : user.firstName;
  const lastName = user === null ? '' : user.lastName;
  const type = user === null ? [] : user.type;

  return (
    <SSidebarUserInfo $collapsed={collapsed}>
      <Row align="middle" gutter={[16, 0]} wrap={false}>
        <Col className={collapsed ? 'm-auto' : ''}>
          <Avatar
            title={firstName || lastName}
            alt={`${firstName} ${lastName}`}
            size={40}
            className="text-center"
          />
        </Col>
        {!collapsed && firstName && (
          <Col>
            <Row>
              <Col span={24}>
                <Paragraph
                  ellipsis
                  fontWeight={theme.fontWeightSemiBold}
                  color={theme.menuColorTextDefault}
                >
                  {firstName}
                </Paragraph>
              </Col>
              <Col span={24}>
                <Paragraph
                  fontSize={theme.fontSizeMd}
                  color={theme.menuColorTextDefault}
                >
                  {type.map((item: EUserType, i: number) =>
                    i === type.length - 1
                      ? tUserType(item)
                      : `${tUserType(item)}, `,
                  )}
                </Paragraph>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      <SDivider thickness={1} color={theme.menuColorBorderActive} />
    </SSidebarUserInfo>
  );
};

export default SidebarUserInfo;
