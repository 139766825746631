import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TRedirectSliceState = {
  isElcsMoreThanOne: boolean;
};

const initialState: TRedirectSliceState = {
  isElcsMoreThanOne: false,
};

const redirectSlice = createSlice({
  name: 'redirectSlice',
  initialState,
  reducers: {
    setIsElcsMoreThanOne(state, action: PayloadAction<boolean>) {
      state.isElcsMoreThanOne = action.payload;
    },
  },
});

export default redirectSlice;
