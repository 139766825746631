import { cloneDeep } from 'lodash';
import {
  TAuthConverter,
  TCreateConverter,
  TFetchConverter,
} from '@packages/utils';
import {
  EResetPasswordCasesDB,
  EResetPasswordCasesUI,
  ICurrentAuthUser,
  TCurrentAuthUserUI,
  TResetPasswordCasesDB,
  TResetPasswordCasesUI,
  TResetPasswordDB,
  TResetPasswordUI,
  TSignInAmplifyDB,
  TSignInAmplifyUI,
} from 'data/types/amplify.types';

export const signInAmplifyConverter: TCreateConverter<
  TSignInAmplifyUI,
  TSignInAmplifyDB
> = {
  toDb: ({ email, password }) => {
    return { username: email, password };
  },
};

export const resetPasswordCasesConverter: TAuthConverter<
  TResetPasswordCasesUI,
  TResetPasswordCasesDB,
  EResetPasswordCasesUI,
  EResetPasswordCasesDB
> = {
  toDb: ({ email }) => {
    return {
      username: email,
    };
  },
  fromDb: data => {
    const dataMapper: {
      [key in EResetPasswordCasesDB]: EResetPasswordCasesUI;
    } = {
      [EResetPasswordCasesDB.RESEND_INVITATION]:
        EResetPasswordCasesUI.RESEND_INVITATION,
      [EResetPasswordCasesDB.RESENT_CODE]: EResetPasswordCasesUI.RESENT_CODE,
      [EResetPasswordCasesDB.RESENT_PASSWORD]:
        EResetPasswordCasesUI.RESENT_PASSWORD,
    };
    return dataMapper[data];
  },
};

export const resetPasswordConverter: TCreateConverter<
  TResetPasswordUI,
  TResetPasswordDB
> = {
  toDb: ({ email, password, code }) => {
    return { username: email, password, code: code.trim() };
  },
};

export const currentAuthUserConvertor: TFetchConverter<
  TCurrentAuthUserUI,
  ICurrentAuthUser
> = {
  fromDb: data => {
    const cognitoUser = cloneDeep(data);
    return cognitoUser;
  },
};
