import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import React, { FC, Suspense, useEffect } from 'react';
import useGetSidebarData from './RoutesData';
import { RoutesPaths } from './Routes.types';

const PublicRoutes: FC<{
  isLoggedIn: boolean;
}> = ({ isLoggedIn }) => {
  const navigate = useNavigate();
  const { publicRoutes, privateRoutes } = useGetSidebarData();
  useEffect(() => {
    if (isLoggedIn) {
      const firstRouteInSidebar = privateRoutes.find(
        route => route.show && route.showByPermissions,
      )?.path;
      navigate(`app/${firstRouteInSidebar}`);
    }
  }, [isLoggedIn]);

  return (
    <Suspense fallback={null}>
      <Routes>
        <Route
          path="/"
          element={<Navigate replace to={publicRoutes[0].path} />}
        />
        {publicRoutes.map(({ path, component }) => {
          const Component = component;
          return <Route key={path} path={`${path}`} element={<Component />} />;
        })}
        <Route
          path="*"
          element={<Navigate to={`/app/${RoutesPaths.PAGE_NOT_FOUND}`} />}
        />
      </Routes>
    </Suspense>
  );
};

export default PublicRoutes;
