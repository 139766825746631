import CardContent from 'components/Base/Layout/Content/CardContent';
import WorkshopWaitList from './WorkshopWaitList';
import { TWaitListTabProps } from './WaitListTab.types';

const WaitListTab = ({ data }: TWaitListTabProps) => {
  return (
    <CardContent tabContent contentPadding="8px 0px">
      <WorkshopWaitList
        workshopId={data?.id}
        customQuestion={data?.customQuestion.value}
        csvFileNamePrefix={data?.location?.name || data?.host?.name}
        hideChildInfo={!!data?.isCCP}
      />
    </CardContent>
  );
};

export default WaitListTab;
