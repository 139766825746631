import { Col, useTranslation } from '@packages/utils';

const ConfirmModalBody = () => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.elcs.list.elc-item.pm',
  });
  return (
    <>
      <Col>{t('CONFIRM_QUESTION')}</Col>
      <Col>{t('CONFIRM_MESSAGE')}</Col>
    </>
  );
};

export default ConfirmModalBody;
