import { TConvertor } from 'data/types/converter.types';
import { ERedirectDB, ERedirectUI } from 'data/types/redirect.types';

export const redirectConverter: TConvertor<ERedirectUI, ERedirectDB> = {
  fromDb: data => {
    const dataMapper: { [key in ERedirectDB]: ERedirectUI } = {
      [ERedirectDB.FORGOT_PASSWORD]: ERedirectUI.FORGOT_PASSWORD,
      [ERedirectDB.INVITE]: ERedirectUI.INVITE,
    };
    return dataMapper[data];
  },
  toDb: data => {
    const dataMapper: { [key in ERedirectUI]: ERedirectDB } = {
      [ERedirectUI.FORGOT_PASSWORD]: ERedirectDB.FORGOT_PASSWORD,
      [ERedirectUI.INVITE]: ERedirectDB.INVITE,
    };
    return dataMapper[data];
  },
};
