import { QueryReturnValue } from '@packages/utils';
import { Auth } from 'aws-amplify';
import {
  currentAuthUserConvertor,
  resetPasswordConverter,
  signInAmplifyConverter,
} from 'data/convertors/auth.converters';
import {
  EPreferredMFATypes,
  ICurrentAuthUser,
  TAmplifyError,
  TChangePassword,
  TChangePasswordUI,
  TCompleteNewPassword,
  TCompleteNewPasswordUI,
  TConfirmSignInDB,
  TConfirmSignInUI,
  TCurrentAuthUserUI,
  TResetPasswordUI,
  TSetPreferredMFAUI,
  TSetupTOTPDB,
  TSetupTOTPUI,
  TSignInAmplifyUI,
  TVerifyTotpTokenDB,
  TVerifyTotpTokenUI,
} from 'data/types/amplify.types';
import { authSplitApi } from 'redux/helpers/slice.helpers';

export const authApi = authSplitApi('auth').injectEndpoints({
  endpoints: build => ({
    signIn: build.mutation<TCurrentAuthUserUI, TSignInAmplifyUI>({
      // @ts-ignore
      async queryFn(arg) {
        try {
          const { username, password } = signInAmplifyConverter.toDb(arg);
          const amplifyUser = await Auth.signIn(username, password);
          const convertedUser = currentAuthUserConvertor.fromDb(amplifyUser);
          localStorage.setItem('amplifyUser', JSON.stringify(amplifyUser));
          return {
            data: convertedUser,
          } as QueryReturnValue<ICurrentAuthUser>;
        } catch (e) {
          const error = e as TAmplifyError;
          return {
            error: {
              data: {
                name: error.name,
                code: error.code,
                message: error.message,
                username: arg.email,
              },
              status: error.code,
            },
          };
        }
      },
    }),
    // this api confirms sign in after 2 fa code is submitted in login flow
    confirmSignIn: build.mutation<TConfirmSignInDB, TConfirmSignInUI>({
      // @ts-ignore
      async queryFn(arg) {
        try {
          const amplifyUser = await Auth.confirmSignIn(
            arg.user,
            arg.code,
            arg.mfaType,
          );
          const modifiedAmplifyUserWithoutChallengeName = {
            ...amplifyUser,
            preferredMFA: EPreferredMFATypes.TOTP,
          };
          delete modifiedAmplifyUserWithoutChallengeName.challengeName;
          const convertedUser = currentAuthUserConvertor.fromDb(
            modifiedAmplifyUserWithoutChallengeName,
          );
          localStorage.setItem(
            'amplifyUser',
            JSON.stringify(modifiedAmplifyUserWithoutChallengeName),
          );
          return {
            data: convertedUser,
          } as QueryReturnValue<ICurrentAuthUser>;
        } catch (e) {
          const error = e as TAmplifyError;
          return {
            error: {
              data: {
                name: error.name,
                code: error.code,
                message: error.message,
              },
              status: error.code,
            },
          };
        }
      },
    }),
    // this api gets secret which we use to activate 2fa in authenticator app (in qr code value or by just typing)
    getTOTPCode: build.mutation<TSetupTOTPDB, TSetupTOTPUI>({
      // @ts-ignore
      async queryFn(arg) {
        try {
          const data = await Auth.setupTOTP(arg.user);
          return { data };
        } catch (e) {
          return { error: e };
        }
      },
    }),
    // this api activates or deactivated 2fa by using code from authenticator app
    verifyTotpToken: build.mutation<TVerifyTotpTokenDB, TVerifyTotpTokenUI>({
      // @ts-ignore
      async queryFn(arg) {
        try {
          const data = await Auth.verifyTotpToken(
            arg.user,
            arg.challengeAnswer,
          );
          return { data };
        } catch (e) {
          const error = e as TAmplifyError;
          return {
            error: {
              data: {
                name: error.name,
                code: error.code,
                message: error.message,
              },
              status: error.code,
            },
          };
        }
      },
    }),
    // this api is used to update preferredMFA type which idetifies if 2fa is on or off
    setPreferredMFA: build.mutation<void, TSetPreferredMFAUI>({
      // @ts-ignore
      async queryFn(arg) {
        try {
          return await Auth.setPreferredMFA(arg.user, arg.MFAMethod);
        } catch (e) {
          const error = e as TAmplifyError;
          return {
            error: {
              data: {
                name: error.name,
                code: error.code,
                message: error.message,
              },
              status: error.code,
            },
          };
        }
      },
    }),
    signOut: build.mutation<void, void>({
      // @ts-ignore
      async queryFn() {
        try {
          await Auth.signOut();
          return { data: null };
        } catch (e) {
          return { error: e };
        }
      },
    }),
    changePassword: build.mutation<TChangePassword, TChangePasswordUI>({
      // @ts-ignore
      async queryFn(arg) {
        try {
          return await Auth.changePassword(
            arg.user,
            arg.existing,
            arg.password,
          );
        } catch (e) {
          const error = e as TAmplifyError;
          return {
            error: {
              data: {
                name: error.name,
                code: error.code,
                message: error.message,
              },
              status: error.code,
            },
          };
        }
      },
    }),
    setNewPassword: build.mutation<
      TCompleteNewPassword,
      TCompleteNewPasswordUI
    >({
      // @ts-ignore
      async queryFn(arg) {
        try {
          const amplifyUser = await Auth.completeNewPassword(
            arg.user,
            arg.newPassword,
          );
          localStorage.setItem('amplifyUser', JSON.stringify(amplifyUser));
          return { data: currentAuthUserConvertor.fromDb(amplifyUser) };
        } catch (e) {
          const error = e as TAmplifyError;
          return {
            error: {
              data: {
                name: error.name,
                code: error.code,
                message: error.message,
              },
              status: error.code,
            },
          };
        }
      },
    }),
    resetPasswordAmplify: build.mutation<void, TResetPasswordUI>({
      // @ts-ignore
      async queryFn(data) {
        const { username, password, code } = resetPasswordConverter.toDb(data);
        try {
          await Auth.forgotPasswordSubmit(username, code, password);
          return { data: null };
        } catch (e) {
          const error = e as TAmplifyError;
          return {
            error: {
              data: {
                name: error.name,
                code: error.code,
                message: error.message,
              },
              status: error.code,
            },
          };
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useSignInMutation,
  useSignOutMutation,
  useSetNewPasswordMutation,
  useChangePasswordMutation,
  useResetPasswordAmplifyMutation,
  useGetTOTPCodeMutation,
  useConfirmSignInMutation,
  useSetPreferredMFAMutation,
  useVerifyTotpTokenMutation,
} = authApi;

export const currentAmplifyAuthUser = async (): Promise<TCurrentAuthUserUI> => {
  return Auth.currentAuthenticatedUser();
};
