import { useTranslation } from '@packages/utils';
import { useCheckPermission } from 'hooks/useCheckPermission';
import Parents from 'pages/private/KitManagement/Parents';
import Workshop from 'pages/private/KitManagement/Workshop';
import { RoutesPaths, SubRouteType } from 'routes/Routes.types';

const useGetKitsSidebarData = () => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.sidebar',
  });
  const kitManagementSubRoutes: SubRouteType[] = [
    {
      title: t('WORKSHOPS'),
      component: Workshop,
      path: RoutesPaths.KIT_MANAGEMENT_WORKSHOP,
      show: true,
      showByPermissions: useCheckPermission('PART_ORDER_VIEW'),
    },
    {
      title: t('PARENTS'),
      component: Parents,
      path: RoutesPaths.KIT_MANAGEMENT_PARENTS,
      show: true,
      showByPermissions: useCheckPermission('PARENT_ORDER_VIEW'),
    },
  ];

  return { kitManagementSubRoutes };
};

export default useGetKitsSidebarData;
