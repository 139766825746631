import { Row } from '@packages/utils';
import { TTeamSectionProps } from './TeamSection.types';
import AEYCLead from './AEYCLead';
import ProjectManager from './ProjectManager';
import FiscalAgent from './FiscalAgent';
import LeadAgency from './LeadAgency';
import CoLeadAgency from './CoLeadAgency';

const TeamSection = ({ data, elcId, isEditable }: TTeamSectionProps) => {
  const {
    lead,
    firstProjectManager,
    secondProjectManager,
    fiscalAgent,
    leadAgency,
    coLeadAgency,
  } = data;

  return (
    <Row gutter={[16, 16]}>
      <AEYCLead data={lead} />
      <ProjectManager data={firstProjectManager} />
      <ProjectManager data={secondProjectManager} />
      <FiscalAgent data={fiscalAgent} elcId={elcId} isEditable={isEditable} />
      <LeadAgency data={leadAgency} elcId={elcId} isEditable={isEditable} />
      <CoLeadAgency data={coLeadAgency} elcId={elcId} isEditable={isEditable} />
    </Row>
  );
};

export default TeamSection;
