import { useEffect, useRef } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useDidUpdateEffect = (fn: () => void, inputs: any) => {
  const didMountRef = useRef(false);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (didMountRef.current) {
      return fn();
    }
    didMountRef.current = true;
  }, inputs);
};

export default useDidUpdateEffect;
