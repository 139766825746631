import { Col, Row, useTranslation } from '@packages/utils';
import { TColumnType } from 'components/Base/Table/Table.types';
import { TElcMeetingActionItemsUI, TElcDetailUI } from 'data/types/elcs.types';
import RemoveActionItem from './RemoveActionItem';
import ActionItemTitle from './ActionItemTitle';
import ActionItemDueDate from './ActionItemDueDate';
import ActionItemOwner from './ActionItemOwner';
import ActionItemStatus from './ActionItemStatus';
import { TAddNewRow } from './ActionItemsTab.types';

const useGetData = (
  elcId: TElcDetailUI['elcId'],
  // isEditable?: boolean,
  setNewRowData: React.Dispatch<React.SetStateAction<TAddNewRow | undefined>>,
) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.meetings-tab.details.action-items.table.title',
  });

  const defaultColumns: TColumnType<TElcMeetingActionItemsUI>[] = [
    {
      title: t('TASK'),
      dataIndex: 'title',
      key: 'title',
      render: (text, record: TElcMeetingActionItemsUI) => (
        <ActionItemTitle
          {...record}
          elcId={elcId}
          setNewRowData={setNewRowData}
        />
      ),
      onCell: () => ({
        style: { minWidth: '250px', maxWidth: '250px' },
      }),
    },
    {
      title: t('DUE_DATE'),
      dataIndex: 'dueDate',
      key: 'dueDate',
      render: (text, record: TElcMeetingActionItemsUI) => (
        <ActionItemDueDate
          {...record}
          elcId={elcId}
          setNewRowData={setNewRowData}
        />
      ),
      onCell: () => ({
        style: { minWidth: '200px' },
      }),
    },
    {
      title: t('OWNER'),
      dataIndex: 'ownerId',
      key: 'ownerId',
      render: (text, record: TElcMeetingActionItemsUI) => (
        <ActionItemOwner
          {...record}
          elcId={elcId}
          setNewRowData={setNewRowData}
        />
      ),
      onCell: () => ({
        style: { minWidth: '200px' },
      }),
    },
    {
      title: t('ACTIONS'),
      key: 'actions',
      dataIndex: 'actions',
      width: 120,
      onCell: () => ({
        style: { minWidth: '120px' },
        onClick: e => {
          e.stopPropagation();
        },
      }),
      render: (text, record: TElcMeetingActionItemsUI) => (
        <Row gutter={[16, 16]} align="middle">
          <Col>
            <ActionItemStatus
              {...record}
              elcId={elcId}
              setNewRowData={setNewRowData}
            />
          </Col>
          <Col>
            <RemoveActionItem elcId={elcId} {...record} />
          </Col>
        </Row>
      ),
    },
  ];
  return { defaultColumns };
};

export default useGetData;
