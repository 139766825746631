import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TUserDataUi } from 'data/types/user.types';
import { TCurrentAuthUserUI } from 'data/types/amplify.types';
import { authApi } from 'redux/apiSlice/auth.slice';
import { userGrowApi } from 'redux/apiSlice/userGrow.slice';

export type TInitialStateState = {
  user: TUserDataUi | null;
  amplifyUser: TCurrentAuthUserUI | null;
  isLoading: boolean;
};

const initialState = {
  user: localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user') as string)
    : null,
  amplifyUser: localStorage.getItem('amplifyUser')
    ? JSON.parse(localStorage.getItem('amplifyUser') || '')
    : null,
  isLoading: !localStorage.getItem('user'),
};

const userAmplifySlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setAmplifyUser(state, action: PayloadAction<TCurrentAuthUserUI>) {
      const newState = {
        ...state,
        amplifyUser: action.payload,
      };
      localStorage.setItem('amplifyUser', JSON.stringify(action.payload));
      return newState;
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      authApi.endpoints.confirmSignIn.matchFulfilled,
      (state, action) => {
        if (action.payload) {
          return {
            ...state,
            amplifyUser: action.payload,
          };
        }
        return state;
      },
    );
    builder.addMatcher(
      authApi.endpoints.signIn.matchFulfilled,
      (state, action) => {
        if (action.payload) {
          return {
            ...state,
            amplifyUser: action.payload,
          };
        }
        return state;
      },
    );
    // @ts-ignore
    builder.addMatcher(authApi.endpoints.signOut.matchFulfilled, () => {
      localStorage.clear();
      return {
        user: null,
        amplifyUser: null,
      };
    });
    builder.addMatcher(
      authApi.endpoints.setNewPassword.matchFulfilled,
      (state, action) => {
        if (action.payload) {
          return {
            ...state,
            amplifyUser: action.payload,
          };
        }
        return state;
      },
    );
    builder.addMatcher(
      userGrowApi.endpoints.getUserProfile.matchFulfilled,
      (state, { payload }) => {
        state.user = payload;
        state.isLoading = false;
        localStorage.setItem('user', JSON.stringify(payload));
      },
    );
  },
});

export default userAmplifySlice;
