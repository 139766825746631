import { useTranslation } from '@packages/utils';
import useGetValidationData from 'components/Base/Form/validationRules';
import { EAgeGroup, EWorkshopLanguage } from 'data/types/generic.types';

export const keyPrefix =
  'descriptions.admin-portal.subscribers.list.actions.add-subscriber.modal.form';

const useGetFormData = () => {
  const { t } = useTranslation();
  const { validationRules } = useGetValidationData();
  const createWorkshopSubscribersFormFields = {
    firstName: {
      name: 'firstName',
      label: t(
        'descriptions.admin-portal.subscribers.list.actions.add-subscriber.modal.form.first-name.LABEL',
      ),
      placeholder: t(
        'descriptions.admin-portal.subscribers.list.actions.add-subscriber.modal.form.first-name.PLACEHOLDER',
      ),
      rules: [validationRules.requiredField()],
    },
    lastName: {
      name: 'lastName',
      label: t(
        'descriptions.admin-portal.subscribers.list.actions.add-subscriber.modal.form.last-name.LABEL',
      ),
      placeholder: t(
        'descriptions.admin-portal.subscribers.list.actions.add-subscriber.modal.form.last-name.PLACEHOLDER',
      ),
      rules: [validationRules.requiredField()],
    },
    phone: {
      name: 'phone',
      label: t(
        'descriptions.admin-portal.subscribers.list.actions.add-subscriber.modal.form.mobile.LABEL',
      ),
      placeholder: t(
        'descriptions.admin-portal.subscribers.list.actions.add-subscriber.modal.form.mobile.PLACEHOLDER',
      ),
      rules: [validationRules.requiredField(), validationRules.phoneNumber()],
    },
    email: {
      name: 'email',
      label: t(
        'descriptions.admin-portal.subscribers.list.actions.add-subscriber.modal.form.email.LABEL',
      ),
      placeholder: t(
        'descriptions.admin-portal.subscribers.list.actions.add-subscriber.modal.form.email.PLACEHOLDER',
      ),
      rules: [validationRules.requiredField(), validationRules.email()],
    },
    cities: {
      name: 'cities',
      label: t(
        'descriptions.admin-portal.subscribers.list.actions.add-subscriber.modal.form.cities.LABEL',
      ),
      placeholder: t(
        'descriptions.admin-portal.subscribers.list.actions.add-subscriber.modal.form.cities.PLACEHOLDER',
      ),
      rules: [
        validationRules.requiredField(),
        {
          ...validationRules.maxLimit(3),
          warningOnly: true,
          message: t(
            'descriptions.admin-portal.subscribers.list.actions.add-subscriber.modal.form.cities.WARNING_TEXT',
          ),
        },
      ],
    },
    ageGroups: {
      name: 'ageGroups',
      label: t(
        'descriptions.admin-portal.subscribers.list.actions.add-subscriber.modal.form.age-group.LABEL',
      ),
      placeholder: t(
        'descriptions.admin-portal.subscribers.list.actions.add-subscriber.modal.form.age-group.PLACEHOLDER',
      ),
      rules: [validationRules.requiredField()],
      options: Object.values(EAgeGroup)
        .filter(ageGroup => ageGroup !== EAgeGroup.THREE_TO_FIVE)
        .map(ageGroup => ({
          label: t(`enums.age-group.${ageGroup}`),
          value: ageGroup,
        })),
    },
    languages: {
      name: 'languages',
      label: t(
        'descriptions.admin-portal.subscribers.list.actions.add-subscriber.modal.form.language.LABEL',
      ),
      placeholder: t(
        'descriptions.admin-portal.subscribers.list.actions.add-subscriber.modal.form.language.PLACEHOLDER',
      ),
      rules: [validationRules.requiredField()],
      options: Object.values(EWorkshopLanguage).map(language => ({
        label: t(`enums.language.${language}`),
        value: language,
      })),
    },
  };

  return { createWorkshopSubscribersFormFields };
};

export default useGetFormData;
