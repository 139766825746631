import { useTranslation, Tag } from '@packages/utils';
import { Form, FormItem } from 'components/Base/Form';
import { TextArea } from 'components/Base/Input';
import { TEditDetailsModalFormProps } from './EditDetailsModalForm.types';
import useGetFormData from './EditDetailsModalForm.data';
import { addDetailsSectionKey } from '../../../../AddDetailsSection.data';

const EditDetailsForm = ({
  form,
  note: noteDetails,
}: TEditDetailsModalFormProps) => {
  const { addDetailsFormFields } = useGetFormData();
  const { t } = useTranslation({
    keyPrefix: `${addDetailsSectionKey}.notes-categories`,
  });
  const { note } = addDetailsFormFields;
  if (!noteDetails) return null;
  return (
    <Form
      form={form}
      initialValues={{
        note: noteDetails.note || '',
      }}
    >
      <Tag color="primary" className="mb-4">
        {t(noteDetails.category)}
      </Tag>
      <FormItem {...note}>
        <TextArea
          placeholder={note.placeholder}
          className="mb-4"
          maxLength={350}
          showCount
        />
      </FormItem>
    </Form>
  );
};

export default EditDetailsForm;
