import { FC } from 'react';
import { Modal } from 'components/Base/Modal';
import ConfirmModalBody from 'components/Shared/ConfirmModalBody';
import { TConfirmModalEditHostProps } from './ConfirmModalEditHost.types';

const ConfirmModalEditHost: FC<TConfirmModalEditHostProps> = ({
  open,
  setOpen,
  onHostEdit,
  editHostLoading,
}) => {
  const onConfirmModalClose = () => {
    setOpen({
      visible: false,
      newOrgId: '',
    });
  };
  const onConfirmModalSubmit = async () => {
    await onHostEdit(open.newOrgId);
    onConfirmModalClose();
  };

  return (
    <Modal
      title="Edit"
      open={open.visible}
      onCancel={onConfirmModalClose}
      onOk={onConfirmModalSubmit}
      okText="Submit"
      confirmLoading={editHostLoading}
    >
      <ConfirmModalBody
        title="If you edit the Host, it may affect the Location information."
        description="Are you sure you want to change the Host?"
      />
    </Modal>
  );
};

export default ConfirmModalEditHost;
