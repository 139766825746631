import { Key, useEffect, useMemo, useState } from 'react';
import { Table as AntdTable } from '@idaho-aeyc/ui-kit';
import { CSVLink } from 'react-csv';
import { nanoid } from 'nanoid';
import { useAppSelector } from 'redux/store';
import { useTranslation } from '@packages/utils';
import { appLocalStorage, EStorageNames } from 'helpers/storage';
import { TableProps, TColumnsState, TColumnType } from './Table.types';
import useScreenSize from '../../../hooks/useScreenSize';
import Checkbox from '../Checkbox';
import { Row } from '../Grid';

export const Table = <T,>({ ...props }: TableProps<T>) => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.generic-components.table',
  });
  const {
    pageSize,
    columns,
    setPageSize,
    totalDataCount,
    currentPage,
    selectedRows,
    setSelectedRows,
    hasFilters,
    onFiltersReset,
    resetFiltersLabel = t('RESET_FILTERS_BTN'),
    filterColumnLabel = t('FILTER_COLUMNS_LABEL'),
    resultsLabel = t('RESULTS_LABEL'),
    ofLabel = t('OF'),
    pageHeaderContent,
    storagePath,
    exportCSV,
    dataSource,
    tabHeight,
    ...restProps
  } = props;
  const { height } = useScreenSize();
  const key = useMemo(() => {
    return nanoid();
  }, [columns]);
  const pageHeaderHeight = useAppSelector(s => s.appSlice.pageHeaderHeight);

  const tablesSettingsFromStorage = appLocalStorage.getItem(
    EStorageNames.tableSettings,
  );

  const currentTableSettingFromStorage =
    tablesSettingsFromStorage?.[storagePath];
  const getColumnsState = () => {
    if (!currentTableSettingFromStorage?.length) {
      return (columns || []).map(column => ({
        key: column?.key || '',
        show: true,
        title: column?.title as string,
      }));
    }
    // Filter local storage table settings columns to include only those that exist in the column data.
    const filteredColumns = (
      currentTableSettingFromStorage as TColumnsState[]
    ).filter(storageItem =>
      (columns || []).some(col => col.key === storageItem.key),
    );
    // Retrieve columns from column data that do not exist in local storage
    const columnsNotInStorage = (columns || [])
      .filter(el => !filteredColumns.some(elem => elem.key === el.key))
      .map(column => ({
        key: column?.key || '',
        show: true,
        title: column?.title as string,
      }));
    const lastIndex = filteredColumns.length - 1;
    // If there are action columns, they should be positioned as the last columns.
    filteredColumns.splice(lastIndex, 0, ...columnsNotInStorage);
    return filteredColumns;
  };

  const [columnsState, setColumnsState] = useState<TColumnsState[]>(
    getColumnsState(),
  );

  useEffect(() => {
    setColumnsState(getColumnsState());
  }, [columns]);
  const onDragEnd = (fromIndex: number, toIndex: number) => {
    const x = columnsState.filter(col => col?.show);
    const realToIndex = columnsState.findIndex(
      col => col.key === x[toIndex].key,
    );
    const realFromIndex = columnsState.findIndex(
      col => col.key === x[fromIndex].key,
    );

    const columnsCopy = [...columnsState];
    const item = columnsCopy.splice(realFromIndex, 1)[0];
    columnsCopy.splice(realToIndex, 0, item);
    setColumnsState(columnsCopy);
  };

  const handleColumnFilterChange = (columnKey: Key) => {
    const updatedColumnsState = columnsState.map(column => {
      if (column.key === columnKey) {
        return { ...column, show: !column.show };
      }
      return column;
    });
    setColumnsState(updatedColumnsState);
  };

  const content = useMemo(() => {
    return columnsState
      .filter(col => !!col?.title.trim())
      .map((column, i, arr) => (
        <Row>
          <Checkbox
            key={column.key}
            checked={column.show}
            disabled={arr.filter(col => col.show).length === 1 && column.show}
            onChange={() => handleColumnFilterChange(column.key)}
          >
            {column.title}
          </Checkbox>
        </Row>
      ));
  }, [columnsState]);

  useEffect(() => {
    if (storagePath) {
      const newStorageObject = {
        ...tablesSettingsFromStorage,
        [storagePath]: columnsState,
      };
      appLocalStorage.setItem(EStorageNames.tableSettings, newStorageObject);
    }
  }, [columnsState]);

  const visibleSortedColumns: TColumnType<T>[] = [];
  // sort columns by order from columnsState and filter out columns that are not visible
  columnsState
    .filter(col => col?.show)
    .forEach((col, index) => {
      const column = columns?.find(c => c.key === col.key);
      if (column) {
        visibleSortedColumns[index] = column;
      }
    });

  const exportCSVButton = exportCSV && (
    <CSVLink
      filename={exportCSV.filename || 'table.csv'}
      data={exportCSV.exportData}
      headers={visibleSortedColumns
        .filter(
          column =>
            !exportCSV.hiddenColumnDataIndexes?.includes(
              (column?.dataIndex || '') as string,
            ),
        )
        .map(col => ({
          label: col.title as string,
          key: col.dataIndex as string,
        }))}
      asyncOnClick={exportCSV.asyncOnClick}
      onClick={exportCSV.onClick}
    >
      {t('export.EXPORT_BTN')}
    </CSVLink>
  );
  const isExportCSVDisabled = dataSource?.length === 0;

  const onCollapseChange = (isCollapsed: boolean) => {
    appLocalStorage.setItem(EStorageNames.table_collapsed, isCollapsed);
  };

  return (
    <AntdTable<T>
      key={key}
      resetFiltersLabel={resetFiltersLabel}
      filterColumnLabel={filterColumnLabel}
      resultsLabel={resultsLabel}
      ofLabel={ofLabel}
      columns={visibleSortedColumns}
      onFiltersReset={onFiltersReset}
      hasFilters={hasFilters}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      setPageSize={setPageSize}
      totalDataCount={totalDataCount}
      pageSize={pageSize}
      currentPage={currentPage}
      tableHeight={
        height -
        (tabHeight || 0) -
        pageHeaderHeight -
        (pageHeaderContent || 0) -
        172
      }
      filterColumnContent={content}
      onDragEnd={onDragEnd}
      isCollapsed={appLocalStorage.getItem(EStorageNames.table_collapsed)}
      onCollapseChange={onCollapseChange}
      dataSource={dataSource}
      {...(exportCSVButton && {
        exportAction: {
          button: exportCSVButton,
          isLoading: exportCSV.isLoading,
          disabled: {
            condition: isExportCSVDisabled,
            tooltipText: isExportCSVDisabled
              ? t('export.DISABLED_TOOLTIP')
              : '',
          },
        },
      })}
      locale={{
        filterReset: t('columns-search.RESET_BTN'),
      }}
      {...restProps}
    />
  );
};
