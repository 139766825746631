import { FC, useEffect, useRef } from 'react';
import {
  Button,
  ENotificationType,
  TRtkErrorType,
  useShowToastNotification,
  useTranslation,
} from '@packages/utils';
import { Icon, EIconNames } from 'components/Base/Icon';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { useCreateElcMeetingActionItemMutation } from 'redux/apiSlice/elcs.slice';
import { TAddActionItemProps } from './AddActionItem.types';

const AddActionItem: FC<TAddActionItemProps> = ({
  tableHasNewRow,
  tableIsEmpty,
  newRowData,
  onAddNewRow,
  onRemoveNewRow,
  elcId,
  meetingId,
  dataSource,
  isFinishedGetActionItems,
}) => {
  const { showToastNotification } = useShowToastNotification();
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.meetings-tab.details.action-items.actions.add-action-item',
  });

  const [addActionItem, { isLoading, isSuccess }] =
    useCreateElcMeetingActionItemMutation();

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const newRowNode = document.querySelector('.new-row');
    if (newRowNode) {
      // @ts-ignore
      ref.current = newRowNode as HTMLDivElement;
      newRowNode.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [dataSource]);

  useEffect(() => {
    if (isSuccess && isFinishedGetActionItems) {
      onRemoveNewRow();
    }
  }, [isFinishedGetActionItems, isSuccess]);

  const onSaveNewRow = async () => {
    if (isLoading) return;
    if (!newRowData || !newRowData.title) {
      if (tableIsEmpty) {
        return;
      }
      onRemoveNewRow();
      return;
    }
    try {
      await addActionItem({
        elcId,
        meetingId,
        dueDate: newRowData.dueDate,
        ownerId: newRowData.ownerId,
        status: newRowData.status,
        title: newRowData.title,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (err) {
      onRemoveNewRow();
      showToastNotification({
        message: (err as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  useOutsideClick(
    ref,
    async () => {
      await onSaveNewRow();
    },
    true,
    ['.ant-select-dropdown', '.ant-picker-dropdown'],
  );

  return (
    <Button
      icon={<Icon icon={EIconNames.PLUS} />}
      color="primary"
      type="link"
      onClick={onAddNewRow}
      disabled={tableHasNewRow}
    >
      {t('ADD_ACTION_BTN')}
    </Button>
  );
};

export default AddActionItem;
