import { styled } from 'styled-components';
import { Row, Col, Button } from '@packages/utils';
import { Icon } from 'components/Base/Icon';

export const SSidebarHeader = styled(Row)<{ $collapsed: boolean }>`
  padding: ${({ $collapsed }) => ($collapsed ? '24px 0' : '24px')};
`;

export const SHeaderContent = styled(Col)<{ $collapsed: boolean }>`
  text-align: ${({ $collapsed }) => ($collapsed ? 'center' : 'initial')};
  border-bottom: 1px solid ${({ theme }) => theme.menuColorBorderActive};
  padding-bottom: 24px;
`;

export const SToggleIcon = styled(Icon)<{ $collapsed: boolean }>`
  transform: ${({ $collapsed }) =>
    $collapsed ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

export const SButton = styled(Button)<{ $collapsed: boolean }>`
  &.ant-btn {
    position: absolute;
    top: 68px;
    left: ${({ $collapsed }) => ($collapsed ? '62px' : '238px')};
    z-index: 99;
    &:active {
      .ant-btn-icon {
        svg {
          path {
            fill: ${({ theme }) => theme.colorBlackDescription}!important;
          }
        }
      }
    }
  }
`;
