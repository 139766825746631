import { useState, useEffect, useRef } from 'react';
import { InputRef } from 'antd';
import { useTheme } from 'styled-components';
import {
  Col,
  Row,
  Tag,
  useForm,
  FormItem,
  useGetValidationData,
  useTranslation,
  useShowToastNotification,
  TRtkErrorType,
  ENotificationType,
  Spinner,
} from '@packages/utils';
import {
  useCreateZipCodeMutation,
  useDeleteZipCodeMutation,
} from 'redux/apiSlice/elcs.slice';
import { InplaceEditLabels } from 'components/Base/EditableFields/components/InplaceEditLabel';
import { Icon, EIconNames } from 'components/Base/Icon';
import { Tooltip } from 'components/Base/Tooltip';
import { useCheckPermission } from 'hooks/useCheckPermission';
import {
  AddNewTag,
  EditZipInput,
  SForm,
  TooltipGlobalStyle,
} from '../AreaServedSection.styles';
import { TZipCodesProps } from './ZipCodes.types';

const ZipCodes = ({ elcBoundaries, elcId }: TZipCodesProps) => {
  const isEditable = useCheckPermission('ELC_BOUNDARY_EDIT');
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.details-tab.area-served-section.zip-codes',
  });
  const [showAll, setShowAll] = useState(false);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const { form } = useForm<{ zipCode: string }>();
  const theme = useTheme();
  const { validationRules } = useGetValidationData();
  const { showToastNotification } = useShowToastNotification();
  const inputRef = useRef<InputRef>(null);

  const visibleZipCodes = showAll ? elcBoundaries : elcBoundaries.slice(0, 8);
  const hiddenZipCodes = elcBoundaries.slice(8);

  const [addZipCode, { isLoading: isAddZipCodeLoading }] =
    useCreateZipCodeMutation();
  const [deleteZipCode] = useDeleteZipCodeMutation();

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  const handleRemove = async (
    e: React.MouseEvent<HTMLElement>,
    boundaryId: string,
  ) => {
    if (elcBoundaries.length === 1) {
      e.preventDefault();
    }
    try {
      await deleteZipCode({
        elcId,
        boundaryId,
      }).unwrap();
      showToastNotification({
        message: t('messages.REMOVED'),
        type: ENotificationType.SUCCESS,
      });
    } catch (err) {
      showToastNotification({
        message: (err as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  const handleInputConfirm = async () => {
    const values = await form.validateFields();
    try {
      if (
        form.isFieldTouched('zipCode') &&
        inputValue &&
        !elcBoundaries.some(zip => zip.id === inputValue)
      ) {
        await addZipCode({ ...values, elcId }).unwrap();
        showToastNotification({
          message: t('messages.ADDED'),
          type: ENotificationType.SUCCESS,
        });
      }
      setInputVisible(false);
      form.resetFields();
    } catch (e) {
      setInputVisible(false);
      form.resetFields();
      showToastNotification({
        message: (e as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  return (
    <>
      <TooltipGlobalStyle />
      <Row wrap={false}>
        <Col>
          <InplaceEditLabels
            label={t('LABEL')}
            icon={EIconNames.EMAIL_OUTLINED_ARROW}
          />
        </Col>
        <Col>
          <Row>
            {visibleZipCodes.map(item => (
              <Col key={item.id}>
                <Tag
                  color="grey"
                  closable={isEditable}
                  onClose={e => handleRemove(e, item.id)}
                >
                  {item.zipCode}
                </Tag>
              </Col>
            ))}
            {elcBoundaries.length > 8 && (
              <Col>
                <Tooltip
                  title={
                    showAll ? (
                      ''
                    ) : (
                      <>
                        {hiddenZipCodes.map(item => (
                          <Tag color="grey" key={item.id}>
                            {item.zipCode}
                          </Tag>
                        ))}
                      </>
                    )
                  }
                >
                  <Tag
                    color="grey"
                    className="pointer"
                    onClick={() => setShowAll(!showAll)}
                  >
                    {showAll
                      ? 'Show less'
                      : `Show ${elcBoundaries.length - 8} more`}
                  </Tag>
                </Tooltip>
              </Col>
            )}
            {isEditable && (
              <Col>
                {inputVisible ? (
                  <SForm form={form} autoComplete="off">
                    <FormItem
                      name="zipCode"
                      rules={[validationRules.zipCode()]}
                    >
                      <EditZipInput
                        ref={inputRef}
                        placeholder={t('ADD_BUTTON')}
                        value={inputValue}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setInputValue(e.target.value)
                        }
                        onBlur={handleInputConfirm}
                        onPressEnter={handleInputConfirm}
                      />
                      {isAddZipCodeLoading && (
                        <Spinner spinnerSize={12} className="ml-1" />
                      )}
                    </FormItem>
                  </SForm>
                ) : (
                  <AddNewTag
                    color="grey"
                    icon={
                      <Icon
                        icon={EIconNames.PLUS}
                        size={16}
                        color={theme.tagColorGreyNormalIcon}
                      />
                    }
                    onClick={() => setInputVisible(true)}
                  >
                    {t('ADD_BUTTON')}
                  </AddNewTag>
                )}
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ZipCodes;
