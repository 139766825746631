import SidebarFooterMenu from './SidebarFooterMenu';
import SidebarUserInfo from './SidebarUserInfo';

export const SidebarFooter = () => {
  return (
    <>
      <SidebarUserInfo />
      <SidebarFooterMenu />
    </>
  );
};

export default SidebarFooter;
