import { TOrganizationByIdUI } from 'data/types/organizations.types';

export type TActionsProps = {
  id: string;
  data?: TOrganizationByIdUI;
};

export enum EOrganizationActions {
  DELETE = 'delete',
  DOWNLOAD_QR = 'download-qr',
  COPY_URL = 'copy-url',
}
