import {
  ENotificationType,
  TRtkErrorType,
  useShowToastNotification,
  useTranslation,
} from '@packages/utils';
import { useEditElcMeetingNoteMutation } from 'redux/apiSlice/elcs.slice';
import { TNotesItemProps } from './NoteItem.types';
import NoteCard from '../NoteCard';

const NoteItem = ({ elcId, meetingId, note, noteId }: TNotesItemProps) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.meetings-tab.details.notes.edit.note',
  });
  const { showToastNotification } = useShowToastNotification();

  const [editElcMeetingNote, { isLoading, isError }] =
    useEditElcMeetingNoteMutation();

  const onEdit = async (value: string) => {
    try {
      await editElcMeetingNote({
        elcId,
        meetingId,
        noteId,
        note: value,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (e) {
      showToastNotification({
        message: (e as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  return (
    <NoteCard
      elcId={elcId}
      meetingId={meetingId}
      onEdit={onEdit}
      isLoading={isLoading}
      isError={isError}
      note={note}
      noteId={noteId}
    />
  );
};

export default NoteItem;
