import { FC, useEffect } from 'react';
import get from 'lodash/get';
import { useTranslation } from '@packages/utils';
import { Modal } from 'components/Base/Modal';
import useForm from 'hooks/useForm';
import { TPeopleCreateUI } from 'data/types/people.types';
import { useCreatePeopleMutation } from 'redux/apiSlice/people.slice';
import {
  useShowToastNotification,
  ENotificationType,
} from 'components/Base/Notification';
import { TRtkErrorType, TValidateFieldsError } from 'data/types/generic.types';
import { TCreateCoordinatorModalProps } from './CreateCoordinatorModal.types';
import CreateCoordinatorForm from './CreateCoordinatorForm';

const CreateCoordinatorModal: FC<TCreateCoordinatorModalProps> = ({
  open,
  setOpen,
  setCoordinatorId,
  label,
}) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.generic-actions.create-coordinator.modal',
  });
  const { showToastNotification } = useShowToastNotification();
  const { initialName, visible } = open;
  const { form } = useForm<TPeopleCreateUI>();
  const [createWorkshopMaintainer, { isLoading: maintainerLoading }] =
    useCreatePeopleMutation();
  const handleClose = () => {
    setOpen({
      visible: false,
      initialName: '',
    });
    form.resetFields();
  };

  useEffect(() => {
    if (initialName) {
      const [firstName, ...lastName] = initialName.split(' ');
      form.setFieldValue('firstName', firstName);
      form.setFieldValue('lastName', lastName.join(' '));
    }
  }, [initialName]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      try {
        const coordinatorId = await createWorkshopMaintainer(values).unwrap();
        if (setCoordinatorId) {
          setCoordinatorId(coordinatorId);
        }
        showToastNotification({
          message: t('SUCCESS_MESSAGE', {
            placeholder: label,
          }),
          type: ENotificationType.SUCCESS,
        });
        handleClose();
      } catch (err) {
        showToastNotification({
          message: (err as TRtkErrorType)?.data?.message,
          type: ENotificationType.ERROR,
        });
      }
    } catch (e) {
      if (!(e as TValidateFieldsError)?.errorFields) {
        const message = get(e, 'data.message');
        showToastNotification({
          message,
          type: ENotificationType.ERROR,
        });
      }
    }
  };

  return (
    <Modal
      open={visible}
      onCancel={handleClose}
      onOk={handleSubmit}
      title={t('TITLE', {
        placeholder: label,
      })}
      confirmLoading={maintainerLoading}
      okText={t('SUBMIT_BTN')}
      cancelText={t('CANCEL_BTN')}
    >
      <CreateCoordinatorForm form={form} />
    </Modal>
  );
};

export default CreateCoordinatorModal;
