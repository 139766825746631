import get from 'lodash/get';
import { useParams } from 'react-router-dom';
import { useTranslation } from '@packages/utils';
import PhotoUpload from 'components/Base/PhotoUpload';
import { TValidateFieldsError } from 'data/types/generic.types';
import {
  useGetWorkshopPhotoGalleryQuery,
  useAddWorkshopPhotoMutation,
} from 'redux/apiSlice/workshops.slice';
import {
  ENotificationType,
  useShowToastNotification,
} from 'components/Base/Notification';
import { TWorkshopPhotoProps } from './WorkshopPhoto.types';

const WorkshopPhoto = ({ photo }: TWorkshopPhotoProps) => {
  const params = useParams();
  const { id } = params as { id: string };
  const { data: gallery } = useGetWorkshopPhotoGalleryQuery();
  const [addWorkshopImage, { isLoading }] = useAddWorkshopPhotoMutation();
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.workshops.details.basic-info.photo',
  });
  const { showToastNotification } = useShowToastNotification();

  const handleUpload = async (img: string) => {
    try {
      await addWorkshopImage({ workshopId: id, photoUrl: img }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (e) {
      if (!(e as TValidateFieldsError)?.errorFields) {
        const message = get(e, 'data.message');
        showToastNotification({
          message,
          type: ENotificationType.ERROR,
        });
      }
    }
  };

  return (
    <PhotoUpload
      gallery={gallery?.photos}
      handleUpload={handleUpload}
      isLoading={isLoading}
      canEdit={!photo?.allowedOperations.readonly}
      selectedImage={photo?.value}
    />
  );
};

export default WorkshopPhoto;
