import { FC } from 'react';
import { TagEllipsis } from 'components/Shared/TagEllipsis';
import { EditableColumn } from 'components/Base/Table/components/EditableColumn';
import { TTagColumnContentProps } from './TagColumnContent.types';

const TagColumnContent: FC<TTagColumnContentProps> = ({
  tags,
  onOpenModal,
  isEditable,
}) => {
  return (
    <EditableColumn
      onEdit={onOpenModal}
      onAdd={onOpenModal}
      isEmpty={!tags?.length}
      readonly={!isEditable}
    >
      {(tags || []).map(tag => (
        <TagEllipsis
          className="mb-1"
          key={tag.value}
          text={tag.text}
          textMaxLength={20}
          color="grey"
        />
      ))}
    </EditableColumn>
  );
};

export default TagColumnContent;
