import { useEffect } from 'react';
import { useTranslation } from '@packages/utils';
import { useGetContactsQuery } from 'redux/apiSlice/elcs.slice';
import { EContactsColumnKey, TContactsUI } from 'data/types/elcs.types';
import useTable from 'components/Base/Table/hooks/useTable';
import { ETableStoragePaths } from 'components/Base/Table/Table.types';
import { useGetTagsListQuery } from 'redux/apiSlice/tags.slice';
import { ETagType } from 'data/types/tags.types';
import { useCheckPermission } from 'hooks/useCheckPermission';
import { TabBodyWrapper } from 'components/Base/Tabs';
import { EIconNames } from 'components/Base/Icon';
import { Table } from 'components/Base/Table';
import CardContent from 'components/Base/Layout/Content/CardContent';
import useNavigateWithBackState from 'hooks/useNavigateWithBackState';
import useGetData from './ELACMembersTab.data';
import { TELACMembersTabProps } from './ELACMembersTab.types';
import AddELACMembers from './ELACMembersActions/AddELACMembers';
import ExportELACMembers from './ELACMembersActions/ExportELACMembers';

const ElacMembers = ({ elcId }: TELACMembersTabProps) => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.elcs.detail.elac-members-tab.list',
  });

  const isContactEditable = useCheckPermission('ELC_CONTACTS_EDIT');
  const { defaultColumns } = useGetData(elcId, isContactEditable);
  const {
    page,
    pageSize,
    setPageSize,
    sortColumn,
    selectedFilters,
    hasSelectedFilter,
    onFiltersReset,
    columns,
    setColumns,
    handleTableChange,
  } = useTable<TContactsUI>({
    storagePath: ETableStoragePaths.ELAC_MEMBERS,
    defaultColumns,
  });

  const { data, isFetching, isLoading } = useGetContactsQuery({
    page,
    pageSize,
    sortColumn,
    filters: selectedFilters,
    elcId,
    isElacMember: true,
  });

  const { data: tagsFilters, isSuccess: tagsFiltersSuccess } =
    useGetTagsListQuery(ETagType.PEOPLE);

  useEffect(() => {
    if (tagsFiltersSuccess) {
      setColumns(p => {
        return p.map(item => {
          if (item.key === EContactsColumnKey.TAGS && tagsFiltersSuccess) {
            item.filters = tagsFilters;
            item.filterSearch = true;
          }
          return item;
        });
      });
    }
  }, [tagsFiltersSuccess, tagsFilters]);

  const { goPeopleDetails } = useNavigateWithBackState();

  const navigateToPeopleDetails = (record: TContactsUI) => {
    return {
      onClick: () => goPeopleDetails(record.userId, t('TITLE')),
    };
  };

  return (
    <CardContent tabContent contentPadding="8px 0px">
      <TabBodyWrapper
        isLoading={isLoading}
        emptyText={t('empty-view.EMPTY_TEXT')}
        emptyIcon={EIconNames.PARENT_OUTLINED}
      >
        <Table<TContactsUI>
          onFiltersReset={onFiltersReset}
          hasFilters={hasSelectedFilter}
          columns={columns}
          {...(isContactEditable && {
            additionalActions: [
              <ExportELACMembers
                elcId={elcId}
                selectedFilters={selectedFilters}
              />,
              <AddELACMembers elcId={elcId} />,
            ],
          })}
          dataSource={data?.content}
          rowClassName="pointer"
          onRow={navigateToPeopleDetails}
          loading={isFetching}
          pageSize={pageSize}
          setPageSize={setPageSize}
          totalDataCount={data?.totalElements}
          onChange={handleTableChange}
          rowKey={record => record.parentOrderId}
          currentPage={page}
          storagePath={ETableStoragePaths.ELAC_MEMBERS}
        />
      </TabBodyWrapper>
    </CardContent>
  );
};

export default ElacMembers;
