import {
  ENotificationType,
  TRtkErrorType,
  useShowToastNotification,
  useTranslation,
} from '@packages/utils';
import { useCreateElcMeetingNoteMutation } from 'redux/apiSlice/elcs.slice';
import { TAddNoteProps } from './AddNote.types';
import NoteCard from '../components/NoteCard';

const AddNote = ({ elcId, meetingId }: TAddNoteProps) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.meetings-tab.details.notes.add',
  });
  const { showToastNotification } = useShowToastNotification();
  const [createElcMeetingNote, { isLoading, isError }] =
    useCreateElcMeetingNoteMutation();

  const onSubmit = async (value: string) => {
    try {
      await createElcMeetingNote({
        meetingId,
        elcId,
        note: value,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (err) {
      showToastNotification({
        message: (err as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  return (
    <NoteCard
      elcId={elcId}
      meetingId={meetingId}
      onEdit={onSubmit}
      isLoading={isLoading}
      isError={isError}
    />
  );
};

export default AddNote;
