import useForm from 'hooks/useForm';
import { Modal } from 'components/Base/Modal';
import {
  ENotificationType,
  useShowToastNotification,
} from 'components/Base/Notification';
import { useCreateOrganizationNoteMutation } from 'redux/apiSlice/organizations.slice';
import { TCreateOrganizationNoteUI } from 'data/types/organizations.types';
import { TRtkErrorType } from 'data/types/generic.types';
import { useTranslation } from '@packages/utils';
import { TAddDetailsModalProps } from './AddDetailsModal.types';
import { addDetailsSectionKey } from '../AddDetailsSection.data';
import AddDetailsForm from './AddDetailsForm';

const AddDetailsModal = ({
  open,
  setOpen,
  organizationId,
  categories,
}: TAddDetailsModalProps) => {
  const { form } = useForm<TCreateOrganizationNoteUI>();
  const { t } = useTranslation({
    keyPrefix: `${addDetailsSectionKey}.modals.add`,
  });
  const { showToastNotification } = useShowToastNotification();
  const [createOrganizationNote, { isLoading }] =
    useCreateOrganizationNoteMutation();

  const onCloseModal = () => {
    setOpen(false);
    form.resetFields();
  };

  const handleAddBtnClick = async () => {
    try {
      const values = await form.validateFields();
      await createOrganizationNote({ ...values, organizationId }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
      onCloseModal();
    } catch (err) {
      showToastNotification({
        message: (err as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  return (
    <Modal
      destroyOnClose
      title={t('TITLE')}
      open={open}
      onCancel={onCloseModal}
      okText={t('SUBMIT_BTN')}
      onOk={handleAddBtnClick}
      confirmLoading={isLoading}
    >
      <AddDetailsForm form={form} categories={categories} />
    </Modal>
  );
};

export default AddDetailsModal;
