import { Col, Title } from '@packages/utils';
import { InView } from 'react-intersection-observer';
import {
  SContentHeaderWrapper,
  SContentWrapper,
  SMainContentWrapper,
  SSectionWrapper,
} from '../Content.styles';
import { TSectionsContentProps } from './SectionsContent.types';

const SectionsContent = ({ sections }: TSectionsContentProps) => {
  return (
    <SMainContentWrapper>
      {sections.map(section => (
        <InView key={section.id}>
          {({ ref, inView }) => (
            <SSectionWrapper
              ref={ref}
              id={`section-${section.id}`}
              className={`section ${inView ? `fixed` : ''}`}
            >
              <SContentHeaderWrapper
                className="section-header"
                justify="space-between"
                align="middle"
              >
                <Col>
                  <Title level={3} color="#000000">
                    {section.title}
                  </Title>
                </Col>
                {section.actions && <Col>{section.actions}</Col>}
              </SContentHeaderWrapper>
              <SContentWrapper>{section.content}</SContentWrapper>
            </SSectionWrapper>
          )}
        </InView>
      ))}
    </SMainContentWrapper>
  );
};

export default SectionsContent;
