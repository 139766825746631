import { useState } from 'react';
import { useTranslation, Button } from '@packages/utils';
import { useTheme } from 'styled-components';
import { Col } from 'components/Base/Grid';
import { EIconNames, Icon } from 'components/Base/Icon';
import { TFamilyListActionsProps } from './FamilyListActions.types';
import EditFamilyModal from './EditFamilyModal';
import { familyListKey } from '../Family.data';

const FamilyListActions = ({ familyData }: TFamilyListActionsProps) => {
  const theme = useTheme();
  const { t } = useTranslation({
    keyPrefix: familyListKey.concat('.actions.edit-family'),
  });
  const [openEditFamilyModal, setOpenEditFamilyModal] =
    useState<boolean>(false);

  return (
    <>
      <Col>
        <Button
          icon={
            <Icon
              icon={EIconNames.EDIT_FILLED}
              size={14}
              className="pointer"
              color={theme.colorPrimaryDefault}
            />
          }
          tooltip={t('EDIT_FAMILY_BTN_TOOLTIP')}
          onClick={() => setOpenEditFamilyModal(true)}
          color="primary"
          shape="circle"
          size="middle"
        />
      </Col>

      {openEditFamilyModal && familyData && (
        <EditFamilyModal
          open={openEditFamilyModal}
          setOpen={setOpenEditFamilyModal}
          familyData={familyData}
        />
      )}
    </>
  );
};

export default FamilyListActions;
