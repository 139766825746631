import { useTranslation, Tag } from '@packages/utils';
import { Paragraph } from 'components/Base/Typography';
import { Col, Row } from 'components/Base/Grid';
import { TDetailsListViewProps } from './DetailsListView.types';
import {
  SDetailsListItemWrapper,
  SNotesHeader,
} from './DetailsListView.styles';
import { addDetailsSectionKey } from '../AddDetailsSection.data';
import Actions from '../Actions';
import AddAction from '../Actions/AddAction';

const DetailsListView = ({
  organizationNotes,
  organizationId,
  allowedOperations,
  categories,
}: TDetailsListViewProps) => {
  const { t } = useTranslation({
    keyPrefix: `${addDetailsSectionKey}.notes-categories`,
  });

  return (
    <>
      {allowedOperations.canAdd && categories && (
        <AddAction
          organizationId={organizationId}
          canAdd={categories.length > 0}
          categories={categories}
        />
      )}
      <Row gutter={[16, 16]}>
        {organizationNotes?.value?.map(item => (
          <Col key={item.id} span={24}>
            <SDetailsListItemWrapper>
              <SNotesHeader justify="space-between">
                <Col>
                  <Tag color="blue">{t(item.category)}</Tag>
                </Col>
                <Col className="actions-section">
                  <Actions
                    note={item}
                    allowedOperations={allowedOperations}
                    organizationId={organizationId}
                  />
                </Col>
              </SNotesHeader>
              <Paragraph className="mt-3">{item.note}</Paragraph>
            </SDetailsListItemWrapper>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default DetailsListView;
