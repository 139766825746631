import { TCreateConverter, TFetchConverter } from 'data/types/converter.types';
import {
  EKitManagementParentColumnKey,
  EKitManagementWorkshopFilterParams,
  TAddKitManagementParentShippingDetailsDB,
  TAddKitManagementParentShippingDetailsUI,
  TKitManagementMarkAsOrderedDB,
  TKitManagementMarkAsOrderedUI,
  TKitManagementParentDB,
  TKitManagementParentUI,
  TKitManagementWorkshopDB,
  TKitManagementWorkshopUI,
  TParentMarkAsOrderedDB,
  TParentMarkAsOrderedUI,
} from 'data/types/kitManagement.types';
import { TTableFilters } from 'data/types/generic.types';
import {
  EWorkshopCategory,
  EWorkshopPartType,
} from 'data/types/workshops.types';
import { dateConverter, dateShortenFormat } from 'helpers/utils';
import {
  workshopCategoryToCount,
  workshopPartsToCount,
} from './workshops.convertors';
import {
  addressConvertor,
  addressShortDataConverter,
  phoneNumberConverter,
} from './general.converters';
import { tagsGetConverter } from './tags.convertor';

const getPartDisplayText = (
  category: EWorkshopCategory,
  partType: EWorkshopPartType,
): string => {
  if (
    category === EWorkshopCategory.STANDALONE &&
    partType === EWorkshopPartType.STANDALONE
  ) {
    return 'Standalone';
  }
  return `${workshopPartsToCount[partType]} of ${workshopCategoryToCount[category]}`;
};

export const kitManagementWorkshopConverter: TFetchConverter<
  TKitManagementWorkshopUI,
  TKitManagementWorkshopDB
> = {
  fromDb: data => {
    const partDisplayText = getPartDisplayText(
      data.workshopCategory,
      data.workshopPartType,
    );
    return {
      workshopId: data.workshopId,
      hostId: data.hostId,
      orderId: data.orderId,
      orderDate:
        data.orderDate && dateConverter(data.orderDate, dateShortenFormat),
      hostName: data.hostName,
      date:
        data.firstSessionDate &&
        dateConverter(data.firstSessionDate, dateShortenFormat),
      orderStatus: data.orderStatus,
      shippingAddress: data.shippingAddress,
      workshopType: data.workshopType,
      workshopCategory: data.workshopCategory,
      workshopPartType: data.workshopPartType,
      partDisplayText,
      location: data.location,
    };
  },
};

export const kitManagementParentConverter: TFetchConverter<
  TKitManagementParentUI,
  TKitManagementParentDB
> = {
  fromDb: data => {
    const workshopCategory = data.workshopTypeCategory;
    const partDisplayText = getPartDisplayText(
      workshopCategory,
      data.workshopPartType,
    );
    return {
      parentOrderId: data.parentOrderId,
      email: data.email,
      phone: phoneNumberConverter.fromDb(data.phone || '') || '',
      status: data.status,
      firstName: data.firstName,
      lastName: data.lastName,
      orderDate:
        data.orderDate && dateConverter(data.orderDate, dateShortenFormat),
      language: data.language,
      ageGroup: data.ageGroup,
      childrenCount: data.childrenCount,
      quantity: data.quantity,
      workshopClassId: data.workshopClassId,
      workshopId: data.workshopId,
      workshopSessionId: data.workshopSessionId,
      registrantId: data.registrantId,
      workshopType: data.workshopType,
      address: addressShortDataConverter.fromDb(data.address),
      workshopTypeCategory: workshopCategory,
      workshopPartType: data.workshopPartType,
      partDisplayText,
      orientationKitQuantity: data.orientationKitQuantity,
      hostName: data.hostName,
      location: data.location,
      tags: data.tags && tagsGetConverter.fromDb(data.tags),
    };
  },
};

export const kitManagementWorkshopFilterConverter = (
  filters: TTableFilters<EKitManagementWorkshopFilterParams>['filters'],
) => {
  const filtersToDB = {
    ...(filters?.statuses && {
      [EKitManagementWorkshopFilterParams.STATUS]: filters?.statuses.map(
        status => status,
      ),
    }),
    ...(filters?.types && {
      [EKitManagementWorkshopFilterParams.TYPE]: filters?.types.map(
        type => type,
      ),
    }),
    ...(filters?.hostIds && {
      [EKitManagementWorkshopFilterParams.HOST]: filters?.hostIds,
    }),
    ...(filters?.partTypes && {
      [EKitManagementWorkshopFilterParams.PART_TYPES]: filters?.partTypes.map(
        patyType => patyType,
      ),
    }),
    ...(filters?.locationIds && {
      [EKitManagementWorkshopFilterParams.LOCATION]: filters?.locationIds,
    }),
  };
  return filtersToDB;
};

export const kitManagementParentFilterConverter = (
  filters: TTableFilters<EKitManagementParentColumnKey>['filters'],
) => {
  const filtersToDB = {
    ...(filters?.statuses && {
      [EKitManagementParentColumnKey.STATUS]: filters.statuses,
    }),
    ...(filters?.hasShipmentAddress &&
      filters.hasShipmentAddress?.length !== 2 && {
        [EKitManagementParentColumnKey.HAS_SHIPMENT_ADDRESS]:
          filters.hasShipmentAddress,
      }),
    ...(filters?.hostIds && {
      [EKitManagementParentColumnKey.HOST]: filters.hostIds,
    }),
    ...(filters?.locationIds && {
      [EKitManagementParentColumnKey.LOCATION]: filters.locationIds,
    }),
    ...(filters?.firstName && {
      [EKitManagementParentColumnKey.FIRST_NAME]: filters.firstName
        .toString()
        .trim(),
    }),
    ...(filters?.lastName && {
      [EKitManagementParentColumnKey.LAST_NAME]: filters.lastName
        .toString()
        .trim(),
    }),
    ...(filters?.tagIds && {
      [EKitManagementParentColumnKey.TAGS]: filters?.tagIds,
    }),
  };
  return filtersToDB;
};

export const kitManagementParentShippingDetailsConverter: TCreateConverter<
  TAddKitManagementParentShippingDetailsUI,
  TAddKitManagementParentShippingDetailsDB
> = {
  toDb: data => ({
    parentOrderId: data.parentOrderId,
    address: data.address && addressConvertor.toDb(data.address),
  }),
};

export const kitManagementMarkAsOrderedConverter: TCreateConverter<
  TKitManagementMarkAsOrderedUI,
  TKitManagementMarkAsOrderedDB
> = {
  toDb: data => ({
    partOrderIds: data.partOrderIds,
    orderDate: data.orderDate,
  }),
};

export const parentMarkAsOrderedConverter: TCreateConverter<
  TParentMarkAsOrderedUI,
  TParentMarkAsOrderedDB
> = {
  toDb: data => ({
    parentOrderIds: data.parentOrderIds,
    orderDate: data.orderDate,
  }),
};
