import { useTranslation, Tag } from '@packages/utils';
import { useAppSelector } from 'redux/store';
import CardContent from 'components/Base/Layout/Content/CardContent';
import { Collapse } from 'components/Base/Collapse';
import PageWrapper from 'components/Base/PageWrapper';
import { Row, Col } from 'components/Base/Grid';
import { Paragraph } from 'components/Base/Typography';
import { useTheme } from 'styled-components';
import { EPreferredMFATypes } from 'data/types/amplify.types';
import ChangePassword from './SetNewPassword';
import Authentication from './Authentication';

const Security = () => {
  const theme = useTheme();
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.security',
  });
  const { t: tAuthentication } = useTranslation({
    keyPrefix: 'descriptions.authentication',
  });
  const amplifyUser = useAppSelector(state => state.userSlice.amplifyUser);
  return (
    <PageWrapper title={t('TITLE')}>
      <CardContent contentPadding="16px">
        <Collapse
          accordion
          items={[
            {
              key: '1',
              label: tAuthentication('changePassword.TITLE'),
              children: <ChangePassword />,
            },
            {
              key: '2',
              label: (
                <Row gutter={[10, 10]}>
                  <Col>
                    <Paragraph
                      fontSize={theme.collapseFontSizeXs}
                      color={theme.collapseColorText}
                      fontWeight={theme.collapseFontWeight}
                    >
                      {tAuthentication('twoFA.TITLE')}
                    </Paragraph>
                  </Col>
                  <Col>
                    <Tag
                      color={
                        amplifyUser?.preferredMFA === EPreferredMFATypes.TOTP
                          ? 'primary'
                          : 'danger'
                      }
                    >
                      {amplifyUser?.preferredMFA === EPreferredMFATypes.TOTP
                        ? tAuthentication('twoFA.radio.ON')
                        : tAuthentication('twoFA.radio.OFF')}
                    </Tag>
                  </Col>
                </Row>
              ),
              children: <Authentication />,
            },
          ]}
        />
      </CardContent>
    </PageWrapper>
  );
};

export default Security;
