import { useTheme } from 'styled-components';
import { Card } from '@packages/utils';
import { Paragraph, Title } from 'components/Base/Typography';
import { Col } from 'components/Base/Grid';
import { TErrorPageViewProps } from './ErrorPageView.types';
import { SErrorPageWrapper, SImage } from './ErrorPageView.styles';

const ErrorPageView = ({
  imgSrc,
  alt,
  title,
  description,
}: TErrorPageViewProps) => {
  const theme = useTheme();
  return (
    <Card className="m-4">
      <SErrorPageWrapper justify="center" className="py-10">
        <Col>
          <SImage src={imgSrc} alt={alt} />
          <Title level={2} color={theme.colorPrimaryDefault}>
            {title}
          </Title>
          <Paragraph>{description}</Paragraph>
        </Col>
      </SErrorPageWrapper>
    </Card>
  );
};

export default ErrorPageView;
