import { FC } from 'react';
import { useTheme } from 'styled-components';
import { Paragraph, useTranslation } from '@packages/utils';
import { Col, Row } from 'components/Base/Grid';
import { EWorkshopStatus } from 'data/types/workshops.types';
import { TProvidingProps } from './Providing.types';
import Childcare from './Childcare/Childcare';
import Food from './Food/Food';
import { providingKey } from './Providing.data';

const Providing: FC<TProvidingProps> = ({
  childcareProvided,
  foodProvided,
  id,
  workshopStatus,
}) => {
  const theme = useTheme();
  const needConfirmation = workshopStatus === EWorkshopStatus.PUBLISHED;
  const { t } = useTranslation({
    keyPrefix: providingKey,
  });

  return (
    <Row gutter={[8, 0]} align="middle" wrap={false}>
      <Col style={{ width: `${theme.inPlaceEditLabelMaxWidth}px` }}>
        <Paragraph
          fontSize={theme.inPlaceEditFontSize}
          color={theme.colorBlackGrey60}
          fontWeight={theme.inPlaceEditFontWeightLabel}
          fontFamily={theme.inPlaceEditFontFamily}
        >
          {t('LABEL')}
        </Paragraph>
      </Col>
      <Col>
        <Food
          foodProvided={foodProvided}
          id={id}
          needConfirmation={needConfirmation}
        />
      </Col>
      <Col>
        <Childcare
          id={id}
          childcareProvided={childcareProvided}
          needConfirmation={needConfirmation}
        />
      </Col>
    </Row>
  );
};

export default Providing;
