import { useEffect, useState } from 'react';
import { RadioChangeEvent } from 'antd';
import { get } from 'lodash';
import {
  Button,
  ENotificationType,
  Form,
  FormItem,
  Input,
  Modal,
  TextArea,
  useForm,
  useShowToastNotification,
  useTranslation,
  filterLabelOption,
} from '@packages/utils';
import { EIconNames, Icon } from 'components/Base/Icon';
import { Select } from 'components/Base/Select';
import { RadioGroup } from 'components/Base/RadioGroup';
import {
  useAddAdditionalEmailMutation,
  useAddAdditionalPhoneMutation,
  useAddPeopleOrganizationsMutation,
} from 'redux/apiSlice/people.slice';
import { TValidateFieldsError } from 'data/types/generic.types';
import { useGetOrganizationsIdentityQuery } from 'redux/apiSlice/organizations.slice';
import {
  TAddInfoFormProps,
  TAddInfoProps,
  TInfoTypeProps,
} from './AddInfo.types';
import useGetFormData from './AddInfo.data';

const AddInfo = ({
  allowActionsEmail,
  allowActionsMobile,
  userId,
}: TAddInfoProps) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.people.details.details-tab.actions.add',
  });
  const { showToastNotification } = useShowToastNotification();
  const { form } = useForm<TAddInfoFormProps>();
  const [open, setOpen] = useState<boolean>(false);
  let defaultSelectedType: TInfoTypeProps;

  if (allowActionsEmail.canAdd) {
    defaultSelectedType = 'email';
  } else if (allowActionsMobile.canAdd) {
    defaultSelectedType = 'mobile';
  } else {
    defaultSelectedType = 'organization';
  }

  const [selectedType, setSelectedType] =
    useState<TInfoTypeProps>(defaultSelectedType);
  const { addInfoFormFields } = useGetFormData();
  const { organizationId, email, mobile, title, infoType } = addInfoFormFields;
  const [addAdditionalEmail, { isLoading: addAdditionalEmailLoading }] =
    useAddAdditionalEmailMutation();
  const [addAdditionalPhone, { isLoading: addAdditionalPhoneLoading }] =
    useAddAdditionalPhoneMutation();
  const [addPeopleOrganizations, { isLoading: addPeopleOrganizationsLoading }] =
    useAddPeopleOrganizationsMutation();
  const { data: organizations } = useGetOrganizationsIdentityQuery();

  useEffect(() => {
    setSelectedType(defaultSelectedType);
  }, [defaultSelectedType]);

  const infoTypeOptions = [
    ...(allowActionsEmail.canAdd
      ? [
          {
            value: 'email',
            label: t('modal.form.email.LABEL'),
          },
        ]
      : []),
    ...(allowActionsMobile.canAdd
      ? [
          {
            value: 'mobile',
            label: t('modal.form.mobile.LABEL'),
          },
        ]
      : []),
    {
      value: 'organization',
      label: t('modal.form.organization.LABEL'),
    },
  ];

  const handleClose = () => {
    setOpen(false);
    form.resetFields();
  };

  const onChange = (e: RadioChangeEvent) => {
    setSelectedType(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      if (selectedType === 'email') {
        await addAdditionalEmail({ userId, ...values }).unwrap();
      }
      if (selectedType === 'mobile') {
        await addAdditionalPhone({ userId, ...values }).unwrap();
      }
      if (selectedType === 'organization') {
        await addPeopleOrganizations({ userId, ...values }).unwrap();
      }
      showToastNotification({
        message: t('modal.SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
      handleClose();
    } catch (err) {
      if (!(err as TValidateFieldsError)?.errorFields) {
        const message = get(err, 'data.message');
        showToastNotification({
          message,
          type: ENotificationType.ERROR,
        });
      }
    }
  };

  const renderFormItems = (type: TInfoTypeProps) => {
    const formItems: {
      [key in TInfoTypeProps]: JSX.Element;
    } = {
      email: (
        <FormItem {...email}>
          <Input placeholder={email.placeholder} />
        </FormItem>
      ),
      mobile: (
        <FormItem {...mobile}>
          <Input placeholder={mobile.placeholder} />
        </FormItem>
      ),
      organization: (
        <>
          <FormItem {...organizationId}>
            <Select
              filterOption={filterLabelOption}
              showSearch
              placeholder={organizationId.placeholder}
              options={organizations}
            />
          </FormItem>
          <FormItem {...title}>
            <TextArea
              maxLength={250}
              showCount
              placeholder={title.placeholder}
            />
          </FormItem>
        </>
      ),
    };

    return formItems[type] || null;
  };

  return (
    <>
      <Button
        color="primary"
        type="primary"
        onClick={() => setOpen(true)}
        icon={<Icon icon={EIconNames.PLUS} className="pointer" />}
      >
        {t('ADD_BTN')}
      </Button>
      <Modal
        title={t('modal.TITLE')}
        open={open}
        onCancel={handleClose}
        onOk={handleSubmit}
        centeredPosition
        okText={t('modal.SUBMIT_BTN')}
        cancelText={t('modal.CANCEL_BTN')}
        confirmLoading={
          addAdditionalEmailLoading ||
          addAdditionalPhoneLoading ||
          addPeopleOrganizationsLoading
        }
        destroyOnClose
      >
        <Form form={form}>
          {(allowActionsMobile.canAdd || allowActionsEmail.canAdd) && (
            <FormItem {...infoType}>
              <RadioGroup
                onChange={onChange}
                optionType="button"
                buttonStyle="solid"
                fullWidth
                options={infoTypeOptions}
                defaultValue={selectedType}
              />
            </FormItem>
          )}
          {renderFormItems(selectedType)}
        </Form>
      </Modal>
    </>
  );
};

export default AddInfo;
