import React, { FC } from 'react';
import { ENotificationType } from '@idaho-aeyc/ui-kit';
import { Modal } from 'components/Base/Modal';
import { useTranslation } from '@packages/utils';
import { useShowToastNotification } from 'components/Base/Notification';
import useForm from 'hooks/useForm';
import { useUpdateCustomQuestionMutation } from 'redux/apiSlice/workshops.slice';
import { TRtkErrorType } from 'data/types/generic.types';
import QuestionAddForm from '../QuestionAddForm';
import { TRANSLATION_KEY } from '../../QuestionTab.data';
import {
  TEditQuestionModalProps,
  TUpdateQuestionFormData,
} from '../../QuestionTab.types';

const EditQuestionModal: FC<TEditQuestionModalProps> = ({
  open,
  setModalOpen,
  question,
  workshopId,
}) => {
  const [updateQuestion, { isLoading }] = useUpdateCustomQuestionMutation();

  const { form } = useForm();
  const { showToastNotification } = useShowToastNotification();

  const { t } = useTranslation({
    keyPrefix: `${TRANSLATION_KEY}.modals.edit-modal`,
  });

  const handleEditBtnClick = async () => {
    try {
      const formValues =
        (await form.validateFields()) as TUpdateQuestionFormData;
      await updateQuestion({
        question: formValues.question,
        id: workshopId,
      }).unwrap();
      showToastNotification({
        message: t('success-message'),
        type: ENotificationType.SUCCESS,
      });
      setModalOpen(false);
    } catch (err) {
      if ((err as TRtkErrorType)?.data?.message)
        showToastNotification({
          message: (err as TRtkErrorType)?.data?.message,
          type: ENotificationType.ERROR,
        });
    }
  };
  const cancelBtnClick = () => {
    setModalOpen(false);
  };

  return (
    <Modal
      title={t('TITLE')}
      open={open}
      onCancel={cancelBtnClick}
      okText={t('submit-btn')}
      onOk={handleEditBtnClick}
      confirmLoading={isLoading}
      destroyOnClose
    >
      <QuestionAddForm form={form} questionInitialValue={question} />
    </Modal>
  );
};

export default EditQuestionModal;
