export enum EOrganizationsUrls {
  ORGANIZATIONS = 'organizations',
  IDENTITY = 'identity',
  CITIES = 'cities',
  UPDATE_ALLOWED = 'update-allowed',
  DELETE_ALLOWED = 'delete-allowed',
  NAME = 'name',
  ADDRESS = 'address',
  EMAIL = 'email',
  PHONE = 'phone',
  URL = 'url',
  NOTES = 'notes',
  AVAILABLE_CATEGORIES = 'available-categories',
  ADDITIONAL_EMAIL = 'additional-email',
  ADDITIONAL_PHONE = 'additional-phone',
  TITLE = 'title',
  ADDITIONAL_ADDRESS = 'additional-address',
}

export const getOrganizationByIdUrl = (id: string) =>
  `${EOrganizationsUrls.ORGANIZATIONS}/${id}`;
export const updateOrganizationNameUrl = `${EOrganizationsUrls.ORGANIZATIONS}/${EOrganizationsUrls.NAME}`;
export const updateOrganizationAddressUrl = `${EOrganizationsUrls.ORGANIZATIONS}/${EOrganizationsUrls.ADDRESS}`;
export const updateOrganizationEmailUrl = `${EOrganizationsUrls.ORGANIZATIONS}/${EOrganizationsUrls.EMAIL}`;
export const updateOrganizationPhoneUrl = `${EOrganizationsUrls.ORGANIZATIONS}/${EOrganizationsUrls.PHONE}`;
