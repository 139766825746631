import { Row, useTranslation } from '@packages/utils';
import EditableSelect from 'components/Base/EditableFields/EditableSelect';
import { InplaceEditLabels } from 'components/Base/EditableFields/components/InplaceEditLabel';
import { EIconNames } from 'components/Base/Icon';
import {
  useShowToastNotification,
  ENotificationType,
} from 'components/Base/Notification';
import { EWorkshopLanguage, TRtkErrorType } from 'data/types/generic.types';
import { useEditLanguageMutation } from 'redux/apiSlice/workshops.slice';
import { TLanguageSelectProps } from './LanguageSelect.types';
import useGetData from '../../../CreateSessionCard/CreateSessionCard.data';

const LanguageSelect = ({ id, workshopId, language }: TLanguageSelectProps) => {
  const { t } = useTranslation();
  const { languageOptions } = useGetData();
  const { showToastNotification } = useShowToastNotification();
  const [editLanguage, { isLoading, isError }] = useEditLanguageMutation();

  const onEdit = async (value: string) => {
    try {
      await editLanguage({
        id,
        workshopId,
        value: value as EWorkshopLanguage,
      }).unwrap();
      showToastNotification({
        message: t(
          'descriptions.admin-portal.workshops.details.sessions.view.actions.update.language.SUCCESS_MESSAGE',
        ),
        type: ENotificationType.SUCCESS,
      });
    } catch (e) {
      showToastNotification({
        message: (e as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };
  return (
    <Row align="middle">
      <InplaceEditLabels
        label={t(
          'descriptions.admin-portal.workshops.details.sessions.view.actions.update.language.LABEL',
        )}
        icon={EIconNames.LANGUAGE}
      />
      <EditableSelect
        onEdit={onEdit}
        options={languageOptions}
        title={{
          label: t(`enums.language.${language?.value.label}`) || '',
          value: language?.value.value || '',
        }}
        emptyText={t(
          'descriptions.admin-portal.workshops.details.sessions.view.actions.update.language.EMPTY_TEXT',
        )}
        isLoading={isLoading}
        isError={isError}
        readonly={language?.allowedOperations.readonly}
        canRemove={language?.allowedOperations.canRemove}
      />
    </Row>
  );
};

export default LanguageSelect;
