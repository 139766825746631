import { deleteUploadedResourceConverter } from 'data/convertors/storage.converters';
import { TDeleteUploadedResourceUI } from 'data/types/storage.types';
import { EStorageUrls } from 'data/urls/storage.url';
import { authSplitApi } from 'redux/helpers/slice.helpers';

export const storageApi = authSplitApi('storage', ['']).injectEndpoints({
  endpoints: build => ({
    deleteUploadedResource: build.mutation<void, TDeleteUploadedResourceUI>({
      query(body) {
        return {
          url: `${EStorageUrls.STORAGE}`,
          method: 'DELETE',
          body: deleteUploadedResourceConverter.toDb(body),
        };
      },
    }),
  }),
});

export const { useDeleteUploadedResourceMutation } = storageApi;
