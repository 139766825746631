import { Row } from 'components/Base/Grid';
import WorkshopExport from './WorkshopExport';
import WorkshopMarkAsOrdered from './WorkshopMarkAsOrdered';
import { TWorkshopActionsProps } from './WorkshopActions.types';

const WorkshopActions: React.FC<TWorkshopActionsProps> = ({
  setShowAlert,
  selectedRowKeys,
  data,
}) => {
  const selectedRows = selectedRowKeys.map(key => ({
    key,
    status: data.find(item => item.orderId === key)?.orderStatus,
  }));
  return (
    <Row gutter={[16, 16]}>
      <WorkshopMarkAsOrdered
        setShowAlert={setShowAlert}
        selectedRows={selectedRows}
      />
      <WorkshopExport setShowAlert={setShowAlert} selectedRows={selectedRows} />
    </Row>
  );
};

export default WorkshopActions;
