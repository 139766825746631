import { styled } from 'styled-components';
import { Image } from 'components/Base/Image';
import { Row } from 'components/Base/Grid';

export const SImage = styled(Image)`
  max-height: 270px;
`;

export const SErrorPageWrapper = styled(Row)`
  text-align: center;
`;
