import { Menu, MenuItem } from '@idaho-aeyc/ui-kit';
import { createGlobalStyle, styled } from 'styled-components';
import { MenuItemProps, TMenuProps } from './Menu.types';

export const SMenu = styled(Menu)<TMenuProps>`
  &&.ant-menu {
    background-color: ${({ theme }) => theme.menuColorBackgroundDefault};
    border-inline-end: none;
    width: 100%;
    .ant-menu-item {
      border-radius: ${({ theme }) => theme.menuBorderRadius}px;
      ${({ $collapsed }) => ($collapsed ? '' : 'padding: 0 16px!important;')}
      transition: none;
      margin: ${({ theme }) => theme.marginXxs}px 0;
      width: 100%;
      &:hover {
        background-color: ${({ theme }) => theme.menuColorBackgroundHover};
      }
      &-selected {
        background-color: ${({ theme }) => theme.menuColorBackgroundSelected};
      }
      .ant-menu-title-content {
        font-weight: ${({ theme }) => theme.menuFontWeightDefault};
        color: ${({ theme }) => theme.menuColorTextDefault};
        ${({ $collapsed }) => ($collapsed ? 'display: none;' : '')}
      }
      &-selected {
        .ant-menu-title-content {
          font-weight: ${({ theme }) => theme.menuFontWeightActive};
          color: ${({ theme }) => theme.menuColorTextActive};
          ${({ $collapsed }) => ($collapsed ? 'display: none;' : '')}
        }
      }
    }
    && .ant-menu-submenu {
      transition: none;
      .ant-menu {
        background-color: ${({ theme }) => theme.menuColorBackgroundDefault};
        .ant-menu-item {
          margin-left: 28px;
          width: initial;
        }
      }
      &-title {
        margin-inline: 0;
        width: 100%;
        transition: none;
        ${({ $collapsed }) => ($collapsed ? '' : 'padding: 0 16px!important;')}
        &:active {
          background-color: ${({ theme }) => theme.menuColorBackgroundDefault};
        }
        &:hover {
          background-color: ${({ theme }) => theme.menuColorBackgroundHover};
        }
        .ant-menu-title-content {
          font-weight: ${({ theme }) => theme.menuFontWeightDefault};
          color: ${({ theme }) => theme.menuColorTextActive};
          ${({ $collapsed }) => ($collapsed ? 'display: none;' : '')}
        }
      }
      &-arrow {
        color: ${({ theme }) => theme.menuColorIconDefault};
        padding-left: 12px;
      }
      &-selected {
        ${({ $collapsed, theme }) =>
          $collapsed
            ? `background-color: ${theme.menuColorBackgroundSelected};`
            : ''}
      }
    }
    .ant-menu-item-icon {
      width: ${({ theme }) => theme.menuSizingIcon}px;
      path {
        fill: ${({ theme }) => theme.menuColorIconDefault} !important;
      }
    }
  }
`;

export const MenuGlobalStyle = createGlobalStyle`
  .ant-menu-submenu-popup{
    left: 76px !important;
    &.ant-menu-submenu {
      .ant-menu {
        background-color: ${({ theme }) => theme.menuColorBackgroundDefault};
        .ant-menu-item {
          border-radius: ${({ theme }) => theme.menuBorderRadius}px;
            &:hover,
            &:active {
              background-color: ${({ theme }) =>
                theme.menuColorBackgroundHover}!important;
            }
            &-selected {
              background-color: ${({ theme }) =>
                theme.menuColorBackgroundSelected};
            }
        .ant-menu-title-content {
          color: ${({ theme }) => theme.menuColorTextActive};
          font-weight: ${({ theme }) => theme.menuFontWeightDefault};
          }
        }
      }
    }
  }`;

export const SMenuItem = styled(MenuItem)<MenuItemProps>``;
