import { FC } from 'react';
import { Form, FormItem } from 'components/Base/Form';
import { Input, PhoneInput } from 'components/Base/Input';
import { Select } from 'components/Base/Select';
import { TOption } from 'data/types/generic.types';
import { useGetIdahoCitiesQuery } from 'redux/apiSlice/workshopApp.slice';
import useGetFormData from './CreateWorkshopSubscribersForm.data';
import { TCreateWorkshopSubscribersFormProps } from './CreateWorkshopSubscribersForm.types';

const CreateWorkshopSubscriberForm: FC<TCreateWorkshopSubscribersFormProps> = ({
  form,
}) => {
  const { createWorkshopSubscribersFormFields } = useGetFormData();
  const { firstName, lastName, email, phone, cities, ageGroups, languages } =
    createWorkshopSubscribersFormFields;

  const { data: cityOptions, isLoading } = useGetIdahoCitiesQuery();

  return (
    <Form form={form}>
      <FormItem {...firstName}>
        <Input placeholder={firstName.placeholder} />
      </FormItem>
      <FormItem {...lastName}>
        <Input placeholder={lastName.placeholder} />
      </FormItem>
      <FormItem {...phone}>
        <PhoneInput placeholder={phone.placeholder} />
      </FormItem>
      <FormItem {...email}>
        <Input placeholder={email.placeholder} />
      </FormItem>
      <FormItem {...cities}>
        <Select
          disabled={isLoading}
          mode="multiple"
          options={cityOptions as TOption[]}
          placeholder={cities.placeholder}
          onChange={value => {
            if (value?.length > 3) {
              // user can select max 3 cities
              value.pop();
            }
          }}
        />
      </FormItem>
      <FormItem {...ageGroups}>
        <Select
          mode="multiple"
          options={ageGroups.options}
          placeholder={ageGroups.placeholder}
        />
      </FormItem>
      <FormItem {...languages}>
        <Select
          mode="multiple"
          options={languages.options}
          placeholder={languages.placeholder}
          placement="topLeft"
        />
      </FormItem>
    </Form>
  );
};

export default CreateWorkshopSubscriberForm;
