import { FC, useEffect } from 'react';
import { useTranslation } from '@packages/utils';
import useTable from 'components/Base/Table/hooks/useTable';
import { ETableStoragePaths } from 'components/Base/Table/Table.types';
import { Table } from 'components/Base/Table';
import { useGetRegistrantsWithChildcareQuery } from 'redux/apiSlice/registrants.slice';
import { TRegistrantsWithChildcareUI } from 'data/types/registrants.types';
import { TChildcareListProps } from './ChildcareList.types';
import useGetChildcareColumnData from './ChildcareList.data';
import AddChildcareRequest from './AddChildcareRequest';
import ChildcareEditColumn from './ChildcareEditColumn';

const ChildcareList: FC<TChildcareListProps> = ({ workshopId }) => {
  const { defaultColumns } = useGetChildcareColumnData();
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.workshops.details.child-care',
  });

  const {
    page,
    pageSize,
    sortColumn,
    setPageSize,
    handleTableChange,
    selectedFilters,
    hasFilters,
    columns,
    setColumns,
    onFiltersReset,
  } = useTable<TRegistrantsWithChildcareUI>({
    storagePath: `${ETableStoragePaths.WORKSHOP_CHILDCARE}-${workshopId}`,
    defaultColumns,
  });

  const { data, isFetching, isLoading, isSuccess } =
    useGetRegistrantsWithChildcareQuery(
      {
        page,
        pageSize,
        sortColumn,
        filters: selectedFilters,
        workshopId,
      },
      {
        skip: !workshopId,
      },
    );

  useEffect(() => {
    setColumns(
      columns.map(item => {
        if (item.key === 'childcareRequirements') {
          item.render = (_, record) => {
            return (
              <ChildcareEditColumn
                workshopId={workshopId}
                {...record}
                isReadOnly={data?.allowedOperations.readonly}
              />
            );
          };
        }
        return item;
      }),
    );
  }, [isSuccess]);

  if (!isSuccess) {
    return null;
  }

  return (
    <Table<TRegistrantsWithChildcareUI>
      onFiltersReset={onFiltersReset}
      hasFilters={hasFilters}
      currentPage={page}
      columns={columns}
      dataSource={data?.content}
      loading={isFetching && !isLoading}
      pageSize={pageSize}
      tableTitle={t('TITLE')}
      setPageSize={setPageSize}
      totalDataCount={data?.totalElements}
      onChange={handleTableChange}
      rowKey={record => record.registrantId}
      storagePath={ETableStoragePaths.WORKSHOP_CHILDCARE}
      pageHeaderContent={150}
      {...(!data.allowedOperations.readonly && {
        additionalActions: [<AddChildcareRequest workshopId={workshopId} />],
      })}
    />
  );
};

export default ChildcareList;
