import { Row } from '@packages/utils';
import { styled } from 'styled-components';

export const SMainContentWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colorBlackMainBorder};
  border-radius: ${({ theme }) => theme.borderRadiusL}px;
  background-color: ${({ theme }) => theme.colorWhitePrimary};
  max-height: calc(100vh - 155px);
  &.card-content {
    max-height: calc(100vh - 120px);
    margin: ${({ theme }) => theme.marginS}px;
  }
  overflow: auto;
`;

export const SSectionWrapper = styled.div`
  &.fixed {
    .section-header {
      position: sticky;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 9;
    }
  }
`;

export const SContentHeaderWrapper = styled(Row)`
  padding: ${({ theme }) => theme.paddingS}px;
  background-color: ${({ theme }) => theme.colorWhitePrimary};
`;

export const SContentWrapper = styled.div`
  padding: ${({ theme }) =>
    `${theme.paddingXs}px ${theme.paddingS}px ${theme.paddingLg}px`};
`;

export const SCardContentWrapper = styled.div<{ $contentPadding: string }>`
  padding: ${({ $contentPadding }) => $contentPadding};
`;
