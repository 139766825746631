import { useTranslation } from '@packages/utils';
import { TRtkErrorType } from 'data/types/generic.types';
import { Modal } from 'components/Base/Modal';
import {
  ENotificationType,
  useShowToastNotification,
} from 'components/Base/Notification';
import ConfirmModalBody from 'components/Shared/ConfirmModalBody';
import { useDeleteNoteMutation } from 'redux/apiSlice/elcs.slice';
import { TDeleteDetailsModalProps } from './DeleteDetailsModal.types';

const DeleteDetailsModal = ({
  open,
  setOpen,
  elcId,
  elcNoteId,
}: TDeleteDetailsModalProps) => {
  const { t } = useTranslation({
    keyPrefix: `descriptions.admin-portal.elcs.detail.details-tab.details-section.action.delete.modal`,
  });
  const { showToastNotification } = useShowToastNotification();
  const [deleteNote, { isLoading }] = useDeleteNoteMutation();

  const handleSubmit = async () => {
    try {
      await deleteNote({
        elcId,
        elcNoteId,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
      setOpen(false);
    } catch (err) {
      showToastNotification({
        message: (err as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  return (
    <Modal
      title={t('TITLE')}
      open={open}
      onCancel={() => setOpen(false)}
      onOk={handleSubmit}
      okText={t('SUBMIT_BTN')}
      confirmLoading={isLoading}
    >
      <ConfirmModalBody description={t('DESCRIPTION_CONFIRM')} />
    </Modal>
  );
};

export default DeleteDetailsModal;
