import { FC, useState } from 'react';
import { Form, FormItem, useForm, useTranslation } from '@packages/utils';
import { useAppDispatch } from 'redux/store';
import { dateShortenFormat } from 'helpers/utils';
import { registrantsApi } from 'redux/apiSlice/registrants.slice';
import { useMarkAsOrderedParentActionMutation } from 'redux/apiSlice/kitManagement.slice';
import { Modal } from 'components/Base/Modal';
import { Col } from 'components/Base/Grid';
import {
  useShowToastNotification,
  ENotificationType,
} from 'components/Base/Notification';
import { DatePicker } from 'components/Base/DatePicker';
import { TRtkErrorType } from 'data/types/generic.types';
import ActionGroupItemLabel from 'components/Shared/ActionsButtonDetailsPage/ActionGroupItemLabel';
import {
  TParentActionProps,
  TParentMarkAsOrderedForm,
} from '../MarkAsOrdered.types';
import useGetFormData from './MarkAsOrderedAction.data';

const MarkAsOrderedAction: FC<TParentActionProps> = ({
  setShowAlert,
  selectedRows,
}) => {
  const { form } = useForm<TParentMarkAsOrderedForm>();
  const dispatch = useAppDispatch();
  const parentOrderIds = (selectedRows?.map(row => row.key) || []) as string[];
  const [markAsOrdered, { isLoading }] = useMarkAsOrderedParentActionMutation();
  const { markAsOrderedFormFields } = useGetFormData();
  const { orderDate } = markAsOrderedFormFields;
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.parents.list.actions.mark-as-ordered',
  });
  const { showToastNotification } = useShowToastNotification();
  const [open, setOpen] = useState(false);

  const workshopIdsSet = new Set<string>();
  selectedRows?.forEach(row => {
    if (row.workshopId) workshopIdsSet.add(row.workshopId);
  });
  const invalidTags = Array.from(workshopIdsSet).map(id => ({
    type: 'workshop-registrants',
    id,
  }));

  const handleSubmit = async () => {
    const values = await form.validateFields();
    try {
      await markAsOrdered({
        parentOrderIds,
        orderDate: values.orderDate,
      }).unwrap();
      dispatch(registrantsApi.util.invalidateTags(invalidTags));
      showToastNotification({
        message: t('modal.SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (err) {
      showToastNotification({
        message: (err as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
    setOpen(false);
  };

  const handleActionButtonClick = () => {
    if (selectedRows.length) {
      setOpen(true);
    } else {
      setShowAlert(true);
    }
  };

  return (
    <Col>
      <ActionGroupItemLabel
        onClick={handleActionButtonClick}
        label={t('MARK_AS_ORDERED_BTN')}
      />
      <Modal
        open={open}
        title={t('modal.TITLE')}
        okText={t('modal.SUBMIT_BTN')}
        cancelText={t('modal.CANCEL_BTN')}
        onCancel={() => setOpen(false)}
        onOk={handleSubmit}
        width={408}
        confirmLoading={isLoading}
        afterClose={() => form.resetFields()}
      >
        <Form form={form}>
          <FormItem {...orderDate}>
            <DatePicker
              className="w-100"
              placeholder={orderDate.placeholder}
              format={dateShortenFormat}
            />
          </FormItem>
        </Form>
      </Modal>
    </Col>
  );
};

export default MarkAsOrderedAction;
