import { Col, Row, Tag, useTranslation } from '@packages/utils';
import EditableSelect from 'components/Base/EditableFields/EditableSelect';
import { InplaceEditLabels } from 'components/Base/EditableFields/components/InplaceEditLabel';
import { EIconNames } from 'components/Base/Icon';
import { avatarColorMapper } from 'pages/private/ELCs/ELCs.data';
import ElcItemPopover from 'pages/private/ELCs/components/ElcPopover';
import { TAEYCLeadProps } from './AEYCLead.types';
import { SCard } from '../TeamSection.styles';

const AEYCLead = ({ data }: TAEYCLeadProps) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.details-tab.team-section.aeyc-lead',
  });

  const onEdit = () => {
    // do nothing
  };

  return (
    <Col md={8}>
      <SCard className="h-100">
        <Tag color="blue">{t('LABEL')}</Tag>
        <Row align="middle">
          <Col>
            <InplaceEditLabels
              label={t('INPLACE_EDIT_LABEL')}
              icon={EIconNames.PROFILE}
            />
          </Col>
          <Col flex="auto">
            <EditableSelect
              title={{
                label: data.fullName,
                value: data.userId,
              }}
              fitContent={false}
              options={[]}
              onEdit={onEdit}
              emptyText={t('EMPTY_TEXT')}
              placeholder={t('PLACEHOLDER')}
              readonly
              canRemove={false}
              avatarColor={avatarColorMapper.lead}
              {...(data && {
                tooltip: (
                  <ElcItemPopover
                    fullName={data.fullName}
                    email={data.email}
                    phone={data.phone}
                  />
                ),
              })}
            />
          </Col>
        </Row>
      </SCard>
    </Col>
  );
};

export default AEYCLead;
