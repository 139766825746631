import { useState } from 'react';
import {
  ENotificationType,
  Modal,
  useShowToastNotification,
  useTranslation,
  useForm,
  Paragraph,
  TRtkErrorType,
} from '@packages/utils';
import get from 'lodash/get';
import { TValidateFieldsError } from 'data/types/generic.types';
import { useDeleteUploadedResourceMutation } from 'redux/apiSlice/storage.slice';
import { useAddResourcesMutation } from 'redux/apiSlice/elcs.slice';
import {
  TAddResourcesFormProps,
  TAddResourcesModalProps,
  TSelectedFiles,
} from './AddResourcesModal.types';
import AddResourcesForm from './AddResourcesForm';

const AddResourcesModal = ({
  open,
  setOpen,
  elcId,
}: TAddResourcesModalProps) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.resources-tab.add-resource.modal',
  });
  const [selectedFiles, setSelectedFiles] = useState<TSelectedFiles[]>([]);
  const [fileIsUploading, setFileIsUploading] = useState<boolean>(false);
  const { showToastNotification } = useShowToastNotification();
  const { form } = useForm<TAddResourcesFormProps>();
  const [addResources, { isLoading }] = useAddResourcesMutation();
  const [deleteUploadedResource] = useDeleteUploadedResourceMutation();

  const handleRemove = async (files: TSelectedFiles[]) => {
    try {
      await deleteUploadedResource({ files }).unwrap();
    } catch (err) {
      showToastNotification({
        message: (err as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  const handleCancel = () => {
    setOpen(false);
    setSelectedFiles([]);
    form.resetFields();
  };

  const handleClose = () => {
    if (selectedFiles.length) {
      handleRemove(selectedFiles);
    }
    handleCancel();
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const files =
        values.resourceType === 'LINK'
          ? [{ name: values.name, url: values.url }]
          : selectedFiles;
      await addResources({
        elcId,
        resourceType:
          values.resourceType === 'LINK'
            ? values.resourceType
            : values.documentType,
        files,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
      handleCancel();
    } catch (e) {
      if (!(e as TValidateFieldsError)?.errorFields) {
        const message = get(e, 'data.message');
        showToastNotification({
          message,
          type: ENotificationType.ERROR,
        });
      }
    }
  };

  return (
    <Modal
      title={t('TITLE')}
      open={open}
      centeredPosition
      onCancel={handleClose}
      onOk={handleSubmit}
      okText={t('SUBMIT_BTN')}
      cancelText={t('CANCEL_BTN')}
      okButtonProps={{ loading: isLoading, disabled: fileIsUploading }}
      cancelButtonProps={{ disabled: fileIsUploading }}
      destroyOnClose
    >
      <Paragraph className="mb-2">{t('SUBTITLE')}</Paragraph>
      <AddResourcesForm
        form={form}
        elcId={elcId}
        setSelectedFiles={setSelectedFiles}
        selectedFiles={selectedFiles}
        setFileIsUploading={setFileIsUploading}
        handleRemove={handleRemove}
      />
    </Modal>
  );
};

export default AddResourcesModal;
