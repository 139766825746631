import {
  EWorkshopCategory,
  EWorkshopPartType,
  EWorkshopSeriesType,
  EWorkshopStandaloneType,
} from './workshops.types';
import {
  EAgeGroup,
  EWorkshopLanguage,
  TAddressShortDataDB,
  TAddressShortDataUI,
  TAddressTypesDB,
  TAddressTypesUI,
} from './generic.types';
import { TTagDB, TTagUI } from './tags.types';

export enum EOrderStatus {
  ORDERED = 'ORDERED',
  NOT_ORDERED = 'NOT_ORDERED',
}
export enum EParentOrderStatus {
  ORDERED = 'ORDERED',
  NOT_ORDERED = 'NOT_ORDERED',
  NO_ADDRESS = 'NO_ADDRESS',
}
export enum EKitManagementWorkshopSortParams {
  FIRST_SESSION_DATE = 'FIRST_SESSION_DATE',
  CREATION_DATE = 'CREATION_DATE',
  ORDER_DATE = 'ORDER_DATE',
}

export enum EKitManagementWorkshopFilterParams {
  HOST = 'hostIds',
  LOCATION = 'locationIds',
  TYPE = 'types',
  PART_TYPES = 'partTypes',
  STATUS = 'statuses',
}

export enum EKitManagementParentColumnKey {
  CREATION_DATE = 'creationDate',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  STATUS = 'statuses',
  HAS_SHIPMENT_ADDRESS = 'hasShipmentAddress',
  HOST = 'hostIds',
  LOCATION = 'locationIds',
  TAGS = 'tagIds',
  ORDER_DATE = 'orderDate',
}

export type TKitManagementWorkshopUI = {
  workshopId: string;
  hostId: string;
  orderId: string;
  orderDate: string | null;
  hostName: string;
  location: string;
  workshopPartType: EWorkshopPartType;
  workshopCategory: EWorkshopCategory;
  workshopType: EWorkshopStandaloneType | EWorkshopSeriesType;
  partDisplayText: string;
  date: string;
  shippingAddress: string;
  orderStatus: EOrderStatus;
};

export type TKitManagementWorkshopDB = {
  workshopId: string;
  hostId: string;
  orderId: string;
  orderDate: string | null;
  hostName: string;
  location: string;
  workshopPartType: EWorkshopPartType;
  workshopType: EWorkshopStandaloneType | EWorkshopSeriesType;
  workshopCategory: EWorkshopCategory;
  firstSessionDate: string;
  shippingAddress: string;
  orderStatus: EOrderStatus;
};

export type TKitManagementParentUI = {
  parentOrderId: string;
  firstName: string;
  lastName: string;
  orderDate: string | null;
  workshopPartType: EWorkshopPartType;
  workshopType: EWorkshopStandaloneType | EWorkshopSeriesType;
  workshopTypeCategory: EWorkshopCategory;
  workshopClassId: string;
  workshopId: string;
  workshopSessionId: string;
  registrantId: string;
  childrenCount: number;
  language: EWorkshopLanguage;
  partDisplayText: string;
  ageGroup: EAgeGroup;
  quantity: number;
  address: TAddressShortDataUI;
  email?: string;
  phone?: string;
  status: EParentOrderStatus;
  orientationKitQuantity: number;
  hostName: string;
  location: string;
  tags: TTagUI[];
};

export type TKitManagementParentDB = {
  email?: string;
  phone?: string;
  parentOrderId: string;
  firstName: string;
  lastName: string;
  orderDate: string | null;
  language: EWorkshopLanguage;
  workshopPartType: EWorkshopPartType;
  workshopType: EWorkshopStandaloneType | EWorkshopSeriesType;
  workshopTypeCategory: EWorkshopCategory;
  workshopClassId: string;
  workshopId: string;
  workshopSessionId: string;
  registrantId: string;
  ageGroup: EAgeGroup;
  quantity: number;
  address: TAddressShortDataDB;
  childrenCount: number;
  status: EParentOrderStatus;
  orientationKitQuantity: number;
  hostName: string;
  location: string;
  tags: TTagDB[];
};

export type TAddKitManagementParentShippingDetailsUI = {
  parentOrderId: string;
  address: TAddressTypesUI;
};
export type TAddKitManagementParentShippingDetailsDB = {
  parentOrderId: string;
  address: TAddressTypesDB;
};

export type TKitManagementMarkAsOrderedUI = {
  partOrderIds: string[];
  orderDate: string;
};
export type TKitManagementMarkAsOrderedDB = {
  partOrderIds: string[];
  orderDate: string;
};

export type TParentMarkAsOrderedUI = {
  parentOrderIds: string[];
  orderDate: string;
};

export type TParentMarkAsOrderedDB = {
  parentOrderIds: string[];
  orderDate: string;
};
