import {
  exportCsvConverter,
  pagableDataConverter,
} from 'data/convertors/general.converters';
import {
  TExportCsvDB,
  TExportCsvUI,
  TExportFilters,
  TPageableDataWithContentDB,
  TPageableDataWithContentUI,
  TTableFilters,
} from 'data/types/generic.types';
import { authSplitApi } from 'redux/helpers/slice.helpers';
import { EFamilyUrls, getFamilyUrl } from 'data/urls/family.url';
import {
  EFamilyFilterParams,
  TEditFamilyUI,
  TFamilyDataDB,
  TFamilyDataUi,
} from 'data/types/family.types';
import {
  getFamilyConvertor,
  familyFilterConverter,
  familyUpdateConverter,
} from 'data/convertors/family.convertor';
import { downloadFile } from 'redux/services/fileServices';
import { registrantsApi } from './registrants.slice';

export const familyApi = authSplitApi('family', ['family']).injectEndpoints({
  endpoints: build => ({
    getFamilyList: build.query<
      TPageableDataWithContentUI<TFamilyDataUi[]>,
      TTableFilters<EFamilyFilterParams>
    >({
      query({
        page,
        pageSize,
        filters,
        sortColumn: { sortDirection, sortField },
      }) {
        return {
          url: getFamilyUrl,
          method: 'GET',
          params: {
            page,
            size: pageSize,
            ...(sortField && sortDirection && { sortField, sortDirection }),
            ...(filters && {
              ...familyFilterConverter(filters),
            }),
          },
        };
      },
      providesTags: ['family'],
      transformResponse: (
        data: TPageableDataWithContentDB<TFamilyDataDB[]>,
      ) => {
        return {
          ...pagableDataConverter.fromDb(data),
          content: (data.content || []).map(family =>
            getFamilyConvertor.fromDb(family),
          ),
        };
      },
    }),
    exportFamilyCSV: build.query<
      TExportCsvUI,
      TExportFilters<EFamilyFilterParams>
    >({
      query({ filters }) {
        return {
          url: `${getFamilyUrl}/${EFamilyUrls.EXPORT}`,
          method: 'GET',
          params: {
            ...(filters && {
              ...familyFilterConverter(filters),
            }),
          },
        };
      },
      transformResponse: (data: TExportCsvDB) => {
        const convertedData = exportCsvConverter('text/csv').fromDb(data);
        downloadFile(convertedData.content, convertedData.fileName);
        return convertedData;
      },
    }),
    editFamily: build.mutation<void, TEditFamilyUI>({
      query(body) {
        return {
          url: getFamilyUrl,
          method: 'PATCH',
          body: familyUpdateConverter.toDb(body),
        };
      },
      invalidatesTags: ['family'],
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(
              registrantsApi.util.invalidateTags([
                'workshop-registrants',
                'wait-list',
              ]),
            );
          })
          .catch(() => {
            // do nothing
          });
      },
    }),
  }),
});

export const {
  useGetFamilyListQuery,
  useEditFamilyMutation,
  useLazyExportFamilyCSVQuery,
} = familyApi;
