import { styled } from 'styled-components';
import { Row } from 'components/Base/Grid';
import { Divider } from 'components/Base/Divider';

export const SStandaloneColumnWrapper = styled(Row)`
  flex-direction: column;
`;

export const SDividerWithText = styled(Divider)`
  && {
    border-block-start-color: ${({ theme }) =>
      theme.colorPrimaryBorderLight} !important;
    margin: 0;
    &::after,
    &::before {
      border-block-start-width: ${5}px;
    }
  }
`;
