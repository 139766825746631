import {
  Col,
  ENotificationType,
  Row,
  TRtkErrorType,
  Tag,
  useShowToastNotification,
  useTranslation,
} from '@packages/utils';
import EditableSelect from 'components/Base/EditableFields/EditableSelect';
import { InplaceEditLabels } from 'components/Base/EditableFields/components/InplaceEditLabel';
import { EIconNames } from 'components/Base/Icon';
import { useGetOrganizationsIdentityQuery } from 'redux/apiSlice/organizations.slice';
import { avatarColorMapper } from 'pages/private/ELCs/ELCs.data';
import ElcOrgPopover from 'pages/private/ELCs/components/ElcOrgPopover';
import { useEditLeadAgencyMutation } from 'redux/apiSlice/elcs.slice';
import { TLeadAgencyProps } from './LeadAgency.types';
import { SCard } from '../TeamSection.styles';

const LeadAgency = ({ data, elcId, isEditable }: TLeadAgencyProps) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.details-tab.team-section.lead-agency',
  });
  const { showToastNotification } = useShowToastNotification();

  const { data: organizations } = useGetOrganizationsIdentityQuery();

  const [editLeadAgency, { isLoading, isError }] = useEditLeadAgencyMutation();

  const onEdit = async (value: string) => {
    try {
      await editLeadAgency({
        elcId,
        organizationId: value,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (e) {
      showToastNotification({
        message: (e as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  return (
    <Col md={8}>
      <SCard className="h-100">
        <Tag color="blue">{t('LABEL')}</Tag>
        <Row align="middle" wrap={false}>
          <Col>
            <InplaceEditLabels
              label={t('INPLACE_EDIT_LABEL')}
              icon={EIconNames.PEOPLE_GROUP_LABEL}
            />
          </Col>
          <Col flex="auto">
            <EditableSelect
              {...(data && {
                title: {
                  label: data.name,
                  value: data.id,
                },
              })}
              fitContent={false}
              options={organizations}
              onEdit={onEdit}
              emptyText={t('EMPTY_TEXT')}
              placeholder={t('PLACEHOLDER')}
              readonly={!isEditable}
              canRemove={false}
              isError={isError}
              ellipsis
              isLoading={isLoading}
              avatarColor={avatarColorMapper.lead}
              {...(data && {
                tooltip: (
                  <ElcOrgPopover name={data.name} address={data.address} />
                ),
              })}
            />
          </Col>
        </Row>
      </SCard>
    </Col>
  );
};

export default LeadAgency;
