import { TCreateConverter, TFetchConverter } from 'data/types/converter.types';
import {
  EWorkshopSubscribersColumnKey,
  TWorkshopSubscriberCreateDB,
  TWorkshopSubscriberCreateUI,
  TWorkshopSubscriberDataDB,
  TWorkshopSubscriberDataUI,
  TWorkshopSubscriberUnsubscribeDB,
  TWorkshopSubscriberUnsubscribeUI,
} from 'data/types/subscribers.types.';
import { EWorkshopLanguage, TTableFilters } from 'data/types/generic.types';
import { dateConverter, dateShortenFormat } from 'helpers/utils';
import { phoneNumberConverter } from './general.converters';

export const workshopSubscriberConverter: TFetchConverter<
  TWorkshopSubscriberDataUI,
  TWorkshopSubscriberDataDB
> = {
  fromDb: data => ({
    createdDate:
      data.createdDate && dateConverter(data.createdDate, dateShortenFormat),
    spanishAgeGroups: (data.spanishAgeGroups || []).map(spanishAgeGroup => ({
      ageGroup: spanishAgeGroup,
      language: EWorkshopLanguage.SPANISH,
      count: 1,
    })),
    englishAgeGroups: (data.englishAgeGroups || []).map(englishAgeGroup => ({
      ageGroup: englishAgeGroup,
      language: EWorkshopLanguage.ENGLISH,
      count: 1,
    })),
    cities: data.cities,
    email: data.email,
    firstName: data.firstName,
    lastName: data.lastName,
    phone: phoneNumberConverter.fromDb(data.phone) || '',
    workshopSubscriberId: data.workshopSubscriberId,
  }),
};

export const workshopSubscriberFilterConverter = (
  filters: TTableFilters<EWorkshopSubscribersColumnKey>['filters'],
) => {
  const filtersToDB = {
    ...(filters?.email && {
      [EWorkshopSubscribersColumnKey.EMAIL]: filters.email.toString().trim(),
    }),
    ...(filters?.cities && {
      [EWorkshopSubscribersColumnKey.CITY]: filters.cities,
    }),
    ...(filters?.ageGroups && {
      [EWorkshopSubscribersColumnKey.AGE_GROUP]: filters.ageGroups,
    }),
    ...(filters?.firstName && {
      [EWorkshopSubscribersColumnKey.FIRST_NAME]: filters.firstName
        .toString()
        .trim(),
    }),
    ...(filters?.lastName && {
      [EWorkshopSubscribersColumnKey.LAST_NAME]: filters.lastName
        .toString()
        .trim(),
    }),
  };
  return filtersToDB;
};

export const workshopSubscriberCreateConverter: TCreateConverter<
  TWorkshopSubscriberCreateUI,
  TWorkshopSubscriberCreateDB
> = {
  toDb: data => ({
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    phone: phoneNumberConverter.toDb(data.phone) || '',
    cities: data.cities,
    ageGroups: (data.ageGroups || []).map(ageGroup => ageGroup),
    languages: (data.languages || []).map(language => language),
  }),
};

export const workshopSubscriberUnsubscribeConverter: TCreateConverter<
  TWorkshopSubscriberUnsubscribeUI,
  TWorkshopSubscriberUnsubscribeDB
> = {
  toDb: data => ({
    workshopSubscriberId: data.workshopSubscriberId,
  }),
};
