import { FC, useEffect, useState } from 'react';
import { useEditCildcareProvidedMutation } from 'redux/apiSlice/workshops.slice';
import {
  ENotificationType,
  useShowToastNotification,
} from 'components/Base/Notification';
import { useTranslation } from '@packages/utils';
import { TRtkErrorType } from 'data/types/generic.types';
import { TCheckboxChangeEvent } from 'components/Base/Checkbox/Checbox.types';
import Checkbox from 'components/Base/Checkbox';
import { Modal } from 'components/Base/Modal';
import ConfirmModalBody from 'components/Shared/ConfirmModalBody';
import { TChildcareProps } from './Childcare.types';
import { providingKey } from '../Providing.data';

const Childcare: FC<TChildcareProps> = ({
  childcareProvided,
  id,
  needConfirmation,
}) => {
  const [checked, setChecked] = useState<boolean>(!!childcareProvided?.value);

  useEffect(() => {
    setChecked(!!childcareProvided?.value);
  }, [childcareProvided?.value]);

  const [openConfirmMidal, setOpenConfirmModal] = useState<boolean>(false);
  const [editChildcareProvided, { isLoading: isChildcareLoading }] =
    useEditCildcareProvidedMutation();
  const { showToastNotification } = useShowToastNotification();
  const { t } = useTranslation({
    keyPrefix: `${providingKey}.child-care`,
  });

  const updateChildcare = async (value: boolean) => {
    try {
      await editChildcareProvided({
        id,
        value,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (error) {
      showToastNotification({
        message: (error as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  const onChange = async (e: TCheckboxChangeEvent) => {
    if (needConfirmation) {
      setOpenConfirmModal(true);
      return;
    }
    const value = e.target.checked;
    setChecked(value);
    await updateChildcare(value);
  };

  const handleClose = () => {
    setOpenConfirmModal(false);
  };
  const handleSubmit = async () => {
    await updateChildcare(!checked);
    handleClose();
  };
  return (
    <>
      <Checkbox
        onChange={onChange}
        checked={checked}
        disabled={
          isChildcareLoading || childcareProvided?.allowedOperations.readonly
        }
        $readonly={childcareProvided?.allowedOperations.readonly}
      >
        {t('LABEL')}
      </Checkbox>
      {openConfirmMidal && (
        <Modal
          open={openConfirmMidal}
          title={t('confirm-modal.TITLE')}
          cancelText={t('confirm-modal.CANCEL_BTN')}
          okText={t('confirm-modal.UPDATE_BTN')}
          onCancel={handleClose}
          onOk={handleSubmit}
          confirmLoading={isChildcareLoading}
        >
          <ConfirmModalBody description={t('confirm-modal.DESCRIPTION')} />
        </Modal>
      )}
    </>
  );
};

export default Childcare;
