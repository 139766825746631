import { FC, useEffect, useState } from 'react';
import useTable from 'components/Base/Table/hooks/useTable';
import { TElcMeetingActionItemsUI } from 'data/types/elcs.types';
import { ETableStoragePaths } from 'components/Base/Table/Table.types';
import { Table } from 'components/Base/Table';
import CardContent from 'components/Base/Layout/Content/CardContent';
import { useGetElcMeetingActionItemQuery } from 'redux/apiSlice/elcs.slice';
import { TabBodyWrapper } from 'components/Base/Tabs';
import { TActionItemsTabProps, TAddNewRow } from './ActionItemsTab.types';
import useGetData from './ActionItemsTab.data';
import { SActionItemTabWrapper } from './ActionItem.styles';
import AddActionItem from './AddActionItem';

const ActionItemsTab: FC<TActionItemsTabProps> = ({ elcId, meetingId }) => {
  const [newRowData, setNewRowData] = useState<TAddNewRow>();

  const { defaultColumns } = useGetData(elcId, setNewRowData);

  const { columns, handleTableChange } = useTable<TElcMeetingActionItemsUI>({
    storagePath: ETableStoragePaths.MEETING_ACTION_ITEMS,
    defaultColumns,
  });

  const { data, isLoading, isFetching, isSuccess } =
    useGetElcMeetingActionItemQuery({
      meetingId,
      page: 0,
      pageSize: 2000,
    });

  const newRow: TElcMeetingActionItemsUI = {
    meetingId,
    id: '',
    title: '',
    dueDate: '',
    ownerId: '',
    status: false,
    isNew: true,
  };

  const [dataSource, setDataSource] = useState<TElcMeetingActionItemsUI[]>([]);

  useEffect(() => {
    if (isSuccess && !isFetching && !isLoading) {
      if (data?.content.length) {
        setDataSource(data.content);
      } else {
        setDataSource([newRow]);
      }
    }
  }, [data?.content]);

  const hanldeAddActionItem = () => {
    setDataSource([...dataSource, newRow]);
  };
  const removeNewRow = () => {
    setDataSource(dataSource.filter(item => !item.isNew));
    setNewRowData(undefined);
  };

  return (
    <CardContent tabContent>
      <TabBodyWrapper isLoading={isLoading}>
        <SActionItemTabWrapper>
          <Table<TElcMeetingActionItemsUI>
            columns={columns}
            hideActions
            dataSource={dataSource}
            rowClassName={record => record.isNew && 'new-row'}
            loading={isFetching}
            onChange={handleTableChange}
            rowKey={record => record.parentOrderId}
            storagePath={ETableStoragePaths.MEETING_ACTION_ITEMS}
            locale={{
              emptyText: () => <></>,
            }}
            footer={() => (
              <AddActionItem
                isFinishedGetActionItems={
                  isSuccess && !isFetching && !isLoading
                }
                tableIsEmpty={dataSource.every(item => item.isNew)}
                tableHasNewRow={dataSource.some(item => item.isNew)}
                onAddNewRow={hanldeAddActionItem}
                onRemoveNewRow={removeNewRow}
                newRowData={newRowData}
                meetingId={meetingId}
                elcId={elcId}
                dataSource={dataSource}
              />
            )}
          />
        </SActionItemTabWrapper>
      </TabBodyWrapper>
    </CardContent>
  );
};

export default ActionItemsTab;
