import useCopyToClipboard from 'hooks/useCopyClipboard';
import { Col, Row, Text, Title } from '@packages/utils';
import { useTheme } from 'styled-components';
import { EIconNames, Icon } from 'components/Base/Icon';
import { TElcPopoverProps } from './ElcPopover.types';

const ElcPopover = ({ fullName, email, phone }: TElcPopoverProps) => {
  const theme = useTheme();
  const [isCopied, copyToClipboard] = useCopyToClipboard();

  const handleCopyClick = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    copyToClipboard(email);
  };

  return (
    <>
      <Title level={4} className="mb-3">
        {fullName}
      </Title>
      <Row align="middle" className="mb-2">
        <Icon
          icon={EIconNames.EMAIL_OUTLINED}
          className="pointer "
          color={theme.colorBlackIconColor}
          alt="edit attendance"
        />
        <Text className="mx-2" color={theme.colorBlackDescription}>
          {email}
        </Text>
        <Col onClick={handleCopyClick}>
          <Icon
            icon={isCopied ? EIconNames.CHECK : EIconNames.COPY_OUTLINED}
            alt="copy"
            size={16}
            className="pointer"
            color={theme.colorBlackIconColor}
            tooltip={{ title: 'Copy' }}
          />
        </Col>
      </Row>
      {phone && (
        <Row align="middle">
          <Icon
            icon={EIconNames.PHONE_OUTLINED}
            className="pointer "
            color={theme.colorBlackIconColor}
            alt="edit attendance"
          />
          <Text className="mx-2" color={theme.colorBlackDescription}>
            {phone}
          </Text>
        </Row>
      )}
    </>
  );
};

export default ElcPopover;
