import { Key } from 'react';
import {
  exportCsvConverter,
  locationHostFilterDataConverter,
  pagableDataConverter,
} from 'data/convertors/general.converters';
import {
  kitManagementMarkAsOrderedConverter,
  kitManagementParentConverter,
  kitManagementParentFilterConverter,
  kitManagementParentShippingDetailsConverter,
  kitManagementWorkshopConverter,
  kitManagementWorkshopFilterConverter,
  parentMarkAsOrderedConverter,
} from 'data/convertors/kitManagement.converter';
import {
  TExportCsvDB,
  TExportCsvUI,
  TLocationHostFilterDataDB,
  TPageableDataWithContentDB,
  TPageableDataWithContentUI,
  TTableFilterDataUI,
  TTableFilters,
} from 'data/types/generic.types';
import {
  EKitManagementParentColumnKey,
  EKitManagementWorkshopFilterParams,
  TAddKitManagementParentShippingDetailsUI,
  TKitManagementMarkAsOrderedUI,
  TKitManagementParentDB,
  TKitManagementParentUI,
  TKitManagementWorkshopDB,
  TKitManagementWorkshopUI,
  TParentMarkAsOrderedUI,
} from 'data/types/kitManagement.types';
import { EKitManagementUrls } from 'data/urls/kitManagement.url';
import { authSplitApi } from 'redux/helpers/slice.helpers';
import { downloadFile } from 'redux/services/fileServices';

const baseUrl = `${EKitManagementUrls.BASE_URL}`;

export const kitManagementApi = authSplitApi('kit-management', [
  'kit-management-workshops',
  'kit-management-parents',
  'kit-management-workshops-hosts',
  'kit-management-workshops-locations',
  'kit-management-parent-locations',
  'kit-management-parent-hosts',
]).injectEndpoints({
  endpoints: build => ({
    getKitManagementWorkshops: build.query<
      TPageableDataWithContentUI<TKitManagementWorkshopUI[]>,
      TTableFilters<EKitManagementWorkshopFilterParams>
    >({
      query({
        page,
        pageSize,
        sortColumn: { sortDirection, sortField },
        filters,
      }) {
        return {
          url: `${baseUrl}/${EKitManagementUrls.WORKSHOP}`,
          method: 'GET',
          params: {
            page,
            size: pageSize,
            ...(sortField && sortDirection && { sortField, sortDirection }),
            ...(filters && {
              ...kitManagementWorkshopFilterConverter(filters),
            }),
          },
        };
      },
      providesTags: ['kit-management-workshops'],
      transformResponse: (
        data: TPageableDataWithContentDB<TKitManagementWorkshopDB[]>,
      ) => {
        return {
          ...pagableDataConverter.fromDb(data),
          content: (data.content || []).map(workshop =>
            kitManagementWorkshopConverter.fromDb(workshop),
          ),
        };
      },
    }),
    getKitManagementParents: build.query<
      TPageableDataWithContentUI<TKitManagementParentUI[]>,
      TTableFilters<EKitManagementParentColumnKey>
    >({
      query({
        page,
        pageSize,
        sortColumn: { sortDirection, sortField },
        filters,
      }) {
        return {
          url: `${baseUrl}/${EKitManagementUrls.PARENT}`,
          method: 'GET',
          params: {
            page,
            size: pageSize,
            ...(sortField && sortDirection && { sortField, sortDirection }),
            ...(filters && {
              ...kitManagementParentFilterConverter(filters),
            }),
          },
        };
      },
      providesTags: ['kit-management-parents'],
      transformResponse: (
        data: TPageableDataWithContentDB<TKitManagementParentDB[]>,
      ) => {
        return {
          ...pagableDataConverter.fromDb(data),
          content: (data.content || []).map(parent =>
            kitManagementParentConverter.fromDb(parent),
          ),
        };
      },
    }),
    getKitManagementWorkshopHosts: build.query<TTableFilterDataUI[], void>({
      query() {
        return {
          url: `${baseUrl}/${EKitManagementUrls.WORKSHOP}/${EKitManagementUrls.HOSTS}`,
          method: 'GET',
        };
      },
      transformResponse: (data: TLocationHostFilterDataDB[]) => {
        return locationHostFilterDataConverter.fromDb(data);
      },
      providesTags: ['kit-management-workshops-hosts'],
    }),

    getKitManagementWorkshopLocations: build.query<TTableFilterDataUI[], void>({
      query() {
        return {
          url: `${baseUrl}/${EKitManagementUrls.WORKSHOP}/${EKitManagementUrls.LOCATIONS}`,
          method: 'GET',
        };
      },
      transformResponse: (data: TLocationHostFilterDataDB[]) => {
        return locationHostFilterDataConverter.fromDb(data);
      },
      providesTags: ['kit-management-workshops-locations'],
    }),

    getKitManagementParentLocations: build.query<TTableFilterDataUI[], void>({
      query() {
        return {
          url: `${baseUrl}/${EKitManagementUrls.PARENT}/${EKitManagementUrls.LOCATIONS}`,
          method: 'GET',
        };
      },
      transformResponse: (data: TLocationHostFilterDataDB[]) => {
        return locationHostFilterDataConverter.fromDb(data);
      },
      providesTags: ['kit-management-parent-locations'],
    }),

    getKitManagementParentHosts: build.query<TTableFilterDataUI[], void>({
      query() {
        return {
          url: `${baseUrl}/${EKitManagementUrls.PARENT}/${EKitManagementUrls.HOSTS}`,
          method: 'GET',
        };
      },
      transformResponse: (data: TLocationHostFilterDataDB[]) => {
        return locationHostFilterDataConverter.fromDb(data);
      },
      providesTags: ['kit-management-parent-hosts'],
    }),

    markAsOrderedWorkshopAction: build.mutation<
      void,
      TKitManagementMarkAsOrderedUI
    >({
      query(body) {
        return {
          url: `${baseUrl}/${EKitManagementUrls.WORKSHOP}/${EKitManagementUrls.ORDER}`,
          method: 'PATCH',
          body: kitManagementMarkAsOrderedConverter.toDb(body),
        };
      },
      invalidatesTags: ['kit-management-workshops'],
    }),
    exportWorkshopsCSV: build.query<TExportCsvUI, { orderIds: Key[] }>({
      query(params) {
        return {
          url: `${baseUrl}/${EKitManagementUrls.WORKSHOP}/${EKitManagementUrls.EXPORT}`,
          method: 'GET',
          params,
        };
      },
      transformResponse: (data: TExportCsvDB) => {
        const convertedData = exportCsvConverter('zip').fromDb(data);
        downloadFile(convertedData.content, convertedData.fileName);
        return convertedData;
      },
    }),
    markAsOrderedParentAction: build.mutation<void, TParentMarkAsOrderedUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EKitManagementUrls.PARENT}/${EKitManagementUrls.ORDER}`,
          method: 'PATCH',
          body: parentMarkAsOrderedConverter.toDb(body),
        };
      },
      invalidatesTags: ['kit-management-parents'],
    }),
    markAsNoAddressAction: build.mutation<void, { parentOrderIds: Key[] }>({
      query(body) {
        return {
          url: `${baseUrl}/${EKitManagementUrls.PARENT}/${EKitManagementUrls.NO_ADDRESS}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: ['kit-management-parents'],
    }),
    exportParentsCSV: build.query<TExportCsvUI, { parentOrderIds: Key[] }>({
      query(params) {
        return {
          url: `${baseUrl}/${EKitManagementUrls.PARENT}/${EKitManagementUrls.EXPORT}`,
          method: 'GET',
          params,
        };
      },
      transformResponse: (data: TExportCsvDB) => {
        const convertedData = exportCsvConverter('text/csv').fromDb(data);
        downloadFile(convertedData.content, convertedData.fileName);
        return convertedData;
      },
    }),
    addParentShippigDetails: build.mutation<
      void,
      TAddKitManagementParentShippingDetailsUI
    >({
      query(body) {
        return {
          url: `${baseUrl}/${EKitManagementUrls.PARENT}/${EKitManagementUrls.SHIPPING_DETAILS}`,
          method: 'PATCH',
          body: kitManagementParentShippingDetailsConverter.toDb(body),
        };
      },
      invalidatesTags: ['kit-management-parents'],
    }),
  }),
});

export const {
  useGetKitManagementWorkshopsQuery,
  useMarkAsOrderedWorkshopActionMutation,
  useLazyExportWorkshopsCSVQuery,
  useGetKitManagementParentsQuery,
  useMarkAsOrderedParentActionMutation,
  useMarkAsNoAddressActionMutation,
  useLazyExportParentsCSVQuery,
  useAddParentShippigDetailsMutation,
  useGetKitManagementWorkshopHostsQuery,
  useGetKitManagementWorkshopLocationsQuery,
  useGetKitManagementParentHostsQuery,
  useGetKitManagementParentLocationsQuery,
} = kitManagementApi;
