import { SRadio, SRadioGroup } from './RadioGroup.styles';
import { RadioProps, RadioGroupProps } from './RadioGroup.types';

export const RadioGroup = ({ ...props }: RadioGroupProps) => {
  return <SRadioGroup {...props} />;
};

export const Radio = ({ ...props }: RadioProps) => {
  return <SRadio {...props} />;
};
