import { authSplitApi } from 'redux/helpers/slice.helpers';
import { ELightboxUrls, lightboxBaseUrl } from 'data/urls/lightbox.url';
import {
  TGetLightBoxDB,
  TGetLightBoxUI,
  TUpdateLightBoxUI,
} from 'data/types/lightBox.types';
import {
  getLightBoxConverter,
  updateLightBoxConverter,
} from 'data/convertors/lightBox.convertors';

export const lightboxApi = authSplitApi('lightboxApi', [
  'lightBox',
  'lightBoxGallery',
]).injectEndpoints({
  endpoints: build => ({
    getLightBox: build.query<TGetLightBoxUI, void>({
      query() {
        return {
          url: lightboxBaseUrl,
          method: 'GET',
        };
      },
      transformResponse: (data: TGetLightBoxDB) => {
        return getLightBoxConverter.fromDb(data);
      },
      providesTags: ['lightBox'],
    }),
    getLightBoxGallery: build.query<string[], void>({
      query() {
        return {
          url: `${lightboxBaseUrl}/${ELightboxUrls.PHOTO}/${ELightboxUrls.GALLERY}`,
          method: 'GET',
        };
      },
      providesTags: ['lightBoxGallery'],
    }),
    publishLightBox: build.mutation<void, { lightboxId: string }>({
      query({ lightboxId }) {
        return {
          url: `${lightboxBaseUrl}/${lightboxId}/${ELightboxUrls.PUBLISH}`,
          method: 'PATCH',
        };
      },
      invalidatesTags: ['lightBox'],
    }),
    unpublishLightBox: build.mutation<void, { lightboxId: string }>({
      query({ lightboxId }) {
        return {
          url: `${lightboxBaseUrl}/${lightboxId}/${ELightboxUrls.UNPUBLISH}`,
          method: 'PATCH',
        };
      },
      invalidatesTags: ['lightBox'],
    }),
    updateLightBox: build.mutation<string, TUpdateLightBoxUI>({
      query(body) {
        return {
          url: `${lightboxBaseUrl}`,
          method: 'PUT',
          body: updateLightBoxConverter.toDb(body),
        };
      },
      invalidatesTags: ['lightBox'],
    }),
  }),
});

export const {
  useGetLightBoxQuery,
  useGetLightBoxGalleryQuery,
  usePublishLightBoxMutation,
  useUnpublishLightBoxMutation,
  useUpdateLightBoxMutation,
} = lightboxApi;
