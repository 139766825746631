import { Button } from '@packages/utils';
import { styled } from 'styled-components';

export const SChangePasswordWrapper = styled.div`
  max-width: 360px;
`;

export const SButton = styled(Button)`
  &.ant-btn {
    width: auto;
  }
`;
