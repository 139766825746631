import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Amplify } from 'aws-amplify';
import { LoadScript } from '@react-google-maps/api';
import ThemeWrapper from 'theme/ThemeWrapper';
import { RedirectLayer } from 'components/Shared/RedirectLayer';
import ErrorBoundary from './ErrorBoundary';
import { store } from './redux/store';
import RoutesPage from './routes';
import amplifyConfig from './aws-exports';
import { sentryInitialization } from './configs/sentry.config';

Amplify.configure(amplifyConfig);
const googleMapApiKey = process.env.REACT_APP_GOOGLE_PLACES_API_KEY || '';
if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
  sentryInitialization();
}

function App() {
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <Provider store={store}>
          <RedirectLayer>
            <ThemeWrapper>
              {/* // TODO: Load script once on top of application, find good solution */}
              <LoadScript
                googleMapsApiKey={googleMapApiKey}
                libraries={['places']}
                language="en"
              >
                <RoutesPage />
              </LoadScript>
            </ThemeWrapper>
          </RedirectLayer>
        </Provider>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;
