import { FC, useEffect, useState } from 'react';
import { useTranslation } from '@packages/utils';
import { EIconNames } from 'components/Base/Icon';
import { TCheckboxChangeEvent } from 'components/Base/Checkbox/Checbox.types';
import Checkbox from 'components/Base/Checkbox';
import EditableSelect from 'components/Base/EditableFields/EditableSelect';
import { InplaceEditLabels } from 'components/Base/EditableFields/components/InplaceEditLabel';
import { Row, Col } from 'components/Base/Grid';
import { useGetOrganizationsIdentityQuery } from 'redux/apiSlice/organizations.slice';
import CreateOrganizationModal from 'components/Shared/CreateOrganizationModal';
import { useEditLocationMutation } from 'redux/apiSlice/workshops.slice';
import {
  useShowToastNotification,
  ENotificationType,
} from 'components/Base/Notification';
import { TRtkErrorType } from 'data/types/generic.types';
import { TCreateOrganizationModalOpen } from 'components/Shared/CreateOrganizationModal/CreateOrganizationModal.types';
import { TLocationProps } from './Location.types';

const Location: FC<TLocationProps> = ({ location, sameAsHost, id, host }) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.workshops.details.basic-info.location',
  });
  const { showToastNotification } = useShowToastNotification();
  const [checkboxValue, setCheckboxValue] = useState<boolean>();
  const [open, setOpen] = useState<TCreateOrganizationModalOpen>({
    visible: false,
    initialOrgName: '',
  });
  const { data: organizations } = useGetOrganizationsIdentityQuery();
  const [editLocation, { isLoading: editLocationLoading }] =
    useEditLocationMutation();

  useEffect(() => {
    setCheckboxValue(sameAsHost);
  }, [sameAsHost]);

  const onLocationEdit = async (value: string) => {
    try {
      await editLocation({
        id,
        value,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (err) {
      showToastNotification({
        message: (err as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  const onCheckboxChange = async (e: TCheckboxChangeEvent) => {
    const value = e.target.checked;
    setCheckboxValue(value);
    if (value && location?.id && host?.id && location?.id !== host?.id) {
      await onLocationEdit(host.id);
    }
  };
  return (
    <Row align="middle" gutter={[12, 12]}>
      <Col>
        <Row align="middle">
          <InplaceEditLabels label={t('LABEL')} icon={EIconNames.LOCATION} />
          <EditableSelect
            onEdit={onLocationEdit}
            title={{
              label: location?.nameAndAddress || '',
              value: location?.id || '',
            }}
            isLoading={editLocationLoading}
            options={organizations}
            addNewOption={{
              onClick: currentTypedValue => {
                setOpen({
                  visible: true,
                  initialOrgName: currentTypedValue || '',
                });
              },
              addButtonLabel: t('ADD'),
            }}
            emptyText={t('EMPTY_TEXT')}
            readonly={location?.allowedOperations.readonly || checkboxValue}
            canRemove={location?.allowedOperations.canRemove}
          />
        </Row>
      </Col>
      <Col>
        <Checkbox
          onChange={onCheckboxChange}
          disabled={editLocationLoading || location?.allowedOperations.readonly}
          checked={checkboxValue}
          $textNoWrap
          $readonly={location?.allowedOperations.readonly}
        >
          {t('SAME_AS_HOST')}
        </Checkbox>
      </Col>
      <CreateOrganizationModal
        open={open}
        setOpen={setOpen}
        attachOrganization={onLocationEdit}
        attachOrganizationLoading={editLocationLoading}
      />
    </Row>
  );
};

export default Location;
