import { forwardRef } from 'react';
import { useTheme } from 'styled-components';
import { QRCode } from 'components/Base/QRCode';
import { QRCodeImageProps } from './QRCodeImage.types';

const QRCodeImage = forwardRef<HTMLDivElement, QRCodeImageProps>(
  ({ url }, ref) => {
    const theme = useTheme();

    return (
      <div ref={ref} style={{ display: 'none' }}>
        <QRCode value={url} bgColor={theme.colorWhitePrimary} size={240} />
      </div>
    );
  },
);

export default QRCodeImage;
