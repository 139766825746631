import { useState } from 'react';
import { useTranslation } from '@packages/utils';
import { useCheckPermission } from 'hooks/useCheckPermission';
import useTable from 'components/Base/Table/hooks/useTable';
import { useGetMeetingsQuery } from 'redux/apiSlice/elcs.slice';
import { TMeetingsUI } from 'data/types/elcs.types';
import { ETableStoragePaths } from 'components/Base/Table/Table.types';
import CardContent from 'components/Base/Layout/Content/CardContent';
import { TabBodyWrapper } from 'components/Base/Tabs';
import { Table } from 'components/Base/Table';
import { TabsProps } from 'components/Base/Tabs/Tabs.types';
import { Drawer } from 'components/Base/Drawer';
import useKeepTabsInUrl from 'hooks/useKeepTabsInUrl';
import { TMeetingsTabProps } from './MeetingsTab.types';
import useGetData from './Meetings.data';
import AddMeeting from './MeetingsActions/AddMeeting';
import { EELSMeetingsTabsUrl } from './MeetingsDetails/MeetingsDetails.types';
import TopicsTab from './MeetingsDetails/TopicsTab';
import NotesTab from './MeetingsDetails/NotesTab';
import TATimeTab from './MeetingsDetails/TATimeTab';
import AttendeesTab from './MeetingsDetails/AttendeesTab';
import ActionItemsTab from './MeetingsDetails/ActionItemsTab';
import ThingsToShareTab from './MeetingsDetails/ThingsToShareTab';
import { STabs } from './MeetingsTab.styles';

const Meetings = ({ elcId }: TMeetingsTabProps) => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.elcs.detail.meetings-tab',
  });
  const [open, setOpen] = useState<boolean>(false);
  const [meetingDetail, setMeetingDetail] = useState<TMeetingsUI>();
  const isMeetingEditable = useCheckPermission('MEETING_EDIT');
  const { defaultColumns } = useGetData(elcId, isMeetingEditable);

  const {
    page,
    pageSize,
    setPageSize,
    sortColumn,
    hasSelectedFilter,
    onFiltersReset,
    columns,
    handleTableChange,
  } = useTable<TMeetingsUI>({
    storagePath: ETableStoragePaths.MEETINGS,
    defaultColumns,
  });

  const { data, isFetching, isLoading } = useGetMeetingsQuery({
    page,
    pageSize,
    sortColumn,
    elcId,
  });

  const { onTabChange, tabInUrl, removeTab } = useKeepTabsInUrl({
    defaultTab: EELSMeetingsTabsUrl.ATTENDEES,
    isSubTab: true,
  });

  const handleOpenMeetingDetails = (record: TMeetingsUI) => {
    setOpen(true);
    setMeetingDetail(record);
  };

  const tabItems: TabsProps['items'] = [
    {
      key: EELSMeetingsTabsUrl.ATTENDEES,
      label: t('details.tabs.ATTENDEES'),
      children: (
        <AttendeesTab
          meetingId={meetingDetail?.meetingId || ''}
          elcId={elcId}
        />
      ),
    },
    {
      key: EELSMeetingsTabsUrl.TOPICS,
      label: t('details.tabs.TOPICS'),
      children: (
        <TopicsTab
          isDrawerOpen={open}
          meetingId={meetingDetail?.meetingId || ''}
          elcId={elcId}
        />
      ),
    },
    {
      key: EELSMeetingsTabsUrl.THINGS_TO_SHARE,
      label: t('details.tabs.THINGS_TO_SHARE'),
      children: (
        <ThingsToShareTab
          meetingId={meetingDetail?.meetingId || ''}
          elcId={elcId}
        />
      ),
    },
    {
      key: EELSMeetingsTabsUrl.ACTION_ITEMS,
      label: t('details.tabs.ACTION_ITEMS'),
      children: (
        <ActionItemsTab
          meetingId={meetingDetail?.meetingId || ''}
          elcId={elcId}
        />
      ),
    },
    {
      key: EELSMeetingsTabsUrl.NOTES,
      label: t('details.tabs.NOTES'),
      children: (
        <NotesTab meetingId={meetingDetail?.meetingId || ''} elcId={elcId} />
      ),
    },
    {
      key: EELSMeetingsTabsUrl.TA_TIME,
      label: t('details.tabs.TA_TIME'),
      children: (
        <TATimeTab
          meetingId={meetingDetail?.meetingId || ''}
          elcId={elcId}
          isDrawerOpen={open}
        />
      ),
    },
  ];

  const handleDrawerClose = () => {
    setOpen(false);
    removeTab();
  };

  return (
    <CardContent tabContent contentPadding="8px 0px">
      <TabBodyWrapper
        isLoading={isLoading}
        emptyText={t('list.empty-view.EMPTY_TEXT')}
      >
        <Table<TMeetingsUI>
          onFiltersReset={onFiltersReset}
          hasFilters={hasSelectedFilter}
          columns={columns}
          {...(isMeetingEditable && {
            additionalActions: [<AddMeeting elcId={elcId} />],
          })}
          onRow={(record: TMeetingsUI) => ({
            onClick: () => handleOpenMeetingDetails(record),
          })}
          dataSource={data?.content}
          rowClassName="pointer"
          loading={isFetching}
          pageSize={pageSize}
          setPageSize={setPageSize}
          totalDataCount={data?.totalElements}
          onChange={handleTableChange}
          rowKey={record => record.parentOrderId}
          currentPage={page}
          storagePath={ETableStoragePaths.MEETINGS}
        />
      </TabBodyWrapper>
      <Drawer
        open={open}
        title={meetingDetail?.title}
        onClose={handleDrawerClose}
      >
        <STabs
          key={tabInUrl}
          defaultActiveKey={tabInUrl}
          onChange={onTabChange}
          items={tabItems}
        />
      </Drawer>
    </CardContent>
  );
};

export default Meetings;
