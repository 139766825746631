import { useTranslation } from '@packages/utils';
import useGetValidationData from 'components/Base/Form/validationRules';

const useGetFormData = () => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.parents.list.actions.add-shipping-details.modal.form',
  });
  const { validationRules } = useGetValidationData();
  const addShippingDetailsFormFields = {
    address: {
      name: 'address',
      label: t('address.LABEL'),
      placeholder: t('address.PLACEHOLDER'),
      rules: [validationRules.address(), validationRules.address()],
    },
  };

  return { addShippingDetailsFormFields };
};

export default useGetFormData;
