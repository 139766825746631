import { FC, useState } from 'react';
import { useTranslation, Button } from '@packages/utils';
import { Modal } from 'components/Base/Modal';
import {
  useShowToastNotification,
  ENotificationType,
} from 'components/Base/Notification';
import { usePublishActionMutation } from 'redux/apiSlice/workshops.slice';
import { TRtkErrorType } from 'data/types/generic.types';
import ConfirmModalBody from 'components/Shared/ConfirmModalBody';
import { TActionProps } from '../Actions.types';

const PublishAction: FC<TActionProps> = ({ id }) => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.workshops.details.actions.publish',
  });
  const [open, setOpen] = useState(false);
  const { showToastNotification } = useShowToastNotification();

  const [publish, { isLoading }] = usePublishActionMutation();

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    try {
      await publish({
        workshopId: id,
      }).unwrap();
      showToastNotification({
        message: t('modal.SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (err) {
      showToastNotification({
        message: (err as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
    handleClose();
  };

  return (
    <>
      <Button type="primary" color="primary" onClick={() => setOpen(true)}>
        {t('PUBLISH_BTN')}
      </Button>
      <Modal
        open={open}
        title={t('modal.TITLE')}
        cancelText={t('modal.CANCEL_BTN')}
        okText={t('modal.PUBLISH_BTN')}
        onCancel={handleClose}
        onOk={handleSubmit}
        width={350}
        confirmLoading={isLoading}
      >
        <ConfirmModalBody
          title={t('modal.SUBTITLE')}
          description={t('modal.DESCRIPTION')}
        />
      </Modal>
    </>
  );
};

export default PublishAction;
