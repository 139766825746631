import { Row, Col } from 'components/Base/Grid';
import DeleteAction from './DeleteAction';
import EditAction from './EditAction';
import { TActionsProps } from './Actions.types';

const Actions = ({
  organizationId,
  note,
  allowedOperations,
}: TActionsProps) => {
  return (
    <Row gutter={[16, 16]}>
      {!allowedOperations?.readonly && (
        <Col>
          <EditAction organizationId={organizationId} note={note} />
        </Col>
      )}
      {allowedOperations?.canRemove && (
        <Col>
          <DeleteAction organizationId={organizationId} note={note} />
        </Col>
      )}
    </Row>
  );
};

export default Actions;
