import { FC } from 'react';
import { Tag } from '@packages/utils';
import { Tooltip } from 'components/Base/Tooltip';
import { TTagEllipsisProps } from './TagEllipsis.types';

export const TagEllipsis: FC<TTagEllipsisProps> = ({
  text,
  textMaxLength,
  ...tagProps
}) => {
  const isLonger = text?.length > textMaxLength;
  return (
    <Tooltip title={isLonger ? text : ''}>
      <Tag {...tagProps}>
        {isLonger ? `${text?.slice(0, textMaxLength)}...` : text}
      </Tag>
    </Tooltip>
  );
};
