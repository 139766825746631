import useForm from 'hooks/useForm';
import { Modal } from 'components/Base/Modal';
import {
  ENotificationType,
  useShowToastNotification,
} from 'components/Base/Notification';
import { useUpdateOrganizationNoteMutation } from 'redux/apiSlice/organizations.slice';
import { TCreateOrganizationNoteUI } from 'data/types/organizations.types';
import { TRtkErrorType } from 'data/types/generic.types';
import { useTranslation } from '@packages/utils';
import { addDetailsSectionKey } from '../../../AddDetailsSection.data';
import { TEditDetailsModalProps } from './EditDetailsModal.types';
import EditDetailsForm from './EditDetailsModalForm';

const EditDetailsModal = ({
  open,
  setOpen,
  organizationId,
  note,
}: TEditDetailsModalProps) => {
  const { form } = useForm<TCreateOrganizationNoteUI>();
  const { t } = useTranslation({
    keyPrefix: `${addDetailsSectionKey}.modals.edit`,
  });
  const { showToastNotification } = useShowToastNotification();
  const [updateOrganizationNote, { isLoading }] =
    useUpdateOrganizationNoteMutation();

  const handleEditBtnClick = async () => {
    try {
      const values = await form.validateFields();
      await updateOrganizationNote({
        ...values,
        organizationId,
        organizationNoteId: note.id,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
      setOpen(false);
    } catch (err) {
      showToastNotification({
        message: (err as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  return (
    <Modal
      destroyOnClose
      title={t('TITLE')}
      open={open}
      onCancel={() => setOpen(false)}
      okText={t('SUBMIT_BTN')}
      onOk={handleEditBtnClick}
      confirmLoading={isLoading}
    >
      <EditDetailsForm
        form={form}
        note={note}
        organizationId={organizationId}
      />
    </Modal>
  );
};

export default EditDetailsModal;
