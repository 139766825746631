import { Row, useTranslation } from '@packages/utils';
import EditableInput from 'components/Base/EditableFields/EditableInput';
import { InplaceEditLabels } from 'components/Base/EditableFields/components/InplaceEditLabel';
import { EIconNames } from 'components/Base/Icon';
import {
  useShowToastNotification,
  ENotificationType,
} from 'components/Base/Notification';
import { useEditCapacityMutation } from 'redux/apiSlice/workshops.slice';
import { TRtkErrorType } from 'data/types/generic.types';
import useGetValidationData from 'components/Base/Form/validationRules';
import { useTranslateCapacityShowValue } from 'pages/private/Workshop/hooks/useTranslateCapacityShowValue';
import { ECapacityEmptyTexts } from 'data/types/workshops.types';
import { TClassCapacityProps } from './ClassCapacity.types';

const ClassCapacity = ({
  classId,
  workshopId,
  capacity,
  hasIcon,
  registrantsCount,
}: TClassCapacityProps) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.workshops.details.sessions.view.actions.update.capacity',
  });
  const capacityText = useTranslateCapacityShowValue(
    capacity.showValue as ECapacityEmptyTexts,
  );
  const { validationRules } = useGetValidationData();
  const { showToastNotification } = useShowToastNotification();
  const [editCapacity, { isLoading, isError }] = useEditCapacityMutation();
  const onEdit = async (value: string) => {
    try {
      await editCapacity({
        id: classId,
        workshopId,
        value,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (e) {
      showToastNotification({
        message: (e as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  const showValue =
    registrantsCount === undefined
      ? capacityText
      : `${capacityText} / ${registrantsCount}`;

  return (
    <Row align="middle">
      <InplaceEditLabels
        label={t('LABEL')}
        {...(hasIcon && { icon: EIconNames.CAPACITY })}
      />
      <EditableInput
        onEdit={onEdit}
        title={capacity.value}
        showValue={showValue}
        isError={isError}
        isLoading={isLoading}
        readonly={capacity.allowedOperations.readonly}
        canRemove={capacity.allowedOperations.canRemove}
        rules={[validationRules.classCapacityValidation()]}
        backIcon
      />
    </Row>
  );
};

export default ClassCapacity;
