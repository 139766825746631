import { useTranslation } from '@packages/utils';
import { useAppSelector } from 'redux/store';
import {
  EWorkshopSeriesType,
  EWorkshopStandaloneType,
  TWorkshopDetailsDataUI,
} from 'data/types/workshops.types';

const useGetFlyerDescData = () => {
  const flyerLanguage = useAppSelector(state => state.appSlice.flyerLanguage);
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.workshops.details.actions.download-flyer.flyer',
    ...(flyerLanguage && { lng: flyerLanguage }),
  });
  const flyerDescriptionMapper: Record<
    TWorkshopDetailsDataUI['type'],
    { description: string; title: string }
  > = {
    [EWorkshopStandaloneType.READY_FOR_MATH]: {
      description: t('description.READY_FOR_MATH'),
      title: t('subtitle.READY_FOR_MATH'),
    },

    [EWorkshopStandaloneType.READY_FOR_KINDERGARTEN_SOCIAL_EMOTIONAL]: {
      description: t('description.READY_FOR_KINDERGARTEN_SOCIAL_EMOTIONAL'),
      title: t('subtitle.READY_FOR_KINDERGARTEN_SOCIAL_EMOTIONAL'),
    },

    [EWorkshopStandaloneType.CHILD_CARE_AND_PRESCHOOL_PROGRAMS_PART_1]: {
      description: t('description.CHILD_CARE_AND_PRESCHOOL_PROGRAMS_PART_1'),
      title: t('subtitle.CHILD_CARE_AND_PRESCHOOL_PROGRAMS_PART_1'),
    },

    [EWorkshopStandaloneType.CHILD_CARE_AND_PRESCHOOL_PROGRAMS_PART_2]: {
      description: t('description.CHILD_CARE_AND_PRESCHOOL_PROGRAMS_PART_2'),
      title: t('subtitle.CHILD_CARE_AND_PRESCHOOL_PROGRAMS_PART_2'),
    },

    [EWorkshopSeriesType.READY_FOR_KINDERGARTEN]: {
      description: t('description.READY_FOR_KINDERGARTEN'),
      title: t('subtitle.READY_FOR_KINDERGARTEN'),
    },

    [EWorkshopSeriesType.LEARNING_CHILD_CARE_AND_PRESCHOOL_PROGRAMS]: {
      description: t('description.LEARNING_CHILD_CARE_AND_PRESCHOOL_PROGRAMS'),
      title: t('subtitle.LEARNING_CHILD_CARE_AND_PRESCHOOL_PROGRAMS'),
    },
  };
  return { flyerDescriptionMapper };
};

export default useGetFlyerDescData;
