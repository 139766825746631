import { useMemo } from 'react';
import { TMenuProps } from '@idaho-aeyc/ui-kit';
import ActionsButtonDetailsPage from 'components/Shared/ActionsButtonDetailsPage';
import {
  EParentOrderActions,
  TParentActionsProps,
} from './ParentActions.types';
import ParentExport from './ParentExport';
import MarkAsOrdered from './MarkAsOrdered';
import MarkAsNoAddress from './MarkAsNoAddress';

const ParentActions: React.FC<TParentActionsProps> = ({
  selectedRowKeys,
  data,
  setShowAlert,
}) => {
  const selectedRows = useMemo(
    () =>
      selectedRowKeys.map(key => {
        const parent = data.find(item => item.parentOrderId === key);
        return {
          key,
          status: parent?.status,
          workshopId: parent?.workshopId,
        };
      }),
    [selectedRowKeys, data],
  );
  if (!data) return null;
  const items: TMenuProps['items'] = [
    {
      label: (
        <MarkAsNoAddress
          selectedRows={selectedRows}
          setShowAlert={setShowAlert}
        />
      ),
      key: EParentOrderActions.MARK_AS_NO_ADDRESS,
    },
    {
      label: (
        <MarkAsOrdered
          selectedRows={selectedRows}
          setShowAlert={setShowAlert}
        />
      ),
      key: EParentOrderActions.MARK_AS_ORDERED,
    },
    {
      label: (
        <ParentExport selectedRows={selectedRows} setShowAlert={setShowAlert} />
      ),
      key: EParentOrderActions.EXPORT,
    },
  ];

  return <ActionsButtonDetailsPage items={items} />;
};

export default ParentActions;
