import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { setFlyerLanguage } from 'redux/slices';
import { EIconNames } from 'components/Base/Icon';
import { useTranslation } from '@packages/utils';
import ActionGroupItemLabel from 'components/Shared/ActionsButtonDetailsPage/ActionGroupItemLabel';
import { TDownloadFlyerByLanguageProps } from './DownloadFlyerByLanguage.types';

export const DownloadFlyerByLanguage: FC<TDownloadFlyerByLanguageProps> = ({
  flyerLanguage,
  labelLanguage,
}) => {
  const dispatch = useDispatch();
  const { t: tLanguageEnum } = useTranslation({
    keyPrefix: 'enums.language',
  });

  return (
    <ActionGroupItemLabel
      label={tLanguageEnum(labelLanguage)}
      icon={EIconNames.DOWNLOAD}
      onClick={() => dispatch(setFlyerLanguage(flyerLanguage))}
    />
  );
};
