import styled from 'styled-components';
import { Divider } from '@packages/utils';

export const SSidebarUserInfo = styled.div<{ $collapsed: boolean }>`
  padding: ${({ $collapsed }) => ($collapsed ? '24px 0 0' : '24px 24px 0')};
  width: 100%;
`;

export const SDivider = styled(Divider)`
  &.ant-divider {
    margin: 24px 0 12px;
  }
`;
