import {
  pillarConverter,
  pillarFocusConverter,
} from 'data/convertors/pillars.converters';
import {
  TPillarsDB,
  TPillarsFocusesDB,
  TPillarsFocusesUI,
  TPillarsUI,
} from 'data/types/pillars.types';
import { EPillarsUrls } from 'data/urls/pillars.url';
import { authSplitApi } from 'redux/helpers/slice.helpers';

const baseUrl = `${EPillarsUrls.PILLARS}`;

export const pillarsApi = authSplitApi('pillars', [
  'pillars',
  'pillars-focuses',
]).injectEndpoints({
  endpoints: build => ({
    getPillarsList: build.query<TPillarsUI[], void>({
      query() {
        return {
          url: `${baseUrl}`,
          method: 'GET',
        };
      },
      transformResponse: (data: TPillarsDB[]) => {
        return data?.length
          ? data.map(item => pillarConverter.fromDb(item))
          : [];
      },
      providesTags: ['pillars'],
    }),
    getPillarsFocusesList: build.query<
      TPillarsFocusesUI[],
      { pillarId: string }
    >({
      query({ pillarId }) {
        return {
          url: `${baseUrl}/${EPillarsUrls.FOCUSES}`,
          method: 'GET',
          params: { pillarId },
        };
      },
      providesTags: ['pillars-focuses'],
      transformResponse: (data: TPillarsFocusesDB[]) => {
        return data?.length
          ? data.map(item => pillarFocusConverter.fromDb(item))
          : [];
      },
    }),
  }),
});
export const { useGetPillarsListQuery, useGetPillarsFocusesListQuery } =
  pillarsApi;
