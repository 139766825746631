import * as React from 'react';
import { useTranslation } from '@packages/utils';
import { useTheme } from 'styled-components';
import { Col, Row } from 'components/Base/Grid';
import { Title } from 'components/Base/Typography';
import { Image } from 'components/Base/Image';
import {
  EWorkshopStandaloneType,
  EWorkshopSeriesType,
} from 'data/types/workshops.types';
import logo from 'assets/images/logo.svg';
import kinderGardenLogo from 'assets/images/readyForKinderGardenLogo.svg';
import { useAppSelector } from 'redux/store';
import { SDivider } from '../Flyers.styles';
import { TFlyerHeaderProps } from './FlyerHeader.types';

export const FlyerHeader = ({ type }: TFlyerHeaderProps) => {
  const theme = useTheme();
  const flyerLanguage = useAppSelector(state => state.appSlice.flyerLanguage);
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.workshops.details.actions.download-flyer',
    ...(flyerLanguage && { lng: flyerLanguage }),
  });
  return (
    <Row align="middle" justify="space-between" className="mt-8" wrap={false}>
      <Col>
        <Title level={1} className="m-0" color={theme.colorWhitePrimary}>
          {[
            EWorkshopStandaloneType.CHILD_CARE_AND_PRESCHOOL_PROGRAMS_PART_1,
            EWorkshopStandaloneType.CHILD_CARE_AND_PRESCHOOL_PROGRAMS_PART_2,
            EWorkshopSeriesType.LEARNING_CHILD_CARE_AND_PRESCHOOL_PROGRAMS,
          ].includes(type)
            ? t('flyer.CHILDCARE_TITLE')
            : t('flyer.TITLE')}
        </Title>
      </Col>
      <Col>
        <Image src={logo} alt="logo" aria-hidden width={196} />
        <SDivider
          color={theme.colorPrimaryBorderLight}
          type="vertical"
          height={100}
          className="mx-6"
        />
        <Image src={kinderGardenLogo} alt="logo" aria-hidden width={140} />
      </Col>
    </Row>
  );
};
