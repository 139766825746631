import { useGetElcMeetingThingsToShareQuery } from 'redux/apiSlice/elcs.slice';
import { TThingsToShareTabProps } from './ThingsToShareTab.types';
import ThingToShareInplaceEdit from './ThingToShareInplaceEdit';

const ThingsToShareTab = ({ elcId, meetingId }: TThingsToShareTabProps) => {
  const { data } = useGetElcMeetingThingsToShareQuery(
    {
      meetingId,
    },
    {
      skip: !meetingId,
    },
  );

  if (!data) {
    return <></>;
  }

  const { pmThingsToShare, leadThingsToShare } = data;

  return (
    <>
      <ThingToShareInplaceEdit
        content={pmThingsToShare.content}
        elcId={elcId}
        meetingId={meetingId}
        thingToShareId={pmThingsToShare.thingToShareId}
        type="pm"
      />
      <ThingToShareInplaceEdit
        content={leadThingsToShare.content}
        elcId={elcId}
        meetingId={meetingId}
        thingToShareId={leadThingsToShare.thingToShareId}
        type="lead"
      />
    </>
  );
};

export default ThingsToShareTab;
