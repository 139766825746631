import { Paragraph, useTranslation } from '@packages/utils';
import { TColumnType } from 'components/Base/Table/Table.types';

import { phoneNumberConverter } from 'data/convertors/general.converters';
import useGetTableColumnData from 'components/Base/Table/components/SearchFilter';
import { EPeoplesColumnKey, TPeoplesDataUI } from 'data/types/people.types';
import Tags from 'components/Shared/Tags';
import { ETagsUrls } from 'data/urls/tags.url';
import { ETagType } from 'data/types/tags.types';
import { peopleApi } from 'redux/apiSlice/people.slice';
import { ESortDirection } from 'data/types/generic.types';
import { elcsApi } from 'redux/apiSlice/elcs.slice';
// import PeopleListActions from '../PeopleListActions';

const useGetColumnsData = () => {
  const { getTableColumnSearchProps } = useGetTableColumnData();
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.people.list.table.title',
  });
  const defaultColumns: TColumnType<TPeoplesDataUI>[] = [
    {
      title: t('CREATE_DATE'),
      dataIndex: 'createdDate',
      key: EPeoplesColumnKey.CREATION_DATE,
      sorter: true,
    },
    {
      title: t('FIRST_NAME'),
      dataIndex: 'firstName',
      sorter: true,
      key: EPeoplesColumnKey.FIRST_NAME,
      ...getTableColumnSearchProps<TPeoplesDataUI>(),
    },
    {
      title: t('LAST_NAME'),
      dataIndex: 'lastName',
      sorter: true,
      key: EPeoplesColumnKey.LAST_NAME,
      ...getTableColumnSearchProps<TPeoplesDataUI>(),
    },
    {
      title: t('TITLE'),
      dataIndex: 'title',
      key: 'title',
      onCell: () => ({
        style: {
          maxWidth: '200px',
        },
      }),
      render: text => (
        <Paragraph
          ellipsis={{
            tooltip: text,
          }}
        >
          {text}
        </Paragraph>
      ),
    },
    {
      title: t('MOBILE'),
      dataIndex: 'phone',
      key: 'phone',
      onCell: () => ({
        style: {
          minWidth: '160px',
        },
      }),
      render: (text, record) => (
        <>{record.phone && phoneNumberConverter.fromDb(record.phone)}</>
      ),
    },
    {
      title: t('EMAIL'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t('TAG'),
      dataIndex: 'tags',
      key: EPeoplesColumnKey.TAGS,
      filterSearch: true,
      filters: [],
      width: 50,
      onCell: () => ({
        className: 'custom-cell',
        onClick: e => {
          e.stopPropagation();
        },
      }),
      render: (text, record) => (
        <Tags
          selectedTags={record.tags || []}
          associationId={record.userId}
          url={ETagsUrls.PEOPLE}
          tagType={ETagType.PEOPLE}
          invalidateTagsConfig={[
            { invalidateTag: 'peoples', sliceName: peopleApi },
            { invalidateTag: 'elcs-contacts', sliceName: elcsApi },
          ]}
        />
      ),
    },
    // {
    //   title: ' ',
    //   key: 'actions',
    //   dataIndex: 'actions',
    //   fixed: 'right',
    //   width: 120,
    //   onCell: () => ({
    //     style: { minWidth: '120px' },
    //   }),
    //   render: (text, record) => (
    //     <>{!record.locked && <PeopleListActions personData={record} />}</>
    //   ),
    // },
  ];

  const defaultSortParam = {
    sortField: EPeoplesColumnKey.CREATION_DATE,
    sortDirection: ESortDirection.descend,
  };

  return { defaultColumns, defaultSortParam };
};

export default useGetColumnsData;
