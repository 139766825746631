import { FC } from 'react';
import ExportAction from 'pages/private/KitManagement/components/KitManagementActions/ExportAction';
import { TActionProps } from 'pages/private/KitManagement/components/KitManagementActions/ExportAction/ExportActions.types';
import { useLazyExportWorkshopsCSVQuery } from 'redux/apiSlice/kitManagement.slice';

const WorkshopExport: FC<TActionProps> = ({ setShowAlert, selectedRows }) => {
  const orderIds = selectedRows?.map(row => row.key) || [];
  const [exportWorkshops, { isLoading }] = useLazyExportWorkshopsCSVQuery();

  const onExport = async () => {
    await exportWorkshops({
      orderIds,
    }).unwrap();
  };
  return (
    <ExportAction
      setShowAlert={setShowAlert}
      onSubmit={onExport}
      confirmLoading={isLoading}
      selectedRows={selectedRows}
    />
  );
};

export default WorkshopExport;
