import { useRef } from 'react';
import { useTranslation } from '@packages/utils';
import { EIconNames } from 'components/Base/Icon';
import useQRCodeDownloader from 'hooks/useDownloadQRCode';
import ActionGroupItemLabel from 'components/Shared/ActionsButtonDetailsPage/ActionGroupItemLabel';
import QRCodeImage from '../../components/QRCodeImage';

const DownloadLandingQrAction = ({
  workshopUrl,
}: {
  workshopUrl: string | undefined;
}) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.workshops.details.actions.download-qr',
  });
  const downloadQRCode = useQRCodeDownloader();

  const qrRef = useRef<HTMLDivElement>(null);
  const onDownloadQrCode = async () => {
    await downloadQRCode(qrRef);
  };
  return (
    <>
      {workshopUrl && <QRCodeImage url={workshopUrl} ref={qrRef} />}
      <ActionGroupItemLabel
        label={t('DOWNLOAD_QR_BTN')}
        icon={EIconNames.DOWNLOAD}
        onClick={onDownloadQrCode}
      />
    </>
  );
};

export default DownloadLandingQrAction;
