import { FC } from 'react';
import { useTranslation } from '@packages/utils';
import { Row } from 'components/Base/Grid';
import { TOrientationKitItemProps } from './OrientationKitItem.types';
import AttendanceItemRow from '../AttendanceItemRow';
import useGetHelpers from '../../helpers';

const OrientationKitItem: FC<TOrientationKitItemProps> = ({
  orientationKit,
}) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.workshops.details.workshop-registrants.orientation-kit',
  });
  const { getOrientationKitRequestedText, getAttendanceTextColor } =
    useGetHelpers();

  if (!orientationKit) {
    return null;
  }
  const text = getOrientationKitRequestedText(orientationKit.requested);

  return (
    <Row className="white-space-no-wrap">
      <AttendanceItemRow
        title={`${t('label.BINDER')}:`}
        titleSuffix={{
          text,
          color: getAttendanceTextColor(orientationKit.requested),
        }}
        width={150}
        isChecked={orientationKit.received}
        className="mt-2"
      />
    </Row>
  );
};

export default OrientationKitItem;
