import { TMenuProps } from '@idaho-aeyc/ui-kit';
import { useGetWorkshopUrlQuery } from 'redux/apiSlice/workshops.slice';
import { EWorkshopLanguage } from 'data/types/generic.types';
import {
  EWorkshopDetailsAllowedActions,
  EWorkshopStatus,
} from 'data/types/workshops.types';
import { EI18nLanguageUI, useTranslation } from '@packages/utils';
import ActionsButtonDetailsPage from 'components/Shared/ActionsButtonDetailsPage';
import { TActionsGroupProps } from '../Actions.types';
import CopyUrlAction from '../CopyUrlAction';
import DownloadLandingQrAction from '../DownloadLandingQrAction';
import CancelAction from '../CancelAction';
import DuplicateAction from '../DuplicateAction';
import DeleteAction from '../DeleteAction';
import DownloadFlyerAction from '../DownloadFlyerAction';
import { DownloadFlyerByLanguage } from '../DownloadFlyerAction/DownloadFlyerByLanguage';
import CloseAction from '../CloseAction';
import CopyUrls from '../CopyUrlAction/CopyUrls';
import CopyAttendanceListUrl from '../CopyUrlAction/CopyAttendanceListUrl';
import CopyChildCareUrl from '../CopyUrlAction/CopyChildCareUrl';

const ActionsGroupButton = ({ id, data }: TActionsGroupProps) => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.workshops.details.actions',
  });

  const { data: workshopUrl } = useGetWorkshopUrlQuery(
    {
      workshopId: id,
    },
    {
      skip: data?.status !== EWorkshopStatus.PUBLISHED,
    },
  );

  const actionsMapper: Partial<
    Record<EWorkshopDetailsAllowedActions, JSX.Element | null>
  > = {
    CANCEL: <CancelAction id={id} />,
    DUPLICATE: <DuplicateAction id={id} />,
    DELETE: <DeleteAction id={id} />,
    COPY_URL: <CopyUrlAction />,
    DOWNLOAD_LANDING_QR: <DownloadLandingQrAction workshopUrl={workshopUrl} />,
    DOWNLOAD_FLYER: (
      <DownloadFlyerAction data={data} workshopUrl={workshopUrl} />
    ),
    CLOSE: <CloseAction id={id} />,
  };
  // @ts-ignore // TODO NONA review
  const items: TMenuProps['items'] = data?.allowedActions.map(action => {
    if (action === EWorkshopDetailsAllowedActions.DOWNLOAD_FLYER) {
      return {
        label: actionsMapper[action],
        key: action,
        children: [
          {
            label: (
              <DownloadFlyerByLanguage
                flyerLanguage={EI18nLanguageUI.ENGLISH}
                labelLanguage={EWorkshopLanguage.ENGLISH}
              />
            ),
            key: EI18nLanguageUI.ENGLISH,
          },
          {
            label: (
              <DownloadFlyerByLanguage
                flyerLanguage={EI18nLanguageUI.SPANISH}
                labelLanguage={EWorkshopLanguage.SPANISH}
              />
            ),
            key: EI18nLanguageUI.SPANISH,
          },
        ],
      };
    }
    if (action === EWorkshopDetailsAllowedActions.COPY_URL) {
      return {
        label: actionsMapper[action],
        key: action,
        children: [
          {
            key: '1',
            type: 'group',
            label: t('copy-url.PUBLIC_PAGE'),
            children: [
              {
                key: '1-1',
                label: (
                  <CopyUrls
                    copyText={t('copy-url.COPY')}
                    copyUrl={workshopUrl}
                  />
                ),
              },
            ],
          },
          data.childcareProvided?.value
            ? {
                key: '2',
                type: 'group',
                label: t('copy-url.CHILD_CARE_URL'),
                children: [
                  {
                    key: '2-1',
                    label: (
                      <CopyChildCareUrl
                        workshopId={id}
                        copyText={t('copy-url.COPY')}
                      />
                    ),
                  },
                ],
              }
            : null,
          {
            key: '3',
            type: 'group',
            label: t('copy-url.TOTAL_ATTENDANCE_LIST'),
            children: data.actualSessionDates?.map((item, index) => ({
              key: `3-${index}`,
              label: <CopyAttendanceListUrl workshopId={id} date={item} />,
            })),
          },
        ],
      };
    }
    return {
      label: actionsMapper[action],
      key: action,
    };
  });

  if (!items?.length) {
    return <></>;
  }

  return <ActionsButtonDetailsPage items={items} />;
};

export default ActionsGroupButton;
