import { TAgeGroupColumnItem } from '../../components/Shared/AgeGroupesColumn/AgeGroupesColumn.types';
import { EAgeGroup, EWorkshopLanguage } from './generic.types';

export enum EWorkshopSubscribersColumnKey {
  AGE_GROUP = 'ageGroups',
  CITY = 'cities',
  EMAIL = 'email',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
}

export type TWorkshopSubscriberDataUI = {
  createdDate: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  spanishAgeGroups: TAgeGroupColumnItem[];
  englishAgeGroups: TAgeGroupColumnItem[];
  cities: string[];
  workshopSubscriberId: string;
};

export type TWorkshopSubscriberDataDB = {
  createdDate: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  spanishAgeGroups: EAgeGroup[];
  englishAgeGroups: EAgeGroup[];
  workshopSubscriberId: string;
  cities: string[];
};

export type TWorkshopSubscriberCreateUI = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  cities: string[];
  ageGroups: EAgeGroup[];
  languages: EWorkshopLanguage[];
};

export type TWorkshopSubscriberCreateDB = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  cities: string[];
  ageGroups: EAgeGroup[];
  languages: EWorkshopLanguage[];
};

export type TWorkshopSubscriberUnsubscribeUI = {
  workshopSubscriberId: string;
};
export type TWorkshopSubscriberUnsubscribeDB = {
  workshopSubscriberId: string;
};
