import { FC, useEffect, useState } from 'react';
import { Card, Col, Row } from '@packages/utils';
import { EWorkshopCategory, EWorkshopStatus } from 'data/types/workshops.types';
import { TSessionViewItemProps } from '../SessionView.types';
import AgeGroupSelect from '../components/AgeGroupSelect';
import LanguageSelect from '../components/LanguageSelect';
import ClassCapacity from '../components/ClassCapacity';
import StandalonePartView from './StandalonePartView';
import SeriesPartsView from './SeriesPartsView';
import Actions from '../components/Actions';
import { SRow } from './SessionViewItem.styles';

const SessionViewItem: FC<TSessionViewItemProps> = ({
  typeCategory,
  allowedOperations,
  classItem,
  onDuplicate,
  workshopId,
  workshopStatus,
  possibleAgeGroup,
}) => {
  const [registrantsCount, setRegistrantsCount] = useState(
    classItem.registrantsCount,
  );

  useEffect(() => {
    setRegistrantsCount(classItem.registrantsCount);
  }, [classItem.registrantsCount]);

  const { ageGroup, capacity, language, id } = classItem;

  return (
    <Card className="mt-4">
      <SRow gutter={[16, 16]} className="w-100 mb-0">
        <Col>
          <AgeGroupSelect
            id={id}
            age={ageGroup}
            workshopId={workshopId}
            possibleAgeGroup={possibleAgeGroup}
          />
        </Col>
        <Col>
          <LanguageSelect id={id} workshopId={workshopId} language={language} />
        </Col>
        {workshopStatus === EWorkshopStatus.PLANNING && (
          <Col>
            <ClassCapacity
              hasIcon
              classId={id}
              workshopId={workshopId}
              capacity={capacity}
            />
          </Col>
        )}
        <Col className="ml-auto">
          <Actions
            classItem={classItem}
            onDuplicate={onDuplicate}
            workshopId={workshopId}
            workshopStatus={workshopStatus}
            allowedOperations={allowedOperations}
            registrantsCount={registrantsCount}
          />
        </Col>
      </SRow>
      <Row gutter={[16, 16]}>
        {typeCategory === EWorkshopCategory.STANDALONE ? (
          <StandalonePartView
            classItem={classItem}
            workshopId={workshopId}
            workshopStatus={workshopStatus}
          />
        ) : (
          <SeriesPartsView
            workshopStatus={workshopStatus}
            classItem={classItem}
            workshopId={workshopId}
          />
        )}
      </Row>
    </Card>
  );
};
export default SessionViewItem;
