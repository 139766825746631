import { FC, useRef } from 'react';
import { useTranslation } from '@packages/utils';
import { EIconNames } from 'components/Base/Icon';
import useQRCodeDownloader from 'hooks/useDownloadQRCode';
import ActionGroupItemLabel from 'components/Shared/ActionsButtonDetailsPage/ActionGroupItemLabel';
import QRCodeImage from 'pages/private/Workshop/components/WorkShopDetails/components/QRCodeImage';
import {
  ENotificationType,
  useShowToastNotification,
} from 'components/Base/Notification';
import { useLazyGetWorkshopAppUrlByOrganizationQuery } from 'redux/apiSlice/organizations.slice';
import { organizationActionsKey } from '../../OrganizationDetails.data';
import { TDownloadQrActionProps } from './DeleteAction.types';

const DownloadQrAction: FC<TDownloadQrActionProps> = ({ id }) => {
  const { t } = useTranslation({
    keyPrefix: organizationActionsKey.concat('.download-qr'),
  });
  const { showToastNotification } = useShowToastNotification();

  const [getWorkshopUrl, { data: workshopAppUrl }] =
    useLazyGetWorkshopAppUrlByOrganizationQuery();

  const downloadQRCode = useQRCodeDownloader();

  const qrRef = useRef<HTMLDivElement>(null);
  const onDownloadQrCode = async () => {
    try {
      await getWorkshopUrl({
        id,
      }).unwrap();
      await downloadQRCode(qrRef);
    } catch (error) {
      showToastNotification({
        message: undefined,
        type: ENotificationType.ERROR,
      });
    }
  };
  return (
    <>
      {workshopAppUrl && <QRCodeImage url={workshopAppUrl} ref={qrRef} />}
      <ActionGroupItemLabel
        label={t('DOWNLOAD_QR_BTN')}
        icon={EIconNames.DOWNLOAD}
        onClick={onDownloadQrCode}
      />
    </>
  );
};

export default DownloadQrAction;
