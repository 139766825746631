import {
  configureStore,
  combineReducers,
  CombinedState,
  AnyAction,
} from '@reduxjs/toolkit';
import {
  shallowEqual,
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
} from 'react-redux';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import appSlice from './slices/app.slice';
import redirectSlice from './slices/redirect.slice';
import userAmplifySlice from './slices/userAmplify.slices';
import { authApi } from './apiSlice/auth.slice';
import { workshopsApi } from './apiSlice/workshops.slice';
import { authNoHeaderApi } from './apiSlice/authNoHeader.slice';
import { organizationsApi } from './apiSlice/organizations.slice';
import { registrantsApi } from './apiSlice/registrants.slice';
import { kitManagementApi } from './apiSlice/kitManagement.slice';
import { subscibersApi } from './apiSlice/subscibers.slice';
import { workshopAppApi } from './apiSlice/workshopApp.slice';
import { lightboxApi } from './apiSlice/lightbox.slice';
import { familyApi } from './apiSlice/family.slice';
import { tagsApi } from './apiSlice/tags.slice';
import { userGrowApi } from './apiSlice/userGrow.slice';
import { peopleApi } from './apiSlice/people.slice';
import { elcsApi } from './apiSlice/elcs.slice';
import { pillarsApi } from './apiSlice/pillars.slice';
import { storageApi } from './apiSlice/storage.slice';

type TState =
  | CombinedState<{
      [x: string]: unknown;
    }>
  | undefined;

const appReducer = combineReducers({
  appSlice: appSlice.reducer,
  redirectSlice: redirectSlice.reducer,
  userSlice: userAmplifySlice.reducer,
  [userGrowApi.reducerPath]: userGrowApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [authNoHeaderApi.reducerPath]: authApi.reducer,
  [workshopsApi.reducerPath]: workshopsApi.reducer,
  [organizationsApi.reducerPath]: organizationsApi.reducer,
  [registrantsApi.reducerPath]: registrantsApi.reducer,
  [kitManagementApi.reducerPath]: kitManagementApi.reducer,
  [subscibersApi.reducerPath]: subscibersApi.reducer,
  [workshopAppApi.reducerPath]: workshopAppApi.reducer,
  [lightboxApi.reducerPath]: lightboxApi.reducer,
  [familyApi.reducerPath]: familyApi.reducer,
  [tagsApi.reducerPath]: tagsApi.reducer,
  [peopleApi.reducerPath]: peopleApi.reducer,
  [elcsApi.reducerPath]: elcsApi.reducer,
  [storageApi.reducerPath]: storageApi.reducer,
  [pillarsApi.reducerPath]: pillarsApi.reducer,
});

const rootReducer = (state: TState, action: AnyAction) => {
  if (action.type === 'USER_LOGOUT') {
    return appReducer(undefined, action);
  }

  // @ts-ignore
  return appReducer(state, action);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setupStore = (initialState: Record<string, any> = {}) =>
  configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(
        userGrowApi.middleware,
        authApi.middleware,
        authNoHeaderApi.middleware,
        workshopsApi.middleware,
        organizationsApi.middleware,
        registrantsApi.middleware,
        kitManagementApi.middleware,
        subscibersApi.middleware,
        workshopAppApi.middleware,
        lightboxApi.middleware,
        familyApi.middleware,
        tagsApi.middleware,
        peopleApi.middleware,
        elcsApi.middleware,
        storageApi.middleware,
        pillarsApi.middleware,
      ),
    preloadedState: initialState,
  });

export const store = setupStore();

export const clearAllCachedData = () => {
  store.dispatch(userGrowApi.util.resetApiState());
  store.dispatch(authApi.util.resetApiState());
  store.dispatch(authNoHeaderApi.util.resetApiState());
  store.dispatch(workshopsApi.util.resetApiState());
  store.dispatch(organizationsApi.util.resetApiState());
  store.dispatch(registrantsApi.util.resetApiState());
  store.dispatch(kitManagementApi.util.resetApiState());
  store.dispatch(subscibersApi.util.resetApiState());
  store.dispatch(workshopAppApi.util.resetApiState());
  store.dispatch(lightboxApi.util.resetApiState());
  store.dispatch(familyApi.util.resetApiState());
  store.dispatch(tagsApi.util.resetApiState());
  store.dispatch(peopleApi.util.resetApiState());
  store.dispatch(elcsApi.util.resetApiState());
  store.dispatch(storageApi.util.resetApiState());
  store.dispatch(pillarsApi.util.resetApiState());
};

export type RootState = ReturnType<typeof rootReducer>;
type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = cb =>
  useSelector(cb, shallowEqual);

setupListeners(store.dispatch);
