import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from '@packages/utils';
import { workshopsApi } from 'redux/apiSlice/workshops.slice';
import { Table } from 'components/Base/Table';
import {
  ERegistrantsColumnKey,
  ERegitrantStatus,
  TRegistrantUI,
} from 'data/types/registrants.types';
import {
  useGetRegistrantsQuery,
  useGetValidateExportQuery,
} from 'redux/apiSlice/registrants.slice';
import useTable from 'components/Base/Table/hooks/useTable';
import { ETableStoragePaths } from 'components/Base/Table/Table.types';
import { TabBodyWrapper } from 'components/Base/Tabs';
import { EWorkshopStatus } from 'data/types/workshops.types';
import useGetRegistrantsColumnsData, {
  filterRegistrantsColumns,
} from './RegistrantsList.data';
import { TRegistrantsListProps } from './RegistrantsList.types';
import useAgeGroupLanguageFilter from './useAgeGroupLanguageFilter';
import Attendances from './Attendances';
import RegistrantsExport from './RegistrantsAction/RegistrantsExport';

const RegistrantsList: FC<TRegistrantsListProps> = ({
  workshopId = '',
  customQuestion,
  hideChildInfo,
}) => {
  const { workshopRegistrantsColumns } = useGetRegistrantsColumnsData();
  const { data: isExportValid } = useGetValidateExportQuery(
    {
      workshopId,
    },
    {
      skip: !workshopId,
    },
  );

  const params = useParams();
  const { id } = params as { id: string };

  const { data: workshopDetailsCachedData } =
    workshopsApi.endpoints.getWorkshopById.useQueryState(
      {
        id,
      },
      { skip: !id },
    );

  const workshopClasses = workshopDetailsCachedData?.classes.value || [];
  const workshopStatus = workshopDetailsCachedData?.status;

  const {
    page,
    pageSize,
    sortColumn,
    setPageSize,
    handleTableChange,
    selectedFilters,
    hasSelectedFilter,
    hasFilters,
    columns,
    setColumns,
    onFiltersReset,
  } = useTable<TRegistrantUI>({
    storagePath: `${ETableStoragePaths.WORKSHOP_REGISTRANTS}-${workshopId}`,
    defaultColumns: workshopRegistrantsColumns,
  });

  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.workshops.details.workshop-registrants',
  });

  const { data, isFetching, isLoading, isSuccess } = useGetRegistrantsQuery(
    {
      page: 0,
      pageSize: 2000,
      sortColumn,
      filters: selectedFilters,
      workshopId,
    },
    {
      skip: !workshopId,
    },
  );

  useEffect(() => {
    setColumns(
      columns.map(item => {
        if (item.key === 'attendances') {
          item.render = (_, record) => {
            return (
              <Attendances
                isReadonly={
                  workshopStatus !== EWorkshopStatus.PUBLISHED ||
                  data?.allowedOperations.readonly
                }
                key={record.registrantId}
                attendances={record.attendances}
                fullName={record.fullName}
                hasUpdatableAttendance={record.hasUpdatableAttendance}
                registrantId={record.registrantId}
                workshopClassId={record.workshopClassId}
                orientationKit={record.orientationKit}
                hasStartedPart={!!workshopDetailsCachedData?.hasStartedPart}
                materialsLanguage={record.materialsLanguage}
                workshopId={workshopId}
                registrantStatus={record.registrantStatus}
              />
            );
          };
        }
        return item;
      }),
    );
    filterRegistrantsColumns({
      columns,
      setColumns,
      customQuestion,
      hideChildInfo,
    });
  }, [
    isSuccess,
    workshopStatus,
    workshopDetailsCachedData?.hasStartedPart,
    hideChildInfo,
    customQuestion,
  ]);

  const ageGroupLanguageFilters = useAgeGroupLanguageFilter(workshopClasses);
  useEffect(() => {
    if (ageGroupLanguageFilters) {
      setColumns(
        columns.map(item => {
          if (
            item.key ===
              ERegistrantsColumnKey.FILTER_BY_LANGUAGE_AND_AGE_GROUP &&
            ageGroupLanguageFilters
          ) {
            item.filters = ageGroupLanguageFilters;
          }
          return item;
        }),
      );
    }
  }, [workshopClasses]);

  return (
    <TabBodyWrapper
      isEmpty={!data?.content.length && !hasSelectedFilter && !isFetching}
      isLoading={isLoading}
      emptyText={t('empty-view.TITLE')}
    >
      <Table<TRegistrantUI>
        {...(isExportValid && {
          additionalActions: (
            <RegistrantsExport
              workshopId={workshopId}
              selectedFilters={selectedFilters}
            />
          ),
        })}
        tabHeight={44}
        tableTitle={t('TITLE')}
        onFiltersReset={onFiltersReset}
        hasFilters={hasFilters}
        currentPage={page}
        columns={columns}
        dataSource={data?.content}
        loading={isFetching}
        pageSize={pageSize}
        setPageSize={setPageSize}
        totalDataCount={data?.totalElements}
        onChange={handleTableChange}
        rowKey={record => record.registrantId}
        rowClassName={record =>
          record.registrantStatus === ERegitrantStatus.UNREGISTERED
            ? 'disabled-registrant-row'
            : ''
        }
        storagePath={ETableStoragePaths.WORKSHOP_REGISTRANTS}
      />
    </TabBodyWrapper>
  );
};

export default RegistrantsList;
