import {
  Button,
  ENotificationType,
  TRtkErrorType,
  useShowToastNotification,
  useTranslation,
} from '@packages/utils';
import { EIconNames, Icon } from 'components/Base/Icon';
import { useLazyExportRegistrantsCSVQuery } from 'redux/apiSlice/registrants.slice';
import { TRegistrantsExportProps } from './RegistrantsExport.types';

const RegistrantsExport = ({
  workshopId,
  selectedFilters,
}: TRegistrantsExportProps) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.workshops.details.workshop-registrants.table.actions.export',
  });
  const { showToastNotification } = useShowToastNotification();

  const [exportParents, { isLoading }] = useLazyExportRegistrantsCSVQuery();

  const handleExport = async () => {
    try {
      await exportParents({ workshopId, filters: selectedFilters }).unwrap();
    } catch (err) {
      showToastNotification({
        message: (err as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  return (
    <Button
      onClick={handleExport}
      color="primary"
      type="default"
      icon={<Icon icon={EIconNames.DOWNLOAD} alt="filter icon" />}
      loading={isLoading}
      disabled={isLoading}
    >
      {t('LABEL')}
    </Button>
  );
};

export default RegistrantsExport;
