import { FC, useEffect, useState } from 'react';
import { useEditFoodProvidedMutation } from 'redux/apiSlice/workshops.slice';
import {
  ENotificationType,
  useShowToastNotification,
} from 'components/Base/Notification';
import { useTranslation } from '@packages/utils';
import { TRtkErrorType } from 'data/types/generic.types';
import { TCheckboxChangeEvent } from 'components/Base/Checkbox/Checbox.types';
import Checkbox from 'components/Base/Checkbox';
import { Modal } from 'components/Base/Modal';
import ConfirmModalBody from 'components/Shared/ConfirmModalBody';
import { TFoodProps } from './Food.types';
import { providingKey } from '../Providing.data';

const Food: FC<TFoodProps> = ({ foodProvided, id, needConfirmation }) => {
  const [checked, setChecked] = useState<boolean>(!!foodProvided?.value);

  useEffect(() => {
    setChecked(!!foodProvided?.value);
  }, [foodProvided?.value]);

  const [openConfirmMidal, setOpenConfirmModal] = useState<boolean>(false);
  const [editFoodProvided, { isLoading: isFoodLoading }] =
    useEditFoodProvidedMutation();
  const { showToastNotification } = useShowToastNotification();
  const { t } = useTranslation({
    keyPrefix: `${providingKey}.meal`,
  });

  const updateFood = async (value: boolean) => {
    try {
      await editFoodProvided({
        id,
        value,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (error) {
      showToastNotification({
        message: (error as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  const onChange = async (e: TCheckboxChangeEvent) => {
    if (needConfirmation) {
      setOpenConfirmModal(true);
      return;
    }
    const value = e.target.checked;
    setChecked(value);
    await updateFood(value);
  };

  const handleClose = () => {
    setOpenConfirmModal(false);
  };
  const handleSubmit = async () => {
    await updateFood(!checked);
    handleClose();
  };
  return (
    <>
      <Checkbox
        onChange={onChange}
        checked={checked}
        disabled={isFoodLoading || foodProvided?.allowedOperations.readonly}
        $readonly={foodProvided?.allowedOperations.readonly}
      >
        {t('LABEL')}
      </Checkbox>
      {openConfirmMidal && (
        <Modal
          open={openConfirmMidal}
          title={t('confirm-modal.TITLE')}
          cancelText={t('confirm-modal.CANCEL_BTN')}
          okText={t('confirm-modal.UPDATE_BTN')}
          onCancel={handleClose}
          onOk={handleSubmit}
          confirmLoading={isFoodLoading}
        >
          <ConfirmModalBody description={t('confirm-modal.DESCRIPTION')} />
        </Modal>
      )}
    </>
  );
};

export default Food;
