import { pagableDataConverter } from 'data/convertors/general.converters';
import {
  addAdditionalEmailConverter,
  addAdditionalPhoneConverter,
  createOrganizationConvertor,
  createOrganizationNoteConvertor,
  deleteOrganizationConverter,
  deleteOrganizationNoteConvertor,
  getOrganizationByIdConverter,
  getOrganizationsIdentityConvertor,
  organizationFilterConverter,
  organizationGetConverter,
  updateAdditionalEmailConverter,
  updateAdditionalPhoneConverter,
  updateOrganizationAdditionalAddressConverter,
  updateOrganizationAddressConverter,
  updateOrganizationConverter,
  updateOrganizationEmailConverter,
  updateOrganizationNameConverter,
  updateOrganizationNoteConvertor,
  updateOrganizationPhoneConverter,
} from 'data/convertors/organizations.convertors';
import {
  TPageableDataWithContentDB,
  TPageableDataWithContentUI,
  TTableFilters,
} from 'data/types/generic.types';
import {
  EOrganizationFilterParams,
  EOrganizationSortParams,
  TAddAdditionalEmailUI,
  TAddAdditionalPhoneUI,
  TCreateOrganizationNoteUI,
  TCreateOrganizationUI,
  TDeleteOrganizationNoteUI,
  TDeleteOrganizationUI,
  TOrganizationByIdDB,
  TOrganizationByIdUI,
  TOrganizationDataDB,
  TOrganizationDataUI,
  TOrganizationsIdentityDB,
  TOrganizationsIdentityUI,
  TUpdateAdditionalEmailUI,
  TUpdateAdditionalPhoneUI,
  TUpdateOrganizationAdditionalAddressUI,
  TUpdateOrganizationAddressUI,
  TUpdateOrganizationFieldUI,
  TUpdateOrganizationNoteUI,
  TUpdateOrganizationUI,
} from 'data/types/organizations.types';
import {
  EOrganizationsUrls,
  getOrganizationByIdUrl,
  updateOrganizationAddressUrl,
  updateOrganizationEmailUrl,
  updateOrganizationNameUrl,
  updateOrganizationPhoneUrl,
} from 'data/urls/organizations.url';
import { authSplitApi } from 'redux/helpers/slice.helpers';
import { workshopsApi } from './workshops.slice';
import { elcsApi } from './elcs.slice';

const baseUrl = `${EOrganizationsUrls.ORGANIZATIONS}`;

export const organizationsApi = authSplitApi('organizations', [
  'organizationsIdentity',
  'organizationsCity',
  'organizations',
  'organization',
]).injectEndpoints({
  endpoints: build => ({
    getOrganizationsIdentity: build.query<TOrganizationsIdentityUI[], void>({
      query() {
        return {
          url: `${baseUrl}/${EOrganizationsUrls.IDENTITY}`,
          method: 'GET',
        };
      },
      providesTags: ['organizationsIdentity'],
      transformResponse: (data: TOrganizationsIdentityDB[] | []) => {
        return data?.length
          ? data.map(item => getOrganizationsIdentityConvertor.fromDb(item))
          : [];
      },
    }),
    getOrganizationsCities: build.query<string[], void>({
      query() {
        return {
          url: `${baseUrl}/${EOrganizationsUrls.CITIES}`,
          method: 'GET',
        };
      },
      providesTags: ['organizationsCity'],
    }),
    deleteOrganization: build.mutation<void, TDeleteOrganizationUI>({
      query(body) {
        return {
          url: `${baseUrl}`,
          method: 'DELETE',
          body: deleteOrganizationConverter.toDb(body),
        };
      },
      invalidatesTags: ['organizationsCity', 'organizations'],
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(workshopsApi.util.invalidateTags(['workshop']));
            dispatch(elcsApi.util.invalidateTags(['elc-places-identity']));
          })
          .catch(() => {
            // do nothing
          });
      },
    }),
    createOrganization: build.mutation<string, TCreateOrganizationUI>({
      query(body) {
        return {
          url: `${baseUrl}`,
          method: 'POST',
          body: createOrganizationConvertor.toDb(body),
        };
      },
      invalidatesTags: ['organizationsIdentity', 'organizations'],
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(elcsApi.util.invalidateTags(['elc-places-identity']));
          })
          .catch(() => {
            // do nothing
          });
      },
    }),
    updateOrganization: build.mutation<string, TUpdateOrganizationUI>({
      query(body) {
        return {
          url: `${baseUrl}`,
          method: 'PUT',
          body: updateOrganizationConverter.toDb(body),
        };
      },
      invalidatesTags: ['organizationsIdentity', 'organizations'],
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(workshopsApi.util.invalidateTags(['workshop']));
            dispatch(elcsApi.util.invalidateTags(['elc-places-identity']));
            dispatch(elcsApi.util.invalidateTags(['elc-places']));
          })
          .catch(() => {
            // do nothing
          });
      },
    }),
    getOrganizationsList: build.query<
      TPageableDataWithContentUI<TOrganizationDataUI[]>,
      TTableFilters<EOrganizationFilterParams>
    >({
      query({
        page,
        pageSize,
        sortColumn: { sortDirection, sortField },
        filters,
      }) {
        if (sortField === EOrganizationFilterParams.NAME_CONTAINS) {
          sortField = EOrganizationSortParams.ORGANIZATION_NAME;
        }

        return {
          url: `${baseUrl}`,
          method: 'GET',
          params: {
            page,
            size: pageSize,
            ...(sortField && sortDirection && { sortField, sortDirection }),
            ...(filters && { ...organizationFilterConverter(filters) }),
          },
        };
      },
      providesTags: ['organizations'],
      transformResponse: (
        data: TPageableDataWithContentDB<TOrganizationDataDB[]>,
      ) => {
        return {
          ...pagableDataConverter.fromDb(data),
          content: (data.content || []).map(workshop =>
            organizationGetConverter.fromDb(workshop),
          ),
        };
      },
    }),
    getOrganizationById: build.query<TOrganizationByIdUI, { id: string }>({
      query({ id }) {
        return {
          url: getOrganizationByIdUrl(id),
          method: 'GET',
        };
      },
      providesTags: ['organization'],
      transformResponse: (response: TOrganizationByIdDB) =>
        response && getOrganizationByIdConverter.fromDb(response),
    }),
    getOrganizationDetailCategories: build.query<
      string[],
      { organizationId: string }
    >({
      query({ organizationId }) {
        return {
          url: `${baseUrl}/${EOrganizationsUrls.NOTES}/${EOrganizationsUrls.AVAILABLE_CATEGORIES}`,
          method: 'GET',
          params: { organizationId },
        };
      },
      providesTags: ['organization_available_categories'],
    }),
    createOrganizationNote: build.mutation<string, TCreateOrganizationNoteUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EOrganizationsUrls.NOTES}`,
          method: 'POST',
          body: createOrganizationNoteConvertor.toDb(body),
        };
      },
      invalidatesTags: ['organization', 'organization_available_categories'],
    }),
    updateOrganizationNote: build.mutation<string, TUpdateOrganizationNoteUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EOrganizationsUrls.NOTES}`,
          method: 'PATCH',
          body: updateOrganizationNoteConvertor.toDb(body),
        };
      },
      invalidatesTags: ['organization'],
    }),
    deleteOrganizationNote: build.mutation<void, TDeleteOrganizationNoteUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EOrganizationsUrls.NOTES}`,
          method: 'DELETE',
          body: deleteOrganizationNoteConvertor.toDb(body),
        };
      },
      invalidatesTags: ['organization', 'organization_available_categories'],
    }),
    updateOrganizationName: build.mutation<void, TUpdateOrganizationFieldUI>({
      query(body) {
        return {
          url: updateOrganizationNameUrl,
          method: 'PATCH',
          body: updateOrganizationNameConverter.toDb(body),
        };
      },
      invalidatesTags: [
        'organization',
        'organizations',
        'organizationsIdentity',
      ],
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(elcsApi.util.invalidateTags(['elc-places']));
            dispatch(elcsApi.util.invalidateTags(['elc-places-identity']));
          })
          .catch(() => {
            // do nothing
          });
      },
    }),
    updateOrganizationAddress: build.mutation<
      void,
      TUpdateOrganizationAddressUI
    >({
      query(body) {
        return {
          url: updateOrganizationAddressUrl,
          method: 'PATCH',
          body: updateOrganizationAddressConverter.toDb(body),
        };
      },
      invalidatesTags: ['organization', 'organizations', 'organizationsCity'],
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(elcsApi.util.invalidateTags(['elc-places']));
          })
          .catch(() => {
            // do nothing
          });
      },
    }),
    updateOrganizationAdditionalAddress: build.mutation<
      void,
      TUpdateOrganizationAdditionalAddressUI
    >({
      query(body) {
        return {
          url: `${baseUrl}/${EOrganizationsUrls.ADDITIONAL_ADDRESS}`,
          method: 'PATCH',
          body: updateOrganizationAdditionalAddressConverter.toDb(body),
        };
      },
      invalidatesTags: ['organization'],
    }),
    updateOrganizationEmail: build.mutation<void, TUpdateOrganizationFieldUI>({
      query(body) {
        return {
          url: updateOrganizationEmailUrl,
          method: 'PATCH',
          body: updateOrganizationEmailConverter.toDb(body),
        };
      },
      invalidatesTags: ['organization'],
    }),
    updateOrganizationPhone: build.mutation<void, TUpdateOrganizationFieldUI>({
      query(body) {
        return {
          url: updateOrganizationPhoneUrl,
          method: 'PATCH',
          body: updateOrganizationPhoneConverter.toDb(body),
        };
      },
      invalidatesTags: ['organization'],
    }),

    getWorkshopAppUrlByOrganization: build.query<string, { id: string }>({
      query({ id }) {
        return {
          url: `${baseUrl}/${id}/${EOrganizationsUrls.URL}`,
          method: 'GET',
        };
      },
    }),
    addAdditionalEmail: build.mutation<string, TAddAdditionalEmailUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EOrganizationsUrls.ADDITIONAL_EMAIL}`,
          method: 'POST',
          body: addAdditionalEmailConverter.toDb(body),
        };
      },
      invalidatesTags: ['organization'],
    }),
    addAdditionalPhone: build.mutation<string, TAddAdditionalPhoneUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EOrganizationsUrls.ADDITIONAL_PHONE}`,
          method: 'POST',
          body: addAdditionalPhoneConverter.toDb(body),
        };
      },
      invalidatesTags: ['organization'],
    }),
    updateAdditionalPhone: build.mutation<void, TUpdateAdditionalPhoneUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EOrganizationsUrls.ADDITIONAL_PHONE}`,
          method: 'PATCH',
          body: updateAdditionalPhoneConverter.toDb(body),
        };
      },
      invalidatesTags: ['organization'],
    }),
    updateAdditionalEmail: build.mutation<void, TUpdateAdditionalEmailUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EOrganizationsUrls.ADDITIONAL_EMAIL}`,
          method: 'PATCH',
          body: updateAdditionalEmailConverter.toDb(body),
        };
      },
      invalidatesTags: ['organization'],
    }),
  }),
});

export const {
  useCreateOrganizationNoteMutation,
  useUpdateOrganizationNoteMutation,
  useDeleteOrganizationNoteMutation,
  useGetOrganizationDetailCategoriesQuery,
  useGetOrganizationsIdentityQuery,
  useDeleteOrganizationMutation,
  useGetOrganizationsCitiesQuery,
  useCreateOrganizationMutation,
  useUpdateOrganizationMutation,
  useGetOrganizationsListQuery,
  useGetOrganizationByIdQuery,
  useUpdateOrganizationNameMutation,
  useUpdateOrganizationAddressMutation,
  useUpdateOrganizationAdditionalAddressMutation,
  useUpdateOrganizationEmailMutation,
  useUpdateOrganizationPhoneMutation,
  useLazyGetWorkshopAppUrlByOrganizationQuery,
  useAddAdditionalEmailMutation,
  useAddAdditionalPhoneMutation,
  useUpdateAdditionalPhoneMutation,
  useUpdateAdditionalEmailMutation,
} = organizationsApi;
