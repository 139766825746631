import {
  TAddressTypesDB,
  TAddressTypesUI,
  TAllowedOperationsValueDB,
  TAllowedOperationsValueUI,
  TAddressShortDataDB,
  TAddressShortDataUI,
  TOption,
  TShortAddressWithAllowOperationsDB,
  TShortAddressWithAllowOperationsUI,
} from './generic.types';
import { TTagDB, TTagUI } from './tags.types';

export enum EOrganizationFilterParams {
  CITIES = 'cities',
  NAME_CONTAINS = 'nameContains',
  TAGS = 'tagIds',
}

export enum EOrganizationSortParams {
  CREATION_DATE = 'CREATION_DATE',
  ORGANIZATION_NAME = 'ORGANIZATION_NAME',
}

export type TOrganizationsIdentityUI = TOption;

export type TOrganizationsIdentityDB = { id: string; name: string };

export type TCreateOrganizationNoteUI = {
  organizationId: string;
  category: string;
  note: string;
};

export type TCreateOrganizationNoteDB = {
  organizationId: string;
  category: string;
  note: string;
};

export type TUpdateOrganizationNoteUI = {
  organizationId: string;
  organizationNoteId: string;
  note: string;
};

export type TUpdateOrganizationNoteDB = {
  organizationId: string;
  organizationNoteId: string;
  note: string;
};

export type TDeleteOrganizationNoteUI = {
  organizationId: string;
  organizationNoteId: string;
};

export type TDeleteOrganizationNoteDB = {
  organizationId: string;
  organizationNoteId: string;
};

export type TCreateOrganizationUI = {
  name: string;
  address: TAddressTypesUI;
};

export type TCreateOrganizationDB = {
  id: string;
};

export type TUpdateOrganizationDB = {
  organizationId: string;
  name: string;
  address: TAddressTypesUI;
};

export type TUpdateOrganizationUI = {
  organizationId: string;
  name: string;
  address: TAddressTypesUI;
};

export type TDeleteOrganizationUI = {
  organizationId: string;
};

export type TDeleteOrganizationDB = {
  organizationId: string;
};

export type TOrganizationDataUI = {
  createdDate: string;
  name: string;
  organizationId: string;
  address?: TAddressShortDataUI;
  tags?: TTagUI[];
};
export type TOrganizationDataDB = {
  createdDate: string;
  name: string;
  organizationId: string;
  address: TAddressShortDataDB;
  tags?: TTagDB[];
};

export type TOrganizationNotesUI = {
  category: string;
  id: string;
  note: string;
};

export type TOrganizationNotesDB = {
  category: string;
  id: string;
  note: string;
};

export type TAdditionalEmailsUI = {
  additionalEmailId: string;
  email: string;
};

export type TAdditionalEmailsDB = {
  additionalEmailId: string;
  email: string;
};

export type TAdditionalPhoneUI = {
  additionalPhoneId: string;
  phone?: string | null;
};

export type TAdditionalPhoneDB = {
  additionalPhoneId: string;
  phone: string;
};

export type TPeopleOrganizationsUI = {
  fullName: string;
  userId: string;
  title: TAllowedOperationsValueUI<string>;
  userOrganizationId: string;
};

export type TPeopleOrganizationsDB = {
  fullName: string;
  userId: string;
  title: TAllowedOperationsValueDB<string>;
  userOrganizationId: string;
};

export type TOrganizationByIdDB = {
  additionalAddress: TShortAddressWithAllowOperationsDB;
  additionalEmails: TAllowedOperationsValueDB<TAdditionalEmailsDB[]>;
  additionalPhones: TAllowedOperationsValueDB<TAdditionalPhoneDB[]>;
  address: TShortAddressWithAllowOperationsDB;
  allowedDelete: boolean;
  email?: TAllowedOperationsValueDB<string>;
  id: string;
  isAddressUpdated: boolean;
  name: TAllowedOperationsValueDB<string>;
  organizationNotes?: TAllowedOperationsValueDB<TOrganizationNotesDB[]>;
  phone?: TAllowedOperationsValueDB<string>;
  userOrganizations: TAllowedOperationsValueDB<TPeopleOrganizationsDB[]>;
};

export type TOrganizationByIdUI = {
  additionalAddress: TAllowedOperationsValueUI<string>;
  additionalEmails: TAllowedOperationsValueUI<TAdditionalEmailsUI[]>;
  additionalPhones: TAllowedOperationsValueUI<TAdditionalPhoneUI[]>;
  address: TShortAddressWithAllowOperationsUI;
  allowedDelete: boolean;
  email?: TAllowedOperationsValueUI<string>;
  id: string;
  isAddressUpdated: boolean;
  name: TAllowedOperationsValueUI<string>;
  organizationNotes?: TAllowedOperationsValueUI<TOrganizationNotesUI[]>;
  phone?: TAllowedOperationsValueUI<string>;
  userOrganizations: TAllowedOperationsValueUI<TPeopleOrganizationsUI[]>;
};

export type TUpdateOrganizationAddressUI = {
  id: string;
  address: TAddressTypesUI;
};
export type TUpdateOrganizationAddressDB = {
  organizationId: string;
  address: TAddressTypesDB;
};

export type TUpdateOrganizationAdditionalAddressUI = {
  id: string;
  additionalAddress: string | null;
};
export type TUpdateOrganizationAdditionalAddressDB = {
  organizationId: string;
  additionalAddress: string | null;
};

export type TUpdateOrganizationFieldUI = {
  id: string;
  value: string;
};
export type TUpdateOrganizationNameDB = {
  organizationId: string;
  name: string;
};
export type TUpdateOrganizationEmailDB = {
  organizationId: string;
  email: string | null;
};
export type TUpdateOrganizationPhoneDB = {
  organizationId: string;
  phone: string | null;
};

export type TAddAdditionalEmailUI = {
  organizationId: string;
  email: string;
};
export type TAddAdditionalEmailDB = {
  organizationId: string;
  email: string;
};

export type TAddAdditionalPhoneUI = {
  organizationId: string;
  phone: string;
};
export type TAddAdditionalPhoneDB = {
  organizationId: string;
  phone: string;
};

export type TUpdateAdditionalPhoneUI = {
  organizationId: string;
  phone: string;
  additionalPhoneId: string;
};
export type TUpdateAdditionalPhoneDB = {
  organizationId: string;
  phone: string;
  additionalPhoneId: string;
};

export type TUpdateAdditionalEmailUI = {
  organizationId: string;
  email: string;
  additionalEmailId: string;
};
export type TUpdateAdditionalEmailDB = {
  organizationId: string;
  email: string;
  additionalEmailId: string;
};
