import {
  ENotificationType,
  Modal,
  TRtkErrorType,
  useForm,
  useShowToastNotification,
  useTranslation,
} from '@packages/utils';
import { useCreateNoteMutation } from 'redux/apiSlice/elcs.slice';
import {
  TAddDetailsModalProps,
  TCreateDetailFormProps,
} from './AddDetailsModal.types';
import AddDetailsForm from './AddDetailsForm';

const AddDetailsModal = ({
  elcId,
  open,
  setOpen,
  categories,
}: TAddDetailsModalProps) => {
  const { form } = useForm<TCreateDetailFormProps>();
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.details-tab.details-section.action.add.modal',
  });
  const [createNote, { isLoading }] = useCreateNoteMutation();
  const { showToastNotification } = useShowToastNotification();

  const onCloseModal = () => {
    setOpen(false);
    form.resetFields();
  };

  const handleAddBtnClick = async () => {
    try {
      const values = await form.validateFields();
      await createNote({ ...values, elcId }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
      onCloseModal();
    } catch (err) {
      showToastNotification({
        message: (err as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  return (
    <Modal
      destroyOnClose
      title={t('TITLE')}
      open={open}
      centeredPosition
      onCancel={onCloseModal}
      okText={t('SUBMIT_BTN')}
      onOk={handleAddBtnClick}
      confirmLoading={isLoading}
    >
      <AddDetailsForm form={form} categories={categories} />
    </Modal>
  );
};

export default AddDetailsModal;
