import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from './useQueryParam';

type TUseSaveTabsInUrlProps = {
  defaultTab: string;
  stateInUrl?: any;
  isSubTab?: boolean;
};

const useKeepTabsInUrl = ({
  defaultTab,
  stateInUrl,
  isSubTab,
}: TUseSaveTabsInUrlProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = useQuery();
  const keyInUrl = isSubTab ? 'subTab' : 'tab';

  const tabInUrl = query.get(keyInUrl) || defaultTab;
  const newState = {
    ...location.state, // Preserve the existing state
    ...(stateInUrl && { ...stateInUrl }), // Add new state if it exists
  };

  const onTabChange = (key: string) => {
    const currentUrl = location.pathname + location.search;

    if (location.search.includes(keyInUrl)) {
      navigate(currentUrl.replace(tabInUrl, key), {
        replace: true,
        state: newState,
      });
    } else {
      query.append(keyInUrl, key);
      navigate(`${location.pathname}?${query.toString()}`, {
        state: newState,
      });
    }
  };

  const removeTab = () => {
    query.delete(keyInUrl);
    navigate(`${location.pathname}?${query.toString()}`, {
      state: newState,
      replace: true,
    });
  };

  return { tabInUrl, onTabChange, removeTab };
};

export default useKeepTabsInUrl;
