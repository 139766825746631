export enum EElcsUrls {
  ELCS = 'elcs',
  MYELCS = 'my-elcs',
  NOTES = 'notes',
  AVAILABLE_CATEGORIES = 'available-categories',
  LEAD = 'lead',
  LEAD_AGENCY = 'lead-agency',
  CO_LEAD_AGENCY = 'co-lead-agency',
  FISCAL_AGENT = 'fiscal-agent',
  FIRST_PROJECT_MANAGER = 'first-project-manager',
  SECOND_PROJECT_MANAGER = 'second-project-manager',
  BOUNDARIES = 'boundaries',
  CONTACTS = 'contacts',
  EXPORT = 'export',
  USER_IDENTITY = 'user-identity',
  IDENTITY = 'identity',
  ELAC_MEMBER = 'elac-member',
  PLACES = 'places',
  MEETINGS = 'meetings',
  TOPICS = 'topics',
  CONTEXT = 'context',
  PROGRESS = 'progress',
  ROAD_BLOCK = 'road-block',
  ATTENDEES = 'attendees',
  ATTENDANCE_STATUS = 'attendance-status',
  TA_TIMES = 'ta-times',
  ACTION_ITEMS = 'action-items',
  STORAGE = 'storage',
  RESOURCES = 'resources',
  THINGS_TO_SHARE = 'things-to-share',
  CONTENT = 'content',
}
