import {
  Col,
  ENotificationType,
  Row,
  TRtkErrorType,
  useShowToastNotification,
  useTranslation,
} from '@packages/utils';
import EditableInput from 'components/Base/EditableFields/EditableInput';
import { InplaceEditLabels } from 'components/Base/EditableFields/components/InplaceEditLabel';
import { useUpdatePeopleFirstNameMutation } from 'redux/apiSlice/people.slice';
import { EIconNames } from 'components/Base/Icon';
import { TFirstNameProps } from './FirstName.types';

const FirstName = ({ userId, data }: TFirstNameProps) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.people.details.details-tab.main-contact.first-name',
  });
  const { showToastNotification } = useShowToastNotification();
  const [editFirstName, { isLoading, isError }] =
    useUpdatePeopleFirstNameMutation();

  const onEdit = async (value: string) => {
    try {
      await editFirstName({
        userId,
        firstName: value,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (e) {
      showToastNotification({
        message: (e as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  return (
    <Row align="middle" wrap={false} className="w-100 mb-1">
      <Col>
        <InplaceEditLabels
          label={t('INPLACE_EDIT_LABEL')}
          icon={EIconNames.PROFILE}
        />
      </Col>
      <Col flex="auto">
        <EditableInput
          onEdit={onEdit}
          title={data.value}
          isError={isError}
          isLoading={isLoading}
          fitContent={false}
          validateTrigger="onSubmit"
          readonly={data?.allowedOperations.readonly}
          canRemove={data?.allowedOperations.canRemove}
          emptyText={t('EMPTY_TEXT')}
          placeholder={t('PLACEHOLDER')}
          backIcon
        />
      </Col>
    </Row>
  );
};

export default FirstName;
