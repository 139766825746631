import { useCheckPermission } from 'hooks/useCheckPermission';
import WorkShopDetails from 'pages/private/Workshop/components/WorkShopDetails';
import WorkshopList from 'pages/private/Workshop/components/WorkshopList';
import { RoutesPaths, SubRouteType } from 'routes/Routes.types';

const useGetWorkshopSubRoutesData = () => {
  const workshopSubRoutes: SubRouteType[] = [
    {
      component: WorkshopList,
      path: RoutesPaths.WORKSHOP,
      show: false,
      showByPermissions: useCheckPermission(['WORKSHOP_VIEW']),
    },
    {
      component: WorkShopDetails,
      path: RoutesPaths.WORKSHOP_DETAILS,
      show: false,
      showByPermissions: useCheckPermission(['WORKSHOP_VIEW']),
    },
  ];
  return { workshopSubRoutes };
};

export default useGetWorkshopSubRoutesData;
