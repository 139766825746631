import {
  Button,
  ENotificationType,
  TRtkErrorType,
  useShowToastNotification,
  useTranslation,
} from '@packages/utils';
import { EIconNames, Icon } from 'components/Base/Icon';
import { useLazyExportContactsCSVQuery } from 'redux/apiSlice/elcs.slice';
import { TExportELACMembersProps } from './ExportELACMembers.types';

const ExportELACMembers = ({
  elcId,
  selectedFilters,
}: TExportELACMembersProps) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.elac-members-tab.list.actions.export',
  });
  const { showToastNotification } = useShowToastNotification();

  const [exportContacts, { isLoading }] = useLazyExportContactsCSVQuery();

  const handleExport = async () => {
    try {
      await exportContacts({ elcId, filters: selectedFilters }).unwrap();
    } catch (err) {
      showToastNotification({
        message: (err as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  return (
    <Button
      onClick={handleExport}
      color="primary"
      type="default"
      icon={<Icon icon={EIconNames.DOWNLOAD} alt="filter icon" />}
      loading={isLoading}
      disabled={isLoading}
    >
      {t('EXPORT_BTN')}
    </Button>
  );
};

export default ExportELACMembers;
