import { useTranslation } from '@packages/utils';
import { EmptyView } from 'components/Base/EmptyView';
import { EIconNames } from 'components/Base/Icon';
import { TCreateSessionFormEmptyViewProps } from './CreateSessionFormEmptyView.types';

const CreateSessionEmptyView = ({
  setShowForm,
  canAdd,
}: TCreateSessionFormEmptyViewProps) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.workshops.details.sessions.empty-view',
  });
  return (
    <EmptyView
      emptyText={t('TITLE')}
      icon={EIconNames.EMPTY_DATA}
      alt="empty image view"
      {...(canAdd && { btnText: t('CREATE_SESSIONS_BTN') })}
      onClick={() => setShowForm(true)}
    />
  );
};

export default CreateSessionEmptyView;
