import { TMenuProps } from '@idaho-aeyc/ui-kit';
import ActionsButtonDetailsPage from 'components/Shared/ActionsButtonDetailsPage';
import { EOrganizationActions, TActionsProps } from './Actions.types';
import DeleteAction from './DeleteAction';
import DownloadQrAction from './DownloadQrAction';
import CopyUrlAction from './CopyUrlAction';

const Actions = ({ data, id }: TActionsProps) => {
  if (!data) return null;
  const items: TMenuProps['items'] = [
    {
      label: <DeleteAction id={id} />,
      key: EOrganizationActions.DELETE,
    },
    {
      label: <DownloadQrAction id={id} />,
      key: EOrganizationActions.DOWNLOAD_QR,
    },
    {
      label: <CopyUrlAction id={id} />,
      key: EOrganizationActions.COPY_URL,
    },
  ];

  return <ActionsButtonDetailsPage items={items} />;
};

export default Actions;
