import React from 'react';
import { useTranslation } from '@packages/utils';
import DropdownItems from '../DropdownItems';

const CopyUrlAction = () => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.workshops.details.actions.copy-url',
  });

  return <DropdownItems label={t('COPY_URL_BTN')} />;
};

export default CopyUrlAction;
