import { TConvertor, TFetchConverter } from 'data/types/converter.types';
import {
  EAgeGroup,
  EAllowedOperations,
  EWorkshopLanguage,
  TAddressShortDataDB,
  TAddressShortDataUI,
  TAddressTypesDB,
  TAddressTypesUI,
  TAllowedOperationsDB,
  TAllowedOperationsUI,
  TAllowedOperationsValueDB,
  TAllowedOperationsValueUI,
  TExportCsvDB,
  TExportCsvUI,
  TFile,
  TLanguageOptionUI,
  TLocationHostFilterDataDB,
  TOption,
  TPageableDataDB,
  TPageableDataUI,
  TShortAddressWithAllowOperationsDB,
  TShortAddressWithAllowOperationsUI,
  TTableFilterDataUI,
} from 'data/types/generic.types';
import {
  TAgeGroupAllowedOperationsValueDB,
  TAgeGroupAllowedOperationsValueUI,
  TLanguageAllowedOperationsValueDB,
  TLanguageAllowedOperationsValueUI,
  TPossibleAgeGroupUI,
} from 'data/types/workshops.types';
import {
  convertStateToUpperCase,
  dateConverter,
  dateShortenFormat,
  dateTimeFormatUI,
  formatPhoneNumber,
} from 'helpers/utils';
import { TColumnFilterItem } from 'components/Base/Table/Table.types';
import { b64toBlob } from '../../redux/services/fileServices';

export const pagableDataConverter: TFetchConverter<
  TPageableDataUI,
  TPageableDataDB
> = {
  fromDb: data => {
    return {
      totalElements: data.totalElements,
      totalPages: data.totalPages,
      number: data.number,
      size: data.size,
    };
  },
};

export const allowOperationsConverter: TFetchConverter<
  TAllowedOperationsUI,
  TAllowedOperationsDB
> = {
  fromDb: data => {
    return {
      readonly: !data?.includes(EAllowedOperations.UPDATE),
      canRemove: !!data?.includes(EAllowedOperations.REMOVE),
      canAdd: !!data?.includes(EAllowedOperations.ADD),
    };
  },
};

export const ageGroupDataConverter: TFetchConverter<
  TPossibleAgeGroupUI,
  EAgeGroup
> = {
  fromDb: item => {
    return {
      value: item,
      label: item,
    };
  },
};

export const allowOperationsAgeGroupValueConverter: TFetchConverter<
  TAgeGroupAllowedOperationsValueUI,
  TAgeGroupAllowedOperationsValueDB
> = {
  fromDb: data => {
    return {
      value: ageGroupDataConverter.fromDb(data.value),
      allowedOperations: allowOperationsConverter.fromDb(
        data.allowedOperations,
      ),
    };
  },
};

export const languageDataConverter: TFetchConverter<
  TLanguageOptionUI,
  EWorkshopLanguage
> = {
  fromDb: item => {
    return {
      value: item,
      label: item,
    };
  },
};

export const allowOperationsLanguageValueConverter: TFetchConverter<
  TLanguageAllowedOperationsValueUI,
  TLanguageAllowedOperationsValueDB
> = {
  fromDb: data => {
    return {
      value: languageDataConverter.fromDb(data.value),
      allowedOperations: allowOperationsConverter.fromDb(
        data.allowedOperations,
      ),
    };
  },
};

export const allowOperationsWithStringValueConverter: TFetchConverter<
  TAllowedOperationsValueUI<string>,
  TAllowedOperationsValueDB<string>
> = {
  fromDb: data => {
    return {
      value: data.value,
      allowedOperations: allowOperationsConverter.fromDb(
        data.allowedOperations,
      ),
    };
  },
};
export const allowOperationsWithBooleanValueConverter: TFetchConverter<
  TAllowedOperationsValueUI<boolean>,
  TAllowedOperationsValueDB<boolean>
> = {
  fromDb: data => {
    return {
      value: data.value,
      allowedOperations: allowOperationsConverter.fromDb(
        data.allowedOperations,
      ),
    };
  },
};

export const allowOperationsWithDateValueConverter: TFetchConverter<
  TAllowedOperationsValueUI<string>,
  TAllowedOperationsValueDB<string>
> = {
  fromDb: data => {
    return {
      value: data.value && dateConverter(data.value, dateShortenFormat),
      allowedOperations: allowOperationsConverter.fromDb(
        data.allowedOperations,
      ),
    };
  },
};
export const allowOperationsWithDateTimeValueConverter: TFetchConverter<
  TAllowedOperationsValueUI<string>,
  TAllowedOperationsValueDB<string>
> = {
  fromDb: data => {
    return {
      value: data.value && dateConverter(data.value, dateTimeFormatUI),
      allowedOperations: allowOperationsConverter.fromDb(
        data.allowedOperations,
      ),
    };
  },
};

export const allowOperationsShortAddressWithConverter: TFetchConverter<
  TShortAddressWithAllowOperationsUI,
  TShortAddressWithAllowOperationsDB
> = {
  fromDb: data => ({
    id: data.id,
    formattedAddress: data.formattedAddress,
    allowedOperations: allowOperationsConverter.fromDb(data.allowedOperations),
  }),
};

export const addressShortDataConverter: TFetchConverter<
  TAddressShortDataUI,
  TAddressShortDataDB
> = {
  fromDb: data => {
    if (!data) return null;
    return {
      id: data.id,
      formattedAddress: data.formattedAddress,
    };
  },
};

export const addressConvertor: TConvertor<TAddressTypesUI, TAddressTypesDB> = {
  fromDb: data => ({
    placeId: data.placeId,
    formattedAddress: data.formattedAddress,
    country: data.country,
    state: data.state && convertStateToUpperCase(data.state),
    city: data.city,
    streetNumber: data.streetNumber,
    street: data.street,
    zipCode: data.zipCode,
    latitude: data.latitude,
    longitude: data.longitude,
  }),
  toDb: data => ({
    placeId: data.placeId,
    formattedAddress: data.formattedAddress,
    country: data.country,
    state: data.state && convertStateToUpperCase(data.state),
    city: data.city,
    streetNumber: data.streetNumber,
    street: data.street,
    zipCode: data.zipCode,
    latitude: data.latitude,
    longitude: data.longitude,
  }),
};

export const phoneNumberConverter: TConvertor<
  string | null | undefined,
  string | null
> = {
  toDb: phoneNumber => {
    if (phoneNumber) {
      // Remove any non-numeric characters from the input
      const numericValue = phoneNumber.replace(/\D/g, '');

      // Add the +1 prefix
      const formattedPhoneNumber = `+1${numericValue}`;

      return formattedPhoneNumber;
    }

    return null;
  },
  fromDb: phoneNumber => formatPhoneNumber(phoneNumber),
};

export const exportCsvConverter: (
  fileType: string,
) => TFetchConverter<TExportCsvUI, TExportCsvDB> = (fileType: string) => ({
  fromDb: ({ content, fileName }) => {
    const file = b64toBlob(content, fileType);
    return {
      content: file,
      fileName,
    };
  },
});

export const allowOperationsWithPhoneNumberConverter: TFetchConverter<
  TAllowedOperationsValueUI<string>,
  TAllowedOperationsValueDB<string>
> = {
  fromDb: data => {
    return {
      value: phoneNumberConverter.fromDb(data.value || '') || '',
      allowedOperations: allowOperationsConverter.fromDb(
        data.allowedOperations,
      ),
    };
  },
};

export const citiesOptionsConverter: TFetchConverter<TOption[], string[]> = {
  fromDb: data => (data || []).map(city => ({ label: city, value: city })),
};

export const citiesFiltersConverter: TFetchConverter<
  TColumnFilterItem[],
  string[]
> = {
  fromDb: data => (data || []).map(city => ({ text: city, value: city })),
};

export const locationHostFilterDataConverter: TFetchConverter<
  TTableFilterDataUI[],
  TLocationHostFilterDataDB[]
> = {
  fromDb: data =>
    (data || []).map(item => ({ text: item.name, value: item.id })),
};

export const fileConverter: TFetchConverter<TFile, TFile> = {
  fromDb: data => {
    return {
      name: data.name,
      url: data.url,
    };
  },
};
