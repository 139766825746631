import { FC } from 'react';
import { useAppSelector } from 'redux/store';
import { useTheme } from 'styled-components';
import { useTranslation } from '@packages/utils';
import { Col } from 'components/Base/Grid';
import { TStandalonePartProps } from './StandalonePart.types';
import { SStandaloneColumnWrapper } from './StandalonePart.styles';
import SessionPartCard from '../SessionPartCard';
import {
  enumAgeGroupKeyPrefix,
  flyerSessionsSectionKeyPrefix,
} from '../SeriesPart/Series.data';

const StandalonePart: FC<TStandalonePartProps> = ({ standaloneData, date }) => {
  const theme = useTheme();
  const flyerLanguage = useAppSelector(state => state.appSlice.flyerLanguage);
  const { t } = useTranslation({
    ...(flyerLanguage && { lng: flyerLanguage }),
  });
  return (
    <>
      {standaloneData.map(ageGroup => {
        return (
          <Col key={ageGroup}>
            <SStandaloneColumnWrapper gutter={[0, 12]}>
              <Col>
                <SessionPartCard
                  color={theme.colorGreenFlyer}
                  title={t(`${flyerSessionsSectionKeyPrefix}.AGE_GROUP_LABEL`, {
                    placeholder: t(`${enumAgeGroupKeyPrefix}.${ageGroup}`),
                  })}
                />
              </Col>
              {date && (
                <Col>
                  <SessionPartCard
                    color={theme.colorPurpleBright}
                    title={date}
                  />
                </Col>
              )}
            </SStandaloneColumnWrapper>
          </Col>
        );
      })}
    </>
  );
};

export default StandalonePart;
