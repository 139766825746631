import { styled } from 'styled-components';
import { Card, Row, Col } from '@packages/utils';
import { Image } from 'components/Base/Image';

export const SSelectableImageWrapper = styled(Col)`
  max-width: 120px;
  margin-right: 12px;
  &.selected {
    .selected-wrapper {
      display: flex;
    }
  }
`;

export const SImageWrapper = styled(Card)`
  width: 120px;
  height: 120px;
`;

export const SSelectedPhotoHoverWrapper = styled(Row)`
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: ${({ theme }) => theme.borderRadiusL}px;
  display: none;
  cursor: pointer;
`;

export const SImage = styled(Image)``;
