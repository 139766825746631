import {
  Col,
  Form,
  FormItem,
  Row,
  useForm,
  useShowToastNotification,
  useTranslation,
  TRtkErrorType,
  ENotificationType,
} from '@packages/utils';
import Checkbox from 'components/Base/Checkbox';
import EditableSelect from 'components/Base/EditableFields/EditableSelect';
import { InplaceEditLabels } from 'components/Base/EditableFields/components/InplaceEditLabel';
import { EIconNames } from 'components/Base/Icon';
import { useUpdateAttendanceStatusMutation } from 'redux/apiSlice/elcs.slice';
import { avatarColorMapper } from 'pages/private/ELCs/ELCs.data';
import ElcItemPopover from 'pages/private/ELCs/components/ElcPopover';
import { TFiscalAgentProps } from './FiscalAgent.types';

const FiscalAgent = ({ data, elcId, meetingId }: TFiscalAgentProps) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.meetings-tab.details.attendees.fiscal-agent',
  });
  const { showToastNotification } = useShowToastNotification();
  const { form } = useForm<{ isAttended: boolean }>();

  const [updateAttendanceStatus, { isLoading }] =
    useUpdateAttendanceStatusMutation();

  const onEdit = () => {
    // do nothing
  };

  const handleCheckboxChange = async (e: { target: { checked: boolean } }) => {
    try {
      await updateAttendanceStatus({
        attendeeId: data.attendeeId,
        elcId,
        meetingId,
        isAttended: e.target.checked,
      }).unwrap();
      form.setFieldsValue({ isAttended: e.target.checked });
    } catch (err) {
      showToastNotification({
        message: (err as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  return (
    <Row align="middle">
      <Col className="mr-4">
        <Form form={form} initialValues={{ isAttended: data?.isAttended }}>
          <FormItem className="mb-0" name="isAttended" valuePropName="checked">
            <Checkbox onChange={handleCheckboxChange} disabled={isLoading} />
          </FormItem>
        </Form>
      </Col>
      <Col>
        <InplaceEditLabels
          label={t('INPLACE_EDIT_LABEL')}
          icon={EIconNames.PROFILE}
        />
      </Col>
      <Col flex="auto">
        <EditableSelect
          title={{
            label: data.fullName,
            value: data.userId,
          }}
          fitContent={false}
          options={[]}
          onEdit={onEdit}
          emptyText={t('EMPTY_TEXT')}
          placeholder={t('PLACEHOLDER')}
          readonly
          canRemove={false}
          avatarColor={avatarColorMapper.lead}
          {...(data && {
            tooltip: (
              <ElcItemPopover
                fullName={data.fullName}
                email={data.email}
                phone={data.phone || ''}
              />
            ),
          })}
        />
      </Col>
    </Row>
  );
};

export default FiscalAgent;
