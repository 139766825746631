import { FC } from 'react';
import { useAppSelector } from 'redux/store';
import { useTheme } from 'styled-components';
import { Text } from 'components/Base/Typography';
import { Row, Col } from 'components/Base/Grid';
import { useTranslation } from '@packages/utils';
import { EWorkshopPartType } from 'data/types/workshops.types';
import { TSessionPartCardProps } from './SessionPartCard.types';
import {
  enumPartTypeKeyPrefix,
  flyerSessionsSectionKeyPrefix,
} from '../SeriesPart/Series.data';
import { SInfoItemWrapper } from '../../Flyers.styles';

const SessionPartCard: FC<TSessionPartCardProps> = ({
  color,
  title,
  part,
  sessionDate,
  isChildCareAndPreschool,
}) => {
  const theme = useTheme();
  const flyerLanguage = useAppSelector(state => state.appSlice.flyerLanguage);
  const { t } = useTranslation({
    ...(flyerLanguage && { lng: flyerLanguage }),
  });

  return (
    <SInfoItemWrapper>
      <Row
        wrap={false}
        align="middle"
        justify={part ? 'start' : 'center'}
        gutter={[6, 0]}
      >
        {part && (
          <Col>
            <Text
              fontSize={theme.fontSizeHeading2}
              fontWeight={theme.fontWeightHeavy}
              color={color}
            >
              {`${part} - `}
            </Text>
          </Col>
        )}
        <Col>
          <Text
            fontSize={theme.fontSizeHeading2}
            fontWeight={theme.fontWeightHeavy}
            color={color}
          >
            {title}
            {sessionDate ? ': ' : ''}
            {isChildCareAndPreschool &&
              part ===
                t(`${enumPartTypeKeyPrefix}.${EWorkshopPartType.PART_TWO}`) && (
                <>
                  <br />
                  {t(`${flyerSessionsSectionKeyPrefix}.PART_3_TITLE`)}
                  {sessionDate ? ': ' : ''}
                </>
              )}
          </Text>
        </Col>

        {sessionDate && (
          <Col>
            <Text
              color={theme.colorBlackDescription}
              fontWeight={theme.fontWeightRegular}
              fontSize={theme.fontSizeHeading2}
            >
              {sessionDate.value}
            </Text>
          </Col>
        )}
      </Row>
    </SInfoItemWrapper>
  );
};

export default SessionPartCard;
