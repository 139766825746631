import { FC, useState } from 'react';
import { useTranslation } from '@packages/utils';
import DetailsEmptyView from 'components/Base/DetailsEmptyView/DetailsEmptyView';
import AddQuestionModal from '../modals/AddQuestionModal/AddQuestionModal';
import { TRANSLATION_KEY } from '../QuestionTab.data';
import { TQuestionsEmptyViewProps } from '../QuestionTab.types';

const QuestionsEmptyView: FC<TQuestionsEmptyViewProps> = ({ workshopId }) => {
  const { t } = useTranslation({
    keyPrefix: `${TRANSLATION_KEY}.empty-view`,
  });
  const [openAddModal, setOpenAddModal] = useState(false);

  const handleAddButtonClick = () => {
    setOpenAddModal(true);
  };

  return (
    <>
      <DetailsEmptyView
        title={t('TITLE')}
        onClick={handleAddButtonClick}
        addBtnText={t('ADD_BTN')}
      />
      <AddQuestionModal
        open={openAddModal}
        setModalOpen={setOpenAddModal}
        workshopId={workshopId}
      />
    </>
  );
};

export default QuestionsEmptyView;
