import {
  TAllowedOperationsValueDB,
  TAllowedOperationsValueUI,
  TOption,
} from './generic.types';
import { TTagDB, TTagUI } from './tags.types';

export enum EPeoplesColumnKey {
  CREATION_DATE = 'creationDate',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  TAGS = 'tagIds',
}

export type TPeoplesDataUI = {
  createdDate: string;
  email: string;
  firstName: string;
  fullName: string;
  lastName: string;
  phone?: string | null;
  userId: string;
  locked: boolean;
  tags?: TTagUI[];
  title?: string;
};
export type TPeoplesDataDB = {
  createdDate: string;
  email: string;
  firstName: string;
  fullName: string;
  lastName: string;
  phone: string;
  userId: string;
  locked: boolean;
  tags?: TTagDB[];
  title?: string;
};

export type TPeopleCreateUI = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  title?: string;
};
export type TPeopleCreateDB = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  title?: string;
};

export type TPeopleUpdateUI = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  userId: string;
  title?: string;
};
export type TPeopleUpdateDB = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  userId: string;
  title?: string;
};

export type TPeopleGetUI = TOption;

export type TPeopleGetDB = {
  id: string;
  name: string;
};

export type TAdditionalEmailsUI = {
  additionalEmailId: string;
  email: string;
};

export type TAdditionalEmailsDB = {
  additionalEmailId: string;
  email: string;
};

export type TAdditionalPhoneUI = {
  additionalPhoneId: string;
  phone?: string | null;
};

export type TAdditionalPhoneDB = {
  additionalPhoneId: string;
  phone: string;
};

export type TPeopleOrganizationsUI = {
  organizationId: string;
  organizationName: string;
  title: TAllowedOperationsValueUI<string>;
  userOrganizationId: string;
};

export type TPeopleOrganizationsDB = {
  organizationId: string;
  organizationName: string;
  title: TAllowedOperationsValueDB<string>;
  userOrganizationId: string;
};

export type TPeopleByIdUI = {
  id: string;
  additionalEmails: TAllowedOperationsValueUI<TAdditionalEmailsUI[]>;
  additionalPhones: TAllowedOperationsValueUI<TAdditionalPhoneUI[]>;
  email: TAllowedOperationsValueUI<string>;
  firstName: TAllowedOperationsValueUI<string>;
  lastName: TAllowedOperationsValueUI<string>;
  phone: TAllowedOperationsValueUI<string>;
  title: TAllowedOperationsValueUI<string>;
  userOrganizations: TAllowedOperationsValueUI<TPeopleOrganizationsUI[]>;
  fullName: string;
};

export type TPeopleByIdDB = {
  id: string;
  additionalEmails: TAllowedOperationsValueDB<TAdditionalEmailsDB[]>;
  additionalPhones: TAllowedOperationsValueDB<TAdditionalPhoneDB[]>;
  email: TAllowedOperationsValueDB<string>;
  firstName: TAllowedOperationsValueDB<string>;
  lastName: TAllowedOperationsValueDB<string>;
  phone: TAllowedOperationsValueDB<string>;
  title: TAllowedOperationsValueDB<string>;
  userOrganizations: TAllowedOperationsValueDB<TPeopleOrganizationsDB[]>;
};

export type TUpdatePeopleFirstNameUI = {
  userId: string;
  firstName: string;
};
export type TUpdatePeopleFirstNameDB = {
  userId: string;
  firstName: string;
};

export type TUpdatePeopleLastNameUI = {
  userId: string;
  lastName: string;
};
export type TUpdatePeopleLastNameDB = {
  userId: string;
  lastName: string;
};

export type TUpdatePeopleTitleUI = {
  userId: string;
  title: string;
};
export type TUpdatePeopleLTitleDB = {
  userId: string;
  title: string;
};

export type TUpdatePeopleEmailUI = {
  userId: string;
  email: string;
};
export type TUpdatePeopleLEmailDB = {
  userId: string;
  email: string;
};

export type TUpdatePeopleMobileUI = {
  userId: string;
  phone: string;
};
export type TUpdatePeopleMobileDB = {
  userId: string;
  phone: string;
};

export type TAddAdditionalEmailUI = {
  userId: string;
  email: string;
};
export type TAddAdditionalEmailDB = {
  userId: string;
  email: string;
};

export type TAddAdditionalPhoneUI = {
  userId: string;
  phone: string;
};
export type TAddAdditionalPhoneDB = {
  userId: string;
  phone: string;
};

export type TAddPeopleOrganizationUI = {
  userId: string;
  title: string;
  organizationId: string;
};
export type TAddPeopleOrganizationDB = {
  userId: string;
  title: string;
  organizationId: string;
};

export type TUpdateAdditionalPhoneUI = {
  userId: string;
  phone: string;
  additionalPhoneId: string;
};
export type TUpdateAdditionalPhoneDB = {
  userId: string;
  phone: string;
  additionalPhoneId: string;
};

export type TUpdateAdditionalEmailUI = {
  userId: string;
  email: string;
  additionalEmailId: string;
};
export type TUpdateAdditionalEmailDB = {
  userId: string;
  email: string;
  additionalEmailId: string;
};

export type TDeletePeopleOrganizationUI = {
  userId: string;
  organizationId: string;
};

export type TDeletePeopleOrganizationDB = {
  userId: string;
  organizationId: string;
};

export type TUpdatePeopleOrganizationTitleUI = {
  userId: string;
  title: string;
  organizationId: string;
};
export type TUpdatePeopleOrganizationTitleDB = {
  userId: string;
  title: string;
  organizationId: string;
};
