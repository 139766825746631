import {
  citiesFiltersConverter,
  locationHostFilterDataConverter,
  pagableDataConverter,
} from 'data/convertors/general.converters';
import {
  workshopChildcareEditConverter,
  workshopFoodProvidedEditConverter,
  addWorkshopPhotoConverter,
  workshopCreateConverter,
  workshopDetailsConverter,
  workshopFilterConverter,
  workshopGalleryConverter,
  workshopGetConverter,
  workshopLocationEditConverter,
  workshopShipingAddressConverter,
  workshopSiteCoordinatorEditConverter,
  workshopClassCreateConverter,
  workshopClassAgeGroupEditConverter,
  classCapacityEditConverter,
  workshopClassLanguageEditConverter,
  workshopClassSessionDateTimeEditConverter,
  workshopClassSessionFacilitatorEditConverter,
  deleteWorkshopClassConverter,
  workshopDescriptionEditConverter,
  workshopHostEditConverter,
  duplicateConverter,
  workshopCapacityEditConverter,
  workshopMeetingLinkEditConverter,
  workshopChildCareConverter,
  workshopPartDateTimeEditConverter,
} from 'data/convertors/workshops.convertors';
import {
  TLocationHostFilterDataDB,
  TPageableDataWithContentDB,
  TPageableDataWithContentUI,
  TTableFilterDataUI,
  TTableFilters,
} from 'data/types/generic.types';
import {
  EWorkshopFilterParams,
  TWorkshopChildcareProvidedEditUI,
  TAddWorkshopPhotoUI,
  TWorkshopDataDB,
  TWorkshopDataUI,
  TWorkshopDetailsDataDB,
  TWorkshopDetailsDataUI,
  TWorkshopFoodProvidedEditUI,
  TWorkshopCreateUI,
  TWorkshopGalleryDB,
  TWorkshopGalleryUI,
  TWorkshopLocationEditUI,
  TWorkshopShippingAddressEditUI,
  TWorkshopSiteCoordinatorEditUI,
  TWorkshopClassDataUI,
  TWorkshopClassAgeGroupEditUI,
  TClassCapacityEditUI,
  TWorkshopClassDateTimeEditUI,
  TWorkshopClassFacilitatorEditUI,
  TDeleteWorkshopClassUI,
  TWorkshopClassLanguageEditUI,
  TWorkshopDescriptionEditUI,
  TWorkshopHostEditUI,
  TDuplicateWorkshopUI,
  TWorkshopCapacityEditUI,
  TWorkshopMeetingLinkEditUI,
  TWorkshopChildCareUI,
  TWorkshopChildCareDB,
  TUpdateCustomQuestionUi,
  TWorkshopRegistrantsUrl,
  TWorkshopPartDateTimeEditUI,
} from 'data/types/workshops.types';
import {
  EWorkshopsUrls,
  editMeetingLinkUrl,
  editSessionDateTimeUrl,
  workshopLocationUrl,
  editPartDateTimeUrl,
} from 'data/urls/workshops.url';
import { authSplitApi } from 'redux/helpers/slice.helpers';
import { TColumnFilterItem } from 'components/Base/Table/Table.types';
import { kitManagementApi } from './kitManagement.slice';

const baseUrl = `${EWorkshopsUrls.WORKSHOPS}`;

export const workshopsApi = authSplitApi('workshops', [
  'workshops',
  'workshop',
  'workshop-hosts',
  'workshop-cities',
  'workshop-gallery',
  'workshop-tags',
  'workshop-location',
  'workshops-childCare-count',
]).injectEndpoints({
  endpoints: build => ({
    createWorkshop: build.mutation<string, TWorkshopCreateUI>({
      query(body) {
        return {
          url: `${baseUrl}`,
          method: 'POST',
          body: workshopCreateConverter.toDb(body),
        };
      },
      invalidatesTags: [
        'workshops',
        'workshop-hosts',
        'workshop-cities',
        'workshop-location',
      ],
    }),
    createWorkshopClass: build.mutation<string, TWorkshopClassDataUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EWorkshopsUrls.CLASS}`,
          method: 'POST',
          body: workshopClassCreateConverter.toDb(body),
        };
      },
      invalidatesTags: ['workshops', 'workshop'],
    }),
    deleteWorkshopClass: build.mutation<void, TDeleteWorkshopClassUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EWorkshopsUrls.CLASS}`,
          method: 'DELETE',
          body: deleteWorkshopClassConverter.toDb(body),
        };
      },
      invalidatesTags: ['workshops', 'workshop'],
    }),
    // here
    getWorkshops: build.query<
      TPageableDataWithContentUI<TWorkshopDataUI[]>,
      TTableFilters<EWorkshopFilterParams>
    >({
      query({
        page,
        pageSize,
        sortColumn: { sortDirection, sortField },
        filters,
      }) {
        return {
          url: baseUrl,
          method: 'GET',
          params: {
            page,
            size: pageSize,
            ...(sortField && sortDirection && { sortField, sortDirection }),
            ...(filters && { ...workshopFilterConverter(filters) }),
          },
        };
      },
      providesTags: ['workshops'],
      transformResponse: (
        data: TPageableDataWithContentDB<TWorkshopDataDB[]>,
      ) => {
        return {
          ...pagableDataConverter.fromDb(data),
          content: (data.content || []).map(workshop =>
            workshopGetConverter.fromDb(workshop),
          ),
        };
      },
    }),
    getWorkshopById: build.query<TWorkshopDetailsDataUI, { id: string }>({
      query({ id }) {
        return {
          url: `${baseUrl}/${id}`,
          method: 'GET',
        };
      },
      providesTags: ['workshop'],
      transformResponse: (data: TWorkshopDetailsDataDB) => {
        return workshopDetailsConverter.fromDb(data || {});
      },
    }),
    getWorkshopChildCareCountById: build.query<
      TWorkshopChildCareUI[],
      { workshopId: string }
    >({
      query({ workshopId }) {
        return {
          url: `${baseUrl}/${workshopId}/${EWorkshopsUrls.CHILDCARE_COUNT}`,
          method: 'GET',
        };
      },
      providesTags: ['workshops-childCare-count'],
      transformResponse: (data: TWorkshopChildCareDB[]) => {
        return data?.length
          ? data.map(item => workshopChildCareConverter.fromDb(item))
          : [];
      },
    }),
    duplicateWorkshop: build.mutation<string, TDuplicateWorkshopUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EWorkshopsUrls.DUPLICATE}`,
          method: 'POST',
          body: duplicateConverter.toDb(body),
        };
      },
      invalidatesTags: ['workshops'],
    }),
    getWorkshopUrl: build.query<string, { workshopId: string }>({
      query({ workshopId }) {
        return {
          url: `${baseUrl}/${EWorkshopsUrls.URL}`,
          method: 'GET',
          params: {
            workshopId,
          },
        };
      },
    }),
    getChildCareUrl: build.query<string, { workshopId: string }>({
      query({ workshopId }) {
        return {
          url: `${baseUrl}/${EWorkshopsUrls.REGISTRANTS}/${EWorkshopsUrls.CHILDCARE}/${EWorkshopsUrls.URL}`,
          method: 'GET',
          params: {
            workshopId,
          },
        };
      },
    }),
    getWorkshopRegistrantsUrl: build.query<string, TWorkshopRegistrantsUrl>({
      query(params) {
        return {
          url: `${baseUrl}/${EWorkshopsUrls.REGISTRANTS}/${EWorkshopsUrls.URL}`,
          method: 'GET',
          params,
        };
      },
    }),
    getWorkshopPhotoGallery: build.query<TWorkshopGalleryUI, void>({
      query() {
        return {
          url: `${baseUrl}/${EWorkshopsUrls.PHOTO}/${EWorkshopsUrls.GALLERY}`,
          method: 'GET',
        };
      },
      providesTags: ['workshop-gallery'],
      transformResponse: (data: TWorkshopGalleryDB) => {
        return workshopGalleryConverter.fromDb(data || {});
      },
    }),
    addWorkshopPhoto: build.mutation<string, TAddWorkshopPhotoUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EWorkshopsUrls.PHOTO}`,
          method: 'PATCH',
          body: addWorkshopPhotoConverter.toDb(body),
        };
      },
      invalidatesTags: ['workshop', 'workshops'],
    }),
    getWorkshopCities: build.query<TColumnFilterItem[], void>({
      query() {
        return {
          url: `${baseUrl}/${EWorkshopsUrls.LOCATION}/${EWorkshopsUrls.CITIES}`,
          method: 'GET',
        };
      },
      transformResponse: (data: string[]) =>
        citiesFiltersConverter.fromDb(data),
      providesTags: ['workshop-cities'],
    }),
    getWorkshopHosts: build.query<TTableFilterDataUI[], void>({
      query() {
        return {
          url: `${baseUrl}/${EWorkshopsUrls.HOSTS}`,
          method: 'GET',
        };
      },
      transformResponse: (data: TLocationHostFilterDataDB[]) => {
        return locationHostFilterDataConverter.fromDb(data);
      },
      providesTags: ['workshop-hosts'],
    }),
    editAgeGroup: build.mutation<void, TWorkshopClassAgeGroupEditUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EWorkshopsUrls.CLASS}/${EWorkshopsUrls.AGE_GROUP}`,
          method: 'PATCH',
          body: workshopClassAgeGroupEditConverter.toDb(body),
        };
      },
      invalidatesTags: ['workshops', 'workshop'],
    }),
    editCapacity: build.mutation<void, TClassCapacityEditUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EWorkshopsUrls.CLASS}/${EWorkshopsUrls.CAPACITY}`,
          method: 'PATCH',
          body: classCapacityEditConverter.toDb(body),
        };
      },
      invalidatesTags: ['workshops', 'workshop'],
    }),
    editLanguage: build.mutation<void, TWorkshopClassLanguageEditUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EWorkshopsUrls.CLASS}/${EWorkshopsUrls.LANGUAGE}`,
          method: 'PATCH',
          body: workshopClassLanguageEditConverter.toDb(body),
        };
      },
      invalidatesTags: ['workshops', 'workshop'],
    }),
    editSessionDateTime: build.mutation<void, TWorkshopClassDateTimeEditUI>({
      query(body) {
        return {
          url: editSessionDateTimeUrl,
          method: 'PATCH',
          body: workshopClassSessionDateTimeEditConverter.toDb(body),
        };
      },
      invalidatesTags: ['workshops', 'workshop'],
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(
              kitManagementApi.util.invalidateTags([
                'kit-management-workshops',
              ]),
            );
          })
          .catch(() => {
            // do nothing
          });
      },
    }),
    editPartDateTime: build.mutation<void, TWorkshopPartDateTimeEditUI>({
      query(body) {
        return {
          url: `${editPartDateTimeUrl}`,
          method: 'PATCH',
          body: workshopPartDateTimeEditConverter.toDb(body),
        };
      },
      invalidatesTags: ['workshops', 'workshop'],
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(
              kitManagementApi.util.invalidateTags([
                'kit-management-workshops',
              ]),
            );
          })
          .catch(() => {
            // do nothing
          });
      },
    }),
    editSessionFacilitator: build.mutation<
      void,
      TWorkshopClassFacilitatorEditUI
    >({
      query(body) {
        return {
          url: `${baseUrl}/${EWorkshopsUrls.SESSION}/${EWorkshopsUrls.FACILITATOR}`,
          method: 'PATCH',
          body: workshopClassSessionFacilitatorEditConverter.toDb(body),
        };
      },
      invalidatesTags: ['workshops', 'workshop'],
    }),
    editLocation: build.mutation<void, TWorkshopLocationEditUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EWorkshopsUrls.LOCATION}`,
          method: 'PATCH',
          body: workshopLocationEditConverter.toDb(body),
        };
      },
      invalidatesTags: ['workshops', 'workshop', 'workshop-location'],
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(
              kitManagementApi.util.invalidateTags([
                'kit-management-workshops-hosts',
                'kit-management-workshops-locations',
                'kit-management-parent-locations',
                'kit-management-parent-hosts',
              ]),
            );
          })
          .catch(() => {
            // do nothing
          });
      },
    }),
    editSiteCoordinator: build.mutation<void, TWorkshopSiteCoordinatorEditUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EWorkshopsUrls.SITE_COORDINATOR}`,
          method: 'PATCH',
          body: workshopSiteCoordinatorEditConverter.toDb(body),
        };
      },
      invalidatesTags: ['workshops', 'workshop'],
    }),
    editWorkshopCapacity: build.mutation<void, TWorkshopCapacityEditUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EWorkshopsUrls.CAPACITY}`,
          method: 'PATCH',
          body: workshopCapacityEditConverter.toDb(body),
        };
      },
      invalidatesTags: ['workshops', 'workshop'],
    }),
    editFoodProvided: build.mutation<void, TWorkshopFoodProvidedEditUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EWorkshopsUrls.FOOD_PROVIDED}`,
          method: 'PATCH',
          body: workshopFoodProvidedEditConverter.toDb(body),
        };
      },
      invalidatesTags: ['workshops', 'workshop'],
    }),
    editCildcareProvided: build.mutation<
      void,
      TWorkshopChildcareProvidedEditUI
    >({
      query(body) {
        return {
          url: `${baseUrl}/${EWorkshopsUrls.CHILDCARE_PROVIDED}`,
          method: 'PATCH',
          body: workshopChildcareEditConverter.toDb(body),
        };
      },
      invalidatesTags: ['workshops', 'workshop'],
    }),
    editShippingAddress: build.mutation<void, TWorkshopShippingAddressEditUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EWorkshopsUrls.SHIPMENT_ADDRESS}`,
          method: 'PATCH',
          body: workshopShipingAddressConverter.toDb(body),
        };
      },
      invalidatesTags: ['workshops', 'workshop'],
    }),
    publishAction: build.mutation<void, { workshopId: string }>({
      query(body) {
        return {
          url: `${baseUrl}/${EWorkshopsUrls.PUBLISH}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: ['workshops', 'workshop'],
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(
              kitManagementApi.util.invalidateTags([
                'kit-management-workshops',
                'kit-management-workshops-hosts',
                'kit-management-workshops-locations',
                'kit-management-parent-locations',
                'kit-management-parent-hosts',
              ]),
            );
          })
          .catch(() => {
            // do nothing
          });
      },
    }),
    republishAction: build.mutation<void, { workshopId: string }>({
      query(body) {
        return {
          url: `${baseUrl}/${EWorkshopsUrls.REPUBLISH}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: ['workshops', 'workshop'],
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(
              kitManagementApi.util.invalidateTags([
                'kit-management-workshops',
                'kit-management-workshops-hosts',
                'kit-management-workshops-locations',
                'kit-management-parent-locations',
                'kit-management-parent-hosts',
              ]),
            );
          })
          .catch(() => {
            // do nothing
          });
      },
    }),
    rescheduleAction: build.mutation<void, { workshopId: string }>({
      query(body) {
        return {
          url: `${baseUrl}/${EWorkshopsUrls.RESCHEDULE}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: ['workshops', 'workshop'],
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(
              kitManagementApi.util.invalidateTags([
                'kit-management-workshops',
                'kit-management-workshops-hosts',
                'kit-management-workshops-locations',
                'kit-management-parent-locations',
                'kit-management-parent-hosts',
              ]),
            );
          })
          .catch(() => {
            // do nothing
          });
      },
    }),
    cancelAction: build.mutation<void, { workshopId: string }>({
      query(body) {
        return {
          url: `${baseUrl}/${EWorkshopsUrls.CANCEL}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: ['workshops', 'workshop'],
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(
              kitManagementApi.util.invalidateTags([
                'kit-management-workshops',
                'kit-management-workshops-hosts',
                'kit-management-workshops-locations',
                'kit-management-parent-locations',
                'kit-management-parent-hosts',
              ]),
            );
          })
          .catch(() => {
            // do nothing
          });
      },
    }),
    editDescription: build.mutation<void, TWorkshopDescriptionEditUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EWorkshopsUrls.DESCRIPTION}`,
          method: 'PATCH',
          body: workshopDescriptionEditConverter.toDb(body),
        };
      },
      invalidatesTags: ['workshop'],
    }),
    editHost: build.mutation<void, TWorkshopHostEditUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EWorkshopsUrls.HOST}`,
          method: 'PATCH',
          body: workshopHostEditConverter.toDb(body),
        };
      },
      invalidatesTags: [
        'workshops',
        'workshop',
        'workshop-hosts',
        'workshop-cities',
        'workshop-location',
      ],
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(
              kitManagementApi.util.invalidateTags([
                'kit-management-workshops',
                'kit-management-workshops-hosts',
                'kit-management-workshops-locations',
                'kit-management-parent-locations',
                'kit-management-parent-hosts',
              ]),
            );
          })
          .catch(() => {
            // do nothing
          });
      },
    }),
    editMeetingLink: build.mutation<void, TWorkshopMeetingLinkEditUI>({
      query(body) {
        return {
          url: editMeetingLinkUrl,
          method: 'PATCH',
          body: workshopMeetingLinkEditConverter.toDb(body),
        };
      },
      invalidatesTags: ['workshop'],
    }),
    deleteAction: build.mutation<void, { workshopId: string }>({
      query(body) {
        return {
          url: `${baseUrl}/${body.workshopId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['workshops', 'workshop'],
    }),
    getWorkshopLocation: build.query<TTableFilterDataUI[], void>({
      query() {
        return {
          url: `${workshopLocationUrl}`,
          method: 'GET',
        };
      },
      transformResponse: (data: TLocationHostFilterDataDB[]) =>
        locationHostFilterDataConverter.fromDb(data),
      providesTags: ['workshop-location'],
    }),
    updateCustomQuestion: build.mutation<void, TUpdateCustomQuestionUi>({
      query({ id, question }) {
        return {
          url: `${baseUrl}/${EWorkshopsUrls.CUSTOM_QUESTION}`,
          method: 'PATCH',
          body: {
            workshopId: id,
            customQuestion: question,
          },
        };
      },
      invalidatesTags: ['workshop'],
    }),
    deleteCustomQuestion: build.mutation<void, { id: string }>({
      query({ id }) {
        return {
          url: `${baseUrl}/${EWorkshopsUrls.CUSTOM_QUESTION}`,
          method: 'PATCH',
          body: {
            workshopId: id,
            customQuestion: null,
          },
        };
      },
      invalidatesTags: ['workshop'],
    }),
    closeAction: build.mutation<void, { workshopId: string }>({
      query(body) {
        return {
          url: `${baseUrl}/${EWorkshopsUrls.CLOSE}`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['workshops', 'workshop'],
    }),
    getSessionAttendanceUrl: build.query<
      string,
      { workshopId: string; sessionId: string }
    >({
      query(params) {
        return {
          url: `${baseUrl}/${EWorkshopsUrls.SESSIONS}/${EWorkshopsUrls.REGISTRANTS}/${EWorkshopsUrls.URL}`,
          method: 'GET',
          params,
        };
      },
    }),
  }),
});

export const {
  useCreateWorkshopMutation,
  useCreateWorkshopClassMutation,
  useGetWorkshopsQuery,
  useGetWorkshopByIdQuery,
  useGetWorkshopHostsQuery,
  useGetWorkshopCitiesQuery,
  useGetWorkshopUrlQuery,
  useGetWorkshopRegistrantsUrlQuery,
  useGetChildCareUrlQuery,
  useEditAgeGroupMutation,
  useEditCapacityMutation,
  useDeleteWorkshopClassMutation,
  useEditLanguageMutation,
  useEditSessionDateTimeMutation,
  useEditSessionFacilitatorMutation,
  useEditLocationMutation,
  useEditSiteCoordinatorMutation,
  useEditFoodProvidedMutation,
  useEditCildcareProvidedMutation,
  useEditShippingAddressMutation,
  useGetWorkshopPhotoGalleryQuery,
  useAddWorkshopPhotoMutation,
  usePublishActionMutation,
  useRepublishActionMutation,
  useRescheduleActionMutation,
  useCancelActionMutation,
  useEditDescriptionMutation,
  useEditHostMutation,
  useDeleteActionMutation,
  useDuplicateWorkshopMutation,
  useEditWorkshopCapacityMutation,
  useEditMeetingLinkMutation,
  useGetWorkshopLocationQuery,
  useGetWorkshopChildCareCountByIdQuery,
  useUpdateCustomQuestionMutation,
  useDeleteCustomQuestionMutation,
  useCloseActionMutation,
  useLazyGetSessionAttendanceUrlQuery,
  useEditPartDateTimeMutation,
} = workshopsApi;
