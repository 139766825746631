import { FC } from 'react';
import { get } from 'lodash';
import { useTranslation } from '@packages/utils';
import { Modal } from 'components/Base/Modal';
import useForm from 'hooks/useForm';
import { useAddParentShippigDetailsMutation } from 'redux/apiSlice/kitManagement.slice';
import {
  useShowToastNotification,
  ENotificationType,
} from 'components/Base/Notification';
import { TValidateFieldsError } from 'data/types/generic.types';
import { TAddShippingDetailsModalProps } from './AddShippingDetailsModal.types';
import { TAddShippingDetailsForm } from './AddShippingDetailsForm/AddShippingDetailsForm.types';
import AddShippingDetailsForm from './AddShippingDetailsForm';

const AddShippingDetailsModal: FC<TAddShippingDetailsModalProps> = ({
  parentOrderId,
  open,
  setOpen,
}) => {
  const { form } = useForm<TAddShippingDetailsForm>();
  const [addShippingDetails, { isLoading }] =
    useAddParentShippigDetailsMutation();
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.parents.list.actions.add-shipping-details.modal',
  });
  const { showToastNotification } = useShowToastNotification();
  const onCloseModal = () => {
    setOpen(false);
    form.resetFields();
  };

  const onSubmitModal = async () => {
    try {
      const values = await form.validateFields();
      await addShippingDetails({
        parentOrderId,
        ...values,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
      onCloseModal();
    } catch (e) {
      if (!(e as TValidateFieldsError)?.errorFields) {
        const message = get(e, 'data.message');
        showToastNotification({
          message,
          type: ENotificationType.ERROR,
        });
      }
    }
  };

  return (
    <Modal
      open={open}
      title={t('TITLE')}
      okText={t('SUBMIT_BTN')}
      cancelText={t('CANCEL_BTN')}
      onCancel={onCloseModal}
      onOk={onSubmitModal}
      confirmLoading={isLoading}
    >
      <AddShippingDetailsForm form={form} />
    </Modal>
  );
};

export default AddShippingDetailsModal;
