import { Card, Col, Row, useTranslation } from '@packages/utils';
import EditableReachTextEditor from 'components/Base/EditableFields/EditableReachTextEditor';
import { InplaceEditLabels } from 'components/Base/EditableFields/components/InplaceEditLabel';
import { TNoteCardProps } from './NoteCard.types';
import RemoveNote from '../../RemoveNote';

const NoteCard = ({
  onEdit,
  elcId,
  meetingId,
  isError,
  isLoading,
  note,
  noteId,
}: TNoteCardProps) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.meetings-tab.details.notes.edit.note',
  });

  return (
    <Card className="mb-4">
      <Col className="ml-1">
        <Row justify="space-between">
          <Col>
            <InplaceEditLabels label={t('INPLACE_EDIT_LABEL')} />
          </Col>
          {noteId && (
            <Col>
              <RemoveNote elcId={elcId} meetingId={meetingId} noteId={noteId} />
            </Col>
          )}
        </Row>
      </Col>
      <Col>
        <EditableReachTextEditor
          title={note || ''}
          fitContent={false}
          onEdit={onEdit}
          emptyText={t('EMPTY_TEXT')}
          placeholder={t('PLACEHOLDER')}
          readonly={false}
          isError={isError}
          isLoading={isLoading}
          required
        />
      </Col>
    </Card>
  );
};

export default NoteCard;
