import { useEffect } from 'react';
import { get } from 'lodash';
import {
  Card,
  Col,
  Form,
  FormItem,
  Row,
  Button,
  TextArea,
  useForm,
  useTranslation,
  useShowToastNotification,
  ENotificationType,
} from '@packages/utils';
import { TValidateFieldsError } from 'data/types/generic.types';
import { ReachTextEditor } from 'components/Base/Input';
import { useCreateElcMeetingTopicMutation } from 'redux/apiSlice/elcs.slice';
import { TAddTopicFormProps, TAddTopicProps } from './AddTopic.types';
import useGetFormData from './AddTopic.data';

const AddTopic = ({
  meetingId,
  elcId,
  onCancelForm,
  isDrawerOpen,
}: TAddTopicProps) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.meetings-tab.details.topics.add',
  });
  const { showToastNotification } = useShowToastNotification();
  const { form } = useForm<TAddTopicFormProps>();
  const { addMeetingTopicFormFields } = useGetFormData();
  const { context, progress, roadBLock, topicTitle } =
    addMeetingTopicFormFields;
  const [createElcMeetingTopic, { isLoading }] =
    useCreateElcMeetingTopicMutation();

  useEffect(() => {
    if (!isDrawerOpen) {
      form.resetFields();
    }
  }, [isDrawerOpen]);

  const onSubmit = async () => {
    try {
      const values = await form.validateFields();
      await createElcMeetingTopic({
        meetingId,
        elcId,
        ...values,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
      form.resetFields();
    } catch (err) {
      if (!(err as TValidateFieldsError)?.errorFields) {
        const message = get(err, 'data.message');
        showToastNotification({
          message,
          type: ENotificationType.ERROR,
        });
      }
    }
  };

  return (
    <Card className="mb-4">
      <Form form={form} onFinish={onSubmit}>
        <FormItem {...topicTitle}>
          <TextArea
            maxLength={350}
            showCount
            placeholder={topicTitle.placeholder}
          />
        </FormItem>
        <FormItem {...context}>
          <ReachTextEditor placeholder={context.placeholder} />
        </FormItem>
        <FormItem {...progress}>
          <ReachTextEditor placeholder={progress.placeholder} />
        </FormItem>
        <FormItem {...roadBLock}>
          <ReachTextEditor placeholder={roadBLock.placeholder} />
        </FormItem>
        <Row gutter={[16, 16]} align="middle" className="mt-4">
          <Col>
            <Button
              color="primary"
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              {t('SUBMIT_BTN')}
            </Button>
          </Col>
          <Col>
            <Button color="primary" type="default" onClick={onCancelForm}>
              {t('CANCEL_BTN')}
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default AddTopic;
