import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { EStorageNames, appLocalStorage } from 'helpers/storage';

type TCollapseSidebarContextType = {
  collapsed: boolean;
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CollapseSidebarContext =
  createContext<TCollapseSidebarContextType | null>(null);

type CollapseSidebarContextProps = {
  children: React.ReactNode;
};

export const SidebarContext: React.FC<CollapseSidebarContextProps> = ({
  children,
}) => {
  const initialCollapsedState = !!appLocalStorage.getItem(
    EStorageNames.sidebar_collapsed,
  );
  const [collapsed, setCollapsed] = useState(initialCollapsedState);
  const memorizedState = useMemo(
    () => ({ collapsed, setCollapsed }),
    [collapsed],
  );
  useEffect(() => {
    appLocalStorage.setItem(EStorageNames.sidebar_collapsed, collapsed);
  }, [collapsed]);
  return (
    <CollapseSidebarContext.Provider value={memorizedState}>
      {children}
    </CollapseSidebarContext.Provider>
  );
};

function useSidebarContext() {
  const context = useContext(CollapseSidebarContext);
  if (!context) {
    throw new Error('useSidebarContext must be used within a SidebarContext');
  }
  return context;
}

export default useSidebarContext;
