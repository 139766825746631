import { FC, useState } from 'react';
import { EditableColumn } from 'components/Base/Table/components/EditableColumn';
import { ERegitrantStatus } from 'data/types/registrants.types';
import ActionsOnAttendanceColumn from './components/ActionsOnAttendanceColumn';
import KitLanguageText from './components/KitLanguageText';
import AttendanceViewItem from './components/AttendanceViewItem';
import OrientationKitItem from './components/OrientationKitItem';
import UpdateAttendancesModal from './UpdateAttendancesModal';
import { TAttendancesProps } from './Attendances.types';
import RemoveRegistrantModal from './RemoveRegistrantModal';
import { SAttendanceContentWrapper } from './Attendances.styles';
import UnregisterRegistrantModal from './UnregisterRegistrantModal';
import ReregisterRegistrantModal from './ReregisterRegistrantModal';

const Attendances: FC<TAttendancesProps> = ({
  attendances,
  fullName,
  hasUpdatableAttendance,
  registrantId,
  workshopClassId,
  orientationKit,
  hasStartedPart,
  materialsLanguage,
  workshopId,
  registrantStatus,
  isReadonly,
}) => {
  const [openEditAttendanceModal, setOpenEditAttendanceModal] =
    useState<boolean>(false);
  const [openRemoveRegistrantModal, setOpenRemoveRegistrantModal] =
    useState<boolean>(false);
  const [openUnregisterModal, setOpenUnregisterModal] =
    useState<boolean>(false);
  const [openReRegisterModal, setOpenReRegisterModal] =
    useState<boolean>(false);

  return (
    <>
      <EditableColumn
        readonly={isReadonly}
        actionButtons={
          <ActionsOnAttendanceColumn
            showReRegisterRegistrant={
              registrantStatus === ERegitrantStatus.UNREGISTERED
            }
            showUnregisterRegistrant={
              registrantStatus === ERegitrantStatus.ACTIVE
            }
            showEditAttendance={
              !!hasUpdatableAttendance &&
              registrantStatus !== ERegitrantStatus.UNREGISTERED
            }
            showRemoveRegistrant={!hasStartedPart}
            onEditButtonClick={() => setOpenEditAttendanceModal(true)}
            onRemoveButtonClick={() => setOpenRemoveRegistrantModal(true)}
            onReRegisterButtonClick={() => setOpenReRegisterModal(true)}
            onUnregisterButtonClick={() => setOpenUnregisterModal(true)}
          />
        }
      >
        {hasUpdatableAttendance ? (
          <SAttendanceContentWrapper>
            <KitLanguageText language={materialsLanguage} />
            {attendances?.map(item => (
              <AttendanceViewItem key={item.sessionAttendanceId} {...item} />
            ))}
          </SAttendanceContentWrapper>
        ) : (
          '-'
        )}
        {orientationKit && (
          <OrientationKitItem orientationKit={orientationKit} />
        )}
      </EditableColumn>

      {openEditAttendanceModal && (
        <UpdateAttendancesModal
          workshopClassId={workshopClassId}
          registrantId={registrantId}
          attendances={attendances.filter(el => el.isUpdatable)}
          fullName={fullName}
          open={openEditAttendanceModal}
          setOpen={setOpenEditAttendanceModal}
          orientationKit={orientationKit}
          materialsLanguage={materialsLanguage}
          workshopId={workshopId}
        />
      )}
      {openRemoveRegistrantModal && (
        <RemoveRegistrantModal
          open={openRemoveRegistrantModal}
          setOpen={setOpenRemoveRegistrantModal}
          registrantId={registrantId}
          workshopClassId={workshopClassId}
          workshopId={workshopId}
        />
      )}
      {openUnregisterModal && (
        <UnregisterRegistrantModal
          open={openUnregisterModal}
          setOpen={setOpenUnregisterModal}
          registrantId={registrantId}
          workshopClassId={workshopClassId}
          workshopId={workshopId}
        />
      )}
      {openReRegisterModal && (
        <ReregisterRegistrantModal
          open={openReRegisterModal}
          setOpen={setOpenReRegisterModal}
          registrantId={registrantId}
          workshopClassId={workshopClassId}
          workshopId={workshopId}
        />
      )}
    </>
  );
};

export default Attendances;
