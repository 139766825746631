import { styled } from 'styled-components';
import { Content } from '@idaho-aeyc/ui-kit';

export const SMainWrapper = styled.div`
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  flex: auto;
  flex-direction: column;
  background: ${({ theme }) => theme.colorWhiteBgLayout};
`;

export const SLayout = styled.div`
  min-height: 100vh;
  overflow: hidden;
  display: flex;
`;

export const SContent = styled(Content)`
  &.ant-layout-content {
    height: calc(100vh - 24px);
  }
`;
