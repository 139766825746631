import { resetPasswordCasesConverter } from 'data/convertors/auth.converters';
import {
  EResetPasswordCasesDB,
  EResetPasswordCasesUI,
  TResetPasswordCasesUI,
} from 'data/types/amplify.types';
import { EAuthUrls } from 'data/urls/auth.url';
import { authNoSessionSplitApi } from 'redux/helpers/slice.helpers';

export const authNoHeaderApi = authNoSessionSplitApi(
  'authNoHeader',
  [],
).injectEndpoints({
  endpoints: build => ({
    resetPasswordCases: build.mutation<
      EResetPasswordCasesUI,
      TResetPasswordCasesUI
    >({
      query(data) {
        return {
          url: `${EAuthUrls.AUTH}/${EAuthUrls.RESEND_PASSWORD}`,
          method: 'PATCH',
          body: resetPasswordCasesConverter.toDb(data),
        };
      },
      transformResponse: (data: EResetPasswordCasesDB) =>
        resetPasswordCasesConverter.fromDb(data),
    }),
  }),
  overrideExisting: false,
});

export const { useResetPasswordCasesMutation } = authNoHeaderApi;
