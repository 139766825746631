import { useState } from 'react';
import { useTheme } from 'styled-components';
import { Icon, EIconNames } from 'components/Base/Icon';
import { Row } from 'components/Base/Grid';
import { Tooltip } from 'components/Base/Tooltip';
import { useTranslation, Button } from '@packages/utils';
import { addDetailsSectionKey } from '../../AddDetailsSection.data';
import AddDetailsModal from '../../AddDetailsModal';
import { TAddActionProps } from './AddAction.types';

const AddAction = ({ organizationId, canAdd, categories }: TAddActionProps) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation({
    keyPrefix: `${addDetailsSectionKey}`,
  });
  return (
    <>
      <Row justify="end">
        {!canAdd ? (
          <Tooltip title={t('DISABLED_TOOLTIP_TEXT')}>
            <Button
              onClick={() => setOpen(true)}
              color="primary"
              size="small"
              className="mb-2"
              disabled
              icon={
                <Icon
                  icon={EIconNames.PLUS}
                  size={12}
                  color={theme.colorBlackPrimary}
                />
              }
            >
              {t('ADD_BTN_TITLE')}
            </Button>
          </Tooltip>
        ) : (
          <Button
            onClick={() => setOpen(true)}
            color="primary"
            size="small"
            className="mb-2"
            icon={
              <Icon
                icon={EIconNames.PLUS}
                size={12}
                color={theme.colorBlackPrimary}
              />
            }
          >
            {t('ADD_BTN_TITLE')}
          </Button>
        )}
      </Row>
      <AddDetailsModal
        open={open}
        categories={categories}
        setOpen={setOpen}
        organizationId={organizationId}
      />
    </>
  );
};

export default AddAction;
