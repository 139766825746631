import { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import pageNotFoundImage from 'assets/images/badSmile.svg';
import { RoutesPaths } from 'routes/Routes.types';
import ErrorPageView from '..';
import { TSomethingWentWrongProps } from './SomethingWentWrong.types';

const SomethingWentWrong = ({ error }: TSomethingWentWrongProps) => {
  useEffect(() => {
    if (!error) {
      return;
    }
    if (error.status === 401) {
      (async () => {
        await Auth.signOut();
        window.location.href = `/${RoutesPaths.LOGIN}`;
      })();
    }
  }, [error]);

  return (
    <ErrorPageView
      imgSrc={pageNotFoundImage}
      alt="something went wrong"
      title="OOPS! Something went wrong!"
      description="Please try again later."
    />
  );
};

export default SomethingWentWrong;
