import { FC } from 'react';
import { useTranslation } from '@packages/utils';
import { useTheme } from 'styled-components';
import { Text } from 'components/Base/Typography';
import { TextIcon } from 'components/Base/TextIcon';
import { EIconNames } from 'components/Base/Icon';
import { TCoordinatorInfoProps } from './CoordinatorInfo.types';

const CoordinatorInfo: FC<TCoordinatorInfoProps> = ({
  email,
  phone,
  popoverTitle = '',
}) => {
  const theme = useTheme();
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.workshops.details.sessions.view.actions.update.facilitator.popover',
  });
  return (
    <>
      <Text fontWeight={500} fontSize={14} color={theme.colorWhitePrimary}>
        {popoverTitle}
      </Text>
      <TextIcon
        fontSize={14}
        strong={false}
        iconColor={theme.colorWhitePrimary}
        labelColor={theme.colorWhitePrimary}
        icon={EIconNames.EMAIL}
        className="mb-2 mt-2"
        alt="Site coordinator email"
        label={email || t('N/A')}
      />
      <TextIcon
        fontSize={14}
        size={16}
        strong={false}
        iconColor={theme.colorWhitePrimary}
        labelColor={theme.colorWhitePrimary}
        icon={EIconNames.PHONE}
        alt="Site coordinator phone"
        label={phone || t('N/A')}
      />
    </>
  );
};

export default CoordinatorInfo;
