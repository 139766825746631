import get from 'lodash/get';
import { useTranslation } from '@packages/utils';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { Modal } from 'components/Base/Modal';
import {
  useShowToastNotification,
  ENotificationType,
} from 'components/Base/Notification';
import { TValidateFieldsError } from 'data/types/generic.types';
import ConfirmModalBody from 'components/Shared/ConfirmModalBody';
import {
  useDeleteUserMutation,
  userGrowApi,
} from 'redux/apiSlice/userGrow.slice';
import { TRemoveAdminModalProps } from './RemoveAdminModal.types';

const RemoveAdminModal = ({
  adminId,
  open,
  setOpen,
}: TRemoveAdminModalProps) => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.team.list.actions.remove-user.modal',
  });
  const dispatch = useAppDispatch();
  const usersData = useAppSelector(state => state.userSlice);
  const { user } = usersData;
  const { showToastNotification } = useShowToastNotification();
  const [deleteUser, { isLoading }] = useDeleteUserMutation();

  const handleSubmit = async () => {
    try {
      await deleteUser({
        userId: adminId,
      }).unwrap();
      if (adminId === user.userId) {
        dispatch(userGrowApi.util.invalidateTags(['user']));
      }
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
      setOpen(false);
    } catch (e) {
      if (!(e as TValidateFieldsError)?.errorFields) {
        const message = get(e, 'data.message');
        showToastNotification({
          message,
          type: ENotificationType.ERROR,
        });
      }
    }
  };

  return (
    <Modal
      title={t('TITLE')}
      open={open}
      onCancel={() => setOpen(false)}
      onOk={handleSubmit}
      okText={t('SUBMIT_BTN')}
      cancelText={t('CANCEL_BTN')}
      confirmLoading={isLoading}
    >
      <ConfirmModalBody description={t('DESCRIPTION')} />
    </Modal>
  );
};

export default RemoveAdminModal;
