import { FC } from 'react';
import { useTranslation, Tag, TagColors } from '@packages/utils';
import { EOrderStatus } from 'data/types/kitManagement.types';
import { TOrderStatusProps } from './OrderStatus.types';

const OrderStatus: FC<TOrderStatusProps> = ({ text, className }) => {
  const orderStatusColorMapper: Record<EOrderStatus, TagColors> = {
    [EOrderStatus.ORDERED]: 'primary',
    [EOrderStatus.NOT_ORDERED]: 'pink',
  };
  const { t } = useTranslation({
    keyPrefix: 'enums.order-status',
  });

  if (!text) return null;
  return (
    <Tag className={className} color={orderStatusColorMapper[text]}>
      {t(text)}
    </Tag>
  );
};

export default OrderStatus;
