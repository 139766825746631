import { Outlet } from 'react-router-dom';
import Layout from 'components/Base/Layout';
import { SidebarContext } from '../context/CollapseSidebarContext';

const SidebarRoutes = () => {
  return (
    <SidebarContext>
      <Layout>
        <Outlet />
      </Layout>
    </SidebarContext>
  );
};

export default SidebarRoutes;
