export enum EStorageNames {
  filters = 'filters',
  sidebar_collapsed = 'sidebar_collapsed',
  tableSettings = 'tableSettings',
  table_collapsed = 'table_collapsed',
  updatePageSizeDefaultValue = 'updatePageSizeDefaultValue',
  removeRegistrantsTablesSettings = 'removeRegistrantsTablesSettings',
  removeKitManagementTablesSettings = 'removeKitManagementTablesSettings',
  removeWorkshopFilters = 'removeWorkshopFilters',
  cleanUpStorage_V1 = 'cleanUpStorage_V1',
  removeGlobalWaitlistTableSettings = 'removeGlobalWaitlistTableSettings',
  removeParentOrderTableData = 'removeParentOrderTableFiltersAndSettings',
  cleanUpStorage_V2 = 'cleanUpStorage_V2',
  cleanUpStorage_V3 = 'cleanUpStorage_V3',
  cleanUpStorage_V4 = 'cleanUpStorage_V4',
  cleanUpStorage_V5 = 'cleanUpStorage_V5',
  cleanUpStorage_V6 = 'cleanUpStorage_V6',
  cleanUpStorage_V7 = 'cleanUpStorage_V7',
}

type TStorage = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getItem: (key: EStorageNames) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setItem: (key: EStorageNames, value: any) => void;
  removeItem: (key: EStorageNames) => void;
  clear: () => void;
};

const appLocalStorage: TStorage = {
  getItem: key => {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  },
  setItem: (key, value) => {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  },
  removeItem: key => {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  },
  clear: () => {
    try {
      localStorage.clear();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  },
};

export { appLocalStorage };
