import Context from './Context';
import { TEditTopicProps } from './EditTopic.types';
import Progress from './Progress';
import RoadBlock from './RoadBlock';

const EditTopic = ({ data, elcId, meetingId }: TEditTopicProps) => {
  const { topicId, context, progress, roadBlock } = data;

  return (
    <>
      <Context
        elcId={elcId}
        meetingId={meetingId}
        topicId={topicId}
        context={context}
      />
      <Progress
        elcId={elcId}
        meetingId={meetingId}
        topicId={topicId}
        progress={progress}
      />
      <RoadBlock
        elcId={elcId}
        meetingId={meetingId}
        topicId={topicId}
        roadBlock={roadBlock}
      />
    </>
  );
};

export default EditTopic;
