import { useTranslation } from '@packages/utils';
import useGetValidationData from 'components/Base/Form/validationRules';

const useGetFormData = () => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.meetings-tab.details.topics.add.form',
  });
  const { validationRules } = useGetValidationData();
  const addMeetingTopicFormFields = {
    topicTitle: {
      name: 'topicTitle',
      label: t('topic.LABEL'),
      placeholder: t('topic.PLACEHOLDER'),
      rules: [validationRules.requiredField()],
    },
    context: {
      name: 'context',
      label: t('context.LABEL'),
      placeholder: t('context.PLACEHOLDER'),
    },
    progress: {
      name: 'progress',
      label: t('progress.LABEL'),
      placeholder: t('progress.PLACEHOLDER'),
    },
    roadBLock: {
      name: 'roadBlock',
      label: t('roadblocks.LABEL'),
      placeholder: t('roadblocks.PLACEHOLDER'),
    },
  };

  return { addMeetingTopicFormFields };
};

export default useGetFormData;
