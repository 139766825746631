import { Tag, Input, Form } from '@packages/utils';
import styled, { createGlobalStyle } from 'styled-components';

export const AddNewTag = styled(Tag)`
  &&.ant-tag,
  &&&:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.tagColorGreyNormalIcon};
    border-style: dashed;
    background-color: ${({ theme }) => theme.colorWhitePrimary} !important;
    svg {
      margin-right: 4px;
    }
  }
`;

export const EditZipInput = styled(Input)`
  &.ant-input {
    height: 22px;
    width: 100px;
  }
`;

export const SForm = styled(Form)`
  &.ant-form {
    .ant-form-item {
      margin-bottom: 0;
      .ant-form-item-control-input {
        min-height: 22px;
      }
    }
  }
`;

export const TooltipGlobalStyle = createGlobalStyle`
.ant-tooltip {
  max-width: 227px;
  }`;
