import * as React from 'react';
import { useTranslation } from '@packages/utils';
import { useTheme } from 'styled-components';
import { Col, Row } from 'components/Base/Grid';
import { Text } from 'components/Base/Typography';
import { useAppSelector } from 'redux/store';
import { SCard } from '../Flyers.styles';
import { SQRCode } from './QR.styles';

export const QR = ({ url }: { url?: string }) => {
  const theme = useTheme();
  const flyerLanguage = useAppSelector(state => state.appSlice.flyerLanguage);
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.workshops.details.actions.download-flyer.flyer',
    ...(flyerLanguage && { lng: flyerLanguage }),
  });
  return (
    <Row gutter={[16, 16]} className="flex-direction-column" align="middle">
      <Col>
        <SCard className="p-2">
          <SQRCode value={url || ''} />
        </SCard>
      </Col>
      <Col className="text-center">
        <Text
          fontSize={theme.fontSizeHeading2}
          fontWeight={theme.fontWeightMedium}
        >
          {t('REGISTER_TODAY')}
        </Text>
      </Col>

      <Col className="text-center" span={24}>
        <Text
          fontSize={theme.fontSizeHeading3}
          fontWeight={theme.fontWeightMedium}
        >
          {t('IDAHOAEYC_URL')}
        </Text>
      </Col>
    </Row>
  );
};
