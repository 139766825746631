import { Card, Col, Title, Row } from '@packages/utils';
import RemovePeopleOrganization from 'components/Shared/RemovePeopleOrganization';
import PeopleOrganizationTitle from 'components/Shared/PeopleOrganizationTitle';
import { RoutesPaths } from 'routes/Routes.types';
import { useNavigate } from 'react-router-dom';
import { TBackButtonState } from 'components/Base/PageWrapper/PageWrapper.types';

import { TPeopleOrganizationCardProps } from './PeopleOrganizationCard.data';

const PeopleOrganizationCard = ({
  userId,
  data,
  allowedActions,
  userName,
}: TPeopleOrganizationCardProps) => {
  const navigate = useNavigate();

  const redirectToOrganization = () => {
    navigate(`/app/${RoutesPaths.ORGANIZATION}/${data.organizationId}`, {
      state: {
        backUrl: window.location.pathname,
        backButtonSuffix: userName,
      } as TBackButtonState,
    });
  };
  return (
    <Card className="mb-3" onClick={redirectToOrganization}>
      <Row align="middle" justify="space-between" wrap={false}>
        <Col>
          <Title level={4}>{data.organizationName}</Title>
        </Col>
        {allowedActions.canRemove && (
          <Col>
            <RemovePeopleOrganization
              organizationId={data.organizationId}
              userId={userId}
            />
          </Col>
        )}
      </Row>
      <Row>
        <PeopleOrganizationTitle
          data={data}
          organizationId={data.organizationId}
          userId={userId}
        />
      </Row>
    </Card>
  );
};

export default PeopleOrganizationCard;
