export enum EKitManagementUrls {
  BASE_URL = 'ordering',
  WORKSHOP = 'workshop-part',
  PARENT = 'parent',
  HOSTS = 'hosts',
  LOCATIONS = 'locations',
  ORDER = 'order',
  NO_ADDRESS = 'no-address',
  EXPORT = 'export',
  SHIPPING_DETAILS = 'shipping-details',
  PARENT_ORDER = 'parent-order',
}
