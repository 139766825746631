import { useTranslation } from '@packages/utils';
import { useGetUsersListQuery } from 'redux/apiSlice/userGrow.slice';
import PageWrapper from 'components/Base/PageWrapper';
import { Table } from 'components/Base/Table';
import useTable from 'components/Base/Table/hooks/useTable';
import CardContent from 'components/Base/Layout/Content/CardContent';
import { ETableStoragePaths } from 'components/Base/Table/Table.types';
import { TAdminsDataUI } from 'data/types/admin.types';
import { TRtkErrorType } from 'data/types/generic.types';
import useNavigateWithBackState from 'hooks/useNavigateWithBackState';
import useGetTeamTableData from './TeamList.data';
import AddUserAction from '../TeamAction/AddUser';

const TeamList = () => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.team.list',
  });

  const { defaultColumns } = useGetTeamTableData();

  const {
    page,
    pageSize,
    sortColumn,
    setPageSize,
    handleTableChange,
    selectedFilters,
    columns,
  } = useTable<TAdminsDataUI>({
    storagePath: ETableStoragePaths.TEAM,
    defaultColumns,
  });

  const { data, isFetching, isLoading, error } = useGetUsersListQuery({
    page,
    pageSize,
    sortColumn,
    filters: selectedFilters,
  });

  const { goPeopleDetails } = useNavigateWithBackState();

  const navigateToPeopleDetails = (record: TAdminsDataUI) => {
    return {
      onClick: () => goPeopleDetails(record.adminId, t('TITLE')),
    };
  };

  return (
    <PageWrapper
      title={t('TITLE')}
      actions={<AddUserAction />}
      isLoading={isLoading}
      error={error as TRtkErrorType}
    >
      <CardContent>
        <Table<TAdminsDataUI>
          rowKey={(record: TAdminsDataUI) => record.adminId}
          currentPage={page}
          columns={columns}
          dataSource={data?.content}
          rowClassName="pointer"
          onRow={navigateToPeopleDetails}
          loading={isFetching}
          pageSize={pageSize}
          setPageSize={setPageSize}
          totalDataCount={data?.totalElements}
          onChange={handleTableChange}
          storagePath={ETableStoragePaths.TEAM}
        />
      </CardContent>
    </PageWrapper>
  );
};

export default TeamList;
