import { useTranslation, Button } from '@packages/utils';
import { TSaveActionProps } from './SaveAction.types';

const SaveAction = ({
  isLoading,
  formHasChange,
  handleSubmit,
}: TSaveActionProps) => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.lightBox.actions',
  });

  return (
    <Button
      loading={isLoading}
      type="primary"
      color="primary"
      onClick={handleSubmit}
      className="mt-3"
      disabled={!formHasChange}
    >
      {t('save.LABEL')}
    </Button>
  );
};

export default SaveAction;
