import { useEffect } from 'react';
import { useTranslation } from '@packages/utils';
import { useGetFamilyListQuery } from 'redux/apiSlice/family.slice';
import { useGetWorkshopLocationQuery } from 'redux/apiSlice/workshops.slice';
import { useGetTagsListQuery } from 'redux/apiSlice/tags.slice';
import { ETagType } from 'data/types/tags.types';
import CardContent from 'components/Base/Layout/Content/CardContent';
import useTable from 'components/Base/Table/hooks/useTable';
import { ETableStoragePaths } from 'components/Base/Table/Table.types';
import PageWrapper from 'components/Base/PageWrapper';
import { TRtkErrorType } from 'data/types/generic.types';
import { EIconNames } from 'components/Base/Icon';
import { Table } from 'components/Base/Table';
import useNavigateWithBackState from 'hooks/useNavigateWithBackState';
import { EFamilyFilterParams, TFamilyDataUi } from 'data/types/family.types';
import useGetFamilyColumnsData from './FamilyList.data';
import { familyListKey } from '../Family.data';
import FamilyExport from '../FamilyListActions/FamilyExport';

const FamilyList = () => {
  const { familyColumns } = useGetFamilyColumnsData();

  const { t } = useTranslation({
    keyPrefix: familyListKey,
  });

  const {
    page,
    pageSize,
    sortColumn,
    setPageSize,
    handleTableChange,
    selectedFilters,
    hasSelectedFilter,
    hasFilters,
    columns,
    setColumns,
    onFiltersReset,
  } = useTable<TFamilyDataUi>({
    storagePath: ETableStoragePaths.FAMILY,
    defaultColumns: familyColumns,
  });

  const { data, isFetching, isLoading, error } = useGetFamilyListQuery({
    page,
    pageSize,
    sortColumn,
    filters: selectedFilters,
  });

  const { data: locationFilters } = useGetWorkshopLocationQuery();

  const isEmpty = !data?.content.length && !hasSelectedFilter && !isFetching;

  const { goWorkshopDetails } = useNavigateWithBackState();

  const navigateToWorkshopDetails = (record: TFamilyDataUi) => {
    return {
      onClick: () => goWorkshopDetails(record.workshopId, t('TITLE')),
    };
  };

  const { data: tagsFilters } = useGetTagsListQuery(ETagType.REGISTRANT);

  useEffect(() => {
    setColumns(
      columns.map(item => {
        if (item.key === EFamilyFilterParams.LOCATION) {
          item.filters = locationFilters;
        }
        if (item.key === EFamilyFilterParams.TAGS) {
          item.filters = tagsFilters;
        }
        return item;
      }),
    );
  }, [tagsFilters, locationFilters]);

  return (
    <PageWrapper
      title={t('TITLE')}
      isLoading={isLoading}
      error={error as TRtkErrorType}
      emptyWrapper={{
        isEmpty,
        alt: 'Family List Empty State',
        icon: EIconNames.FAMILY,
        emptyText: t('empty-view.EMPTY_TEXT'),
      }}
    >
      <CardContent>
        <Table<TFamilyDataUi>
          rowKey={(record: TFamilyDataUi) => record.registrantId}
          onRow={navigateToWorkshopDetails}
          additionalActions={<FamilyExport selectedFilters={selectedFilters} />}
          currentPage={page}
          columns={columns}
          dataSource={data?.content}
          rowClassName="pointer"
          loading={isFetching}
          pageSize={pageSize}
          setPageSize={setPageSize}
          totalDataCount={data?.totalElements}
          onChange={handleTableChange}
          hasFilters={hasFilters}
          onFiltersReset={onFiltersReset}
          storagePath={ETableStoragePaths.FAMILY}
        />
      </CardContent>
    </PageWrapper>
  );
};

export default FamilyList;
