import { useMemo } from 'react';
import { useGetUserIdentityQuery } from 'redux/apiSlice/userGrow.slice';
import { Row, Col, Text } from '@packages/utils';
import { useTheme } from 'styled-components';

const useUserOptions = () => {
  const theme = useTheme();

  const { data } = useGetUserIdentityQuery({
    profileTypes: undefined,
  });

  const userOptions = useMemo(() => {
    return data?.map(item => ({
      label: (
        <Row>
          <Col xs={24}>{item.fullName}</Col>
          <Col xs={24}>
            <Text
              fontSize={theme.fontSizeMd}
              color={theme.buttonColorPrimaryDisabledText}
            >
              {item.email}
            </Text>
          </Col>
        </Row>
      ),
      value: item.id,
      title: item.fullName,
    }));
  }, [data]);

  return userOptions;
};

export default useUserOptions;
