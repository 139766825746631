import useCopyToClipboard from 'hooks/useCopyClipboard';
import { EIconNames } from 'components/Base/Icon';
import ActionGroupItemLabel from 'components/Shared/ActionsButtonDetailsPage/ActionGroupItemLabel';
import { TCopyUrlsProps } from './CopyUrls.types';

const CopyUrls = ({ copyUrl, copyText }: TCopyUrlsProps) => {
  const [isCopied, copyToClipboard] = useCopyToClipboard();

  const handleCopyClick = () => {
    if (!copyUrl) {
      return;
    }
    copyToClipboard(copyUrl);
  };

  return (
    <ActionGroupItemLabel
      label={copyText}
      icon={isCopied ? EIconNames.CHECK : EIconNames.COPY_OUTLINED}
      onClick={handleCopyClick}
    />
  );
};

export default CopyUrls;
