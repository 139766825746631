import CardContent from 'components/Base/Layout/Content/CardContent';
import { TRegistrantsTabProps } from './RegistrantsTab.types';
import RegistrantsList from './RegistrantsList';

const RegistrantsTab = ({ data }: TRegistrantsTabProps) => {
  return (
    <CardContent tabContent contentPadding="8px 0px">
      <RegistrantsList
        workshopId={data?.id}
        customQuestion={data?.customQuestion.value}
        hideChildInfo={!!data?.isCCP}
      />
    </CardContent>
  );
};

export default RegistrantsTab;
