import { FC } from 'react';
import { EIconNames } from 'components/Base/Icon';
import { InplaceEditLabels } from 'components/Base/EditableFields/components/InplaceEditLabel';
import {
  useShowToastNotification,
  ENotificationType,
} from 'components/Base/Notification';
import EditableInput from 'components/Base/EditableFields/EditableInput';
import { useUpdateOrganizationAdditionalAddressMutation } from 'redux/apiSlice/organizations.slice';
import { TRtkErrorType } from 'data/types/generic.types';
import { Col, Row, useTranslation } from '@packages/utils';
import { TUpdateAdditionalOrgAddressProps } from './UpdateOrganizationAddress.types';

const UpdateAdditionalOrgAddress: FC<TUpdateAdditionalOrgAddressProps> = ({
  address,
  id,
}) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.organizations.details.details-tab.main-contact.address2',
  });
  const { showToastNotification } = useShowToastNotification();

  const [updateOrganizationAddress, { isLoading, isError }] =
    useUpdateOrganizationAdditionalAddressMutation();

  const onEdit = async (value: string) => {
    try {
      await updateOrganizationAddress({
        id,
        additionalAddress: value,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (e) {
      showToastNotification({
        message: (e as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };
  return (
    <Row align="middle" wrap={false} className="w-100">
      <Col>
        <InplaceEditLabels
          label={t('LABEL')}
          icon={EIconNames.LOCATION_OUTLINED}
        />
      </Col>
      <Col flex="auto">
        <EditableInput
          emptyText={t('EMPTY_TEXT')}
          placeholder={t('PLACEHOLDER')}
          title={address.value}
          onEdit={onEdit}
          isError={isError}
          isLoading={isLoading}
          fitContent={false}
          readonly={address.allowedOperations.readonly}
          canRemove={address.allowedOperations.canRemove}
          backIcon
        />
      </Col>
    </Row>
  );
};

export default UpdateAdditionalOrgAddress;
