import { Col, Row, Tag, useTranslation } from '@packages/utils';
import EditableSelect from 'components/Base/EditableFields/EditableSelect';
import { InplaceEditLabels } from 'components/Base/EditableFields/components/InplaceEditLabel';
import { EIconNames } from 'components/Base/Icon';
import { avatarColorMapper } from 'pages/private/ELCs/ELCs.data';
import { EInvitationStatus } from 'data/types/elcs.types';
import PendingIconTooltip from 'pages/private/ELCs/components/PendingIconTooltip';
import ElcItemPopover from 'pages/private/ELCs/components/ElcPopover';
import { TProjectManagerProps } from './ProjectManager.types';
import { SCard } from '../TeamSection.styles';

const ProjectManager = ({ data }: TProjectManagerProps) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.details-tab.team-section.pm',
  });

  const onEdit = () => {
    // do nothing
  };

  return (
    <Col md={8}>
      <SCard className="h-100">
        <Tag color="blue">{t('LABEL')}</Tag>
        <Row align="middle">
          <Col>
            <Row align="middle">
              <Col>
                <InplaceEditLabels
                  label={t('INPLACE_EDIT_LABEL')}
                  icon={EIconNames.PROFILE}
                />
              </Col>
              <Col flex="auto">
                <EditableSelect
                  {...(data && {
                    title: {
                      label: data.fullName,
                      value: data.userId,
                    },
                  })}
                  options={[]}
                  onEdit={onEdit}
                  emptyText={t('EMPTY_TEXT')}
                  placeholder={t('PLACEHOLDER')}
                  readonly
                  fitContent={false}
                  canRemove={false}
                  avatarColor={
                    data?.invitationStatus === EInvitationStatus.PENDING
                      ? 'grey'
                      : avatarColorMapper.lead
                  }
                  {...(data && {
                    tooltip: (
                      <ElcItemPopover
                        fullName={data.fullName}
                        email={data.email}
                        phone={data.phone}
                      />
                    ),
                  })}
                />
              </Col>
            </Row>
          </Col>
          {data?.invitationStatus === EInvitationStatus.PENDING && (
            <PendingIconTooltip />
          )}
        </Row>
      </SCard>
    </Col>
  );
};

export default ProjectManager;
