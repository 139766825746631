import { FC, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch } from 'react-redux';
import { setFlyerLanguage } from 'redux/slices';
import { useAppSelector } from 'redux/store';
import { getImageFromUrl } from 'redux/services/fileServices';
import { useStateSafely } from 'hooks/useStateSafely';
import { useTranslation } from '@packages/utils';
import { useDownloadPdfFromComponent } from 'hooks/useDownloadPdf';
import { TDownloadFlyerActionProps } from './DownloadFlyerAction.types';
import Flyers from '../../components/Flyers';
import DropdownItems from '../DropdownItems';

const DownloadFlyerAction: FC<TDownloadFlyerActionProps> = ({
  data,
  workshopUrl,
}) => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.workshops.details.actions',
  });
  const dispatch = useDispatch();
  const downloadFlyer = useDownloadPdfFromComponent();
  const ref = useRef<HTMLDivElement>(null);
  const flyerLanguage = useAppSelector(s => s.appSlice.flyerLanguage);

  const [blobImageUrl, setBlobImageUrl] = useStateSafely('');
  useEffect(() => {
    if (!data?.photo?.value) {
      return;
    }
    (async () => {
      const urlVal = await getImageFromUrl(data?.photo?.value || '');
      if (urlVal) setBlobImageUrl(urlVal);
    })();
  }, [data?.photo?.value]);

  useEffect(() => {
    if (!flyerLanguage) {
      return;
    }
    (async () => {
      await downloadFlyer(ref, `${data?.location.name} (${flyerLanguage})`);
      dispatch(setFlyerLanguage(null));
    })();
  }, [flyerLanguage]);
  return (
    <>
      <DropdownItems label={t('download-flyer.DOWNLOAD_FLYER_BTN')} />
      {data &&
        flyerLanguage &&
        createPortal(
          <Flyers
            data={data}
            ref={ref}
            url={workshopUrl}
            imageUrl={blobImageUrl}
          />,
          document.getElementsByClassName('page-wrapper')[0],
        )}
    </>
  );
};

export default DownloadFlyerAction;
