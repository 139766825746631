import { useTranslation } from '@packages/utils';
import useGetValidationData from 'components/Base/Form/validationRules';

const useGetFormData = () => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.people.details.details-tab.actions.add.modal.form',
  });
  const { validationRules } = useGetValidationData();

  const addInfoFormFields = {
    infoType: {
      name: 'infoType',
    },
    email: {
      name: 'email',
      label: t('email.LABEL'),
      placeholder: t('email.PLACEHOLDER'),
      rules: [validationRules.email(), validationRules.requiredField()],
    },
    mobile: {
      name: 'phone',
      label: t('mobile.LABEL'),
      placeholder: t('mobile.PLACEHOLDER'),
      rules: [validationRules.phoneNumber(), validationRules.requiredField()],
    },
    title: {
      name: 'title',
      label: t('title.LABEL'),
      placeholder: t('title.PLACEHOLDER'),
    },
    organizationId: {
      name: 'organizationId',
      label: t('organization.LABEL'),
      placeholder: t('organization.PLACEHOLDER'),
      rules: [validationRules.requiredField()],
    },
  };

  return { addInfoFormFields };
};

export default useGetFormData;
