import { css, styled } from 'styled-components';

export const SFlyerWrapper = styled('div')`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  width: 1275px;
  height: 1650px;
  background-color: ${({ theme }) => theme.colorWhitePrimary};
`;

export const SFlyerBody = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  position: absolute;
  padding: 0px 64px;
`;

export const SCircleWrapper = styled('div')<{
  size?: number;
  color?: string;
  left?: number;
  right?: number;
  top?: number;
  bottom?: number;
}>`
  position: absolute;
  ${({ left }) =>
    left !== undefined &&
    css`
      left: ${left}px;
    `};
  ${({ right }) =>
    right !== undefined &&
    css`
      right: ${right}px;
    `};
  ${({ top }) =>
    top !== undefined &&
    css`
      top: ${top}px;
    `};
  ${({ bottom }) =>
    bottom !== undefined &&
    css`
      bottom: ${bottom}px;
    `};
`;
