import { useTranslation } from '@packages/utils'; 
import { TEditableInputProps } from './EditableInput.types';
import { SEditableInput } from './EditableInput.styles';

const EditableInput = ({ ...props }: TEditableInputProps) => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.generic-components.editable',
  });
  const { goBackTooltip = t('GO_BACK_LABEL'), ...restProps } = props;
  return <SEditableInput {...restProps} goBackTooltip={goBackTooltip} />;
};

export default EditableInput;
