import { useTranslation } from '@packages/utils';
import { TColumnType } from 'components/Base/Table/Table.types';
import useGetTableColumnData from 'components/Base/Table/components/SearchFilter';
import Tags from 'components/Shared/Tags';
import {
  EELCPlacesColumnKey,
  TELCPlacesUI,
  TElcDetailUI,
} from 'data/types/elcs.types';
import { ETagType } from 'data/types/tags.types';
import { ETagsUrls } from 'data/urls/tags.url';
import { elcsApi } from 'redux/apiSlice/elcs.slice';
import { organizationsApi } from 'redux/apiSlice/organizations.slice';
import RemoveELCPlace from './ELCPlacesActions/RemoveELCPlace';

const useGetData = (elcId: TElcDetailUI['elcId'], isEditable?: boolean) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.elc-places-tab.list.table.title',
  });
  const { getTableColumnSearchProps } = useGetTableColumnData();

  const defaultColumns: TColumnType<TELCPlacesUI>[] = [
    {
      title: t('ORGANIZATION'),
      dataIndex: 'name',
      key: EELCPlacesColumnKey.ORGANIZATION,
      sorter: true,
      ...getTableColumnSearchProps<TELCPlacesUI>(),
    },
    {
      title: t('ADDRESS'),
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: t('TAG'),
      dataIndex: 'tags',
      key: EELCPlacesColumnKey.TAGS,
      filters: [],
      width: 50,
      onCell: () => ({
        className: 'custom-cell',
        onClick: e => {
          e.stopPropagation();
        },
      }),
      render: (text, record) => (
        <Tags
          selectedTags={record.tags || []}
          associationId={record.organizationId}
          url={ETagsUrls.ORGANIZATION}
          invalidateTagsConfig={[
            { invalidateTag: 'elc-places', sliceName: elcsApi },
            { invalidateTag: 'organizations', sliceName: organizationsApi },
          ]}
          tagType={ETagType.ORGANIZATION}
          isEditable={isEditable}
        />
      ),
    },
    ...(isEditable
      ? [
          {
            title: t('ACTIONS'),
            key: 'actions',
            dataIndex: 'actions',
            fixed: 'right',
            width: 120,
            onCell: () => ({
              style: { minWidth: '120px' },
              onClick: e => {
                e.stopPropagation();
              },
            }),
            render: (text, record: TELCPlacesUI) => (
              <RemoveELCPlace elcId={elcId} placeId={record.placeId} />
            ),
          } as TColumnType<TELCPlacesUI>,
        ]
      : []),
  ];
  return { defaultColumns };
};

export default useGetData;
