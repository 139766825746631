import { FC } from 'react';
import { useTranslation } from '@packages/utils';
import {
  useShowToastNotification,
  ENotificationType,
} from 'components/Base/Notification';
import { useEditDescriptionMutation } from 'redux/apiSlice/workshops.slice';
import { TRtkErrorType } from 'data/types/generic.types';
import EditableTextArea from 'components/Base/EditableFields/EditableTextArea';
import { TDescriptionProps } from './Description.types';

const Description: FC<TDescriptionProps> = ({ id, description }) => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.workshops.details.description',
  });
  const { showToastNotification } = useShowToastNotification();
  const [editDescription, { isLoading }] = useEditDescriptionMutation();

  const onDescriptionEdit = async (value: string) => {
    try {
      await editDescription({
        id,
        value,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (err) {
      showToastNotification({
        message: (err as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };
  return (
    <EditableTextArea
      fitContent={false}
      wrapperClassName="pb-3 word-break"
      title={description?.value}
      emptyText={t('EMPTY_TEXT')}
      onEdit={onDescriptionEdit}
      isLoading={isLoading}
      showCount
      maxLength={350}
      readonly={description?.allowedOperations.readonly}
      canRemove={description?.allowedOperations.canRemove}
    />
  );
};

export default Description;
