import { Col, Row } from '@packages/utils';
import { useTheme } from 'styled-components';
import { ParagraphEllipsis } from 'components/Shared/ParagraphEllipsis';
import { EIconNames, Icon } from 'components/Base/Icon';
import { SLinkItemWrapper } from './LinkItem.styles';
import { TLinkItemProps } from './LinkItem.types';
import RemoveResource from '../../components/RemoveResource';

const LinkItem = ({ data, elcId, isResourceEditable }: TLinkItemProps) => {
  const theme = useTheme();
  const { resourceId, file } = data;

  return (
    <SLinkItemWrapper>
      <Row align="middle" wrap={false}>
        <Col className="mr-1">
          <Icon icon={EIconNames.LINK} color={theme.colorPrimaryDefault} />
        </Col>
        <Col>
          <a
            target="_blank"
            style={{ color: theme.colorBlackDescription }}
            href={file.url}
            rel="noreferrer"
          >
            <ParagraphEllipsis text={file.name} textMaxLength={20} />
            {/*  */}
          </a>
        </Col>
        {isResourceEditable && (
          <RemoveResource
            elcId={elcId}
            resourceId={resourceId}
            iconName={EIconNames.CLOSE}
            iconSize={16}
            className="ml-1"
          />
        )}
      </Row>
    </SLinkItemWrapper>
  );
};

export default LinkItem;
