import { styled } from 'styled-components';
import { Paragraph } from 'components/Base/Typography';
import { Row } from 'components/Base/Grid';

export const SStep = styled.span`
  color: ${({ theme }) => theme.colorPinkActive};
  font-weight: ${({ theme }) => theme.fontWeightSemiBold};
  margin-right: ${({ theme }) => theme.marginS}px;
`;

export const STextWithHorizontalLine = styled(Paragraph)`
  width: 100%;
  text-align: center;
  border-bottom: 1px solid ${({ theme }) => theme.colorPrimaryDisabled};
  line-height: 0.1em;
  span {
    background: ${({ theme }) => theme.colorWhitePrimary};
    padding: 0 ${({ theme }) => theme.paddingSm}px;
    color: ${({ theme }) => theme.colorPrimaryDisabled};
  }
`;

export const QRWrapper = styled(Row)`
  max-width: 624px;
`;

export const SAuthAppLinks = styled.span`
  color: ${({ theme }) => theme.colorPrimaryDefault};
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`;

export const SAuthenticationContentWrapper = styled.div``;
