import { FC } from 'react';
import { Row, Col } from 'components/Base/Grid';
import { TAgeGroupesColumnProps } from './AgeGroupesColumn.types';
import AgeGroupItem from './AgeGroupItem';

const AgeGroupesColumn: FC<TAgeGroupesColumnProps> = ({
  englishSessions,
  spanishSessions,
}) => {
  if (!englishSessions?.length && !spanishSessions?.length) {
    return <>-</>;
  }
  return (
    <Row wrap={false}>
      {!!englishSessions?.length && (
        <Col>
          {englishSessions.map(session => (
            <AgeGroupItem
              key={session.ageGroup}
              color="pink"
              ageGroup={session.ageGroup}
              language={session.language.slice(0, 1)}
              count={session.count}
            />
          ))}
        </Col>
      )}
      {!!spanishSessions?.length && (
        <Col>
          {spanishSessions.map(session => (
            <AgeGroupItem
              key={session.ageGroup}
              color="success"
              ageGroup={session.ageGroup}
              language={session.language.slice(0, 1)}
              count={session.count}
            />
          ))}
        </Col>
      )}
    </Row>
  );
};
export default AgeGroupesColumn;
