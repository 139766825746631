import { useTranslation } from '@packages/utils';
import useGetValidationData from 'components/Base/Form/validationRules';

const useGetFormData = () => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.parents.list.actions.mark-as-ordered.modal.form',
  });
  const { validationRules } = useGetValidationData();
  const markAsOrderedFormFields = {
    orderDate: {
      name: 'orderDate',
      label: t('orderDate.LABEL'),
      placeholder: t('orderDate.PLACEHOLDER'),
      rules: [validationRules.requiredField()],
    },
  };

  return { markAsOrderedFormFields };
};

export default useGetFormData;
