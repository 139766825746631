import { FC } from 'react';
import { useTranslation, Tag, TagColors } from '@packages/utils';
import { EParentOrderStatus } from 'data/types/kitManagement.types';
import { TParentOrderStatusProps } from './parentOrderStatus.types';

const ParentOrderStatus: FC<TParentOrderStatusProps> = ({
  text,
  className,
}) => {
  const orderStatusColorMapper: Record<EParentOrderStatus, TagColors> = {
    [EParentOrderStatus.ORDERED]: 'primary',
    [EParentOrderStatus.NOT_ORDERED]: 'pink',
    [EParentOrderStatus.NO_ADDRESS]: 'purple',
  };
  const { t } = useTranslation({
    keyPrefix: 'enums.parent-order-status',
  });

  if (!text) return null;
  return (
    <Tag className={className} color={orderStatusColorMapper[text]}>
      {t(text)}
    </Tag>
  );
};

export default ParentOrderStatus;
