import {
  TAuthConverter,
  TCreateConverter,
  TFetchConverter,
} from 'data/types/converter.types';
import {
  TAddNewUserDB,
  TAddNewUserUI,
  TDeleteUserDB,
  TDeleteUserUI,
  TPermissionsTypeDB,
  TPermissionsTypeUI,
  TUpdateUserDB,
  TUpdateUserUI,
  TUserDataDB,
  TUserDataUi,
  TUserIdentityDB,
  TUserIdentityUI,
  TUserListDataDB,
  TUserListDataUI,
} from 'data/types/user.types';
import { phoneNumberConverter } from './general.converters';

const permissionsConverter: TFetchConverter<
  TPermissionsTypeUI[],
  TPermissionsTypeDB[]
> = {
  fromDb: data => {
    const newPermissions: TPermissionsTypeUI[] = [...data, 'SECURITY_VIEW'];
    return newPermissions;
  },
};

export const getUserProfileConvertor: TAuthConverter<
  void,
  void,
  TUserDataUi,
  TUserDataDB
> = {
  toDb: () => {
    /* do nothing */
  },
  fromDb: data => ({
    email: data.email,
    firstName: data.firstName,
    lastName: data.lastName,
    phone: data.phone,
    profilePicture: data.profilePicture,
    type: data.type,
    permissions: permissionsConverter.fromDb(data.permissions),
    userId: data.userId,
  }),
};

export const userListGetConverter: TFetchConverter<
  TUserListDataUI,
  TUserListDataDB
> = {
  fromDb: data => ({
    createdDate: data.createdDate,
    adminId: data.userId,
    fullName: data.fullName,
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    phone: phoneNumberConverter.fromDb(data.phone || ''),
  }),
};

export const userIdentityGetConverter: TFetchConverter<
  TUserIdentityUI,
  TUserIdentityDB
> = {
  fromDb: data => ({
    email: data.email,
    id: data.id,
    fullName: data.fullName,
  }),
};

// export const addNewUserConverter: TCreateConverter<
//   TAddNewUserUI,
//   TAddNewUserDB
// > = {
//   toDb: data => ({
//     firstName: data.firstName,
//     lastName: data.lastName,
//     email: data.email,
//     phone: phoneNumberConverter.toDb(data.phone) || '',
//     profileType: data.profileType,
//   }),
// };

export const addNewUserConverter: TCreateConverter<
  TAddNewUserUI,
  TAddNewUserDB
> = {
  toDb: data => ({
    userId: data.userId,
    profileType: data.profileType,
  }),
};

export const deleteUserConverter: TCreateConverter<
  TDeleteUserUI,
  TDeleteUserDB
> = {
  toDb: data => ({
    userId: data.userId,
  }),
};

export const updateUserConverter: TCreateConverter<
  TUpdateUserUI,
  TUpdateUserDB
> = {
  toDb: data => ({
    userId: data.userId,
    firstName: data.firstName,
    lastName: data.lastName,
    phone: phoneNumberConverter.toDb(data.phone) || '',
  }),
};
