import { useTranslation } from '@packages/utils';
import { TColumnType } from 'components/Base/Table/Table.types';
import {
  EOrganizationFilterParams,
  EOrganizationSortParams,
  TOrganizationDataUI,
} from 'data/types/organizations.types';
import useGetTableColumnData from 'components/Base/Table/components/SearchFilter';
import { ETagType } from 'data/types/tags.types';
import { ETagsUrls } from 'data/urls/tags.url';
import Tags from 'components/Shared/Tags';
import { organizationsApi } from 'redux/apiSlice/organizations.slice';
import { elcsApi } from 'redux/apiSlice/elcs.slice';

export const organizationListKey =
  'descriptions.admin-portal.organizations.list';

const useGetColumnsData = () => {
  const { getTableColumnSearchProps } = useGetTableColumnData();
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.organizations.list.table.title',
  });
  const defaultColumns: TColumnType<TOrganizationDataUI>[] = [
    {
      title: t('CREATE_DATE'),
      dataIndex: 'createdDate',
      key: EOrganizationSortParams.CREATION_DATE,
      sorter: true,
    },
    {
      title: t('ORGANIZATION_NAME'),
      dataIndex: 'name',
      key: EOrganizationFilterParams.NAME_CONTAINS,
      sorter: true,
      ...getTableColumnSearchProps<TOrganizationDataUI>(),
    },
    {
      title: t('ADDRESS'),
      dataIndex: ['address', 'formattedAddress'],
      key: EOrganizationFilterParams.CITIES,
      filters: [],
      filterSearch: true,
    },
    {
      title: t('TAG'),
      dataIndex: 'tags',
      key: EOrganizationFilterParams.TAGS,
      filters: [],
      width: 50,
      fixed: 'right',
      filterSearch: true,
      onCell: () => ({
        className: 'custom-cell',
        onClick: e => {
          e.stopPropagation();
        },
      }),
      render: (text, record) => (
        <Tags
          selectedTags={record.tags || []}
          associationId={record.organizationId}
          url={ETagsUrls.ORGANIZATION}
          invalidateTagsConfig={[
            { invalidateTag: 'organizations', sliceName: organizationsApi },
            { invalidateTag: 'elc-places', sliceName: elcsApi },
          ]}
          tagType={ETagType.ORGANIZATION}
        />
      ),
    },
  ];

  return { defaultColumns };
};

export default useGetColumnsData;
