import { FC } from 'react';
import MarkAsOrderedAction from './MarkAsOrderedAction';
import { TActionProps } from './MarkAsOrdered.types';

const MarkAsOrdered: FC<TActionProps> = ({ setShowAlert, selectedRows }) => {
  return (
    <MarkAsOrderedAction
      selectedRows={selectedRows}
      setShowAlert={setShowAlert}
    />
  );
};

export default MarkAsOrdered;
