import { TPermissionsTypeUI, TUserDataUi } from 'data/types/user.types';
import { useAppSelector } from 'redux/store';

export const useCheckPermission = (
  permission: TPermissionsTypeUI | TPermissionsTypeUI[],
  matchAllPermissions?: boolean,
): boolean => {
  const usersData: TUserDataUi = useAppSelector(state => state.userSlice.user);
  if (!usersData) return false;
  const permissions = usersData?.permissions;
  if (!usersData?.permissions) return false;
  if (Array.isArray(permission)) {
    if (matchAllPermissions) {
      return permission.every(p => permissions.includes(p));
    }
    return permission.some(p => permissions.includes(p));
  }
  return permissions.includes(permission);
};
