import { Card } from '@packages/utils';
import { styled } from 'styled-components';

export const QuestionContentWrapper = styled(Card)`
  .ant-row {
    position: absolute;
    right: 16px;
    top: 0;
  }
`;
