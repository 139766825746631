import { appLocalStorage, EStorageNames } from './storage';

export const migrateCleanUpStorage = () => {
  const cleanUpStorage = appLocalStorage.getItem(
    EStorageNames.cleanUpStorage_V7,
  );

  if (cleanUpStorage) return;

  appLocalStorage.removeItem(EStorageNames.filters);
  appLocalStorage.removeItem(EStorageNames.tableSettings);
  appLocalStorage.removeItem(EStorageNames.updatePageSizeDefaultValue);
  appLocalStorage.removeItem(EStorageNames.removeWorkshopFilters);
  appLocalStorage.removeItem(EStorageNames.removeKitManagementTablesSettings);
  appLocalStorage.removeItem(EStorageNames.removeRegistrantsTablesSettings);
  appLocalStorage.removeItem(EStorageNames.removeGlobalWaitlistTableSettings);
  appLocalStorage.removeItem(EStorageNames.removeParentOrderTableData);
  appLocalStorage.removeItem(EStorageNames.cleanUpStorage_V1);
  appLocalStorage.removeItem(EStorageNames.cleanUpStorage_V2);
  appLocalStorage.removeItem(EStorageNames.cleanUpStorage_V3);
  appLocalStorage.removeItem(EStorageNames.cleanUpStorage_V4);
  appLocalStorage.removeItem(EStorageNames.cleanUpStorage_V5);
  appLocalStorage.removeItem(EStorageNames.cleanUpStorage_V6);

  appLocalStorage.setItem(EStorageNames.cleanUpStorage_V7, true);
};
