import { useEffect, useRef, useState } from 'react';
import get from 'lodash/get';
import { useTranslation, Button, Card, Row, Col } from '@packages/utils';
import { useAppSelector, useAppDispatch } from 'redux/store';
import userAmplifySlice from 'redux/slices/userAmplify.slices';
import useGetValidationData from 'components/Base/Form/validationRules/index';
import { QRCode } from 'components/Base/QRCode';
import { MessageLabel, Paragraph } from 'components/Base/Typography';
import { Icon, EIconNames } from 'components/Base/Icon';
import { Form, FormItem } from 'components/Base/Form';
import { Switch } from 'components/Base/Switch';
import { VerificationCode } from 'components/Base/VerificationCode';
import {
  useShowToastNotification,
  ENotificationType,
} from 'components/Base/Notification';
import useForm from 'hooks/useForm';
import {
  useSetPreferredMFAMutation,
  useVerifyTotpTokenMutation,
  useGetTOTPCodeMutation,
  currentAmplifyAuthUser,
} from 'redux/apiSlice/auth.slice';
import {
  EPreferredMFATypes,
  EVerifyTotpToken,
  TMFAMethod,
} from 'data/types/amplify.types';
import useCopyToClipboard from 'hooks/useCopyClipboard';
import {
  QRWrapper,
  SAuthAppLinks,
  SAuthenticationContentWrapper,
  SStep,
  STextWithHorizontalLine,
} from './Authentication.styles';
import AuthAppDownloadRedirectModal from './AuthAppDownloadRedirectModal';

const Authentication = () => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.authentication.twoFA',
  });
  const appDownloadModalRef = useRef<{ open: (opSystem: string) => void }>({
    open: () => {
      // open approve modal
    },
  });
  const { validationRules } = useGetValidationData();
  const { showToastNotification } = useShowToastNotification();
  const dispatch = useAppDispatch();
  const [error, setError] = useState<string>('');
  const [qrCodeValue, setQrCodeValue] = useState<string>('');
  const [showAuthContent, setShowAuthContent] = useState<boolean>(false);
  const [oneTimeCode, setOneTimeCode] = useState<string>('');
  const amplifyUser = useAppSelector(state => state.userSlice.amplifyUser);
  const isAuthActivated = amplifyUser?.preferredMFA === EPreferredMFATypes.TOTP;
  const [isMfaEnabled, setIsMfaEnabled] = useState<boolean>(isAuthActivated);
  const [isCopied, copyToClipboard] = useCopyToClipboard();
  const { form } = useForm<{ code: string }>();
  const [verifyTotpToken, { isLoading }] = useVerifyTotpTokenMutation();
  const [verify2FAActivation, { isLoading: is2FAActivationLoading }] =
    useSetPreferredMFAMutation();

  const [getTOTPCode, { isLoading: isCodeGettingLoading }] =
    useGetTOTPCodeMutation();

  const handle2FAActivation = async (MFAMethod: TMFAMethod) => {
    try {
      const user = await currentAmplifyAuthUser();
      await verify2FAActivation({
        user,
        MFAMethod,
      }).unwrap();
      const modifiedAmplifyUser = { ...amplifyUser, preferredMFA: MFAMethod };
      dispatch(userAmplifySlice.actions.setAmplifyUser(modifiedAmplifyUser));
      showToastNotification({
        message: t('SUCCESS_MESSAGE', {
          placeholder:
            MFAMethod === EPreferredMFATypes.NOMFA
              ? t('DEACTIVATE')
              : t('ACTIVATE'),
        }),
        type: ENotificationType.SUCCESS,
      });
      form.resetFields();
      setOneTimeCode('');
      setError('');
      setShowAuthContent(false);
    } catch (err) {
      const message = get(err, ['data', 'message']);
      showToastNotification({
        message,
        type: ENotificationType.ERROR,
      });
    }
  };

  useEffect(() => {
    if (!isMfaEnabled && showAuthContent) {
      return;
    }
    (async () => {
      const user = await currentAmplifyAuthUser();
      // @ts-ignore
      const { data } = await getTOTPCode({ user });
      setOneTimeCode(data);
      setQrCodeValue(
        `otpauth://totp/${amplifyUser.attributes.email}?secret=${data}&issuer=GROW`,
      );
    })();
  }, [isMfaEnabled, showAuthContent]);

  const onSubmit = async () => {
    const values = await form.validateFields();
    try {
      const user = await currentAmplifyAuthUser();
      const data = await verifyTotpToken({
        user,
        challengeAnswer: values.code,
      }).unwrap();
      if (data.Status === EVerifyTotpToken.SUCCESS) {
        await handle2FAActivation(EPreferredMFATypes.TOTP);
      }
    } catch (err) {
      const message = get(err, ['data', 'message']);
      setError(message);
    }
  };

  const handleSwitchChange = async (isEnabled: boolean) => {
    setIsMfaEnabled(isEnabled);
    if (!isEnabled) {
      setOneTimeCode('');
      setShowAuthContent(false);
      handle2FAActivation(EPreferredMFATypes.NOMFA);
    }
  };

  return (
    <>
      <Row gutter={[24, 12]} align="middle">
        <Col>
          <Switch
            checkedChildren={t('radio.ON')}
            unCheckedChildren={t('radio.OFF')}
            onChange={handleSwitchChange}
            checked={isMfaEnabled}
            loading={is2FAActivationLoading || isCodeGettingLoading}
          />
        </Col>
        {isMfaEnabled && isAuthActivated && (
          <Col>
            <Button
              size="small"
              color="error"
              type="default"
              onClick={() => setShowAuthContent(true)}
            >
              {t('SETUP_NEW_METHOD_BTN')}
            </Button>
          </Col>
        )}
      </Row>
      {((isMfaEnabled &&
        oneTimeCode &&
        amplifyUser?.preferredMFA !== EPreferredMFATypes.TOTP) ||
        showAuthContent) && (
        <SAuthenticationContentWrapper className="mt-4">
          <Paragraph className="mb-3">
            <SStep>{t('step.one.TITLE')}</SStep>
            {t('step.one.DESCRIPTION')}{' '}
            <SAuthAppLinks
              onClick={() =>
                appDownloadModalRef.current.open(t('step.one.ANDROID'))
              }
            >
              {t('step.one.ANDROID')}
            </SAuthAppLinks>{' '}
            {t('step.one.OR')}{' '}
            <SAuthAppLinks
              onClick={() =>
                appDownloadModalRef.current.open(t('step.one.IOS'))
              }
            >
              {t('step.one.IOS')}
            </SAuthAppLinks>
          </Paragraph>
          <Paragraph>
            <SStep>{t('step.two.TITLE')}</SStep>
            {t('step.two.DESCRIPTION')}
          </Paragraph>
          <QRWrapper>
            <QRCode
              value={qrCodeValue}
              size={144}
              className="mb-6 mt-4 m-auto"
            />
            <STextWithHorizontalLine>
              <span>{t('step.two.OR')}</span>
            </STextWithHorizontalLine>
            <Row align="middle" gutter={[16, 16]} wrap={false} className="mt-6">
              <Col>
                <Card>{oneTimeCode}</Card>
              </Col>
              <Col onClick={() => copyToClipboard(oneTimeCode)}>
                <Icon
                  icon={isCopied ? EIconNames.CHECK : EIconNames.COPY}
                  alt="copy"
                  className="pointer"
                  tooltip={{ title: 'Copy' }}
                />
              </Col>
            </Row>
          </QRWrapper>
          <Paragraph className="mt-4 mb-3">
            <SStep>{t('step.three.TITLE')}</SStep>
            {t('step.three.DESCRIPTION')}
          </Paragraph>
          <Row>
            <Form
              form={form}
              name="login"
              layout="vertical"
              requiredMark={false}
              onFinish={onSubmit}
              initialValues={{
                code: '',
              }}
            >
              <FormItem
                name="code"
                className="w-fit-content"
                rules={[validationRules.required()]}
              >
                <VerificationCode onChange={() => setError('')} />
              </FormItem>
              <Row gutter={[16, 16]} align="middle" className="mt-6">
                <Col>
                  <Button
                    color="primary"
                    type="primary"
                    htmlType="submit"
                    loading={isLoading || is2FAActivationLoading}
                  >
                    {t('SUBMIT_BTN')}
                  </Button>
                </Col>
                {isAuthActivated && (
                  <Col>
                    <Button
                      color="primary"
                      type="default"
                      onClick={() => setShowAuthContent(false)}
                    >
                      {t('CANCEL_BTN')}
                    </Button>
                  </Col>
                )}
              </Row>
              <div>
                <MessageLabel type="danger">{error}</MessageLabel>
              </div>
            </Form>
          </Row>
        </SAuthenticationContentWrapper>
      )}
      <AuthAppDownloadRedirectModal ref={appDownloadModalRef} />
    </>
  );
};

export default Authentication;
