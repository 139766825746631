export enum EPeopleUrls {
  BASE = 'people',
  IDENTITY = 'identity',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  TITLE = 'title',
  EMAIL = 'email',
  PHONE = 'phone',
  ADDITIONAL_EMAIL = 'additional-email',
  ADDITIONAL_PHONE = 'additional-phone',
  PEOPLE_ORGANIZATIONS = 'people-organizations',
}
