import { Card } from '@packages/utils';
import styled from 'styled-components';

export const SCard = styled(Card)`
  &.ant-card {
    .ant-card-body {
      padding-top: 20px;
    }
  }
`;
