import { useNavigate } from 'react-router-dom';
import { Col, Title } from '@packages/utils';
import { TBackButtonState } from 'components/Base/PageWrapper/PageWrapper.types';
import { TELCItemProps } from './ELCItem.types';
import AEYCLead from './components/AEYCLead';
import FirstPM from './components/FirstPM';
import SecondPM from './components/SecondPM';
import { SCard } from './ELCItem.styles';

const ELCItem = ({
  elcData,
  isEditAllowed,
  backButtonSuffix,
}: TELCItemProps) => {
  const navigate = useNavigate();

  const { name, lead, firstProjectManager, elcId, secondProjectManager } =
    elcData;

  const navigateToElcDetails = () => {
    navigate(elcId, {
      state: {
        backUrl: window.location.pathname,
        backButtonSuffix,
        tooltip: backButtonSuffix,
      } as TBackButtonState,
    });
  };

  return (
    <Col md={8}>
      <SCard className="h-100" onClick={navigateToElcDetails}>
        <Title
          className="card-title"
          level={4}
          ellipsis={{
            tooltip: name,
          }}
        >
          {name}
        </Title>
        <AEYCLead data={lead} elcId={elcId} isEditAllowed={isEditAllowed} />
        <FirstPM
          data={firstProjectManager}
          elcId={elcId}
          isEditAllowed={isEditAllowed}
        />
        <SecondPM
          data={secondProjectManager}
          elcId={elcId}
          isEditAllowed={isEditAllowed}
        />
      </SCard>
    </Col>
  );
};

export default ELCItem;
