import { useTranslation } from '@packages/utils';
import SectionsContent from 'components/Base/Layout/Content/SectionsContent';
import { TSectionProps } from 'components/Base/Layout/Content/SectionsContent/SectionsContent.types';
import TeamSection from './TeamSection';
import DetailsSection from './DetailsSection';
import { TDetailsTabProps } from './DetailsTab.types';
import AddDetailAction from './DetailsSection/AddDetailAction';
import AreaServedSection from './AreaServedSection';

const DetailsTab = ({ data, elcId, isEditable }: TDetailsTabProps) => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.elcs.detail.details-tab',
  });

  if (!data) {
    return <></>;
  }

  const sectionsInfo: TSectionProps[] = [
    {
      id: 1,
      title: t(`team-section.TITLE`),
      content: (
        <TeamSection isEditable={isEditable} data={data} elcId={elcId} />
      ),
    },
    {
      id: 2,
      title: t(`area-served-section.TITLE`),
      content: (
        <AreaServedSection elcBoundaries={data.elcBoundaries} elcId={elcId} />
      ),
    },
    {
      id: 3,
      title: t(`details-section.TITLE`),
      ...(isEditable ? { actions: <AddDetailAction elcId={elcId} /> } : {}),
      content: (
        <DetailsSection
          elcId={elcId}
          isEditable={isEditable}
          data={data.elcNotes}
        />
      ),
    },
  ];

  return <SectionsContent sections={sectionsInfo} />;
};

export default DetailsTab;
