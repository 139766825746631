import { FC } from 'react';
import { useTheme } from 'styled-components';
import { useTranslation, Button } from '@packages/utils';
import { EIconNames, Icon } from 'components/Base/Icon';
import { TActionButtonProps } from '../Actions.types';

const ActionButton: FC<TActionButtonProps> = props => {
  const theme = useTheme();
  const { type, onClick } = props;

  const { t } = useTranslation({
    keyPrefix: 'descriptions.tooltip',
  });

  switch (type) {
    case 'delete':
      return (
        <Button
          icon={
            <Icon
              alt="delete icon"
              icon={EIconNames.DELETE}
              size={14}
              color={theme.colorErrorDefault}
              className="pointer"
            />
          }
          tooltip={t('DELETE')}
          onClick={onClick}
          color="primary"
          shape="circle"
          size="middle"
        />
      );
    case 'duplicate':
      return (
        <Button
          icon={
            <Icon
              alt="duplicate icon"
              icon={EIconNames.COPY}
              size={14}
              className="pointer"
              color={theme.colorWarningDefault}
            />
          }
          tooltip={t('DUPLICATE')}
          onClick={onClick}
          color="primary"
          shape="circle"
          size="middle"
        />
      );
    case 'close':
      return (
        <Button
          icon={
            <Icon
              alt="close icon"
              icon={EIconNames.CLOSE}
              color={theme.colorErrorDefault}
              size={16}
              className="pointer"
            />
          }
          tooltip={t('CLOSE')}
          onClick={onClick}
          color="primary"
          shape="circle"
          size="middle"
        />
      );
    default:
      return null;
  }
};

export default ActionButton;
