import { FC } from 'react';
import EditableInput from 'components/Base/EditableFields/EditableInput';
import { InplaceEditLabels } from 'components/Base/EditableFields/components/InplaceEditLabel';
import { EIconNames } from 'components/Base/Icon';
import { useUpdateOrganizationNameMutation } from 'redux/apiSlice/organizations.slice';
import {
  useShowToastNotification,
  ENotificationType,
} from 'components/Base/Notification';
import { TRtkErrorType } from 'data/types/generic.types';
import { Col, Row, useTranslation } from '@packages/utils';
import { TUpdateOrganizationNameProps } from './UpdateOrganizationName.types';

const UpdateOrganizationName: FC<TUpdateOrganizationNameProps> = ({
  id,
  name,
}) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.organizations.details.details-tab.main-contact.name',
  });
  const { showToastNotification } = useShowToastNotification();

  const [updateName, { isLoading, isError }] =
    useUpdateOrganizationNameMutation();

  const onUpdateName = async (value: string) => {
    try {
      await updateName({
        id,
        value,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (e) {
      showToastNotification({
        message: (e as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };
  return (
    <Row align="middle" wrap={false} className="w-100">
      <Col>
        <InplaceEditLabels
          label={t('LABEL')}
          icon={EIconNames.PEOPLE_GROUP_LABEL}
        />
      </Col>
      <Col flex="auto">
        <EditableInput
          onEdit={onUpdateName}
          title={name.value}
          fitContent={false}
          isError={isError}
          isLoading={isLoading}
          readonly={name?.allowedOperations.readonly}
          canRemove={name?.allowedOperations.canRemove}
          backIcon
          placeholder={t('PLACEHOLDER')}
        />
      </Col>
    </Row>
  );
};

export default UpdateOrganizationName;
