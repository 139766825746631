import { FC, useState } from 'react';
import { useTranslation, Button } from '@packages/utils';
import { useTheme } from 'styled-components';
import { Modal } from 'components/Base/Modal';
import { Row, Col } from 'components/Base/Grid';
import { Image } from 'components/Base/Image';
import QuestionMark from 'assets/images/questionMark.png';
import { Paragraph } from 'components/Base/Typography';
import {
  useShowToastNotification,
  ENotificationType,
} from 'components/Base/Notification';
import { TRtkErrorType } from 'data/types/generic.types';
import { EOrderStatus } from 'data/types/kitManagement.types';
import { TExportActionProps } from './ExportActions.types';

const ExportAction: FC<TExportActionProps> = ({
  onSubmit,
  confirmLoading,
  selectedRows,
  setShowAlert,
}) => {
  const theme = useTheme();
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.kit-management.list.actions.export',
  });
  const { showToastNotification } = useShowToastNotification();
  const countOfOrderedRows = selectedRows?.filter(
    row => row.status === EOrderStatus.ORDERED,
  ).length;
  const isAllRowsOrdered = countOfOrderedRows === selectedRows?.length;
  const showConfirmModal = countOfOrderedRows >= 1;
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = async () => {
    try {
      await onSubmit();
    } catch (err) {
      showToastNotification({
        message: (err as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
    handleClose();
  };

  const handleActionButtonClick = () => {
    if (selectedRows.length) {
      if (showConfirmModal) {
        handleOpen();
      } else {
        handleSubmit();
      }
    } else {
      setShowAlert(true);
    }
  };

  return (
    <Col>
      <Button type="primary" color="primary" onClick={handleActionButtonClick}>
        {t('EXPORT_BTN')}
      </Button>

      <Modal
        open={open}
        title={t('modal.TITLE')}
        okText={t('modal.SUBMIT_BTN')}
        cancelText={t('modal.CANCEL_BTN')}
        onCancel={handleClose}
        onOk={handleSubmit}
        confirmLoading={confirmLoading}
      >
        <Row justify="center" className="text-center">
          <Image src={QuestionMark} alt="Question Mark" />

          <Paragraph
            fontSize={16}
            fontWeight={500}
            color={theme.colorPinkDefault}
          >
            {isAllRowsOrdered
              ? t('modal.subtitle.IS_ALL_ORDERED')
              : t('modal.subtitle.IS_SOME_ORDERED', {
                  placeholder: countOfOrderedRows,
                })}
          </Paragraph>
        </Row>
      </Modal>
    </Col>
  );
};

export default ExportAction;
