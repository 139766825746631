import { useAppSelector } from 'redux/store';
import { useTranslation } from '@packages/utils';
import { useTheme } from 'styled-components';
import { EWorkshopPartType } from 'data/types/workshops.types';
import { TSeriesDataMapperByPart } from './SeriesPart.type';

export const flyerSessionsSectionKeyPrefix =
  'descriptions.admin-portal.workshops.details.actions.download-flyer.flyer.sessions-section';
export const enumPartTypeKeyPrefix = 'enums.workshop-part-type';
export const enumAgeGroupKeyPrefix = 'enums.age-group';

const useGetSerriesPartData = () => {
  const theme = useTheme();
  const flyerLanguage = useAppSelector(state => state.appSlice.flyerLanguage);
  const { t } = useTranslation({
    ...(flyerLanguage && { lng: flyerLanguage }),
  });

  const dataMapperByPart: Record<EWorkshopPartType, TSeriesDataMapperByPart> = {
    [EWorkshopPartType.PART_ONE]: {
      part: t(`${enumPartTypeKeyPrefix}.${EWorkshopPartType.PART_ONE}`),
      title: t(`${flyerSessionsSectionKeyPrefix}.PART_1_TITLE`),
      color: theme.colorPrimaryDefault,
    },
    [EWorkshopPartType.PART_TWO]: {
      part: t(`${enumPartTypeKeyPrefix}.${EWorkshopPartType.PART_TWO}`),
      title: t(`${flyerSessionsSectionKeyPrefix}.PART_2_TITLE`),
      color: theme.colorPurpleBright,
    },
    [EWorkshopPartType.PART_THREE]: {
      part: t(`${enumPartTypeKeyPrefix}.${EWorkshopPartType.PART_THREE}`),
      title: t(`${flyerSessionsSectionKeyPrefix}.PART_3_TITLE`),
      color: theme.colorWarningActive,
    },
    // Standalone don't use this mapper
    [EWorkshopPartType.STANDALONE]: {
      part: EWorkshopPartType.STANDALONE,
      title: '',
      color: theme.colorPurpleBright,
    },
  };

  return { dataMapperByPart };
};

export default useGetSerriesPartData;
