import useSidebarContext from 'context/CollapseSidebarContext';
import { Row } from 'components/Base/Grid';
import SidebarBodyMenu from './SidebarBodyMenu';
import SidebarHeader from './SidebarHeader';
import { SSider, SSidebarBodyWrapper } from './Sidebar.styles';
import SidebarFooter from './SidebarFooter';

const Sidebar = () => {
  const { collapsed, setCollapsed } = useSidebarContext();

  return (
    <SSider
      collapsible
      trigger={null}
      collapsed={collapsed}
      onCollapse={value => setCollapsed(value)}
      width={collapsed ? 80 : 256}
    >
      <Row>
        <SidebarHeader />
      </Row>
      <SSidebarBodyWrapper>
        <SidebarBodyMenu />
      </SSidebarBodyWrapper>
      <Row>
        <SidebarFooter />
      </Row>
    </SSider>
  );
};

export default Sidebar;
