import * as React from 'react';
import { forwardRef } from 'react';
import { useTheme } from 'styled-components';
import { Col, Row } from 'components/Base/Grid';
import { Paragraph, Title } from 'components/Base/Typography';
import { EWorkshopSeriesType } from 'data/types/workshops.types';
import { useTranslation } from '@packages/utils';
import { useAppSelector } from 'redux/store';
import { FlyerWrapper } from './FlyerWrapper';
import { TFlyerProps } from './Flyers.types';
import { FlyerHeader } from './FlyerHeader';
import { QR } from './QR';
import { SessionsSection } from './SessionsSection';
import { SImage, SCard } from './Flyers.styles';
import Location from './Location';
import Address from './Address';
import useGetFlyerDescData from './Flyer.data';
import AgeGroup from './AgeGroup';
import ChildCareFoodProvided from './SessionsSection/ChildCareFoodProvided';

const Flyers = forwardRef<HTMLDivElement, TFlyerProps>((props, ref) => {
  const theme = useTheme();
  const { data, url: workshopUrl, imageUrl } = props;
  const { type, childcareProvided, foodProvided, location, flyerData } = data;

  const flyerLanguage = useAppSelector(state => state.appSlice.flyerLanguage);
  const { t } = useTranslation({
    ...(flyerLanguage && { lng: flyerLanguage }),
  });
  const { flyerDescriptionMapper } = useGetFlyerDescData();

  return (
    <FlyerWrapper ref={ref}>
      <>
        <Row gutter={[0, 16]}>
          <Col span={24}>
            <FlyerHeader type={type} />
          </Col>
          <Col span={24}>
            <Row
              align="middle"
              justify="space-between"
              wrap={false}
              gutter={[0, 16]}
            >
              <Col>
                {imageUrl && (
                  <SImage
                    src={imageUrl}
                    alt="flyer image"
                    width={566}
                    height={432}
                  />
                )}
              </Col>

              <Col>
                <Location title={location?.name} />
                <QR url={workshopUrl} />
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Title className="m-0 mb-2" level={1} color={theme.colorGreenFlyer}>
              {t(`enums.workshop-type.${type}`)}
            </Title>
            <Paragraph fontSize={theme.fontSizeHeading2}>
              {flyerDescriptionMapper[type].title}
            </Paragraph>
          </Col>
          <Col>
            <SCard className="px-7">
              <Paragraph fontSize={theme.fontSizeHeading3} className="mb-0">
                {flyerDescriptionMapper[type].description}
              </Paragraph>
            </SCard>
          </Col>
          {type === EWorkshopSeriesType.READY_FOR_KINDERGARTEN && (
            <Col>
              <AgeGroup existingAgeGroups={flyerData.existingAgeGroups} />
            </Col>
          )}
          <Col span={24}>
            <SessionsSection
              typeCategory={data.typeCategory}
              data={flyerData}
              type={type}
              isChildCareAndPreschool={
                type ===
                EWorkshopSeriesType.LEARNING_CHILD_CARE_AND_PRESCHOOL_PROGRAMS
              }
            />
          </Col>
        </Row>
        <Row className="mb-8" gutter={[0, 24]}>
          <Col span={24}>
            <ChildCareFoodProvided
              foodProvided={!!foodProvided?.value}
              childcareProvided={!!childcareProvided?.value}
            />
          </Col>
          <Col span={24}>
            <Address title={location?.address?.formattedAddress} />
          </Col>
        </Row>
      </>
    </FlyerWrapper>
  );
});

export default Flyers;
