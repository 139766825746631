import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { RoutesPaths } from 'routes/Routes.types';
import PageWrapper from 'components/Base/PageWrapper';
import { useGetOrganizationByIdQuery } from 'redux/apiSlice/organizations.slice';
import { Tabs } from 'components/Base/Tabs';
import { TabsProps } from 'components/Base/Tabs/Tabs.types';
import { useTranslation } from '@packages/utils';
import { TRtkErrorType } from 'data/types/generic.types';
import { TBackButtonState } from 'components/Base/PageWrapper/PageWrapper.types';
import useKeepTabsInUrl from 'hooks/useKeepTabsInUrl';
import { organizationDetailsKey } from './OrganizationDetails.data';
import { EOrganizationDetailsTabsInUrl } from './OrganizationDetails.types';
import DetailsTab from './DetailsTab';
import { organizationListKey } from '../OrganizationList/OrganizationList.data';
import Actions from './Actions';

const OrganizationDetails = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const { t: tOrgList } = useTranslation({
    keyPrefix: organizationListKey,
  });
  const { t } = useTranslation({
    keyPrefix: organizationDetailsKey,
  });

  const goBackState = (location.state as TBackButtonState) || {
    backUrl: `/app/${RoutesPaths.ORGANIZATION}`,
    backButtonSuffix: tOrgList('TITLE'),
  };

  const { id } = params as { id: string };
  const { data, isLoading, error } = useGetOrganizationByIdQuery({
    id,
  });
  const onBackClick = () => {
    navigate(goBackState.backUrl);
  };

  const { onTabChange, tabInUrl } = useKeepTabsInUrl({
    defaultTab: EOrganizationDetailsTabsInUrl.DETAILS,
  });

  const tabItems: TabsProps['items'] = [
    {
      key: EOrganizationDetailsTabsInUrl.DETAILS,
      label: t('tabs-titles.DETAILS'),
      children: <DetailsTab detailsData={data} />,
    },
  ];

  return (
    <PageWrapper
      title={data?.name.value}
      backButton={{
        labelPrefix: data?.name.value || '',
        tooltip: goBackState.backButtonSuffix,
        onClick: onBackClick,
      }}
      isLoading={isLoading}
      error={error as TRtkErrorType}
      actions={<Actions id={id} data={data} />}
    >
      <Tabs
        defaultActiveKey={tabInUrl}
        onChange={onTabChange}
        items={tabItems}
      />
    </PageWrapper>
  );
};

export default OrganizationDetails;
