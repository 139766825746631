import { FC } from 'react';
import { EIconNames } from 'components/Base/Icon';
import { EditableAddress } from 'components/Base/EditableFields/EditableAddress';
import { InplaceEditLabels } from 'components/Base/EditableFields/components/InplaceEditLabel';
import {
  useShowToastNotification,
  ENotificationType,
} from 'components/Base/Notification';
import { useUpdateOrganizationAddressMutation } from 'redux/apiSlice/organizations.slice';
import { TRtkErrorType } from 'data/types/generic.types';
import { Col, Row, useTranslation } from '@packages/utils';
import { TEditableAddressOnEdit } from 'components/Base/EditableFields/EditableAddress/EditableAddress.types';
import { TUpdateOrganizationAddressProps } from './UpdateOrganizationAddress.types';

const UpdateOrganizationAddress: FC<TUpdateOrganizationAddressProps> = ({
  address,
  id,
  isAddressUpdated,
}) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.organizations.details.details-tab.main-contact.address',
  });
  const { showToastNotification } = useShowToastNotification();

  const [updateOrganizationAddress, { isLoading, isError }] =
    useUpdateOrganizationAddressMutation();

  const onEdit: TEditableAddressOnEdit = async value => {
    if (value === null) {
      return;
    }
    try {
      await updateOrganizationAddress({
        id,
        address: value,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (e) {
      showToastNotification({
        message: (e as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };
  return (
    <Row align="middle" wrap={false} className="w-100">
      <Col>
        <InplaceEditLabels
          label={t('LABEL')}
          icon={EIconNames.LOCATION_OUTLINED}
        />
      </Col>
      <Col flex="auto">
        <EditableAddress
          placeholder={t('PLACEHOLDER')}
          emptyText={t('EMPTY_TEXT')}
          title={address.formattedAddress}
          onEdit={onEdit}
          isLoading={isLoading}
          isError={isError}
          required
          fitContent={false}
          readonly={address.allowedOperations.readonly}
          canRemove={address.allowedOperations.canRemove}
          tooltip={isAddressUpdated ? t('TOOLTIP') : null}
        />
      </Col>
    </Row>
  );
};

export default UpdateOrganizationAddress;
