import { dateConverter, dateShortenFormat } from '@idaho-aeyc/ui-kit';
import { TCreateConverter, TFetchConverter } from 'data/types/converter.types';
import {
  EPeoplesColumnKey,
  TAddAdditionalEmailDB,
  TAddAdditionalEmailUI,
  TAddAdditionalPhoneDB,
  TAddAdditionalPhoneUI,
  TAddPeopleOrganizationDB,
  TAddPeopleOrganizationUI,
  TAdditionalEmailsDB,
  TAdditionalEmailsUI,
  TAdditionalPhoneDB,
  TAdditionalPhoneUI,
  TPeopleOrganizationsDB,
  TPeopleOrganizationsUI,
  TDeletePeopleOrganizationDB,
  TDeletePeopleOrganizationUI,
  TPeopleByIdDB,
  TPeopleByIdUI,
  TPeopleCreateDB,
  TPeopleCreateUI,
  TPeopleGetDB,
  TPeopleGetUI,
  TPeopleUpdateDB,
  TPeopleUpdateUI,
  TPeoplesDataDB,
  TPeoplesDataUI,
  TUpdateAdditionalEmailDB,
  TUpdateAdditionalEmailUI,
  TUpdateAdditionalPhoneDB,
  TUpdateAdditionalPhoneUI,
  TUpdatePeopleEmailUI,
  TUpdatePeopleFirstNameDB,
  TUpdatePeopleFirstNameUI,
  TUpdatePeopleLEmailDB,
  TUpdatePeopleLTitleDB,
  TUpdatePeopleLastNameDB,
  TUpdatePeopleLastNameUI,
  TUpdatePeopleMobileDB,
  TUpdatePeopleMobileUI,
  TUpdatePeopleOrganizationTitleDB,
  TUpdatePeopleOrganizationTitleUI,
  TUpdatePeopleTitleUI,
} from 'data/types/people.types';
import {
  TAllowedOperationsValueDB,
  TAllowedOperationsValueUI,
  TTableFilters,
} from 'data/types/generic.types';
import {
  allowOperationsConverter,
  allowOperationsWithPhoneNumberConverter,
  allowOperationsWithStringValueConverter,
  phoneNumberConverter,
} from './general.converters';
import { tagsGetConverter } from './tags.convertor';

export const peoplesGetConverter: TFetchConverter<
  TPeoplesDataUI,
  TPeoplesDataDB
> = {
  fromDb: data => ({
    userId: data.userId,
    createdDate:
      data.createdDate && dateConverter(data.createdDate, dateShortenFormat),
    fullName: data.fullName,
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    phone: data.phone && phoneNumberConverter.fromDb(data.phone),
    locked: data.locked,
    tags: tagsGetConverter.fromDb(data.tags),
    title: data.title,
  }),
};

export const peopleCreateConverter: TCreateConverter<
  TPeopleCreateUI,
  TPeopleCreateDB
> = {
  toDb: data => ({
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    phone: phoneNumberConverter.toDb(data.phone) || '',
    title: data.title,
  }),
};

export const peopleUpdateConverter: TCreateConverter<
  TPeopleUpdateUI,
  TPeopleUpdateDB
> = {
  toDb: data => ({
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    phone: phoneNumberConverter.toDb(data.phone) || '',
    userId: data.userId,
    title: data.title,
  }),
};

export const peopleGetConverter: TFetchConverter<TPeopleGetUI, TPeopleGetDB> = {
  fromDb: data => ({
    value: data.id,
    label: data.name,
  }),
};

export const peopleFilterConverter = (
  filters: TTableFilters<EPeoplesColumnKey>['filters'],
) => {
  const filtersToDB = {
    ...(filters?.firstName && {
      [EPeoplesColumnKey.FIRST_NAME]: filters.firstName.toString().trim(),
    }),
    ...(filters?.lastName && {
      [EPeoplesColumnKey.LAST_NAME]: filters.lastName.toString().trim(),
    }),
    ...(filters?.tagIds && {
      [EPeoplesColumnKey.TAGS]: filters?.tagIds,
    }),
  };
  return filtersToDB;
};

export const allowOperationsForAdditionalEmailValue: TFetchConverter<
  TAllowedOperationsValueUI<TAdditionalEmailsUI[]>,
  TAllowedOperationsValueDB<TAdditionalEmailsDB[]>
> = {
  fromDb: data => {
    return {
      value: data.value,
      allowedOperations: allowOperationsConverter.fromDb(
        data.allowedOperations,
      ),
    };
  },
};

export const peopleOrganizationsValues: TFetchConverter<
  TPeopleOrganizationsUI,
  TPeopleOrganizationsDB
> = {
  fromDb: data => {
    return {
      organizationId: data.organizationId,
      organizationName: data.organizationName,
      userOrganizationId: data.userOrganizationId,
      title: allowOperationsWithStringValueConverter.fromDb(data.title),
    };
  },
};

export const allowOperationsForPeopleOrganizationsValue: TFetchConverter<
  TAllowedOperationsValueUI<TPeopleOrganizationsUI[]>,
  TAllowedOperationsValueDB<TPeopleOrganizationsDB[]>
> = {
  fromDb: data => {
    return {
      value: data.value?.map(item => peopleOrganizationsValues.fromDb(item)),
      allowedOperations: allowOperationsConverter.fromDb(
        data.allowedOperations,
      ),
    };
  },
};

export const allowAdditionalPhoneValue: TFetchConverter<
  TAdditionalPhoneUI,
  TAdditionalPhoneDB
> = {
  fromDb: data => {
    return {
      additionalPhoneId: data.additionalPhoneId,
      phone: phoneNumberConverter.fromDb(data.phone),
    };
  },
};

export const allowOperationsForAdditionalPhoneValue: TFetchConverter<
  TAllowedOperationsValueUI<TAdditionalPhoneUI[]>,
  TAllowedOperationsValueDB<TAdditionalPhoneDB[]>
> = {
  fromDb: data => {
    return {
      value: data.value?.map(item => allowAdditionalPhoneValue.fromDb(item)),
      allowedOperations: allowOperationsConverter.fromDb(
        data.allowedOperations,
      ),
    };
  },
};

export const getPeopleByIdConverter: TFetchConverter<
  TPeopleByIdUI,
  TPeopleByIdDB
> = {
  fromDb: data => ({
    id: data.id,
    additionalEmails:
      data.additionalEmails &&
      allowOperationsForAdditionalEmailValue.fromDb(data.additionalEmails),
    additionalPhones:
      data.additionalPhones &&
      allowOperationsForAdditionalPhoneValue.fromDb(data.additionalPhones),
    email:
      data.email && allowOperationsWithStringValueConverter.fromDb(data.email),
    firstName:
      data.firstName &&
      allowOperationsWithStringValueConverter.fromDb(data.firstName),
    lastName:
      data.lastName &&
      allowOperationsWithStringValueConverter.fromDb(data.lastName),
    phone:
      data.phone && allowOperationsWithPhoneNumberConverter.fromDb(data.phone),
    title:
      data.title && allowOperationsWithStringValueConverter.fromDb(data.title),
    userOrganizations:
      data.userOrganizations &&
      allowOperationsForPeopleOrganizationsValue.fromDb(data.userOrganizations),
    fullName: `${data.firstName.value} ${data?.lastName.value}`,
  }),
};

export const updatePeopleFirstNameConverter: TCreateConverter<
  TUpdatePeopleFirstNameUI,
  TUpdatePeopleFirstNameDB
> = {
  toDb: data => ({
    firstName: data.firstName,
    userId: data.userId,
  }),
};

export const updatePeopleLastNameConverter: TCreateConverter<
  TUpdatePeopleLastNameUI,
  TUpdatePeopleLastNameDB
> = {
  toDb: data => ({
    lastName: data.lastName,
    userId: data.userId,
  }),
};

export const updatePeopleTitleConverter: TCreateConverter<
  TUpdatePeopleTitleUI,
  TUpdatePeopleLTitleDB
> = {
  toDb: data => ({
    title: data.title,
    userId: data.userId,
  }),
};

export const updatePeopleEmailConverter: TCreateConverter<
  TUpdatePeopleEmailUI,
  TUpdatePeopleLEmailDB
> = {
  toDb: data => ({
    email: data.email,
    userId: data.userId,
  }),
};

export const updatePeopleMobileConverter: TCreateConverter<
  TUpdatePeopleMobileUI,
  TUpdatePeopleMobileDB
> = {
  toDb: data => ({
    phone: phoneNumberConverter.toDb(data.phone) || '',
    userId: data.userId,
  }),
};

export const addAdditionalEmailConverter: TCreateConverter<
  TAddAdditionalEmailUI,
  TAddAdditionalEmailDB
> = {
  toDb: data => ({
    userId: data.userId,
    email: data.email,
  }),
};

export const addAdditionalPhoneConverter: TCreateConverter<
  TAddAdditionalPhoneUI,
  TAddAdditionalPhoneDB
> = {
  toDb: data => ({
    userId: data.userId,
    phone: phoneNumberConverter.toDb(data.phone) || '',
  }),
};

export const addPeopleOrganizationConverter: TCreateConverter<
  TAddPeopleOrganizationUI,
  TAddPeopleOrganizationDB
> = {
  toDb: data => ({
    userId: data.userId,
    organizationId: data.organizationId,
    title: data.title,
  }),
};

export const updateAdditionalPhoneConverter: TCreateConverter<
  TUpdateAdditionalPhoneUI,
  TUpdateAdditionalPhoneDB
> = {
  toDb: data => ({
    phone: phoneNumberConverter.toDb(data.phone) || '',
    userId: data.userId,
    additionalPhoneId: data.additionalPhoneId,
  }),
};

export const updateAdditionalEmailConverter: TCreateConverter<
  TUpdateAdditionalEmailUI,
  TUpdateAdditionalEmailDB
> = {
  toDb: data => ({
    email: data.email || '',
    userId: data.userId,
    additionalEmailId: data.additionalEmailId,
  }),
};

export const deletePeopleOrganizationConverter: TCreateConverter<
  TDeletePeopleOrganizationUI,
  TDeletePeopleOrganizationDB
> = {
  toDb: data => ({
    userId: data.userId,
    organizationId: data.organizationId,
  }),
};

export const updatePeopleOrganizationTitleConverter: TCreateConverter<
  TUpdatePeopleOrganizationTitleUI,
  TUpdatePeopleOrganizationTitleDB
> = {
  toDb: data => ({
    userId: data.userId,
    title: data.title,
    organizationId: data.organizationId,
  }),
};
