const useQRCodeDownloader = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const downloadQRCode = async (ref: any) => {
    const canvas = ref.current.querySelector('canvas');
    if (canvas) {
      const url = canvas.toDataURL();
      const a = document.createElement('a');
      a.download = 'QRCode.png';
      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  return downloadQRCode;
};

export default useQRCodeDownloader;
