import { useTranslation } from '@packages/utils';
import { TColumnType } from 'components/Base/Table/Table.types';
import useGetTableColumnData from 'components/Base/Table/components/SearchFilter';
import AgeGroupesColumn from 'components/Shared/AgeGroupesColumn';
import { EAgeGroup, ESortDirection } from 'data/types/generic.types';
import { EWaitListColumnKey, TWaitListUI } from 'data/types/registrants.types';
import {
  EWorkshopSeriesType,
  EWorkshopStandaloneType,
} from 'data/types/workshops.types';
import Tags from 'components/Shared/Tags';
import { ETagsUrls } from 'data/urls/tags.url';
import { ETagType } from 'data/types/tags.types';
import { registrantsApi } from 'redux/apiSlice/registrants.slice';

const useGetColumnsData = () => {
  const { getTableColumnSearchProps } = useGetTableColumnData();
  const { t } = useTranslation();
  const defaultSortParam = {
    sortField: EWaitListColumnKey.CREATION_DATE,
    sortDirection: ESortDirection.descend,
  };

  const defaultColumns: TColumnType<TWaitListUI>[] = [
    {
      title: t(
        'descriptions.admin-portal.waitlist.list.table.title.REGISTRATION_DATE',
      ),
      dataIndex: 'createdDate',
      key: EWaitListColumnKey.CREATION_DATE,
      sorter: true,
    },
    {
      title: t('descriptions.admin-portal.waitlist.list.table.title.LOCATION'),
      dataIndex: 'location',
      key: EWaitListColumnKey.LOCATION,
      filters: [],
    },
    {
      title: t('descriptions.admin-portal.waitlist.list.table.title.CITY'),
      dataIndex: 'city',
      key: EWaitListColumnKey.CITIES,
      filters: [],
    },
    {
      title: t(
        'descriptions.admin-portal.waitlist.list.table.title.WORKSHOP_TYPE',
      ),
      dataIndex: 'workshopType',
      key: EWaitListColumnKey.TYPE,
      filters: [
        ...Object.values(EWorkshopStandaloneType).map(status => ({
          text: t(`enums.workshop-standalone-type.${status}`),
          value: status,
        })),
        ...Object.values(EWorkshopSeriesType).map(status => ({
          text: t(`enums.workshop-series-type.${status}`),
          value: status,
        })),
      ],
      render: text => <>{t(`enums.workshop-type.${text}`)}</>,
    },
    {
      title: t('descriptions.admin-portal.waitlist.list.table.title.AGE_GROUP'),
      dataIndex: 'ageGroup',
      key: EWaitListColumnKey.AGE_GROUP,
      filters: Object.values(EAgeGroup).map(ageGroup => ({
        text: t(`enums.age-group.${ageGroup}`),
        value: ageGroup,
      })),
      render: (_, { englishAgeGroups, spanishAgeGroups }) => {
        return (
          <AgeGroupesColumn
            englishSessions={englishAgeGroups}
            spanishSessions={spanishAgeGroups}
          />
        );
      },
    },
    {
      title: t(
        'descriptions.admin-portal.waitlist.list.table.title.FIRST_NAME',
      ),
      dataIndex: 'firstName',
      sorter: true,
      key: EWaitListColumnKey.FIRST_NAME,
      ...getTableColumnSearchProps<TWaitListUI>(),
    },
    {
      title: t('descriptions.admin-portal.waitlist.list.table.title.LAST_NAME'),
      dataIndex: 'lastName',
      sorter: true,
      key: EWaitListColumnKey.LAST_NAME,
      ...getTableColumnSearchProps<TWaitListUI>(),
    },
    {
      title: t('descriptions.admin-portal.waitlist.list.table.title.EMAIL'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t('descriptions.admin-portal.waitlist.list.table.title.MOBILE'),
      dataIndex: 'phone',
      key: 'phone',
      ellipsis: true,
    },
    {
      title: t('descriptions.admin-portal.waitlist.list.table.title.TAG'),
      dataIndex: 'tags',
      key: EWaitListColumnKey.TAGS,
      filters: [],
      width: 50,
      fixed: 'right',
      onCell: () => ({
        className: 'custom-cell',
        onClick: e => {
          e.stopPropagation();
        },
      }),
      render: (text, record) => (
        <Tags
          selectedTags={record.tags || []}
          associationId={record.registrantId}
          url={ETagsUrls.REGISTRANT}
          invalidateTagsConfig={[
            { invalidateTag: 'wait-list', sliceName: registrantsApi },
          ]}
          tagType={ETagType.REGISTRANT}
        />
      ),
    },
  ];

  return { defaultColumns, defaultSortParam };
};

export default useGetColumnsData;
