import { TChildcareItem } from '@packages/utils';
import {
  TConvertor,
  TCreateConverter,
  TFetchConverter,
} from 'data/types/converter.types';
import {
  EAgeGroup,
  EWorkshopLanguage,
  TTableFilters,
} from 'data/types/generic.types';
import {
  ERegistrantsColumnKey,
  EWaitListColumnKey,
  TAttendanceEditDB,
  TAttendanceEditUI,
  TAttendancesEditDB,
  TAttendancesEditUI,
  TAttendancesGetDB,
  TAttendancesGetUI,
  TRegistrantDB,
  TRegistrantUI,
  TRemoveRegistrantDB,
  TRemoveRegistrantUI,
  TRegisterRegistrantDB,
  TRegisterRegistrantUI,
  TWaitListDB,
  TWaitListMoveDB,
  TWaitListMoveUI,
  TWaitListUI,
  TRegistrantsWithChildcareUI,
  TRegistrantsWithChildcareDB,
  EChildcareColumnKey,
  TChildcareRequirementsDB,
  TEditRegitrantChildcareUI,
  TEditRegitrantChildcareDB,
  TRegistrantsNoChildcareDB,
  TRegistrantsNoChildcareUI,
} from 'data/types/registrants.types';
import { dateConverter, dateShortenFormat } from 'helpers/utils';
import { TAgeGroupColumnItem } from 'components/Shared/AgeGroupesColumn/AgeGroupesColumn.types';
import { phoneNumberConverter } from './general.converters';
import { tagsGetConverter } from './tags.convertor';

export const singleAgeGroupColumnConverter = (
  language: EWorkshopLanguage,
  targetedLanguage: EWorkshopLanguage,
): TFetchConverter<TAgeGroupColumnItem[], EAgeGroup> => {
  return {
    fromDb: data => {
      if (!data || targetedLanguage !== language) {
        return [];
      }
      return [
        {
          ageGroup: data,
          count: 1,
          language,
        },
      ];
    },
  };
};

export const getAttendancesConvertor: TFetchConverter<
  TAttendancesGetUI,
  TAttendancesGetDB
> = {
  fromDb: data => {
    return {
      sessionId: data.sessionId,
      sessionAttendanceId: data.sessionAttendanceId,
      attendanceStatus: data.attendanceStatus,
      kitCount: data.kitCount,
      partType: data.partType,
      isUpdatable: data.isUpdatable,
    };
  },
};

export const getRegistrantConvertor: TFetchConverter<
  TRegistrantUI,
  TRegistrantDB
> = {
  fromDb: data => {
    return {
      registrantId: data.registrantId,
      firstName: data.firstName,
      lastName: data.lastName,
      fullName: `${data.firstName} ${data.lastName}`,
      countOfChildren: data.countOfChildren,
      email: data.email,
      phone: phoneNumberConverter.fromDb(data.phone) || '',
      createdDate:
        data.createdDate && dateConverter(data.createdDate, dateShortenFormat),
      childrenNames: data.childrenNames,
      attendances: (data.attendances || []).map(el =>
        getAttendancesConvertor.fromDb(el),
      ),
      hasUpdatableAttendance:
        data.attendances && data.attendances.some(el => el.isUpdatable),
      orientationKit:
        data.orientationKitRequested !== null
          ? {
              received: !!data.orientationKitReceived,
              requested: !!data.orientationKitRequested,
            }
          : undefined,
      materialsLanguage: data.materialsLanguage,
      englishAgeGroups: singleAgeGroupColumnConverter(
        data.language,
        EWorkshopLanguage.ENGLISH,
      ).fromDb(data.ageGroup),
      spanishAgeGroups: singleAgeGroupColumnConverter(
        data.language,
        EWorkshopLanguage.SPANISH,
      ).fromDb(data.ageGroup),
      customQuestionAnswer: data.customQuestionAnswer,
      workshopClassId: data.workshopClassId,
      registrantStatus: data.status,
    };
  },
};

export const attendanceEditConverter: TCreateConverter<
  TAttendanceEditUI,
  TAttendanceEditDB
> = {
  toDb: data => ({
    attendanceId: data.attendanceId,
    kitCount: data.kitCount,
    attendanceStatus: data.attendanceStatus,
  }),
};

export const attendancesEditConverter: TCreateConverter<
  TAttendancesEditUI,
  TAttendancesEditDB
> = {
  toDb: data => ({
    registrantId: data.registrantId,
    workshopId: data.workshopId,
    attendances: data.attendances.map(el => attendanceEditConverter.toDb(el)),
    ...(data.orientationKitReceived !== undefined && {
      orientationKitReceived: data.orientationKitReceived,
    }),
  }),
};

export const getWaitListConvertor: TFetchConverter<TWaitListUI, TWaitListDB> = {
  fromDb: data => {
    return {
      lastName: data.lastName,
      firstName: data.firstName,
      workshopTypeCategory: data.workshopTypeCategory,
      email: data.email,
      phone: phoneNumberConverter.fromDb(data.phone) || '',
      workshopType: data.workshopType,
      attendances: (data.attendances || []).map(el =>
        getAttendancesConvertor.fromDb(el),
      ),
      createdDate:
        data.createdDate && dateConverter(data.createdDate, dateShortenFormat),
      childrenNames: data.childrenNames,
      countOfChildren: data.countOfChildren,
      englishAgeGroups: singleAgeGroupColumnConverter(
        data.language,
        EWorkshopLanguage.ENGLISH,
      ).fromDb(data.ageGroup),
      spanishAgeGroups: singleAgeGroupColumnConverter(
        data.language,
        EWorkshopLanguage.SPANISH,
      ).fromDb(data.ageGroup),
      registrantId: data.registrantId,
      workshopClassId: data.workshopClassId,
      city: data.city,
      customQuestionAnswer: data.customQuestionAnswer,
      location: data.location,
      workshopId: data.workshopId,
      tags: data.tags && tagsGetConverter.fromDb(data.tags),
    };
  },
};

export const waitListFilterConverter = (
  filters: TTableFilters<EWaitListColumnKey>['filters'],
) => {
  const filtersToDB = {
    ...(filters?.workshopTypes && {
      [EWaitListColumnKey.TYPE]: filters.workshopTypes,
    }),
    ...(filters?.ageGroups && {
      [EWaitListColumnKey.AGE_GROUP]: filters.ageGroups,
    }),
    ...(filters?.firstName && {
      [EWaitListColumnKey.FIRST_NAME]: filters.firstName.toString().trim(),
    }),
    ...(filters?.lastName && {
      [EWaitListColumnKey.LAST_NAME]: filters.lastName.toString().trim(),
    }),
    ...(filters?.cities && {
      [EWaitListColumnKey.CITIES]: filters.cities,
    }),
    ...(filters?.locationIds && {
      [EWaitListColumnKey.LOCATION]: filters.locationIds,
    }),
    ...(filters?.tagIds && {
      [EWaitListColumnKey.TAGS]: filters.tagIds,
    }),
  };
  return filtersToDB;
};
export const registrantsFilterConverter = (
  filters: TTableFilters<ERegistrantsColumnKey>['filters'],
) => {
  let ageGroups;
  let languages;
  if (filters?.FILTER_BY_LANGUAGE_AND_AGE_GROUP?.length) {
    const allAgeGroups = Object.values(EAgeGroup);
    const allLanguages = Object.values(EWorkshopLanguage);
    ageGroups = filters?.FILTER_BY_LANGUAGE_AND_AGE_GROUP?.filter(ageGroup =>
      allAgeGroups.includes(ageGroup as EAgeGroup),
    );
    languages = filters?.FILTER_BY_LANGUAGE_AND_AGE_GROUP?.filter(language =>
      allLanguages.includes(language as EWorkshopLanguage),
    );
  }
  const filtersToDB = {
    ...(filters?.firstName && {
      [ERegistrantsColumnKey.FIRST_NAME]: filters.firstName.toString().trim(),
    }),
    ...(filters?.lastName && {
      [ERegistrantsColumnKey.LAST_NAME]: filters.lastName.toString().trim(),
    }),
    ...(ageGroups &&
      ageGroups.length && {
        [ERegistrantsColumnKey.AGE_GROUP]: ageGroups,
      }),
    ...(languages &&
      languages.length && {
        [ERegistrantsColumnKey.LANGUAGE]: languages,
      }),
  };
  return filtersToDB;
};

export const waitListMoveConverter: TCreateConverter<
  TWaitListMoveUI,
  TWaitListMoveDB
> = {
  toDb: data => ({
    registrantId: data.registrantId,
    workshopClassId: data.workshopClassId,
    workshopId: data.workshopId,
  }),
};

export const registrantRemoveConverter: TCreateConverter<
  TRemoveRegistrantUI,
  TRemoveRegistrantDB
> = {
  toDb: data => ({
    registrantId: data.registrantId,
    workshopClassId: data.workshopClassId,
    workshopId: data.workshopId,
  }),
};

export const registrantRegisterConverter: TCreateConverter<
  TRegisterRegistrantUI,
  TRegisterRegistrantDB
> = {
  toDb: data => ({
    registrantId: data.registrantId,
    workshopClassId: data.workshopClassId,
    workshopId: data.workshopId,
  }),
};

export const childcareRequirementsConvertor: TConvertor<
  TChildcareItem,
  TChildcareRequirementsDB
> = {
  toDb: data => ({
    ageGroup: data.name,
    count: data.value,
  }),
  fromDb: data => ({
    name: data.ageGroup,
    value: data.count || 0,
    checked: !!data.count,
  }),
};
export const getRegistrantsWithChildcareConvertor: TFetchConverter<
  TRegistrantsWithChildcareUI,
  TRegistrantsWithChildcareDB
> = {
  fromDb: data => {
    return {
      childcareRequirements: (data.childcareRequirements || []).map(item =>
        childcareRequirementsConvertor.fromDb(item),
      ),
      firstName: data.firstName,
      lastName: data.lastName,
      registrantId: data.registrantId,
      workshopClassId: data.workshopClassId,
    };
  },
};

export const getRegistrantsNoChildcareConvertor: TFetchConverter<
  TRegistrantsNoChildcareUI,
  TRegistrantsNoChildcareDB
> = {
  fromDb: data => {
    return {
      fullName: `${data.firstName} ${data.lastName}`,
      registrantId: data.registrantId,
      workshopClassId: data.workshopClassId,
    };
  },
};

export const childcareFilterConverter = (
  filters: TTableFilters<EChildcareColumnKey>['filters'],
) => {
  const filtersToDB = {
    ...(filters?.firstName && {
      [EChildcareColumnKey.FIRST_NAME]: filters.firstName.toString().trim(),
    }),
    ...(filters?.lastName && {
      [EChildcareColumnKey.LAST_NAME]: filters.lastName.toString().trim(),
    }),
  };
  return filtersToDB;
};

export const editRegistrantsChildcareConvertor: TCreateConverter<
  TEditRegitrantChildcareUI,
  TEditRegitrantChildcareDB
> = {
  toDb: data => ({
    registrantId: data.registrantId,
    workshopClassId: data.workshopClassId,
    workshopId: data.workshopId,
    childcareRequirements: (data.childcareRequirements || []).map(item =>
      childcareRequirementsConvertor.toDb(item),
    ),
  }),
};
