import { FC } from 'react';
import { Col, Row } from 'components/Base/Grid';
import { TSeriesPartsProps } from './SeriesPart.type';
import SessionPartCard from '../SessionPartCard';
import useGetSerriesPartData from './Series.data';

const SeriesPart: FC<TSeriesPartsProps> = ({
  parts,
  isChildCareAndPreschool,
}) => {
  const { dataMapperByPart } = useGetSerriesPartData();

  if (!parts.length) return null;

  return (
    <Col>
      <Row gutter={[0, 12]} className="flex-direction-column">
        {parts.map(item => {
          return (
            <Col key={item.workshopPartId}>
              <SessionPartCard
                color={dataMapperByPart[item.partType].color}
                title={dataMapperByPart[item.partType].title}
                part={dataMapperByPart[item.partType].part}
                sessionDate={item.dateTime}
                isChildCareAndPreschool={isChildCareAndPreschool}
              />
            </Col>
          );
        })}
      </Row>
    </Col>
  );
};

export default SeriesPart;
