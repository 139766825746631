import { useTranslation } from '@packages/utils';
import useGetValidationData from 'components/Base/Form/validationRules';

const useGetFormData = () => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.lightBox.form',
  });

  const { validationRules } = useGetValidationData();
  const lightBoxFormFields = {
    firstSection: {
      name: 'firstSection',
      label: t('pink-text.LABEL'),
      placeholder: t('pink-text.PLACEHOLDER'),
      rules: [validationRules.requiredField()],
    },
    secondSection: {
      name: 'secondSection',
      label: t('black-text.LABEL'),
      placeholder: t('black-text.PLACEHOLDER'),
      rules: [validationRules.requiredField()],
    },
    picture: {
      name: 'picture',
      label: t('pictures.LABEL'),
      rules: [validationRules.requiredField()],
    },
  };

  return { lightBoxFormFields };
};

export default useGetFormData;
