import {
  Col,
  Form,
  FormItem,
  Row,
  useForm,
  useShowToastNotification,
  useTranslation,
  TRtkErrorType,
  ENotificationType,
  filterLabelOption,
} from '@packages/utils';
import Checkbox from 'components/Base/Checkbox';
import EditableSelect from 'components/Base/EditableFields/EditableSelect';
import { InplaceEditLabels } from 'components/Base/EditableFields/components/InplaceEditLabel';
import { EIconNames } from 'components/Base/Icon';
import { useAddGuestAttendeeMutation } from 'redux/apiSlice/elcs.slice';
import { useGetUserIdentityQuery } from 'redux/apiSlice/userGrow.slice';
import { TUserIdentityUI } from 'data/types/user.types';
import { avatarColorMapper } from 'pages/private/ELCs/ELCs.data';
import { TGuestProps } from '../Guest.types';

const GuestAdd = ({ elcId, meetingId }: TGuestProps) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.meetings-tab.details.attendees.other',
  });
  const { showToastNotification } = useShowToastNotification();
  const { form } = useForm<{ isAttended: boolean }>();

  const [addGuestAttendee, { isLoading, isError, isSuccess }] =
    useAddGuestAttendeeMutation();

  const onEdit = async (value: string) => {
    try {
      await addGuestAttendee({
        elcId,
        meetingId,
        userId: value,
      }).unwrap();
      form.resetFields();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (e) {
      showToastNotification({
        message: (e as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  const { data: people } = useGetUserIdentityQuery({ profileTypes: undefined });
  const peopleOptions = people?.map((item: TUserIdentityUI) => ({
    label: item.fullName,
    value: item.id,
  }));

  return (
    <Row align="middle">
      <Col className="mr-4">
        <Form form={form} initialValues={{ isAttended: false }}>
          <FormItem className="mb-0" name="isAttended" valuePropName="checked">
            <Checkbox disabled />
          </FormItem>
        </Form>
      </Col>
      <Col>
        <InplaceEditLabels
          label={t('INPLACE_EDIT_LABEL')}
          icon={EIconNames.PROFILE}
        />
      </Col>
      <Col flex="auto">
        <EditableSelect
          key={String(isSuccess)}
          fitContent={false}
          options={peopleOptions}
          onEdit={onEdit}
          emptyText={t('EMPTY_TEXT')}
          placeholder={t('PLACEHOLDER')}
          readonly={false}
          canRemove={false}
          selectProps={{
            filterOption: filterLabelOption,
            showSearch: true,
          }}
          isError={isError}
          isLoading={isLoading}
          avatarColor={avatarColorMapper.lead}
        />
      </Col>
    </Row>
  );
};

export default GuestAdd;
