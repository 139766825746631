import { useTranslation } from '@packages/utils';
import { TColumnType } from 'components/Base/Table/Table.types';
import { TAdminsDataUI } from 'data/types/admin.types';
import TeamAction from '../TeamAction/Remove';

const useGetTeamTableData = () => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.team.list.table.title',
  });
  const CURRENT_ANIMALS_COLUMNS: TColumnType<TAdminsDataUI>[] = [
    {
      title: t('FIRST_NAME'),
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: t('LAST_NAME'),
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: t('MOBILE'),
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: t('EMAIL'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: ' ',
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      width: 60,
      onCell: () => ({
        className: 'custom-cell',
        onClick: e => {
          e.stopPropagation();
        },
      }),
      render: (text, record) => <TeamAction teamData={record} />,
    },
  ];
  return { defaultColumns: CURRENT_ANIMALS_COLUMNS };
};

export default useGetTeamTableData;
