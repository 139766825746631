import { EI18nLanguageUI } from '@packages/utils';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TAppSliceState = {
  currentNotificationUrl: string;
  flyerLanguage: EI18nLanguageUI | null;
  pageHeaderHeight: number;
};

const initialState: TAppSliceState = {
  currentNotificationUrl: '',
  flyerLanguage: null,
  pageHeaderHeight: 0,
};

const appSlice = createSlice({
  name: 'appSlice',
  initialState,
  reducers: {
    setCurrentNotificationUrl(state, action: PayloadAction<string>) {
      state.currentNotificationUrl = action.payload;
    },
    setFlyerLanguage(state, action: PayloadAction<EI18nLanguageUI | null>) {
      state.flyerLanguage = action.payload;
    },
    setPageHeaderHeight(state, action: PayloadAction<number>) {
      state.pageHeaderHeight = action.payload;
    },
  },
});

export default appSlice;
