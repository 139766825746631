import { TFile } from './generic.types';
import { TTagDB, TTagUI } from './tags.types';

export enum EInvitationStatus {
  PENDING = 'PENDING',
  EXPIRED = 'EXPIRED',
  ACCEPTED = 'ACCEPTED',
}

export type TElcLeadUI = {
  userId: string;
  fullName: string;
  email: string;
  phone: string | null;
  invitationStatus: EInvitationStatus | null;
  allowedUpdateWithNull: boolean;
};

export type TElcLeadDB = {
  userId: string;
  fullName: string;
  email: string;
  phone: string | null;
  invitationStatus: EInvitationStatus | null;
  allowedUpdateWithNull: boolean;
};

export type TElcFirstPMUI = {
  userId: string;
  fullName: string;
  email: string;
  phone: string | null;
  invitationStatus: EInvitationStatus | null;
  allowedUpdateWithNull: boolean;
};

export type TElcFirstPMDB = {
  userId: string;
  fullName: string;
  email: string;
  phone: string | null;
  invitationStatus: EInvitationStatus | null;
  allowedUpdateWithNull: boolean;
};

export type TElcSecondPMUI = {
  userId: string;
  fullName: string;
  email: string;
  phone: string | null;
  invitationStatus: EInvitationStatus | null;
  allowedUpdateWithNull: boolean;
};

export type TElcSecondPMDB = {
  userId: string;
  fullName: string;
  email: string;
  phone: string | null;
  invitationStatus: EInvitationStatus | null;
  allowedUpdateWithNull: boolean;
};

export type TAddressUI = {
  id: string;
  formattedAddress: string;
};

export type TAddressDB = {
  id: string;
  formattedAddress: string;
};

export type TElcItemUI = {
  name: string;
  elcId: string;
  lead: TElcLeadUI;
  firstProjectManager: TElcFirstPMUI | null;
  secondProjectManager: TElcSecondPMUI | null;
};

export type TElcItemDB = {
  name: string;
  elcId: string;
  lead: TElcLeadDB;
  firstProjectManager: TElcFirstPMDB | null;
  secondProjectManager: TElcSecondPMDB | null;
};

export type TMyElcItemUI = {
  name: string;
  elcId: string;
  lead: TElcLeadUI;
  firstProjectManager: TElcFirstPMUI | null;
  secondProjectManager: TElcSecondPMUI | null;
};

export type TMyElcItemDB = {
  name: string;
  elcId: string;
  lead: TElcLeadDB;
  firstProjectManager: TElcFirstPMDB | null;
  secondProjectManager: TElcSecondPMDB | null;
};

export type TElcDetailFiscalAgentUI = {
  email: string;
  fullName: string;
  phone: string | null;
  userId: string;
};

export type TElcDetailFiscalAgentDB = {
  email: string;
  fullName: string;
  phone: string | null;
  userId: string;
};

export type TElcDetailLeadAgencyUI = {
  id: string;
  name: string;
  address?: TAddressUI;
};

export type TElcDetailLeadAgencyDB = {
  id: string;
  name: string;
  address?: TAddressUI;
};

export type TElcNotesUI = {
  id: string;
  category: string;
  note: string;
};

export type TElcNotesDB = {
  id: string;
  category: string;
  note: string;
};

export type TElcBoundariesUI = {
  id: string;
  zipCode: string;
};

export type TElcBoundariesDB = {
  id: string;
  zipCode: string;
};

export type TElcDetailUI = {
  name: string;
  elcId: string;
  lead: TElcLeadUI;
  firstProjectManager: TElcFirstPMUI | null;
  secondProjectManager: TElcSecondPMUI | null;
  fiscalAgent: TElcDetailFiscalAgentUI | null;
  leadAgency: TElcDetailLeadAgencyUI | null;
  coLeadAgency: TElcDetailLeadAgencyUI | null;
  elcNotes: TElcNotesUI[];
  elcBoundaries: TElcBoundariesUI[];
};

export type TElcDetailDB = {
  name: string;
  elcId: string;
  lead: TElcLeadDB;
  firstProjectManager: TElcFirstPMDB | null;
  secondProjectManager: TElcSecondPMDB | null;
  fiscalAgent: TElcDetailFiscalAgentDB | null;
  leadAgency: TElcDetailLeadAgencyDB | null;
  coLeadAgency: TElcDetailLeadAgencyDB | null;
  elcNotes: TElcNotesDB[];
  elcBoundaries: TElcBoundariesDB[];
};
export type TLeadEditUI = {
  elcId: string;
  userId: string;
};
export type TLeadEditDB = {
  elcId: string;
  userId: string;
};

export type TPMEditUI = {
  elcId: string;
  userId: string;
};
export type TPMEditDB = {
  elcId: string;
  userId: string;
};

export type TLeadAgencyEditUI = {
  elcId: string;
  organizationId: string;
};
export type TLeadAgencyEditDB = {
  elcId: string;
  organizationId: string;
};

export type TCoLeadAgencyEditUI = {
  elcId: string;
  organizationId: string | null;
};
export type TCoLeadAgencyEditDB = {
  elcId: string;
  organizationId: string | null;
};

export type TFiscalAgentEditUI = {
  elcId: string;
  userId: string;
};
export type TFiscalAgentEditDB = {
  elcId: string;
  userId: string;
};

export type TNoteEditUI = {
  elcId: string;
  elcNoteId: string;
  note: string;
};
export type TNoteEditDB = {
  elcId: string;
  elcNoteId: string;
  note: string;
};

export type TNoteCreateUI = {
  elcId: string;
  category: string;
  note: string;
};
export type TNoteCreateDB = {
  elcId: string;
  category: string;
  note: string;
};

export type TNoteDeleteUI = {
  elcId: string;
  elcNoteId: string;
};
export type TNoteDeleteDB = {
  elcId: string;
  elcNoteId: string;
};

export type TBoundariesCreateUI = {
  elcId: string;
  zipCode: string;
};
export type TBoundariesCreateDB = {
  elcId: string;
  zipCode: string;
};

export type TBoundariesDeleteUI = {
  elcId: string;
  boundaryId: string;
};
export type TBoundariesDeleteDB = {
  elcId: string;
  boundaryId: string;
};

export type TContactsUI = {
  contactId: string;
  email?: string;
  firstName: string;
  isElacMember: boolean;
  lastName: string;
  phone?: string;
  title?: string;
  tags: TTagUI[];
  userId: string;
};

export type TContactsDB = {
  contactId: string;
  email?: string;
  firstName: string;
  isElacMember: boolean;
  lastName: string;
  phone?: string;
  title?: string;
  tags: TTagDB[];
  userId: string;
};

export enum EContactsColumnKey {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  TAGS = 'tagIds',
}

export type TAddContactUI = {
  elcId: string;
  userId: string;
  isElacMember: boolean;
};

export type TAddContactDB = {
  elcId: string;
  userId: string;
  isElacMember: boolean;
};

export type TUpdateELACMemberUI = {
  elcId: string;
  contactId: string;
  isElacMember: boolean;
};

export type TUpdateELACMemberDB = {
  elcId: string;
  contactId: string;
  isElacMember: boolean;
};

export type TDeleteContactUI = {
  elcId: string;
  contactId: string;
};

export type TDeleteContactDB = {
  elcId: string;
  contactId: string;
};

export enum EELCPlacesColumnKey {
  ORGANIZATION = 'organizationName',
  TAGS = 'tagIds',
}

export type TAddELCPlaceUI = {
  elcId: string;
  organizationId: string;
};

export type TAddELCPlaceDB = {
  elcId: string;
  organizationId: string;
};

export type TDeleteELCPlaceUI = {
  elcId: string;
  placeId: string;
};

export type TDeleteELCPlaceDB = {
  elcId: string;
  placeId: string;
};

export type TELCPlacesUI = {
  address: string;
  name: string;
  organizationId: string;
  placeId: string;
  tags: TTagUI[];
};

export type TELCPlacesDB = {
  address: string;
  name: string;
  organizationId: string;
  placeId: string;
  tags: TTagDB[];
};

export type TMeetingsUI = {
  date: string;
  meetingId: string;
  title: string;
  lastUpdateDate: string;
};

export type TMeetingsDB = {
  date: string;
  meetingId: string;
  title: string;
  lastUpdateDate: string;
};

export type TDeleteMeetingUI = {
  elcId: string;
  meetingId: string;
};

export type TDeleteMeetingDB = {
  elcId: string;
  meetingId: string;
};

export type TAddMeetingUI = {
  elcId: string;
  title: string;
  date: string;
};

export type TAddMeetingDB = {
  elcId: string;
  title: string;
  date: string;
};

export type TEditMeetingUI = {
  elcId: string;
  title: string;
  date: string;
  meetingId: string;
};

export type TEditMeetingDB = {
  elcId: string;
  title: string;
  date: string;
  meetingId: string;
};

export enum EMeetingsColumnKey {
  DATE = 'date',
}

export type TElcMeetingTopicUI = {
  topicId: string;
  meetingId: string;
  topicTitle: string;
  context?: string;
  progress?: string;
  roadBlock?: string;
};

export type TElcMeetingTopicDB = {
  topicId: string;
  meetingId: string;
  topicTitle: string;
  context?: string;
  progress?: string;
  roadBlock?: string;
};

export type TCreateElcMeetingTopicUI = {
  elcId: string;
  meetingId: string;
  topicTitle: string;
  context?: string;
  progress?: string;
  roadBlock?: string;
};

export type TCreateElcMeetingTopicDB = {
  elcId: string;
  meetingId: string;
  topicTitle: string;
  context?: string;
  progress?: string;
  roadBlock?: string;
};

export type TTopicCommonEditUI = {
  elcId: string;
  meetingId: string;
  topicId: string;
};
export type TTopicCommonEditDB = {
  elcId: string;
  meetingId: string;
  topicId: string;
};

export type TContextEditUI = TTopicCommonEditUI & {
  context: string;
};
export type TContextEditDB = TTopicCommonEditDB & {
  context: string;
};

export type TProgressEditUI = TTopicCommonEditUI & {
  progress: string;
};
export type TProgressEditDB = TTopicCommonEditDB & {
  progress: string;
};

export type TRoadBlockEditUI = TTopicCommonEditUI & {
  roadBlock: string;
};
export type TRoadBlockEditDB = TTopicCommonEditDB & {
  roadBlock: string;
};

export type TDeleteElcMeetingTopicUI = {
  elcId: string;
  meetingId: string;
  topicId: string;
};

export type TDeleteElcMeetingTopicDB = {
  elcId: string;
  meetingId: string;
  topicId: string;
};

export type TElcMeetingNoteUI = {
  noteId: string;
  meetingId: string;
  note: string;
};

export type TElcMeetingNoteDB = {
  noteId: string;
  meetingId: string;
  note: string;
};

export type TCreateElcMeetingNoteUI = {
  elcId: string;
  meetingId: string;
  note: string;
};

export type TCreateElcMeetingNoteDB = {
  elcId: string;
  meetingId: string;
  note: string;
};

export type TElcMeetingNoteEditUI = {
  elcId: string;
  meetingId: string;
  noteId: string;
  note: string;
};
export type TElcMeetingNoteEditDB = {
  elcId: string;
  meetingId: string;
  noteId: string;
  note: string;
};

export type TDeleteElcMeetingNoteUI = {
  elcId: string;
  meetingId: string;
  noteId: string;
};

export type TDeleteElcMeetingNoteDB = {
  elcId: string;
  meetingId: string;
  noteId: string;
};

export type TAttendeeUI = {
  userId: string;
  attendeeId: string;
  isAttended: boolean;
  fullName: string;
  email: string;
  phone?: string;
};

export type TAttendeeDB = {
  userId: string;
  attendeeId: string;
  isAttended: boolean;
  fullName: string;
  email: string;
  phone?: string;
};

export type TElcMeetingAttendeesUI = {
  meetingId: string;
  lead: TAttendeeUI;
  firstProjectManager: TAttendeeUI;
  secondProjectManager?: TAttendeeUI;
  fiscalAgent: TAttendeeUI;
  guests: TAttendeeUI[];
};

export type TElcMeetingAttendeesDB = {
  meetingId: string;
  lead: TAttendeeDB;
  firstProjectManager: TAttendeeDB;
  secondProjectManager?: TAttendeeDB;
  fiscalAgent: TAttendeeDB;
  guests: TAttendeeDB[];
};

export type TUpdateAttendanceStatusUI = {
  elcId: string;
  meetingId: string;
  attendeeId: string;
  isAttended: boolean;
};
export type TUpdateAttendanceStatusDB = {
  elcId: string;
  meetingId: string;
  attendeeId: string;
  isAttended: boolean;
};

export type TAddGuestAttendeeUI = {
  elcId: string;
  meetingId: string;
  userId: string;
};
export type TAddGuestAttendeeDB = {
  elcId: string;
  meetingId: string;
  userId: string;
};

export type TDeleteGuestAttendeeUI = {
  elcId: string;
  meetingId: string;
  attendeeId: string;
};

export type TDeleteGuestAttendeeDB = {
  elcId: string;
  meetingId: string;
  attendeeId: string;
};

export type TElcMeetingTATimeUI = {
  duration: string;
  meetingId: string;
  pillarName: string;
  focus: string;
  taTimeId: string;
};

export type TElcMeetingTATimeDB = {
  duration: string;
  meetingId: string;
  pillarName: string;
  focus: string;
  taTimeId: string;
};

export type TCreateElcMeetingTATimeUI = {
  elcId: string;
  meetingId: string;
  focusId: string;
  duration: string;
};

export type TCreateElcMeetingTATimeDB = {
  elcId: string;
  meetingId: string;
  focusId: string;
  duration: string;
};

export type TDeleteElcMeetingTATimeUI = {
  elcId: string;
  meetingId: string;
  taTimeId: string;
};

export type TDeleteElcMeetingTATimeDB = {
  elcId: string;
  meetingId: string;
  taTimeId: string;
};
export type TElcMeetingActionItemsDB = {
  actionItemId: string;
  meetingId: string;
  summary: string;
  dueDate?: string;
  ownerId?: string;
  isCompleted?: boolean;
};

export type TElcMeetingActionItemsUI = {
  id: string;
  meetingId: string;
  title: string;
  dueDate?: string;
  ownerId?: string;
  status?: boolean;
  isNew?: boolean;
};

export type TAddElcMeetingsActionItemUI = {
  elcId: string;
  meetingId: string;
  title?: string;
  dueDate?: string;
  ownerId?: string;
  status?: boolean;
};

export type TAddElcMeetingsActionItemDB = {
  elcId: string;
  meetingId: string;
  summary: string;
  dueDate?: string;
  ownerId?: string;
  isCompleted?: boolean;
};

export type TEditElcMeetingActionItemCommonUI = {
  elcId: string;
  meetingId: string;
  actionItemId: string;
};
export type TEditElcMeetingActionItemCommonDB = {
  elcId: string;
  meetingId: string;
  actionItemId: string;
};

export type TDeleteElcMeetingActionItemUI = TEditElcMeetingActionItemCommonUI;

export type TDeleteElcMeetingActionItemDB = TEditElcMeetingActionItemCommonDB;

export type TEditElcMeetingActionItemSummaryUI =
  TEditElcMeetingActionItemCommonUI & {
    title: string;
  };
export type TEditElcMeetingActionItemSummaryDB =
  TEditElcMeetingActionItemCommonDB & {
    summary: string;
  };

export type TEditElcMeetingActionItemDueDateUI =
  TEditElcMeetingActionItemCommonUI & {
    dueDate: string;
  };
export type TEditElcMeetingActionItemDueDateDB =
  TEditElcMeetingActionItemCommonDB & {
    dueDate: string;
  };

export type TEditElcMeetingActionItemOwnerUI =
  TEditElcMeetingActionItemCommonUI & {
    ownerId: string;
  };
export type TEditElcMeetingActionItemOwnerDB =
  TEditElcMeetingActionItemCommonDB & {
    ownerId: string;
  };

export type TEditElcMeetingActionItemStatusUI =
  TEditElcMeetingActionItemCommonUI & {
    status: boolean;
  };
export type TEditElcMeetingActionItemStatusDB =
  TEditElcMeetingActionItemCommonDB & {
    isCompleted: boolean;
  };

export type TUploadResourceUI = {
  elcId: string;
  file: Blob;
};

export type TUploadResourceDB = TFile;

export type TResourceItemUI = {
  resourceId: string;
  file: TFile;
};

export type TResourceItemDB = {
  resourceId: string;
  file: TFile;
};

export type TElcResourcesUI = {
  generalElcDocuments: TResourceItemUI[];
  elcSpecificDocuments: TResourceItemUI[];
  links: TResourceItemUI[];
};

export type TElcResourcesDB = {
  generalElcDocuments: TResourceItemDB[];
  elcSpecificDocuments: TResourceItemDB[];
  links: TResourceItemDB[];
};

export type TResourceTypes =
  | 'GENERAL_ELC_DOCUMENT'
  | 'ELC_SPECIFIC_DOCUMENT'
  | 'LINK';

export type TUploadedResourceUI = TFile & { uid?: string };

export type TAddResourceUI = {
  elcId: string;
  resourceType: TResourceTypes;
  files?: TUploadedResourceUI[];
};
export type TAddResourceDB = {
  elcId: string;
  resourceType: TResourceTypes;
  files?: TFile[];
};

export type TDeleteResourceUI = {
  elcId: string;
  resourceId: string;
};

export type TDeleteResourceDB = {
  elcId: string;
  resourceId: string;
};

export type TElcMeetingThingToShareEditUI = {
  elcId: string;
  meetingId: string;
  thingToShareId: string;
  content: string;
};
export type TElcMeetingThingToShareEditDB = {
  elcId: string;
  meetingId: string;
  thingToShareId: string;
  content: string;
};

export type TThinsToShareUI = {
  thingToShareId: string;
  content: string;
};

export type TThinsToShareDB = {
  thingToShareId: string;
  content: string;
};

export type TElcMeetingThingsToShareUI = {
  meetingId: string;
  leadThingsToShare: TThinsToShareUI;
  pmThingsToShare: TThinsToShareUI;
};

export type TElcMeetingThingsToShareDB = {
  meetingId: string;
  leadThingsToShare: TThinsToShareDB;
  pmThingsToShare: TThinsToShareDB;
};
