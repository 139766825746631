import React from 'react';
import { useAppSelector } from 'redux/store';
import { useTranslation } from '@packages/utils';
import { useTheme } from 'styled-components';
import { Col, Row } from 'components/Base/Grid';
import { Icon, EIconNames } from 'components/Base/Icon';
import { Text } from 'components/Base/Typography';
import { TChildCareFoodProvidedProps } from './ChildCareFoodProvided.types';

const ChildCareFoodProvided = ({
  childcareProvided,
  foodProvided,
}: TChildCareFoodProvidedProps) => {
  const theme = useTheme();
  const flyerLanguage = useAppSelector(state => state.appSlice.flyerLanguage);
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal',
    ...(flyerLanguage && { lng: flyerLanguage }),
  });
  const { t: tGeneric } = useTranslation({
    keyPrefix: 'descriptions.generic-components.childcare-meal-info',
    ...(flyerLanguage && { lng: flyerLanguage }),
  });

  if (!childcareProvided && !foodProvided) return null;
  return (
    <Row wrap={false} align="middle" gutter={[8, 8]}>
      <Col>
        <Text
          fontSize={theme.fontSizeHeading2}
          fontWeight={theme.fontWeightSemiBold}
          color={theme.colorGreenFlyer}
        >
          {t(
            'workshops.details.actions.download-flyer.flyer.additionally.TITLE',
          )}
        </Text>
      </Col>
      <Col>
        <Row gutter={[0, 16]}>
          <Col>
            {childcareProvided && (
              <Icon
                alt="child care provided icon"
                icon={EIconNames.CHILDCARE}
                size={56}
                color={theme.colorWarningDefault}
              />
            )}
          </Col>
          <Col>
            {foodProvided && (
              <Icon
                alt="food provided icon"
                icon={EIconNames.FOOD}
                size={56}
                color={theme.colorPinkDefault}
              />
            )}
          </Col>
        </Row>
      </Col>
      <Col>
        <Text
          fontWeight={theme.fontWeightMedium}
          fontSize={theme.fontSizeHeading2}
          color={theme.colorBlackPrimary}
        >
          {foodProvided && !childcareProvided && tGeneric('MEAL_PROVIDED')}
          {childcareProvided && !foodProvided && tGeneric('CHILDCARE_PROVIDED')}
          {foodProvided &&
            childcareProvided &&
            tGeneric('MEAL_AND_CHILDCARE_PROVIDED')}
        </Text>
      </Col>
    </Row>
  );
};

export default ChildCareFoodProvided;
