import { TFetchConverter } from '@packages/utils';
import {
  TPillarsDB,
  TPillarsFocusesDB,
  TPillarsFocusesUI,
  TPillarsUI,
} from 'data/types/pillars.types';

export const pillarConverter: TFetchConverter<TPillarsUI, TPillarsDB> = {
  fromDb: data => ({
    pillarId: data.pillarId,
    pillarName: data.pillarName,
  }),
};

export const pillarFocusConverter: TFetchConverter<
  TPillarsFocusesUI,
  TPillarsFocusesDB
> = {
  fromDb: data => ({
    focusId: data.focusId,
    focus: data.focus,
  }),
};
