import { TCreateConverter, TFetchConverter } from 'data/types/converter.types';
import {
  dateConverter,
  dateShortenFormat,
  dateTimeFormatDB,
  sortAgeGroups,
} from 'helpers/utils';
import {
  EWorkshopFilterParams,
  TAddWorkshopPhotoDB,
  TAddWorkshopPhotoUI,
  TCapacityDB,
  TCapacityUI,
  TClassesDataDB,
  TClassesDataUI,
  TClassItemDB,
  TClassItemUI,
  TDeleteWorkshopClassDB,
  TDeleteWorkshopClassUI,
  TDuplicateWorkshopDB,
  TDuplicateWorkshopUI,
  TPhotoGalleryDB,
  TPhotoGalleryUI,
  TFlyersDataUI,
  TSessionsDB,
  TSessionsUI,
  TClassCapacityEditDB,
  TClassCapacityEditUI,
  TWorkshopChildcareProvidedEditDB,
  TWorkshopChildcareProvidedEditUI,
  TWorkshopClassAgeGroupEditDB,
  TWorkshopClassAgeGroupEditUI,
  TWorkshopClassDataDB,
  TWorkshopClassDataUI,
  TWorkshopClassDateTimeEditDB,
  TWorkshopClassDateTimeEditUI,
  TWorkshopClassFacilitatorEditDB,
  TWorkshopClassFacilitatorEditUI,
  TWorkshopClassLanguageEditDB,
  TWorkshopClassLanguageEditUI,
  TWorkshopDataDB,
  TWorkshopDataUI,
  TWorkshopDescriptionEditDB,
  TWorkshopDescriptionEditUI,
  TWorkshopDetailsDataDB,
  TWorkshopDetailsDataUI,
  TWorkshopFoodProvidedEditDB,
  TWorkshopFoodProvidedEditUI,
  TWorkshopCreateDB,
  TWorkshopCreateUI,
  TWorkshopGalleryDB,
  TWorkshopGalleryUI,
  TWorkshopHostDataDB,
  TWorkshopHostDataUI,
  TWorkshopHostEditDB,
  TWorkshopHostEditUI,
  TWorkshopLocationDB,
  TWorkshopLocationEditDB,
  TWorkshopLocationEditUI,
  TWorkshopLocationUI,
  TWorkshopSessionDataDB,
  TWorkshopSessionDataUI,
  TWorkshopSessionItemDB,
  TWorkshopSessionItemUI,
  TWorkshopShippingAddressEditDB,
  TWorkshopShippingAddressEditUI,
  TWorkshopSiteCoordinatorDB,
  TWorkshopSiteCoordinatorEditDB,
  TWorkshopSiteCoordinatorEditUI,
  TWorkshopSiteCoordinatorUI,
  TWorkshopCapacityEditUI,
  TWorkshopCapacityEditDB,
  TWorkshopMeetingLinkEditUI,
  TWorkshopMeetingLinkEditDB,
  EWorkshopCategory,
  EWorkshopPartType,
  ECapacityEmptyTexts,
  TWorkshopChildCareUI,
  TWorkshopChildCareDB,
  EAttendanceType,
  EWorkshopStatus,
  EWorkshopDetailsAllowedActions,
  EWorkshopStandaloneType,
  EWorkshopSeriesType,
  TWorkshopSessionDateDB,
  TWorkshopSessionDateUI,
  TPartsUI,
  TPartsDB,
  TWorkshopPartDateTimeEditDB,
  TWorkshopPartDateTimeEditUI,
} from 'data/types/workshops.types';
import { EAgeGroup, TTableFilters } from 'data/types/generic.types';
import {
  addressConvertor,
  addressShortDataConverter,
  allowOperationsAgeGroupValueConverter,
  allowOperationsConverter,
  allowOperationsLanguageValueConverter,
  allowOperationsShortAddressWithConverter,
  allowOperationsWithBooleanValueConverter,
  allowOperationsWithDateTimeValueConverter,
  allowOperationsWithStringValueConverter,
  phoneNumberConverter,
} from './general.converters';
import { tagsGetConverter } from './tags.convertor';

export const workshopSessionItemConverter: TFetchConverter<
  TWorkshopSessionItemUI,
  TWorkshopSessionItemDB
> = {
  fromDb: data => ({
    ageGroup: data.ageGroup,
    language: data.language,
    count: data.count,
  }),
};
export const workshopHostConverter: TFetchConverter<
  TWorkshopHostDataUI,
  TWorkshopHostDataDB
> = {
  fromDb: data => ({
    id: data.id,
    name: data.name,
    address: addressShortDataConverter.fromDb(data.address),
    allowedOperations: allowOperationsConverter.fromDb(data.allowedOperations),
  }),
};
export const workshopLocationConverter: TFetchConverter<
  TWorkshopLocationUI,
  TWorkshopLocationDB
> = {
  fromDb: data => ({
    id: data.id,
    name: data.name,
    address: addressShortDataConverter.fromDb(data.address),
    nameAndAddress: `${data.name || ''} ${
      data.name && data.address?.formattedAddress ? '|' : ''
    } ${data.address?.formattedAddress || ''}`.trim(),
    allowedOperations: allowOperationsConverter.fromDb(data.allowedOperations),
  }),
};

export const workshopSiteCoordinatorConverter: TFetchConverter<
  TWorkshopSiteCoordinatorUI,
  TWorkshopSiteCoordinatorDB
> = {
  fromDb: data => ({
    id: data.id,
    allowedOperations: allowOperationsConverter.fromDb(data.allowedOperations),
    email: data.email,
    firstName: data.firstName,
    lastName: data.lastName,
    phone: (data.phone && phoneNumberConverter.fromDb(data.phone)) || '',
    profilePicture: data.profilePicture,
    ...((data?.firstName || data?.lastName) && {
      fullName: `${data?.firstName || ''} ${data?.lastName || ''}`.trim(),
    }),
  }),
};

export const workshopCreateConverter: TCreateConverter<
  TWorkshopCreateUI,
  TWorkshopCreateDB
> = {
  toDb: data => ({
    type: data.type,
    hostId: data.host,
    attendanceType: data.attendanceType,
    hostedPrivately: data.hostedPrivately,
  }),
};

export const workshopClassSessionConverter: TCreateConverter<
  TWorkshopSessionDataUI,
  TWorkshopSessionDataDB
> = {
  toDb: data => ({
    partType: data.partType,
    ...(data.date && { dateTime: dateConverter(data.date, dateTimeFormatDB) }),
    facilitatorId: data.facilitator,
  }),
};

export const workshopClassCreateConverter: TCreateConverter<
  TWorkshopClassDataUI,
  TWorkshopClassDataDB
> = {
  toDb: data => ({
    workshopId: data.workshopId,
    ageGroup: data.age,
    language: data.language,
    ...(data.capacity && { capacity: data.capacity }),
    sessions: data.sessions.map(session =>
      workshopClassSessionConverter.toDb(session),
    ),
  }),
};

export const duplicateConverter: TCreateConverter<
  TDuplicateWorkshopUI,
  TDuplicateWorkshopDB
> = {
  toDb: data => ({
    workshopId: data.workshopId,
  }),
};

export const deleteWorkshopClassConverter: TCreateConverter<
  TDeleteWorkshopClassUI,
  TDeleteWorkshopClassDB
> = {
  toDb: data => ({
    classId: data.classId,
    workshopId: data.workshopId,
  }),
};

export const workshopSessionDateConverter: TFetchConverter<
  TWorkshopSessionDateUI[],
  TWorkshopSessionDateDB
> = {
  fromDb: data => {
    return Object.entries(data).map(([key, value]) => {
      return {
        date: value && dateConverter(value, dateShortenFormat),
        partType: key as EWorkshopPartType,
      };
    });
  },
};

export const workshopGetConverter: TFetchConverter<
  TWorkshopDataUI,
  TWorkshopDataDB
> = {
  fromDb: data => ({
    workshopId: data.workshopId,
    hostName: data.hostName,
    hostId: data.hostId,
    attendanceType: data.attendanceType,
    createdDate:
      data.createdDate && dateConverter(data.createdDate, dateShortenFormat),
    city: data.city,
    englishSessions: (data.englishSessions || []).map(session =>
      workshopSessionItemConverter.fromDb(session),
    ),
    spanishSessions: (data.spanishSessions || []).map(session =>
      workshopSessionItemConverter.fromDb(session),
    ),
    hostedPrivately: data.hostedPrivately,
    workshopCategory: data.workshopCategory,
    workshopStatus: data.workshopStatus,
    workshopType: data.workshopType,
    tags: data.tags && tagsGetConverter.fromDb(data.tags),
    locationId: data.locationId,
    locationName: data.locationName,
    registrantCount: data.registrantCount,
    capacity: data.capacity ?? ECapacityEmptyTexts.UNLIMITED_SHORT,
    sessionDates: workshopSessionDateConverter.fromDb(data.sessionDates),
    siteCoordinator: data.siteCoordinator,
  }),
};

export const sessionItemConverter: TFetchConverter<TSessionsUI, TSessionsDB> = {
  fromDb: data => {
    return {
      facilitator:
        data.facilitator &&
        workshopSiteCoordinatorConverter.fromDb(data.facilitator),
      id: data.id,
      partType: data.partType,
    };
  },
};

export const workshopCapacityConverter: TFetchConverter<
  TCapacityUI,
  TCapacityDB
> = {
  fromDb: data => {
    return {
      allowedOperations: allowOperationsConverter.fromDb(
        data.allowedOperations,
      ),
      value: data?.value !== undefined ? String(data?.value) : '',
      showValue: data?.value ?? ECapacityEmptyTexts.UNLIMITED_LONG,
    };
  },
};

export const classCapacityConverterConverter = (
  workshopCapacity: TWorkshopDetailsDataDB['capacity']['value'],
): TFetchConverter<TCapacityUI, TCapacityDB> => {
  return {
    fromDb: data => {
      const emptyText = workshopCapacity
        ? ECapacityEmptyTexts.LIMITED_WITH_WORKSHOP
        : ECapacityEmptyTexts.UNLIMITED_LONG;
      return {
        allowedOperations: allowOperationsConverter.fromDb(
          data.allowedOperations,
        ),
        value: data?.value !== undefined ? String(data?.value) : '',
        showValue: data.value ?? emptyText,
      };
    },
  };
};

export const classItemConverter = (
  workshopCapacity: TWorkshopDetailsDataDB['capacity']['value'],
): TFetchConverter<TClassItemUI, TClassItemDB> => {
  return {
    fromDb: data => {
      return {
        ageGroup: allowOperationsAgeGroupValueConverter.fromDb(data.ageGroup),
        capacity: classCapacityConverterConverter(workshopCapacity).fromDb(
          data.capacity,
        ),
        id: data.id,
        language: allowOperationsLanguageValueConverter.fromDb(data.language),
        sessions: data.sessions.map(item => sessionItemConverter.fromDb(item)),
        registrantsCount: data.registrantsCount,
      };
    },
  };
};

export const classesConverter = (
  workshopCapacity: TWorkshopDetailsDataDB['capacity']['value'],
): TFetchConverter<TClassesDataUI, TClassesDataDB> => {
  return {
    fromDb: data => {
      return {
        value: data.value?.map(item =>
          classItemConverter(workshopCapacity).fromDb(item),
        ),
        allowedOperations: allowOperationsConverter.fromDb(
          data.allowedOperations,
        ),
      };
    },
  };
};

export const uniqueAgeGroupsConverter: TFetchConverter<
  EAgeGroup[],
  TClassesDataDB
> = {
  fromDb: classes => {
    const uniqueAgeGroups = Array.from(
      new Set(classes.value?.map(item => item.ageGroup.value)),
    );

    return sortAgeGroups(uniqueAgeGroups);
  },
};

export const partsConverter: TFetchConverter<TPartsUI, TPartsDB> = {
  fromDb: data => ({
    partType: data.partType,
    isPassed: data.isPassed,
    workshopPartId: data.workshopPartId,
    ...(data.dateTime && {
      dateTime: allowOperationsWithDateTimeValueConverter.fromDb(data.dateTime),
    }),
  }),
};

export const flyersDataConverter: TFetchConverter<
  TFlyersDataUI,
  TWorkshopDetailsDataDB
> = {
  fromDb: data => {
    const finalResult: TFlyersDataUI = {
      existingAgeGroups: uniqueAgeGroupsConverter.fromDb(data.classes),
      parts: (data.parts || []).map(part => partsConverter.fromDb(part)),
    };

    return finalResult;
  },
};

export const allowedActionsConverter = (
  status: TWorkshopDetailsDataDB['status'],
  attendanceType: TWorkshopDetailsDataDB['attendanceType'],
): TFetchConverter<
  TWorkshopDetailsDataUI['allowedActions'],
  TWorkshopDetailsDataDB['allowedActions']
> => {
  return {
    fromDb: data => {
      const actionsOrder = [
        EWorkshopDetailsAllowedActions.PUBLISH,
        EWorkshopDetailsAllowedActions.CANCEL,
        EWorkshopDetailsAllowedActions.CLOSE,
        EWorkshopDetailsAllowedActions.DELETE,
        EWorkshopDetailsAllowedActions.DUPLICATE,
      ];

      const sortedActions: TWorkshopDetailsDataUI['allowedActions'] = (
        data || []
      ).sort((a, b) => actionsOrder.indexOf(a) - actionsOrder.indexOf(b));

      if (status === EWorkshopStatus.PUBLISHED) {
        sortedActions.push(
          EWorkshopDetailsAllowedActions.DOWNLOAD_LANDING_QR,
          EWorkshopDetailsAllowedActions.COPY_URL,
        );

        if (attendanceType === EAttendanceType.IN_PERSON) {
          sortedActions.push(EWorkshopDetailsAllowedActions.DOWNLOAD_FLYER);
        }
      }

      return sortedActions;
    },
  };
};

export const workshopDetailsConverter: TFetchConverter<
  TWorkshopDetailsDataUI,
  TWorkshopDetailsDataDB
> = {
  fromDb: data => ({
    id: data.id,
    childcareProvided:
      data.childcareProvided &&
      allowOperationsWithBooleanValueConverter.fromDb(data.childcareProvided),
    foodProvided:
      data.childcareProvided &&
      allowOperationsWithBooleanValueConverter.fromDb(data.foodProvided),
    host: data.host && workshopHostConverter.fromDb(data.host),
    hostedPrivately: data.hostedPrivately,
    location: data.location && workshopLocationConverter.fromDb(data.location),
    photo: allowOperationsWithStringValueConverter.fromDb(data.photo),
    shipmentAddress:
      data.shipmentAddress &&
      allowOperationsShortAddressWithConverter.fromDb(data.shipmentAddress),
    siteCoordinator:
      data.siteCoordinator &&
      workshopSiteCoordinatorConverter.fromDb(data.siteCoordinator),
    status: data.status,
    type: data.type,
    shipmentAddressSameAsLocationAddress:
      data.shipmentAddressSameAsLocationAddress,
    locationSameAsHost: data.locationSameAsHost,
    typeCategory: data.typeCategory,
    classes: classesConverter(data.capacity.value).fromDb(data.classes),
    possibleAgeGroups: data.possibleAgeGroups,
    flyerData: flyersDataConverter.fromDb(data),
    description:
      data.description &&
      allowOperationsWithStringValueConverter.fromDb(data.description),
    capacity: workshopCapacityConverter.fromDb(data.capacity),
    attendanceType: data.attendanceType,
    meetingLink:
      data.meetingLink &&
      allowOperationsWithStringValueConverter.fromDb(data.meetingLink),
    customQuestion:
      data.customQuestion &&
      allowOperationsWithStringValueConverter.fromDb(data.customQuestion),
    allowedActions: allowedActionsConverter(
      data.status,
      data.attendanceType,
    ).fromDb(data.allowedActions),
    isCCP: [
      EWorkshopStandaloneType.CHILD_CARE_AND_PRESCHOOL_PROGRAMS_PART_1,
      EWorkshopStandaloneType.CHILD_CARE_AND_PRESCHOOL_PROGRAMS_PART_2,
      EWorkshopSeriesType.LEARNING_CHILD_CARE_AND_PRESCHOOL_PROGRAMS,
    ].includes(data.type),
    actualSessionDates: data.actualSessionDates,
    hasStartedPart: data.hasStartedPart,
    parts: (data.parts || []).map(part => partsConverter.fromDb(part)),
  }),
};

export const workshopChildCareConverter: TFetchConverter<
  TWorkshopChildCareUI,
  TWorkshopChildCareDB
> = {
  fromDb: data => ({
    ageGroup: data.ageGroup,
    count: data.count,
  }),
};

export const photoGalleryConverter: TFetchConverter<
  TPhotoGalleryUI,
  TPhotoGalleryDB
> = {
  fromDb: data => ({
    originalPhoto: data.originalPhoto,
    thumbnailPhoto: data.thumbnailPhoto,
  }),
};

export const workshopGalleryConverter: TFetchConverter<
  TWorkshopGalleryUI,
  TWorkshopGalleryDB
> = {
  fromDb: data => ({
    photos:
      data.photos &&
      data.photos.map(item => photoGalleryConverter.fromDb(item)),
  }),
};

export const addWorkshopPhotoConverter: TCreateConverter<
  TAddWorkshopPhotoUI,
  TAddWorkshopPhotoDB
> = {
  toDb: data => ({
    workshopId: data.workshopId,
    photoUrl: data.photoUrl,
  }),
};

export const workshopFilterConverter = (
  filters: TTableFilters<EWorkshopFilterParams>['filters'],
) => {
  const filtersToDB = {
    ...(filters?.statuses && {
      [EWorkshopFilterParams.STATUS]: filters?.statuses.map(status => status),
    }),
    ...(filters?.types && {
      [EWorkshopFilterParams.TYPE]: filters?.types.map(type => type),
    }),
    ...(filters?.cities && {
      [EWorkshopFilterParams.CITY]: filters?.cities,
    }),
    ...(filters?.hostIds && {
      [EWorkshopFilterParams.HOST]: filters?.hostIds,
    }),
    ...(filters?.tagIds && {
      [EWorkshopFilterParams.TAGS]: filters?.tagIds,
    }),
    ...(filters?.locationIds && {
      [EWorkshopFilterParams.LOCATION]: filters?.locationIds,
    }),
    ...(filters?.hostingTypes && {
      [EWorkshopFilterParams.HOSTING_TYPES]: filters?.hostingTypes,
    }),
    ...(filters?.attendanceTypes && {
      [EWorkshopFilterParams.ATTENDANCE_TYPE]: filters?.attendanceTypes,
    }),
    ...(filters?.siteCoordinator && {
      [EWorkshopFilterParams.SITE_COORDINATOR]: filters?.siteCoordinator,
    }),
  };
  return filtersToDB;
};

export const workshopClassAgeGroupEditConverter: TCreateConverter<
  TWorkshopClassAgeGroupEditUI,
  TWorkshopClassAgeGroupEditDB
> = {
  toDb: data => ({
    workshopClassId: data.id,
    workshopId: data.workshopId,
    ageGroup: data.value,
  }),
};

export const workshopClassLanguageEditConverter: TCreateConverter<
  TWorkshopClassLanguageEditUI,
  TWorkshopClassLanguageEditDB
> = {
  toDb: data => ({
    workshopClassId: data.id,
    workshopId: data.workshopId,
    language: data.value,
  }),
};

export const workshopClassSessionDateTimeEditConverter: TCreateConverter<
  TWorkshopClassDateTimeEditUI,
  TWorkshopClassDateTimeEditDB
> = {
  toDb: data => ({
    workshopClassId: data.id,
    workshopSessionId: data.workshopSessionId,
    workshopId: data.workshopId,
    dateTime: data.value && dateConverter(data.value, dateTimeFormatDB),
  }),
};
export const workshopPartDateTimeEditConverter: TCreateConverter<
  TWorkshopPartDateTimeEditUI,
  TWorkshopPartDateTimeEditDB
> = {
  toDb: data => {
    return {
      dateTime: data.dateTime && dateConverter(data.dateTime, dateTimeFormatDB),
      workshopId: data.workshopId,
      workshopPartId: data.workshopPartId,
    };
  },
};

export const workshopClassSessionFacilitatorEditConverter: TCreateConverter<
  TWorkshopClassFacilitatorEditUI,
  TWorkshopClassFacilitatorEditDB
> = {
  toDb: data => ({
    workshopClassId: data.id,
    workshopSessionId: data.workshopSessionId,
    userId: data.value || null,
    workshopId: data.workshopId,
  }),
};

export const classCapacityEditConverter: TCreateConverter<
  TClassCapacityEditUI,
  TClassCapacityEditDB
> = {
  toDb: data => ({
    workshopClassId: data.id,
    workshopId: data.workshopId,
    capacity: data.value,
  }),
};

export const workshopCapacityEditConverter: TCreateConverter<
  TWorkshopCapacityEditUI,
  TWorkshopCapacityEditDB
> = {
  toDb: data => ({
    workshopId: data.workshopId,
    capacity: data.value || null,
  }),
};

export const workshopLocationEditConverter: TCreateConverter<
  TWorkshopLocationEditUI,
  TWorkshopLocationEditDB
> = {
  toDb: data => ({
    locationId: data.value,
    workshopId: data.id,
  }),
};
export const workshopHostEditConverter: TCreateConverter<
  TWorkshopHostEditUI,
  TWorkshopHostEditDB
> = {
  toDb: data => ({
    hostId: data.value || null,
    workshopId: data.id,
  }),
};

export const workshopMeetingLinkEditConverter: TCreateConverter<
  TWorkshopMeetingLinkEditUI,
  TWorkshopMeetingLinkEditDB
> = {
  toDb: data => ({
    meetingLink: data.value || null,
    workshopId: data.id,
  }),
};

export const workshopSiteCoordinatorEditConverter: TCreateConverter<
  TWorkshopSiteCoordinatorEditUI,
  TWorkshopSiteCoordinatorEditDB
> = {
  toDb: data => ({
    userId: data.value,
    workshopId: data.id,
  }),
};

export const workshopFoodProvidedEditConverter: TCreateConverter<
  TWorkshopFoodProvidedEditUI,
  TWorkshopFoodProvidedEditDB
> = {
  toDb: data => ({
    foodProvided: data.value,
    workshopId: data.id,
  }),
};
export const workshopChildcareEditConverter: TCreateConverter<
  TWorkshopChildcareProvidedEditUI,
  TWorkshopChildcareProvidedEditDB
> = {
  toDb: data => ({
    childcareProvided: data.value,
    workshopId: data.id,
  }),
};
export const workshopShipingAddressConverter: TCreateConverter<
  TWorkshopShippingAddressEditUI,
  TWorkshopShippingAddressEditDB
> = {
  toDb: data => ({
    workshopId: data.id,
    address: addressConvertor.toDb(data),
  }),
};

export const workshopDescriptionEditConverter: TCreateConverter<
  TWorkshopDescriptionEditUI,
  TWorkshopDescriptionEditDB
> = {
  toDb: data => ({
    description: data.value,
    workshopId: data.id,
  }),
};

export const workshopCategoryToCount: Record<EWorkshopCategory, number> = {
  [EWorkshopCategory.STANDALONE]: 1,
  [EWorkshopCategory.SERIES_WITH_TWO_PARTS]: 2,
  [EWorkshopCategory.SERIES_WITH_THREE_PARTS]: 3,
};

export const workshopPartsToCount: Record<EWorkshopPartType, number> = {
  [EWorkshopPartType.STANDALONE]: 0,
  [EWorkshopPartType.PART_ONE]: 1,
  [EWorkshopPartType.PART_TWO]: 2,
  [EWorkshopPartType.PART_THREE]: 3,
};
