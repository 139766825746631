import { useCheckPermission } from 'hooks/useCheckPermission';
import AllELCDetail from 'pages/private/ELCs/ELCDetail/AllELCDetail';
import AllELCs from 'pages/private/ELCs/ELCList/AllELCs';
import { RoutesPaths, SubRouteType } from 'routes/Routes.types';

const useGetELCsSubRoutesData = () => {
  const ELCsSubRoutes: SubRouteType[] = [
    {
      component: AllELCs,
      path: RoutesPaths.ELCS,
      show: false,
      showByPermissions: useCheckPermission('ELC_LIST_VIEW'),
    },
    {
      component: AllELCDetail,
      path: RoutesPaths.ELC_DETAIL,
      show: false,
      showByPermissions: useCheckPermission('ELC_LIST_VIEW'),
    },
  ];
  return { ELCsSubRoutes };
};

export default useGetELCsSubRoutesData;
