import get from 'lodash/get';
import { useTranslation, Button } from '@packages/utils';
import {
  useShowToastNotification,
  ENotificationType,
} from 'components/Base/Notification';
import { Row, Col } from 'components/Base/Grid';
import useForm from 'hooks/useForm';
import { useCreateWorkshopClassMutation } from 'redux/apiSlice/workshops.slice';
import { TValidateFieldsError } from 'data/types/generic.types';
import {
  EWorkshopPartType,
  EWorkshopCategory,
} from 'data/types/workshops.types';
import SeriesForm from './CreateSessionForm/SeriesForm';
import StandaloneForm from './CreateSessionForm/StandaloneForm';
import {
  TCreateSessionCardProps,
  TWorkshopClassFormProps,
} from './CreateSessionCard.types';

const CreateSessionCard = ({
  setShowForm,
  typeCategory,
  possibleAgeGroup,
  id,
  formValues,
  setFormValues,
}: TCreateSessionCardProps) => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.workshops.details.sessions',
  });
  const { showToastNotification } = useShowToastNotification();
  const { form } = useForm<TWorkshopClassFormProps>();
  const [createSessions, { isLoading }] = useCreateWorkshopClassMutation();

  const handleCancel = () => {
    setShowForm(false);
    form.resetFields();
    setFormValues(null);
  };

  const getModifiedValues = (
    values: TWorkshopClassFormProps,
  ): TWorkshopClassFormProps => {
    const modifiedValues: TWorkshopClassFormProps = { ...values };

    if (typeCategory === EWorkshopCategory.STANDALONE) {
      modifiedValues.sessions[0].partType = EWorkshopPartType.STANDALONE;
    } else {
      modifiedValues.sessions = modifiedValues.sessions.map(
        (session, index) => ({
          ...session,
          partType: Object.values(EWorkshopPartType)[index + 1],
        }),
      );
    }

    return modifiedValues;
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();

      const modifiedValues = getModifiedValues(values);
      await createSessions({
        workshopId: id,
        ...modifiedValues,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
      handleCancel();
    } catch (e) {
      if (!(e as TValidateFieldsError)?.errorFields) {
        const message = get(e, 'data.message');
        showToastNotification({
          message,
          type: ENotificationType.ERROR,
        });
      }
    }
  };

  return (
    <>
      {typeCategory === EWorkshopCategory.STANDALONE ? (
        <StandaloneForm
          formValues={formValues}
          form={form}
          possibleAgeGroup={possibleAgeGroup}
        />
      ) : (
        <SeriesForm
          form={form}
          formValues={formValues}
          typeCategory={typeCategory}
          possibleAgeGroup={possibleAgeGroup}
        />
      )}
      <Row gutter={[16, 16]} className="mt-3">
        <Col>
          <Button
            onClick={handleSubmit}
            size="small"
            color="primary"
            type="primary"
            loading={isLoading}
          >
            {t('SAVE_BTN')}
          </Button>
        </Col>
        <Col>
          <Button
            onClick={handleCancel}
            size="small"
            color="primary"
            type="default"
            disabled={isLoading}
          >
            {t('CANCEL_BTN')}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default CreateSessionCard;
