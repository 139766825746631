import { FC } from 'react';
import { Paragraph } from 'components/Base/Typography';
import { Tooltip } from 'components/Base/Tooltip';
import { TParagraphEllipsisProps } from './ParagraphEllipsis.types';

export const ParagraphEllipsis: FC<TParagraphEllipsisProps> = ({
  text,
  textMaxLength,
  ...paragraphProps
}) => {
  const isLonger = text?.length > textMaxLength;
  return (
    <Tooltip title={isLonger ? text : ''}>
      <Paragraph {...paragraphProps}>
        {isLonger ? `${text?.slice(0, textMaxLength)}...` : text}
      </Paragraph>
    </Tooltip>
  );
};
