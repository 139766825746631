import { FC } from 'react';
import { Form, FormItem } from 'components/Base/Form';
import PlaceAutocomplete from 'components/Shared/PlaceAutocomplete';
import { TAddShippingDetailsFormProps } from './AddShippingDetailsForm.types';
import useGetFormData from './AddShippingDetailsForm.data';

const AddShippingDetailsForm: FC<TAddShippingDetailsFormProps> = ({ form }) => {
  const { addShippingDetailsFormFields } = useGetFormData();
  const { address } = addShippingDetailsFormFields;

  return (
    <Form form={form}>
      <FormItem {...address}>
        <PlaceAutocomplete placeholder={address.placeholder} />
      </FormItem>
    </Form>
  );
};

export default AddShippingDetailsForm;
