import { showNotification, ENotificationType } from '@idaho-aeyc/ui-kit';
import { useTranslation } from '@packages/utils';

const useShowToastNotification = () => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.messages',
  });
  const showToastNotification = ({
    message,
    type,
  }: {
    message?: string;
    type: ENotificationType;
  }) => {
    let toastMessage = message;
    if (type === ENotificationType.ERROR && !message) {
      toastMessage = t('error.SOMETHING_WENT_WRONG');
    }
    return showNotification({ message: toastMessage, type });
  };
  return { showToastNotification };
};

export { useShowToastNotification, ENotificationType };
