import fileWithParagraphs from 'assets/images/fileWithParagraphs.svg';
import { Image, Row, Col, Title, useTranslation } from '@packages/utils';

export const DetailsSectionEmptyView = () => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.details-tab.details-section',
  });

  return (
    <Row justify="center" className="text-center p-10">
      <Col span={24}>
        <Image src={fileWithParagraphs} height={140} alt="" />
      </Col>
      <Col span={24} className="mt-6">
        <Title level={3}>{t('EMPTY_VIEW')}</Title>
      </Col>
    </Row>
  );
};

export default DetailsSectionEmptyView;
