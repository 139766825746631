import { FC, useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { useTranslation, Card } from '@packages/utils';
import { Modal } from 'components/Base/Modal';
import { Col, Row } from 'components/Base/Grid';
import { Title } from 'components/Base/Typography';
import {
  useShowToastNotification,
  ENotificationType,
} from 'components/Base/Notification';
import { Divider } from 'components/Base/Divider';
import { TRtkErrorType } from 'data/types/generic.types';
import { useUpdateAttendanceMutation } from 'redux/apiSlice/registrants.slice';
import {
  EAttendanceStatus,
  TAttendancesGetUI,
} from 'data/types/registrants.types';
import { EWorkshopPartType } from 'data/types/workshops.types';
import { TUpdateAttendancesModalProps } from './UpdateAttendancesModal.types';
import UpdateAttendanceItem from './UpdateAttendanceItem';
import OrientationKitReceived from './OrientationKitReceived';
import { FirstCol, SecondCol } from './UpdateAttendancesModal.styles';
import AttendanceText from '../components/AttendanceText';
import KitLanguageText from '../components/KitLanguageText';

const UpdateAttendancesModal: FC<TUpdateAttendancesModalProps> = ({
  open,
  setOpen,
  fullName,
  attendances,
  registrantId,
  orientationKit,
  materialsLanguage,
  workshopId,
}) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.workshops.details.workshop-registrants.actions.edit.modal',
  });
  const { showToastNotification } = useShowToastNotification();
  const [attendancesState, setAttendancesState] =
    useState<TAttendancesGetUI[]>(attendances);

  const [orientationKitReceived, setOrientationKitReceived] = useState(
    orientationKit?.received,
  );

  const onSelect = (
    attendanceId: string,
    attendanceStatus: EAttendanceStatus,
  ) => {
    const newAttendancesState = attendancesState?.map(attendance => {
      if (attendance.sessionAttendanceId === attendanceId) {
        const isAttendedWithKitCountNull =
          attendance.kitCount === null &&
          attendanceStatus !== EAttendanceStatus.ABSENT;
        const isAbsent = attendanceStatus === EAttendanceStatus.ABSENT;
        return {
          ...attendance,
          attendanceStatus,
          ...((isAbsent || isAttendedWithKitCountNull) && { kitCount: 0 }),
        };
      }
      return attendance;
    });
    setAttendancesState(newAttendancesState);
  };

  const onKitCountChange = (attendanceId: string, kitCount: number) => {
    const newAttendancesState = attendancesState?.map(attendance => {
      if (attendance.sessionAttendanceId === attendanceId) {
        return {
          ...attendance,
          kitCount,
        };
      }
      return attendance;
    });
    setAttendancesState(newAttendancesState);
  };

  const isAllPartsNotAttended = attendancesState?.every(
    attendance => attendance.attendanceStatus === EAttendanceStatus.ABSENT,
  );

  const isStandalone = attendances.some(
    el => el.partType === EWorkshopPartType.STANDALONE,
  );
  const firstColLabel = isStandalone
    ? t('attendance-chart.labels.TYPE')
    : t('attendance-chart.labels.PART');

  const [updateAttendance, { isLoading }] = useUpdateAttendanceMutation();
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (isAllPartsNotAttended && orientationKitReceived) {
      setOrientationKitReceived(false);
    }
  }, [attendancesState]);

  const handleSubmit = async () => {
    if (
      isEqual(attendances, attendancesState) &&
      isEqual(orientationKit?.received, orientationKitReceived)
    ) {
      handleClose();
      return;
    }
    try {
      await updateAttendance({
        attendances:
          attendancesState?.map(item => ({
            attendanceId: item.sessionAttendanceId,
            attendanceStatus: item.attendanceStatus,
            kitCount: item.kitCount,
          })) || [],
        registrantId,
        orientationKitReceived,
        workshopId,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
      handleClose();
    } catch (err) {
      showToastNotification({
        message: (err as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  return (
    <Modal
      open={open}
      title={t('TITLE')}
      okText={t('SUBMIT_BTN')}
      cancelText={t('CANCEL_BTN')}
      onCancel={handleClose}
      confirmLoading={isLoading}
      onOk={handleSubmit}
      destroyOnClose
    >
      <Row className="mb-2">
        <AttendanceText text={fullName} />
      </Row>
      <Row className="mb-4" justify="start">
        <KitLanguageText language={materialsLanguage} />
      </Row>

      <Col className="w-100 px-0">
        <Card
          styles={{
            body: { padding: '2px 12px' },
          }}
          className="mb-2"
        >
          <Row wrap={false}>
            <FirstCol>
              <Title level={5} className="m-0">
                {firstColLabel}
              </Title>
            </FirstCol>
            <Divider type="vertical" thickness={3} height={24} />
            <SecondCol>
              <Title level={5} className="m-0">
                {t('attendance-chart.labels.ATTENDED')}
              </Title>
            </SecondCol>
            <Divider type="vertical" thickness={3} height={24} />
            <Col>
              <Title level={5} className="m-0">
                {t('attendance-chart.labels.KITS_RECEIVED')}
              </Title>
            </Col>
          </Row>
        </Card>
      </Col>

      <Row justify="end" className="mb-4 w-100">
        {attendancesState?.map(attendance => (
          <UpdateAttendanceItem
            key={attendance.sessionAttendanceId}
            attendance={attendance}
            onSelect={onSelect}
            onKitCountChange={onKitCountChange}
          />
        ))}
      </Row>

      {orientationKit && (
        <OrientationKitReceived
          isReceived={orientationKitReceived}
          setIsReceived={setOrientationKitReceived}
          disabled={isAllPartsNotAttended}
          orientationKit={orientationKit}
        />
      )}
    </Modal>
  );
};

export default UpdateAttendancesModal;
