import { useTranslation } from '@packages/utils';
import { TColumnType } from 'components/Base/Table/Table.types';
import {
  EChildcareColumnKey,
  TRegistrantsWithChildcareUI,
} from 'data/types/registrants.types';
import useGetTableColumnData from 'components/Base/Table/components/SearchFilter';
import { childCareTableKeyPrefix } from '../ChildCareTab.data';
// import ChildcareEditColumn from './ChildcareEditColumn';

const useGetChildcareColumnData = () => {
  const { t } = useTranslation({
    keyPrefix: childCareTableKeyPrefix.concat('.title'),
  });
  const { getTableColumnSearchProps } = useGetTableColumnData();

  const defaultColumns: TColumnType<TRegistrantsWithChildcareUI>[] = [
    {
      title: t('FIRST_NAME'),
      dataIndex: 'firstName',
      key: EChildcareColumnKey.FIRST_NAME,
      sorter: true,
      ...getTableColumnSearchProps<TRegistrantsWithChildcareUI>(),
    },
    {
      title: t('LAST_NAME'),
      dataIndex: 'lastName',
      key: EChildcareColumnKey.LAST_NAME,
      sorter: true,
      ...getTableColumnSearchProps<TRegistrantsWithChildcareUI>(),
    },
    {
      title: t('CHILD_CARE'),
      dataIndex: 'childcareRequirements',
      key: 'childcareRequirements',
      width: 250,
      // render: (text, record) => {
      //   return <ChildcareEditColumn {...record} workshopId={workshopId} />;
      // },
      onCell: () => {
        return {
          style: {
            height: '140px', // for not dxkal
            paddingTop: '4px',
            paddingBottom: '4px',
          },
        };
      },
      render: () => null,
    },
  ];

  return { defaultColumns };
};

export default useGetChildcareColumnData;
