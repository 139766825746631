import React, { Key } from 'react';
import { TKitManagementParentUI } from 'data/types/kitManagement.types';

export type TParentActionsProps = {
  setShowAlert: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRowKeys: Key[];
  data: TKitManagementParentUI[];
};
export enum EParentOrderActions {
  MARK_AS_ORDERED = 'MARK_AS_ORDERED',
  MARK_AS_NO_ADDRESS = 'MARK_AS_NO_ADDRESS',
  EXPORT = 'EXPORT',
}
