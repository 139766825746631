import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Auth } from 'aws-amplify';
import { RoutesPaths } from 'routes/Routes.types';

const baseUrl =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    ? `${process.env.REACT_APP_HOSTNAME}/api/v1`
    : `${process.env.REACT_APP_HOSTNAME}/api/v1`;

export const authNoSessionSplitApi = (
  reducerPath: string,
  tagTypes?: string[],
) =>
  createApi({
    reducerPath,
    baseQuery: fetchBaseQuery({
      baseUrl,
    }),
    tagTypes: tagTypes || [],
    endpoints: () => ({}),
  });

export const authSplitApi = (reducerPath: string, tagTypes?: string[]) =>
  createApi({
    reducerPath,
    baseQuery: fetchBaseQuery({
      baseUrl,
      prepareHeaders: async headers => {
        let session = null;
        try {
          session = await Auth.currentSession();
        } catch (err) {
          window.location.replace(`/${RoutesPaths.LOGIN}`);
          localStorage.clear();
        }
        if (session) {
          headers.set(
            'authorization',
            `Bearer ${session.getIdToken().getJwtToken()}`,
          );
          headers.set('x-user-profile', 'READY_ADMIN');
        }
        return headers;
      },
    }),
    tagTypes: tagTypes || [],
    endpoints: () => ({}),
  });

export const emptySplitApi = (reducerPath: string, tagTypes?: string[]) =>
  createApi({
    reducerPath,
    tagTypes: tagTypes || [],
    baseQuery: fetchBaseQuery({
      baseUrl,
    }),
    endpoints: () => ({}),
  });
