export enum ERegistrantsUrls {
  BASE_URL = 'workshops',
  REGISTRANTS = 'registrants',
  ATTENDANCE = 'attendance',
  WAIT_LIST = 'wait-list',
  CITIES = 'cities',
  MOVE = 'move',
  UNREGISTER = 'unregister',
  REREGISTER = 'activate',
  LOCATIONS = 'locations',
  CILDCARE = 'childcare',
  NO_CILDCARE = 'no-childcare',
  EXPORT = 'export',
  VALIDATE = 'validate',
}
const baseUrl = ERegistrantsUrls.BASE_URL;
export const updateAttendanceUrl = `${baseUrl}/${ERegistrantsUrls.REGISTRANTS}/${ERegistrantsUrls.ATTENDANCE}`;
export const removeRegistrantsUrl = `${baseUrl}/${ERegistrantsUrls.REGISTRANTS}`;
export const unRegisterRegistrantsUrl = `${baseUrl}/${ERegistrantsUrls.REGISTRANTS}/${ERegistrantsUrls.UNREGISTER}`;
export const reRegisterRegistrantsUrl = `${baseUrl}/${ERegistrantsUrls.REGISTRANTS}/${ERegistrantsUrls.REREGISTER}`;
export const getWaitlistLocations = `${baseUrl}/${ERegistrantsUrls.REGISTRANTS}/${ERegistrantsUrls.WAIT_LIST}/${ERegistrantsUrls.LOCATIONS}`;
export const registrantsChildcareUrl = `${baseUrl}/${ERegistrantsUrls.REGISTRANTS}/${ERegistrantsUrls.CILDCARE}`;
export const registrantsNoChildcareUrl = `${baseUrl}/${ERegistrantsUrls.REGISTRANTS}/${ERegistrantsUrls.NO_CILDCARE}`;
