import { FC } from 'react';
import { useTheme } from 'styled-components';
import { TextIcon } from 'components/Base/TextIcon';
import { Icon } from 'components/Base/Icon';
import { TActionGroupItemLabelProps } from './ActionGroupItemLabel.types';

const ActionGroupItemLabel: FC<TActionGroupItemLabelProps> = ({
  icon,
  label,
  onClick,
}) => {
  const theme = useTheme();
  const alt = `${icon}-icon`;
  return (
    <TextIcon
      {...(icon && {
        icon: (
          <Icon
            icon={icon}
            alt={alt}
            color={theme.colorBlackPrimary}
            size={16}
          />
        ),
      })}
      label={label}
      alt={alt}
      strong={false}
      color={theme.colorBlackPrimary}
      fontWeight={theme.fontWeightRegular}
      {...(onClick && { onClick })}
    />
  );
};

export default ActionGroupItemLabel;
