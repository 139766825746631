import {
  Card,
  ENotificationType,
  TRtkErrorType,
  useShowToastNotification,
  useTranslation,
} from '@packages/utils';
import EditableReachTextEditor from 'components/Base/EditableFields/EditableReachTextEditor';
import { InplaceEditLabels } from 'components/Base/EditableFields/components/InplaceEditLabel';
import { useEditElcMeetingThingsToShareUpdateMutation } from 'redux/apiSlice/elcs.slice';
import { TThingToShareInplaceEditProps } from './ThingToShareInplaceEdit.types';

const ThingToShareInplaceEdit = ({
  content,
  elcId,
  meetingId,
  thingToShareId,
  type,
}: TThingToShareInplaceEditProps) => {
  const { t } = useTranslation({
    keyPrefix: `descriptions.admin-portal.elcs.detail.meetings-tab.details.things-to-share.${type}`,
  });
  const { showToastNotification } = useShowToastNotification();
  const [addElcMeetingThingsToShare, { isLoading, isError }] =
    useEditElcMeetingThingsToShareUpdateMutation();

  const onSubmit = async (value: string) => {
    try {
      await addElcMeetingThingsToShare({
        meetingId,
        elcId,
        thingToShareId,
        content: value,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (err) {
      showToastNotification({
        message: (err as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  return (
    <Card className="mb-4">
      <InplaceEditLabels label={t('INPLACE_EDIT_LABEL')} />
      <EditableReachTextEditor
        title={content || ''}
        fitContent={false}
        onEdit={onSubmit}
        emptyText={t('EMPTY_TEXT')}
        placeholder={t('PLACEHOLDER')}
        readonly={false}
        isError={isError}
        isLoading={isLoading}
      />
    </Card>
  );
};

export default ThingToShareInplaceEdit;
