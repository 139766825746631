import { useCheckPermission } from 'hooks/useCheckPermission';
import PeopleDetails from 'pages/private/People/PeopleDetails';
import PeopleList from 'pages/private/People/PeopleList';
import { RoutesPaths, SubRouteType } from 'routes/Routes.types';

const useGetPeopleSubRoutesData = () => {
  const peopleSubRoutes: SubRouteType[] = [
    {
      component: PeopleList,
      path: RoutesPaths.PEOPLE,
      show: false,
      showByPermissions: useCheckPermission('PEOPLE_VIEW'),
    },
    {
      component: PeopleDetails,
      path: RoutesPaths.PEOPLE_DETAILS,
      show: false,
      showByPermissions: useCheckPermission('PEOPLE_VIEW'),
    },
  ];
  return { peopleSubRoutes };
};

export default useGetPeopleSubRoutesData;
