import { useEffect, useState } from 'react';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { useNavigate, useLocation } from 'react-router-dom';
import useGetSidebarData from 'routes/RoutesData';
import useSidebarContext from 'context/CollapseSidebarContext';
import { Icon } from 'components/Base/Icon';
import { Menu } from 'components/Base/Layout/Sidebar/Menu';

const SidebarBodyMenu = () => {
  const { collapsed } = useSidebarContext();
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const navigate = useNavigate();
  const location = useLocation().pathname;
  const { privateRoutes } = useGetSidebarData();

  const routesInSidebar = privateRoutes.filter(route => route.show);
  const subRoutesInSidebar = routesInSidebar
    .map(route => route.subRoutes?.filter(subRoute => subRoute.show))
    .flat();

  const selectedKey =
    subRoutesInSidebar.find(route => location.startsWith(`/app/${route?.path}`))
      ?.path ||
    routesInSidebar.find(route => location.startsWith(`/app/${route.path}`))
      ?.path ||
    '';

  useEffect(() => {
    if (location) {
      setOpenKeys([selectedKey.split('/')[0]]);
    }
  }, [location]);

  const items: ItemType[] = routesInSidebar.map(item => {
    const subRoutesShown = (item?.subRoutes || [])?.filter(el => el.show);
    const hasSubRoutes = subRoutesShown?.length;
    const parentRouteIcon =
      selectedKey === item.path ||
      (openKeys.includes(item.path) && item.selectedIcon)
        ? item.selectedIcon
        : item.icon;
    if (hasSubRoutes) {
      return {
        label: item.title,
        key: item.path,
        icon: parentRouteIcon && <Icon icon={parentRouteIcon} />,
        children: subRoutesShown?.map(subRoute => {
          const subRouteIcon =
            selectedKey === subRoute.path
              ? subRoute.selectedIcon
              : subRoute.icon;
          return {
            label: subRoute.title,
            key: subRoute.path,
            icon: subRouteIcon && <Icon icon={subRouteIcon} />,
            onClick: () => {
              navigate(subRoute.path);
            },
          };
        }),
      };
    }
    return {
      label: item.title,
      key: item.path,
      icon: parentRouteIcon && <Icon icon={parentRouteIcon} />,
      onClick: () => {
        navigate(item.path);
        setOpenKeys([]);
      },
    };
  });

  return (
    <Menu
      selectedKeys={[selectedKey]}
      mode="inline"
      items={items}
      $collapsed={collapsed}
      onOpenChange={keys => {
        const selectedKeyParent = selectedKey.split('/')[0];
        const lastOpenedKey = keys[keys.length - 1];
        if (keys.length > 1) {
          setOpenKeys([lastOpenedKey, selectedKeyParent]);
        } else {
          setOpenKeys(keys);
        }
      }}
      {...(openKeys.length && { openKeys })}
    />
  );
};

export default SidebarBodyMenu;
