import { FC } from 'react';
import { Col } from 'components/Base/Grid';
import ActionButton from '../ActionButton';
import { TEditActionProps } from '../../QuestionTab.types';

const EditAction: FC<TEditActionProps> = ({ canEdit, onClick }) => {
  return canEdit ? (
    <Col>
      <ActionButton onClick={onClick} type="edit" />
    </Col>
  ) : null;
};

export default EditAction;
