import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { TRtkErrorType, useTranslation } from '@packages/utils';
import { TabsProps } from 'components/Base/Tabs/Tabs.types';
import { TBackButtonState } from 'components/Base/PageWrapper/PageWrapper.types';
import { RoutesPaths } from 'routes/Routes.types';
import { useGetPeopleByIdQuery } from 'redux/apiSlice/people.slice';
import PageWrapper from 'components/Base/PageWrapper';
import { Tabs } from 'components/Base/Tabs';
import useKeepTabsInUrl from 'hooks/useKeepTabsInUrl';
import { EPeopleDetailsTabsInUrl } from './PeopleDetails.data';
import DetailsTab from './components/DetailsTab';

const PeopleDetails = () => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.people',
  });
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = params as { id: string };

  const { data, isLoading, error } = useGetPeopleByIdQuery({
    userId: id,
  });
  const goBackState = (location.state as TBackButtonState) || {
    backUrl: `/app/${RoutesPaths.PEOPLE}`,
    backButtonSuffix: t('list.TITLE'),
  };

  const onBackClick = () => {
    navigate(goBackState.backUrl);
  };

  const { onTabChange, tabInUrl } = useKeepTabsInUrl({
    defaultTab: EPeopleDetailsTabsInUrl.DETAILS,
    stateInUrl: goBackState,
  });

  const tabItems: TabsProps['items'] = [
    {
      key: EPeopleDetailsTabsInUrl.DETAILS,
      label: t('details.tabs.DETAILS'),
      children: <DetailsTab data={data} />,
    },
  ];

  return (
    <PageWrapper
      title={data?.fullName}
      backButton={{
        labelPrefix: data?.fullName || '',
        tooltip: goBackState.backButtonSuffix,
        onClick: onBackClick,
      }}
      isLoading={isLoading}
      error={error as TRtkErrorType}
    >
      <Tabs
        defaultActiveKey={tabInUrl}
        onChange={onTabChange}
        items={tabItems}
      />
    </PageWrapper>
  );
};

export default PeopleDetails;
