import { useGetChildCareUrlQuery } from 'redux/apiSlice/workshops.slice';
import CopyUrls from '../CopyUrls';
import { TCopyChildCareUrlProps } from './CopyChildCareUrl.types';

const CopyChildCareUrl = ({ workshopId, copyText }: TCopyChildCareUrlProps) => {
  const { data: childCareUrl } = useGetChildCareUrlQuery(
    {
      workshopId,
    },
    {
      skip: !workshopId,
    },
  );

  return <CopyUrls copyText={copyText} copyUrl={childCareUrl} />;
};

export default CopyChildCareUrl;
