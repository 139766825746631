import { Col, Row } from 'components/Base/Grid';
import { TabBodyWrapper } from 'components/Base/Tabs';
import CardContent from 'components/Base/Layout/Content/CardContent';
import { useGetWorkshopChildCareCountByIdQuery } from 'redux/apiSlice/workshops.slice';
import { TChildCareTabProps } from './ChildCareTab.types';
import ChildcareList from './ChildcareList';
import ChildcareTotals from './ChildcareTotals';

const ChildCareTab = ({ id }: TChildCareTabProps) => {
  const { data, isLoading } = useGetWorkshopChildCareCountByIdQuery({
    workshopId: id,
  });

  return (
    <CardContent tabContent contentPadding="8px 0px">
      <TabBodyWrapper minHeight="55vh" isLoading={isLoading}>
        <Row gutter={[0, 24]}>
          <Col span={24} className="px-4">
            <ChildcareTotals childcareTotalCounts={data} />
          </Col>

          <Col span={24}>
            <ChildcareList workshopId={id} />
          </Col>
        </Row>
      </TabBodyWrapper>
    </CardContent>
  );
};

export default ChildCareTab;
