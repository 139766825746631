import { FC } from 'react';
import { useTranslateCapacityShowValue } from 'pages/private/Workshop/hooks/useTranslateCapacityShowValue';
import { ECapacityEmptyTexts } from 'data/types/workshops.types';
import { TCapacityColumnProps } from './CapacityColumn.types';

const CapacityColumn: FC<TCapacityColumnProps> = ({
  capacity,
  registrantCount,
}) => {
  const capacityText = useTranslateCapacityShowValue(
    capacity as ECapacityEmptyTexts,
  );
  return (
    <>
      {registrantCount} / {capacityText}
    </>
  );
};

export default CapacityColumn;
