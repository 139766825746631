import { useTranslation } from '@packages/utils';
import useGetValidationData from 'components/Base/Form/validationRules';
import { familyListKey } from 'pages/private/Family/Family.data';

const useGetFormData = () => {
  const { t } = useTranslation({
    keyPrefix: familyListKey.concat('.actions.edit-family.modal.form'),
  });
  const { validationRules } = useGetValidationData();
  const editFamilyFormFields = {
    firstName: {
      name: 'firstName',
      label: t('first-name.LABEL'),
      placeholder: t('first-name.PLACEHOLDER'),
      rules: [validationRules.requiredField()],
    },
    lastName: {
      name: 'lastName',
      label: t('last-name.LABEL'),
      placeholder: t('last-name.PLACEHOLDER'),
      rules: [validationRules.requiredField()],
    },
    email: {
      name: 'email',
      label: t('email.LABEL'),
      placeholder: t('email.PLACEHOLDER'),
      rules: [validationRules.requiredField(), validationRules.email()],
    },
    phone: {
      name: 'phone',
      label: t('mobile.LABEL'),
      placeholder: t('mobile.PLACEHOLDER'),
      rules: [validationRules.requiredField(), validationRules.phoneNumber()],
    },
  };

  return { editFamilyFormFields };
};

export default useGetFormData;
