import { TColumnFilterItem } from '@idaho-aeyc/ui-kit';
import { FilterValue } from 'antd/es/table/interface';

export enum EAgeGroup {
  ZERO_TO_ONE = 'ZERO_TO_ONE',
  ONE_TO_TWO = 'ONE_TO_TWO',
  TWO_TO_THREE = 'TWO_TO_THREE',
  TWO_TO_FIVE = 'TWO_TO_FIVE',
  THREE_TO_FOUR = 'THREE_TO_FOUR',
  THREE_TO_FIVE = 'THREE_TO_FIVE',
  FOUR_TO_FIVE = 'FOUR_TO_FIVE',
}

export enum EWorkshopLanguage {
  ENGLISH = 'ENGLISH',
  SPANISH = 'SPANISH',
}

export enum ESortDirection {
  ascend = 'ASC',
  descend = 'DESC',
}

export enum EAllowedOperations {
  UPDATE = 'UPDATE',
  REMOVE = 'REMOVE',
  ADD = 'ADD',
}

export type TErrorField = {
  name: string[];
  errors: string[];
  warnings: string[];
};

export type TValidateFieldsError = {
  errorFields: TErrorField[];
  outOfDate: boolean;
};

export type TOption = {
  value: string;
  label: string;
};

export type TPageableDataUI = {
  number: number;
  totalPages: number;
  totalElements: number;
  size: number;
};
export type TPageableDataDB = {
  number: number;
  totalPages: number;
  totalElements: number;
  size: number;
};

export type TPageableDataWithContentUI<T> = TPageableDataUI & {
  content: T;
} & TAllowedOperationsUI;

export type TPageableDataWithContentDB<T> = TPageableDataDB & {
  content: T;
} & TAllowedOperationsDB;

export type TSortParams = {
  sortField?: string;
  sortDirection?: ESortDirection;
};

export type TTableFilters<F extends string | number | symbol = string> = {
  page: number;
  pageSize: number;
  sortColumn: TSortParams;
  filters?: Record<F, FilterValue | null>;
};

export type TAllowedOperationsDB = EAllowedOperations[];

export type TAllowedOperationsUI = {
  readonly?: boolean;
  canRemove?: boolean;
  canAdd?: boolean;
};

export type TAllowedOperationsValueUI<T extends boolean | string | unknown> = {
  allowedOperations: TAllowedOperationsUI;
  value?: T;
};

export type TAllowedOperationsValueDB<T extends boolean | string | unknown> = {
  allowedOperations: TAllowedOperationsDB;
  value?: T;
};

export type TLanguageOptionUI = {
  value: EWorkshopLanguage;
  label: EWorkshopLanguage;
};

export type TAddressShortDataDB = {
  formattedAddress: string;
  id: string;
} | null;
export type TAddressShortDataUI = {
  formattedAddress: string;
  id: string;
} | null;

export type TAddressTypesUI = {
  placeId?: string;
  formattedAddress?: string;
  country?: string;
  state?: string;
  city?: string;
  streetNumber?: string;
  street?: string;
  zipCode?: string;
  latitude?: number;
  longitude?: number;
};

export type TAddressTypesDB = {
  placeId?: string;
  formattedAddress?: string;
  country?: string;
  state?: string;
  city?: string;
  streetNumber?: string;
  street?: string;
  zipCode?: string;
  latitude?: number;
  longitude?: number;
};

export type TShortAddressWithAllowOperationsUI = {
  id: string;
  formattedAddress: string;
  allowedOperations: TAllowedOperationsUI;
};
export type TShortAddressWithAllowOperationsDB = {
  id: string;
  formattedAddress: string;
  allowedOperations: TAllowedOperationsDB;
};

export type TRtkErrorType = {
  code?: number;
  data: {
    message: string;
  };
  status?: number;
} | null;

export type TExportCsvDB = {
  content: string;
  fileName: string;
};

export type TExportCsvUI = {
  content: Blob;
  fileName: string;
};

export type TLocationHostFilterDataDB = {
  id: string;
  name: string;
};
export type TTableFilterDataUI = TColumnFilterItem;

export type TExportFilters<F extends string | number | symbol = string> = {
  filters?: Record<F, FilterValue | null>;
};

export type TFile = {
  url: string;
  name: string;
};
