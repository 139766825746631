import { useRef } from 'react';
import { useTranslation } from '@packages/utils';
import useElementResize from 'hooks/useElementResize';
import { TWorkshopDataUI } from 'data/types/workshops.types';
import { TKitManagementWorkshopUI } from 'data/types/kitManagement.types';
import { Table } from 'components/Base/Table';
import { Alert } from 'components/Base/Alert';
import useNavigateWithBackState from 'hooks/useNavigateWithBackState';
import { TWorkshopBodyProps } from './WorkshopBody.types';

const WorkshopBody = ({
  showAlert,
  setShowAlert,
  dataSource,
  isFetching,
  totalDataCount,
  hasFilters,
  onFiltersReset,
  columns,
  pageSize,
  setPageSize,
  setSelectedRows,
  onChange,
  selectedRows,
  currentPage,
  storagePath,
}: TWorkshopBodyProps) => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.kit-management.list',
  });
  const alertRef = useRef<HTMLDivElement>(null);
  const { height: alertHeight } = useElementResize({
    ref: alertRef,
  });
  const { goWorkshopDetails } = useNavigateWithBackState();
  const navigateToWorkshopDetails = (record: TWorkshopDataUI) => {
    return {
      onClick: () => goWorkshopDetails(record.workshopId, t('TITLE')),
    };
  };

  return (
    <>
      {/* added 1px minHeight to calculate alert height correctly when is
      shown/hidden */}
      <div ref={alertRef} className="mx-4" style={{ minHeight: '1px' }}>
        {showAlert && (
          <Alert
            type="warning"
            closable
            message={t('table.ACTION_ALERT_MESSAGE')}
            className="mt-4"
            onClose={() => setShowAlert(false)}
          />
        )}
      </div>
      <Table<TKitManagementWorkshopUI>
        hasFilters={hasFilters}
        onFiltersReset={onFiltersReset}
        columns={columns}
        dataSource={dataSource}
        rowClassName="pointer"
        onRow={navigateToWorkshopDetails}
        loading={isFetching}
        pageSize={pageSize}
        setPageSize={setPageSize}
        totalDataCount={totalDataCount}
        onChange={onChange}
        rowKey={(record: TKitManagementWorkshopUI) => record.orderId}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        currentPage={currentPage}
        pageHeaderContent={alertHeight}
        storagePath={storagePath}
      />
    </>
  );
};

export default WorkshopBody;
