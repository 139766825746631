export enum EWorkshopsUrls {
  WORKSHOPS = 'workshops',
  CLASS = 'class',
  AGE_GROUP = 'age-group',
  CAPACITY = 'capacity',
  LANGUAGE = 'language',
  DATE_TIME = 'date-time',
  FACILITATOR = 'facilitator',
  CITIES = 'cities',
  LOCATION = 'location',
  HOSTS = 'hosts',
  PLANNED_DATE = 'planned-date',
  HOSTED_PRIVATELY = 'hosted-privately',
  SHIPMENT_ADDRESS = 'shipment-address',
  SITE_COORDINATOR = 'site-coordinator',
  FOOD_PROVIDED = 'food-provided',
  CHILDCARE_PROVIDED = 'childcare-provided',
  IDENTITY = 'identity',
  PHOTO = 'photo',
  GALLERY = 'gallery',
  PUBLISH = 'publish',
  REPUBLISH = 'republish',
  RESCHEDULE = 'reschedule',
  CANCEL = 'cancel',
  SESSION = 'session',
  SESSIONS = 'sessions',
  DESCRIPTION = 'description',
  URL = 'url',
  HOST = 'host',
  DUPLICATE = 'duplicate',
  MEETING_LINK = 'meeting-link',
  LOCATIONS = 'locations',
  CHILDCARE_COUNT = 'childcare-count',
  CHILDCARE = 'childcare',
  CUSTOM_QUESTION = 'custom-question',
  CLOSE = 'close',
  REGISTRANTS = 'registrants',
  PART = 'part',
}

export const editMeetingLinkUrl = `${EWorkshopsUrls.WORKSHOPS}/${EWorkshopsUrls.MEETING_LINK}`;
export const workshopLocationUrl = `${EWorkshopsUrls.WORKSHOPS}/${EWorkshopsUrls.LOCATIONS}`;
export const editSessionDateTimeUrl = `${EWorkshopsUrls.WORKSHOPS}/${EWorkshopsUrls.SESSION}/${EWorkshopsUrls.DATE_TIME}`;

export const editPartDateTimeUrl = `${EWorkshopsUrls.WORKSHOPS}/${EWorkshopsUrls.PART}/${EWorkshopsUrls.DATE_TIME}`;

export const workshopCustomQuestionUrl = `${EWorkshopsUrls.WORKSHOPS}/${EWorkshopsUrls.LOCATIONS}`;
