import { FC, useEffect, useState } from 'react';
import { useTranslation } from '@packages/utils';
import { EIconNames } from 'components/Base/Icon';
import { TCheckboxChangeEvent } from 'components/Base/Checkbox/Checbox.types';
import Checkbox from 'components/Base/Checkbox';
import { Row, Col } from 'components/Base/Grid';
import { EditableAddress } from 'components/Base/EditableFields/EditableAddress';
import { InplaceEditLabels } from 'components/Base/EditableFields/components/InplaceEditLabel';
import {
  useShowToastNotification,
  ENotificationType,
} from 'components/Base/Notification';
import { useEditShippingAddressMutation } from 'redux/apiSlice/workshops.slice';
import { TRtkErrorType } from 'data/types/generic.types';
import { TEditableAddressOnEdit } from 'components/Base/EditableFields/EditableAddress/EditableAddress.types';
import { TShippingAddressProps } from './ShippingAddress.types';

const ShippingAddress: FC<TShippingAddressProps> = ({
  shippingAddress,
  sameAsLocation,
  locationAddressId,
  id,
  isFetchingWorkshopData,
}) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.workshops.details.basic-info.shipping-address',
  });
  const { showToastNotification } = useShowToastNotification();
  const [checkboxValue, setCheckboxValue] = useState<boolean>();
  const [editShipmetAddress, { isLoading, isError }] =
    useEditShippingAddressMutation();

  useEffect(() => {
    if (!isFetchingWorkshopData && sameAsLocation !== undefined) {
      setCheckboxValue(sameAsLocation);
    }
  }, [isFetchingWorkshopData]);

  const onEdit: TEditableAddressOnEdit = async value => {
    if (value === null) {
      return;
    }
    try {
      await editShipmetAddress({
        id,
        ...value,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (e) {
      showToastNotification({
        message: (e as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };
  const onCheckboxChange = async (e: TCheckboxChangeEvent) => {
    const value = e.target.checked;
    setCheckboxValue(value);
    if (
      value &&
      locationAddressId &&
      shippingAddress?.id &&
      locationAddressId !== shippingAddress?.id
    ) {
      await onEdit({
        placeId: locationAddressId,
      });
    }
  };
  return (
    <Row align="middle" gutter={[12, 12]}>
      <Col>
        <Row align="middle">
          <InplaceEditLabels
            label={t('LABEL')}
            icon={EIconNames.SHIPPING_ADDRESS}
          />
          <EditableAddress
            required
            placeholder={t('PLACEHOLDER')}
            title={shippingAddress?.formattedAddress}
            onEdit={onEdit}
            isLoading={isLoading}
            isError={isError}
            readonly={
              shippingAddress?.allowedOperations.readonly || checkboxValue
            }
            canRemove={shippingAddress?.allowedOperations.canRemove}
            minWidth={250}
          />
        </Row>
      </Col>
      <Col>
        <Checkbox
          onChange={onCheckboxChange}
          checked={checkboxValue}
          disabled={isLoading || shippingAddress?.allowedOperations.readonly}
          $textNoWrap
          $readonly={shippingAddress?.allowedOperations.readonly}
        >
          {t('SAME_AS_LOCATION')}
        </Checkbox>
      </Col>
    </Row>
  );
};

export default ShippingAddress;
