import { FC } from 'react';
import { useTheme } from 'styled-components';
import EditableInput from 'components/Base/EditableFields/EditableInput';
import { InplaceEditLabels } from 'components/Base/EditableFields/components/InplaceEditLabel';
import { Icon, EIconNames } from 'components/Base/Icon';
import { useUpdateOrganizationEmailMutation } from 'redux/apiSlice/organizations.slice';
import {
  useShowToastNotification,
  ENotificationType,
} from 'components/Base/Notification';
import useCopyToClipboard from 'hooks/useCopyClipboard';
import { TRtkErrorType } from 'data/types/generic.types';
import useGetValidationData from 'components/Base/Form/validationRules';
import { Col, Row, useTranslation } from '@packages/utils';
import { TUpdateOrganizationEmailProps } from './UpdateOrganizationEmail.types';

const UpdateOrganizationEmail: FC<TUpdateOrganizationEmailProps> = ({
  id,
  email,
}) => {
  const theme = useTheme();
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.organizations.details.details-tab.main-contact.email',
  });
  const [isCopied, copyToClipboard] = useCopyToClipboard();
  const { showToastNotification } = useShowToastNotification();
  const { validationRules } = useGetValidationData();

  const [updateEmail, { isLoading, isError }] =
    useUpdateOrganizationEmailMutation();

  const handleCopyClick = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    copyToClipboard(email?.value || '');
  };

  const onUpdateEmail = async (value: string) => {
    try {
      await updateEmail({
        id,
        value,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (e) {
      showToastNotification({
        message: (e as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };
  return (
    <Row align="middle" wrap={false} className="w-100">
      <Col>
        <InplaceEditLabels
          label={t('LABEL')}
          icon={EIconNames.EMAIL_OUTLINED}
        />
      </Col>
      <Col flex="auto">
        <EditableInput
          onEdit={onUpdateEmail}
          title={email?.value}
          isError={isError}
          isLoading={isLoading}
          fitContent={false}
          rules={[validationRules.email()]}
          validateTrigger="onSubmit"
          readonly={email?.allowedOperations.readonly}
          canRemove={email?.allowedOperations.canRemove}
          emptyText={t('EMPTY_TEXT')}
          placeholder={t('PLACEHOLDER')}
          backIcon
        />
      </Col>
      {email?.value && (
        <Col onClick={handleCopyClick}>
          <Icon
            icon={isCopied ? EIconNames.CHECK : EIconNames.COPY_OUTLINED}
            color={theme.colorBlackIconColor}
            className="pointer"
            size={16}
          />
        </Col>
      )}
    </Row>
  );
};

export default UpdateOrganizationEmail;
