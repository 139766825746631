import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { RadioChangeEvent } from 'antd';
import {
  Button,
  ENotificationType,
  Form,
  FormItem,
  Input,
  Space,
  TRtkErrorType,
  useShowToastNotification,
  useTranslation,
  Col,
  Row,
} from '@packages/utils';
import { useUploadResourcesMutation } from 'redux/apiSlice/elcs.slice';
import { Radio, RadioGroup } from 'components/Base/RadioGroup';
import { UploadFile, UploadProps } from 'antd/es/upload';
import { EIconNames, Icon } from 'components/Base/Icon';
import Spinner from 'components/Base/Spinner';
import {
  TAddResourcesFormProps,
  TResourceTypeProps,
} from './AddResourcesForm.types';
import useGetFormData from './AddResourcesForm.data';
import { SUplaoder, SUploadedItemWrapper } from './AddResourcesForm.styles';

const AddResourcesForm = ({
  form,
  elcId,
  setSelectedFiles,
  selectedFiles,
  setFileIsUploading,
  handleRemove,
}: TAddResourcesFormProps) => {
  const theme = useTheme();
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.resources-tab.add-resource.modal.form.file',
  });
  const [uploadingFiles, setUploadingFiles] = useState<string[]>([]);
  const [filesList, setFilesList] = useState<UploadFile<any>[]>([]);
  const { showToastNotification } = useShowToastNotification();
  const [selectedType, setSelectedType] =
    useState<TResourceTypeProps>('DOCUMENT');
  const { resourceTypeOptions, addResourcesFormFields, documentTypeOptions } =
    useGetFormData();
  const { resourceType, name, url, documentType, fileUpload } =
    addResourcesFormFields;
  const [uploadResource, { isLoading }] = useUploadResourcesMutation();

  useEffect(() => {
    setFileIsUploading(isLoading);
  }, [isLoading]);

  const handleUpload: UploadProps['onChange'] = async info => {
    const { file } = info;
    try {
      // Manage uploading state
      if (file.status === 'uploading' && !uploadingFiles.includes(file.uid)) {
        setUploadingFiles([...uploadingFiles, file.uid]);
      }
      if (file.status === 'done') {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as Blob);
        reader.onload = async () => {
          const data = await uploadResource({
            file: file.originFileObj as Blob,
            elcId,
          }).unwrap();
          if (data) {
            setUploadingFiles([]);
          }
          if (file.originFileObj?.uid) {
            setSelectedFiles([
              ...selectedFiles,
              { ...data, uid: file.originFileObj.uid },
            ]);
          }
        };
      }
    } catch (err) {
      showToastNotification({
        message: (err as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  const onRemove: UploadProps['onRemove'] = file => {
    const updatedFiles = selectedFiles.filter(item => item.uid !== file.uid);
    const removingFile = selectedFiles.filter(item => item.uid === file.uid);
    setSelectedFiles(updatedFiles);
    const index = filesList.indexOf(file);
    const newFileList = filesList.slice();
    newFileList.splice(index, 1);
    setFilesList(newFileList);
    if (removingFile) {
      handleRemove(removingFile);
    }
  };

  const onRadioChange = (e: RadioChangeEvent) => {
    setSelectedType(e.target.value);
    form.resetFields();
    form.setFieldValue('resourceType', e.target.value);
    if (selectedFiles.length) {
      handleRemove(selectedFiles);
      setFilesList([]);
    }
  };

  const customItemRender: UploadProps['itemRender'] = (originNode, file) => {
    return (
      <SUploadedItemWrapper className="custom-file-icon my-2">
        <Row align="middle" wrap={false}>
          <Col className="mr-1" md={2}>
            {uploadingFiles.includes(file.uid) ? (
              <Spinner spinnerSize={24} color={theme.colorBlackIconColor} />
            ) : (
              <Icon icon={EIconNames.FILE} color={theme.colorBlackIconColor} />
            )}
          </Col>
          <Col md={20}>
            <span>{file.name}</span>
          </Col>
          <Col md={2}>
            <Button
              type="text"
              disabled={isLoading}
              color="primary"
              icon={
                <Icon
                  icon={EIconNames.DELETE_OUTLINED}
                  color={theme.colorBlackIconColor}
                  className="pointer"
                  size={24}
                  tooltip={{ title: t('DELETE_TOOLTIP') }}
                />
              }
              onClick={() => onRemove(file)}
            />
          </Col>
        </Row>
      </SUploadedItemWrapper>
    );
  };

  const renderFormItems = (type: TResourceTypeProps) => {
    const formItems: {
      [key in TResourceTypeProps]: JSX.Element;
    } = {
      DOCUMENT: (
        <>
          <FormItem {...documentType}>
            <RadioGroup>
              <Space direction="vertical">
                {documentTypeOptions.map(option => (
                  <Radio key={option.value} value={option.value}>
                    {option.label}
                  </Radio>
                ))}
              </Space>
            </RadioGroup>
          </FormItem>
          <FormItem name={fileUpload.name} rules={fileUpload.rules}>
            <SUplaoder
              disabled={isLoading}
              onChange={handleUpload}
              listType="picture"
              className="avatar-uploader"
              itemRender={customItemRender}
              fileList={filesList}
              customRequest={({ onSuccess }) => {
                if (onSuccess) {
                  // todo investigate if this function is removed onChange call is not working
                  // or you should pass action prop
                  onSuccess('ok');
                }
              }}
              beforeUpload={file => {
                const isLt10MB = file.size / 1024 / 1024 < 10;
                if (!isLt10MB) {
                  showToastNotification({
                    message: t('SIZE_VALIDATION_ERROR'),
                    type: ENotificationType.SUCCESS,
                  });
                } else {
                  setFilesList([...filesList, file]);
                }
                return isLt10MB;
              }}
            >
              <Button
                color="primary"
                icon={<Icon icon={EIconNames.PLUS} className="pointer" />}
              >
                {fileUpload.label}
              </Button>
            </SUplaoder>
          </FormItem>
        </>
      ),
      LINK: (
        <>
          <FormItem {...name}>
            <Input placeholder={name.placeholder} />
          </FormItem>
          <FormItem {...url}>
            <Input placeholder={url.placeholder} type="url" />
          </FormItem>
        </>
      ),
    };

    return formItems[type] || null;
  };

  return (
    <Form form={form}>
      <FormItem {...resourceType} initialValue="DOCUMENT">
        <RadioGroup
          onChange={onRadioChange}
          optionType="button"
          buttonStyle="solid"
          fullWidth
          options={resourceTypeOptions}
        />
      </FormItem>
      {renderFormItems(selectedType)}
    </Form>
  );
};

export default AddResourcesForm;
