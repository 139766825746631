import {
  Col,
  ENotificationType,
  Row,
  TRtkErrorType,
  useShowToastNotification,
  useTranslation,
  filterTitleOption,
} from '@packages/utils';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { EInvitationStatus } from 'data/types/elcs.types';
import EditableSelect from 'components/Base/EditableFields/EditableSelect';
import { InplaceEditLabels } from 'components/Base/EditableFields/components/InplaceEditLabel';
import { useEditFirstPMMutation } from 'redux/apiSlice/elcs.slice';
import { userGrowApi } from 'redux/apiSlice/userGrow.slice';
import { EIconNames } from 'components/Base/Icon';
import PendingIconTooltip from 'pages/private/ELCs/components/PendingIconTooltip';
import ElcItemPopover from 'pages/private/ELCs/components/ElcPopover';
import useUserOptions from 'hooks/useUserOptions';
import { TFirstPMProps } from './FirstPM.types';
import { avatarColorMapper } from '../../../../ELCs.data';
import ConfirmModalBody from '../ConfirmModalBody';

const FirstPM = ({ data, elcId, isEditAllowed }: TFirstPMProps) => {
  const dispatch = useAppDispatch();
  const usersData = useAppSelector(state => state.userSlice);
  const { user } = usersData;
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.elcs.list.elc-item.pm',
  });
  const { showToastNotification } = useShowToastNotification();

  const pmOptions = useUserOptions();

  const [
    editFirstPM,
    { isLoading: firstPMEditIsLoading, isError: firstPMEditIsError },
  ] = useEditFirstPMMutation();

  const onFirstPMEdit = async (value: string) => {
    try {
      await editFirstPM({
        elcId,
        userId: value,
      }).unwrap();
      if (value === user.userId || data?.userId === user.userId) {
        dispatch(userGrowApi.util.invalidateTags(['user']));
      }
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (e) {
      showToastNotification({
        message: (e as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };
  return (
    <Row align="middle" wrap={false}>
      <Col flex="auto">
        <Row align="middle" wrap={false}>
          <Col>
            <InplaceEditLabels label={t('LABEL')} icon={EIconNames.PROFILE} />
          </Col>
          <Col flex="auto">
            <EditableSelect
              {...(data && {
                title: {
                  label: data.fullName,
                  value: data.userId,
                },
              })}
              readonly={!isEditAllowed}
              onEdit={onFirstPMEdit}
              options={pmOptions}
              emptyText={t('EMPTY_TEXT')}
              placeholder={t('PLACEHOLDER')}
              {...(data && {
                tooltip: (
                  <ElcItemPopover
                    fullName={data.fullName}
                    email={data.email}
                    phone={data.phone}
                  />
                ),
              })}
              fitContent={false}
              canRemove={data?.allowedUpdateWithNull}
              avatarColor={
                data?.invitationStatus === EInvitationStatus.PENDING
                  ? 'grey'
                  : avatarColorMapper.firstPM
              }
              selectProps={{
                filterOption: filterTitleOption,
                showSearch: true,
              }}
              isError={firstPMEditIsError}
              isLoading={firstPMEditIsLoading}
              {...(data && {
                modalBody: <ConfirmModalBody />,
              })}
            />
          </Col>
        </Row>
      </Col>
      {data?.invitationStatus === EInvitationStatus.PENDING && (
        <PendingIconTooltip />
      )}
    </Row>
  );
};

export default FirstPM;
