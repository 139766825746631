import React, { FC } from 'react';
import { ENotificationType } from '@idaho-aeyc/ui-kit';
import { Modal } from 'components/Base/Modal';
import { useTranslation } from '@packages/utils';
import { useShowToastNotification } from 'components/Base/Notification';
import useForm from 'hooks/useForm';
import { useUpdateCustomQuestionMutation } from 'redux/apiSlice/workshops.slice';
import { TRtkErrorType } from 'data/types/generic.types';
import QuestionAddForm from '../QuestionAddForm';
import { TRANSLATION_KEY } from '../../QuestionTab.data';
import {
  TAddQuestionModalProps,
  TUpdateQuestionFormData,
} from '../../QuestionTab.types';

const AddQuestionModal: FC<TAddQuestionModalProps> = ({
  open,
  setModalOpen,
  workshopId,
}) => {
  const [updateQuestion, { isLoading }] = useUpdateCustomQuestionMutation();

  const { form } = useForm();
  const { showToastNotification } = useShowToastNotification();

  const { t } = useTranslation({
    keyPrefix: `${TRANSLATION_KEY}.modals.add-modal`,
  });
  const closeModal = () => {
    setModalOpen(false);
  };
  const handleAddBtnClick = async () => {
    try {
      const formValues =
        (await form.validateFields()) as TUpdateQuestionFormData;
      await updateQuestion({
        question: formValues.question,
        id: workshopId,
      }).unwrap();
      showToastNotification({
        message: t('success-message'),
        type: ENotificationType.SUCCESS,
      });
    } catch (err) {
      if ((err as TRtkErrorType)?.data?.message)
        showToastNotification({
          message: (err as TRtkErrorType)?.data?.message,
          type: ENotificationType.ERROR,
        });
    }
  };
  return (
    <Modal
      destroyOnClose
      title={t('TITLE')}
      open={open}
      onCancel={closeModal}
      okText={t('submit-btn')}
      onOk={handleAddBtnClick}
      confirmLoading={isLoading}
    >
      <QuestionAddForm form={form} />
    </Modal>
  );
};

export default AddQuestionModal;
