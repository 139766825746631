import { ColumnFilterItem } from 'antd/es/table/interface';
import { ERegistrantsColumnKey } from 'data/types/registrants.types';
import { TClassItemUI } from 'data/types/workshops.types';
import { useTranslation } from '@packages/utils';

const useAgeGroupLanguageFilter = (
  classes?: TClassItemUI[],
): ColumnFilterItem[] | null => {
  const tableKey = 'descriptions.generic-components.table';
  const { t } = useTranslation();
  if (!classes || !classes.length) {
    return null;
  }

  const ageGroupFilterOptions: ColumnFilterItem['children'] = [];
  const languageFilterOprions: ColumnFilterItem['children'] = [];

  classes.forEach(item => {
    const ageGroupValue = item.ageGroup.value.value;
    const languageValue = item.language.value.value;

    if (
      ageGroupValue &&
      !ageGroupFilterOptions.find(
        ageGroupOption => ageGroupOption.value === ageGroupValue,
      )
    ) {
      ageGroupFilterOptions.push({
        text: t(`enums.age-group.${ageGroupValue}`),
        value: ageGroupValue,
      });
    }

    if (
      languageValue &&
      !languageFilterOprions.find(
        langugeOption => langugeOption.value === languageValue,
      )
    ) {
      languageFilterOprions.push({
        text: t(`enums.language.${languageValue}`),
        value: languageValue,
      });
    }
  });

  return [
    {
      text: t(`${tableKey}.grouped-filters.FILTER_BY_AGE_GROUP`),
      value: ERegistrantsColumnKey.AGE_GROUP,
      children: ageGroupFilterOptions,
    },
    {
      text: t(`${tableKey}.grouped-filters.FILTER_BY_LANGUAGE`),
      value: ERegistrantsColumnKey.LANGUAGE,
      children: languageFilterOprions,
    },
  ];
};

export default useAgeGroupLanguageFilter;
