import { FC } from 'react';
import { useTheme } from 'styled-components';
import { Row, Col } from 'components/Base/Grid';
import { Icon, EIconNames } from 'components/Base/Icon';
import { Divider } from 'components/Base/Divider';
import { Text } from 'components/Base/Typography';
import { TLocationProps } from './Location.types';

const Location: FC<TLocationProps> = ({ title }) => {
  const theme = useTheme();
  return (
    <Row wrap={false} align="middle" className="mb-4">
      <Col>
        <Row wrap={false} align="middle">
          <Col>
            <Icon
              icon={EIconNames.LOCATION}
              size={56}
              color={theme.colorGreenFlyer}
              alt="location icon"
            />
          </Col>
          <Col>
            <Divider
              className="mx-4"
              orientation="center"
              height={title?.length > 16 ? 100 : 60}
              type="vertical"
              color={theme.colorGreenFlyer}
              thickness={5}
            />
          </Col>
        </Row>
      </Col>

      <Col>
        <Text
          fontSize={theme.fontSizeHeading1}
          fontWeight={theme.fontWeightBold}
          color={theme.colorGreenFlyer}
        >
          {title}
        </Text>
      </Col>
    </Row>
  );
};

export default Location;
