import { TColumnFilterItem } from 'components/Base/Table/Table.types';
import {
  citiesFiltersConverter,
  citiesOptionsConverter,
  pagableDataConverter,
} from 'data/convertors/general.converters';
import {
  workshopSubscriberConverter,
  workshopSubscriberCreateConverter,
  workshopSubscriberFilterConverter,
  workshopSubscriberUnsubscribeConverter,
} from 'data/convertors/subscribers.convertors';
import {
  TOption,
  TPageableDataWithContentDB,
  TPageableDataWithContentUI,
  TTableFilters,
} from 'data/types/generic.types';
import {
  EWorkshopSubscribersColumnKey,
  TWorkshopSubscriberCreateUI,
  TWorkshopSubscriberDataDB,
  TWorkshopSubscriberDataUI,
  TWorkshopSubscriberUnsubscribeUI,
} from 'data/types/subscribers.types.';
import { ESubscribersUrls } from 'data/urls/subscribers.url';
import { authSplitApi } from 'redux/helpers/slice.helpers';

const baseUrl = `${ESubscribersUrls.BASE_URL}`;

export const subscibersApi = authSplitApi('subscibers', [
  'workshop-subscribers',
  'workshop-subscribers-cities',
]).injectEndpoints({
  endpoints: build => ({
    createWorkshopSubscriber: build.mutation<
      string,
      TWorkshopSubscriberCreateUI
    >({
      query(body) {
        return {
          url: `${baseUrl}`,
          method: 'POST',
          body: workshopSubscriberCreateConverter.toDb(body),
        };
      },
      invalidatesTags: ['workshop-subscribers', 'workshop-subscribers-cities'],
    }),
    getWorkshopSubscribers: build.query<
      TPageableDataWithContentUI<TWorkshopSubscriberDataUI[]>,
      TTableFilters<EWorkshopSubscribersColumnKey>
    >({
      query({
        page,
        pageSize,
        sortColumn: { sortDirection, sortField },
        filters,
      }) {
        return {
          url: `${baseUrl}`,
          method: 'GET',
          params: {
            page,
            size: pageSize,
            ...(sortField && sortDirection && { sortField, sortDirection }),
            ...(filters && { ...workshopSubscriberFilterConverter(filters) }),
          },
        };
      },
      providesTags: ['workshop-subscribers'],
      transformResponse: (
        data: TPageableDataWithContentDB<TWorkshopSubscriberDataDB[]>,
      ) => {
        return {
          ...pagableDataConverter.fromDb(data),
          content: (data.content || []).map(workshop =>
            workshopSubscriberConverter.fromDb(workshop),
          ),
        };
      },
    }),
    getWorkshopSubscribersCities: build.query<
      TOption[] | TColumnFilterItem[] | string[],
      'option' | 'filter' | undefined
    >({
      query() {
        return {
          url: `${baseUrl}/${ESubscribersUrls.CITIES}`,
          method: 'GET',
        };
      },
      providesTags: ['workshop-subscribers-cities'],
      transformResponse(data: string[], meta, arg) {
        switch (arg) {
          case 'option':
            return citiesOptionsConverter.fromDb(data);
          case 'filter':
            return citiesFiltersConverter.fromDb(data);
          default:
            return data;
        }
      },
    }),
    unsubscribeAction: build.mutation<void, TWorkshopSubscriberUnsubscribeUI>({
      query(body) {
        return {
          url: `${baseUrl}`,
          method: 'DELETE',
          body: workshopSubscriberUnsubscribeConverter.toDb(body),
        };
      },
      invalidatesTags: ['workshop-subscribers', 'workshop-subscribers-cities'],
    }),
  }),
});

export const {
  useGetWorkshopSubscribersQuery,
  useGetWorkshopSubscribersCitiesQuery,
  useCreateWorkshopSubscriberMutation,
  useUnsubscribeActionMutation,
} = subscibersApi;
