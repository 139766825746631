import { Row, Col, Tag, useTranslation } from '@packages/utils';
import { TDetailsListProps } from './DetailsList.data';
import { SDetailsListItemWrapper, SNotesHeader } from './DetailsList.styles';
import DeleteDetailAction from '../actions/DeleteDetailAction';
import EditDetailAction from '../actions/EditDetailAction';

const DetailsList = ({ data, elcId, isEditable }: TDetailsListProps) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.details-tab.details-section.notes-categories',
  });
  return (
    <Row gutter={[16, 16]}>
      {data.map(item => (
        <Col key={item.id} span={24}>
          <SDetailsListItemWrapper>
            <SNotesHeader justify="space-between">
              <Col>
                <Tag color="blue">{t(item.category)}</Tag>
              </Col>
              <Col className="actions-section">
                <DeleteDetailAction
                  elcNoteId={item.id}
                  isEditable={isEditable}
                  elcId={elcId}
                />
              </Col>
            </SNotesHeader>
            <EditDetailAction
              elcNoteId={item.id}
              isEditable={isEditable}
              elcId={elcId}
              note={item.note}
            />
          </SDetailsListItemWrapper>
        </Col>
      ))}
    </Row>
  );
};

export default DetailsList;
