import { Row, useTranslation } from '@packages/utils';
import EditableSelect from 'components/Base/EditableFields/EditableSelect';
import { InplaceEditLabels } from 'components/Base/EditableFields/components/InplaceEditLabel';
import { EIconNames } from 'components/Base/Icon';
import {
  useShowToastNotification,
  ENotificationType,
} from 'components/Base/Notification';
import { useEditAgeGroupMutation } from 'redux/apiSlice/workshops.slice';
import { TRtkErrorType } from 'data/types/generic.types';
import { TAgeGroupSelectProps } from './AgeGroupSelect.types';

const AgeGroupSelect = ({
  possibleAgeGroup,
  id,
  workshopId,
  age,
}: TAgeGroupSelectProps) => {
  const { t } = useTranslation();
  const { showToastNotification } = useShowToastNotification();
  const [editAgeGroup, { isLoading, isError }] = useEditAgeGroupMutation();
  const ageGroupsOptions = possibleAgeGroup?.map(status => ({
    label: t(`enums.age-group.${status}`),
    value: status,
  }));

  const onEdit = async (value: string) => {
    try {
      await editAgeGroup({
        id,
        workshopId,
        value,
      }).unwrap();
      showToastNotification({
        message: t(
          'descriptions.admin-portal.workshops.details.sessions.view.actions.update.age-group.SUCCESS_MESSAGE',
        ),
        type: ENotificationType.SUCCESS,
      });
    } catch (e) {
      showToastNotification({
        message: (e as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };
  return (
    <Row align="middle">
      <InplaceEditLabels
        label={t(
          'descriptions.admin-portal.workshops.details.sessions.view.actions.update.age-group.LABEL',
        )}
        icon={EIconNames.AGE_GROUP}
      />
      <EditableSelect
        onEdit={onEdit}
        options={ageGroupsOptions}
        title={{
          label: t(`enums.age-group.${age.value.label}`),
          value: age.value.value,
        }}
        emptyText={t(
          'descriptions.admin-portal.workshops.details.sessions.view.actions.update.age-group.EMPTY_TEXT',
        )}
        isLoading={isLoading}
        isError={isError}
        readonly={age.allowedOperations.readonly}
        canRemove={age.allowedOperations.canRemove}
      />
    </Row>
  );
};

export default AgeGroupSelect;
