import { useEffect, useState } from 'react';
import { useTranslation, Card, Row, Col } from '@packages/utils';
import { Form, FormItem, FormList } from 'components/Base/Form';
import { Select, SelectWithAdd } from 'components/Base/Select';
import { Input } from 'components/Base/Input';
import CreateCoordinatorModal from 'components/Shared/Coordinator/CreateCoordinatorModal';
import { TCreateCoordinatorModalOpen } from 'components/Shared/Coordinator/CreateCoordinatorModal/CreateCoordinatorModal.types';
import { useGetPeopleIdentityQuery } from 'redux/apiSlice/people.slice';
import useGetData from '../../CreateSessionCard.data';
import { TCreateSessionFormProps } from '../../CreateSessionCard.types';

const StandaloneForm = ({
  form,
  possibleAgeGroup,
  formValues,
}: TCreateSessionFormProps) => {
  const { t } = useTranslation();
  const { languageOptions, sessionFormFields } = useGetData();
  const [open, setOpen] = useState<TCreateCoordinatorModalOpen>({
    initialName: '',
    visible: false,
  });
  const [coordinatorId, setCoordinatorId] = useState<string>('');

  const { age, language, capacity, facilitator } = sessionFormFields;
  const { data: peopleIdentityOptions, isLoading } =
    useGetPeopleIdentityQuery();

  const ageGroupsOptions = possibleAgeGroup?.map(item => ({
    label: t(`enums.age-group.${item}`),
    value: item,
  }));

  useEffect(() => {
    if (!coordinatorId && !peopleIdentityOptions) {
      return;
    }
    const facilitatorSelected = peopleIdentityOptions?.find(
      item => item.value === coordinatorId,
    );
    const fields = form.getFieldValue('sessions');
    fields[0][facilitator.name] = facilitatorSelected?.value;
    form.setFieldsValue({ list: fields });
  }, [peopleIdentityOptions, coordinatorId]);

  useEffect(() => {
    if (!formValues) {
      return;
    }
    form.setFieldsValue(formValues);
  }, [formValues]);

  return (
    <>
      <Form
        form={form}
        initialValues={{
          age:
            ageGroupsOptions?.length === 1 ? ageGroupsOptions[0].value : null,
          language: null,
          capacity: '',
        }}
      >
        <Card>
          <Row>
            <Col span={24}>
              <Row gutter={[16, 16]}>
                <Col sm={8} xs={24}>
                  <FormItem {...age}>
                    <Select
                      placeholder={age.placeholder}
                      options={ageGroupsOptions}
                    />
                  </FormItem>
                </Col>
                <Col sm={8} xs={24}>
                  <FormItem {...language}>
                    <Select
                      placeholder={language.placeholder}
                      options={languageOptions}
                    />
                  </FormItem>
                </Col>
                <Col sm={8} xs={24}>
                  <FormItem {...capacity}>
                    <Input placeholder={capacity.placeholder} />
                  </FormItem>
                </Col>
              </Row>
              <FormList
                name="sessions"
                initialValue={[
                  {
                    facilitator: null,
                  },
                ]}
              >
                {fields => (
                  <>
                    {fields.map(field => (
                      <Row gutter={[16, 16]} key={field.key}>
                        <Col sm={8} xs={24}>
                          <FormItem
                            name={[field.name, facilitator.name]}
                            label={facilitator.label}
                          >
                            <SelectWithAdd
                              placeholder={facilitator.placeholder}
                              loading={isLoading}
                              options={peopleIdentityOptions}
                              onBtnClick={value =>
                                setOpen({
                                  initialName: value || '',
                                  visible: true,
                                })
                              }
                              addButtonLabel={t(
                                'descriptions.admin-portal.workshops.details.sessions.create-form.facilitator.ADD_BTN',
                              )}
                              enableAddButton
                              allowClear
                            />
                          </FormItem>
                        </Col>
                      </Row>
                    ))}
                  </>
                )}
              </FormList>
            </Col>
          </Row>
        </Card>
      </Form>
      <CreateCoordinatorModal
        open={open}
        setOpen={setOpen}
        label={t(
          'descriptions.admin-portal.workshops.details.sessions.create-form.facilitator.LABEL',
        )}
        setCoordinatorId={setCoordinatorId}
      />
    </>
  );
};

export default StandaloneForm;
