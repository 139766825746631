import { useState } from 'react';
import { get } from 'lodash';
import {
  Button,
  ENotificationType,
  Modal,
  useForm,
  useShowToastNotification,
  useTranslation,
} from '@packages/utils';
import { TValidateFieldsError } from 'data/types/generic.types';
import { EIconNames, Icon } from 'components/Base/Icon';
import { useAddMeetingMutation } from 'redux/apiSlice/elcs.slice';
import { TAddMeetingFormProps, TAddMeetingProps } from './AddMeeting.types';
import AddEditMeetingForm from '../AddEditMeetingForm';

const AddMeeting = ({ elcId }: TAddMeetingProps) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.meetings-tab.list.actions.add',
  });
  const { showToastNotification } = useShowToastNotification();
  const { form } = useForm<TAddMeetingFormProps>();
  const [open, setOpen] = useState<boolean>(false);
  const [addMeeting, { isLoading }] = useAddMeetingMutation();

  const handleClose = () => {
    setOpen(false);
    form.resetFields();
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      await addMeeting({
        elcId,
        ...values,
      }).unwrap();
      showToastNotification({
        message: t('modal.SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
      handleClose();
    } catch (err) {
      if (!(err as TValidateFieldsError)?.errorFields) {
        const message = get(err, 'data.message');
        showToastNotification({
          message,
          type: ENotificationType.ERROR,
        });
      }
    }
  };

  return (
    <>
      <Button
        color="primary"
        type="primary"
        onClick={() => setOpen(true)}
        icon={<Icon icon={EIconNames.PLUS} className="pointer" />}
      >
        {t('ADD_BTN')}
      </Button>
      <Modal
        title={t('modal.TITLE')}
        open={open}
        onCancel={handleClose}
        onOk={handleSubmit}
        centeredPosition
        okText={t('modal.SUBMIT_BTN')}
        cancelText={t('modal.CANCEL_BTN')}
        confirmLoading={isLoading}
      >
        <AddEditMeetingForm form={form} />
      </Modal>
    </>
  );
};

export default AddMeeting;
