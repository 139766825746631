import { useState } from 'react';
import { Card, Col, Row, getFileViewerUrl } from '@packages/utils';
import { useTheme } from 'styled-components';
import { EIconNames, Icon } from 'components/Base/Icon';
import Spinner from 'components/Base/Spinner';
import { TResourceItemProps } from './ResourceItem.types';
import RemoveResource from '../RemoveResource';

const ResourceItem = ({
  data,
  elcId,
  isResourceEditable,
}: TResourceItemProps) => {
  const [removeIsLoading, setRemoveIsLoading] = useState<boolean>(false);
  const theme = useTheme();
  const { resourceId, file } = data;

  return (
    <Col md={8}>
      <Card>
        <Row align="middle" wrap={false}>
          <Col className="mr-1" md={2}>
            {removeIsLoading ? (
              <Spinner spinnerSize={24} color={theme.colorBlackIconColor} />
            ) : (
              <Icon icon={EIconNames.FILE} color={theme.colorBlackIconColor} />
            )}
          </Col>
          <Col md={20}>
            <a
              target="_blank"
              className="ellipsis"
              style={{ color: theme.colorBlackDescription }}
              href={getFileViewerUrl(file.url)}
              rel="noreferrer"
            >
              {file.name}
            </a>
          </Col>
          {isResourceEditable && (
            <RemoveResource
              elcId={elcId}
              resourceId={resourceId}
              className="ml-auto"
              iconName={EIconNames.DELETE_OUTLINED}
              setRemoveIsLoading={setRemoveIsLoading}
            />
          )}
        </Row>
      </Card>
    </Col>
  );
};

export default ResourceItem;
