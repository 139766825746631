import { AvatarBgColor } from 'components/Base/Avatar/Avatar.types';

export const avatarColorMapper: Record<
  'lead' | 'firstPM' | 'secondPM' | 'fiscalAgent',
  AvatarBgColor
> = {
  lead: 'blue',
  firstPM: 'green',
  secondPM: 'green',
  fiscalAgent: 'lightGreen',
};
