import { Paragraph, useTranslation } from '@packages/utils';
import { TColumnType } from 'components/Base/Table/Table.types';
import useGetTableColumnData from 'components/Base/Table/components/SearchFilter';
import Tags from 'components/Shared/Tags';
import {
  EContactsColumnKey,
  TContactsUI,
  TElcDetailUI,
} from 'data/types/elcs.types';
import { ETagType } from 'data/types/tags.types';
import { ETagsUrls } from 'data/urls/tags.url';
import { elcsApi } from 'redux/apiSlice/elcs.slice';
import { peopleApi } from 'redux/apiSlice/people.slice';
import RemoveContact from './ContactsActions/RemoveContact';

const useGetData = (
  elcId: TElcDetailUI['elcId'],
  isContactEditable?: boolean,
) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.contacts-tab.list.table.title',
  });
  const { getTableColumnSearchProps } = useGetTableColumnData();

  const defaultColumns: TColumnType<TContactsUI>[] = [
    {
      title: t('FIRST_NAME'),
      dataIndex: 'firstName',
      key: EContactsColumnKey.FIRST_NAME,
      sorter: true,
      ...getTableColumnSearchProps<TContactsUI>(),
      width: 150,
    },
    {
      title: t('LAST_NAME'),
      dataIndex: 'lastName',
      width: 150,
      sorter: true,
      key: EContactsColumnKey.LAST_NAME,
      ...getTableColumnSearchProps<TContactsUI>(),
    },
    {
      title: t('TITLE'),
      dataIndex: 'title',
      key: 'title',
      onCell: () => ({
        style: {
          maxWidth: '200px',
        },
      }),
      render: text => (
        <Paragraph
          ellipsis={{
            tooltip: text,
          }}
        >
          {text}
        </Paragraph>
      ),
    },
    {
      title: t('EMAIL'),
      dataIndex: 'email',
      key: 'email',
      ellipsis: true,
    },
    {
      title: t('MOBILE'),
      dataIndex: 'phone',
      key: 'phone',
      ellipsis: true,
    },
    {
      title: t('TAG'),
      dataIndex: 'tags',
      key: EContactsColumnKey.TAGS,
      filters: [],
      width: 50,
      onCell: () => ({
        className: 'custom-cell',
        onClick: e => {
          e.stopPropagation();
        },
      }),
      render: (text, record) => (
        <Tags
          selectedTags={record.tags || []}
          associationId={record.userId}
          url={ETagsUrls.PEOPLE}
          invalidateTagsConfig={[
            { invalidateTag: 'elcs-contacts', sliceName: elcsApi },
            { invalidateTag: 'peoples', sliceName: peopleApi },
          ]}
          tagType={ETagType.PEOPLE}
          isEditable={isContactEditable}
        />
      ),
    },
    ...(isContactEditable
      ? [
          {
            title: t('ACTIONS'),
            key: 'actions',
            dataIndex: 'actions',
            fixed: 'right',
            width: 120,
            onCell: () => ({
              style: { minWidth: '120px' },
              onClick: e => {
                e.stopPropagation();
              },
            }),
            render: (text, record: TContactsUI) => (
              <RemoveContact elcId={elcId} contactId={record.contactId} />
            ),
          } as TColumnType<TContactsUI>,
        ]
      : []),
  ];
  return { defaultColumns };
};

export default useGetData;
