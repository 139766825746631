import { FC, useState } from 'react';
import { useTranslation, Button } from '@packages/utils';
import { Col } from 'components/Base/Grid';
import MarkAsOrderedModal from './MarkAsOrderedModal';
import { TMarkAsOrderedActionProps } from './KitsMarkAsOrderedAction.types';

const KitsMarkAsOrderedAction: FC<TMarkAsOrderedActionProps> = ({
  setShowAlert,
  partOrderIds,
  selectedRows,
}) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.kit-management.list.actions.mark-as-ordered',
  });

  const [open, setOpen] = useState(false);

  const handleActionButtonClick = () => {
    if (selectedRows.length) {
      setOpen(true);
    } else {
      setShowAlert(true);
    }
  };

  return (
    <Col>
      <Button type="default" color="primary" onClick={handleActionButtonClick}>
        {t('MARK_AS_ORDERED_BTN')}
      </Button>
      <MarkAsOrderedModal
        open={open}
        setOpen={setOpen}
        partOrderIds={partOrderIds}
      />
    </Col>
  );
};

export default KitsMarkAsOrderedAction;
