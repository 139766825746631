import {
  Col,
  Image,
  Paragraph,
  Row,
  Title,
  useTranslation,
} from '@packages/utils';
import fileWithParagraphs from 'assets/images/fileWithParagraphs.svg';
import { TPeopleOrganizationsProps } from './PeopleOrganizations.types';
import PeopleOrganizationCard from './PeopleOrganizationCard';

export const PeopleOrganizations = ({
  userOrganizations,
  organizationId,
  organizationName,
}: TPeopleOrganizationsProps) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.organizations.details.details-tab.people',
  });

  return (
    <>
      <Row className="w-100 mb-3">
        <Title level={4}>{t('LABEL')}</Title>
      </Row>
      {userOrganizations.value?.length ? (
        <>
          {userOrganizations.value.map(item => (
            <PeopleOrganizationCard
              key={item.userId}
              organizationId={organizationId}
              data={item}
              organizationName={organizationName}
              allowedActions={userOrganizations.allowedOperations}
            />
          ))}
        </>
      ) : (
        <Row justify="center" className="text-center p-10">
          <Col span={24}>
            <Image src={fileWithParagraphs} height={140} alt="" />
          </Col>
          <Col span={24} className="mt-6">
            <Paragraph>{t('EMPTY_TEXT')}</Paragraph>
          </Col>
        </Row>
      )}
    </>
  );
};

export default PeopleOrganizations;
