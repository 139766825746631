import { Buffer } from 'buffer';

export const downloadFile = (file: Blob, fileName: string) => {
  const link = window.URL.createObjectURL(file);
  const anchorTag = document.createElement('a');
  anchorTag.setAttribute('href', link);
  anchorTag.setAttribute('download', fileName);
  anchorTag.style.display = 'none';
  document.body.appendChild(anchorTag);
  anchorTag.click();
  document.body.removeChild(anchorTag);
};

export const getImageFromUrl = (url: string) => {
  return fetch(url, {
    cache: 'no-cache',
  })
    .then(response => response.blob())
    .then(blob => {
      return URL.createObjectURL(blob);
    })
    .catch(() => {
      return null;
    });
};

// export const downloadFileData = (url: string, name: string) =>
//   fetch(url).then((response) => response.blob().then((blob) => downloadFile(blob, name)));
//
// export const filePreviewData = (fileUrl: string) =>
//   fetch(fileUrl)
//     .then((response) => response.text())
//     .then((text) => text);
//
// export const getArrayBufferFromUrl = async (url: string) => {
//   const response = await fetch(url);
//   return response.arrayBuffer();
// };

// export const convertDocToHtml = async (file: ArrayBuffer) => {
//   const result = await mammoth.convertToHtml({ arrayBuffer: file });
//   return result.value;
// };
//

export function b64toBlob(base64String: string, type: string) {
  const decodedString = Buffer.from(base64String, 'base64');
  return new Blob([decodedString], { type });
}
