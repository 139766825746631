import { useState } from 'react';
import { useTranslation, Button } from '@packages/utils';
import { useTheme } from 'styled-components';
import { Row, Col } from 'components/Base/Grid';
import { EIconNames, Icon } from 'components/Base/Icon';
import { TTeamListActionProps } from '../TeamListAction.types';
import RemoveAdminModal from './RemoveAdminModal';

const TeamAction = ({ teamData }: TTeamListActionProps) => {
  const theme = useTheme();
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.team.list.actions',
  });
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <Row gutter={[12, 12]}>
        <Col>
          <Button
            icon={
              <Icon
                alt="person delete"
                icon={EIconNames.PERSON_DELETE}
                size={14}
                className="pointer"
                color={theme.colorErrorDefault}
              />
            }
            tooltip={t('remove-user.REMOVE_TOOLTIP')}
            onClick={() => setOpen(true)}
            color="primary"
            shape="circle"
            size="middle"
          />
        </Col>
      </Row>
      <RemoveAdminModal
        open={open}
        setOpen={setOpen}
        adminId={teamData.adminId}
      />
    </>
  );
};

export default TeamAction;
