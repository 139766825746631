import { useTheme } from 'styled-components';
import { useGetLightBoxGalleryQuery } from 'redux/apiSlice/lightbox.slice';
import { Form, FormItem } from 'components/Base/Form';
import { TextArea } from 'components/Base/Input';
import { Row } from 'components/Base/Grid';
import { EIconNames, Icon } from 'components/Base/Icon';
import Spinner from 'components/Base/Spinner';
import useGetFormData from './LightBoxForm.data';
import { TLightBoxFormProps } from './LightboxForm.types';
import {
  SImage,
  SImageWrapper,
  SSelectableImageWrapper,
  SSelectedPhotoHoverWrapper,
} from './LightBoxForm.styles';

const LightBoxForm = ({
  form,
  selectedImage,
  setSelectedImage,
  initialValues,
  setFirstSectionText,
  setSecondSectionText,
}: TLightBoxFormProps) => {
  const theme = useTheme();
  const { data: gallery, isLoading } = useGetLightBoxGalleryQuery();
  const { lightBoxFormFields } = useGetFormData();
  const { firstSection, secondSection, picture } = lightBoxFormFields;

  const handleSelectImage = (img: string) => {
    setSelectedImage(img);
    form.setFields([
      {
        name: 'picture',
        value: img,
        errors: [],
      },
    ]);
  };

  return (
    <Form form={form} initialValues={initialValues}>
      <FormItem {...firstSection}>
        <TextArea
          onChange={(e: { target: { value: string } }) =>
            setFirstSectionText(e.target.value)
          }
          placeholder={firstSection.placeholder}
          maxLength={350}
        />
      </FormItem>
      <FormItem {...secondSection}>
        <TextArea
          onChange={(e: { target: { value: string } }) =>
            setSecondSectionText(e.target.value)
          }
          placeholder={secondSection.placeholder}
          maxLength={350}
        />
      </FormItem>
      <FormItem {...picture}>
        {isLoading ? (
          <Spinner spinnerSize={16} />
        ) : (
          <Row gutter={[16, 16]}>
            {(gallery || []).map((img, index) => {
              return (
                <SSelectableImageWrapper
                  md={8}
                  sm={24}
                  key={img}
                  className={`${
                    selectedImage === img ? 'selected' : ''
                  } pointer`}
                >
                  <SImageWrapper>
                    <SImage
                      alt={`Image ${index + 1}`}
                      src={img}
                      onClick={() => handleSelectImage(img)}
                    />
                  </SImageWrapper>
                  <SSelectedPhotoHoverWrapper
                    justify="center"
                    align="middle"
                    className="selected-wrapper h-100 w-100"
                  >
                    <Icon
                      icon={EIconNames.CHECK}
                      alt="check icon"
                      color={theme.colorWhitePrimary}
                      size={40}
                    />
                  </SSelectedPhotoHoverWrapper>
                </SSelectableImageWrapper>
              );
            })}
          </Row>
        )}
      </FormItem>
    </Form>
  );
};

export default LightBoxForm;
