import { useTranslation } from '@packages/utils';
import { get } from 'lodash';
import useForm from 'hooks/useForm';
import { Form, FormItem } from 'components/Base/Form';
import { useMarkAsOrderedWorkshopActionMutation } from 'redux/apiSlice/kitManagement.slice';
import { Modal } from 'components/Base/Modal';
import { dateShortenFormat } from 'helpers/utils';
import {
  useShowToastNotification,
  ENotificationType,
} from 'components/Base/Notification';
import { DatePicker } from 'components/Base/DatePicker';
import { TValidateFieldsError } from 'data/types/generic.types';
import {
  TKitManagementMarkAsOrderedForm,
  TMarkAsOrderedModalProps,
} from './MarkAsOrderedModal.types';
import useGetFormData from './MarkAsOrderedModal.data';

const MarkAsOrderedModal = ({
  open,
  setOpen,
  partOrderIds,
}: TMarkAsOrderedModalProps) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.kit-management.list.actions.mark-as-ordered',
  });
  const { form } = useForm<TKitManagementMarkAsOrderedForm>();
  const { markAsOrderedFormFields } = useGetFormData();
  const { orderDate } = markAsOrderedFormFields;

  const { showToastNotification } = useShowToastNotification();

  const [markAsOrdered, { isLoading }] =
    useMarkAsOrderedWorkshopActionMutation();

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      await markAsOrdered({
        partOrderIds,
        orderDate: values.orderDate,
      }).unwrap();
      showToastNotification({
        message: t('modal.SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (err) {
      if (!(err as TValidateFieldsError)?.errorFields) {
        const message = get(err, 'data.message');
        showToastNotification({
          message,
          type: ENotificationType.ERROR,
        });
      }
    }
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      title={t('modal.TITLE')}
      okText={t('modal.SUBMIT_BTN')}
      cancelText={t('modal.CANCEL_BTN')}
      onCancel={() => setOpen(false)}
      onOk={handleSubmit}
      width={408}
      afterClose={() => form.resetFields()}
      confirmLoading={isLoading}
    >
      <Form form={form}>
        <FormItem {...orderDate}>
          <DatePicker
            className="w-100"
            placeholder={orderDate.placeholder}
            format={dateShortenFormat}
          />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default MarkAsOrderedModal;
