import {
  useShowToastNotification,
  Button,
  useTranslation,
  ENotificationType,
  TRtkErrorType,
} from '@packages/utils';
import { useTheme } from 'styled-components';
import { EIconNames, Icon } from 'components/Base/Icon';
import { useDeletePeopleOrganizationMutation } from '../../../redux/apiSlice/people.slice';
import { TRemovePeopleOrganizationProps } from './RemovePeopleOrganization.types';

const RemovePeopleOrganization = ({
  organizationId,
  userId,
}: TRemovePeopleOrganizationProps) => {
  const theme = useTheme();
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.people.details.details-tab.actions.delete',
  });
  const { showToastNotification } = useShowToastNotification();
  const [deletePeopleOrganization] = useDeletePeopleOrganizationMutation();
  const handleRemove = async () => {
    try {
      await deletePeopleOrganization({
        organizationId,
        userId,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (e) {
      showToastNotification({
        message: (e as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  return (
    <Button
      icon={
        <Icon
          alt="Edit icon"
          icon={EIconNames.DELETE_OUTLINED}
          color={theme.colorPinkActive}
          size={16}
          className="pointer"
        />
      }
      tooltip={t('DELETE_TOOLTIP')}
      color="primary"
      onClick={handleRemove}
      shape="circle"
      size="middle"
    />
  );
};

export default RemovePeopleOrganization;
