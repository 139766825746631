import { useTranslation } from '@packages/utils';
import useGetValidationData from 'components/Base/Form/validationRules';

const useGetFormData = () => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.team.list.actions.add-user.modal.form',
  });
  const { validationRules } = useGetValidationData();
  const addNewAdminFormFields = {
    userId: {
      name: 'userId',
      label: t('user.LABEL'),
      placeholder: t('user.PLACEHOLDER'),
      rules: [validationRules.requiredField()],
    },
    // firstName: {
    //   name: 'firstName',
    //   label: t('first-name.LABEL'),
    //   placeholder: t('first-name.PLACEHOLDER'),
    //   rules: [validationRules.requiredField()],
    // },
    // lastName: {
    //   name: 'lastName',
    //   label: t('last-name.LABEL'),
    //   placeholder: t('last-name.PLACEHOLDER'),
    //   rules: [validationRules.requiredField()],
    // },
    // email: {
    //   name: 'email',
    //   label: t('email.LABEL'),
    //   placeholder: t('email.PLACEHOLDER'),
    //   rules: [validationRules.requiredField(), validationRules.email()],
    // },
    // phone: {
    //   name: 'phone',
    //   label: t('mobile.LABEL'),
    //   placeholder: t('mobile.PLACEHOLDER'),
    //   rules: [validationRules.requiredField(), validationRules.phoneNumber()],
    // },
  };

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  };

  return { initialValues, addNewAdminFormFields };
};

export default useGetFormData;
