import {
  Col,
  ENotificationType,
  Row,
  TRtkErrorType,
  useGetValidationData,
  useShowToastNotification,
  useTranslation,
} from '@packages/utils';
import EditableInput from 'components/Base/EditableFields/EditableInput';
import { InplaceEditLabels } from 'components/Base/EditableFields/components/InplaceEditLabel';
import { EIconNames } from 'components/Base/Icon';
import { useUpdateAdditionalPhoneMutation } from 'redux/apiSlice/organizations.slice';
import { TAdditionalMobileProps } from './AdditionalMobile.types';

const AdditionalMobile = ({
  allowedActions,
  data,
  organizationId,
}: TAdditionalMobileProps) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.people.details.details-tab.additional-contacts.mobile',
  });
  const { validationRules } = useGetValidationData();
  const { showToastNotification } = useShowToastNotification();
  const [editAdditionalPhone, { isLoading, isError }] =
    useUpdateAdditionalPhoneMutation();

  const onEdit = async (value: string) => {
    try {
      await editAdditionalPhone({
        organizationId,
        phone: value,
        additionalPhoneId: data.additionalPhoneId,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (e) {
      showToastNotification({
        message: (e as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  return (
    <Row align="middle" wrap={false} className="w-100 mb-1">
      <Col>
        <InplaceEditLabels
          label={t('INPLACE_EDIT_LABEL')}
          icon={EIconNames.PHONE_OUTLINED}
        />
      </Col>
      <Col flex="auto">
        <EditableInput
          onEdit={onEdit}
          title={data.phone || ''}
          isError={isError}
          isLoading={isLoading}
          fitContent={false}
          validateTrigger="onSubmit"
          rules={[validationRules.phoneNumber()]}
          readonly={allowedActions.readonly}
          canRemove
          emptyText={t('EMPTY_TEXT')}
          placeholder={t('PLACEHOLDER')}
          backIcon
        />
      </Col>
    </Row>
  );
};

export default AdditionalMobile;
