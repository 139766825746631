import { FC } from 'react';
import { useTheme } from 'styled-components';
import { Paragraph } from 'components/Base/Typography';
import { useTranslation } from '@packages/utils';
import { TKitLanguageTextProps } from './KitLanguageText.types';

const KitLanguageText: FC<TKitLanguageTextProps> = ({ language }) => {
  const theme = useTheme();
  const { t } = useTranslation({
    keyPrefix: 'enums.language',
  });
  return (
    <Paragraph
      fontWeight={theme.fontWeightSemiBold}
      fontSize={theme.fontSizeLg}
      color={theme.colorPurpleDefault}
    >
      {t(language)}
    </Paragraph>
  );
};

export default KitLanguageText;
