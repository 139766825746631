import { RefObject, useEffect } from 'react';

export const useOutsideClick = (
  ref: RefObject<Element>,
  onOutsideClick: () => void,
  conditionToTrigger: boolean,
  additionalIgnoreSelectors: string[] = [],
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Node;

      if (!ref.current) return;

      // Check if click is outside the ref element
      if (ref.current.contains(target)) return;

      // Check if click is within any additional ignored selectors
      const isIgnored = additionalIgnoreSelectors.some(selector => {
        const ignoredElement = document.querySelector(selector);
        return ignoredElement && ignoredElement.contains(target);
      });

      if (isIgnored) return;

      // Check condition and call the outside click handler
      if (conditionToTrigger) {
        onOutsideClick();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, onOutsideClick, conditionToTrigger, additionalIgnoreSelectors]);
};
