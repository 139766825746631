import { useTranslation } from '@packages/utils';
import { dateConverter, dateShortenFormat } from 'helpers/utils';
import Spinner from 'components/Base/Spinner';
import { useGetWorkshopRegistrantsUrlQuery } from 'redux/apiSlice/workshops.slice';
import CopyUrls from '../CopyUrls';
import { TCopyAttendanceListUrlProps } from './CopyAttendanceListUrl.types';

const CopyAttendanceListUrl = ({
  workshopId,
  date,
}: TCopyAttendanceListUrlProps) => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.workshops.details.actions',
  });

  const { data: registrantsUrl, isLoading } = useGetWorkshopRegistrantsUrlQuery(
    {
      workshopId,
      localDate: date,
    },
  );

  return (
    <>
      {isLoading ? (
        <Spinner spinnerSize={16} className="edit-loading" />
      ) : (
        <CopyUrls
          copyText={`${t('copy-url.COPY')} (${dateConverter(
            date,
            dateShortenFormat,
          )})`}
          copyUrl={registrantsUrl}
        />
      )}
    </>
  );
};

export default CopyAttendanceListUrl;
