import get from 'lodash/get';
import { isEqual } from 'lodash';
import { useTranslation } from '@packages/utils';
import { Modal } from 'components/Base/Modal';
import {
  useShowToastNotification,
  ENotificationType,
} from 'components/Base/Notification';
import { useEditFamilyMutation } from 'redux/apiSlice/family.slice';
import { TValidateFieldsError } from 'data/types/generic.types';
import useForm from 'hooks/useForm';
import {
  TEditFamilyFormUI,
  TEditFamilyModalProps,
} from './EditFamilyModal.types';
import EditFamilyForm from './EditFamilyForm';
import { familyListKey } from '../../Family.data';

const EditFamilyModal = ({
  open,
  setOpen,
  familyData,
}: TEditFamilyModalProps) => {
  const { t } = useTranslation({
    keyPrefix: familyListKey.concat('.actions.edit-family.modal'),
  });
  const { showToastNotification } = useShowToastNotification();
  const { form } = useForm<TEditFamilyFormUI>();
  const [updateFamily, { isLoading }] = useEditFamilyMutation();

  const handleClose = () => {
    setOpen(false);
    form.resetFields();
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const { email, firstName, lastName, phone } = familyData;
      if (isEqual(values, { email, firstName, lastName, phone })) {
        handleClose();
        return;
      }

      await updateFamily({
        ...values,
        registrantId: familyData.registrantId,
        workshopClassId: familyData.workshopClassId,
        workshopId: familyData.workshopId,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
      handleClose();
    } catch (e) {
      if (!(e as TValidateFieldsError)?.errorFields) {
        const message = get(e, 'data.message');
        showToastNotification({
          message,
          type: ENotificationType.ERROR,
        });
      }
    }
  };

  return (
    <Modal
      title={t('TITLE')}
      open={open}
      onCancel={handleClose}
      onOk={handleSubmit}
      okText={t('SUBMIT_BTN')}
      cancelText={t('CANCEL_BTN')}
      confirmLoading={isLoading}
    >
      <EditFamilyForm form={form} data={familyData} />
    </Modal>
  );
};

export default EditFamilyModal;
