import { FC } from 'react';
import { useTranslation } from '@packages/utils';
import { EIconNames } from 'components/Base/Icon';
import ActionGroupItemLabel from 'components/Shared/ActionsButtonDetailsPage/ActionGroupItemLabel';
import {
  ENotificationType,
  useShowToastNotification,
} from 'components/Base/Notification';
import { useLazyGetWorkshopAppUrlByOrganizationQuery } from 'redux/apiSlice/organizations.slice';
import { organizationActionsKey } from '../../OrganizationDetails.data';
import { TCopyUrlActionProps } from './CopyUrlAction.types';

const CopyUrlAction: FC<TCopyUrlActionProps> = ({ id }) => {
  const { t } = useTranslation({
    keyPrefix: organizationActionsKey.concat('.copy-url'),
  });
  const { showToastNotification } = useShowToastNotification();

  const [getWorkshopUrl] = useLazyGetWorkshopAppUrlByOrganizationQuery();

  const onCopyUrl = async () => {
    try {
      const workshopAppUrl = await getWorkshopUrl({
        id,
      }).unwrap();

      if (workshopAppUrl) {
        navigator.clipboard.writeText(workshopAppUrl);
        showToastNotification({
          message: t('SUCCESS_MESSAGE'),
          type: ENotificationType.SUCCESS,
        });
      }
    } catch (error) {
      showToastNotification({
        message: undefined,
        type: ENotificationType.ERROR,
      });
    }
  };
  return (
    <ActionGroupItemLabel
      label={t('COPY_URL_BTN')}
      icon={EIconNames.COPY_OUTLINED}
      onClick={onCopyUrl}
    />
  );
};

export default CopyUrlAction;
