import { Row, Title, useTranslation } from '@packages/utils';
import { TLinksSectionProps } from './LinksSection.types';
import LinkItem from './LinkItem';

const LinksSection = ({
  data,
  elcId,
  isResourceEditable,
}: TLinksSectionProps) => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.elcs.detail.resources-tab.sections',
  });
  return (
    <>
      <Title level={4} className="mt-6 mb-3">
        {t('LINKS')}
      </Title>
      <Row gutter={[16, 16]} className="ml-1">
        {data.map(item => (
          <LinkItem
            key={item.resourceId}
            data={item}
            elcId={elcId}
            isResourceEditable={isResourceEditable}
          />
        ))}
      </Row>
    </>
  );
};

export default LinksSection;
