import { styled } from 'styled-components';
import { Card } from '@packages/utils';
import { Divider } from 'components/Base/Divider';
import { Image } from 'components/Base/Image';

export const SDivider = styled(Divider)`
  border-inline-start-width: 5px;
`;
export const SImage = styled(Image)`
  width: 566px;
  height: 432px;
  object-fit: cover;
  border: 5px solid ${({ theme }) => theme.colorWhitePrimary};
  border-radius: 24px;
`;

export const SCard = styled(Card)`
  &&& {
    border-radius: 32px;
    border: 5px solid ${({ theme }) => theme.colorPrimaryBorderLight};
  }
`;

export const SInfoItemWrapper = styled('div')`
  border: 5px solid ${({ theme }) => theme.colorPrimaryBorderLight};
  padding: 8px 24px;
  border-radius: ${({ theme }) => theme.borderRadiusXxl}px;
  text-align: center;
  min-width: max-content;
`;
