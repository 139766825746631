import styled from 'styled-components';
import { Uploader } from 'components/Base/Uploader';
import { Card } from '@packages/utils';

export const SUplaoder = styled(Uploader)`
  .ant-upload {
    width: 100%;
    .ant-btn,
    .ant-btn:hover {
      width: 100%;
      border: 1px dashed ${({ theme }) => theme.colorPrimaryDefaultNew};
      background-color: ${({ theme }) => theme.colorPrimaryBgBlue};
      color: ${({ theme }) => theme.colorPrimaryDefaultNew};
      display: block;
      min-height: 104px;
      .ant-btn-icon {
        display: block;
      }
    }
  }
`;

export const SUploadedItemWrapper = styled(Card)`
  &.ant-card {
    .ant-card-body {
      padding: 12px;
    }
  }
`;
