import { useTranslation } from '@packages/utils';
import { useCheckPermission } from 'hooks/useCheckPermission';
import WaitList from 'pages/private/Subscribers/WaitList';
import WorkshopSubscribersList from 'pages/private/Subscribers/WorkshopSubscribersList';
import { RoutesPaths, SubRouteType } from 'routes/Routes.types';

const useGetSubscribersSidebarData = () => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.sidebar',
  });
  const subscribersSubRoutes: SubRouteType[] = [
    {
      title: t('WAIT_LIST'),
      component: WaitList,
      path: RoutesPaths.SUBSCRIBERS_WAIT_LIST,
      show: true,
      showByPermissions: useCheckPermission('WAIT_LIST_VIEW'),
    },
    {
      title: t('NOTIFY_ME'),
      component: WorkshopSubscribersList,
      path: RoutesPaths.SUBSCRIBERS_NOTIFY,
      show: true,
      showByPermissions: useCheckPermission('SUBSCRIBERS_VIEW'),
    },
  ];
  return { subscribersSubRoutes };
};

export default useGetSubscribersSidebarData;
