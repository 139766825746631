import { useCheckPermission } from 'hooks/useCheckPermission';
import MyELCDetail from 'pages/private/ELCs/ELCDetail/MyELCDetail';
import MyELCs from 'pages/private/ELCs/ELCList/MyELCs';
import { RoutesPaths, SubRouteType } from 'routes/Routes.types';

const useGetMyELCsSubRoutesData = () => {
  const MyELCsSubRoutes: SubRouteType[] = [
    {
      component: MyELCs,
      path: RoutesPaths.MYELCS,
      show: false,
      showByPermissions: useCheckPermission('MY_ELC_LIST_VIEW'),
    },
    {
      component: MyELCDetail,
      path: RoutesPaths.MYELCS_DETAIL,
      show: false,
      showByPermissions: useCheckPermission('MY_ELC_LIST_VIEW'),
    },
  ];
  return { MyELCsSubRoutes };
};

export default useGetMyELCsSubRoutesData;
