import { Card } from '@packages/utils';
import { styled } from 'styled-components';

export const SLightBoxCardWrapper = styled(Card)`
  &&& {
    .ant-card-body {
      text-align: center;
    }
  }
`;
