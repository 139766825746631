import { FC } from 'react';
import { useTranslation } from '@packages/utils';
import { Row } from 'components/Base/Grid';
import { Switch } from 'components/Base/Switch';
import { TOrientationKitReceivedProps } from './OrientationKitReceived.types';
import useGetHelpers from '../../helpers';
import AttendanceText from '../../components/AttendanceText';

const OrientationKitReceived: FC<TOrientationKitReceivedProps> = ({
  orientationKit,
  disabled,
  isReceived,
  setIsReceived,
}) => {
  const { getOrientationKitRequestedText, getAttendanceTextColor } =
    useGetHelpers();
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.workshops.details.workshop-registrants.actions.edit.modal.attendance-chart',
  });
  const handleOrientationKitChange = (checked: boolean) => {
    setIsReceived(checked);
  };

  return (
    <Row justify="start" className="mb-4 w-100">
      <AttendanceText text={t('labels.BINDER')} />
      <AttendanceText
        className="ml-1"
        text={getOrientationKitRequestedText(orientationKit.requested)}
        color={getAttendanceTextColor(orientationKit.requested)}
      />

      <Switch
        className="ml-4"
        checkedChildren={t('binder-options.CHECKED')}
        unCheckedChildren={t('binder-options.NON_CHECKED')}
        onChange={handleOrientationKitChange}
        checked={isReceived}
        disabled={disabled}
      />
    </Row>
  );
};

export default OrientationKitReceived;
