import { FC } from 'react';
import { Col } from 'components/Base/Grid';
import ActionButton from '../ActionButton';
import { TDeleteActionProps } from '../../QuestionTab.types';

const DeleteAction: FC<TDeleteActionProps> = ({ canDelete, onClick }) => {
  return canDelete ? (
    <Col>
      <ActionButton onClick={onClick} type="delete" />
    </Col>
  ) : null;
};

export default DeleteAction;
