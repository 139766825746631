import useCopyToClipboard from 'hooks/useCopyClipboard';
import {
  Col,
  ENotificationType,
  Row,
  TRtkErrorType,
  useGetValidationData,
  useShowToastNotification,
  useTranslation,
} from '@packages/utils';
import { useTheme } from 'styled-components';
import EditableInput from 'components/Base/EditableFields/EditableInput';
import { InplaceEditLabels } from 'components/Base/EditableFields/components/InplaceEditLabel';
import { useUpdateAdditionalEmailMutation } from 'redux/apiSlice/people.slice';
import { Icon, EIconNames } from 'components/Base/Icon';
import { TAdditionalEmailProps } from './AdditionalEmail.types';

const AdditionalEmail = ({
  userId,
  allowedActions,
  data,
}: TAdditionalEmailProps) => {
  const theme = useTheme();
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.people.details.details-tab.additional-contacts.email',
  });
  const [isCopied, copyToClipboard] = useCopyToClipboard();
  const { validationRules } = useGetValidationData();
  const { showToastNotification } = useShowToastNotification();
  const [editAdditionalEmail, { isLoading, isError }] =
    useUpdateAdditionalEmailMutation();

  const onEdit = async (value: string) => {
    try {
      await editAdditionalEmail({
        userId,
        email: value,
        additionalEmailId: data.additionalEmailId,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (e) {
      showToastNotification({
        message: (e as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  return (
    <Row align="middle" wrap={false} className="w-100 mb-1">
      <Col>
        <InplaceEditLabels
          label={t('INPLACE_EDIT_LABEL')}
          icon={EIconNames.EMAIL_OUTLINED}
        />
      </Col>
      <Col flex="auto">
        <EditableInput
          onEdit={onEdit}
          title={data.email}
          isError={isError}
          isLoading={isLoading}
          fitContent={false}
          validateTrigger="onSubmit"
          rules={[validationRules.email()]}
          readonly={allowedActions.readonly}
          canRemove={allowedActions.canRemove}
          emptyText={t('EMPTY_TEXT')}
          placeholder={t('PLACEHOLDER')}
          backIcon
        />
      </Col>
      {data.email && (
        <Col onClick={() => copyToClipboard(data.email)}>
          <Icon
            icon={isCopied ? EIconNames.CHECK : EIconNames.COPY_OUTLINED}
            color={theme.colorBlackIconColor}
            className="pointer"
            size={16}
          />
        </Col>
      )}
    </Row>
  );
};

export default AdditionalEmail;
