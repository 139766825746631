import {
  Col,
  ENotificationType,
  Row,
  TRtkErrorType,
  useShowToastNotification,
  useTranslation,
} from '@packages/utils';
import EditableTextArea from 'components/Base/EditableFields/EditableTextArea';
import { InplaceEditLabels } from 'components/Base/EditableFields/components/InplaceEditLabel';
import { useUpdatePeopleTitleMutation } from 'redux/apiSlice/people.slice';
import { EIconNames } from 'components/Base/Icon';
import { TPeopleTitleProps } from './PeopleTitle.types';

const PeopleTitle = ({ userId, data }: TPeopleTitleProps) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.people.details.details-tab.main-contact.title',
  });
  const { showToastNotification } = useShowToastNotification();
  const [editTitle, { isLoading, isError }] = useUpdatePeopleTitleMutation();

  const onEdit = async (value: string) => {
    try {
      await editTitle({
        userId,
        title: value,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (e) {
      showToastNotification({
        message: (e as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  return (
    <Row align="middle" wrap={false} className="w-100 mb-1">
      <Col>
        <InplaceEditLabels
          label={t('INPLACE_EDIT_LABEL')}
          icon={EIconNames.TWO_LINES}
        />
      </Col>
      <Col flex="auto">
        <EditableTextArea
          onEdit={onEdit}
          title={data.value}
          isError={isError}
          isLoading={isLoading}
          autoSize={{ maxRows: 1 }}
          maxLength={250}
          showCount
          readonly={data?.allowedOperations.readonly}
          canRemove={data?.allowedOperations.canRemove}
          emptyText={t('EMPTY_TEXT')}
          placeholder={t('PLACEHOLDER')}
        />
      </Col>
    </Row>
  );
};

export default PeopleTitle;
