import { FC } from 'react';
import { useTranslation } from '@packages/utils';
import { STabBodyWrapper, STabs } from './Tabs.styles';
import { TTabBodyWrapperProps, TabsProps } from './Tabs.types';
import Spinner from '../Spinner';
import { EmptyView } from '../EmptyView';
import { EIconNames } from '../Icon';

export const Tabs = ({ ...props }: TabsProps) => {
  return <STabs {...props} />;
};

export const TabBodyWrapper: FC<TTabBodyWrapperProps> = ({
  children,
  isEmpty,
  isLoading,
  emptyText,
  emptyIcon,
  minHeight,
  btnText,
  src,
  onBtnClick,
}) => {
  const { t } = useTranslation({ keyPrefix: 'descriptions.global_names' });
  let emptyLodingView: JSX.Element | null;
  if (isLoading) {
    emptyLodingView = <Spinner centered spinnerSize={32} />;
  } else if (isEmpty) {
    emptyLodingView = (
      <EmptyView
        alt="empty view"
        btnText={btnText}
        emptyText={emptyText || t('NO_DATA')}
        {...(!src && {
          icon: emptyIcon || EIconNames.EMPTY_DATA,
        })}
        onClick={onBtnClick}
        src={src}
      />
    );
  } else {
    emptyLodingView = null;
  }

  return (
    <STabBodyWrapper minHeight={minHeight}>
      {emptyLodingView || children}
    </STabBodyWrapper>
  );
};
