import { useTranslation } from '@packages/utils';
import Security from 'pages/private/Security';
import TeamList from 'pages/private/Team/TeamList';
import LightBox from 'pages/private/LightBox';
import { RoutesPaths, SubRouteType } from 'routes/Routes.types';
import { useCheckPermission } from 'hooks/useCheckPermission';

const useGetSettingsSidebarData = () => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.sidebar',
  });
  const settingsSubRoutes: SubRouteType[] = [
    {
      title: t('SECURITY'),
      component: Security,
      path: RoutesPaths.SECURITY,
      show: true,
      showByPermissions: useCheckPermission('SECURITY_VIEW'),
    },
    {
      title: t('TEAM'),
      component: TeamList,
      path: RoutesPaths.TEAM,
      show: true,
      showByPermissions: useCheckPermission('ADMIN_LIST_VIEW'),
    },
    {
      title: t('LIGHT_BOX'),
      component: LightBox,
      path: RoutesPaths.LIGHT_BOX,
      show: true,
      showByPermissions: useCheckPermission('LIGHT_BOX_VIEW'),
    },
  ];
  return { settingsSubRoutes };
};

export default useGetSettingsSidebarData;
