import { FC } from 'react';
import get from 'lodash/get';
import { useTranslation } from '@packages/utils';
import { useAppDispatch, useAppSelector } from 'redux/store';
import useForm from 'hooks/useForm';
import {
  useAddNewUserMutation,
  userGrowApi,
} from 'redux/apiSlice/userGrow.slice';
import { Modal } from 'components/Base/Modal';
import {
  useShowToastNotification,
  ENotificationType,
} from 'components/Base/Notification';
import { TValidateFieldsError } from 'data/types/generic.types';
import { TAddNewAdminModalProps } from './AddNewAdminModal.types';
import AddNewAdminForm from './AddNewAdminForm';

const AddNewAdminModal: FC<TAddNewAdminModalProps> = ({
  open,
  setOpen,
  profileType,
  modalTitle,
}) => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.team.list.actions.add-user.modal',
  });
  const dispatch = useAppDispatch();
  const usersData = useAppSelector(state => state.userSlice);
  const { user } = usersData;
  const { showToastNotification } = useShowToastNotification();
  const { form } = useForm<{ userId: string }>();
  const [addNewUser, { isLoading }] = useAddNewUserMutation();
  const handleClose = () => {
    setOpen(false);
    form.resetFields();
  };

  const handleSubmit = async () => {
    const values = await form.validateFields();
    try {
      await addNewUser({ profileType, ...values }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
      if (values.userId === user.userId) {
        dispatch(userGrowApi.util.invalidateTags(['user']));
      }
      handleClose();
    } catch (e) {
      if (!(e as TValidateFieldsError)?.errorFields) {
        const message = get(e, 'data.message');
        showToastNotification({
          message,
          type: ENotificationType.ERROR,
        });
      }
    }
  };

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      onOk={handleSubmit}
      title={modalTitle}
      confirmLoading={isLoading}
      okText={t('SUBMIT_BTN')}
      cancelText={t('CANCEL_BTN')}
    >
      <AddNewAdminForm form={form} profileType={profileType} />
    </Modal>
  );
};

export default AddNewAdminModal;
