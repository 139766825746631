import { useTranslation } from '@packages/utils';
import useGetValidationData from 'components/Base/Form/validationRules';

const useGetFormData = () => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.elc-places-tab.list.actions.add.modal.form',
  });
  const { validationRules } = useGetValidationData();
  const addELACMemberFormFields = {
    organizationId: {
      name: 'organizationId',
      label: t('place.LABEL'),
      placeholder: t('place.PLACEHOLDER'),
      rules: [validationRules.requiredField()],
    },
  };

  return { addELACMemberFormFields };
};

export default useGetFormData;
