import { FC, useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { useTranslation, CheckableTag } from '@packages/utils';
import { Modal } from 'components/Base/Modal';
import { Row } from 'components/Base/Grid';
import {
  useShowToastNotification,
  ENotificationType,
} from 'components/Base/Notification';
import { TRtkErrorType } from 'data/types/generic.types';
import { useGetTagsListQuery } from 'redux/apiSlice/tags.slice';
import Spinner from 'components/Base/Spinner';
import { TTagUI } from 'data/types/tags.types';
import AddEditTagModalTitle from './AddEditTagModalTitle';
import { getTagDifferences, tagModalKeyPrefix } from '../untils';
import { TAddEditTagModalProps } from './AddEditTagModal.types';

const AddEditTagModal: FC<TAddEditTagModalProps> = ({
  open,
  tagType,
  setOpen,
  initialSelectedTags = [],
  onSubmit,
  confirmLoading,
  selectedTagsMaxCount = 3,
}) => {
  const { data: allTags, isLoading } = useGetTagsListQuery(tagType);

  const { t } = useTranslation({
    keyPrefix: tagModalKeyPrefix,
  });
  const { showToastNotification } = useShowToastNotification();
  const [selectedTags, setSelectedTags] = useState(initialSelectedTags);

  useEffect(() => {
    if (open) {
      setSelectedTags(initialSelectedTags);
    }
  }, [open]);

  const handleClose = () => {
    setOpen(false);
    setSelectedTags([]);
  };

  const handleSubmit = async () => {
    if (isEqual(selectedTags, initialSelectedTags)) {
      handleClose();
      return;
    }
    try {
      const { assignedTagIds, removedTagIds } = getTagDifferences(
        initialSelectedTags,
        selectedTags,
      );

      await onSubmit(assignedTagIds, removedTagIds);

      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
      handleClose();
    } catch (err) {
      showToastNotification({
        message: (err as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  const handleTagChange = (checked: boolean, tag: TTagUI) => {
    if (checked && selectedTags.length !== selectedTagsMaxCount) {
      setSelectedTags([...selectedTags, tag]);
    } else {
      setSelectedTags(
        selectedTags.filter(selectedTag => selectedTag.value !== tag.value),
      );
    }
  };

  return (
    <Modal
      title={<AddEditTagModalTitle />}
      open={open}
      onCancel={handleClose}
      onOk={handleSubmit}
      okText={t('CREATE_MODAL')}
      cancelText={t('CANCEL_BTN')}
      confirmLoading={confirmLoading}
    >
      {isLoading ? (
        <Spinner centered size="large" fullscreen />
      ) : (
        <Row gutter={[4, 4]}>
          {(allTags || []).map(tag => {
            return (
              <CheckableTag
                key={tag.value}
                checked={selectedTags.some(
                  selectedTag => tag.value === selectedTag.value,
                )}
                onChange={checked => handleTagChange(checked, tag)}
              >
                {tag.text}
              </CheckableTag>
            );
          })}
        </Row>
      )}
    </Modal>
  );
};

export default AddEditTagModal;
