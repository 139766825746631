import { useState } from 'react';
import { useTranslation, Button } from '@packages/utils';
import { useTheme } from 'styled-components';
import { EIconNames, Icon } from 'components/Base/Icon';
import { TActionsProps } from '../Actions.types';
import DeleteDetailsModal from './DeleteDetailsModal';

const DeleteAction = ({ organizationId, note }: TActionsProps) => {
  const theme = useTheme();
  const { t } = useTranslation({
    keyPrefix: 'descriptions.tooltip',
  });
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        icon={
          <Icon
            alt="delete icon"
            icon={EIconNames.DELETE}
            size={14}
            color={theme.colorErrorDefault}
            className="pointer"
          />
        }
        tooltip={t('DELETE')}
        onClick={() => setOpen(true)}
        color="primary"
        shape="circle"
        size="middle"
      />
      <DeleteDetailsModal
        setOpen={setOpen}
        open={open}
        organizationId={organizationId}
        organizationNoteId={note.id}
      />
    </>
  );
};

export default DeleteAction;
