import { useTranslation } from '@packages/utils';
import useGetValidationData from 'components/Base/Form/validationRules';

const useGetFormData = () => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.meetings-tab.list.actions.addEditForm.form',
  });
  const { validationRules } = useGetValidationData();
  const addEditMeetingFormFields = {
    title: {
      name: 'title',
      label: t('meeting-name.LABEL'),
      placeholder: t('meeting-name.PLACEHOLDER'),
      rules: [validationRules.requiredField()],
    },
    date: {
      name: 'date',
      label: t('meeting-date.LABEL'),
      placeholder: t('meeting-date.PLACEHOLDER'),
      rules: [validationRules.requiredField()],
    },
  };

  return { addEditMeetingFormFields };
};

export default useGetFormData;
