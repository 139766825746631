import { useTranslation } from '@packages/utils';
import useGetValidationData from 'components/Base/Form/validationRules/index';
import { TChangePasswordFormData } from './ChangePassword.types';

const useGetFormData = () => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.authentication.changePassword.form',
  });
  const { validationRules } = useGetValidationData();
  const changePasswordFormData: TChangePasswordFormData = {
    currentPassword: {
      name: 'currentPassword',
      label: t('current-password.LABEL'),
      rules: [validationRules.required('password')],
      placeholder: t('current-password.PLACEHOLDER'),
    },
    password: {
      name: 'password',
      label: t('new-password.LABEL'),
      rules: [validationRules.password()],
      placeholder: t('new-password.PLACEHOLDER'),
    },
    passwordConfirm: {
      name: 'passwordConfirm',
      label: t('repeat-password.LABEL'),
      rules: [...validationRules.passwordConfirm],
      placeholder: t('repeat-password.PLACEHOLDER'),
    },
  };

  return { changePasswordFormData };
};

export default useGetFormData;
