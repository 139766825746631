import React from 'react';
import { Badge } from 'components/Base/Badge';
import { Col, Row } from 'components/Base/Grid';
import { TBadgeWrapperProps } from './BadgeWrapper.types';
import { SBadgeWrapper } from './BadgeWrapper.styles';

const BadgeWrapper: React.FC<TBadgeWrapperProps> = ({
  children,
  badgeItems,
}) => {
  const renderBadgeItems = badgeItems.map(item => {
    if (item.hide) return null;
    return (
      <Col span={24} key={item.text}>
        <Badge text={item.text} color={item.color} />
      </Col>
    );
  });

  return (
    <SBadgeWrapper
      text={
        <Row style={{ flexDirection: 'column' }} gutter={[0, 28]}>
          {renderBadgeItems}
        </Row>
      }
      color="primary"
    >
      {children}
    </SBadgeWrapper>
  );
};

export default BadgeWrapper;
