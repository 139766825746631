import { ChallengeNameType } from 'aws-sdk/clients/cognitoidentityserviceprovider';
import { CognitoUser } from '@aws-amplify/auth';
import { ChallengeName } from 'amazon-cognito-identity-js';

export enum EVia {
  EMAIL = 'email',
  PHONE = 'phone',
}

// SIGN_IN Amplify
export type TSignInAmplifyDB = { username: string; password: string };
export type TSignInAmplifyUI = { email: string; password: string };

export type TResetPasswordCasesDB = { username: string };
export type TResetPasswordCasesUI = { email: string };

export type TResetPasswordUI = {
  code: string;
  password: string;
  email: string;
};

export type TResetPasswordDB = {
  code: string;
  password: string;
  username: string;
};

export enum EResetPasswordCasesDB {
  RESENT_CODE = 'RESENT_CODE',
  RESENT_PASSWORD = 'RESENT_PASSWORD',
  RESEND_INVITATION = 'RESEND_INVITATION',
}

export enum EResetPasswordCasesUI {
  RESENT_CODE = 'RESEND_CODE',
  RESENT_PASSWORD = 'RESET_PASSWORD',
  RESEND_INVITATION = 'RESEND_INVITATION',
}

export type TAmplifyError = {
  code: string;
  name: string;
  message: string;
  username: string;
};
export type TAmplifyErrorPayload = { data: TAmplifyError };

export interface TAmplifySignInWithEmailUserData extends CognitoUser {
  username: string;
  name?: string;
  email?: string;
  emailVerified?: boolean;
  attributes?: UserEmailAttributes;
  challengeName?: ChallengeName;
}

export interface IAmplifyUser extends CognitoUser {
  username: string;
  name?: string;
  email?: string;
  emailVerified?: boolean;
  phoneNumber?: string;
  phoneNumberVerified?: boolean;
  challengeName?: ChallengeName;
}

export type TChallengeNameType = ChallengeNameType;

export type TSignInWithEmailAmplifyProp = { email: string; password: string };

export type TCurrentAuthUserUI = {
  username: string;
  name?: string;
  email?: string;
  challengeName?: TChallengeNameType;
};

export type TSignUpAmplifyDataUi = {
  username: string;
  password: string;
  userConfirmed: boolean;
  codeSend: boolean;
  via: EVia;
};

export enum EmfaTypes {
  SOFTWARE_TOKEN_MFA = 'SOFTWARE_TOKEN_MFA',
  SMS_MFA = 'SMS_MFA',
}

export enum EPreferredMFATypes {
  TOTP = 'TOTP',
  NOMFA = 'NOMFA',
}

export type TConfirmSignInUI = {
  user: TCurrentAuthUserUI;
  code: string;
  mfaType?: EmfaTypes | null;
};

export type TConfirmSignInDB = TCurrentAuthUserUI;

export type TSetupTOTPUI = {
  user: TCurrentAuthUserUI;
};

export type TSetupTOTPDB = {
  data: string;
};

export type TVerifyTotpTokenUI = {
  user: TCurrentAuthUserUI;
  challengeAnswer: string;
};

export enum EVerifyTotpToken {
  SUCCESS = 'SUCCESS',
}

export type TVerifyTotpTokenDB = {
  Status: EVerifyTotpToken;
};

export type TMFAMethod =
  | 'TOTP'
  | 'SMS'
  | 'NOMFA'
  | 'SMS_MFA'
  | 'SOFTWARE_TOKEN_MFA';

export type TSetPreferredMFAUI = {
  user: TCurrentAuthUserUI;
  MFAMethod: TMFAMethod;
};

export type TCompleteNewPassword = ICurrentAuthUser;

export type TCompleteNewPasswordUI = {
  user: CognitoUser;
  newPassword: string;
};

export type TChangePassword = ICurrentAuthUser;

export type TChangePasswordUI = {
  user: TCurrentAuthUserUI;
  existing: string;
  password: string;
};

export type TNewPasswordData = {
  password: string;
  passwordConfirm: string;
};

export enum EErrorCode {
  USER_NOT_CONFIRMED_EXCEPTION = 'UserNotConfirmedException',
  INVALID_PARAMETER_EXCEPTION = 'InvalidParameterException',
  NOT_AUTHORIZED_EXCEPTION = 'NotAuthorizedException',
  USER_NOT_FOUND_EXCEPTION = 'UserNotFoundException',
}

export enum EChallengeName {
  NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
  SOFTWARE_TOKEN_MFA = 'SOFTWARE_TOKEN_MFA',
}

export interface UserEmailAttributes {
  sub: string;
  email: string;
  email_verified: boolean;
  name: string;
  updated_at: string;
}

export interface ICurrentAuthUser extends CognitoUser {
  username: string;
  name?: string;
  email?: string;
  phoneNumber?: string;
  attributes: UserEmailAttributes;
}
