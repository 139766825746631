import { Col, Row } from 'components/Base/Grid';
import { TSessionItemPartViewProps } from '../../SessionView.types';
import FacilitatorSelect from '../../components/FacilitatorSelect';

const StandalonePartView = ({
  classItem,
  workshopId,
}: TSessionItemPartViewProps) => {
  const { id, sessions } = classItem;
  return (
    <Row className="w-100">
      <Col flex={1}>
        <FacilitatorSelect
          id={id}
          workshopId={workshopId}
          facilitator={sessions[0].facilitator}
          workshopSessionId={sessions[0].id}
        />
      </Col>
    </Row>
  );
};

export default StandalonePartView;
