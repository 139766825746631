import { useTranslation } from '@packages/utils';
import { TColumnType } from 'components/Base/Table/Table.types';
import {
  EWorkshopPartType,
  EWorkshopSeriesType,
  EWorkshopStandaloneType,
} from 'data/types/workshops.types';
import {
  EKitManagementWorkshopFilterParams,
  EKitManagementWorkshopSortParams,
  EOrderStatus,
  TKitManagementWorkshopUI,
} from 'data/types/kitManagement.types';
import OrderStatus from 'components/Shared/Statuses/OrderStatus';
import { ESortDirection } from 'data/types/generic.types';

const useGetColumnsData = () => {
  const { t } = useTranslation();
  const defaultSortParam = {
    sortField: EKitManagementWorkshopSortParams.FIRST_SESSION_DATE,
    sortDirection: ESortDirection.descend,
  };
  const defaultFilterParam = {
    [EKitManagementWorkshopFilterParams.STATUS]: [EOrderStatus.NOT_ORDERED],
  };

  const defaultColumns: TColumnType<TKitManagementWorkshopUI>[] = [
    {
      title: t(
        'descriptions.admin-portal.kit-management.list.table.title.HOST',
      ),
      dataIndex: 'hostName',
      key: EKitManagementWorkshopFilterParams.HOST,
    },
    {
      title: t(
        'descriptions.admin-portal.kit-management.list.table.title.LOCATION',
      ),
      dataIndex: 'location',
      key: EKitManagementWorkshopFilterParams.LOCATION,
    },
    {
      title: t(
        'descriptions.admin-portal.kit-management.list.table.title.TYPE',
      ),
      dataIndex: 'workshopType',
      key: EKitManagementWorkshopFilterParams.TYPE,
      filters: [
        ...Object.values(EWorkshopStandaloneType).map(item => ({
          text: t(`enums.workshop-standalone-type.${item}`),
          value: item,
        })),
        ...Object.values(EWorkshopSeriesType).map(item => ({
          text: t(`enums.workshop-series-type.${item}`),
          value: item,
        })),
      ],
      render: text => <>{t(`enums.workshop-type.${text}`)}</>,
    },
    {
      title: t(
        'descriptions.admin-portal.kit-management.list.table.title.PART',
      ),
      dataIndex: 'partDisplayText',
      key: EKitManagementWorkshopFilterParams.PART_TYPES,
      filters: Object.values(EWorkshopPartType).map(status => ({
        text: t(`enums.workshop-part-type.${status}`),
        value: status,
      })),
    },
    {
      title: t(
        'descriptions.admin-portal.kit-management.list.table.title.SESSION_DATE',
      ),
      dataIndex: 'date',
      key: EKitManagementWorkshopSortParams.FIRST_SESSION_DATE,
      sorter: true,
      ellipsis: true,
    },
    {
      title: t(
        'descriptions.admin-portal.kit-management.list.table.title.SHIPPING_ADDRESS',
      ),
      dataIndex: 'shippingAddress',
      key: 'shippingAddress',
      onCell: () => ({
        style: { minWidth: '250px' },
      }),
    },
    {
      title: t(
        'descriptions.admin-portal.kit-management.list.table.title.ORDER_DATE',
      ),
      dataIndex: 'orderDate',
      key: EKitManagementWorkshopSortParams.ORDER_DATE,
      sorter: true,
      onCell: () => ({
        style: { minWidth: '150px' },
      }),
    },
    {
      title: t(
        'descriptions.admin-portal.kit-management.list.table.title.STATUS',
      ),
      dataIndex: 'orderStatus',
      key: EKitManagementWorkshopFilterParams.STATUS,
      filters: Object.values(EOrderStatus).map(status => ({
        text: t(`enums.order-status.${status}`),
        value: status,
      })),
      render: text => <OrderStatus text={text} />,
    },
  ];

  return { defaultColumns, defaultFilterParam, defaultSortParam };
};

export default useGetColumnsData;
