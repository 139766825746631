import { Route, Routes, Navigate, useNavigate, Outlet } from 'react-router-dom';
import React, { Suspense, useEffect, FC } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';
import appSlice from 'redux/slices/app.slice';
import useGetSidebarData from './RoutesData';
import { RoutesPaths } from './Routes.types';
import SidebarRoutes from './SidebarRoutes';
import { migrateCleanUpStorage } from '../helpers/storageMigrations';

const PrivateRoutes: FC<{ isLoggedIn: boolean }> = ({ isLoggedIn }) => {
  migrateCleanUpStorage();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const redirectLink = useAppSelector(
    state => state.appSlice.currentNotificationUrl,
  );
  const { privateRoutes } = useGetSidebarData();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate(`/${RoutesPaths.LOGIN}`);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (redirectLink) {
      navigate(redirectLink);
      dispatch(appSlice.actions.setCurrentNotificationUrl(''));
    }
  }, []);

  return (
    <Suspense fallback={<SidebarRoutes />}>
      <Routes>
        <Route element={<SidebarRoutes />}>
          <Route
            path="*"
            element={<Navigate replace to={RoutesPaths.PAGE_NOT_FOUND} />}
          />
          {privateRoutes.map(({ path, component, subRoutes }) => {
            const Component = component;
            if (!subRoutes?.length) {
              return (
                <Route key={path} path={`${path}`} element={<Component />} />
              );
            }
            return (
              <Route
                key={path}
                path={`${path}/*`}
                element={
                  <Routes>
                    <Route path="/" element={<Outlet />}>
                      <Route
                        path="*"
                        element={
                          <Navigate to={`/app/${RoutesPaths.PAGE_NOT_FOUND}`} />
                        }
                      />
                      {subRoutes?.map(
                        ({ path: subRoute, component: subComponent }) => {
                          const SubComponent = subComponent;
                          const subPath = subRoute.replace(path, '');
                          return (
                            <Route
                              key={subPath}
                              path={`${subPath}`}
                              element={<SubComponent />}
                            />
                          );
                        },
                      )}
                    </Route>
                  </Routes>
                }
              />
            );
          })}
        </Route>
      </Routes>
    </Suspense>
  );
};

export default PrivateRoutes;
