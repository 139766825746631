import get from 'lodash/get';
import { useTranslation } from '@packages/utils';
import { Modal } from 'components/Base/Modal';
import questionMarkIcon from 'assets/images/questionMarkIcon.svg';
import { Image } from 'components/Base/Image';
import { Paragraph } from 'components/Base/Typography';
import {
  useShowToastNotification,
  ENotificationType,
} from 'components/Base/Notification';
import { Row, Col } from 'components/Base/Grid';
import { TValidateFieldsError } from 'data/types/generic.types';
import { useDeleteWorkshopClassMutation } from 'redux/apiSlice/workshops.slice';
import { TDeleteSessionModalProps } from './DeleteSessionModal.types';

const DeleteSessionModal = ({
  open,
  setOpen,
  workshopId,
  classId,
}: TDeleteSessionModalProps) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.workshops.details.sessions.view.actions.remove.modal',
  });
  const { showToastNotification } = useShowToastNotification();
  const [deleteSession, { isLoading }] = useDeleteWorkshopClassMutation();

  const handleSubmit = async () => {
    try {
      await deleteSession({ workshopId, classId }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
      setOpen(false);
    } catch (e) {
      if (!(e as TValidateFieldsError)?.errorFields) {
        const message = get(e, 'data.message');
        showToastNotification({
          message,
          type: ENotificationType.ERROR,
        });
      }
    }
  };

  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      onOk={handleSubmit}
      okText={t('REMOVE_BTN')}
      cancelText={t('CANCEL_BTN')}
      title={t('TITLE')}
      confirmLoading={isLoading}
      width="408px"
    >
      <Row className="p-4 text-center">
        <Col span={24}>
          <Image
            src={questionMarkIcon}
            alt="questionmark icon"
            className="mb-4 text-center"
          />
        </Col>
        <Col span={24}>
          <Paragraph>{t('DESCRIPTION')}</Paragraph>
        </Col>
      </Row>
    </Modal>
  );
};

export default DeleteSessionModal;
