import { useEffect } from 'react';
import {
  useTranslation,
  Col,
  useShowToastNotification,
  ENotificationType,
  TRtkErrorType,
} from '@packages/utils';
import { useTheme } from 'styled-components';
import { Icon } from 'components/Base/Icon';
import { useDeleteResourceMutation } from 'redux/apiSlice/elcs.slice';
import { TRemoveResourceProps } from './RemoveResource.types';

const RemoveResource = ({
  resourceId,
  elcId,
  className,
  iconName,
  iconSize,
  setRemoveIsLoading,
}: TRemoveResourceProps) => {
  const theme = useTheme();
  const { showToastNotification } = useShowToastNotification();
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.elcs.detail.resources-tab.delete',
  });
  const [deleteResource, { isLoading }] = useDeleteResourceMutation();

  useEffect(() => {
    if (setRemoveIsLoading) {
      setRemoveIsLoading(isLoading);
    }
  }, [isLoading]);

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    if (isLoading) {
      e.preventDefault();
      return;
    }
    try {
      await deleteResource({
        elcId,
        resourceId,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (err) {
      showToastNotification({
        message: (err as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  return (
    <Col onClick={handleSubmit} className={className}>
      <Icon
        icon={iconName}
        color={theme.colorBlackIconColor}
        className="pointer"
        size={iconSize || 24}
        tooltip={{ title: t('DELETE_TOOLTIP') }}
      />
    </Col>
  );
};

export default RemoveResource;
