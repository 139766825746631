import { useTranslation, Card } from '@packages/utils';
import { useTheme } from 'styled-components';
import { Col, Row } from 'components/Base/Grid';
import { Text } from 'components/Base/Typography';
import { TChildCareTotalCardProps } from './ChildCareTotalCard.types';
import { childCareKeyPrefix } from '../../ChildCareTab.data';

const ChildCareTotalCard = ({ data }: TChildCareTotalCardProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Col>
      <Card>
        <Row>
          <Text fontWeight={theme.fontWeightSemiBold} className="mr-1">
            {t(`${childCareKeyPrefix}.AGES_LABEL`)}
          </Text>
          <Text>{t(`enums.childcare-age-group.${data.ageGroup}`)}</Text>
        </Row>
        <Row>
          <Text fontWeight={theme.fontWeightSemiBold} className="mr-1">
            {t(`${childCareKeyPrefix}.COUNT_LABEL`)}
          </Text>
          <Text>{data.count}</Text>
        </Row>
      </Card>
    </Col>
  );
};

export default ChildCareTotalCard;
