import { FC, useState } from 'react';
import { Row, useTranslation } from '@packages/utils';
import { EIconNames } from 'components/Base/Icon';
import { useEditSiteCoordinatorMutation } from 'redux/apiSlice/workshops.slice';
import {
  useShowToastNotification,
  ENotificationType,
} from 'components/Base/Notification';
import { TRtkErrorType } from 'data/types/generic.types';
import EditableSelect from 'components/Base/EditableFields/EditableSelect';
import { InplaceEditLabels } from 'components/Base/EditableFields/components/InplaceEditLabel';
import { useGetPeopleIdentityQuery } from 'redux/apiSlice/people.slice';
import CreateCoordinatorModal from './CreateCoordinatorModal';
import { TCoordinatorProps } from './Coordinator.types';
import CoordinatorInfo from './CoordinatorInfo';
import { TCreateCoordinatorModalOpen } from './CreateCoordinatorModal/CreateCoordinatorModal.types';

const Coordinator: FC<TCoordinatorProps> = ({
  coordinator,
  id,
  setCoordinatorId,
  loading,
}) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.workshops.details.basic-info.coordinator',
  });
  const { showToastNotification } = useShowToastNotification();
  const { data: peopleIdentityOptions } = useGetPeopleIdentityQuery();
  const [editSiteCoordinator, { isLoading, isError }] =
    useEditSiteCoordinatorMutation();

  const [open, setOpen] = useState<TCreateCoordinatorModalOpen>({
    visible: false,
    initialName: '',
  });
  const onEdit = async (value: string) => {
    try {
      await editSiteCoordinator({
        id,
        value,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (e) {
      showToastNotification({
        message: (e as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  const onAddNewOption = (value: string) => {
    setOpen({
      initialName: value,
      visible: true,
    });
  };

  return (
    <>
      <Row>
        <InplaceEditLabels label={t('LABEL')} icon={EIconNames.COORDINATOR} />
        <EditableSelect
          onEdit={onEdit}
          options={peopleIdentityOptions}
          placeholder={t('PLACEHOLDER')}
          addNewOption={{
            addButtonLabel: t('ADD'),
            onClick: currentTypedValue =>
              onAddNewOption(currentTypedValue || ''),
            enableAddButton: true,
          }}
          {...(coordinator?.firstName && {
            title: {
              label: coordinator.fullName,
              value: coordinator.id,
            },
          })}
          emptyText={t('EMPTY_TEXT')}
          isLoading={isLoading || loading}
          isError={isError}
          readonly={coordinator?.allowedOperations.readonly}
          canRemove={coordinator?.allowedOperations.canRemove}
          tooltip={
            coordinator?.id ? (
              <CoordinatorInfo
                phone={coordinator.phone}
                email={coordinator.email}
                popoverTitle={t('POPOVER_TITLE')}
              />
            ) : null
          }
        />
      </Row>
      <CreateCoordinatorModal
        open={open}
        setOpen={setOpen}
        label={t('MODAL_LABEL')}
        setCoordinatorId={setCoordinatorId}
      />
    </>
  );
};

export default Coordinator;
