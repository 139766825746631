import { TCreateConverter } from '@packages/utils';
import { TFile } from 'data/types/generic.types';
import {
  TDeleteUploadedResourceDB,
  TDeleteUploadedResourceUI,
  TUploadedResourceUI,
} from 'data/types/storage.types';

export const uploadedResourceConverter: TCreateConverter<
  TUploadedResourceUI,
  TFile
> = {
  toDb: data => ({
    name: data.name,
    url: data.url,
  }),
};

export const deleteUploadedResourceConverter: TCreateConverter<
  TDeleteUploadedResourceUI,
  TDeleteUploadedResourceDB
> = {
  toDb: data => ({
    files: data.files.map(file => uploadedResourceConverter.toDb(file)),
  }),
};
