import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation, Button } from '@packages/utils';
import { EIconNames } from 'components/Base/Icon';
import {
  EWorkshopFilterParams,
  TWorkshopDataUI,
} from 'data/types/workshops.types';
import { Table } from 'components/Base/Table';
import {
  useGetWorkshopCitiesQuery,
  useGetWorkshopHostsQuery,
  useGetWorkshopLocationQuery,
  useGetWorkshopsQuery,
} from 'redux/apiSlice/workshops.slice';
import useTable from 'components/Base/Table/hooks/useTable';
import CardContent from 'components/Base/Layout/Content/CardContent';
import PageWrapper from 'components/Base/PageWrapper';
import { ETableStoragePaths } from 'components/Base/Table/Table.types';
import { TBackButtonState } from 'components/Base/PageWrapper/PageWrapper.types';
import { TRtkErrorType } from 'data/types/generic.types';
import { ETagType } from 'data/types/tags.types';
import { useGetTagsListQuery } from 'redux/apiSlice/tags.slice';
import { useCheckPermission } from 'hooks/useCheckPermission';
import CreateWorkshopModal from '../CreateWorkshop';
import useGetColumnsData from './WorkshopList.data';

const WorkshopList = () => {
  const { defaultColumns } = useGetColumnsData();
  const {
    page,
    pageSize,
    sortColumn,
    setPageSize,
    handleTableChange,
    selectedFilters,
    hasSelectedFilter,
    hasFilters,
    columns,
    setColumns,
    onFiltersReset,
  } = useTable<TWorkshopDataUI>({
    storagePath: ETableStoragePaths.WORKSHOPS,
    defaultColumns,
  });

  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.workshops.list',
  });

  const navigate = useNavigate();
  const [openCreateWorkshopModal, setOpenCreateWorkshopModal] =
    useState<boolean>(false);

  const { data, isFetching, isLoading, error } = useGetWorkshopsQuery({
    page,
    pageSize,
    sortColumn,
    filters: selectedFilters,
  });

  const { data: citiesFilters } = useGetWorkshopCitiesQuery();
  const { data: locationFilters } = useGetWorkshopLocationQuery();

  const { data: hostsFilters } = useGetWorkshopHostsQuery();

  const { data: tagsFilters } = useGetTagsListQuery(ETagType.WORKSHOP);

  useEffect(() => {
    setColumns(
      columns.map(item => {
        if (item.key === EWorkshopFilterParams.HOST) {
          item.filters = hostsFilters;
        }
        if (item.key === EWorkshopFilterParams.CITY) {
          item.filters = citiesFilters;
        }
        if (item.key === EWorkshopFilterParams.LOCATION) {
          item.filters = locationFilters;
        }
        if (item.key === EWorkshopFilterParams.TAGS) {
          item.filters = tagsFilters;
        }
        return item;
      }),
    );
  }, [citiesFilters, hostsFilters, tagsFilters, locationFilters]);

  const navigateToWorkshopDetails = (record: TWorkshopDataUI) => {
    return {
      onClick: () => {
        navigate(record.workshopId, {
          state: {
            backUrl: window.location.pathname,
            backButtonSuffix: t('TITLE'),
          } as TBackButtonState,
        });
      },
    };
  };
  const isEmpty = !data?.content.length && !hasSelectedFilter && !isFetching;

  const canAddWorkshop = useCheckPermission('WORKSHOP_ADD');

  return (
    <>
      <PageWrapper
        title={t('TITLE')}
        {...(canAddWorkshop && {
          actions: (
            <Button
              onClick={() => setOpenCreateWorkshopModal(true)}
              color="primary"
              type="primary"
            >
              {t('actions.create-workshop.CREATE_WORKSHOP_BTN')}
            </Button>
          ),
        })}
        isLoading={isLoading}
        error={error as TRtkErrorType}
        emptyWrapper={{
          isEmpty,
          alt: 'Handbag',
          icon: EIconNames.HANDBAG,
          ...(canAddWorkshop && { btnText: t('empty-view.BTN_TEXT') }),
          emptyText: t('empty-view.EMPTY_TEXT'),
          onClick: () => setOpenCreateWorkshopModal(true),
        }}
      >
        <CardContent>
          <Table<TWorkshopDataUI>
            onFiltersReset={onFiltersReset}
            hasFilters={hasFilters}
            rowKey={(record: TWorkshopDataUI) => record.workshopId}
            currentPage={page}
            columns={columns}
            dataSource={data?.content}
            rowClassName="pointer"
            onRow={navigateToWorkshopDetails}
            loading={isFetching}
            pageSize={pageSize}
            setPageSize={setPageSize}
            totalDataCount={data?.totalElements}
            onChange={handleTableChange}
            storagePath={ETableStoragePaths.WORKSHOPS}
          />
        </CardContent>
      </PageWrapper>
      {openCreateWorkshopModal && (
        <CreateWorkshopModal
          open={openCreateWorkshopModal}
          setOpen={setOpenCreateWorkshopModal}
        />
      )}
    </>
  );
};

export default WorkshopList;
