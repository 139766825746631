import {
  useTranslation,
  Col,
  Row,
  useShowToastNotification,
  ENotificationType,
  TRtkErrorType,
} from '@packages/utils';
import { useTheme } from 'styled-components';
import { EIconNames, Icon } from 'components/Base/Icon';
import { useDeleteElcMeetingActionItemMutation } from 'redux/apiSlice/elcs.slice';
import { TRemoveActionItemProps } from './RemoveActionItem.types';

const RemoveActionItem = ({
  id,
  elcId,
  isNew,
  meetingId,
}: TRemoveActionItemProps) => {
  const theme = useTheme();
  const { showToastNotification } = useShowToastNotification();
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.meetings-tab.details.action-items.actions.delete',
  });

  const [deleteActionItem, { isLoading }] =
    useDeleteElcMeetingActionItemMutation();

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    if (isNew) return;
    if (isLoading) {
      e.preventDefault();
      return;
    }
    try {
      await deleteActionItem({
        elcId,
        meetingId,
        actionItemId: id,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (err) {
      showToastNotification({
        message: (err as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  return (
    <Row justify="center">
      {!isNew && (
        <Col onClick={handleSubmit}>
          <Icon
            icon={EIconNames.DELETE_OUTLINED}
            color={theme.colorBlackIconColor}
            className="pointer"
            tooltip={{ title: t('DELETE_TOOLTIP') }}
          />
        </Col>
      )}
    </Row>
  );
};

export default RemoveActionItem;
