import { FC, useState } from 'react';
import { Col, Row } from 'components/Base/Grid';
import { Title } from 'components/Base/Typography';
import { useTranslation } from '@packages/utils';
import { QuestionContentWrapper } from '../QuestionTab.styles';
import { TQuestionViewProps } from '../QuestionTab.types';
import EditAction from '../actions/EditAction/EditAction';
import DeleteAction from '../actions/DeleteAction/DeleteAction';
import { TRANSLATION_KEY } from '../QuestionTab.data';
import EditQuestionModal from '../modals/EditQuestionModal/EditQuestionModal';
import DeleteQuestionModal from '../modals/DeleteQuestionModal/DeleteQuestionModal';

const QuestionView: FC<TQuestionViewProps> = ({ question, workshopId }) => {
  const { t } = useTranslation({
    keyPrefix: `${TRANSLATION_KEY}.view`,
  });

  const [questionIsHovered, setQuestionHover] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleOnEditQuestion = () => {
    setOpenEditModal(true);
  };
  const handleOnDelete = () => {
    setOpenDeleteModal(true);
  };
  return (
    <Row gutter={[0, 8]}>
      <Col>
        <Title level={5}>{t('TITLE')}</Title>
      </Col>
      <Col span={24}>
        <QuestionContentWrapper
          onMouseEnter={() => {
            setQuestionHover(true);
          }}
          onMouseLeave={() => {
            setQuestionHover(false);
          }}
        >
          {question.value}
          {questionIsHovered && (
            <Row gutter={[8, 0]} className="h-100" align="middle">
              <EditAction
                canEdit={!question.allowedOperations.readonly}
                onClick={handleOnEditQuestion}
              />
              <DeleteAction
                canDelete={!!question.allowedOperations.canRemove}
                onClick={handleOnDelete}
              />
            </Row>
          )}
        </QuestionContentWrapper>
        <EditQuestionModal
          open={openEditModal}
          setModalOpen={setOpenEditModal}
          question={question.value}
          workshopId={workshopId}
        />
        <DeleteQuestionModal
          open={openDeleteModal}
          setModalOpen={setOpenDeleteModal}
          workshopId={workshopId}
        />
      </Col>
    </Row>
  );
};
export default QuestionView;
