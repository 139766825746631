import { TCreateConverter, TFetchConverter } from 'data/types/converter.types';
import {
  EFamilyFilterParams,
  TEditFamilyDB,
  TEditFamilyUI,
  TFamilyDataDB,
  TFamilyDataUi,
} from 'data/types/family.types';
import { TTableFilters } from 'data/types/generic.types';
import { phoneNumberConverter } from './general.converters';
import { workshopSessionDateConverter } from './workshops.convertors';
import { tagsGetConverter } from './tags.convertor';

export const getFamilyConvertor: TFetchConverter<TFamilyDataUi, TFamilyDataDB> =
  {
    fromDb: data => {
      return {
        registrantId: data.registrantId,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone: phoneNumberConverter.fromDb(data.phone) || '',
        childrenNames: data.childrenNames,
        workshopClassId: data.workshopClassId,
        workshopId: data.workshopId,
        workshopType: data.workshopType,
        ageGroup: data.ageGroup,
        sessionDates: workshopSessionDateConverter.fromDb(data.sessionDates),
        location: data.location,
        tags: tagsGetConverter.fromDb(data.tags),
      };
    },
  };

export const familyFilterConverter = (
  filters: TTableFilters<EFamilyFilterParams>['filters'],
) => {
  const filtersToDB = {
    ...(filters?.firstName && {
      [EFamilyFilterParams.FIRST_NAME]: filters.firstName.toString().trim(),
    }),
    ...(filters?.lastName && {
      [EFamilyFilterParams.LAST_NAME]: filters.lastName.toString().trim(),
    }),
    ...(filters?.email && {
      [EFamilyFilterParams.EMAIL]: filters.email.toString().trim(),
    }),
    ...(filters?.phone && {
      [EFamilyFilterParams.MOBILE]: filters.phone.toString().trim(),
    }),
    ...(filters?.locationIds && {
      [EFamilyFilterParams.LOCATION]: filters?.locationIds,
    }),
    ...(filters?.workshopTypes && {
      [EFamilyFilterParams.TYPE]: filters?.workshopTypes.map(type => type),
    }),
    ...(filters?.tagIds && {
      [EFamilyFilterParams.TAGS]: filters?.tagIds,
    }),
  };
  return filtersToDB;
};

export const familyUpdateConverter: TCreateConverter<
  TEditFamilyUI,
  TEditFamilyDB
> = {
  toDb: data => ({
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    phone: phoneNumberConverter.toDb(data.phone) || '',
    registrantId: data.registrantId,
    workshopClassId: data.workshopClassId,
    workshopId: data.workshopId,
  }),
};
