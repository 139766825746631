import { FC } from 'react';
import { MenuGlobalStyle, SMenu, SMenuItem } from './Menu.styles';
import { TMenuProps, MenuItemProps } from './Menu.types';

export const Menu: FC<TMenuProps> = props => {
  return (
    <>
      <MenuGlobalStyle />
      <SMenu {...props} />
    </>
  );
};

export const MenuItem: FC<MenuItemProps> = props => {
  return (
    <>
      <MenuGlobalStyle />
      <SMenuItem {...props} />
    </>
  );
};
