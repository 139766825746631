import { Form, FormItem, TextArea } from '@packages/utils';
import { Select } from 'components/Base/Select';
import useGetFormData from './AddDetailsForm.data';
import { TAddDetailsFormProps } from './AddDetailsForm.types';

const AddDetailsForm = ({ form, categories }: TAddDetailsFormProps) => {
  const { addDetailsFormFields } = useGetFormData();
  const { category, note } = addDetailsFormFields;

  return (
    <Form
      form={form}
      initialValues={{
        category: null,
        note: '',
      }}
    >
      <FormItem {...category}>
        <Select options={categories} placeholder={category.placeholder} />
      </FormItem>
      <FormItem {...note}>
        <TextArea
          placeholder={note.placeholder}
          className="mb-4"
          maxLength={350}
          showCount
        />
      </FormItem>
    </Form>
  );
};

export default AddDetailsForm;
