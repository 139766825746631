import { useTranslation } from '@packages/utils';
import { SPhotoUpload } from './PhotoUpload.styles';
import { PhotoUploadProps } from './PhotoUpload.types';

const PhotoUpload = ({ ...props }: PhotoUploadProps) => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.generic-components.photo-upload.modal',
  });
  const {
    modalTitle = t('TITLE'),
    modalOkText = t('SUBMIT_BTN'),
    modalCancelText = t('CANCEL_BTN'),
    ...restProps
  } = props;

  return (
    <SPhotoUpload
      modalTitle={modalTitle}
      modalOkText={modalOkText}
      modalCancelText={modalCancelText}
      {...restProps}
    />
  );
};

export default PhotoUpload;
