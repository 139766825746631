import { useState } from 'react';
import { useTranslation, Button } from '@packages/utils';
import { useTheme } from 'styled-components';
import { EIconNames, Icon } from 'components/Base/Icon';
import { TActionsProps } from '../Actions.types';
import EditDetailsModal from './EditDetailsModal';

const EditAction = ({ organizationId, note }: TActionsProps) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation({
    keyPrefix: 'descriptions.tooltip',
  });

  return (
    <>
      <Button
        icon={
          <Icon
            alt="Edit icon"
            icon={EIconNames.EDIT_FILLED}
            color={theme.colorPinkActive}
            size={16}
            className="pointer"
          />
        }
        tooltip={t('EDIT')}
        color="primary"
        onClick={() => setOpen(true)}
        shape="circle"
        size="middle"
      />
      {open && (
        <EditDetailsModal
          setOpen={setOpen}
          open={open}
          organizationId={organizationId}
          note={note}
        />
      )}
    </>
  );
};

export default EditAction;
