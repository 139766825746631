import { pagableDataConverter } from 'data/convertors/general.converters';
import {
  peoplesGetConverter,
  peopleFilterConverter,
  peopleUpdateConverter,
  peopleCreateConverter,
  peopleGetConverter,
  getPeopleByIdConverter,
  updatePeopleFirstNameConverter,
  updatePeopleLastNameConverter,
  updatePeopleTitleConverter,
  updatePeopleEmailConverter,
  updatePeopleMobileConverter,
  addAdditionalEmailConverter,
  addAdditionalPhoneConverter,
  addPeopleOrganizationConverter,
  updateAdditionalPhoneConverter,
  updateAdditionalEmailConverter,
  deletePeopleOrganizationConverter,
  updatePeopleOrganizationTitleConverter,
} from 'data/convertors/people.convertors';
import {
  TPageableDataWithContentDB,
  TPageableDataWithContentUI,
  TTableFilters,
} from 'data/types/generic.types';
import {
  EPeoplesColumnKey,
  TAddAdditionalEmailUI,
  TAddAdditionalPhoneUI,
  TAddPeopleOrganizationUI,
  TDeletePeopleOrganizationUI,
  TPeopleByIdDB,
  TPeopleByIdUI,
  TPeopleCreateUI,
  TPeopleGetDB,
  TPeopleGetUI,
  TPeopleUpdateUI,
  TPeoplesDataDB,
  TPeoplesDataUI,
  TUpdateAdditionalEmailUI,
  TUpdateAdditionalPhoneUI,
  TUpdatePeopleEmailUI,
  TUpdatePeopleFirstNameUI,
  TUpdatePeopleLastNameUI,
  TUpdatePeopleMobileUI,
  TUpdatePeopleOrganizationTitleUI,
  TUpdatePeopleTitleUI,
} from 'data/types/people.types';
import { EPeopleUrls } from 'data/urls/people.url';
import { authSplitApi } from 'redux/helpers/slice.helpers';
import { workshopsApi } from './workshops.slice';
import { userGrowApi } from './userGrow.slice'; // eslint-disable-line
import { elcsApi } from './elcs.slice';
import { organizationsApi } from './organizations.slice';

export const peopleApi = authSplitApi('peoples', [
  'site-coordinators',
  'peoples',
]).injectEndpoints({
  endpoints: build => ({
    getPeoplesList: build.query<
      TPageableDataWithContentUI<TPeoplesDataUI[]>,
      TTableFilters<EPeoplesColumnKey>
    >({
      query({
        page,
        pageSize,
        sortColumn: { sortDirection, sortField },
        filters,
      }) {
        return {
          url: `${EPeopleUrls.BASE}`,
          method: 'GET',
          params: {
            page,
            size: pageSize,
            ...(sortField && sortDirection && { sortField, sortDirection }),
            ...(filters && { ...peopleFilterConverter(filters) }),
          },
        };
      },
      providesTags: ['peoples'],
      transformResponse: (
        data: TPageableDataWithContentDB<TPeoplesDataDB[]>,
      ) => {
        return {
          ...pagableDataConverter.fromDb(data),
          content: (data.content || []).map(workshop =>
            peoplesGetConverter.fromDb(workshop),
          ),
        };
      },
    }),
    updatePeople: build.mutation<string, TPeopleUpdateUI>({
      query(body) {
        return {
          url: `${EPeopleUrls.BASE}`,
          method: 'PUT',
          body: peopleUpdateConverter.toDb(body),
        };
      },
      invalidatesTags: ['site-coordinators', 'peoples'],
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(workshopsApi.util.invalidateTags(['workshop']));
            dispatch(elcsApi.util.invalidateTags(['elcs-contacts']));
            dispatch(elcsApi.util.invalidateTags(['contacts-identity']));
            dispatch(elcsApi.util.invalidateTags(['elac-contacts-identity']));
          })
          .catch(() => {
            // do nothing
          });
      },
    }),
    createPeople: build.mutation<string, TPeopleCreateUI>({
      query(body) {
        return {
          url: `${EPeopleUrls.BASE}`,
          method: 'POST',
          body: peopleCreateConverter.toDb(body),
        };
      },
      invalidatesTags: ['site-coordinators', 'peoples'],
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(userGrowApi.util.invalidateTags(['user-identity']));
            dispatch(elcsApi.util.invalidateTags(['contacts-identity']));
            dispatch(elcsApi.util.invalidateTags(['elac-contacts-identity']));
          })
          .catch(() => {
            // do nothing
          });
      },
    }),
    getPeopleById: build.query<TPeopleByIdUI, { userId: string }>({
      query({ userId }) {
        return {
          url: `${EPeopleUrls.BASE}/${userId}`,
          method: 'GET',
        };
      },
      providesTags: ['people-detail'],
      transformResponse: (response: TPeopleByIdDB) =>
        response && getPeopleByIdConverter.fromDb(response),
    }),
    getPeopleIdentity: build.query<TPeopleGetUI[], void>({
      query() {
        return {
          url: `${EPeopleUrls.BASE}/${EPeopleUrls.IDENTITY}`,
          method: 'GET',
        };
      },
      providesTags: ['site-coordinators'],
      transformResponse: (data: TPeopleGetDB[]) => {
        return (data || []).map(item => peopleGetConverter.fromDb(item));
      },
    }),
    updatePeopleFirstName: build.mutation<void, TUpdatePeopleFirstNameUI>({
      query(body) {
        return {
          url: `${EPeopleUrls.BASE}/${EPeopleUrls.FIRST_NAME}`,
          method: 'PATCH',
          body: updatePeopleFirstNameConverter.toDb(body),
        };
      },
      invalidatesTags: ['people-detail', 'peoples'],
    }),
    updatePeopleLastName: build.mutation<void, TUpdatePeopleLastNameUI>({
      query(body) {
        return {
          url: `${EPeopleUrls.BASE}/${EPeopleUrls.LAST_NAME}`,
          method: 'PATCH',
          body: updatePeopleLastNameConverter.toDb(body),
        };
      },
      invalidatesTags: ['people-detail', 'peoples'],
    }),
    updatePeopleTitle: build.mutation<void, TUpdatePeopleTitleUI>({
      query(body) {
        return {
          url: `${EPeopleUrls.BASE}/${EPeopleUrls.TITLE}`,
          method: 'PATCH',
          body: updatePeopleTitleConverter.toDb(body),
        };
      },
      invalidatesTags: ['people-detail', 'peoples'],
    }),
    updatePeopleEmail: build.mutation<void, TUpdatePeopleEmailUI>({
      query(body) {
        return {
          url: `${EPeopleUrls.BASE}/${EPeopleUrls.EMAIL}`,
          method: 'PATCH',
          body: updatePeopleEmailConverter.toDb(body),
        };
      },
      invalidatesTags: ['people-detail', 'peoples'],
    }),
    updatePeopleMobile: build.mutation<void, TUpdatePeopleMobileUI>({
      query(body) {
        return {
          url: `${EPeopleUrls.BASE}/${EPeopleUrls.PHONE}`,
          method: 'PATCH',
          body: updatePeopleMobileConverter.toDb(body),
        };
      },
      invalidatesTags: ['people-detail', 'peoples'],
    }),
    updateAdditionalPhone: build.mutation<void, TUpdateAdditionalPhoneUI>({
      query(body) {
        return {
          url: `${EPeopleUrls.BASE}/${EPeopleUrls.ADDITIONAL_PHONE}`,
          method: 'PATCH',
          body: updateAdditionalPhoneConverter.toDb(body),
        };
      },
      invalidatesTags: ['people-detail'],
    }),
    updateAdditionalEmail: build.mutation<void, TUpdateAdditionalEmailUI>({
      query(body) {
        return {
          url: `${EPeopleUrls.BASE}/${EPeopleUrls.ADDITIONAL_EMAIL}`,
          method: 'PATCH',
          body: updateAdditionalEmailConverter.toDb(body),
        };
      },
      invalidatesTags: ['people-detail'],
    }),
    updatePeopleOrganizationTitle: build.mutation<
      void,
      TUpdatePeopleOrganizationTitleUI
    >({
      query(body) {
        return {
          url: `${EPeopleUrls.BASE}/${EPeopleUrls.PEOPLE_ORGANIZATIONS}/${EPeopleUrls.TITLE}`,
          method: 'PATCH',
          body: updatePeopleOrganizationTitleConverter.toDb(body),
        };
      },
      invalidatesTags: ['people-detail'],
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(organizationsApi.util.invalidateTags(['organization']));
          })
          .catch(() => {
            // do nothing
          });
      },
    }),
    addAdditionalEmail: build.mutation<string, TAddAdditionalEmailUI>({
      query(body) {
        return {
          url: `${EPeopleUrls.BASE}/${EPeopleUrls.ADDITIONAL_EMAIL}`,
          method: 'POST',
          body: addAdditionalEmailConverter.toDb(body),
        };
      },
      invalidatesTags: ['people-detail'],
    }),
    addAdditionalPhone: build.mutation<string, TAddAdditionalPhoneUI>({
      query(body) {
        return {
          url: `${EPeopleUrls.BASE}/${EPeopleUrls.ADDITIONAL_PHONE}`,
          method: 'POST',
          body: addAdditionalPhoneConverter.toDb(body),
        };
      },
      invalidatesTags: ['people-detail'],
    }),
    addPeopleOrganizations: build.mutation<string, TAddPeopleOrganizationUI>({
      query(body) {
        return {
          url: `${EPeopleUrls.BASE}/${EPeopleUrls.PEOPLE_ORGANIZATIONS}`,
          method: 'POST',
          body: addPeopleOrganizationConverter.toDb(body),
        };
      },
      invalidatesTags: ['people-detail'],
      onQueryStarted(arg, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(organizationsApi.util.invalidateTags(['organization']));
          })
          .catch(() => {
            // do nothing
          });
      },
    }),
    deletePeopleOrganization: build.mutation<void, TDeletePeopleOrganizationUI>(
      {
        query(body) {
          return {
            url: `${EPeopleUrls.BASE}/${EPeopleUrls.PEOPLE_ORGANIZATIONS}`,
            method: 'DELETE',
            body: deletePeopleOrganizationConverter.toDb(body),
          };
        },
        invalidatesTags: ['people-detail'],
        onQueryStarted(arg, { dispatch, queryFulfilled }) {
          queryFulfilled
            .then(() => {
              dispatch(organizationsApi.util.invalidateTags(['organization']));
            })
            .catch(() => {
              // do nothing
            });
        },
      },
    ),
  }),
});

export const {
  useCreatePeopleMutation,
  useGetPeoplesListQuery,
  useUpdatePeopleMutation,
  useGetPeopleIdentityQuery,
  useGetPeopleByIdQuery,
  useUpdatePeopleFirstNameMutation,
  useUpdatePeopleLastNameMutation,
  useUpdatePeopleTitleMutation,
  useUpdatePeopleEmailMutation,
  useUpdatePeopleMobileMutation,
  useAddAdditionalEmailMutation,
  useAddAdditionalPhoneMutation,
  useAddPeopleOrganizationsMutation,
  useUpdateAdditionalPhoneMutation,
  useUpdateAdditionalEmailMutation,
  useDeletePeopleOrganizationMutation,
  useUpdatePeopleOrganizationTitleMutation,
} = peopleApi;
