import { TAreaServedSectionProps } from './AreaServedSection.types';
import ZipCodes from './ZipCodes';

const AreaServedSection = ({
  elcBoundaries,
  elcId,
}: TAreaServedSectionProps) => {
  return <ZipCodes elcBoundaries={elcBoundaries} elcId={elcId} />;
};

export default AreaServedSection;
