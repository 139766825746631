import { FC, useState } from 'react';
import { get } from 'lodash';
import { useTranslation, Button } from '@packages/utils';
import { useTheme } from 'styled-components';
import { EIconNames, Icon } from 'components/Base/Icon';
import {
  useShowToastNotification,
  ENotificationType,
} from 'components/Base/Notification';
import { Modal } from 'components/Base/Modal';
import ConfirmModalBody from 'components/Shared/ConfirmModalBody';
import { useUnsubscribeActionMutation } from 'redux/apiSlice/subscibers.slice';
import { TUnsubscribeActionProps } from './UnsubscribeAction.types';

const UnsubscribeAction: FC<TUnsubscribeActionProps> = ({ id }) => {
  const theme = useTheme();
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.subscribers.list.actions.unsubscribe',
  });
  const { showToastNotification } = useShowToastNotification();
  const [openUnsubscribeModal, setOpenUnsubscribeModal] =
    useState<boolean>(false);

  const [unsubscribe, { isLoading }] = useUnsubscribeActionMutation();

  const handleCLoseModal = () => {
    setOpenUnsubscribeModal(false);
  };
  const handleSubmit = async () => {
    try {
      await unsubscribe({
        workshopSubscriberId: id,
      }).unwrap();
      showToastNotification({
        message: t('modal.SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (e) {
      const message = get(e, 'data.message');
      showToastNotification({
        message,
        type: ENotificationType.ERROR,
      });
    }
    handleCLoseModal();
  };

  return (
    <>
      <Button
        icon={
          <Icon
            icon={EIconNames.UNSUBSCRIBE_FILLED}
            size={14}
            className="pointer"
            color={theme.colorErrorDefault}
          />
        }
        tooltip={t('UNSUBSCRIBE_TOOLTIP')}
        onClick={() => setOpenUnsubscribeModal(true)}
        color="primary"
        shape="circle"
        size="middle"
      />
      <Modal
        title={t('modal.TITLE')}
        open={openUnsubscribeModal}
        onCancel={handleCLoseModal}
        onOk={handleSubmit}
        okText={t('modal.SUBMIT_BTN')}
        cancelText={t('modal.CANCEL_BTN')}
        confirmLoading={isLoading}
      >
        <ConfirmModalBody
          title={t('modal.SUBTITLE')}
          description={t('modal.DESCRIPTION')}
        />
      </Modal>
    </>
  );
};

export default UnsubscribeAction;
