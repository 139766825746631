import { Paragraph, Row, useTranslation } from '@packages/utils';
import { TColumnType } from 'components/Base/Table/Table.types';
import {
  EMeetingsColumnKey,
  TElcDetailUI,
  TMeetingsUI,
} from 'data/types/elcs.types';
import RemoveMeeting from './MeetingsActions/RemoveMeeting';
import EditMeeting from './MeetingsActions/EditMeeting';

const useGetData = (elcId: TElcDetailUI['elcId'], isEditable?: boolean) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.meetings-tab.list.table.title',
  });

  const defaultColumns: TColumnType<TMeetingsUI>[] = [
    {
      title: t('MEETING'),
      dataIndex: 'title',
      key: 'title',
      onCell: () => ({
        style: {
          maxWidth: '200px',
        },
      }),
      render: text => (
        <Paragraph
          ellipsis={{
            tooltip: text,
          }}
        >
          {text}
        </Paragraph>
      ),
    },
    {
      title: t('MEETING_DATE'),
      dataIndex: 'date',
      sorter: true,
      key: EMeetingsColumnKey.DATE,
    },
    {
      title: t('LAST_UPDATE'),
      dataIndex: 'lastUpdateDate',
      key: 'lastUpdateDate',
      ellipsis: true,
    },
    ...(isEditable
      ? [
          {
            title: t('ACTIONS'),
            key: 'actions',
            dataIndex: 'actions',
            fixed: 'right',
            width: 120,
            onCell: () => ({
              style: { minWidth: '120px' },
              onClick: e => {
                e.stopPropagation();
              },
            }),
            render: (text, record: TMeetingsUI) => (
              <Row justify="center" gutter={[12, 12]} wrap={false}>
                <EditMeeting
                  initialValues={{ date: record.date, title: record.title }}
                  elcId={elcId}
                  meetingId={record.meetingId}
                />
                <RemoveMeeting elcId={elcId} meetingId={record.meetingId} />
              </Row>
            ),
          } as TColumnType<TMeetingsUI>,
        ]
      : []),
  ];
  return { defaultColumns };
};

export default useGetData;
