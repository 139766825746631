import { FC } from 'react';
import { useTranslation } from '@packages/utils';
import { useTheme } from 'styled-components';
import { Text } from 'components/Base/Typography';
import { Col } from 'components/Base/Grid';
import { EWorkshopPartType } from 'data/types/workshops.types';
import { TSessionDateItemProps } from './SessionDate.types';

const SessionDateItem: FC<TSessionDateItemProps> = ({ date, partType }) => {
  const theme = useTheme();
  const { t } = useTranslation({
    keyPrefix: 'enums.workshop-part-type-short',
  });

  const dateDisplayValue = date || '-';
  if (partType === EWorkshopPartType.STANDALONE) {
    return <>{date}</>;
  }
  const partTypeDisplayValue = t(partType);
  return (
    <Col span={24}>
      <Text fontWeight={theme.fontWeightSemiBold}>
        {partTypeDisplayValue}:{'  '}
        <Text fontWeight={theme.fontWeightRegular}>{dateDisplayValue}</Text>
      </Text>
    </Col>
  );
};

export default SessionDateItem;
