import { Card, Col, Row, Paragraph, useTranslation } from '@packages/utils';
import { InplaceEditLabels } from 'components/Base/EditableFields/components/InplaceEditLabel';
import RemoveTATime from '../RemoveTATime';
import { TTATimeItemProps } from './TATimeItem.types';

const TATimeItem = ({ data, elcId }: TTATimeItemProps) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.meetings-tab.details.ta-time.add.form',
  });

  const { duration, focus, meetingId, pillarName, taTimeId } = data;

  return (
    <Card className="mb-3">
      <Row align="middle">
        <Col span={9}>
          <InplaceEditLabels label={t('pillar.LABEL')} />
          <Paragraph>{pillarName}</Paragraph>
        </Col>
        <Col span={9}>
          <InplaceEditLabels label={t('focus.LABEL')} />
          <Paragraph>{focus}</Paragraph>
        </Col>
        <Col span={4}>
          <InplaceEditLabels label={t('time.LABEL')} />
          <Paragraph>
            {duration} {t('time.MIN')}
          </Paragraph>
        </Col>
        <Col span={2}>
          <RemoveTATime
            taTimeId={taTimeId}
            elcId={elcId}
            meetingId={meetingId}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default TATimeItem;
