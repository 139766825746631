import { useState } from 'react';
import { useTranslation } from '@packages/utils';
import DetailsEmptyView from 'components/Base/DetailsEmptyView/DetailsEmptyView';
import AddDetailsModal from '../AddDetailsModal';
import { addDetailsSectionKey } from '../AddDetailsSection.data';
import { TAddDetailsEmptyViewProps } from './AddDetailsEmptyView.types';

const AddDetailsEmptyView = ({
  organizationId,
  isAllowedToAdd,
  categories,
}: TAddDetailsEmptyViewProps) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation({
    keyPrefix: `${addDetailsSectionKey}.empty-view`,
  });
  if (!isAllowedToAdd) return null;

  return (
    <>
      <DetailsEmptyView
        title={t('TITLE')}
        onClick={() => setOpen(true)}
        addBtnText={t('ADD_BTN')}
      />
      {categories?.length && (
        <AddDetailsModal
          open={open}
          categories={categories}
          setOpen={setOpen}
          organizationId={organizationId}
        />
      )}
    </>
  );
};

export default AddDetailsEmptyView;
