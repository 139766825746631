import { Title, useTranslation, Row } from '@packages/utils';
import { TAdditionalContactsProps } from './AdditionalContacts.types';
import AdditionalMobile from './components/AdditionalMobile';
import AdditionalEmail from './components/AdditionalEmail';

const AdditionalContacts = ({ data }: TAdditionalContactsProps) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.organizations.details.details-tab.additional-contacts',
  });
  const { id, additionalPhones, additionalEmails } = data;
  return (
    <>
      <Row className="w-100 mt-6 mb-3">
        <Title level={4}>{t('LABEL')}</Title>
      </Row>
      <>
        {additionalPhones?.value?.map(item => (
          <AdditionalMobile
            key={item.additionalPhoneId}
            organizationId={id}
            allowedActions={additionalPhones.allowedOperations}
            data={item}
          />
        ))}
        {additionalEmails?.value?.map(item => (
          <AdditionalEmail
            key={item.additionalEmailId}
            organizationId={id}
            allowedActions={additionalPhones.allowedOperations}
            data={item}
          />
        ))}
      </>
    </>
  );
};

export default AdditionalContacts;
