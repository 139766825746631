import { ECapacityEmptyTexts } from 'data/types/workshops.types';
import { useTranslation } from '@packages/utils';

export const capacityTextKey =
  'descriptions.admin-portal.workshops.capacity-text';

export const useTranslateCapacityShowValue = (
  capacityShowValue: ECapacityEmptyTexts | undefined,
) => {
  const { t: tCapacity } = useTranslation({
    keyPrefix: capacityTextKey,
  });

  return capacityShowValue &&
    Object.values(ECapacityEmptyTexts).includes(capacityShowValue)
    ? tCapacity(capacityShowValue)
    : capacityShowValue;
};
