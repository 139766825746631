import { useState, useEffect } from 'react';
import { isEqual } from 'lodash';
import { useTranslation } from '@packages/utils';
import { useTheme } from 'styled-components';
import useForm from 'hooks/useForm';
import PageWrapper from 'components/Base/PageWrapper';
import { Row, Col } from 'components/Base/Grid';
import { Paragraph } from 'components/Base/Typography';
import { Image } from 'components/Base/Image';
import Spinner from 'components/Base/Spinner';
import CardContent from 'components/Base/Layout/Content/CardContent';
import {
  useGetLightBoxQuery,
  useUpdateLightBoxMutation,
} from 'redux/apiSlice/lightbox.slice';
import { TRtkErrorType } from 'data/types/generic.types';
import {
  ENotificationType,
  useShowToastNotification,
} from 'components/Base/Notification';
import PublishToggle from './LightboxActions/PublishToggle';
import LightBoxForm from './LightboxForm';
import { SLightBoxCardWrapper } from './LightBox.styles';
import SaveAction from './LightboxActions/SaveAction';
import { TUpdateLightBoxFormUI } from './LightboxForm/LightboxForm.types';

const LightBox = () => {
  const theme = useTheme();
  const { data, isLoading, error } = useGetLightBoxQuery();
  const [selectedImage, setSelectedImage] = useState<string>('');
  const [firstSectionText, setFirstSectionText] = useState<string>('');
  const [secondSectionText, setSecondSectionText] = useState<string>('');
  const { form } = useForm<TUpdateLightBoxFormUI>();
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.lightBox',
  });
  const { showToastNotification } = useShowToastNotification();
  const [updateWorkshopMaintainer, { isLoading: updateIsLoading }] =
    useUpdateLightBoxMutation();

  const initialValues = {
    firstSection: data?.firstSection || '',
    secondSection: data?.secondSection || '',
    picture: data?.picture || '',
  };
  useEffect(() => {
    if (!data) {
      return;
    }
    setSelectedImage(data.picture);
  }, [data]);

  const currentData = form.getFieldsValue();

  if (!data) {
    return null;
  }

  const handleSubmit = async () => {
    try {
      const val = await form.validateFields();
      await updateWorkshopMaintainer({
        id: data.id,
        ...val,
      }).unwrap();
      showToastNotification({
        message: t('actions.save.SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (err) {
      showToastNotification({
        message: (err as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  return (
    <PageWrapper
      title={t('TITLE')}
      actions={
        <PublishToggle published={data.published} lightboxId={data.id} />
      }
      error={error as TRtkErrorType}
    >
      <CardContent contentPadding="16px">
        {isLoading ? (
          <Spinner spinnerSize={16} />
        ) : (
          <Row gutter={[16, 16]}>
            <Col sm={8}>
              <Paragraph fontWeight={theme.fontWeightMedium}>
                {t('preview.LABEL')}
              </Paragraph>
              <SLightBoxCardWrapper>
                <Image
                  src={selectedImage || data.picture}
                  alt=""
                  className="mb-4"
                  aria-hidden="true"
                />
                <Paragraph
                  className="text-center"
                  fontWeight={theme.fontWeightMedium}
                  color={theme.colorPinkActive}
                >
                  {firstSectionText || data.firstSection}
                </Paragraph>
                <Paragraph className="text-center my-4">
                  {secondSectionText || data.secondSection}
                </Paragraph>
                <Paragraph className="text-center">
                  {t('preview.contact_content.CONTENT1')}{' '}
                  <a
                    href="https://readyforkindergartenidaho.org/"
                    className="word-wrap-break-word"
                  >
                    {t('preview.contact_content.WEBSITE')}{' '}
                  </a>
                  {t('preview.contact_content.CONTENT2')}
                </Paragraph>
              </SLightBoxCardWrapper>
              <SaveAction
                formHasChange={!isEqual(currentData, initialValues)}
                isLoading={updateIsLoading}
                handleSubmit={handleSubmit}
              />
            </Col>
            <Col sm={16}>
              <LightBoxForm
                form={form}
                initialValues={initialValues}
                selectedImage={selectedImage}
                setSelectedImage={setSelectedImage}
                setFirstSectionText={setFirstSectionText}
                setSecondSectionText={setSecondSectionText}
              />
            </Col>
          </Row>
        )}
      </CardContent>
    </PageWrapper>
  );
};

export default LightBox;
