import { useState } from 'react';
import { get } from 'lodash';
import {
  Button,
  ENotificationType,
  Form,
  FormItem,
  Modal,
  useForm,
  useShowToastNotification,
  useTranslation,
  filterLabelOption,
} from '@packages/utils';
import { TValidateFieldsError } from 'data/types/generic.types';
import { EIconNames, Icon } from 'components/Base/Icon';
import { Select } from 'components/Base/Select';
import { TUserIdentityUI } from 'data/types/user.types';
import {
  useGetELACContactsIdentityQuery,
  useUpdateELACMemberMutation,
} from 'redux/apiSlice/elcs.slice';
import {
  TAddELACMembersFormProps,
  TAddELACMembersProps,
} from './AddELACMembers.types';
import useGetFormData from './AddELACMemners.data';

const AddELACMembers = ({ elcId }: TAddELACMembersProps) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.elac-members-tab.list.actions.add-elac-member',
  });
  const { addELACMemberFormFields } = useGetFormData();
  const { contactId } = addELACMemberFormFields;
  const { showToastNotification } = useShowToastNotification();
  const { form } = useForm<TAddELACMembersFormProps>();
  const [open, setOpen] = useState<boolean>(false);
  const [updateELACMember, { isLoading }] = useUpdateELACMemberMutation();

  const handleClose = () => {
    setOpen(false);
    form.resetFields();
  };

  const { data: people } = useGetELACContactsIdentityQuery({
    elcId,
  });

  const peopleOptions = people?.map((item: TUserIdentityUI) => ({
    label: item.fullName,
    value: item.id,
  }));

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      await updateELACMember({
        elcId,
        isElacMember: true,
        ...values,
      }).unwrap();
      showToastNotification({
        message: t('modal.SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
      handleClose();
    } catch (err) {
      if (!(err as TValidateFieldsError)?.errorFields) {
        const message = get(err, 'data.message');
        showToastNotification({
          message,
          type: ENotificationType.ERROR,
        });
      }
    }
  };

  return (
    <>
      <Button
        color="primary"
        type="primary"
        onClick={() => setOpen(true)}
        icon={<Icon icon={EIconNames.PLUS} className="pointer" />}
      >
        {t('ADD_BTN')}
      </Button>
      <Modal
        title={t('modal.TITLE')}
        open={open}
        onCancel={handleClose}
        onOk={handleSubmit}
        centeredPosition
        okText={t('modal.SUBMIT_BTN')}
        cancelText={t('modal.CANCEL_BTN')}
        confirmLoading={isLoading}
      >
        <Form form={form} initialValues={{ isElacMember: false }}>
          <FormItem {...contactId} valuePropName="checked">
            <Select
              filterOption={filterLabelOption}
              showSearch
              placeholder={contactId.placeholder}
              options={peopleOptions}
            />
          </FormItem>
        </Form>
      </Modal>
    </>
  );
};

export default AddELACMembers;
