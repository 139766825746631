import { useTranslation } from '@packages/utils';
import useGetValidationData from 'components/Base/Form/validationRules';
import { addDetailsSectionKey } from '../../AddDetailsSection.data';

const useGetFormData = () => {
  const { t } = useTranslation({
    keyPrefix: `${addDetailsSectionKey}.modals.add.form`,
  });
  const { validationRules } = useGetValidationData();
  const addDetailsFormFields = {
    category: {
      name: 'category',
      label: t('category.LABEL'),
      placeholder: t('category.PLACEHOLDER'),
      rules: [validationRules.requiredField()],
    },
    note: {
      name: 'note',
      label: t('note.LABEL'),
      placeholder: t('note.PLACEHOLDER'),
      rules: [validationRules.requiredField()],
    },
  };

  return { addDetailsFormFields };
};

export default useGetFormData;
