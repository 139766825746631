import { useTranslation } from '@packages/utils';
import useGetValidationData from 'components/Base/Form/validationRules';

const useGetFormData = () => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.generic-actions.create-coordinator.modal.form',
  });
  const { validationRules } = useGetValidationData();
  const createCoordinatorFormFields = {
    firstName: {
      name: 'firstName',
      label: t('first-name.LABEL'),
      placeholder: t('first-name.PLACEHOLDER'),
      rules: [validationRules.requiredField()],
    },
    lastName: {
      name: 'lastName',
      label: t('last-name.LABEL'),
      placeholder: t('last-name.PLACEHOLDER'),
      rules: [validationRules.requiredField()],
    },
    title: {
      name: 'title',
      label: t('title.LABEL'),
      placeholder: t('title.PLACEHOLDER'),
    },
    email: {
      name: 'email',
      label: t('email.LABEL'),
      placeholder: t('email.PLACEHOLDER'),
      rules: [validationRules.requiredField(), validationRules.email()],
    },
    phone: {
      name: 'phone',
      label: t('mobile.LABEL'),
      placeholder: t('mobile.PLACEHOLDER'),
      rules: [validationRules.phoneNumber()],
    },
  };

  return { createCoordinatorFormFields };
};

export default useGetFormData;
