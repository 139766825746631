import { SContent, SLayout, SMainWrapper } from './Layout.styles';
import { TLayoutPops } from './Layout.types';
import Sidebar from './Sidebar';

const Layout = ({ children }: TLayoutPops) => {
  return (
    <SLayout>
      <Sidebar />
      <SMainWrapper>
        <SContent>{children}</SContent>
      </SMainWrapper>
    </SLayout>
  );
};

export default Layout;
