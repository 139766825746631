import { getTextFromI18n } from 'helpers/utils';
import { TAgeGroupColumnItem } from './AgeGroupesColumn.types';

export const collectAgeGroupColumnItemsInList = (
  ageGroupColumnItems: TAgeGroupColumnItem[],
): string[] => {
  if (!ageGroupColumnItems) return ['-'];
  return ageGroupColumnItems.map(el => {
    const ageGroup = getTextFromI18n(`enums.age-group.${el.ageGroup}`);
    const languageFirstLetter = el.language.slice(0, 1);
    const count = el.count > 1 ? `(${el.count})` : '';
    return `${languageFirstLetter}: ${ageGroup} ${count}`;
  });
};
