import { useTranslation } from '@packages/utils';
import { TEditableSelectProps } from './EditableSelect.types';
import { SEditableSelect } from './EditableSelect.styles';

const EditableSelect = (props: TEditableSelectProps) => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.generic-components.select',
  });
  const { emptyOptionsLabel = t('EMPTY_OPTIONS_LABEL'), ...restProps } = props;

  return (
    <SEditableSelect emptyOptionsLabel={emptyOptionsLabel} {...restProps} />
  );
};

export default EditableSelect;
