import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation, Button } from '@packages/utils';
import PageWrapper from 'components/Base/PageWrapper';
import { EIconNames } from 'components/Base/Icon';
import {
  useGetOrganizationsCitiesQuery,
  useGetOrganizationsListQuery,
} from 'redux/apiSlice/organizations.slice';
import useTable from 'components/Base/Table/hooks/useTable';
import CardContent from 'components/Base/Layout/Content/CardContent';
import {
  EOrganizationFilterParams,
  TOrganizationDataUI,
} from 'data/types/organizations.types';
import { ETableStoragePaths } from 'components/Base/Table/Table.types';
import { Table } from 'components/Base/Table';
import CreateOrganizationModal from 'components/Shared/CreateOrganizationModal';
import { TCreateOrganizationModalOpen } from 'components/Shared/CreateOrganizationModal/CreateOrganizationModal.types';
import { TBackButtonState } from 'components/Base/PageWrapper/PageWrapper.types';
import { TRtkErrorType } from 'data/types/generic.types';
import { useGetTagsListQuery } from 'redux/apiSlice/tags.slice';
import { ETagType } from 'data/types/tags.types';
import { useCheckPermission } from 'hooks/useCheckPermission';
import { RoutesPaths } from 'routes/Routes.types';
import { downloadCSV } from 'helpers/downloadCsv';
import { Flex } from 'antd';
import { Alert } from 'components/Base/Alert';
import { getFormattedDateMDY } from 'utils/dateUtils';
import useGetColumnsData, {
  organizationListKey,
} from './OrganizationList.data';
import { EOrganizationDetailsTabsInUrl } from '../OrganizationDetails/OrganizationDetails.types';

const OrganizationList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation({
    keyPrefix: organizationListKey,
  });
  const { defaultColumns } = useGetColumnsData();
  const {
    page,
    pageSize,
    sortColumn,
    setPageSize,
    handleTableChange,
    selectedFilters,
    hasSelectedFilter,
    columns,
    setColumns,
    hasFilters,
    onFiltersReset,
    selectedRows,
    setSelectedRows,
  } = useTable<TOrganizationDataUI>({
    storagePath: ETableStoragePaths.ORGANIZATION,
    defaultColumns,
  });

  const [showAlert, setShowAlert] = useState<boolean>(false);

  const onRowClick = (orgId: string) => {
    navigate(
      `/app/${RoutesPaths.ORGANIZATION}/${orgId}?tab=${EOrganizationDetailsTabsInUrl.DETAILS}`,
      {
        state: {
          backUrl: window.location.pathname,
          backButtonSuffix: t('TITLE'),
        } as TBackButtonState,
      },
    );
  };

  const [openCreateOrganizationModal, setOpenCreateOrganizationModal] =
    useState<TCreateOrganizationModalOpen>({
      visible: false,
      initialOrgName: '',
    });

  const { data, isFetching, isLoading, error } = useGetOrganizationsListQuery({
    page,
    pageSize,
    sortColumn,
    filters: selectedFilters,
  });

  const { data: tagsFilters } = useGetTagsListQuery(ETagType.ORGANIZATION);

  const { data: cities } = useGetOrganizationsCitiesQuery();

  const citiesFilters = useMemo(
    () =>
      cities?.map(city => ({
        text: city,
        value: city,
      })),
    [cities],
  );

  const isEmpty = !data?.content.length && !hasSelectedFilter && !isFetching;
  const canAddOrganization = useCheckPermission('ORGANIZATION_ADD');

  useEffect(() => {
    setColumns(
      columns.map(item => {
        if (item.key === EOrganizationFilterParams.CITIES) {
          item.filters = citiesFilters;
        }
        if (item.key === EOrganizationFilterParams.TAGS) {
          item.filters = tagsFilters;
        }
        return item;
      }),
    );
  }, [citiesFilters, tagsFilters]);

  useEffect(() => {
    if (selectedRows?.selectedRowsKeys?.length) {
      setShowAlert(false);
    }
  }, [selectedRows]);

  return (
    <>
      <PageWrapper
        title={t('TITLE')}
        actions={
          <>
            {canAddOrganization && (
              <Flex align="center" gap={16}>
                <Button
                  onClick={() =>
                    setOpenCreateOrganizationModal({
                      visible: true,
                    })
                  }
                  color="primary"
                  type="primary"
                >
                  {t('actions.add-organization.ADD_ORGANIZATION_BTN')}
                </Button>
                <Button
                  onClick={() => {
                    const { selectedRowsRecords } = selectedRows;

                    if (!selectedRowsRecords.length) {
                      setShowAlert(true);
                      return;
                    }

                    const selectedRowsTransformed = selectedRowsRecords.map(
                      row => {
                        if (row === undefined) return undefined;

                        const { name, address, tags } = row;

                        return {
                          name,
                          address: address?.formattedAddress,
                          tags: tags?.map(tag => tag.text).join(', '),
                        };
                      },
                    );

                    const formattedDate = getFormattedDateMDY();

                    downloadCSV(
                      ['Organization Name', 'Address', 'Tags'],
                      selectedRowsTransformed,
                      record => [
                        `"${record.name}"`,
                        `"${record.address}"`,
                        `"${record.tags}"`,
                      ],
                      `Organizations_${formattedDate}.csv`,
                    );
                  }}
                  color="primary"
                  type="primary"
                >
                  {t('actions.add-organization.EXPORT')}
                </Button>
              </Flex>
            )}
          </>
        }
        isLoading={isLoading}
        error={error as TRtkErrorType}
        emptyWrapper={{
          isEmpty,
          alt: 'People Group',
          icon: EIconNames.PEOPLE_GROUP_FILLED,
          btnText: t('empty-view.BTN_TEXT'),
          emptyText: t('empty-view.EMPTY_TEXT'),
          onClick: () =>
            setOpenCreateOrganizationModal({
              visible: true,
            }),
        }}
      >
        <CardContent>
          {showAlert && (
            <div className="mx-4" style={{ minHeight: '1px' }}>
              <Alert
                type="warning"
                closable
                message={t('table.ACTION_ALERT_MESSAGE')}
                className="mt-4"
                onClose={() => setShowAlert(false)}
              />
            </div>
          )}
          <Table<TOrganizationDataUI>
            rowKey={(record: TOrganizationDataUI) => record.organizationId}
            currentPage={page}
            columns={columns}
            dataSource={data?.content}
            rowClassName="pointer"
            loading={isFetching}
            pageSize={pageSize}
            setPageSize={setPageSize}
            totalDataCount={data?.totalElements}
            onChange={handleTableChange}
            hasFilters={hasFilters}
            onFiltersReset={onFiltersReset}
            onRow={(record: TOrganizationDataUI) => ({
              onClick: () => onRowClick(record.organizationId),
            })}
            storagePath={ETableStoragePaths.ORGANIZATION}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />
        </CardContent>
      </PageWrapper>
      <CreateOrganizationModal
        open={openCreateOrganizationModal}
        setOpen={setOpenCreateOrganizationModal}
      />
    </>
  );
};

export default OrganizationList;
