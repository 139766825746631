import { useNavigate } from 'react-router-dom';
import { RoutesPaths } from 'routes/Routes.types';
import { TBackButtonState } from 'components/Base/PageWrapper/PageWrapper.types';
import { useQuery } from './useQueryParam';

const useNavigateWithBackState = () => {
  const navigate = useNavigate();
  const query = useQuery();

  const getBackUrl = (): string => {
    const tabInUrl = query.get('tab');
    let backUrl = window.location.pathname;
    if (tabInUrl) {
      backUrl = `${backUrl}?tab=${tabInUrl}`;
    }
    return backUrl;
  };

  const goPeopleDetails = (userId: string, backButtonSuffix: string) => {
    const peopleDetailsPath = RoutesPaths.PEOPLE_DETAILS.replace(':id', userId);
    navigate(`/app/${peopleDetailsPath}`, {
      state: {
        backUrl: getBackUrl(),
        backButtonSuffix,
      } as TBackButtonState,
    });
  };

  const goOrganizationDetails = (
    organizationId: string,
    backButtonSuffix: string,
  ) => {
    const orgDetailsPath = RoutesPaths.ORGANIZATION_DETAILS.replace(
      ':id',
      organizationId,
    );
    navigate(`/app/${orgDetailsPath}`, {
      state: {
        backUrl: getBackUrl(),
        backButtonSuffix,
      } as TBackButtonState,
    });
  };

  const goWorkshopDetails = (workshopId: string, backButtonSuffix: string) => {
    const workshopDetailsPath = RoutesPaths.WORKSHOP_DETAILS.replace(
      ':id',
      workshopId,
    );
    navigate(`/app/${workshopDetailsPath}`, {
      state: {
        backUrl: getBackUrl(),
        backButtonSuffix,
      } as TBackButtonState,
    });
  };

  return { goPeopleDetails, goOrganizationDetails, goWorkshopDetails };
};

export default useNavigateWithBackState;
