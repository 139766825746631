import { useNavigate } from 'react-router-dom';
import { useTranslation } from '@packages/utils';
import { TMenuProps } from 'components/Base/Layout/Sidebar/Menu/Menu.types';
import { Icon, EIconNames } from 'components/Base/Icon';
import { useSignOutMutation } from 'redux/apiSlice/auth.slice';
import { RoutesPaths } from 'routes/Routes.types';
import useSidebarContext from 'context/CollapseSidebarContext';
import { Menu } from 'components/Base/Layout/Sidebar/Menu';
import { SSidebarFooterMenuWrapper } from './SidebarFooterMenu.styles';

const SidebarFooterMenu = () => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.sidebar',
  });
  const { collapsed } = useSidebarContext();
  const [signOut] = useSignOutMutation();
  const navigate = useNavigate();

  const handleLogout = async () => {
    await signOut().unwrap();
    navigate(`/${RoutesPaths.LOGIN}`);
  };

  const footerMenuItems: TMenuProps['items'] = [
    {
      label: t('LOG_OUT'),
      key: 'logout',
      icon: <Icon icon={EIconNames.LOGOUT} />,
      onClick: handleLogout,
    },
  ];

  return (
    <SSidebarFooterMenuWrapper>
      <Menu $collapsed={collapsed} className="w-100" items={footerMenuItems} />
    </SSidebarFooterMenuWrapper>
  );
};

export default SidebarFooterMenu;
