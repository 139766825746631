import { useState } from 'react';
import { get } from 'lodash';
import {
  Button,
  ENotificationType,
  Form,
  FormItem,
  Modal,
  useForm,
  useShowToastNotification,
  useTranslation,
  filterLabelOption,
} from '@packages/utils';
import { TValidateFieldsError } from 'data/types/generic.types';
import { EIconNames, Icon } from 'components/Base/Icon';
import { Select } from 'components/Base/Select';
import {
  useAddELCPlaceMutation,
  useGetELCPlacesIdentityQuery,
} from 'redux/apiSlice/elcs.slice';
import {
  TAddELCPlacesFormProps,
  TAddELCPlacesProps,
} from './AddELCPlaces.types';
import useGetFormData from './AddELCPlaces.data';

const AddELCPlaces = ({ elcId }: TAddELCPlacesProps) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.elc-places-tab.list.actions.add',
  });
  const { addELACMemberFormFields } = useGetFormData();
  const { organizationId } = addELACMemberFormFields;
  const { showToastNotification } = useShowToastNotification();
  const { form } = useForm<TAddELCPlacesFormProps>();
  const [open, setOpen] = useState<boolean>(false);
  const [addELCPlace, { isLoading }] = useAddELCPlaceMutation();

  const handleClose = () => {
    setOpen(false);
    form.resetFields();
  };

  const { data: places } = useGetELCPlacesIdentityQuery({
    elcId,
  });

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      await addELCPlace({
        elcId,
        ...values,
      }).unwrap();
      showToastNotification({
        message: t('modal.SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
      handleClose();
    } catch (err) {
      if (!(err as TValidateFieldsError)?.errorFields) {
        const message = get(err, 'data.message');
        showToastNotification({
          message,
          type: ENotificationType.ERROR,
        });
      }
    }
  };

  return (
    <>
      <Button
        color="primary"
        type="primary"
        onClick={() => setOpen(true)}
        icon={<Icon icon={EIconNames.PLUS} className="pointer" />}
      >
        {t('ADD_BTN')}
      </Button>
      <Modal
        title={t('modal.TITLE')}
        open={open}
        onCancel={handleClose}
        onOk={handleSubmit}
        centeredPosition
        okText={t('modal.SUBMIT_BTN')}
        cancelText={t('modal.CANCEL_BTN')}
        confirmLoading={isLoading}
      >
        <Form form={form}>
          <FormItem {...organizationId}>
            <Select
              filterOption={filterLabelOption}
              showSearch
              placeholder={organizationId.placeholder}
              options={places}
            />
          </FormItem>
        </Form>
      </Modal>
    </>
  );
};

export default AddELCPlaces;
