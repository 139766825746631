import {
  Col,
  ENotificationType,
  TRtkErrorType,
  useShowToastNotification,
  useTranslation,
} from '@packages/utils';
import { useEditElcMeetingTopicContextMutation } from 'redux/apiSlice/elcs.slice';
import EditableReachTextEditor from 'components/Base/EditableFields/EditableReachTextEditor';
import { InplaceEditLabels } from 'components/Base/EditableFields/components/InplaceEditLabel';
import { TContextProps } from './Context.types';

const Context = ({ elcId, meetingId, context, topicId }: TContextProps) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.meetings-tab.details.topics.edit.context',
  });
  const { showToastNotification } = useShowToastNotification();

  const [editElcMeetingTopicContext, { isLoading, isError }] =
    useEditElcMeetingTopicContextMutation();

  const onEdit = async (value: string) => {
    try {
      await editElcMeetingTopicContext({
        elcId,
        meetingId,
        topicId,
        context: value,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (e) {
      showToastNotification({
        message: (e as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  return (
    <>
      <Col className="ml-1">
        <InplaceEditLabels label={t('INPLACE_EDIT_LABEL')} />
      </Col>
      <Col className="mb-4">
        <EditableReachTextEditor
          title={context}
          fitContent={false}
          onEdit={onEdit}
          emptyText={t('EMPTY_TEXT')}
          placeholder={t('PLACEHOLDER')}
          readonly={false}
          isError={isError}
          isLoading={isLoading}
        />
      </Col>
    </>
  );
};

export default Context;
