import { styled } from 'styled-components';
import { Col } from 'components/Base/Grid';

export const STitleCol = styled(Col)<{ width: number }>`
  width: ${({ width }) => `${width}px`};
`;

export const SRightCol = styled(Col)`
  width: max-content;
`;

export const SWrapper = styled(Col)`
  width: 100%;
`;
