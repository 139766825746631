import { FC } from 'react';
import EditableInput from 'components/Base/EditableFields/EditableInput';
import { InplaceEditLabels } from 'components/Base/EditableFields/components/InplaceEditLabel';
import { EIconNames } from 'components/Base/Icon';
import { useUpdateOrganizationPhoneMutation } from 'redux/apiSlice/organizations.slice';
import {
  useShowToastNotification,
  ENotificationType,
} from 'components/Base/Notification';
import { TRtkErrorType } from 'data/types/generic.types';
import useGetValidationData from 'components/Base/Form/validationRules';
import { Col, Row, useTranslation } from '@packages/utils';
import { TUpdateOrganizationPhoneProps } from './UpdateOrganizationPhone.types';

const UpdateOrganizationPhone: FC<TUpdateOrganizationPhoneProps> = ({
  id,
  phone,
}) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.organizations.details.details-tab.main-contact.phone',
  });
  const { showToastNotification } = useShowToastNotification();

  const { validationRules } = useGetValidationData();

  const [updatePhone, { isLoading, isError }] =
    useUpdateOrganizationPhoneMutation();

  const onUpdatePhone = async (value: string) => {
    try {
      await updatePhone({
        id,
        value,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (e) {
      showToastNotification({
        message: (e as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };
  return (
    <Row align="middle" wrap={false} className="w-100">
      <Col>
        <InplaceEditLabels
          label={t('LABEL')}
          icon={EIconNames.PHONE_OUTLINED}
        />
      </Col>
      <Col flex="auto">
        <EditableInput
          onEdit={onUpdatePhone}
          title={phone?.value}
          isError={isError}
          isLoading={isLoading}
          fitContent={false}
          readonly={phone?.allowedOperations.readonly}
          canRemove={phone?.allowedOperations.canRemove}
          backIcon
          rules={[validationRules.phoneNumber()]}
          phoneInput
          validateTrigger="onSubmit"
          emptyText={t('EMPTY_TEXT')}
          placeholder={t('PLACEHOLDER')}
        />
      </Col>
    </Row>
  );
};

export default UpdateOrganizationPhone;
