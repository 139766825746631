import { useGetOrganizationDetailCategoriesQuery } from 'redux/apiSlice/organizations.slice';
import { useTranslation } from '@packages/utils';
import { TAddDetailsSectionProps } from './AddDetailsSection.types';
import AddDetailsEmptyView from './AddDetailsEmptyView';
import DetailsListView from './DetailsListView';
import { addDetailsSectionKey } from './AddDetailsSection.data';

const AddDetailsSection = ({ detailsData }: TAddDetailsSectionProps) => {
  const { t } = useTranslation({
    keyPrefix: `${addDetailsSectionKey}.notes-categories`,
  });

  const { data } = useGetOrganizationDetailCategoriesQuery(
    {
      organizationId: detailsData.id,
    },
    {
      skip: !detailsData,
    },
  );

  const categories = data?.map(cat => ({
    label: t(cat),
    value: cat,
  }));

  return (
    <>
      {detailsData.organizationNotes?.value?.length ? (
        <DetailsListView
          categories={categories}
          organizationId={detailsData.id}
          allowedOperations={detailsData.organizationNotes?.allowedOperations}
          organizationNotes={detailsData.organizationNotes}
        />
      ) : (
        <AddDetailsEmptyView
          isAllowedToAdd={
            detailsData.organizationNotes?.allowedOperations.canAdd
          }
          categories={categories}
          organizationId={detailsData.id}
        />
      )}
    </>
  );
};

export default AddDetailsSection;
