import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { get } from 'lodash';
import { useTranslation } from '@packages/utils';
import { EIconNames } from 'components/Base/Icon';
import { Modal } from 'components/Base/Modal';
import {
  useShowToastNotification,
  ENotificationType,
} from 'components/Base/Notification';
import { useDeleteOrganizationMutation } from 'redux/apiSlice/organizations.slice';
import { RoutesPaths } from 'routes/Routes.types';
import ConfirmModalBody from 'components/Shared/ConfirmModalBody';
import ActionGroupItemLabel from 'components/Shared/ActionsButtonDetailsPage/ActionGroupItemLabel';
import { TDeleteActionProps } from './DeleteAction.types';
import { organizationActionsKey } from '../../OrganizationDetails.data';

const DeleteAction: FC<TDeleteActionProps> = ({ id }) => {
  const { t } = useTranslation({
    keyPrefix: organizationActionsKey.concat('.delete'),
  });
  const { showToastNotification } = useShowToastNotification();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const [deleteOrganization, { isLoading }] = useDeleteOrganizationMutation();

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    if (!id) {
      handleClose();
      return;
    }
    try {
      await deleteOrganization({
        organizationId: id,
      }).unwrap();
      showToastNotification({
        message: t('confirm-modal.SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
      navigate(`/app/${RoutesPaths.ORGANIZATION}`);
    } catch (err) {
      const message = get(err, 'data.message');
      showToastNotification({
        message,
        type: ENotificationType.ERROR,
      });
    }
    handleClose();
  };

  return (
    <>
      <ActionGroupItemLabel
        onClick={() => setOpen(true)}
        label={t('DELETE_ORGANIZATION_BTN')}
        icon={EIconNames.REMOVE_PEOPLE_GROUP}
      />
      <Modal
        title={t('confirm-modal.TITLE')}
        open={open}
        onCancel={handleClose}
        onOk={handleSubmit}
        okText={t('confirm-modal.DELETE_BTN')}
        cancelText={t('confirm-modal.CANCEL_BTN')}
        confirmLoading={isLoading}
      >
        <ConfirmModalBody
          description={t('confirm-modal.CONFIRM_DESCRIPTION')}
          title={t('confirm-modal.CONFIRM_TITLE')}
        />
      </Modal>
    </>
  );
};

export default DeleteAction;
