import * as React from 'react';
import { useTheme } from 'styled-components';
import { Card, Col, Row } from '@packages/utils';
import { Text } from 'components/Base/Typography';
import { Divider } from 'components/Base/Divider';
import { Icon, EIconNames } from 'components/Base/Icon';
import { TAttendanceItemRowProps } from './AttendanceItemRow.types';
import { SRightCol, STitleCol, SWrapper } from './AttendanceItemRow.styles';
import { useGetHelpers } from '../../helpers';

export const AttendanceItemRow = ({
  isChecked,
  title,
  count,
  width,
  showOnlyCount,
  titleSuffix,
  className,
  isElearning,
}: TAttendanceItemRowProps) => {
  const theme = useTheme();
  const { getAttendanceTextColor } = useGetHelpers();
  const color = getAttendanceTextColor(!!isChecked);

  const rightCol = isChecked ? (
    <Row gutter={[12, 0]}>
      <Col>
        <Icon size={16} icon={EIconNames.CHECK} color={color} />
      </Col>
      {isElearning && (
        <Col>
          <Icon
            size={16}
            icon={EIconNames.VIDEOCAM}
            color={theme.colorPrimaryDefault}
          />
        </Col>
      )}
      <Col>
        <Text>{count}</Text>
      </Col>
    </Row>
  ) : (
    <Text> - </Text>
  );

  return (
    <SWrapper className={className}>
      <Card
        styles={{
          body: {
            padding: '0 8px',
            width: '100%',
          },
        }}
      >
        <Row gutter={[8, 0]} align="middle" wrap={false}>
          <STitleCol width={width}>
            <Text fontWeight={theme.fontWeightSemiBold}>
              {title}
              {titleSuffix && (
                <Text
                  fontWeight={theme.fontWeightSemiBold}
                  color={titleSuffix.color}
                  className="ml-1"
                >
                  {titleSuffix.text}
                </Text>
              )}
            </Text>
          </STitleCol>
          <Divider type="vertical" thickness={3} height={25} />
          <SRightCol>
            {showOnlyCount ? <Text> {count} </Text> : rightCol}
          </SRightCol>
        </Row>
      </Card>
    </SWrapper>
  );
};

export default AttendanceItemRow;
