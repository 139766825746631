export enum EUserType {
  READY_ADMIN = 'READY_ADMIN',
  SUPER_ADMIN = 'SUPER_ADMIN',
  ELC_PM = 'ELC_PM',
  SITE_COORDINATOR = 'SITE_COORDINATOR',
  ELC_LEAD = 'ELC_LEAD',
}

export type TPermissionsTypeDB =
  | 'WORKSHOP_EDIT'
  | 'WORKSHOP_VIEW'
  | 'WORKSHOP_ADD'
  | 'WORKSHOP_CHILDCARE_VIEW'
  | 'WORKSHOP_TAG_EDIT'
  | 'WORKSHOP_REGISTRANTS_VIEW'
  | 'WORKSHOP_WAIT_LIST_VIEW'
  | 'PART_ORDER_VIEW'
  | 'PART_ORDER_EDIT'
  | 'PARENT_ORDER_VIEW'
  | 'PARENT_ORDER_EDIT'
  | 'ORGANIZATION_EDIT'
  | 'ORGANIZATION_VIEW'
  | 'ORGANIZATION_ADD'
  | 'PEOPLE_EDIT'
  | 'PEOPLE_VIEW'
  | 'PEOPLE_ADD'
  | 'FAMILY_EDIT'
  | 'FAMILY_VIEW'
  | 'WAIT_LIST_VIEW'
  | 'WAIT_LIST_EDIT'
  | 'SUBSCRIBERS_VIEW'
  | 'SUBSCRIBERS_EDIT'
  | 'SUBSCRIBERS_ADD'
  | 'TEAM_EDIT'
  | 'ADMIN_LIST_VIEW'
  | 'ADMIN_USER_ADD'
  | 'USER_EDIT'
  | 'USER_VIEW'
  | 'USER_DELETE'
  | 'LIGHT_BOX_EDIT'
  | 'LIGHT_BOX_VIEW'
  | 'TAG_VIEW'
  | 'TAG_EDIT'
  | 'ELC_LIST_VIEW'
  | 'ELC_LIST_UPDATE'
  | 'MY_ELC_LIST_VIEW'
  | 'ELC_DETAILS_EDIT'
  | 'ELC_DETAILS_VIEW'
  | 'ELC_BOUNDARY_EDIT'
  | 'ELC_CONTACTS_VIEW'
  | 'ELC_CONTACTS_EDIT'
  | 'ELC_PLACES_VIEW'
  | 'ELC_PLACES_EDIT'
  | 'MEETING_VIEW'
  | 'MEETING_EDIT'
  | 'ELC_RESOURCE_VIEW'
  | 'ELC_RESOURCE_EDIT';

export type TPermissionsTypeUI = TPermissionsTypeDB | 'SECURITY_VIEW';

export type TUserListDataUI = {
  createdDate: string;
  email: string;
  fullName: string;
  firstName: string;
  lastName: string;
  phone?: string | null;
  adminId: string;
};

export type TUserListDataDB = {
  createdDate: string;
  email: string;
  fullName: string;
  firstName: string;
  lastName: string;
  phone?: string | null;
  userId: string;
};

// export type TAddNewUserUI = {
//   firstName: string;
//   lastName: string;
//   email: string;
//   phone: string;
//   profileType: EUserType;
// };

// export type TAddNewUserDB = {
//   firstName: string;
//   lastName: string;
//   email: string;
//   phone: string;
//   profileType: EUserType;
// };

export type TUserDataUi = {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  profilePicture: string | null;
  type: EUserType[];
  permissions: TPermissionsTypeUI[];
  userId: string;
} | null;

export type TUserDataDB = {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  profilePicture: string | null;
  type: EUserType[];
  permissions: TPermissionsTypeDB[];
  userId: string;
};

export type TDeleteUserUI = {
  userId: string;
};

export type TDeleteUserDB = {
  userId: string;
};

export type TUpdateUserUI = {
  userId: string;
  firstName: string;
  lastName: string;
  phone: string;
};

export type TUpdateUserDB = {
  userId: string;
  firstName: string;
  lastName: string;
  phone: string;
};

export type TUserIdentityUI = {
  id: string;
  fullName: string;
  email: string;
};

export type TUserIdentityDB = {
  id: string;
  fullName: string;
  email: string;
};

export type TAddNewUserUI = {
  userId: string;
  profileType: EUserType;
};

export type TAddNewUserDB = {
  userId: string;
  profileType: EUserType;
};
