import { useTranslation } from '@packages/utils';
import { useTheme } from 'styled-components';

export const useGetHelpers = () => {
  const theme = useTheme();
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.workshops.details.workshop-registrants.orientation-kit',
  });

  const getAttendanceTextColor = (isChecked: boolean) => {
    switch (isChecked) {
      case true:
        return theme.colorPrimaryDefault;
      case false:
        return theme.colorPinkActive;
      default:
        return '';
    }
  };

  const getOrientationKitRequestedText = (isRequested: boolean) =>
    t(
      isRequested
        ? 'requested-labels.REQUESTED'
        : 'requested-labels.NOT_REQUESTED',
    );

  return {
    getOrientationKitRequestedText,
    getAttendanceTextColor,
  };
};

export default useGetHelpers;
