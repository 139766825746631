import { useEffect, useState } from 'react';
import { Button, useTranslation } from '@packages/utils';
import { useGetElcMeetingNotesQuery } from 'redux/apiSlice/elcs.slice';
import { EIconNames, Icon } from 'components/Base/Icon';
import { TNotesTabProps } from './NotesTab.types';
import NoteItem from './components/NoteItem';
import AddNote from './AddNote';

const NotesTab = ({ elcId, meetingId }: TNotesTabProps) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.meetings-tab.details.notes',
  });
  const { data } = useGetElcMeetingNotesQuery(
    {
      meetingId,
    },
    {
      skip: !meetingId,
    },
  );

  const [hideForm, setHideForm] = useState<boolean>(true);

  useEffect(() => {
    if (!data) {
      return;
    }
    if (data.length > 0) {
      setHideForm(true);
    } else {
      setHideForm(false);
    }
  }, [data]);

  if (!data) {
    return <></>;
  }

  return (
    <>
      {hideForm ? (
        <Button
          color="primary"
          type="link"
          className="pl-0 mb-4"
          onClick={() => setHideForm(false)}
          icon={<Icon icon={EIconNames.PLUS} className="pointer" />}
        >
          {t('add.ADD_BTN')}
        </Button>
      ) : (
        <AddNote elcId={elcId} meetingId={meetingId} />
      )}
      {data.map(item => (
        <NoteItem
          key={item.noteId}
          elcId={elcId}
          meetingId={item.meetingId}
          note={item.note}
          noteId={item.noteId}
        />
      ))}
    </>
  );
};

export default NotesTab;
