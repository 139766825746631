import { FC } from 'react';
import { useTheme } from 'styled-components';
import { Col, Divider, Row, Title, useTranslation } from '@packages/utils';
import CardContent from 'components/Base/Layout/Content/CardContent';
import { TDetailsTabProps } from './DetailsTab.types';
import MainContact from './DetailsTabInfoSection/MainContact';
import AddDetailsSection from './AddDetailsSection';
import AddInfo from './DetailsTabInfoSection/AddInfo';
import AdditionalContacts from './DetailsTabInfoSection/AdditionalContacts';
import PeopleOrganizations from './DetailsTabInfoSection/PeopleOrganizations';

const DetailsTab: FC<TDetailsTabProps> = ({ detailsData }) => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.organizations.details.details-tab',
  });
  const theme = useTheme();

  if (!detailsData) {
    return null;
  }
  const { additionalEmails, additionalPhones, userOrganizations, id } =
    detailsData;

  const displayAdditionalSection =
    !!additionalEmails.value?.length || !!additionalPhones.value?.length;

  return (
    <CardContent contentPadding="16px" tabContent>
      <Row justify="space-between">
        <Col>
          <Title level={3}>{t('TITLE')}</Title>
        </Col>
        <Col>
          <AddInfo
            allowActionsEmail={additionalEmails.allowedOperations}
            allowActionsMobile={additionalPhones.allowedOperations}
            organizationId={id}
          />
        </Col>
      </Row>
      <Row gutter={[12, 12]} className="my-6">
        <Col sm={11}>
          <Row>
            <MainContact detailsData={detailsData} />
            {displayAdditionalSection && (
              <AdditionalContacts data={detailsData} />
            )}
          </Row>
        </Col>
        <Col sm={1}>
          <Divider
            type="vertical"
            color={theme.colorBlackMainBorder}
            style={{ height: '100%' }}
          />
        </Col>
        <Col sm={12}>
          <PeopleOrganizations
            userOrganizations={userOrganizations}
            organizationId={id}
            organizationName={detailsData.name.value}
          />
        </Col>
      </Row>
      <AddDetailsSection detailsData={detailsData} />
    </CardContent>
  );
};

export default DetailsTab;
