import { FC, useEffect, useState } from 'react';
import EditableTextArea from 'components/Base/EditableFields/EditableTextArea';
import { useEditElcMeetingActionItemSummaryMutation } from 'redux/apiSlice/elcs.slice';
import {
  useShowToastNotification,
  ENotificationType,
} from 'components/Base/Notification';
import { TextArea } from 'components/Base/Input';
import { TRtkErrorType } from 'data/types/generic.types';
import { useTranslation } from '@packages/utils';
import { TActionItemTitleProps } from './ActionItemTitle.types';

const ActionItemTitle: FC<TActionItemTitleProps> = ({
  id,
  title,
  isNew,
  setNewRowData,
  elcId,
  meetingId,
}) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.meetings-tab.details.action-items.table.editable-cell.task',
  });
  const { showToastNotification } = useShowToastNotification();

  const [value, setValue] = useState(title);

  useEffect(() => {
    if (title === undefined) return;
    setValue(title);
  }, [title]);

  const [updateName, { isLoading, isError }] =
    useEditElcMeetingActionItemSummaryMutation();

  const onUpdateName = async (val: string) => {
    try {
      await updateName({
        actionItemId: id,
        elcId,
        meetingId,
        title: val,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (e) {
      showToastNotification({
        message: (e as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  const onUpdateNewRowTitle = (val: string) => {
    setValue(val);
    setNewRowData(prev => ({ ...prev, title: val }));
  };
  if (isNew) {
    return (
      <TextArea
        value={value}
        onChange={e => {
          onUpdateNewRowTitle(e.target.value);
        }}
        placeholder={t('PLACEHOLDER')}
        autoSize
        autoFocus
      />
    );
  }
  return (
    <EditableTextArea
      autoSize
      fitContent={false}
      wrapperClassName="word-break"
      onEdit={onUpdateName}
      title={value}
      isError={isError}
      isLoading={isLoading}
      emptyText={t('PLACEHOLDER')}
    />
  );
};

export default ActionItemTitle;
