import { useState } from 'react';
import { useTheme } from 'styled-components';
import { Button, useTranslation } from '@packages/utils';
import { TSectionProps } from 'components/Base/Layout/Content/SectionsContent/SectionsContent.types';
import SectionsContent from 'components/Base/Layout/Content/SectionsContent';
import { Icon, EIconNames } from 'components/Base/Icon';
import BasicInfo from './BasicInfo';
import Description from './Description';
import { TDetailsTabProps } from './DetailsTab.types';
import Sessions from './Sessions';

const DetailsTab = ({ data, id, isFetching }: TDetailsTabProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [showForm, setShowForm] = useState<boolean>(false);

  if (!data) {
    return <></>;
  }

  const showAction =
    !showForm &&
    data.classes.allowedOperations.canAdd &&
    !!data.classes.value?.length;

  const sectionsInfo: TSectionProps[] = [
    {
      id: 1,
      title: t(`enums.workshop-type.${data.type}`),
      content: (
        <BasicInfo
          workshop={data}
          id={id}
          isFetchingWorkshopData={isFetching}
        />
      ),
    },
    {
      id: 2,
      title: t('descriptions.admin-portal.workshops.details.description.LABEL'),
      content: <Description id={id} description={data?.description} />,
    },
    {
      id: 3,
      title: t('descriptions.admin-portal.workshops.details.sessions.TITLE'),
      ...(showAction && {
        actions: (
          <Button
            color="primary"
            type="primary"
            size="middle"
            icon={
              <Icon
                size={11}
                color={theme.colorWhitePrimary}
                icon={EIconNames.PLUS}
                alt="plus icon"
              />
            }
            onClick={() => setShowForm(true)}
          >
            {t(
              'descriptions.admin-portal.workshops.details.sessions.CREATE_SESSION_BTN',
            )}
          </Button>
        ),
      }),
      content: (
        <Sessions
          showForm={showForm}
          setShowForm={setShowForm}
          id={id}
          workshop={data}
          isWorkshopDataFetching={isFetching}
        />
      ),
    },
  ];

  return <SectionsContent sections={sectionsInfo} />;
};

export default DetailsTab;
