import { useCheckPermission } from 'hooks/useCheckPermission';
import OrganizationDetails from 'pages/private/Organization/OrganizationDetails';
import OrganizationList from 'pages/private/Organization/OrganizationList';
import { RoutesPaths, SubRouteType } from 'routes/Routes.types';

const useGetOrganizationSubRoutesData = () => {
  const organizationSubRoutes: SubRouteType[] = [
    {
      component: OrganizationList,
      path: RoutesPaths.ORGANIZATION,
      show: false,
      showByPermissions: useCheckPermission('ORGANIZATION_VIEW'),
    },
    {
      component: OrganizationDetails,
      path: RoutesPaths.ORGANIZATION_DETAILS,
      show: false,
      showByPermissions: useCheckPermission('ORGANIZATION_VIEW'),
    },
  ];
  return { organizationSubRoutes };
};

export default useGetOrganizationSubRoutesData;
