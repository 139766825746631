import { FC } from 'react';
import { useAppSelector } from 'redux/store';
import { useTranslation } from '@packages/utils';
import { useTheme } from 'styled-components';
import { Row, Col } from 'components/Base/Grid';
import { Text } from 'components/Base/Typography';
import { TAddressProps } from './Address.types';

const Address: FC<TAddressProps> = ({ title }) => {
  const theme = useTheme();
  const flyerLanguage = useAppSelector(state => state.appSlice.flyerLanguage);
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.workshops.details.actions.download-flyer.flyer',
    ...(flyerLanguage && { lng: flyerLanguage }),
  });
  return (
    <Row wrap={false} align="middle">
      <Col>
        <Text
          fontSize={theme.fontSizeHeading2}
          fontWeight={theme.fontWeightSemiBold}
          color={theme.colorGreenFlyer}
        >
          {t('ADDRESS')}
        </Text>
      </Col>
      <Col className="ml-2">
        <Text
          fontWeight={theme.fontWeightRegular}
          fontSize={theme.fontSizeHeading2}
          color={theme.colorBlackPrimary}
        >
          {title}
        </Text>
      </Col>
    </Row>
  );
};

export default Address;
