import { useTheme } from 'styled-components';
import { Row, Col } from 'components/Base/Grid';
import { Image } from 'components/Base/Image';
import { Paragraph } from 'components/Base/Typography';
import questionMarkIcon from 'assets/images/questionMarkIcon.svg';
import { TConfirmModalBodyProps } from './ConfirmModalBody.types';

const ConfirmModalBody = ({
  description,
  imgSrc,
  alt,
  title,
}: TConfirmModalBodyProps) => {
  const theme = useTheme();
  return (
    <Row className="p-4 text-center">
      <Col span={24}>
        <Image
          src={imgSrc || questionMarkIcon}
          alt={alt || 'questionmark icon'}
          className="mb-4 text-center"
        />
      </Col>
      {title && (
        <Col span={24}>
          <Paragraph
            fontWeight={theme.fontWeightMedium}
            color={theme.colorPinkActive}
          >
            {title}
          </Paragraph>
        </Col>
      )}
      <Col span={24}>
        <Paragraph>{description}</Paragraph>
      </Col>
    </Row>
  );
};

export default ConfirmModalBody;
