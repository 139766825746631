import { FC } from 'react';
import { TSessionDatesProps } from './SessionDates.types';
import SessionDateItem from './SessionDateItem';

const SessionDates: FC<TSessionDatesProps> = ({ sessionDates }) => {
  if (sessionDates.every(({ date }) => date === null)) {
    return <> - </>;
  }
  return (
    <>
      {sessionDates.map(({ date, partType }) => (
        <SessionDateItem key={partType} date={date} partType={partType} />
      ))}
    </>
  );
};

export default SessionDates;
