import { FC, useEffect, useState } from 'react';
import { dateFormatUI } from '@idaho-aeyc/ui-kit';
import { useEditElcMeetingActionItemDueDateMutation } from 'redux/apiSlice/elcs.slice';
import {
  useShowToastNotification,
  ENotificationType,
} from 'components/Base/Notification';
import { TRtkErrorType } from 'data/types/generic.types';
import { useTranslation } from '@packages/utils';
import EditableDatePicker from 'components/Base/EditableFields/EditableDatePicker';
import { TActionItemDueDateProps } from './ActionItemDueDate.types';

const ActionItemDueDate: FC<TActionItemDueDateProps> = ({
  id,
  dueDate,
  isNew,
  setNewRowData,
  elcId,
  meetingId,
}) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.meetings-tab.details.action-items.table.editable-cell.due-date',
  });
  const { showToastNotification } = useShowToastNotification();

  const [value, setValue] = useState(dueDate);

  useEffect(() => {
    if (dueDate === undefined) return;
    setValue(dueDate);
  }, [dueDate]);

  const [updateDueDate, { isLoading, isError }] =
    useEditElcMeetingActionItemDueDateMutation();

  const onUpdateDate = async (val: string) => {
    if (isNew) {
      setValue(val);
      setNewRowData(prev => ({
        ...prev,
        dueDate: val,
      }));
      return;
    }
    try {
      await updateDueDate({
        actionItemId: id,
        elcId,
        meetingId,
        dueDate: val,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (e) {
      showToastNotification({
        message: (e as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };
  return (
    <EditableDatePicker
      title={value}
      format={dateFormatUI}
      onEdit={onUpdateDate}
      canRemove
      emptyText={t('TOOLTIP')}
      showNow
      isLoading={isLoading}
      isError={isError}
    />
  );
};

export default ActionItemDueDate;
