import {
  useTranslation,
  Col,
  Row,
  useShowToastNotification,
  ENotificationType,
  TRtkErrorType,
} from '@packages/utils';
import { useTheme } from 'styled-components';
import { EIconNames, Icon } from 'components/Base/Icon';
import { useDeleteContactMutation } from 'redux/apiSlice/elcs.slice';
import { TRemoveContactProps } from './RemoveContact.types';

const RemoveContact = ({ contactId, elcId }: TRemoveContactProps) => {
  const theme = useTheme();
  const { showToastNotification } = useShowToastNotification();
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.contacts-tab.list.actions.delete',
  });
  const [deleteContact, { isLoading }] = useDeleteContactMutation();

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    if (isLoading) {
      e.preventDefault();
    }
    try {
      await deleteContact({
        elcId,
        contactId,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (err) {
      showToastNotification({
        message: (err as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  return (
    <Row justify="center">
      <Col onClick={handleSubmit}>
        <Icon
          icon={EIconNames.DELETE_OUTLINED}
          color={theme.colorBlackIconColor}
          className="pointer"
          tooltip={{ title: t('DELETE_TOOLTIP') }}
        />
      </Col>
    </Row>
  );
};

export default RemoveContact;
