import { FC, memo } from 'react';
import { Row, Col } from 'components/Base/Grid';
import { EWorkshopStatus } from 'data/types/workshops.types';
import { TActionsProps } from './Actions.types';
import DeleteAction from './DeleteAction';
import ActionButton from './ActionButton';
import ClassCapacity from '../ClassCapacity';
import CopySessionAttendanceUrl from './CopySessionAttendanceUrl';

const Actions: FC<TActionsProps> = ({
  classItem,
  workshopId,
  allowedOperations,
  onDuplicate,
  workshopStatus,
  registrantsCount,
}) => {
  const hasOnePart = classItem.sessions.length === 1;
  return (
    <Row gutter={[16, 16]} wrap={false} justify="end">
      {allowedOperations.canRemove && (
        <Col>
          <DeleteAction classItem={classItem} workshopId={workshopId} />
        </Col>
      )}
      {allowedOperations.canAdd && (
        <Col>
          <ActionButton
            type="duplicate"
            onClick={() => onDuplicate(classItem)}
          />
        </Col>
      )}
      {workshopStatus && workshopStatus !== EWorkshopStatus.PLANNING && (
        <Row align="middle">
          <Col>
            <ClassCapacity
              classId={classItem.id}
              workshopId={workshopId}
              capacity={classItem.capacity}
              registrantsCount={registrantsCount}
            />
          </Col>
          {hasOnePart && (
            <Col>
              <CopySessionAttendanceUrl
                sessionId={classItem.sessions[0].id}
                workshopId={workshopId}
                workshopStatus={workshopStatus}
              />
            </Col>
          )}
        </Row>
      )}
    </Row>
  );
};

export default memo(Actions);
