import { useTranslation } from '@packages/utils';
import useGetValidationData from 'components/Base/Form/validationRules';

export const keyPrefix =
  'descriptions.admin-portal.organizations.list.actions.add-organization.modal.form';

const useGetFormData = () => {
  const { validationRules } = useGetValidationData();
  const { t } = useTranslation({
    keyPrefix,
  });
  const createOrganizationFormFields = {
    name: {
      name: 'name',
      label: t('organization-name.LABEL'),
      placeholder: t('organization-name.PLACEHOLDER'),
      rules: [validationRules.requiredField()],
    },
    address: {
      name: 'address',
      placeholder: t('address.PLACEHOLDER'),
      rules: [validationRules.requiredField(), validationRules.address()],
    },
  };

  const initialValues = {
    name: '',
    address: '',
  };
  return { createOrganizationFormFields, initialValues };
};

export default useGetFormData;
