import { FC, useEffect, useState } from 'react';
import { TAddressTypesUI } from 'data/types/generic.types';
import EditableFieldWrapper from '../components/EditableFieldWrapper';
import EditableAddressForm from './components/EditableAddressForm';
import { TEditableAddressProps } from './EditableAddress.types';

export const EditableAddress: FC<TEditableAddressProps> = props => {
  const {
    onEdit,
    title: defaultValue,
    placeholder,
    required,
    emptyText,
    canRemove,
    readonly,
    errorMessage,
    isError,
    rules,
    wrapperClassName,
    isLoading,
    ellipsis,
    minWidth,
    tooltip,
    fitContent,
  } = props;
  const [value, setValue] = useState(defaultValue);
  const [isEditable, setIsEditable] = useState(false);

  const onChange = (newValue: TAddressTypesUI | null) => {
    setIsEditable(false);
    if (!canRemove && !newValue) {
      setValue(defaultValue);
      return;
    }
    if (defaultValue !== newValue) {
      if (newValue === null) {
        onEdit(null);
        return;
      }
      setValue(newValue.formattedAddress);
      // @ts-ignore
      if (newValue.target && !newValue.target.value) {
        setValue(defaultValue);
      }
      onEdit(newValue);
    }
  };

  useEffect(() => {
    if (isError) {
      setValue(defaultValue);
    }
  }, [isError]);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <EditableFieldWrapper
      readonly={readonly}
      isEditable={isEditable}
      emptyText={emptyText}
      value={value}
      setIsEditable={setIsEditable}
      isLoading={isLoading}
      wrapperClassName={wrapperClassName}
      errorMessage={errorMessage}
      ellipsis={ellipsis}
      tooltip={tooltip}
      fitContent={fitContent}
    >
      <EditableAddressForm
        minWidth={minWidth}
        onEdit={onChange}
        placeholder={placeholder}
        setIsEditable={setIsEditable}
        setValue={setValue}
        value={value}
        required={required}
        rules={rules}
        canRemove={canRemove}
        defaultValue={defaultValue}
      />
    </EditableFieldWrapper>
  );
};
