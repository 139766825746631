import {
  TColumnType as TColumnTypeAntd,
  TPageSizeOptions,
  TTableComponentProps,
} from '@idaho-aeyc/ui-kit';
import {
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from 'antd/es/table/interface';
import { Dispatch, Key, MouseEventHandler } from 'react';
import { TSortParams } from 'data/types/generic.types';

export type { TColumnFilterItem } from '@idaho-aeyc/ui-kit';

export type TColumnType<T> = Omit<TColumnTypeAntd<T>, 'key'> & {
  key: Key;
};

export type TExportCSVProps = {
  filename?: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  exportData: string | object[] | (() => string | object[]);
  onClick?: (
    event: MouseEventHandler<HTMLAnchorElement>,
    done: (proceed?: boolean) => void,
  ) => void;
  asyncOnClick?: boolean;
  isLoading?: boolean;
  hiddenColumnDataIndexes?: string[];
};

export type TableProps<T> = Omit<
  TTableComponentProps<T>,
  'onDragEnd' | 'filterColumnContent' | 'columns'
> & {
  tableKey?: string;
  pageHeaderContent?: number;
  storagePath: string;
  columns?: TColumnType<T>[];
  exportCSV?: TExportCSVProps;
  tabHeight?: number;
};

export type TUseTableParams<T> = {
  storagePath: string;
  defaultSortColumn?: TSortParams;
  defaultPageSize?: TPageSizeOptions;
  defaultColumns: TColumnType<T>[];
  defaultFilters?: Record<string, FilterValue | null>;
};

export type TSelectedRows<T> = {
  selectedRowsKeys: Key[];
  selectedRowsRecords: T[];
};

export type TUseTableReturnData<T> = {
  columns: TColumnType<T>[];
  setColumns: React.Dispatch<React.SetStateAction<TColumnType<T>[]>>;
  page: number;
  pageSize: number;
  sortColumn: TSortParams;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  setSortColumn: React.Dispatch<React.SetStateAction<TSortParams>>;
  handleTableChange: (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<T> | SorterResult<T>[],
  ) => void;
  selectedFilters: Record<string, FilterValue | null>;
  setSelectedFilters: React.Dispatch<
    React.SetStateAction<Record<string, FilterValue | null>>
  >;
  hasSelectedFilter: boolean;
  selectedRows: TSelectedRows<T>;
  setSelectedRows: Dispatch<React.SetStateAction<TSelectedRows<T>>>;
  hasFilters: boolean;
  onFiltersReset: () => void;
};

export type TStorageFilters<T> = {
  selectedFilters?: Record<string, FilterValue | null>;
  sortColumn?: TSortParams;
  page?: number;
  pageSize?: number;
  selectedRows?: TSelectedRows<T>;
};

export enum ETableStoragePaths {
  WORKSHOPS = 'workshops',
  ORGANIZATION = 'organization',
  SESSION_REGISTRANTS = 'sessionRegistrants',
  PEOPLE = 'people',
  TEAM = 'team',
  KIT_MANAGEMENT_WORKSHOP = 'kitManagementWorkshop',
  KIT_MANAGEMENT_PARENT = 'kitManagementParent',
  SUBSCRIBERS_NOTIFY_ME = 'subscribersNotifyMe',
  SUBSCRIBERS_WAIT_LIST = 'subscribersWaitList',
  WORKSHOP_WAIT_LIST = 'workshopWaitList',
  WORKSHOP_REGISTRANTS = 'workshopRegistrants',
  FAMILY = 'family',
  WORKSHOP_CHILDCARE = 'workshopChildcare',
  CONTACTS = 'contacts',
  MEETINGS = 'meetings',
  ELC_PLACES = 'elcPlaces',
  ELAC_MEMBERS = 'elacMembers',
  MEETING_ACTION_ITEMS = 'meetingActionItems',
}

export type TTableStoragePaths = ETableStoragePaths & string;

export type TColumnsState = {
  key: Key;
  show: boolean;
  title: string;
};
