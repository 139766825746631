import { Form, FormItem } from 'components/Base/Form';
import { Input, PhoneInput } from 'components/Base/Input';
import { TEditFamilyFormProps } from './EditFamilyForm.types';
import useGetFormData from './EditFamilyForm.data';

const EditFamilyForm = ({ form, data }: TEditFamilyFormProps) => {
  const { editFamilyFormFields } = useGetFormData();
  const { firstName, lastName, email, phone } = editFamilyFormFields;

  return (
    <Form
      form={form}
      initialValues={{
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone: data.phone,
      }}
    >
      <FormItem {...firstName}>
        <Input placeholder={firstName.placeholder} />
      </FormItem>
      <FormItem {...lastName}>
        <Input placeholder={lastName.placeholder} />
      </FormItem>
      <FormItem {...email}>
        <Input placeholder={email.placeholder} />
      </FormItem>
      <FormItem {...phone}>
        <PhoneInput placeholder={phone.placeholder} />
      </FormItem>
    </Form>
  );
};

export default EditFamilyForm;
