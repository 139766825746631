import { useState } from 'react';
import { get } from 'lodash';
import {
  Col,
  ENotificationType,
  Modal,
  useForm,
  useShowToastNotification,
  useTranslation,
} from '@packages/utils';
import { TValidateFieldsError } from 'data/types/generic.types';
import { useTheme } from 'styled-components';
import { EIconNames, Icon } from 'components/Base/Icon';
import { useEditMeetingMutation } from 'redux/apiSlice/elcs.slice';
import { TEditMeetingFormProps, TEditMeetingProps } from './EditMeeting.types';
import AddEditMeetingForm from '../AddEditMeetingForm';

const EditMeeting = ({
  elcId,
  meetingId,
  initialValues,
}: TEditMeetingProps) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.meetings-tab.list.actions.edit',
  });
  const theme = useTheme();
  const { showToastNotification } = useShowToastNotification();
  const { form } = useForm<TEditMeetingFormProps>();
  const [open, setOpen] = useState<boolean>(false);
  const [editMeeting, { isLoading }] = useEditMeetingMutation();

  const handleClose = () => {
    setOpen(false);
    form.resetFields();
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      await editMeeting({
        elcId,
        meetingId,
        ...values,
      }).unwrap();
      showToastNotification({
        message: t('modal.SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
      handleClose();
    } catch (err) {
      if (!(err as TValidateFieldsError)?.errorFields) {
        const message = get(err, 'data.message');
        showToastNotification({
          message,
          type: ENotificationType.ERROR,
        });
      }
    }
  };

  return (
    <>
      <Col onClick={() => setOpen(true)}>
        <Icon
          icon={EIconNames.EDIT_OUTLINED}
          color={theme.colorBlackIconColor}
          className="pointer"
          tooltip={{ title: t('EDIT_TOOLTIP') }}
        />
      </Col>
      <Modal
        title={t('modal.TITLE')}
        open={open}
        onCancel={handleClose}
        onOk={handleSubmit}
        centeredPosition
        okText={t('modal.SUBMIT_BTN')}
        cancelText={t('modal.CANCEL_BTN')}
        confirmLoading={isLoading}
      >
        <AddEditMeetingForm form={form} initialValues={initialValues} />
      </Modal>
    </>
  );
};

export default EditMeeting;
