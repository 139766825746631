import { SDragger, SUploader } from './Uploader.styles';
import { TUploaderProps } from './Uploader.type';

export const Uploader = ({
  accept = 'image/png, image/jpeg, image/jpg, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf',
  ...props
}: TUploaderProps) => {
  return <SUploader accept={accept} {...props} />;
};

export const Dragger = ({ ...props }: TUploaderProps) => {
  return <SDragger {...props} />;
};
