import {
  dateConverter,
  dateFormatDB,
  dateFormatUI,
  dateTimeFormatDB,
  dateTimeFormatUI,
} from '@idaho-aeyc/ui-kit';
import { TCreateConverter, TFetchConverter } from '@packages/utils';
import {
  TElcItemDB,
  TElcItemUI,
  TElcLeadDB,
  TElcLeadUI,
  TLeadEditUI,
  TLeadEditDB,
  TMyElcItemDB,
  TMyElcItemUI,
  TElcDetailUI,
  TElcDetailDB,
  TElcDetailLeadAgencyDB,
  TElcDetailLeadAgencyUI,
  TElcDetailFiscalAgentUI,
  TElcDetailFiscalAgentDB,
  TAddressUI,
  TAddressDB,
  TElcNotesUI,
  TElcNotesDB,
  TLeadAgencyEditUI,
  TLeadAgencyEditDB,
  TFiscalAgentEditUI,
  TFiscalAgentEditDB,
  TCoLeadAgencyEditUI,
  TCoLeadAgencyEditDB,
  TNoteEditUI,
  TNoteEditDB,
  TNoteCreateUI,
  TNoteCreateDB,
  TNoteDeleteUI,
  TNoteDeleteDB,
  TPMEditUI,
  TPMEditDB,
  TBoundariesCreateDB,
  TBoundariesCreateUI,
  TBoundariesDeleteDB,
  TBoundariesDeleteUI,
  TElcBoundariesDB,
  TElcBoundariesUI,
  TContactsDB,
  TContactsUI,
  EContactsColumnKey,
  TAddContactUI,
  TAddContactDB,
  TDeleteContactDB,
  TDeleteContactUI,
  TUpdateELACMemberUI,
  TUpdateELACMemberDB,
  TAddELCPlaceUI,
  TAddELCPlaceDB,
  TDeleteELCPlaceUI,
  TDeleteELCPlaceDB,
  TELCPlacesUI,
  TELCPlacesDB,
  EELCPlacesColumnKey,
  TMeetingsUI,
  TMeetingsDB,
  TDeleteMeetingUI,
  TDeleteMeetingDB,
  TAddMeetingUI,
  TAddMeetingDB,
  TEditMeetingUI,
  TEditMeetingDB,
  TCreateElcMeetingTopicUI,
  TCreateElcMeetingTopicDB,
  TElcMeetingTopicUI,
  TElcMeetingTopicDB,
  TContextEditUI,
  TContextEditDB,
  TProgressEditUI,
  TProgressEditDB,
  TRoadBlockEditUI,
  TRoadBlockEditDB,
  TDeleteElcMeetingTopicUI,
  TDeleteElcMeetingTopicDB,
  TElcMeetingNoteUI,
  TElcMeetingNoteDB,
  TCreateElcMeetingNoteUI,
  TCreateElcMeetingNoteDB,
  TElcMeetingNoteEditUI,
  TElcMeetingNoteEditDB,
  TDeleteElcMeetingNoteUI,
  TDeleteElcMeetingNoteDB,
  TElcMeetingAttendeesDB,
  TElcMeetingAttendeesUI,
  TAttendeeUI,
  TAttendeeDB,
  TUpdateAttendanceStatusUI,
  TUpdateAttendanceStatusDB,
  TAddGuestAttendeeUI,
  TAddGuestAttendeeDB,
  TDeleteGuestAttendeeUI,
  TDeleteGuestAttendeeDB,
  TElcMeetingTATimeDB,
  TElcMeetingTATimeUI,
  TCreateElcMeetingTATimeUI,
  TCreateElcMeetingTATimeDB,
  TDeleteElcMeetingTATimeUI,
  TDeleteElcMeetingTATimeDB,
  TTopicCommonEditUI,
  TTopicCommonEditDB,
  TElcMeetingActionItemsUI,
  TElcMeetingActionItemsDB,
  TAddElcMeetingsActionItemUI,
  TAddElcMeetingsActionItemDB,
  TEditElcMeetingActionItemCommonDB,
  TEditElcMeetingActionItemCommonUI,
  TEditElcMeetingActionItemSummaryUI,
  TEditElcMeetingActionItemSummaryDB,
  TEditElcMeetingActionItemDueDateDB,
  TEditElcMeetingActionItemDueDateUI,
  TEditElcMeetingActionItemOwnerUI,
  TEditElcMeetingActionItemOwnerDB,
  TEditElcMeetingActionItemStatusUI,
  TEditElcMeetingActionItemStatusDB,
  TUploadResourceUI,
  TElcResourcesDB,
  TElcResourcesUI,
  TResourceItemDB,
  TResourceItemUI,
  TAddResourceUI,
  TAddResourceDB,
  TDeleteResourceUI,
  TDeleteResourceDB,
  TElcMeetingThingToShareEditUI,
  TElcMeetingThingToShareEditDB,
  TElcMeetingThingsToShareUI,
  TElcMeetingThingsToShareDB,
  TThinsToShareUI,
  TThinsToShareDB,
} from 'data/types/elcs.types';
import { TTableFilters } from 'data/types/generic.types';
import { fileConverter, phoneNumberConverter } from './general.converters';
import { tagsGetConverter } from './tags.convertor';

export const leadConverter: TFetchConverter<TElcLeadUI, TElcLeadDB> = {
  fromDb: data => {
    return {
      email: data.email,
      fullName: data.fullName,
      phone: data.phone || null,
      userId: data.userId,
      invitationStatus: data.invitationStatus,
      allowedUpdateWithNull: data.allowedUpdateWithNull,
    };
  },
};

export const firstPMConverter: TFetchConverter<TElcLeadUI, TElcLeadDB> = {
  fromDb: data => ({
    email: data.email,
    fullName: data.fullName,
    phone: data.phone || null,
    userId: data.userId,
    invitationStatus: data.invitationStatus,
    allowedUpdateWithNull: data.allowedUpdateWithNull,
  }),
};

export const secondPMConverter: TFetchConverter<TElcLeadUI, TElcLeadDB> = {
  fromDb: data => ({
    email: data.email,
    fullName: data.fullName,
    phone: data.phone || null,
    userId: data.userId,
    invitationStatus: data.invitationStatus,
    allowedUpdateWithNull: data.allowedUpdateWithNull,
  }),
};

export const elcConverter: TFetchConverter<TElcItemUI, TElcItemDB> = {
  fromDb: data => ({
    elcId: data.elcId,
    name: data.name,
    lead: leadConverter.fromDb(data.lead),
    firstProjectManager: data.firstProjectManager
      ? firstPMConverter.fromDb(data.firstProjectManager)
      : null,
    secondProjectManager: data.secondProjectManager
      ? secondPMConverter.fromDb(data.secondProjectManager)
      : null,
  }),
};

export const myElcConverter: TFetchConverter<TMyElcItemUI, TMyElcItemDB> = {
  fromDb: data => ({
    elcId: data.elcId,
    name: data.name,
    lead: leadConverter.fromDb(data.lead),
    firstProjectManager: data.firstProjectManager
      ? firstPMConverter.fromDb(data.firstProjectManager)
      : null,
    secondProjectManager: data.secondProjectManager
      ? secondPMConverter.fromDb(data.secondProjectManager)
      : null,
  }),
};

export const elcDetailFiscalAgentConverter: TFetchConverter<
  TElcDetailFiscalAgentUI,
  TElcDetailFiscalAgentDB
> = {
  fromDb: data => {
    return {
      email: data.email,
      phone: data.phone,
      fullName: data.fullName,
      userId: data.userId,
    };
  },
};

export const elcAddressConverter: TFetchConverter<TAddressUI, TAddressDB> = {
  fromDb: data => {
    return {
      formattedAddress: data.formattedAddress,
      id: data.id,
    };
  },
};

export const elcDetailLeadAgencyConverter: TFetchConverter<
  TElcDetailLeadAgencyUI,
  TElcDetailLeadAgencyDB
> = {
  fromDb: data => {
    return {
      id: data.id,
      name: data.name,
      address: data.address && elcAddressConverter.fromDb(data.address),
    };
  },
};

export const elcDetailNotesConverter: TFetchConverter<
  TElcNotesUI,
  TElcNotesDB
> = {
  fromDb: data => {
    return {
      category: data.category,
      id: data.id,
      note: data.note,
    };
  },
};

export const elcBoundariesConverter: TFetchConverter<
  TElcBoundariesUI,
  TElcBoundariesDB
> = {
  fromDb: data => {
    return {
      id: data.id,
      zipCode: data.zipCode,
    };
  },
};

export const elcDetailConverter: TFetchConverter<TElcDetailUI, TElcDetailDB> = {
  fromDb: data => ({
    elcId: data.elcId,
    name: data.name,
    lead: leadConverter.fromDb(data.lead),
    firstProjectManager: data.firstProjectManager
      ? firstPMConverter.fromDb(data.firstProjectManager)
      : null,
    secondProjectManager: data.secondProjectManager
      ? secondPMConverter.fromDb(data.secondProjectManager)
      : null,
    fiscalAgent: data.fiscalAgent
      ? elcDetailFiscalAgentConverter.fromDb(data.fiscalAgent)
      : null,
    leadAgency: data.leadAgency
      ? elcDetailLeadAgencyConverter.fromDb(data.leadAgency)
      : null,
    coLeadAgency: data.coLeadAgency
      ? elcDetailLeadAgencyConverter.fromDb(data.coLeadAgency)
      : null,
    elcNotes: data.elcNotes.map(note => elcDetailNotesConverter.fromDb(note)),
    elcBoundaries: (data.elcBoundaries || []).map(boundary =>
      elcBoundariesConverter.fromDb(boundary),
    ),
  }),
};

export const leadEditConverter: TCreateConverter<TLeadEditUI, TLeadEditDB> = {
  toDb: data => ({
    elcId: data.elcId,
    userId: data.userId,
  }),
};

export const PMEditConverter: TCreateConverter<TPMEditUI, TPMEditDB> = {
  toDb: data => ({
    elcId: data.elcId,
    userId: data.userId,
  }),
};

export const leadAgencyEditConverter: TCreateConverter<
  TLeadAgencyEditUI,
  TLeadAgencyEditDB
> = {
  toDb: data => ({
    elcId: data.elcId,
    organizationId: data.organizationId,
  }),
};

export const coLeadAgencyEditConverter: TCreateConverter<
  TCoLeadAgencyEditUI,
  TCoLeadAgencyEditDB
> = {
  toDb: data => ({
    elcId: data.elcId,
    organizationId: data.organizationId,
  }),
};

export const fiscalAgentEditConverter: TCreateConverter<
  TFiscalAgentEditUI,
  TFiscalAgentEditDB
> = {
  toDb: data => ({
    elcId: data.elcId,
    userId: data.userId,
  }),
};

export const noteEditConverter: TCreateConverter<TNoteEditUI, TNoteEditDB> = {
  toDb: data => ({
    elcId: data.elcId,
    elcNoteId: data.elcNoteId,
    note: data.note,
  }),
};

export const noteCreateConverter: TCreateConverter<
  TNoteCreateUI,
  TNoteCreateDB
> = {
  toDb: data => ({
    elcId: data.elcId,
    category: data.category,
    note: data.note,
  }),
};

export const deleteNoteConverter: TCreateConverter<
  TNoteDeleteUI,
  TNoteDeleteDB
> = {
  toDb: data => ({
    elcId: data.elcId,
    elcNoteId: data.elcNoteId,
  }),
};

export const boundariesCreateConverter: TCreateConverter<
  TBoundariesCreateUI,
  TBoundariesCreateDB
> = {
  toDb: data => ({
    elcId: data.elcId,
    zipCode: data.zipCode,
  }),
};

export const deleteBoundariesConverter: TCreateConverter<
  TBoundariesDeleteUI,
  TBoundariesDeleteDB
> = {
  toDb: data => ({
    elcId: data.elcId,
    boundaryId: data.boundaryId,
  }),
};

export const addContactConverter: TCreateConverter<
  TAddContactUI,
  TAddContactDB
> = {
  toDb: data => ({
    elcId: data.elcId,
    isElacMember: data.isElacMember,
    userId: data.userId,
  }),
};

export const updateELACMemberConverter: TCreateConverter<
  TUpdateELACMemberUI,
  TUpdateELACMemberDB
> = {
  toDb: data => ({
    elcId: data.elcId,
    isElacMember: data.isElacMember,
    contactId: data.contactId,
  }),
};

export const deleteContactConverter: TCreateConverter<
  TDeleteContactUI,
  TDeleteContactDB
> = {
  toDb: data => ({
    contactId: data.contactId,
    elcId: data.elcId,
  }),
};

export const contactsConverter: TFetchConverter<TContactsUI, TContactsDB> = {
  fromDb: data => {
    return {
      contactId: data.contactId,
      email: data.email,
      phone: phoneNumberConverter.fromDb(data.phone || '') || '',
      firstName: data.firstName,
      lastName: data.lastName,
      tags: data.tags && tagsGetConverter.fromDb(data.tags),
      isElacMember: data.isElacMember,
      title: data.title,
      userId: data.userId,
    };
  },
};

export const contactsFilterConverter = (
  filters: TTableFilters<EContactsColumnKey>['filters'],
) => {
  const filtersToDB = {
    ...(filters?.firstName && {
      [EContactsColumnKey.FIRST_NAME]: filters.firstName.toString().trim(),
    }),
    ...(filters?.lastName && {
      [EContactsColumnKey.LAST_NAME]: filters.lastName.toString().trim(),
    }),
    ...(filters?.tagIds && {
      [EContactsColumnKey.TAGS]: filters?.tagIds,
    }),
  };
  return filtersToDB;
};

export const addELCPlacesConverter: TCreateConverter<
  TAddELCPlaceUI,
  TAddELCPlaceDB
> = {
  toDb: data => ({
    elcId: data.elcId,
    organizationId: data.organizationId,
  }),
};

export const deleteELCPlaceConverter: TCreateConverter<
  TDeleteELCPlaceUI,
  TDeleteELCPlaceDB
> = {
  toDb: data => ({
    placeId: data.placeId,
    elcId: data.elcId,
  }),
};

export const ELCPlacesConverter: TFetchConverter<TELCPlacesUI, TELCPlacesDB> = {
  fromDb: data => {
    return {
      address: data.address,
      name: data.name,
      organizationId: data.organizationId,
      placeId: data.placeId,
      tags: data.tags && tagsGetConverter.fromDb(data.tags),
    };
  },
};

export const ELCPlacesFilterConverter = (
  filters: TTableFilters<EELCPlacesColumnKey>['filters'],
) => {
  const filtersToDB = {
    ...(filters?.organizationName && {
      [EELCPlacesColumnKey.ORGANIZATION]: filters.organizationName
        .toString()
        .trim(),
    }),
    ...(filters?.tagIds && {
      [EELCPlacesColumnKey.TAGS]: filters?.tagIds,
    }),
  };
  return filtersToDB;
};

export const meetingsConverter: TFetchConverter<TMeetingsUI, TMeetingsDB> = {
  fromDb: data => {
    return {
      meetingId: data.meetingId,
      title: data.title,
      date: dateConverter(data.date, dateTimeFormatUI),
      lastUpdateDate: dateConverter(data.lastUpdateDate, dateTimeFormatUI),
    };
  },
};

export const deleteMeetingConverter: TCreateConverter<
  TDeleteMeetingUI,
  TDeleteMeetingDB
> = {
  toDb: data => ({
    meetingId: data.meetingId,
    elcId: data.elcId,
  }),
};

export const addMeetingConverter: TCreateConverter<
  TAddMeetingUI,
  TAddMeetingDB
> = {
  toDb: data => ({
    elcId: data.elcId,
    title: data.title,
    date: dateConverter(data.date, dateTimeFormatDB),
  }),
};

export const editMeetingConverter: TCreateConverter<
  TEditMeetingUI,
  TEditMeetingDB
> = {
  toDb: data => ({
    elcId: data.elcId,
    title: data.title,
    date: dateConverter(data.date, dateTimeFormatDB),
    meetingId: data.meetingId,
  }),
};

export const createElcMeetingTopicConvertor: TCreateConverter<
  TCreateElcMeetingTopicUI,
  TCreateElcMeetingTopicDB
> = {
  toDb: data => ({
    elcId: data.elcId,
    meetingId: data.meetingId,
    topicTitle: data.topicTitle,
    context: data.context,
    progress: data.progress,
    roadBlock: data.roadBlock,
  }),
};

export const ElcMeetingTopicsConverter: TFetchConverter<
  TElcMeetingTopicUI,
  TElcMeetingTopicDB
> = {
  fromDb: data => {
    return {
      meetingId: data.meetingId,
      topicId: data.topicId,
      topicTitle: data.topicTitle,
      context: data.context,
      progress: data.progress,
      roadBlock: data.roadBlock,
    };
  },
};

export const topicCommonEditConverter: TCreateConverter<
  TTopicCommonEditUI,
  TTopicCommonEditDB
> = {
  toDb: data => ({
    meetingId: data.meetingId,
    elcId: data.elcId,
    topicId: data.topicId,
  }),
};

export const topicContextEditConverter: TCreateConverter<
  TContextEditUI,
  TContextEditDB
> = {
  toDb: data => ({
    ...topicCommonEditConverter.toDb(data),
    context: data.context,
  }),
};

export const topicProgressEditConverter: TCreateConverter<
  TProgressEditUI,
  TProgressEditDB
> = {
  toDb: data => ({
    ...topicCommonEditConverter.toDb(data),
    progress: data.progress,
  }),
};

export const topicRoadBlockEditConverter: TCreateConverter<
  TRoadBlockEditUI,
  TRoadBlockEditDB
> = {
  toDb: data => ({
    ...topicCommonEditConverter.toDb(data),
    roadBlock: data.roadBlock,
  }),
};

export const deleteElcMeetingTopicConverter: TCreateConverter<
  TDeleteElcMeetingTopicUI,
  TDeleteElcMeetingTopicDB
> = {
  toDb: data => ({
    meetingId: data.meetingId,
    elcId: data.elcId,
    topicId: data.topicId,
  }),
};

export const ElcMeetingNoteConverter: TFetchConverter<
  TElcMeetingNoteUI,
  TElcMeetingNoteDB
> = {
  fromDb: data => {
    return {
      meetingId: data.meetingId,
      noteId: data.noteId,
      note: data.note,
    };
  },
};

export const createElcMeetingNoteConvertor: TCreateConverter<
  TCreateElcMeetingNoteUI,
  TCreateElcMeetingNoteDB
> = {
  toDb: data => ({
    elcId: data.elcId,
    meetingId: data.meetingId,
    note: data.note,
  }),
};

export const ElcMeetingNoteEditConverter: TCreateConverter<
  TElcMeetingNoteEditUI,
  TElcMeetingNoteEditDB
> = {
  toDb: data => ({
    elcId: data.elcId,
    meetingId: data.meetingId,
    noteId: data.noteId,
    note: data.note,
  }),
};

export const deleteElcMeetingNoteConverter: TCreateConverter<
  TDeleteElcMeetingNoteUI,
  TDeleteElcMeetingNoteDB
> = {
  toDb: data => ({
    meetingId: data.meetingId,
    elcId: data.elcId,
    noteId: data.noteId,
  }),
};

export const attendeesConverter: TFetchConverter<TAttendeeUI, TAttendeeDB> = {
  fromDb: data => {
    return {
      attendeeId: data.attendeeId,
      email: data.email,
      phone: data.phone || '',
      fullName: data.fullName,
      isAttended: data.isAttended,
      userId: data.userId,
    };
  },
};

export const ElcMeetingAttendeesConverter: TFetchConverter<
  TElcMeetingAttendeesUI,
  TElcMeetingAttendeesDB
> = {
  fromDb: data => {
    return {
      meetingId: data.meetingId,
      lead: attendeesConverter.fromDb(data.lead),
      firstProjectManager: attendeesConverter.fromDb(data.firstProjectManager),
      secondProjectManager:
        data.secondProjectManager &&
        attendeesConverter.fromDb(data.secondProjectManager),
      fiscalAgent: attendeesConverter.fromDb(data.fiscalAgent),
      guests: (data.guests || []).map(guest =>
        attendeesConverter.fromDb(guest),
      ),
    };
  },
};

export const meetingsActionItemConverter: TFetchConverter<
  TElcMeetingActionItemsUI,
  TElcMeetingActionItemsDB
> = {
  fromDb: data => {
    return {
      id: data.actionItemId,
      meetingId: data.meetingId,
      title: data.summary,
      dueDate: data.dueDate && dateConverter(data.dueDate, dateFormatUI),
      isNew: false,
      ownerId: data.ownerId,
      status: data.isCompleted,
    };
  },
};

export const updateAttendanceStatusConverter: TCreateConverter<
  TUpdateAttendanceStatusUI,
  TUpdateAttendanceStatusDB
> = {
  toDb: data => ({
    elcId: data.elcId,
    attendeeId: data.attendeeId,
    meetingId: data.meetingId,
    isAttended: data.isAttended,
  }),
};

export const addGuestAttendeeConverter: TCreateConverter<
  TAddGuestAttendeeUI,
  TAddGuestAttendeeDB
> = {
  toDb: data => ({
    elcId: data.elcId,
    meetingId: data.meetingId,
    userId: data.userId,
  }),
};

export const deleteGuestAttendeeConverter: TCreateConverter<
  TDeleteGuestAttendeeUI,
  TDeleteGuestAttendeeDB
> = {
  toDb: data => ({
    meetingId: data.meetingId,
    elcId: data.elcId,
    attendeeId: data.attendeeId,
  }),
};

export const ElcMeetingTATimeConverter: TFetchConverter<
  TElcMeetingTATimeUI,
  TElcMeetingTATimeDB
> = {
  fromDb: data => {
    return {
      meetingId: data.meetingId,
      duration: data.duration,
      focus: data.focus,
      pillarName: data.pillarName,
      taTimeId: data.taTimeId,
    };
  },
};

export const createElcMeetingTATimeConvertor: TCreateConverter<
  TCreateElcMeetingTATimeUI,
  TCreateElcMeetingTATimeDB
> = {
  toDb: data => ({
    elcId: data.elcId,
    meetingId: data.meetingId,
    focusId: data.focusId,
    duration: data.duration,
  }),
};

export const deleteElcMeetingTATimeConverter: TCreateConverter<
  TDeleteElcMeetingTATimeUI,
  TDeleteElcMeetingTATimeDB
> = {
  toDb: data => ({
    meetingId: data.meetingId,
    elcId: data.elcId,
    taTimeId: data.taTimeId,
  }),
};

export const createElcActionItemConvertor: TCreateConverter<
  TAddElcMeetingsActionItemUI,
  TAddElcMeetingsActionItemDB
> = {
  toDb: data => ({
    elcId: data.elcId,
    meetingId: data.meetingId,
    summary: data.title || '',
    dueDate: data.dueDate && dateConverter(data.dueDate, dateFormatDB),
    isCompleted: !!data.status,
    ownerId: data.ownerId,
  }),
};

export const editElcMeetingActionItemCommonConverter: TCreateConverter<
  TEditElcMeetingActionItemCommonUI,
  TEditElcMeetingActionItemCommonDB
> = {
  toDb: data => ({
    meetingId: data.meetingId,
    elcId: data.elcId,
    actionItemId: data.actionItemId,
  }),
};

export const editElcMeetingActionItemSummaryConverter: TCreateConverter<
  TEditElcMeetingActionItemSummaryUI,
  TEditElcMeetingActionItemSummaryDB
> = {
  toDb: data => ({
    ...editElcMeetingActionItemCommonConverter.toDb(data),
    summary: data.title,
  }),
};

export const editElcMeetingActionItemDueDateConverter: TCreateConverter<
  TEditElcMeetingActionItemDueDateUI,
  TEditElcMeetingActionItemDueDateDB
> = {
  toDb: data => ({
    ...editElcMeetingActionItemCommonConverter.toDb(data),
    dueDate: data.dueDate && dateConverter(data.dueDate, dateFormatDB),
  }),
};

export const editElcMeetingActionItemOwnerConverter: TCreateConverter<
  TEditElcMeetingActionItemOwnerUI,
  TEditElcMeetingActionItemOwnerDB
> = {
  toDb: data => ({
    ...editElcMeetingActionItemCommonConverter.toDb(data),
    ownerId: data.ownerId,
  }),
};

export const editElcMeetingActionItemStatusConverter: TCreateConverter<
  TEditElcMeetingActionItemStatusUI,
  TEditElcMeetingActionItemStatusDB
> = {
  toDb: data => ({
    ...editElcMeetingActionItemCommonConverter.toDb(data),
    isCompleted: data.status,
  }),
};

export const uploaderConverter: TCreateConverter<TUploadResourceUI, FormData> =
  {
    toDb: data => {
      const formData = new FormData();
      formData.append('file', data.file);
      formData.append('elcId', data.elcId);
      return formData;
    },
  };

export const resourceItemConverter: TFetchConverter<
  TResourceItemUI,
  TResourceItemDB
> = {
  fromDb: data => ({
    resourceId: data.resourceId,
    file: fileConverter.fromDb(data.file),
  }),
};

export const ElcMeetingThingsToShareEditConverter: TCreateConverter<
  TElcMeetingThingToShareEditUI,
  TElcMeetingThingToShareEditDB
> = {
  toDb: data => ({
    elcId: data.elcId,
    meetingId: data.meetingId,
    thingToShareId: data.thingToShareId,
    content: data.content,
  }),
};

export const ElcThingsToShareConverter: TFetchConverter<
  TThinsToShareUI,
  TThinsToShareDB
> = {
  fromDb: data => {
    return {
      content: data.content,
      thingToShareId: data.thingToShareId,
    };
  },
};

export const ElcResourcesConverter: TFetchConverter<
  TElcResourcesUI,
  TElcResourcesDB
> = {
  fromDb: data => {
    return {
      elcSpecificDocuments: data.elcSpecificDocuments.map(item =>
        resourceItemConverter.fromDb(item),
      ),
      generalElcDocuments: data.generalElcDocuments.map(item =>
        resourceItemConverter.fromDb(item),
      ),
      links: data.links.map(item => resourceItemConverter.fromDb(item)),
    };
  },
};

export const addResourceConverter: TCreateConverter<
  TAddResourceUI,
  TAddResourceDB
> = {
  toDb: data => ({
    elcId: data.elcId,
    resourceType: data.resourceType,
    files: (data.files || []).map(file => fileConverter.fromDb(file)),
  }),
};

export const deleteResourceConverter: TCreateConverter<
  TDeleteResourceUI,
  TDeleteResourceDB
> = {
  toDb: data => ({
    resourceId: data.resourceId,
    elcId: data.elcId,
  }),
};

export const ElcMeetingThingsToShareConverter: TFetchConverter<
  TElcMeetingThingsToShareUI,
  TElcMeetingThingsToShareDB
> = {
  fromDb: data => {
    return {
      meetingId: data.meetingId,
      leadThingsToShare: ElcThingsToShareConverter.fromDb(
        data.leadThingsToShare,
      ),
      pmThingsToShare: ElcThingsToShareConverter.fromDb(data.pmThingsToShare),
    };
  },
};
