import { useState } from 'react';
import { Row, useTranslation } from '@packages/utils';
import EditableInput from 'components/Base/EditableFields/EditableInput';
import { InplaceEditLabels } from 'components/Base/EditableFields/components/InplaceEditLabel';
import { EIconNames } from 'components/Base/Icon';
import {
  useShowToastNotification,
  ENotificationType,
} from 'components/Base/Notification';
import { useEditWorkshopCapacityMutation } from 'redux/apiSlice/workshops.slice';
import { TRtkErrorType } from 'data/types/generic.types';
import useGetValidationData from 'components/Base/Form/validationRules';
import {
  ECapacityEmptyTexts,
  EWorkshopStatus,
} from 'data/types/workshops.types';
import { useTranslateCapacityShowValue } from 'pages/private/Workshop/hooks/useTranslateCapacityShowValue';
import { TWorkshopCapacityProps } from './WorkshopCapacity.types';

const WorkshopCapacity = ({
  workshopId,
  capacity,
  workshopStatus,
}: TWorkshopCapacityProps) => {
  const { allowedOperations, value, showValue } = capacity || {};
  const { EValidationMessages, validationRules } = useGetValidationData();
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.workshops.details.basic-info.workshop-capacity',
  });
  const capacityText = useTranslateCapacityShowValue(
    showValue as ECapacityEmptyTexts,
  );
  const { showToastNotification } = useShowToastNotification();
  const [editWorkshopCapacity, { isLoading, isError }] =
    useEditWorkshopCapacityMutation();
  const [errorMessage, setErrorMessage] = useState('');

  const validateCapacity = async (newValue: string) => {
    const numericValue = Number(newValue);
    const isCleared = typeof newValue && !!newValue;
    if (
      workshopStatus === EWorkshopStatus.PUBLISHED &&
      Number(value) > numericValue &&
      isCleared
    ) {
      return Promise.reject(new Error(EValidationMessages.DECREASE_IMPOSSIBLE));
    }
    return Promise.resolve();
  };
  const onEdit = async (val: string) => {
    try {
      await validateCapacity(val);
      await editWorkshopCapacity({
        workshopId,
        value: val,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
      setErrorMessage('');
    } catch (e) {
      setErrorMessage(
        (e as TRtkErrorType)?.data?.message || (e as Error).message,
      );
      showToastNotification({
        message: (e as TRtkErrorType)?.data?.message || (e as Error).message,
        type: ENotificationType.ERROR,
      });
    }
  };

  return (
    <Row align="middle">
      <InplaceEditLabels label={t('LABEL')} icon={EIconNames.CAPACITY} />
      <EditableInput
        onEdit={onEdit}
        title={value}
        fitContent
        showValue={capacityText}
        isError={isError}
        errorMessage={errorMessage}
        isLoading={isLoading}
        readonly={
          allowedOperations?.readonly ||
          (showValue === ECapacityEmptyTexts.UNLIMITED_LONG &&
            workshopStatus === EWorkshopStatus.PUBLISHED)
        }
        canRemove={allowedOperations?.canRemove}
        rules={[validationRules.positiveNumbers()]}
        backIcon
      />
    </Row>
  );
};

export default WorkshopCapacity;
