import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, TRtkErrorType, useTranslation } from '@packages/utils';
import PageWrapper from 'components/Base/PageWrapper';
import { useAppDispatch } from 'redux/store';
import redirectSlice from 'redux/slices/redirect.slice';
import { useGetMyElcsQuery } from 'redux/apiSlice/elcs.slice';
import CardContent from 'components/Base/Layout/Content/CardContent';
import { EIconNames } from 'components/Base/Icon';
import ELCItem from '../ELCItem';

const MyELCs = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.elcs.list.my-elcs',
  });
  const navigate = useNavigate();
  const { data, isLoading, error, isSuccess } = useGetMyElcsQuery();

  const isEmpty = !data?.length;

  useEffect(() => {
    if (!data) {
      return;
    }
    if (data?.length === 1) {
      navigate(data[0].elcId);
    }

    dispatch(redirectSlice.actions.setIsElcsMoreThanOne(data.length > 1));
  }, [isSuccess, data]);

  if (data && data.length === 1) {
    return <></>;
  }

  return (
    <PageWrapper
      title={t('TITLE')}
      isLoading={isLoading}
      error={error as TRtkErrorType}
      emptyWrapper={{
        isEmpty,
        alt: 'chess queen',
        icon: EIconNames.CHESS_QUEEN,
        emptyText: t('empty-view.EMPTY_TEXT'),
      }}
    >
      <CardContent contentPadding="16px">
        <Row gutter={[16, 16]}>
          {data?.map(item => (
            <ELCItem
              key={item.elcId}
              elcData={item}
              isEditAllowed={false}
              {...(data.length > 1 && { backButtonSuffix: t('TITLE') })}
            />
          ))}
        </Row>
      </CardContent>
    </PageWrapper>
  );
};

export default MyELCs;
