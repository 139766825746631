import { useTranslation } from '@packages/utils';
import { useTheme } from 'styled-components';
import { TColumnType } from 'components/Base/Table/Table.types';
import {
  EKitManagementParentColumnKey,
  EParentOrderStatus,
  TKitManagementParentUI,
} from 'data/types/kitManagement.types';
import { ESortDirection, TAddressShortDataUI } from 'data/types/generic.types';
import { Text } from 'components/Base/Typography';
import { TextIcon } from 'components/Base/TextIcon';
import { EIconNames, Icon } from 'components/Base/Icon';
import { Row, Col } from 'components/Base/Grid';
import useGetTableColumnData from 'components/Base/Table/components/SearchFilter';
import ParentOrderStatus from 'components/Shared/Statuses/ParentOrderStatus';
import Tags from 'components/Shared/Tags';
import { ETagsUrls } from 'data/urls/tags.url';
import { ETagType } from 'data/types/tags.types';
import { kitManagementApi } from 'redux/apiSlice/kitManagement.slice';
import AddShippingDetails from './AddShippingDetails';

const useGetData = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { getTableColumnSearchProps } = useGetTableColumnData();
  const defaultSortParam = {
    sortField: EKitManagementParentColumnKey.CREATION_DATE,
    sortDirection: ESortDirection.descend,
  };
  const defaultFilterParam = {
    [EKitManagementParentColumnKey.STATUS]: [EParentOrderStatus.NOT_ORDERED],
  };

  const defaultColumns: TColumnType<TKitManagementParentUI>[] = [
    {
      title: t('descriptions.admin-portal.parents.list.table.title.HOST'),
      dataIndex: 'hostName',
      key: EKitManagementParentColumnKey.HOST,
    },
    {
      title: t('descriptions.admin-portal.parents.list.table.title.LOCATION'),
      dataIndex: 'location',
      key: EKitManagementParentColumnKey.LOCATION,
    },
    {
      title: t('descriptions.admin-portal.parents.list.table.title.FIRST_NAME'),
      dataIndex: 'firstName',
      key: EKitManagementParentColumnKey.FIRST_NAME,
      sorter: true,
      ...getTableColumnSearchProps<TKitManagementParentUI>(),
      width: 150,
    },
    {
      title: t('descriptions.admin-portal.parents.list.table.title.LAST_NAME'),
      dataIndex: 'lastName',
      width: 150,
      sorter: true,
      key: EKitManagementParentColumnKey.LAST_NAME,
      ...getTableColumnSearchProps<TKitManagementParentUI>(),
      render: (lastname: string, record) => (
        <Row wrap={false} align="middle">
          <Col>
            <Text ellipsis>{lastname}</Text>
          </Col>
          <Col>
            <Icon
              className="ml-2"
              alt="Personal information icon"
              size={18}
              icon={EIconNames.INFO}
              tooltip={{
                title: (
                  <>
                    <Text
                      fontWeight={500}
                      fontSize={14}
                      color={theme.colorWhitePrimary}
                    >
                      {t(
                        'descriptions.admin-portal.parents.list.table.data.last-name.popover.TITLE',
                      )}
                    </Text>
                    <TextIcon
                      fontSize={14}
                      strong={false}
                      iconColor={theme.colorWhitePrimary}
                      labelColor={theme.colorWhitePrimary}
                      icon={EIconNames.EMAIL}
                      className="mb-2 mt-2"
                      alt="email"
                      label={record.email}
                    />
                    <TextIcon
                      fontSize={14}
                      size={16}
                      strong={false}
                      iconColor={theme.colorWhitePrimary}
                      labelColor={theme.colorWhitePrimary}
                      icon={EIconNames.PHONE}
                      alt="phone"
                      label={record.phone}
                    />
                  </>
                ),
              }}
            />
          </Col>
        </Row>
      ),
    },
    {
      title: t('descriptions.admin-portal.parents.list.table.title.EMAIL'),
      dataIndex: 'email',
      key: 'email',
      ellipsis: true,
    },
    {
      title: t('descriptions.admin-portal.parents.list.table.title.TYPE'),
      width: 150,
      dataIndex: 'workshopType',
      key: 'workshopType',
      render: text => <>{t(`enums.workshop-type.${text}`)}</>,
    },
    {
      title: t('descriptions.admin-portal.parents.list.table.title.PART'),
      dataIndex: 'partDisplayText',
      key: 'partDisplayText',
      ellipsis: true,
    },
    {
      title: t('descriptions.admin-portal.parents.list.table.title.LANGUAGE'),
      dataIndex: 'language',
      key: 'language',
    },
    {
      title: t('descriptions.admin-portal.parents.list.table.title.AGE_GROUP'),
      dataIndex: 'ageGroup',
      key: 'ageGroup',
      onCell: () => ({
        style: {
          textAlign: 'right',
        },
      }),
      render: text => <>{t(`enums.age-group.${text}`)}</>,
    },
    {
      title: t('descriptions.admin-portal.parents.list.table.title.QTY'),
      width: 100,
      dataIndex: 'quantity',
      key: 'quantity',
      onCell: () => ({
        style: {
          textAlign: 'right',
        },
      }),
    },
    {
      title: t(
        'descriptions.admin-portal.parents.list.table.title.ORIENTATION_KIT_QUANTITY',
      ),
      dataIndex: 'orientationKitQuantity',
      key: 'orientationKitQuantity',
      onCell: () => ({
        style: {
          textAlign: 'right',
        },
      }),
    },
    {
      title: t(
        'descriptions.admin-portal.parents.list.table.title.SHIPPING_ADDRESS',
      ),
      dataIndex: 'address',
      key: EKitManagementParentColumnKey.HAS_SHIPMENT_ADDRESS,
      filters: [
        {
          text: t(
            'descriptions.admin-portal.parents.list.table.body.address.HAS_ADDRESS',
          ),
          value: 'true',
        },
        {
          text: t(
            'descriptions.admin-portal.parents.list.table.body.address.NO_ADDRESS',
          ),
          value: 'false',
        },
      ],
      render: (address: TAddressShortDataUI, record) =>
        address?.formattedAddress || (
          <AddShippingDetails parentOrderId={record.parentOrderId} />
        ),
      onCell: record => ({
        style: {
          minWidth: '200px',
          textAlign: record.address?.formattedAddress ? 'left' : 'center',
        },
        onClick: e => {
          e.stopPropagation();
        },
      }),
    },
    {
      title: t('descriptions.admin-portal.parents.list.table.title.STATUS'),
      dataIndex: 'status',
      key: EKitManagementParentColumnKey.STATUS,
      filters: Object.values(EParentOrderStatus).map(status => ({
        text: t(`enums.parent-order-status.${status}`),
        value: status,
      })),
      defaultFilteredValue:
        defaultFilterParam[EKitManagementParentColumnKey.STATUS],
      render: (text: TKitManagementParentUI['status']) => (
        <ParentOrderStatus text={text} />
      ),
    },
    {
      title: t('descriptions.admin-portal.parents.list.table.title.ORDER_DATE'),
      dataIndex: 'orderDate',
      key: EKitManagementParentColumnKey.ORDER_DATE,
      sorter: true,
      onCell: () => ({
        style: { minWidth: '150px' },
      }),
    },
    {
      title: t('descriptions.admin-portal.parents.list.table.title.TAG'),
      dataIndex: 'tags',
      key: EKitManagementParentColumnKey.TAGS,
      filters: [],
      width: 50,
      fixed: 'right',
      onCell: () => ({
        className: 'custom-cell',
        onClick: e => {
          e.stopPropagation();
        },
      }),
      render: (text, record) => (
        <Tags
          selectedTags={record.tags || []}
          associationId={record.parentOrderId}
          url={ETagsUrls.PARENT_ORDER}
          invalidateTagsConfig={[
            {
              invalidateTag: 'kit-management-parents',
              sliceName: kitManagementApi,
            },
          ]}
          tagType={ETagType.PARENT_ORDER}
        />
      ),
    },
  ];

  return { defaultColumns, defaultSortParam, defaultFilterParam };
};

export default useGetData;
