import { FC, useState } from 'react';
import { useTranslation, Button } from '@packages/utils';
import { TAddShippingDetailsProps } from './AddShippingDetails.types';
import AddShippingDetailsModal from './AddShippingDetailsModal';

const AddShippingDetails: FC<TAddShippingDetailsProps> = ({
  parentOrderId,
}) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.parents.list.actions.add-shipping-details',
  });
  const [open, setOpen] = useState<boolean>(false);

  const onAddButtonClick = () => {
    setOpen(true);
  };

  return (
    <>
      <Button
        onClick={onAddButtonClick}
        type="default"
        color="primary"
        size="small"
      >
        {t('ADD_ADDRESS_BTN')}
      </Button>

      <AddShippingDetailsModal
        open={open}
        setOpen={setOpen}
        parentOrderId={parentOrderId}
      />
    </>
  );
};

export default AddShippingDetails;
