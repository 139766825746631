import dayjs from 'dayjs';
import { EAgeGroup } from 'data/types/generic.types';
import i18n from 'i18n';

export {
  dateConverter,
  dateFormatDB,
  dateFormatUI,
  dateShortenFormat,
  dateTimeFormatUI,
  dateTimeFormatDB,
  dateTimeWithoutSecUI,
  dateFormatWithWeekdayAndTime,
  dateFormatWithYearWeekdayAndTime,
  timepickerFormat,
} from '@idaho-aeyc/ui-kit';

export const getMaskEmail = (email: string) => {
  const [name, domain] = email.split('@');
  const maskedName = name.substring(0, 3) + '*'.repeat(name.length - 3);
  return `${maskedName}@${domain}`;
};

export const sortAgeGroups = (data: EAgeGroup[]) => {
  const compareAgeGroups = (a: EAgeGroup, b: EAgeGroup): number => {
    const order = Object.values(EAgeGroup);
    return order.indexOf(a) - order.indexOf(b);
  };

  const sortedData = data.sort(compareAgeGroups);

  return sortedData;
};

export const generateSpaceClassNames = (array: number[]) => {
  let classNames = ``;
  array.forEach((n, i) => {
    classNames += `
                .mx-${i} {
                    margin: 0 ${n}px !important;
                }
                .my-${i} {
                    margin: ${n}px 0 !important;
                }
                .m-${i} {
                    margin: ${n}px !important;
                }
                .mt-${i} {
                    margin-top: ${n}px !important;
                }
                .mb-${i} {
                    margin-bottom: ${n}px !important;
                }
                .ml-${i} {
                    margin-left: ${n}px !important;
                }
                .mr-${i} {
                    margin-right: ${n}px !important;
                }
                .pt-${i} {
                    padding-top: ${n}px !important;
                }
                .pb-${i} {
                    padding-bottom: ${n}px !important;
                }
                .pl-${i} {
                    padding-left: ${n}px !important;
                }
                .pr-${i} {
                    padding-right: ${n}px !important;
                }
                .px-${i} {
                    padding: 0 ${n}px !important;
                }
                .py-${i} {
                    padding: ${n}px 0 !important;
                }
                .p-${i} {
                    padding: ${n}px !important;
                }
            `;
  });

  return classNames;
};

export const checkDateIsBefore = (current: dayjs.Dayjs) => {
  const dateNow = dayjs(); // Assuming `dateNow` represents the current date
  return current && current.isBefore(dateNow);
};

export const checkDateIsAfter = (current: dayjs.Dayjs) => {
  const dateNow = dayjs(); // Assuming `dateNow` represents the current date
  return current && current.isAfter(dateNow);
};

export const convertStateToUpperCase = (value: string) => {
  return value.toUpperCase().replace(/\s+/g, '_');
};

export const formatPhoneNumber = (value: string | null) => {
  if (!value) {
    return '';
  }
  // if phone number is not us number do not format it
  if (!value.startsWith('+1') || !(value.length === 12)) {
    return value;
  }
  if (value) {
    // Remove the +1 prefix and format as (xxx) xxx-xxxx
    const numericValue = value.replace('+1', '');
    const formattedPhoneNumber = `(${numericValue.substring(
      0,
      3,
    )}) ${numericValue.substring(3, 6)}-${numericValue.substring(6, 10)}`;

    return formattedPhoneNumber;
  }

  return null;
};

export const getTextFromI18n = (
  keyInResource: string,
  language: 'en' | 'es' | undefined = 'en',
) => {
  return i18n.getResource(language, 'translation', keyInResource);
};
