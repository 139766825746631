import { useEffect, useState } from 'react';
import Coordinator from 'components/Shared/Coordinator';
import { useEditSiteCoordinatorMutation } from 'redux/apiSlice/workshops.slice';
import { TCoordinatorInputProps } from './CoordinatorInput.types';

const CoordinatorInput = ({ id, coordinator }: TCoordinatorInputProps) => {
  const [coordinatorId, setCoordinatorId] = useState<string>('');

  const [editSiteCoordinator, { isLoading: siteCoordinatorLoading }] =
    useEditSiteCoordinatorMutation();

  useEffect(() => {
    if (!coordinatorId) {
      return;
    }
    (async () => {
      await editSiteCoordinator({
        id,
        value: coordinatorId,
      }).unwrap();
    })();
  }, [coordinatorId]);

  return (
    <Coordinator
      id={id}
      coordinator={coordinator}
      setCoordinatorId={setCoordinatorId}
      loading={siteCoordinatorLoading}
    />
  );
};

export default CoordinatorInput;
