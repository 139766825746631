import { ERedirectUI } from 'data/types/redirect.types';
import { RoutesPaths } from 'routes/Routes.types';

export type TRedirectMapperProps = {
  url: string;
  private: boolean;
};
export const redirectsMapper: {
  [key in ERedirectUI]: TRedirectMapperProps;
} = {
  [ERedirectUI.FORGOT_PASSWORD]: {
    private: false,
    url: `${RoutesPaths.RESET_PASSWORD}`,
  },
  [ERedirectUI.INVITE]: {
    private: false,
    url: `${RoutesPaths.LOGIN}`,
  },
};
