import React, { Suspense, FC, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useTranslation } from '@packages/utils';
import { useAppSelector } from 'redux/store';
import {
  ENotificationType,
  useShowToastNotification,
} from 'components/Base/Notification';
import { TRtkErrorType } from 'data/types/generic.types';
import { EChallengeName } from 'data/types/amplify.types';
import { useGetUserProfileQuery } from 'redux/apiSlice/userGrow.slice';
import PublicRoutes from './PublicRoutes';
import PrivateRoutes from './PrivateRoutes';

const RoutesPage: FC = () => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.messages.error',
  });
  const { showToastNotification } = useShowToastNotification();
  const usersData = useAppSelector(state => state.userSlice);
  const { amplifyUser, user } = usersData;
  const {
    isLoading: userLoading,
    error,
    isError,
  } = useGetUserProfileQuery(amplifyUser, {
    skip:
      !amplifyUser ||
      amplifyUser.challengeName === EChallengeName.NEW_PASSWORD_REQUIRED ||
      amplifyUser.challengeName === EChallengeName.SOFTWARE_TOKEN_MFA,
  });

  useEffect(() => {
    const err = error as TRtkErrorType;
    if (err) {
      if (err.status === 401) {
        localStorage.removeItem('amplifyUser');
        showToastNotification({
          message: t('USER_NOT_EXIST'),
          type: ENotificationType.ERROR,
        });
        return;
      }
      showToastNotification({
        message: (error as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  }, [isError]);

  return (
    <Suspense fallback={userLoading}>
      <Routes>
        <Route
          path="/*"
          element={<PublicRoutes isLoggedIn={amplifyUser && user} />}
        />
        <Route
          path="app/*"
          element={<PrivateRoutes isLoggedIn={amplifyUser && user} />}
        />
      </Routes>
    </Suspense>
  );
};
export default RoutesPage;
