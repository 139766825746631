import styled from 'styled-components';
import { Badge } from 'components/Base/Badge';

export const SBadgeWrapper = styled(Badge)`
  &&& {
    background: transparent !important;
    & > .ant-ribbon-corner {
      display: none;
    }
  }
`;
