import { useTranslation } from '@packages/utils';
import useGetValidationData from 'components/Base/Form/validationRules';

const useGetFormData = () => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.meetings-tab.details.ta-time.add.form',
  });
  const { validationRules } = useGetValidationData();
  const addTATimeFormFields = {
    pillar: {
      name: 'pillarId',
      label: t('pillar.LABEL'),
      placeholder: t('pillar.PLACEHOLDER'),
      rules: [validationRules.requiredField()],
    },
    focus: {
      name: 'focusId',
      label: t('focus.LABEL'),
      placeholder: t('focus.PLACEHOLDER'),
      rules: [validationRules.requiredField()],
    },
    duration: {
      name: 'duration',
      placeholder: t('time.PLACEHOLDER'),
      rules: [
        validationRules.integerMoreThanZeroOptional(),
        validationRules.requiredField(),
      ],
    },
  };

  return { addTATimeFormFields };
};

export default useGetFormData;
