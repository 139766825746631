import { useEffect, useState } from 'react';
import { useTranslation } from '@packages/utils';
import PageWrapper from 'components/Base/PageWrapper';
import { EIconNames } from 'components/Base/Icon';
import useTable from 'components/Base/Table/hooks/useTable';
import CardContent from 'components/Base/Layout/Content/CardContent';

import {
  EKitManagementParentColumnKey,
  TKitManagementParentUI,
} from 'data/types/kitManagement.types';
import {
  useGetKitManagementParentHostsQuery,
  useGetKitManagementParentLocationsQuery,
  useGetKitManagementParentsQuery,
} from 'redux/apiSlice/kitManagement.slice';
import { ETableStoragePaths } from 'components/Base/Table/Table.types';

import { TRtkErrorType } from 'data/types/generic.types';
import { ETagType } from 'data/types/tags.types';
import { useGetTagsListQuery } from 'redux/apiSlice/tags.slice';
import useGetData from './Parents.data';
import ParentActions from './ParentActions';
import ParentBody from './ParentBody';

const Parents = () => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.parents.list',
  });
  const { defaultColumns, defaultFilterParam, defaultSortParam } = useGetData();
  const {
    page,
    pageSize,
    setPageSize,
    sortColumn,
    selectedFilters,
    hasSelectedFilter,
    selectedRows,
    onFiltersReset,
    columns,
    setColumns,
    handleTableChange,
    setSelectedRows,
  } = useTable<TKitManagementParentUI>({
    defaultSortColumn: defaultSortParam,
    storagePath: ETableStoragePaths.KIT_MANAGEMENT_PARENT,
    defaultColumns,
    defaultFilters: defaultFilterParam,
  });
  const [showAlert, setShowAlert] = useState(false);
  const { data, isFetching, isLoading, error } =
    useGetKitManagementParentsQuery({
      page,
      pageSize,
      sortColumn,
      filters: selectedFilters,
    });

  const { data: hostsFilters, isSuccess: hostsFiltersSuccess } =
    useGetKitManagementParentHostsQuery();
  const { data: locationFilters, isSuccess: locationFiltersSuccess } =
    useGetKitManagementParentLocationsQuery();
  const { data: tagsFilters, isSuccess: tagsFiltersSuccess } =
    useGetTagsListQuery(ETagType.PARENT_ORDER);

  useEffect(() => {
    if (hostsFiltersSuccess || locationFiltersSuccess || tagsFiltersSuccess) {
      setColumns(p => {
        return p.map(item => {
          if (
            item.key === EKitManagementParentColumnKey.HOST &&
            hostsFiltersSuccess
          ) {
            item.filters = hostsFilters;
            item.filterSearch = true;
          }
          if (
            item.key === EKitManagementParentColumnKey.LOCATION &&
            locationFiltersSuccess
          ) {
            item.filters = locationFilters;
            item.filterSearch = true;
          }
          if (
            item.key === EKitManagementParentColumnKey.TAGS &&
            tagsFiltersSuccess
          ) {
            item.filters = tagsFilters;
            item.filterSearch = true;
          }
          return item;
        });
      });
    }
  }, [
    hostsFiltersSuccess,
    hostsFilters,
    locationFiltersSuccess,
    locationFilters,
    tagsFiltersSuccess,
    tagsFilters,
  ]);

  useEffect(() => {
    if (selectedRows?.selectedRowsKeys?.length) {
      setShowAlert(false);
    }
  }, [selectedRows]);

  const isEmpty = !data?.content.length && !hasSelectedFilter && !isFetching;
  return (
    <PageWrapper
      title={t('TITLE')}
      isLoading={isLoading}
      error={error as TRtkErrorType}
      actions={
        <ParentActions
          selectedRowKeys={selectedRows.selectedRowsKeys}
          data={data?.content || []}
          setShowAlert={setShowAlert}
        />
      }
      emptyWrapper={{
        isEmpty,
        alt: 'Parents',
        icon: EIconNames.PARENT_OUTLINED,
        emptyText: t('empty-view.EMPTY_TEXT'),
      }}
    >
      <CardContent>
        <ParentBody
          showAlert={showAlert}
          setShowAlert={setShowAlert}
          dataSource={data?.content}
          totalDataCount={data?.totalElements}
          isFetching={isFetching}
          hasFilters={hasSelectedFilter}
          onFiltersReset={onFiltersReset}
          columns={columns}
          pageSize={pageSize}
          setPageSize={setPageSize}
          onChange={handleTableChange}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          currentPage={page}
          storagePath={ETableStoragePaths.KIT_MANAGEMENT_PARENT}
        />
      </CardContent>
    </PageWrapper>
  );
};

export default Parents;
