import { useTranslation } from '@packages/utils';
import useGetValidationData from 'components/Base/Form/validationRules';

const useGetFormData = () => {
  const { t } = useTranslation({
    keyPrefix: `descriptions.admin-portal.elcs.detail.details-tab.details-section.action.add.modal.form`,
  });
  const { validationRules } = useGetValidationData();
  const addDetailsFormFields = {
    category: {
      name: 'category',
      label: t('category.LABEL'),
      placeholder: t('category.PLACEHOLDER'),
      rules: [validationRules.requiredField()],
    },
    note: {
      name: 'note',
      label: t('details.LABEL'),
      placeholder: t('details.PLACEHOLDER'),
      rules: [validationRules.requiredField()],
    },
  };

  return { addDetailsFormFields };
};

export default useGetFormData;
