import {
  Button,
  ENotificationType,
  TRtkErrorType,
  useShowToastNotification,
  useTranslation,
} from '@packages/utils';
import { EIconNames, Icon } from 'components/Base/Icon';
import { useLazyExportFamilyCSVQuery } from 'redux/apiSlice/family.slice';
import { TFamilyExportProps } from './FamilyExport.types';

const FamilyExport = ({ selectedFilters }: TFamilyExportProps) => {
  const { t } = useTranslation({
    keyPrefix: 'descriptions.admin-portal.family.list.actions.export',
  });
  const { showToastNotification } = useShowToastNotification();

  const [exportFamily, { isLoading }] = useLazyExportFamilyCSVQuery();

  const handleExport = async () => {
    try {
      await exportFamily({ filters: selectedFilters }).unwrap();
    } catch (err) {
      showToastNotification({
        message: (err as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  return (
    <Button
      onClick={handleExport}
      color="primary"
      type="default"
      icon={<Icon icon={EIconNames.DOWNLOAD} alt="filter icon" />}
      loading={isLoading}
      disabled={isLoading}
    >
      {t('EXPORT_BTN')}
    </Button>
  );
};

export default FamilyExport;
