import { Row, useTranslation } from '@packages/utils';
import EditableInput from 'components/Base/EditableFields/EditableInput';
import { InplaceEditLabels } from 'components/Base/EditableFields/components/InplaceEditLabel';
import { EIconNames } from 'components/Base/Icon';
import {
  useShowToastNotification,
  ENotificationType,
} from 'components/Base/Notification';
import { useEditMeetingLinkMutation } from 'redux/apiSlice/workshops.slice';
import { TRtkErrorType } from 'data/types/generic.types';
import useGetValidationData from 'components/Base/Form/validationRules';
import { TMeetingLinkProps } from './MeetingLink.types';

const MeetingLink = ({ workshopId, meetingLink }: TMeetingLinkProps) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.workshops.details.basic-info.meeting-link',
  });
  const { validationRules } = useGetValidationData();
  const { showToastNotification } = useShowToastNotification();
  const [editMeetingLink, { isLoading, isError }] =
    useEditMeetingLinkMutation();
  const onEdit = async (value: string) => {
    try {
      await editMeetingLink({
        id: workshopId,
        value,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
    } catch (e) {
      showToastNotification({
        message: (e as TRtkErrorType)?.data?.message,
        type: ENotificationType.ERROR,
      });
    }
  };

  return (
    <Row align="middle">
      <InplaceEditLabels label={t('LABEL')} icon={EIconNames.LOCATION} />
      <EditableInput
        onEdit={onEdit}
        title={meetingLink?.value}
        isError={isError}
        isLoading={isLoading}
        readonly={meetingLink?.allowedOperations.readonly}
        canRemove={meetingLink?.allowedOperations.canRemove}
        emptyText={t('EMPTY_TEXT')}
        rules={[validationRules.validUrl()]}
        validateTrigger="onSubmit"
        backIcon
        fitContent
        wrapperClassName="word-break"
      />
    </Row>
  );
};

export default MeetingLink;
