import { TCreateConverter, TFetchConverter } from 'data/types/converter.types';
import {
  TGetLightBoxDB,
  TGetLightBoxUI,
  TUpdateLightBoxDB,
  TUpdateLightBoxUI,
} from 'data/types/lightBox.types';

export const getLightBoxConverter: TFetchConverter<
  TGetLightBoxUI,
  TGetLightBoxDB
> = {
  fromDb: data => {
    return {
      id: data.id,
      firstSection: data.firstSection,
      secondSection: data.secondSection,
      picture: data.picture,
      published: data.published,
    };
  },
};

export const updateLightBoxConverter: TCreateConverter<
  TUpdateLightBoxUI,
  TUpdateLightBoxDB
> = {
  toDb: data => ({
    id: data.id,
    firstSection: data.firstSection,
    secondSection: data.secondSection,
    picture: data.picture,
  }),
};
