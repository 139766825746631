import { useCheckPermission } from 'hooks/useCheckPermission';
import FamilyList from 'pages/private/Family/FamilyList';
import { RoutesPaths, SubRouteType } from 'routes/Routes.types';

const useGetFamilySubRoutesData = () => {
  const familySubRoutes: SubRouteType[] = [
    {
      component: FamilyList,
      path: RoutesPaths.FAMILY,
      show: false,
      showByPermissions: useCheckPermission('FAMILY_VIEW'),
    },
  ];
  return { familySubRoutes };
};

export default useGetFamilySubRoutesData;
