import { useEffect, useState } from 'react';
import { Form, FormItem } from 'components/Base/Form';
import { Select, SelectWithAdd } from 'components/Base/Select';
import { useGetOrganizationsIdentityQuery } from 'redux/apiSlice/organizations.slice';
import CreateOrganizationModal from 'components/Shared/CreateOrganizationModal';
import { RadioGroup } from 'components/Base/RadioGroup';
import { TOrganizationsIdentityUI } from 'data/types/organizations.types';
import { TCreateOrganizationModalOpen } from 'components/Shared/CreateOrganizationModal/CreateOrganizationModal.types';
import useGetData from './CreateWorkshopForm.data';
import { TCreateWorkshopFormProps } from './CreateWorkshopForm.types';

const CreateWorkshopForm = ({ form }: TCreateWorkshopFormProps) => {
  const [open, setOpen] = useState<TCreateOrganizationModalOpen>({
    visible: false,
    initialOrgName: '',
  });
  const [selectedOrganization, setSelectedOrganization] =
    useState<TOrganizationsIdentityUI>();
  const { createWorkshopFormFields, typeData } = useGetData();
  const { host, type, attendanceType, hostedPrivately } =
    createWorkshopFormFields;
  const { isLoading, data: organizations } = useGetOrganizationsIdentityQuery();

  useEffect(() => {
    form.setFieldValue('host', selectedOrganization?.value);
  }, [selectedOrganization]);

  return (
    <>
      <Form form={form}>
        <FormItem {...type}>
          <Select
            placement="topLeft"
            placeholder={type.placeholder}
            options={typeData}
          />
        </FormItem>
        <FormItem {...attendanceType}>
          <RadioGroup
            optionType="button"
            buttonStyle="solid"
            options={attendanceType.options}
            defaultValue={attendanceType.initialValue}
          />
        </FormItem>
        <FormItem {...hostedPrivately}>
          <RadioGroup
            optionType="button"
            buttonStyle="solid"
            options={hostedPrivately.options}
            defaultValue={hostedPrivately.initialValue}
          />
        </FormItem>
        <FormItem {...host}>
          <SelectWithAdd
            placeholder={host.placeholder}
            loading={isLoading}
            options={organizations}
            onBtnClick={currentSearchValue => {
              setOpen({
                visible: true,
                initialOrgName: currentSearchValue || '',
              });
            }}
            addButtonLabel="Add"
          />
        </FormItem>
      </Form>
      <CreateOrganizationModal
        open={open}
        setOpen={setOpen}
        setSelectedOrganization={setSelectedOrganization}
      />
    </>
  );
};

export default CreateWorkshopForm;
