import { useEffect, useState } from 'react';
import { get } from 'lodash';
import { useTheme } from 'styled-components';
import {
  useGetPillarsListQuery,
  useGetPillarsFocusesListQuery,
} from 'redux/apiSlice/pillars.slice';
import {
  Card,
  Form,
  FormItem,
  useForm,
  Row,
  Col,
  useTranslation,
  Button,
  Input,
  useShowToastNotification,
  ENotificationType,
  SRequiredLabel,
} from '@packages/utils';
import { useCreateElcMeetingTATimeMutation } from 'redux/apiSlice/elcs.slice';
import { Tooltip } from 'components/Base/Tooltip';
import { EIconNames, Icon } from 'components/Base/Icon';
import { TPillarsFocusesUI, TPillarsUI } from 'data/types/pillars.types';
import { TValidateFieldsError } from 'data/types/generic.types';
import { Select } from 'components/Base/Select';
import { TAddTATimeFormProps, TTATimeFormProps } from './AddTATimeForm.types';
import useGetFormData from './AddTATimeForm.data';

const AddTATimeForm = ({
  isDrawerOpen,
  onCancelForm,
  elcId,
  meetingId,
}: TAddTATimeFormProps) => {
  const { t } = useTranslation({
    keyPrefix:
      'descriptions.admin-portal.elcs.detail.meetings-tab.details.ta-time.add',
  });
  const [selectedPillar, setSelectedPillar] = useState<string>('');
  const [createElcMeetingTopic, { isLoading }] =
    useCreateElcMeetingTATimeMutation();
  const { data: pillars, isLoading: pillarsIsLoading } =
    useGetPillarsListQuery();
  const { data: focuses, isLoading: focusesIsLoading } =
    useGetPillarsFocusesListQuery(
      {
        pillarId: selectedPillar,
      },
      {
        skip: !selectedPillar,
      },
    );

  const pillarsOptions = pillars?.map((item: TPillarsUI) => ({
    label: item.pillarName,
    value: item.pillarId,
  }));

  const focusesOptions = focuses?.map((item: TPillarsFocusesUI) => ({
    label: item.focus,
    value: item.focusId,
  }));

  const theme = useTheme();
  const { showToastNotification } = useShowToastNotification();
  const { form } = useForm<TTATimeFormProps>();
  const { addTATimeFormFields } = useGetFormData();
  const { focus, pillar, duration } = addTATimeFormFields;

  const onPillarChange = (value: string) => {
    setSelectedPillar(value);
    form.resetFields(['focusId']);
  };

  useEffect(() => {
    if (!isDrawerOpen) {
      form.resetFields();
    }
  }, [isDrawerOpen]);

  const onSubmit = async () => {
    try {
      const values = await form.validateFields();
      await createElcMeetingTopic({
        meetingId,
        elcId,
        focusId: values.focusId,
        duration: values.duration,
      }).unwrap();
      showToastNotification({
        message: t('SUCCESS_MESSAGE'),
        type: ENotificationType.SUCCESS,
      });
      form.resetFields();
    } catch (err) {
      if (!(err as TValidateFieldsError)?.errorFields) {
        const message = get(err, 'data.message');
        showToastNotification({
          message,
          type: ENotificationType.ERROR,
        });
      }
    }
  };

  const handleCancel = () => {
    onCancelForm();
    form.resetFields();
  };

  return (
    <Card className="mb-4">
      <Form form={form} onFinish={onSubmit}>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <FormItem {...pillar}>
              <Select
                options={pillarsOptions}
                loading={pillarsIsLoading}
                placeholder={pillar.placeholder}
                onChange={onPillarChange}
              />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem {...focus}>
              <Select
                options={focusesOptions}
                loading={focusesIsLoading}
                placeholder={focus.placeholder}
              />
            </FormItem>
          </Col>
          <Col span={8}>
            <Row gutter={[8, 8]} wrap={false}>
              <Col>
                <SRequiredLabel className="mb-1">
                  {t('form.time.LABEL')} ({t('form.time.MIN')})
                </SRequiredLabel>
              </Col>
              <Col>
                <Tooltip title={t('form.time.TOOLTIP')}>
                  <Icon
                    icon={EIconNames.QUESTION_CIRCLE_ICON}
                    className="pointer"
                    size={14}
                    color={theme.colorBlackIconColor}
                  />
                </Tooltip>
              </Col>
            </Row>
            <FormItem {...duration}>
              <Input placeholder={duration.placeholder} />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={[16, 16]} align="middle">
          <Col>
            <Button
              color="primary"
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              {t('SUBMIT_BTN')}
            </Button>
          </Col>
          <Col>
            <Button color="primary" type="default" onClick={handleCancel}>
              {t('CANCEL_BTN')}
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default AddTATimeForm;
