import { Row, Text, Title } from '@packages/utils';
import { useTheme } from 'styled-components';
import { EIconNames, Icon } from 'components/Base/Icon';
import { TElcOrgPopoverProps } from './ElcOrgPopover.types';

const ElcOrgPopover = ({ name, address }: TElcOrgPopoverProps) => {
  const theme = useTheme();

  return (
    <>
      <Title level={4} className="mb-3">
        {name}
      </Title>
      {address && (
        <Row align="middle">
          <Icon
            icon={EIconNames.LOCATION_OUTLINED}
            className="pointer "
            color={theme.colorBlackIconColor}
            alt="edit attendance"
          />
          <Text className="mx-2" color={theme.colorBlackDescription}>
            {address.formattedAddress}
          </Text>
        </Row>
      )}
    </>
  );
};

export default ElcOrgPopover;
